import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ImageSwitchI, ImageSwitchI as I } from './ifc_image_switch_pagination';

/**
 * Компонент image_switch_pagination принимает
 * @ value - Индекс текущего выбранного изображения
 * @ aImages - Массив изображений (S3ItemImgIDsIWithAlt)
 * @ fGetImgSrc - Функция получения изображения
 * @ nCountItems - Количество изображений (для пагинации точками)
 * @ sAlign - Позицианирование пагинации left | center | right
 * @ bShowFade - Показывать ли размытие в конце пагинации
 */

@Component
export default class image_switch_pagination extends Vue {
    /** Индекс текущего выбранного изображения */
    @Prop({ type: Number, default: 0 }) value: number;
    /** Массив изображений */
    @Prop({ type: Array, required: false, default: () => [] }) aImages: I.S3ItemImgIDsIWithAlt[];
    /** Функция получения изображения */
    @Prop({ type: Function, required: false }) fGetImgSrc: (img: I.S3ItemImgIDsIWithAlt) => string;
    /** Количество изображений (для пагинации точками) */
    @Prop({ type: Number, default: 0 }) nCountItems: number;
    /** Позиционирование пагинации left | center | right */
    @Prop({ type: String, default: 'center' }) sAlign: string;
    /** Показывать ли размытие в конце пагинации */
    @Prop({ type: Boolean, default: true }) bShowFade: boolean;
    /** Показывать ли скроллбар */
    @Prop({ type: Boolean, default: false }) bHideScrollBar: boolean;
    /** Кастомные ширина и высота */
    @Prop({ type: Array, default: () => [] }) aCustomSize: [number, number];
    /** Флаг отображения кнопок */
    @Prop({ type: Boolean, default: false }) bDisplayButtons: boolean;

    $refs!: {
        imageList: HTMLDivElement;
        imageSwitchPagination: HTMLDivElement;
        scroll: HTMLElement;
    };

    /** Положение точек */
    nTransform: number = 0;
    /** Размер точки */
    nCircleSize: number = 5;
    /** Расстояние между точками / паддинг окошка с точками */
    nCirclePadding: number = 2.5;
    /** Максимальная ширина пагинации точками (исходя из размера и количества точек) */
    nMaxPaginationWidth: number = 40;
    /** Размер изображения при пагинации картинками */
    nImageSize: number = 40;
    /** Ширина компонента */
    nComponentWidth: number = 100;
    /** На сколько пикселей скроллим */
    nPixelsForSlide: number = 300;
    /** Запоминаем текущее положение */
    nCurrentPixelsForSlide: number = 0;
    /** Скрывать ли кнопку скролла */
    isHideLeftScrollBtn: boolean = false;
    isHideRightScrollBtn: boolean = false;

    /** Функция получения изображения */
    fGetImgSrcLocal(img: I.S3ItemImgIDsIWithAlt) {
        return this.fGetImgSrc(img);
    }

    /**
     * Автоматический скролл к выбранному элементу
     */
    @Watch('value')
    fAutoScroll() {
        /** Логика пагинации точками */
        if (this.isDotPagination) {
            if (this.nCountImagesLocal > 5) {
                if (this.idxCurrentSlide < 3) {
                    this.nTransform = 0;
                } else if (this.idxCurrentSlide > this.nCountImagesLocal - 3) {
                    this.nTransform = -this.nSlideDistance * (this.nCountImagesLocal - 5);
                } else {
                    this.nTransform = -this.nSlideDistance * (this.idxCurrentSlide - 2);
                }
            }
        } else {
            /** Логика пагинации изображениями */
            /** Элемент скролла */
            const elScroll = this.$refs.scroll as HTMLElement;
            /** Текущее положение скролла */
            const nCurrentScrollX = elScroll.scrollLeft;
            /** Положение изображения, соответствующего индексу */
            let nScrollToImage = this.nSlideDistance * this.value;
            /** Флаг необходимости скролла влево */
            const bScrollRight = nScrollToImage > nCurrentScrollX + this.nComponentWidth - this.nSlideDistance * 1.5;
            /** Флаг необходимости скролла вправо */
            const bScrollLeft = nScrollToImage - this.nSlideDistance * 0.5 < nCurrentScrollX;

            /** Скролл влево */
            if (bScrollLeft) {
                nScrollToImage -= this.nSlideDistance * 0.5;
                elScroll.scrollTo({ left: nScrollToImage, behavior: 'smooth' });
            } else if (bScrollRight) {
                /** Скролл вправо */
                nScrollToImage -= this.nComponentWidth - this.nSlideDistance * 1.5;
                elScroll.scrollTo({ left: nScrollToImage, behavior: 'smooth' });
            }
        }
    }

    /** Выбрать карточку */
    fSelectCard(idx: number, e: Event) {
        e.preventDefault();
        e.stopImmediatePropagation();
        e.stopPropagation();
        this.idxCurrentSlide = idx;
    }

    /** Слайд по кнопке */
    fSlideImageList(isLeft: boolean = false) {
        if (isLeft) {
            this.$refs.scroll.scrollLeft = this.nCurrentPixelsForSlide - this.nPixelsForSlide;
            if (this.nCurrentPixelsForSlide > 0) {
                this.nCurrentPixelsForSlide -= this.nPixelsForSlide;
            }
        } else {
            this.$refs.scroll.scrollLeft = this.nPixelsForSlide + this.nCurrentPixelsForSlide;
            if (this.$refs.scroll.scrollWidth - this.nPixelsForSlide * 1.5 > this.nCurrentPixelsForSlide) {
                this.nCurrentPixelsForSlide += this.nPixelsForSlide;
            }
        }
    }

    /** Изменение видимости кнопок скролла */
    fToggleScrollBtn() {
        const scrollLeft = this.$refs.scroll.scrollLeft;
        const scrollWidth = this.$refs.scroll.scrollWidth;
        const clientWidth = this.$refs.scroll.clientWidth;

        this.isHideLeftScrollBtn = scrollLeft === 0;
        this.isHideRightScrollBtn = Math.ceil(scrollLeft) + clientWidth >= scrollWidth;
    }

    /** Получение размеров компонента */
    fGetSizes() {
        const nComponentHeight = this.$refs.imageSwitchPagination.clientHeight;
        if (this.isDotPagination) {
            /** Паддинг - 12% от высоты компонента */
            this.nCirclePadding = Math.round((nComponentHeight / 100) * 12);
            /** Размер точки - 76% от высоты компонента */
            this.nCircleSize = Math.round((nComponentHeight / 100) * 76);
        } else {
            this.nImageSize = nComponentHeight - 15;
            this.nComponentWidth = this.$refs.imageSwitchPagination.clientWidth;
        }
    }

    mounted() {
        this.fGetSizes();
        /** Слушатель "resize" для корректного отображения при изменении параметров экрана */
        window.addEventListener(`resize`, this.fGetSizes, { passive: true });
        // Следим за скроллом
        if (!this.isDotPagination) {
            this.$refs.scroll.addEventListener('scroll', this.fToggleScrollBtn);
            this.fToggleScrollBtn();
        }
    }

    beforeDestroy() {
        /** Отключение слушателя */
        window.removeEventListener(`resize`, this.fGetSizes);
        if (!this.isDotPagination) {
            this.$refs.scroll.removeEventListener('scroll', this.fToggleScrollBtn);
        }
    }

    /** Количество изображений для пагинации */
    get nCountImagesLocal() {
        return this.nCountItems ? this.nCountItems : this.aImages.length;
    }

    /** Пагинация точками (В ином случае с изображениями) */
    get isDotPagination() {
        return !this.aImages?.length;
    }

    /** Есть ли изображения */
    get isImages() {
        return this.aImages.length;
    }

    /** Количество отображаемых точек */
    get nDisplayedCirclesCount() {
        return this.nCountImagesLocal > 5 ? 5 : this.nCountImagesLocal;
    }

    /** Полная шириша пагинации точками */
    get nPaginationWidth() {
        return this.nDisplayedCirclesCount * (this.nCircleSize + this.nCirclePadding) + this.nCirclePadding;
    }

    /** Дистанция одного слайда */
    get nSlideDistance() {
        let nSlideDistance = 0;
        if (this.isDotPagination) {
            nSlideDistance = this.nCircleSize + this.nCirclePadding;
        } else {
            nSlideDistance = this.aCustomSize[0] ? this.aCustomSize[0] + this.aCustomSize[0] / 10 : this.nImageSize + this.nImageSize / 10;
        }
        return nSlideDistance;
    }

    /** Показывать ли размытие в конце пагинации */
    get bShowFadeLocal() {
        return !this.isDotPagination && this.bShowFade && this.idxCurrentSlide !== this.nCountImagesLocal - 1;
    }

    /** Индекс текущего слайда */
    get idxCurrentSlide() {
        return this.value;
    }

    /** Индекс текущего слайда */
    set idxCurrentSlide(value) {
        this.$emit('input', value);
    }
}

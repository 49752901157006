import P63Vue from '@/system/P63Vue';
import { Component } from 'vue-property-decorator';
import { HomeCtrl, initHomeCtrl } from '../../view/ctrl-home';
import { mIsClient } from '@/common/helpers/ContextH';
import bannerMini from '@/assets/images/home/slider-mobile-baner-plug.webp';
import bannerDesktop from '@/assets/images/home/slider-banner-plug.webp';
import { gImageS } from '@/common/service/ImageS';
import SliderControl from '../slider-control/slider-control.vue';
import ArrowButton from '../arrow-button/arrow-button.vue';
import { BannerI } from '@/ifc/core/CommonI/BannerI';
import dayjs from 'dayjs';
import { sortBy } from 'lodash';

@Component({
    components: {
        SliderControl,
        ArrowButton,
    },
})
export default class BannerNewSource extends P63Vue {
    /** Алиас баннера на десктопе */
    readonly ALIAS_BANNER_PLACE_DESKTOP: string = 'home-page-top-desktop';
    /** Алиас баннера на мобильном */
    readonly ALIAS_BANNER_PLACE_MOBILE: string = 'home-page-top-mobile-new';
    /** Значение задержки при перелистывании */
    readonly TIME_DELAYED: number = 8000;

    /** Индекс текущего баннера */
    idBanner: number = 0;

    /** Таймаут для задержки перелистывания */
    idTimer: NodeJS.Timeout = null;

    /** Оставшееся время при паузе */
    nRestTimer: number = 0;

    /** Время начала показа баннера */
    nStartTime: number = 0;

    /** Точка начала касания */
    objPointStart: { x: number; y: number } = null;

    /** Обратное прокручивание анимации */
    isReverseAnimation: boolean = false;

    /** Получить ссылку при клике на баннер */
    fGetUrlBanner(idx: number): string {
        let objBanner = this.aBanners[idx];
        return objBanner.is_webp
            ? gImageS.fBannerImageUrlWebp(objBanner.filename)
            : gImageS.fBannerImageUrlJpg(objBanner.filename);
    }

    /** Установить баннер по клику */
    fSetBanner(idBanner: number): void {
        this.idBanner = idBanner;
        this.nRestTimer = 0;
    }
    /** Перейти к следующему баннеру*/
    fChangeBanner(): void {
        this.nRestTimer = 0;
        if (this.isMobile) {
            this.isReverseAnimation = false;
        }
        if (this.idBanner < this.aBanners.length - 1) {
            this.idBanner++;
        } else {
            this.idBanner = 0;
        }
    }

    /** Перейти к предыдущему баннеру */
    fReturnBanner(): void {
        if (this.isMobile) this.isReverseAnimation = true;

        this.nRestTimer = 0;

        if (this.idBanner > 0) this.idBanner--;
        else this.idBanner = this.aBanners.length - 1;

        this.nStartTime = Date.now();
    }

    /** Установить таймер для автоматической прокрутки баннера */
    fSetTimeoutChangeBanner(nTime: number = this.TIME_DELAYED): void {
        this.nStartTime = Date.now();
        if (this.isMobile && this.aBanners.length === 2 && this.idBanner === 0) {
            this.isReverseAnimation = true;
        } else if (this.isMobile && this.aBanners.length === 2 && this.idBanner === 1) {
            this.isReverseAnimation = false;
        }

        clearTimeout(this.idTimer);
        this.idTimer = setTimeout(() => {
            this.fChangeBanner();
            this.fSetTimeoutChangeBanner();
        }, nTime);
    }

    /** Остановить таймер */
    fStopTimer(): void {
        if (this.nRestTimer) this.nRestTimer = this.nRestTimer - (Date.now() - this.nStartTime);
        else this.nRestTimer = this.TIME_DELAYED - (Date.now() - this.nStartTime);
        clearTimeout(this.idTimer);
    }

    /** Возобновить таймер */
    fRerunTimer(): void {
        if (this.nRestTimer) this.fSetTimeoutChangeBanner(this.nRestTimer);
        else this.fSetTimeoutChangeBanner();
        this.nStartTime = Date.now();
    }

    /** Нужно ли установить класс для баннера */
    fCheckPrevBanner(idx: number): boolean {
        let isPrevBannerClass: boolean;
        if (this.isMobile) {
            if (this.aBanners.length === 2) {
                isPrevBannerClass = this.idBanner === 1 ? idx === 0 : false;
            } else {
                isPrevBannerClass = (this.idBanner ? this.idBanner - 1 : this.aBanners.length - 1) === idx;
            }
        } else {
            isPrevBannerClass = false;
        }
        return isPrevBannerClass;
    }

    /** Нужно ли установить класс для баннера */
    fCheckNextBanner(idx: number): boolean {
        let isNextBannerClass: boolean;
        if (this.isMobile) {
            if (this.aBanners.length === 2) {
                isNextBannerClass = this.idBanner === 0 ? idx === 1 : false;
            } else {
                isNextBannerClass = (this.idBanner === this.aBanners.length - 1 ? 0 : this.idBanner + 1) === idx;
            }
        } else {
            isNextBannerClass = false;
        }
        return isNextBannerClass;
    }

    /** Обработчик начала касания */
    fHandlerStartTouch(e: TouchEvent): void {
        this.objPointStart = {
            x: e.changedTouches[0].clientX,
            y: e.changedTouches[0].clientY,
        };
        this.fStopTimer();
    }

    /** Обработчик окончания касания */
    fHandlerEndTouch(e: TouchEvent): void {
        const nCoorX = e.changedTouches[0].clientX - this.objPointStart.x;
        const nCoorY = e.changedTouches[0].clientY - this.objPointStart.y;
        if (Math.abs(nCoorX) > Math.abs(nCoorY)) {
            const cbChangeBanner = nCoorX < 0 ? this.fChangeBanner : this.fReturnBanner;
            if (Math.abs(nCoorX) > 50) {
                cbChangeBanner();
            }
        }
        this.fRerunTimer();
    }

    /** По клику на баннер передаем данные с баннером в аналитику */
    fOnBannerClick(): void {
        if (this.aBanners.length) {
            const objBanners: BannerI = this.aBanners[this.idBanner];

            /** Функция для вызова индифиактора Яндекс-метрики (учет кликов по баннерам) */
            this.ctrl.statSys.fSendActionYM(objBanners.banner_name);
            this.ctrl.statSys.faPushClickToStat({
                page: '/',
                type: 'banner_new',
                alias: 'home_new',
                entity_id: objBanners.banner_id,
            });

            this.ctrl.statSys.fSendEcommerceAction({
                action: 'Promotion Click',
                bNonInteraction: false,
                data: {
                    ecommerce: {
                        promoClick: {
                            promotions: [
                                {
                                    id: String(objBanners.banner_id),
                                    name: objBanners.banner_name,
                                    creative: dayjs().format(),
                                    position: String(objBanners.banner_order),
                                },
                            ],
                        },
                    },
                },
            });
        }
    }

    mounted() {
        if (this.aBanners.length > 1) this.fSetTimeoutChangeBanner();
    }

    /** Заглушки для баннеров */
    get sPlugHref(): string {
        let sPlugHref: string = '';

        if (this.isMobile) sPlugHref = `${bannerMini}`;
        else sPlugHref = `${bannerDesktop}`;

        return sPlugHref;
    }

    /** Мобильное ли устройство */
    get isMobile(): boolean {
        return this.status.is_mobile;
    }

    /** Баннеры в зависимости от алиаса */
    get aBanners(): BannerI[] {
        let aBannersByAlias: BannerI[];

        if (this.isMobile)
            aBannersByAlias = sortBy(this.ix.banners_by_alias[this.ALIAS_BANNER_PLACE_MOBILE], 'banner_order');
        else aBannersByAlias = sortBy(this.ix.banners_by_alias[this.ALIAS_BANNER_PLACE_DESKTOP], 'banner_order');

        return aBannersByAlias ?? [];
    }

    /** Ссылка на картинку текущего баннера */
    get sUrlBanner(): string {
        let objBanner = this.aBanners[this.idBanner];
        return objBanner.is_webp
            ? gImageS.fBannerImageUrlWebp(objBanner.filename)
            : gImageS.fBannerImageUrlJpg(objBanner.filename);
    }

    get status() {
        return this.ctrl.status;
    }

    get ix() {
        return this.ctrl.ix;
    }
    /** Хранилище */
    get ctrl(): HomeCtrl {
        return initHomeCtrl(mIsClient() ? this.$context : this.$ssrContext);
    }
}

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"imageSwitch",staticClass:"ds-image-switch",attrs:{"draggable":"false"}},[_c('div',{staticClass:"img-container"},[_c('ul',{ref:"imgList",staticClass:"img-list",style:({
                transform: `translateX(${_vm.nTransform}px)`,
                transition: `transform ${_vm.nSwipeTransition}s ease 0s`,
                ..._vm.vSwitchStyle,
            }),attrs:{"draggable":"false"}},_vm._l((_vm.aImages),function(img,idx){return _c('li',{key:idx,staticClass:"img-box",style:({
                    'min-width': _vm.nSlideWidth ? `${_vm.nSlideWidth}px` : '100%',
                    width: _vm.nSlideWidth ? `${_vm.nSlideWidth}px` : 'auto',
                    height: '100%',
                }),attrs:{"draggable":"false"}},[_c('div',{staticClass:"zoom",class:{ 'pinch-transition': !_vm.isStopAnimation },style:({ transform: _vm.sTransform }),attrs:{"draggable":"false","id":`zoom-${idx}`},on:{"touchstart":_vm.fTouchStartHandler,"touchmove":_vm.fTouchMoveHandler,"touchend":_vm.fTouchEndHandler,"touchcancel":_vm.fTouchEndHandler,"mousedown":_vm.fMouseDownHendler,"mousemove":function($event){return _vm.fMoveZoomImage($event, img)},"mouseleave":_vm.fMouseLeaveHandler}},[_c('img',{staticClass:"img",style:({
                            maxWidth: 'auto',
                            ..._vm.vImgStyle,
                        }),attrs:{"draggable":"false","src":_vm.fGetImgSrcLocal(img),"alt":img.alt,"loading":idx === 0 ? 'eager' : 'lazy',"decoding":idx === 0 ? 'sync' : 'async'},on:{"contextmenu":function($event){return $event.stopPropagation()},"mouseover":function($event){return _vm.fMouseOverHandler($event, img)},"click":_vm.fClickImg}}),_vm._t("after-image",null,{"image":img})],2),(_vm.bImgBlurBg)?_c('span',{staticClass:"bg-filter",style:({
                        background: `url(${_vm.fGetImgSrcLocal(img)}) center center / cover no-repeat`,
                        ..._vm.vImgStyle,
                    })}):_vm._e()])}),0),(_vm.bDisplayButtons && _vm.aImages.length > 1)?_c('button',{staticClass:"btn btn-left",on:{"click":function($event){$event.stopPropagation();return _vm.fShowPreviousImg.apply(null, arguments)}}},[_c('i',{staticClass:"arrow arrow-position-left",class:{
                    filled: _vm.bButtonsFilled,
                    'no-events': _vm.nSwipeStartX,
                },attrs:{"aria-label":"Предыдущее изображение"}})]):_vm._e(),(_vm.bDisplayButtons && _vm.aImages.length > 1)?_c('button',{staticClass:"btn btn-right",on:{"click":function($event){$event.stopPropagation();return _vm.fShowNextImg.apply(null, arguments)}}},[_c('i',{staticClass:"arrow arrow-position-right",class:{
                    filled: _vm.bButtonsFilled,
                    'no-events': _vm.nSwipeStartX,
                },attrs:{"aria-label":"Следующее изображение"}})]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }
import { Component } from 'vue-property-decorator';
import P63Vue from '@/system/P63Vue';
import protect from '@/assets/images/home/benefit_protect.svg';
import star from '@/assets/images/home/benefit_star.svg';
import price from '@/assets/images/home/benefit_price.svg';
import label from '@/assets/images/home/benefit_label.svg';
import { PageCtrl } from '@/system/PageCtrl';
import { gfInitContextCtrl } from '@/system/ContextSys';
import { mIsClient } from '@/common/helpers/ContextH';

@Component
export default class BenefitsSource extends P63Vue {
    /** Открыто ли модальное окно Защиты покупателя*/
    bOpen: boolean = false;

    /** Иконки */
    vIcons = {
        protect,
        star,
        price,
        label,
    };

    /** Открыть модальное окно Защиты покупателя*/
    fOpenModalProtection() {
        this.bOpen = true;
    }

    /** Закрыть модальное окно Защиты покупателя*/
    fCloseModalProtection() {
        this.bOpen = false;
    }

    /** Открыт ли сайт с приложения */
    get isApp() {
        return this.ctrl.isApp;
    }

    get ctrl(): PageCtrl {
        return gfInitContextCtrl(mIsClient() ? this.$context : this.$ssrContext, PageCtrl);
    }
}

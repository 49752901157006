export enum ItemActionT {
    put = 'put', // Действие добавления товара в фантомную корзину
    take_out = 'take_out', // Действие удаления товара из фантомной корзины
    to_order = 'to_order', // Создание заказа из товаров в фантомной корзине
}

/**
 * Интерфейс сущности действия с товарами в фантомной корзине
 */
export interface NoauthCartItemI {
    item_id?: number; // ID товара
    noauthkey?: string; // Кука незарегистрированного пользователя
    user_id?: number; // ID пользователя
    action?: ItemActionT; // Действие с товаром (положили/убрали/создали с ними заказ)
    order_uuid?: string; // UUID заказа, который оформит пользователь
    action_datetime?: string; // Время, когда было совершено действие
}


import { Component, Prop, Watch } from 'vue-property-decorator';
import { HomeCtrl, initHomeCtrl } from '@/pages/home/view/ctrl-home';
import { BannerI } from '@/ifc/core/CommonI/BannerI';
import { HomeN } from '@/pages/home/view/ifc-home';
import bannerTop from '@/assets/images/plugs/bannertop.webp';
import bannerMini from '@/assets/images/plugs/bannermini.webp';
import P63Vue from '@/system/P63Vue';
import { mIsClient } from '@/common/helpers/ContextH';
import dayjs from 'dayjs';
import { gUtmS } from '@/common/service/UtmsS';
import { UtmN } from '@/common/interface/UtmI';
import { mCyrillicToTranslitCleared } from '@/common/helpers/CyrillicToTranslitH';
import { BannerPlaceAliasT } from '@/ifc/core/EntitySQL/P63BannerE';
import { gImageS } from '@/common/service/ImageS';


@Component
export default class HomeBannerSource extends P63Vue {
    @Prop({ type: String, default: '' }) bannerType: HomeN.BannerTypeT; // тип баннера
    @Prop({ type: Array, default: () => [] }) banner: BannerI[]; // все баннеры
    @Prop({ type: String, default: '' }) alias: String // алиас баннера для аналитики

    isClient: boolean = false;
    iTransform: number = 0;
    iSlideInterval: number = 8000; //Интревал перелистывания
    iScreenWidth: number = 1184;
    iScrollWidth: number = 0;
    iSwipeWidth: number = 1184;
    iSliderWidth: number = 1184;
    vEachBannerPosition: Record<number, number> = {}; // Объект с позициями каждого баннера
    nDisplayedBannerIdx: number = 0; // Индекс отображаемого баннера
    nTouchStartCoordinateX: number = null; // Координата начала события touch
    bDraggingEnabled: boolean = true; // Возможность потянуть за баннеры
    nSwipedDistance: number = 0;
    nAutoswipeId = null;
    elMainSlider: HTMLElement = null; // Баннер слайдер (элемент)

    /** По клику на баннер передаем данные с баннером в аналитику */
    fOnBannerClick(banner: BannerI | any) {
        /** Функция для вызова индифиактора Яндекс-метрики (учет кликов по баннерам) */
        this.ctrl.statSys.fSendActionYM(banner.banner_name);
        this.ctrl.statSys.faPushClickToStat({
            page: '/',
            type: 'banner',
            alias: 'home',
            entity_id: banner.banner_id,
        });
        this.ctrl.statSys.fSendEcommerceAction({
            action: 'Promotion Click',
            bNonInteraction: false,
            data: {
                ecommerce: {
                    promoClick: {
                        promotions: [
                            {
                                id: banner.banner_id,
                                name: banner.banner_name,
                                creative: dayjs().format(),
                                position: banner.banner_order,
                            },
                        ],
                    },
                },
            }
        })
    }

    // Получаем размеры слайдера
    fGetSliderSize() {
        const el = this.$refs.mainBanner as HTMLElement;
        if (el) {
            this.iScreenWidth = window.innerWidth; // ширина экрана
            this.iTransform = 0; // убираем смещение
            this.iSwipeWidth = el.clientWidth; //ширина контейнера (прокрутки)
            this.iSliderWidth = this.banner.length * this.iSwipeWidth; // вычисляем ширину контейнера со всеми слайдерами
            this.iScrollWidth = 0; // Ширина вертикальной прокрутки(ползунка)
            if (this.bMobile) {
                this.iScrollWidth = this.iScreenWidth - this.iSwipeWidth;
                this.iTransform = 10;
                this.iSwipeWidth = el.clientWidth - 25;
            }

            let nStep: number = this.iTransform;
            for (let i = 0; i < this.banner.length; i++) {
                this.vEachBannerPosition[i] = nStep;
                nStep -= this.iSwipeWidth + this.iScrollWidth;
            }
        }
    }

    /** Формируем ссылку для картинки в формате jpg или webp*/
    fGetImgUrl(url:string, isWebp: boolean = false) {
      if (isWebp) {
        return gImageS.fBannerImageUrlWebp(url)
      }
      return gImageS.fBannerImageUrlJpg(url)
    }

    fOnSliderSwap(direction?: string, idx?: number, bAuto?: boolean) {
        const nOffset = 16; // 16px - т.к. есть доп padding у banner-а

        if (this.elMainSlider) {
            this.elMainSlider.style.transition = `transform 0.4s`;

            /** Прокрутка влево */
            if (direction === 'left') {
                /** Если быннер не первый, листаем к предыдущему*/
                if (this.nDisplayedBannerIdx > 0) {
                    this.nDisplayedBannerIdx -= 1;
                    this.iTransform = this.vEachBannerPosition[this.nDisplayedBannerIdx];
                } else {
                    /** Если баннер первый, листаем к последнему */
                    this.elMainSlider.style.transition = `transform 0.2s`;
                    this.nDisplayedBannerIdx = this.banner.length - 1;
                    this.iTransform = this.vEachBannerPosition[this.nDisplayedBannerIdx];
                }
            }

            /** Прокрутка вправо */
            if (direction === 'right') {
                /** Если быннер не последний, листаем к следующему */
                if (this.nDisplayedBannerIdx < this.banner.length - 1) {
                    this.nDisplayedBannerIdx += 1;
                    this.iTransform = this.vEachBannerPosition[this.nDisplayedBannerIdx];
                } else {
                    /** Если баннер последний, листаем к первому */
                    this.elMainSlider.style.transition = `transform 0.2s`;
                    this.nDisplayedBannerIdx = 0;
                    this.iTransform = this.vEachBannerPosition[this.nDisplayedBannerIdx];
                }
            }

            /** Пролистать к любому баннеру через его индекс */
            if (idx !== null) {
                this.elMainSlider.style.transition = `transform 0.2s`;
                this.nDisplayedBannerIdx = idx;
                this.iTransform = this.vEachBannerPosition[idx];
            }

            /** Если перелистывание вызванно не автосвайпом */
            if (!bAuto) {
                /** Отключаем автоматическую прокрутку слайдера , либо отменяем запуск*/
                if (this.nAutoswipeId) {
                    clearInterval(this.nAutoswipeId);
                    this.nAutoswipeId = null;
                }
                this.fInitInterval(this.iSlideInterval);
            }
        }
    }

    @Watch('iTransform')
    fTransformSlider() {
        this.elMainSlider.style.willChange = `transform`;
        this.elMainSlider.style.transform = `translateX(${this.iTransform}px)`;
    }

    // Интервал перелистывания слайда
    fInitInterval(slideInt) {
        if (!this.nAutoswipeId) {
            this.nAutoswipeId = setInterval(() => {
                this.fOnSliderSwap('right', null, true);
            }, slideInt);
        }
    }

    /** Запись точки начала свайпа */
    fTouchStartHandler(e) {
        this.nTouchStartCoordinateX = e.changedTouches[0].clientX; // Запись стартовой точки свайпа

        /** При касании отключаем автоматическую прокрутку слайдера , либо отменяем запуск*/
        clearInterval(this.nAutoswipeId);
        this.nAutoswipeId = null;
    }

    /** Обработчик свайпа для мобильной версии баннера */
    fHandlerSwipe(e: TouchEvent) {
        e.stopImmediatePropagation();
        const nCurrentXCoordinate = e.touches[0].clientX;
        const nCurrntBannerTransform = this.vEachBannerPosition[this.nDisplayedBannerIdx]; // Правильная позиция текущего баннера
        const nTouchMoveX = this.nTouchStartCoordinateX - nCurrentXCoordinate;
        this.elMainSlider.style.transition = `transform 0s`;
        this.iTransform = nCurrntBannerTransform - nTouchMoveX;
        this.nSwipedDistance = nTouchMoveX;
    }

    /** Обработка окончания свайпа */
    fTouchEndHandler(e) {
        const nCurrntBannerTransform = this.vEachBannerPosition[this.nDisplayedBannerIdx]; // Правильная позиция текущего баннера
        this.nTouchStartCoordinateX = null; // Сброс стартовой точки свайпа

        /** Если идет перелистывание в правильном (возможном) направлении, долистываем до правильной точки */
        if (this.iTransform < 0 && this.iTransform > -this.iSliderWidth) {
            /** Свайп засчитываения при перемещении на 50px */
            if (
                this.iTransform < nCurrntBannerTransform - 50 &&
                this.nDisplayedBannerIdx < this.banner.length - 1
            ) {
                this.fOnSliderSwap('right', null, false); // Свайп вправо
            } else if (this.iTransform > nCurrntBannerTransform + 50) {
                this.fOnSliderSwap('left', null, false); // Свайп влево
            } else {
                this.fOnSliderSwap('', this.nDisplayedBannerIdx, false); // Вернулись на исходную
            }
        } else {
            /** Если перелистывание в недоступном направлении, возвращаем баннер в исходное положение */
            this.fOnSliderSwap('', this.nDisplayedBannerIdx, false);
        }
    }

    /** Обработчик отмены события для IOS */
    fTouchCancelHandler(e) {
        /** Предотвращаем стандартное поведение браузера */
        e.preventDefault();
        e.stopPropagination();
        e.stopImmediatePropagation();
    }

    /** Получить результирующий URL с utm - метками */
    fGetResUrl(sUrl: string, sTerm: string, nOrder?: number) {
        const isTopBanner = this.bannerType === 'top';
        const vUtm: UtmN.UtmI = {
            source: UtmN.UtmSourceT.banner,
            medium: isTopBanner ? UtmN.UtmMediumT.index_main : UtmN.UtmMediumT.index,
            campaign: this.bDesktop ? 'desktop' : 'mobile',
            content: isTopBanner ? String(nOrder) : String(this.nBannerPlaceNumber),
            term: mCyrillicToTranslitCleared(sTerm),
        };

        return gUtmS.fGetUrlWithUtm(sUrl, vUtm);
    }

    /** Запустить переключение баннеров */
    fStartBannerSwitching() {
        const self = this;
        // запускаем слайдер
        if (Array.isArray(self.banner) && self.banner.length) {
            self.fInitInterval(this.iSlideInterval);
        }

        this.elMainSlider = this.$refs['main-slider'] as HTMLElement;
        // вешаем слушатель событий для баннера
        if (this.bMobile || this.bTablet) {
            if (this.elMainSlider) {
                this.elMainSlider.addEventListener('touchstart', this.fTouchStartHandler, { passive: true });
                this.elMainSlider.addEventListener('touchmove', this.fHandlerSwipe, { passive: false });
                this.elMainSlider.addEventListener('touchend', this.fTouchEndHandler);
                this.elMainSlider.addEventListener('touchcancel', this.fTouchCancelHandler);
            }
        }

        /** при изменении ширины экрана пересчитываем размеры слайдера */
        window.addEventListener('resize', this.fGetSliderSize);
        this.fGetSliderSize();
    }

    // Следить за пропсом, чтобы массив баннеров
    // не становился undefined и не терялась реактивность
    @Watch('banner')
    fUpdateBanners() {
        if (this.banner && this.banner.length > 1) {
            this.$nextTick(() => this.fStartBannerSwitching());
        }
    }

    // Хуки
    async mounted() {
        this.iScreenWidth = window.innerWidth;

        if (this.banner && this.banner.length > 1) {
            await this.$nextTick();
            this.fStartBannerSwitching();
        }
        this.isClient = true;
    }

    beforeDestroy() {
        // очищаем слушатель событий для баннера
        if (this.elMainSlider) {
            this.elMainSlider.removeEventListener('touchstart', this.fTouchStartHandler);
            this.elMainSlider.removeEventListener('touchmove', this.fHandlerSwipe);
            this.elMainSlider.removeEventListener('touchend', this.fTouchEndHandler);
            this.elMainSlider.removeEventListener('touchcancel', this.fTouchCancelHandler);
        }

        if (this.banner && this.banner.length) {
            window.removeEventListener('resize', this.fGetSliderSize);
        }
    }

    /** Хранилище */
    get ctrl(): HomeCtrl {
        return initHomeCtrl(mIsClient() ? this.$context : this.$ssrContext);
    }

    /** Порядковый номер баннера на странице для utm*/
    get nBannerPlaceNumber() {
        let nNumber: number = 0;
        if (this.alias === BannerPlaceAliasT.homeBPageLeft1) nNumber = 1;
        if (this.alias === BannerPlaceAliasT.homeBPageRight1) nNumber = 2;
        if (this.alias === BannerPlaceAliasT.homeBPageLeft2) nNumber = 3;
        if (this.alias ===  BannerPlaceAliasT.homeBPageRight2) nNumber = 4;
        return nNumber;
    }

    /** Баннерные ссылки (ключ - id баннера, значение - его ссылка) */
    get ixResUrls() {
        let ixResUrls: Record<number, string | number> = {};

        for (let i = 0; i < this.banner.length; i += 1) {
            const vDisplayedBanner = this.banner[i];

            ixResUrls[vDisplayedBanner.banner_id] = this.fGetResUrl(
                vDisplayedBanner.banner_url,
                vDisplayedBanner.banner_name,
                vDisplayedBanner.banner_order
            );
        }

        return ixResUrls;
    }

    /** Является ли баннер верхним */
    get bTop() {
        return this.bannerType === HomeN.BannerTypeT.top;
    }

    /** Первый баннер */
    get vFirstBanner() {
        return this.banner[0];
    }

    /** Мобильная ли ширина у устройства */
    get bMobile() {
        return this.ctrl.status.is_mobile;
    }

    /** Плашетная ли ширина у устройства */
    get bTablet() {
        return this.ctrl.status.is_tablet;
    }

    /** Декстопная ли ширина у устройства */
    get bDesktop() {
        return this.ctrl.status.is_desktop;
    }

    /** Заглушки для баннеров */
    get sPlugHref() {
        let sPlugHref: string = '';

        if (this.bMobile) {
            sPlugHref = `${bannerMini}`;
        } else {
            sPlugHref = this.bTop ? `${bannerTop}` : `${bannerMini}`;
        }

        return sPlugHref;
    }
}

import Component from 'vue-class-component';
import P63Vue from '@/system/P63Vue';
import Var1 from './variations/Variant1.vue'
import Var3 from './variations/Variant3.vue'
import Var5 from './variations/Variant5.vue'
import Var7 from './variations/Variant7.vue'
import Var8 from './variations/Variant8.vue'
import Var9 from './variations/Variant9.vue'
import Var2 from './variations/Variant2.vue'
import Var4 from './variations/Variant4.vue'
import Var6 from './variations/Variant6.vue'
import dayjs from 'dayjs'
import { gfInitContextCtrl } from '@/system/ContextSys';
import { mIsClient } from '@/common/helpers/ContextH';
import { PageCtrl } from '@/system/PageCtrl';

const DAY_TIMESTAMP = 86400000

@Component({
    components: {
        Var1,
        Var2,
        Var3,
        Var4,
        Var5,
        Var6,
        Var7,
        Var8,
        Var9
    }
})
export default class app_popup extends P63Vue {
    variants = {
        1: Var1,
        2: Var2,
        3: Var3,
        4: Var4,
        5: Var5,
        6: Var6,
        7: Var7,
        8: Var8,
        9: Var9,
    }
    vCurrentComponent: P63Vue = null;
    nComponentId: number = 0;
    nShowTime: number = null;
    nShowTimerId: NodeJS.Timer = null;
    sPlatform: 'Android' | 'iOS' = null;
    isAndroid: boolean = false;
    isIos: boolean = false;
    nShowCount: number = 0

    mounted() {
        // Получение всех нужных данных для определения устройства и наличия приложения на устройстве
        const userAgent = window.navigator.userAgent;
        const aCookies = document.cookie;
        const isApp = aCookies.includes('isApp=true'); // Проверка на приложение
        const isMobile = userAgent.includes('Mobile'); // Проверка на mobile device
        this.isAndroid = userAgent.includes('Android'); // Проверка на android device
        this.isIos = userAgent.includes('iPhone') || userAgent.includes('iPad'); // Проверка на ios
        this.sPlatform = this.isAndroid ? 'Android' : this.isIos ? 'iOS' : null;
        this.nShowCount = Number(localStorage.getItem('nShowAppPopupCount')) || 0


        // Выбор случайного варианта попапа для отображения
        this.nComponentId = Math.floor(Math.random() * 9) + 1;

        // Проверка на то, что пользователь пользуется с телефона и не через приложение
        if (!isApp && isMobile) {

            // Проверка на наличие даты последнего показа попапа
            const sShowTime = localStorage.getItem('sShowAppPopup')
            if (sShowTime) {
                // Получение текущей даты последнего показа
                this.nShowTime = Number(sShowTime)
            } else {
                // Установка значения, когда должен показаться попап
                this.nShowTime = dayjs().add(5, 'minute').valueOf()
                localStorage.setItem('sShowAppPopup', this.nShowTime.toString())
            }
            if (this.nShowTime - Date.now() < DAY_TIMESTAMP) {
                this.nShowTimerId = setInterval(this.fCheckDateForShow, 15000)
            }
        }
    }

    /** Проверка времени для показа попапа */
    fCheckDateForShow() {
        const nCurrentDate: number = Date.now();
        // Проверяем пора ли показывать и не открыта ли модалка из системы управления модалками
        if (nCurrentDate >= this.nShowTime && !this.sDisplayedModalAlias) {
            // Показываем случайный компонент
            this.vCurrentComponent = this.variants[this.nComponentId]

            // Добавляем query параметр для метрики
            this.$router.push({
                query: {
                    ...this.$route.query,
                    [`modal_window_n${this.nComponentId}`]: this.nShowCount
                }
            })
            clearInterval(this.nShowTimerId)
        }
    }

    /** Закрытие модального окна */
    fClosePopup() {
        // Установка новой даты, когда должен показаться попап повторно
        const nNewDate = dayjs().add(7, 'day').valueOf()
        localStorage.setItem('sShowAppPopup', nNewDate.toString())
        localStorage.setItem('nShowAppPopupCount', String(this.nShowCount + 1))

        // Очистка query параметров от флага для метрики
        const vQueryParams = Object.entries(this.$route.query)
            .reduce((acc, [key, value]) => {
                if (key === `modal_window_n${this.nComponentId}`) {
                    return acc
                }
                acc[key] = value
                return acc
        }, {})
        this.$router.push({query: vQueryParams})

        // Закрытие попапа
        this.vCurrentComponent = null
    }

    /** Переход на страницу загрузки */
    fDownloadApp() {
        if (this.isAndroid) {
            this.ctrl.statSys.fSendActionYM('download-app-android')
            window.location.href = 'https://play.google.com/store/apps/details?id=ru.pokupki63.prod.twa&hl=ru'
        } else if (this.isIos) {
            this.ctrl.statSys.fSendActionYM('download-app-ios')
            window.location.href = 'https://play.google.com/store/apps/details?id=ru.pokupki63.prod.twa' // TODO: Нужна ссылка на AppStore
        }
        // Закрытие попапа
        this.vCurrentComponent = null
    }

    /** Алиас модалки для отображения */
    get sDisplayedModalAlias(): string {
        return this.ctrl.modalNotificationSys.modal_notification.active_modal;
    }

    get ctrl() {
        return gfInitContextCtrl(mIsClient() ? this.$context : this.$ssrContext, PageCtrl);
    }
}

import Component from 'vue-class-component';
import { gfInitContextCtrl } from '@/system/ContextSys';
import { PageCtrl } from '@/system/PageCtrl';
import { Prop } from 'vue-property-decorator';

import P63Vue from '@/system/P63Vue';
import { mIsClient } from '@/common/helpers/ContextH';
import { SavedMenuLinkI } from '@/ifc/core/CommonSQL/SavedMenuLinkE';

@Component({})
export default class MenuManagementSections extends P63Vue {
    // Пропсы
    @Prop({ type: Array, default: [] }) aOrgLink: SavedMenuLinkI[];
    @Prop({ type: Number, default: 0 }) nOrgNotificationCounter: number;
    @Prop({ type: Number, default: 0 }) nNewRefundRequestCounter: number;
    @Prop({ type: Boolean, default: false }) bNewHeader: boolean;
    // Геттеры
    //   Селекторы
    /** Информация о пользователе */
    get vUserInfo() {
        return this.ctrl.userSys.user;
    }

    /** Список избранных ссылок без системных уведомлений */
    get aOrgLinkFiltered() {
        return this.aOrgLink.filter((link) => link.url !== '/org/system-notification/');
    }

    //    Основные
    get ctrl(): PageCtrl {
        return gfInitContextCtrl(mIsClient() ? this.$context : this.$ssrContext, PageCtrl);
    }
}

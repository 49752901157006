import { UserAccountPaymentI } from './AccountSQL/UserAccountPaymentE';
import { UserAccountTransferInvoiceI } from './AccountSQL/UserAccountTransferInvoiceE';
import { P63UserAccountAcquireT } from './EntitySQL/P63UserAccountAcquireE';
import { UserAccountTransferDeliveryI } from './AccountSQL/UserAccountTransferDeliveryE';
import { UserAccountTransferIssueI } from './AccountSQL/UserAccountTransferIssueE';
import { AccountDebtI } from './CommonI/AccountI';
import { P63InvoiceDeliveryI } from './EntitySQL/P63InvoiceDeliveryE';
import { P63InvoiceIssueI } from './EntitySQL/P63InvoiceIssueE';
import { OrderI } from './EntitySQL/OrderE';
import { ItemI } from './EntitySQL/ItemE';
import { PaginationI } from './CommonI/PaginationI';
import { PurchaseUserIDs } from './EntitySQL/PurchaseUserE';
import { PurchaseKeyI } from './EntitySQL/PurchaseE';
import { AutoRefundLinkI } from './AccountSQL/AutoRefundLinkE';
import { BonusExpireInfoI } from './BonusActionUserE';

export namespace UserAccountR {
    export const Alias = 'UserAccount';
    // ===========================================
    // Эквайринг
    // ===========================================

    /** Получить статус транзакции эквайринга */
    export namespace getAcquireTransactionStatus {
        /** APIURL */
        export const route = '/user-account/get-acquire-transaction-status';

        /** Параметры api запроса */
        export interface RequestI {
            account_acquire_id: number; // айди операции эквайринга
        }

        /** Параметры api ответа */
        export interface ResponseI {
            account_acquire_id: number; // номер транзакции для эквайринга в нашей системе
            status: P63UserAccountAcquireT; // Статус транзакции (new|check|pay|refuse)
        }
    }

    /**
     * API callback aquire
     *
     * Используемый метод модели будет разным для разных команд (`command`):
     * - для `check` - проверка возможности платежа - addCheckPayAcquire
     * - для `pay` - оплата - confirmPayAcquire
     */
    export namespace callbackAcquire {
        /** APIURL */
        export const route = '/user-account/callback-acquire';

        /** Параметры api запроса */
        export interface RequestI {
            command?: string; // Комманда
            txn_id?: string; // ID платежа в системе эквайринга
            txn_date?: string; // дата платежа
            account?: string; // user_id _ account_acquire_id - ID юзера и ID транзакции эквайринга
            sum?: number | string; // сумма платежа
        }

        /** Параметры api ответа */
        export interface ResponseI {
            // XML для эквайринга
            osmp_txn_id?: string; // id внешней транзакции, прилетающее с эквайринга
            result?: number; // код завершения(смотреть доку взаимодействия с эквайрингом)
            sum?: number; // сумма перевода через эквайринг
            prv_txn?: number; // id транзакции в нашей бд (p63_account_payment)
            comment?: Record<string, any>; // объект с комментами/ошибками
        }
    }

    /** Создать новую транзакцию эквайринга */
    export namespace addAcquiringPayment {
        /** APIURL */
        export const route = '/user-account/create-acquire-transaction';

        /** Параметры api запроса */
        export interface RequestI {
            money: number; // сумма транзакции
        }

        /** Параметры api ответа */
        export interface ResponseI {
            account_acquire_id: number; // номер транзакции для эквайринга в нашей системе
        }
    }

    /**
     * Узнать баланс СП
     */
    export namespace getAccountBalance {
        /** APIURL */
        export const route = '/user-account/get-balance';

        /** Параметры api запроса */
        export interface RequestI {
            user_id?: number; // ID пользователя
        }

        /** Параметры api ответа */
        export interface ResponseI {
            total_money?: number; // свободные деньги счёта пользователя
            block_money?: number; // заблокированные (ждущие перевода) деньги счёта пользователя
            total_bonus?: number; // бонусы пользователя
        }
    }

    /**
     * Получить историю платежей СП
     */
    export namespace getAccountHistory {
        /** APIURL */
        export const route = '/user-account/account-history';

        /** Параметры api запроса */
        export interface RequestI {
            user_id?: number; // ID пользователя, чью историю получаем (если нет, то показывается владельца запроса)
            start_date?: string; // дата с которой показывать платежи
            final_date?: string; // дата до которой показывать платежи
        }

        /** Параметры api ответа */
        export interface ResponseI {
            list_account_payment: UserAccountPaymentI[]; // все транзакции СП
            list_account_transfer_invoice: UserAccountTransferInvoiceI[]; // переводы на оплату заказов
            list_invoice: PurchaseUserIDs[]; // основные ID заказов
            list_purchase: PurchaseKeyI[]; // информация об оплаченных закупках

            list_account_transfer_delivery: UserAccountTransferDeliveryI[]; // переводы на оплату доставки
            list_invoice_delivery: P63InvoiceDeliveryI[]; // расширения информации переводов на оплату доставки

            list_account_transfer_issue: UserAccountTransferIssueI[]; // переводы на оплату выдачи в ПВЗ
            list_invoice_issue: P63InvoiceIssueI[]; // расширения информации переводов на оплату выдачи

            list_order: OrderI[]; // заказы
            list_item: ItemI[]; // товары соответсвующие заказам

            list_invoice_archive_status: { invoice_id: number, archive: number }[];
            list_auto_refund_link: AutoRefundLinkI[];
        }
    }

    /**
     * Получить долг по заказу
     */
    export namespace getInvoiceDebt {
        /** APIURL */
        export const route = '/user-account/invoice-debt';

        /** Параметры api запроса */
        export interface RequestI {
            invoice_id?: number; // ид заказа
        }

        /** Параметры api ответа */
        export interface ResponseI extends AccountDebtI {}
    }

    /** Сгенерировать и положить в редис pdf-файл истории операций счёта пользователя */
    export namespace setAccountHistoryPDF {
        /** APIURL */
        export const route = '/user-account/set-account-history-pdf';

        /** Параметры api запроса */
        export interface RequestI {
            user_id: number; // ID пользователя
        }

        /** Параметры api ответа */
        export interface ResponseI {
            token: string; // по токену в редисе находится пдф файл
        }
    }

    /** Получить pdf-файл истории операций счёта пользователя */
    export namespace getAccountHistoryPDF {
        /** APIURL */
        export const route = '/user-account/get-account-history-pdf';

        /** Параметры api запроса */
        export interface RequestI {
            token: string; // по токену в редисе находится пдф файл
        }

        /** Параметры api ответа */
        export interface ResponseI {
            // buffer
        }
    }

    /** Пополнить счёт (и опционально оплатить) */
    export namespace addNewInPayment {
        /** APIURL */
        export const route = '/user-account/add-new-in-payment';

        /** Параметры api запроса */
        export interface RequestI {
            money: number; // Денюшка
            comment?: string; // Комментарий
        }

        /** Параметры api ответа */
        export interface ResponseI {
            payment_id: number; // ID транзакции
        }
    }

    /** Создать запрос на вывод денег с лиц. счета*/
    export namespace addRefundRequest {
        /** APIURL */
        export const route = '/user-account/add-refund-request';

        /** Параметры api запроса */
        export interface RequestI {
            refund_money: number; // Сколько денег требуют вернуть (рубли.копейки)
            refund_card?: string; // Номер карты для возврата
            refund_fullname: string; // ФИО заявителя на возврат
            refund_reason?: string; // Описание причины возврата
            bank_bik: string; // БИК банка - 9 цифр
            bank_name: string; // Название банка
            user_bill: string; // Расчетный счет
        }

        /** Параметры api ответа */
        export interface ResponseI {}
    }

    /**
     * Получить информацию о первых сгораемых бонусах
     */
    export namespace getBonusExpired {
        /** APIURL */
        export const route = '/user-account/get-bonus-expired';

        /** Параметры api запроса */
        export interface RequestI {}

        /** Параметры api ответа */
        export interface ResponseI {
            list_bonus_expired: BonusExpireInfoI[];
        }
    }
}

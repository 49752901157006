import Vue from 'vue';
import Vuex from 'vuex';
import Router from 'vue-router';

import { fCreateClientApp, fCreateServerApp } from '@/init';
import P63Vue from '@/system/P63Vue';
import { P63Context } from '@/system/P63Context';
import { gAsyncComponentSys } from '@/system/AsyncComponentSys';
import { mIsClient } from '@/common/helpers/ContextH';

import App from './page-home.vue';

import '@/assets/styles/fonts.css';
// import '@/assets/styles/reset.local.scss';
import '@/assets/styles/home_old_ds.css';


// Добавить плагины
Vue.use(Vuex);
Vue.use(Router);

/** Создать роутер для маршрутизации страницы */
function createRouter() {
    return new Router({
        mode: 'history',
        routes: [
            {
                path: '/',
                component: App,
            },
        ],
    });
}

/** Создать экземпляры роутера, приложения и хранилища */
function createApp() {
    const vRouter = createRouter();

    // Создать экземпляр приложения, который рендерит компонент App
    const vApp = new P63Vue({
        router: vRouter,
        render: (h) => h(App),
    });

    const vStore = new Vuex.Store({});

    return { app: vApp, router: vRouter, store: vStore };
}

// Создать приложение на стороне клиента
fCreateClientApp(createApp);
export default (context: P63Context) => {
    // Создать приложение на стороне сервера
    return fCreateServerApp(context, createApp);
};

// Зарегистрировать асинхронные компоненты
if (mIsClient()) {
    gAsyncComponentSys.fRegisterAsyncComponent('HomePopupStory', (resolve: () => void) =>
        require(['@/pages/home/el/home-popup-story/home-popup-story.vue'], resolve)
    );

    // Защита клиента
    gAsyncComponentSys.fRegisterAsyncComponent('ModalUserProtection', (resolve: () => void) =>
        require(['@/common/el/ModalUserProtection/ModalUserProtection'], resolve)
    );
}

import Component from 'vue-class-component';
import ds_button from '@/ds/el/button/button';
import icon from '@/ds/el/icon/icon';
import { gfInitContextCtrl } from '@/system/ContextSys';
import { mIsClient } from '@/common/helpers/ContextH';
import { PageCtrl } from '@/system/PageCtrl';

import P63Vue from '@/system/P63Vue';

@Component({
    components: {
        'ds-button': ds_button,
        icon,
    },
})
export default class ModalNewApp extends P63Vue {

    /** Переход на страницу загрузки */
    fDownloadApp() {
        window.location.href = 'https://play.google.com/store/apps/details?id=ru.pokupki.app.twa&pli=1';
        this.ctrl.statSys.fSendActionYM('download-new-app-android');
    }

    get ctrl() {
        return gfInitContextCtrl(mIsClient() ? this.$context : this.$ssrContext, PageCtrl);
    }
}

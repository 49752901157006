import * as conf from '@/configs/config.public';
import { BaseCtrl, QuerySys, VuexSys } from '@63pokupki/front';
import { ItemInfoI } from '@/ifc/core/EntitySQL/ItemE';
import { P63ItemCharacteristicIDsI } from '@/ifc/core/EntitySQL/P63ItemCharacteristicE';
import { PurchaseInfoI } from '@/ifc/core/EntitySQL/PurchaseE';
import { S3ItemImgIDsI } from '@/ifc/core/EntitySQL/S3ItemImgE';
import { mIsEqualArraysByCallback, mMergeArraysByUniqKey } from '../helpers/ArrayH';
import gVuexSys from '@/lib_common/system/VuexSys';
import { InputR } from '@/ifc/stat/InputR';
import { catchErrorApiResponse } from '../helpers/ErrorHandler';
import { ItemActionT } from '@/ifc/stat/CH/NoauthCartItemE';
import dayjs from 'dayjs';

/** Товар для неавторизованной корзины */
export type NoAuthItemI = ItemInfoI & {
    nCount: number;
    ixSelectedChar: Record<number, number>; // Индексированный список выбранных характеристик key_id, val_id
    idUniq: number; // Уникальный id записи, текущая дата
};
/** Закупка для неавторизованной корзины */
export type NoAuthPurchaseI = PurchaseInfoI & {
    nOrgFee: number;
    nDeliveryTime: number;
    isMarketStock: boolean;
};

/** Инфа из неавторизованной корзины */
export interface NoauthCartI {
    aItem: NoAuthItemI[];
    aItemChar: P63ItemCharacteristicIDsI[];
    aItemImg: S3ItemImgIDsI[];
    aPurchase: NoAuthPurchaseI[];
}

class NoAuthCartS extends BaseCtrl {
    private LOCAL_STORAGE_ALIAS = 'noauth-cart'; // Алиас данных в корзине
    private LAST_ADD_TO_CART_ALIAS = 'noauth-cart-last-add-to-cart'; // Алиас когда товар был добавлен в последний раз
    private LAST_SHOW_CART_TOOLTIP_ALIAS = 'noauth-cart-last-show-cart-tooltip'; // Алиас когда был показан тултип у корзины в последний раз
    private LAST_SHOW_NO_AUTH_ALIAS = 'noauth-cart-last-show-no-auth'; // Алиас когда была показана корзиночная модалка того что вы не авторизованы

    private isShowBeforeModalUserIsNotSignIn = false; // Показывалась ли корзиночная модалка того что вы не авторизованы

    public conf = conf;
    private queryStatSys: QuerySys = null;

    constructor(vuexSys: VuexSys) {
        super(vuexSys);
        this.queryStatSys = new QuerySys();

        this.queryStatSys.fConfig(conf.statAPI);
    }

    /**  Отправить статистику */
    public fSendStat(request: InputR.writeNoauthCartItem.RequestI) {
        this.queryStatSys.fInit();
        this.queryStatSys.fActionOk((data: InputR.writeNoauthCartItem.ResponseI) => {});
        this.queryStatSys.fActionErr((err) => {
            catchErrorApiResponse({
                error: new Error('Ошибка при отправке статистики не авторизованной корзины'),
                response: err,
                meta: 'NoAuthCartS.fSendStat',
            });
        });
        this.queryStatSys.fSend(InputR.writeNoauthCartItem.route, request);
    }

    /** Получить корзину из localstorage */
    private get vNoAuthCart() {
        let vNoAuthCart: Partial<NoauthCartI>;
        try {
            vNoAuthCart = JSON.parse(localStorage.getItem(this.LOCAL_STORAGE_ALIAS));
        } catch (e) {}
        if (!vNoAuthCart) {
            vNoAuthCart = {};
        }
        if (!vNoAuthCart.aItem) {
            vNoAuthCart.aItem = [];
        }
        if (!vNoAuthCart.aItemChar) {
            vNoAuthCart.aItemChar = [];
        }
        if (!vNoAuthCart.aPurchase) {
            vNoAuthCart.aPurchase = [];
        }
        if (!vNoAuthCart.aItemImg) {
            vNoAuthCart.aItemImg = [];
        }
        return vNoAuthCart as NoauthCartI;
    }

    /** Положить в неавторизованную корзину данные */
    private set vNoAuthCart(vNoauthCart) {
        localStorage.setItem(this.LOCAL_STORAGE_ALIAS, JSON.stringify(vNoauthCart));
        localStorage.setItem(this.LAST_ADD_TO_CART_ALIAS, String(Date.now()));
        this.fTriggerAll(vNoauthCart);
    }

    /** Массив калбэков которые отслеживают изменения */
    private acbTrigger: Array<(vNoauthCart: NoauthCartI) => void> = [];

    /** Вешался ли addEventListener  */
    private isListener = false;

    /** Вызвать все калбэки */
    private fTriggerAll(vNoauthCart: NoauthCartI) {
        for (let i = 0; i < this.acbTrigger.length; i++) {
            const cbTrigger = this.acbTrigger[i];
            cbTrigger(vNoauthCart);
        }
    }

    /** Проверить, показывать ли тултип рядом с значком корзины */
    fIsShowCartTooltip() {
        let isShow = false;
        const sRouteName = window.location.href.split('63pokupki.ru/')[1].split('#')[0];
        // Страница не корзина
        const isNotCart = sRouteName !== 'cart';
        // В корзине что то есть
        const vNoauthCart = this.vNoAuthCart;
        if (isNotCart && vNoauthCart.aPurchase.length) {
            const nDate = Number(localStorage.getItem(this.LAST_SHOW_CART_TOOLTIP_ALIAS));
            if (nDate) {
                // с последнеого показа прошло 24ч
                const n24hour = 1000 * 60 * 60 * 24;
                const nDiff = Date.now() - nDate;
                isShow = nDiff > n24hour;
            } else {
                // или показа никогда не было
                isShow = true;
            }
        }
        return isShow;
    }

    /** Закрыть тултип рядом с значком корзины */
    fCloseCartTooltip() {
        localStorage.setItem(this.LAST_SHOW_CART_TOOLTIP_ALIAS, String(Date.now()));
    }

    /** Корзиночная модалка того что пользователь не авторизован показалась */
    fModalUserNotSignInForCartOpened() {
        localStorage.setItem(this.LAST_SHOW_NO_AUTH_ALIAS, String(Date.now()));
        this.isShowBeforeModalUserIsNotSignIn = true;
    }

    /** Проверить, можно ли показать корзиночную модалку неавторизации */
    fIsShowNoAuthCartModal() {
        let isShow = false;
        if (!this.isShowBeforeModalUserIsNotSignIn) {
            const nDate = Number(localStorage.getItem(this.LAST_SHOW_NO_AUTH_ALIAS));
            if (nDate) {
                // с последнеого показа прошло 24ч
                const n24hour = 1000 * 60 * 60 * 24;
                const nDiff = Date.now() - nDate;
                isShow = nDiff > n24hour;
            } else {
                // или показа никогда не было
                isShow = true;
            }
        }
        return isShow;
    }

    /** Сохранить товар в корзину для неавторизованного пользователя */
    fSaveItemInNoAuthCart(prop: {
        vItem: ItemInfoI;
        nCount: number;
        vPurchase: PurchaseInfoI;
        nOrgFee: number;
        nDeliveryTime: number;
        isMarketStock: boolean;
        aItemChar: P63ItemCharacteristicIDsI[];
        aSelectedItemChar: P63ItemCharacteristicIDsI[];
        aItemImg: S3ItemImgIDsI[];
    }) {
        const {
            vItem,
            nCount,
            vPurchase,
            nOrgFee,
            nDeliveryTime,
            isMarketStock,
            aItemChar,
            aSelectedItemChar,
            aItemImg,
        } = prop;
        const vNoauthCart = this.vNoAuthCart;

        /** Если добавляли такой товар, то прибавляем ему количество, если нет, то добавляем новый в массив */
        const vExistItem = vNoauthCart.aItem.find(
            (el) =>
                el.item_id === vItem.item_id &&
                mIsEqualArraysByCallback(
                    aSelectedItemChar,
                    Object.entries(el.ixSelectedChar),
                    (a, b) => a.characteristic_key_id === Number(b[0]) && a.characteristic_val_id === b[1]
                )
        );

        if (vExistItem) {
            vExistItem.nCount += nCount;
        } else {
            /** Добавляем выбранные характеристики к товару */
            const ixSelectedChar: Record<number, number> = {};
            for (let i = 0; i < aSelectedItemChar.length; i++) {
                const vChar = aSelectedItemChar[i];
                ixSelectedChar[vChar.characteristic_key_id] = vChar.characteristic_val_id;
            }
            vNoauthCart.aItem.push({ ...vItem, ixSelectedChar, idUniq: Date.now(), nCount: nCount });
        }

        /** Добавить закупку только если ее не было */
        const nIndexExistPurchase = vNoauthCart.aPurchase.findIndex((e) => e.purchase_id === vPurchase.purchase_id);
        if (nIndexExistPurchase !== -1) {
            /** Если она была, то удаляем ее из середины, потом переместим ее в начало */
            vNoauthCart.aPurchase.splice(nIndexExistPurchase, 1);
        }
        /** Свежо добавленную закупку в начало */
        vNoauthCart.aPurchase.unshift({ ...vPurchase, nOrgFee, nDeliveryTime, isMarketStock });

        /** Добавить характеристики и картинки если их не было */
        vNoauthCart.aItemChar = mMergeArraysByUniqKey(vNoauthCart.aItemChar, aItemChar, 'id');
        vNoauthCart.aItemImg = mMergeArraysByUniqKey(vNoauthCart.aItemImg, aItemImg, 'id');

        /** Отправляем в статистику что товар добавлен */
        this.fSendStat({
            list_item_id: [vItem.item_id],
            action: ItemActionT.put,
            action_datetime: dayjs().format('YYYY-MM-DD HH:mm:ss'),
        });

        this.vNoAuthCart = vNoauthCart;
    }

    /** Изменить количество товара */
    fChangeItemCount(idUniqItem: number, nDiff: number) {
        const vNoauthCart = this.vNoAuthCart;

        const vItem = vNoauthCart.aItem.find((e) => e.idUniq === idUniqItem);
        vItem.nCount += nDiff;

        this.vNoAuthCart = vNoauthCart;
    }

    /** Удалить товар */
    fDeleteitem(vItem: NoAuthItemI) {
        const vNoauthCart = this.vNoAuthCart;

        vNoauthCart.aItem = vNoauthCart.aItem.filter((e) => e.idUniq !== vItem.idUniq);
        /** Удаляем закупку если она осталась без товаров */
        const isHaveItems = vNoauthCart.aItem.some((e) => e.purchase_id === vItem.purchase_id);
        if (!isHaveItems) {
            vNoauthCart.aPurchase = vNoauthCart.aPurchase.filter((e) => e.purchase_id !== vItem.purchase_id);
        }

        /** Отправляем в статистику что товар удален */
        this.fSendStat({
            list_item_id: [vItem.item_id],
            action: ItemActionT.take_out,
            action_datetime: dayjs().format('YYYY-MM-DD HH:mm:ss'),
        });

        this.vNoAuthCart = vNoauthCart;
    }

    /** Поменять характеристику */
    fChangeChar(idUniqItem: number, vChar: P63ItemCharacteristicIDsI) {
        const vNoauthCart = this.vNoAuthCart;

        const vItem = vNoauthCart.aItem.find((e) => e.idUniq === idUniqItem);
        vItem.ixSelectedChar[vChar.characteristic_key_id] = vChar.characteristic_val_id;

        this.vNoAuthCart = vNoauthCart;
    }

    /** Проверить срок годности корзины */
    fIsExperationDate() {
        const nLastAddToCartDate = Number(localStorage.getItem(this.LAST_ADD_TO_CART_ALIAS));
        const nDiff = Date.now() - nLastAddToCartDate;
        const n4Days = 1000 * 60 * 60 * 24 * 4;
        return nDiff > n4Days;
    }

    /** Получить неавторизованную корзину, проверив ее срок годности */
    fGetNoAuthCart(): NoauthCartI {
        let vNoauthCart: NoauthCartI;
        if (!this.fIsExperationDate()) {
            vNoauthCart = this.vNoAuthCart;
        } else {
            this.fDeleteNoAuthCart();
            vNoauthCart = {
                aItem: [],
                aItemChar: [],
                aItemImg: [],
                aPurchase: [],
            };
        }
        return vNoauthCart;
    }

    /** Подписаться на изменения в localstorage корзины */
    fSubsribeNoAuthCart(cbFn: (vNoauthCart: NoauthCartI) => void) {
        this.acbTrigger.push(cbFn);
        cbFn(this.vNoAuthCart);

        if (!this.isListener) {
            window.addEventListener('storage', (event) => {
                if (event.storageArea != localStorage) return;
                if (event.key === this.LOCAL_STORAGE_ALIAS) {
                    this.fTriggerAll(this.vNoAuthCart);
                }
            });
        }

        this.isListener = true;
    }

    /** Удалить неавторизованную корзину */
    fDeleteNoAuthCart() {
        localStorage.removeItem(this.LOCAL_STORAGE_ALIAS);
    }
}

export const gNoAuthCartS = new NoAuthCartS(gVuexSys);

<script lang="ts">
import Component from 'vue-class-component';
import P63Vue from '@/system/P63Vue';

@Component
export default class HeaderCategories extends P63Vue {}
</script>

<template>
    <nav class="categories">
        <ul class="categories__list">
            <li class="categories__item">
                <a href="/purchases?tags=15" class="categories__link top-purchase">
                    <Icon sIconName="star" sWidth="16" sHeight="16" />
                    Топ-закупки
                </a>
            </li>
            <li class="categories__item">
                <a href="/purchases/odezhda-i-obuv-dlya-zhenshchin-6" class="categories__link">
                    <Icon sIconName="woman" sWidth="16" sHeight="16" />
                    Женщинам
                </a>
            </li>
            <li class="categories__item">
                <a href="/purchases/odezhda-i-obuv-dlya-muzhchin-7" class="categories__link">
                    <Icon sIconName="men" sWidth="16" sHeight="16" />
                    Мужчинам
                </a>
            </li>
            <li class="categories__item">
                <a href="/purchases/odezhda-i-obuv-dlya-detei-5" class="categories__link">
                    <Icon sIconName="child" sWidth="16" sHeight="16" />
                    Детям
                </a>
            </li>
            <li class="categories__item">
                <a href="/purchases/tovari-dlya-doma-3" class="categories__link">
                    <Icon sIconName="house" sWidth="16" sHeight="16" />
                    Дом
                </a>
            </li>
            <li class="categories__item">
                <a href="/purchases/kosmetika-i-parfyumeriya-11" class="categories__link">
                    <Icon sIconName="flacon" sWidth="16" sHeight="16" />
                    Косметика
                </a>
            </li>
            <li class="categories__item product">
                <a href="/purchases/produkti-14" class="categories__link">
                    <Icon sIconName="cake" sWidth="16" sHeight="16" />
                    Продукты
                </a>
            </li>
            <li class="categories__item accessories">
                <a href="/purchases/kozhgalantereya-i-aksessuari-8" class="categories__link">
                    <Icon sIconName="handbag" sWidth="16" sHeight="16" />
                    Аксессуары
                </a>
            </li>
            <li class="categories__item">
                <a href="/market" class="categories__link market">
                    <Icon sIconName="box-fill-v2" sWidth="16" sHeight="16" />
                    Товары в наличии
                </a>
            </li>
            <li class="categories__item">
                <a href="/brands/" class="categories__link brands">
                    <Icon sIconName="label" sWidth="16" sHeight="16" />
                    Бренды
                </a>
            </li>
            <li class="categories__item">
                <a href="/stock/" class="categories__link sale">
                    <Icon sIconName="promotion" sWidth="16" sHeight="16" />
                    Акции
                </a>
            </li>
        </ul>
    </nav>
</template>

<style scoped lang="scss">
.categories {
    position: relative;
    display: block;
    width: 100%;
    padding: 0 24px;
    margin: 0;
    max-width: 1440px;
    font-size: 14px;
    background: #fff;

    @media (max-width: 1024px) {
        display: none;
    }

    @media (min-width: 1440px) {
        padding: 0 48px;
    }

    &__list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        list-style: none;
        padding: 0;
        margin: 0;
        width: 100%;
        white-space: nowrap;
        gap: 15px;
    }

    &__item {
        display: flex;
        align-items: center;
        gap: 6px;
        margin: 0;
        padding: 12px 0;
        @media (max-width: 1211px) {
            &.accessories {
                display: none;
            }
        }
        @media (max-width: 1096px) {
            &.product {
                display: none;
            }
        }
    }

    &__link {
        display: flex;
        align-items: center;
        gap: 6px;
        cursor: pointer;
        color: #1d1e1f;
        text-decoration: none;
        font-size: 14px;
        line-height: 150%;
        transition: color 0.2s ease;

        .ds-icon {
            transition: fill 0.2s ease;
            fill: #a2a3a5;
        }

        &.top-purchase {
            font-weight: 600;
            color: #ff4e4e;

            .ds-icon {
                fill: #ff4e4e;
            }
        }

        &.brands {
            font-weight: 600;
            color: #d146fc;

            .ds-icon {
                fill: #d146fc;
            }
        }

        &.market {
            font-weight: 600;
            color: #00C533;

            .ds-icon {
                fill: #00C533;
            }
        }

        &.sale {
            font-weight: 600;
            color: #ff9f47;

            .ds-icon {
                fill: #ff9f47;
            }
        }

        &:hover {
            color: #36a6f2;

            .ds-icon {
                fill: #36a6f2;
            }
        }
    }
}
</style>

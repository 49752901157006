import dayjs from "dayjs";

interface LocalDateI {
    user_id: number;
    last_visit: string;
}

class LocalDateS {

    //Получить последнюю дату из LocalStorage и записать текущую
    fGetAndUpdateDateFromLocalStorage(idUser: number){
        let sLastVisitDate: string = ''
        const sCurrentDate: string = dayjs().format('YYYY-MM-DD');
        const aDateFromStorage = localStorage.getItem('last_visit_date') ? JSON.parse(localStorage.getItem('last_visit_date')) : {};

        if (aDateFromStorage[idUser]){
            sLastVisitDate = aDateFromStorage[idUser];
        }
        aDateFromStorage[idUser] = sCurrentDate;
        localStorage.setItem('last_visit_date', JSON.stringify(aDateFromStorage));

        return sLastVisitDate
    }
}

export const gLocalDateS = new LocalDateS();

export namespace UtmN {
    export enum UtmT {
        source = 'source',
        medium = 'medium',
        campaign = 'campaign',
        content = 'content',
        term = 'term',
    }

    export interface UtmI {
        [UtmT.source]?: string;
        [UtmT.medium]?: string;
        [UtmT.campaign]?: string;
        [UtmT.content]?: string;
        [UtmT.term]?: string;
    }

    export enum UtmSourceT {
        banner = 'banner',
    }

    export enum UtmMediumT {
        index = 'index',
        index_main = 'index_main',
        purchase = 'purchase',
    }

    export enum UtmCampaignT {
        upper = 'upper',
        bottom = 'bottom',
        orgrec = 'orgrec',
    }
}

import { mIsClient } from "../helpers/ContextH";

/** Навигация мобильного приложения */
class AppNavigatorS {


    /** Текущая сессия */
    get sCurrentSession() {
        if(mIsClient()) {
            let sCurrentSession = '';

            const sHistorySession = window.history.state?.current_session;

            if (sHistorySession) {
                sCurrentSession = sHistorySession;
            } else {
                sCurrentSession = sessionStorage.getItem('current_session');
            }
            return sCurrentSession ? sCurrentSession : 'home';
        }
    }

    /** Статус: является ли мобильным приложением */
    get isApp() {
        return document.cookie.includes('isApp=true')
    }

    /** Хендлер: возвращение на одну позицию в текущей сессиии */
    fBack(event) {
        event.preventDefault();
        const aUrlPosition = JSON.parse(sessionStorage.getItem(this.sCurrentSession));
        if (aUrlPosition.length) {
            aUrlPosition.length = aUrlPosition.length - 1;
        }
        const sUrl = aUrlPosition.slice(-1)[0];
        window.location.href = sUrl;
        sessionStorage.setItem(this.sCurrentSession, JSON.stringify(aUrlPosition));
    }

    /** Добавление позиции истории в текущую сессию */
    fAddPosition() {
        if(this.isApp) {
            const sUrl = window.location.href;
            let asUpdatedUrlPosition: string[] = [];

            const sStateSession = window.history.state?.current_session;
            if (sStateSession) {
                this.fSetSession(sStateSession);
            }
            const aUrlPosition: string[] = JSON.parse(sessionStorage.getItem(this.sCurrentSession));

            // Если в истории текущей сессии пусто
            if(!aUrlPosition?.length) {
                asUpdatedUrlPosition = [sUrl];
            } else {
                // Последний урл в истории
                const sLastUrl = aUrlPosition[aUrlPosition?.length - 1] ?? '';

                // Предпоследний урл в истории
                const sPreLastUrl = aUrlPosition[aUrlPosition?.length - 2] ?? '';

                // в истории лежат "u0-u1-u2" Если мы из состояния u2 пытаемся перейти по ссылки u1 (Получится "u0-u1-u2-u1"),
                // то переходим в состояние u1 что-бы получилось "u0-u1"
                if (sUrl == sPreLastUrl) {
                    asUpdatedUrlPosition = [...aUrlPosition.slice(0, -2), sUrl];
                } else if (sUrl != sLastUrl) {
                    // В остальных случаях, если ссылка не равна последей, то добавляем запись в конец
                    asUpdatedUrlPosition = [...aUrlPosition, sUrl];
                }

            }

            if (asUpdatedUrlPosition?.length) {
                sessionStorage.setItem(this.sCurrentSession, JSON.stringify(asUpdatedUrlPosition));
            }

            if (!sStateSession) {
                window.history.replaceState({
                    current_session: this.sCurrentSession,
                }, '');
            }

        }
    }

    fSetSession(sSessionName: string) {
        navigator.serviceWorker.ready.then((registration) => {
            registration.active.postMessage({
                method: 'fChangeCurrentSession',
                session: sSessionName
            });
        });
        sessionStorage.setItem('current_session', sSessionName);
    }

    /** Смена текущей сессии истории */
    fChangeCurrentSession(sSessionName, event) {

        sessionStorage.setItem('cart', '[]');
        if(sSessionName === this.sCurrentSession) {
            this.fClearSession();
        } else {
            this.fSetSession(sSessionName);
        }
        const aUrlPosition = JSON.parse(sessionStorage.getItem(sSessionName));
        if(aUrlPosition.length) {
            event.preventDefault();
        }
        const sNewLocation = aUrlPosition.pop();
        sessionStorage.setItem(sSessionName, JSON.stringify(aUrlPosition));
        window.location.href = sNewLocation;
    }

    /** Очистка сессии */
    fClearSession() {
        sessionStorage.setItem(this.sCurrentSession, JSON.stringify([]))
    }
};

export const gAppNavigatorS = new AppNavigatorS();
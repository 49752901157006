/** Получить значение атрибута для ссылок */
export function mGetTargetLink(): string {
    const aCookies = document.cookie
        .replaceAll(' ', '')
        .split(';');

    const isApp = aCookies.includes('isApp=true') ? true : false;

    let sTargetLink = '_blank';

    if (isApp) {
        sTargetLink = '_self';
    }

    return sTargetLink;
}
import Component from 'vue-class-component';
import P63Vue from '@/system/P63Vue';
import { Prop } from 'vue-property-decorator';
import partnerOrg from '@/assets/images/home/parter1.webp';
import partnerOrgAvatar from '@/assets/images/home/org.webp';
import partnerProvider from '@/assets/images/home/partner2.webp';
import partnerProviderAvatar from '@/assets/images/home/provider.webp';
import HomeBtn from '@/pages/home/el/home-btn/home-btn';
import { HomeCtrl, initHomeCtrl } from '@/pages/home/view/ctrl-home';
import { mIsClient } from '@/common/helpers/ContextH';

@Component({
    components: { HomeBtn },
})
export default class PartnersBanner extends P63Vue {
    /** Тип кнопки */
    @Prop({ type: String, default: 'primary' }) sType: 'primary' | 'secondary';

    get banner() {
        return { org: partnerOrg, provider: partnerProvider };
    }

    get avatar() {
        return { org: partnerOrgAvatar, provider: partnerProviderAvatar };
    }

    /** Мобильное ли устройство */
    get isMobile(): boolean {
        return this.ctrl.status.is_mobile;
    }

    /** Хранилище */
    get ctrl(): HomeCtrl {
        return initHomeCtrl(mIsClient() ? this.$context : this.$ssrContext);
    }
}

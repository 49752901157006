import * as conf from '@/configs/config.public';
import { QuerySys } from '@63pokupki/front';
import { PageCtrl } from './PageCtrl';
import { gfInitContextQuery } from './ContextSys';
import { SearchPageR } from '@/ifc/core/SearchPageR';
import { mIsClient } from '@/common/helpers/ContextH';

// Search Store
export class SearchStoreI {
  toJSON() {
    return { ...this }; // here I make a POJO's copy of the class instance
  }

  /** Модели */
  /** Результат поиска */
  search_data: SearchPageR.autocomplete.ResponseI = {
    list_suggestion_landing: [],
    list_item: [],
    list_suggestion: [],
    list_suggestion_brand: [],
    list_org: [],
    list_suggestion_active_purchase_name: [],
    list_correction: [],
    list_correction_landing: [],
    list_correction_brand: []
  };

  /** Параметры запроса поиска */
  search__request: SearchPageR.autocomplete.RequestI = {
    text: '',
  };

  /** Статусы */
  /** Информация о загрузке данных */
  is_loading: boolean = false;

  /** Флаг об открытии подсказки поиска */
  is_hints_open: boolean = false;
}

/** System для работы c Search */
export class SearchSys {
  public conf = conf;

  public search: SearchStoreI = null;

  private queryClientSys: QuerySys = null;
  private queryMsgSys: QuerySys = null;

  constructor(ctrl: PageCtrl) {
    // Конфигурация запроса на core
    this.queryClientSys = new QuerySys();
    this.queryClientSys.fConfig(gfInitContextQuery(ctrl, conf.coreApi));

    this.queryMsgSys = new QuerySys();
    this.queryMsgSys.fConfig(gfInitContextQuery(ctrl, conf.msgAPI));

    let optionVuex = null;
    if (mIsClient()) {
      optionVuex = {
        preserveState: true, // сохранять состояние на сервере
      };
    }

    this.search = ctrl.vuexSys.registerModuleCustomKey(new SearchStoreI(), 'search_sys', optionVuex);
  }

  /** Получить список подсказок */
  public fGetSearchInfo() {
    this.search.is_loading = true;

    this.queryClientSys.fInit();

    this.queryClientSys.fActionOk((data: SearchPageR.autocomplete.ResponseI) => {
      this.search.search_data = data;

      this.search.is_loading = false;

      this.search.is_hints_open =
        data.list_suggestion?.length > 0 ||
        data.list_suggestion_landing?.length > 0 ||
        data.list_suggestion_brand?.length > 0 ||
        data.list_suggestion_active_purchase_name?.length > 0 ||
        data.list_org?.length > 0;
    });

    this.queryClientSys.fActionErr((e: Record<string, string>) => {
      console.log('Подсказки для поиска не были загружены =(');
    });

    this.queryClientSys.fSend(SearchPageR.autocomplete.route, this.search.search__request);
  }
}

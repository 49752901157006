import P63Vue from '@/system/P63Vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { gJivoSys } from '@/system/JivoSys';


@Component
export default class OnlineAssistant extends P63Vue {
    /** Статус: является ли устройство мобильным */
    @Prop({ type: Boolean, default: false }) isMobile: boolean;
    /** Cтатус: является ли приложением */
    @Prop({ type: Boolean}) isApp: boolean;
    /** Поднимать ли дополнительно на странице элемент Онлайн Ассистента */
    @Prop({ type: Boolean}) isTopOnlineAssistant: boolean;

    /** Статус: заблокирована ли кнопка-заглушка */
    bStubButtonBlock: boolean = false;

    /** Статус отображения кнопки-заглушки */
    get bStubButtonDisplay() {
        return gJivoSys.status.is_stub_button_shown;
    }

    /** Заблокировать заглушку, а затем загрузить и открыть виджет */
    fBlockStumpThenLoadAndOpenWidget() {
        this.bStubButtonBlock = true;

        gJivoSys.fAddLoadingScript();
        gJivoSys.fUpdateAndOpenWidgetWhenItLoad();
    }
}

import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import P63Vue from '@/system/P63Vue';
import { PageCtrl } from '@/system/PageCtrl';
import { gfInitContextCtrl } from '@/system/ContextSys';
import { ItemOfCollectionI } from '@/ifc/core/CommonI/ItemCollectionI';
import { mIsClient } from '@/common/helpers/ContextH';
import { gLinkS } from '@/common/service/LinkS';
import { gImageS } from '@/common/service/ImageS';
import { mPluralize, mPeriodName } from '@/common/helpers/TextFormatterH';

import button_favorite from '@/ds/el/button_favorite/button_favorite';
import tooltip from '@/ds/el/tooltip/tooltip';
import dayjs from 'dayjs';

@Component({
    components: {
        ButtonFavorite: button_favorite,
        Tooltip: tooltip,
    },
})
export default class ItemCard extends P63Vue {
    @Prop({ type: Object, required: false }) vItem: ItemOfCollectionI; // Товар
    @Prop({ type: Object, required: false }) vPromotion: {name: string, background: string}; // Акция
    @Prop({ type: Number, required: false }) nIndex: number; // Индекс товара
    @Prop({ type: Boolean, required: false }) bPlug: boolean; // Является ли карточка заглушкой
    /** Нужно ли загружать изображение карточки синхронно */
    @Prop({ type: Boolean, required: false }) bSync: boolean;
    @Prop({ type: Boolean, default: true }) isFavorite: boolean; // Показывать ли кнопку избранного

    /** Хранилище */
    get ctrl(): PageCtrl {
        return gfInitContextCtrl(mIsClient() ? this.$context : this.$ssrContext, PageCtrl);
    }

    /** Статус авторизации пользователя */
    get bIsAuth() {
        return this.ctrl.userSys.user.isAuth;
    }

    /** Товары, добавленные в список желаний */
    get ixWishItems() {
        return this.ctrl.wishSys.wish.wish_items;
    }

    /**
     * Показать старую цену, если разница между ней и актуальной ценой
     * больше установленной минимальной разницы
     */
    get bShowOldPrice() {
        const { old_price, price } = this.vItem;
        let nDiffPrice: number = 0;

        if (old_price && price) {
            nDiffPrice = Math.round(((old_price - price) / old_price) * 100);
        }

        return nDiffPrice >= 10;
    }

    /** Добавить товар в список желаний, если пользователь залогинен */
    fAddToWishListIfLoggedIn(idItem: number): void {
        if (this.bIsAuth) {
            this.ctrl.wishSys.faAddToWishList(idItem);
        } else {
            this.ctrl.status.is_modal_user_not_sign_in = true;
        }
    }

    /** Обработать нажатие на карточку */
    fOnClick(item: ItemOfCollectionI, i: number, callbackUrl: string): void {
        this.$emit('card-click', { item, i, callbackUrl });
    }

    /** Получить URL картинки товара в закупке */
    fGetItemImgUrl(itemFileName: string): string {
        return gImageS.fItemImageUrlx256(itemFileName);
    }

    /** Получить URL товара */
    fGetItemUrl(item_code: string, item_name: string): string {
        return gLinkS.fItemUrl(item_code, item_name);
    }

    /** Подобрать правильную форму для слова «раз» */
    fGetPluralizedOneWord(number: number): string {
        return mPluralize(number, ['раз', 'раза', 'раз']);
    }

    /** Корректное именование времени */
    fGetCorrectPeriodName(sFirstOrderDate: string) {
        const nCurrentTime = dayjs().valueOf() //текущее
        const nFirstOrderTime = dayjs(sFirstOrderDate?.replace(/-/g,'/')).valueOf()
        const nTotalDays = Math.floor((nCurrentTime - nFirstOrderTime) / (1000 * 3600 * 24))
        const nReturnDays = nTotalDays ? mPeriodName(nTotalDays) : mPeriodName(1)

        return `купили за ${nReturnDays}`
    }

    /** Получить рубли у цены */
    fGetRubles(price: number): string {
        return String(price).split('.')[0];
    }

    /** Получить копейки у цены */
    fGetPenny(price: number): string {
        const penny = String(price.toFixed(2)).split('.')[1];

        return penny === '00' ? '' : penny;
    }
}

import Component from 'vue-class-component';
import { gfInitContextCtrl } from '@/system/ContextSys';
import { PageCtrl } from '@/system/PageCtrl';
import { Prop } from 'vue-property-decorator';

import alignJustify from '@/assets/images/home/menu-management/align-justify.svg';
import bell from '@/assets/images/home/menu-management/bell.svg';
import book from '@/assets/images/home/menu-management/book.svg';
import boxes from '@/assets/images/home/menu-management/boxes.svg';
import chalkboardTeacher from '@/assets/images/home/menu-management/chalkboard-teacher.svg';
import chartLine from '@/assets/images/home/menu-management/chart-line.svg';
import comments from '@/assets/images/home/menu-management/comments.svg';
import dolly from '@/assets/images/home/menu-management/dolly.svg';
import fileAlt from '@/assets/images/home/menu-management/file-alt.svg';
import fileInvoiceDollar from '@/assets/images/home/menu-management/file-invoice-dollar.svg';
import handshake from '@/assets/images/home/menu-management/handshake.svg';
import link from '@/assets/images/home/menu-management/link.svg';
import moneyCheckAlt from '@/assets/images/home/menu-management/money-check-alt.svg';
import rubleSign from '@/assets/images/home/menu-management/ruble-sign.svg';
import shekelSign from '@/assets/images/home/menu-management/shekel-sign.svg';
import shoppingCart from '@/assets/images/home/menu-management/shopping-cart.svg';
import tags from '@/assets/images/home/menu-management/tags.svg';
import truckLoading from '@/assets/images/home/menu-management/truck-loading.svg';
import undo from '@/assets/images/home/menu-management/undo.svg';
import usersCog from '@/assets/images/home/menu-management/users-cog.svg';
import userLock from '@/assets/images/home/menu-management/user-lock.svg';

import userTag from '@/assets/images/home/menu-management/user-tag.svg';
import envelope from '@/assets/images/home/menu-management/envelope.svg';
import newspaper from '@/assets/images/home/menu-management/newspaper.svg';
import envelopeOpen from '@/assets/images/home/menu-management/envelope-open.svg';
import exclamationTriangle from '@/assets/images/home/menu-management/exclamation-triangle.svg';
import listAlt from '@/assets/images/home/menu-management/list-alt.svg';
import tired from '@/assets/images/home/menu-management/tired.svg';

import infoCircle from '@/assets/images/home/menu-management/info-circle.svg';
import clipboardList from '@/assets/images/home/menu-management/clipboard-list.svg';
import balanceScale from '@/assets/images/home/menu-management/balance-scale.svg';
import search from '@/assets/images/home/menu-management/search.svg';
import percent from '@/assets/images/home/menu-management/percent.svg';

import checkAlt from '@/assets/images/home/menu-management/check-alt.svg';
import wallet from '@/assets/images/home/menu-management/wallet.svg';
import creditCard from '@/assets/images/home/menu-management/credit-card.svg';

import images from '@/assets/images/home/menu-management/images.svg';
import vk from '@/assets/images/home/menu-management/vk.svg';
import columns from '@/assets/images/home/menu-management/columns.svg';
import stream from '@/assets/images/home/menu-management/stream.svg';
import city from '@/assets/images/home/menu-management/city.svg';
import trademark from '@/assets/images/home/menu-management/trademark.svg';
import externalLinkAlt from '@/assets/images/home/menu-management/external-link-alt.svg';

import P63Vue from '@/system/P63Vue';
import { mIsClient } from '@/common/helpers/ContextH';
import { SavedMenuLinkI } from '@/ifc/core/CommonSQL/SavedMenuLinkE';

@Component({})
export default class NewMenuManagement extends P63Vue {
    // Пропсы
    @Prop({ type: Array, default: () => [] }) aOrgLink: SavedMenuLinkI[];
    @Prop({ type: Number, default: 0 }) nOrgNotificationCounter: number;
    @Prop({ type: Number, default: 0 }) nNewRefundRequestCounter: number;
    @Prop({ type: Number, default: 0 }) nOverpaymentCounter: number;

    /** Открыто ли меню */
    isMenuShow = false;

    /** Соотношение link.url: icon */
    ixCollectionIcons = {
        '/org/vi/#': handshake,
        '/org/stocks/#': dolly,
        '/org/purchase': shoppingCart,
        '/org/org-account': fileInvoiceDollar,
        '/org-front/org-cabinet': shoppingCart,
        '/org/commercial': shekelSign,
        '/org/tags': tags,
        '/org/shippers-query': truckLoading,
        '/org/appeal': alignJustify,
        '/org/document': book,
        '/org/org-balance': fileInvoiceDollar,
        '/org/utm-generator': chalkboardTeacher,
        '/org/expense#/': rubleSign,
        '/org/general-report': fileAlt,
        '/org/system-notification/': bell,
        '/org/unpaid-orders-report/': bell,
        '/org/one-organization': fileInvoiceDollar,
        '/org/pristroy-report': fileAlt,
        '/org/my-discussion': comments,
        '/org/overpayment-report': moneyCheckAlt,
        '/org/overage-report': boxes,
        '/org/edit-product': link,
        '/org/refund': undo,
        '/org/stat': chartLine,
        '/admin/user': usersCog,
        '/admin/block-list': userLock,

        '/admin/role-model': userTag,
        '/admin/pvz-messages': envelope,
        '/admin/news': newspaper,
        '/admin/mailing': envelopeOpen,
        '/admin/vi/#/': handshake,
        '/admin/search-error': exclamationTriangle,
        '/admin/text-templates': listAlt,
        '/admin/claims': tired,

        '/admin/onboarding': infoCircle,
        '/admin/vendor-base/#': clipboardList,
        '/admin/vendor-management': clipboardList,
        '/admin/search-query': balanceScale,
        '/admin/characteristic-moderation': search,
        '/admin/promotions': percent,

        '/buh/payment-report': checkAlt,
        '/buh/payment-report-new': checkAlt,
        '/buh/refund': wallet,
        '/buh/refund-new': wallet,
        '/buh/buh-organizations': fileInvoiceDollar,
        '/buh/extra-payment-pvz': fileInvoiceDollar,
        '/buh/pvz-account': creditCard,
        '/buh/statements': fileInvoiceDollar,
        '/buh/invoice-payment-requests#/': clipboardList,

        '/admin/tags': tags,
        '/admin/seo': search,
        '/admin/top-sales': clipboardList,
        '/admin/places-banners': images,
        '/admin/social-adverts': vk,
        '/admin/landings': columns,
        '/admin/catalogs': stream,
        '/admin/regional-pages': city,
        '/admin/tags-moderation': tags,
        '/admin/brand-monitoring': trademark,
        '/admin/mail-constructor': envelope,
        '/admin/blog': envelope,
        '/admin/bonus-action': envelope,
        '/admin/linking': externalLinkAlt       
    };

    /** Переключение меню */
    fMenuToggle() {
        this.isMenuShow = !this.isMenuShow;
        this.fChangeBodyScroll();
    }

    /** Меняем возможность прокрутки body в зависимости от статуса открытия меню панели */
    fChangeBodyScroll() {
        if (this.isMenuShow) {
            document.body.classList.add('disable-scroll');
        } else {
            document.body.classList.remove('disable-scroll');
        }
    }

    /** Открыт ли сайт с приложения */
    get isApp() {
        return this.ctrl.isApp;
    }

    /** Информация о пользователе */
    get vUserInfo() {
        return this.ctrl.userSys.user;
    }

    /** Список избранных ссылок без системных уведомлений */
    get aOrgLinkFiltered() {
        return this.aOrgLink.filter((link) => link.url !== '/org/system-notification/');
    }

    // Основные
    get ctrl(): PageCtrl {
        return gfInitContextCtrl(mIsClient() ? this.$context : this.$ssrContext, PageCtrl);
    }
}

import { QuerySys } from '@63pokupki/front';
import * as conf from '@/configs/config.public';
import { PageCtrl } from './PageCtrl';
import { gfInitContextQuery } from './ContextSys';
import { MenuR } from '@/ifc/core/MenuR';
import { MenuItemCompactI } from '@/ifc/core/EntitySQL/MenuItemE';
import { mIsClient } from '@/common/helpers/ContextH';
import { LandingMenuI } from '@/ifc/core/EntitySQL/P63SearchLandingInfoE';
import { mGroupBy, mKeyBy } from '@/common/helpers/ArrayH';
import { CategoryCounterI, CategoryIDsI } from '@/ifc/core/EntitySQL/CategoryE';
import { mCyrillicToTranslitCleared } from '@/common/helpers/CyrillicToTranslitH';
import { SubscriptionEmailR } from '@/ifc/msg/SubscriptionEmailR';
import { BannerI } from '@/ifc/core/CommonI/BannerI';

// Types
export interface MenuItemCompactIndexed extends MenuItemCompactI {
    chd?: { [key: number]: MenuItemCompactIndexed };
}

// Store
class MenuStoreI {
    toJSON() {
        return { ...this }; // here I make a POJO's copy of the class instance
    }

    /** Лендинги (3 уровень), сгруппированные по id родителя */
    ixLanding: Record<number, LandingMenuI[]> = {};
    /** Категории, скгуппированные по parent_id */
    ixCategoriesGrouped: Record<number, CategoryIDsI[]> = {};
    /** Индексированный список категорий по id */
    ixCategoriesIndexed: Record<number, CategoryIDsI> = null;
    /** Индексированный список категорий по id с количеством товаров */
    ixCategoryOrderCount: Record<number, CategoryCounterI> = null;
    /** Список баннеров для главного меню */
    avBanner: BannerI[] = [];
}

// Sys
export class MenuSys {
    public menu: MenuStoreI = null;

    public queryCoreSys: QuerySys = null;
    public queryMsgSys: QuerySys = null;

    constructor(ctrl: PageCtrl) {
        // Конфигурация запроса на core
        this.queryCoreSys = new QuerySys();
        this.queryMsgSys = new QuerySys();
        this.queryCoreSys.fConfig(gfInitContextQuery(ctrl, conf.coreApi));
        this.queryMsgSys.fConfig(gfInitContextQuery(ctrl, conf.msgAPI));

        let optionVuex = null;
        if (mIsClient()) {
            optionVuex = {
                preserveState: true, // сохранять состояние на сервере
            };
        }

        this.menu = ctrl.vuexSys.registerModuleCustomKey(new MenuStoreI(), 'menu_sys', optionVuex);
    }

    /** Инициализация */
    async faInit() {
        this.queryCoreSys.fInit();
        this.queryCoreSys.fActionOk((data: MenuR.getCompactCategoryMenu.ResponseI) => {
            const ixCategories: Record<number, CategoryIDsI[]> = mGroupBy(
                data.list_category,
                'parent_id'
            );
            this.menu.ixCategoriesIndexed = mKeyBy(data.list_category, 'category_id');
            this.menu.ixCategoryOrderCount = data.ix_category_order_count;
            this.menu.avBanner = data.list_banner;

            for (const category in ixCategories) {
                ixCategories[category] = ixCategories[category].sort(
                    (a, b) => a.category_order - b.category_order
                );
            }

            this.menu.ixCategoriesGrouped = ixCategories;
        });
        this.queryCoreSys.fActionErr((err: any) => {});
        let request: MenuR.getMenuCompact.RequestI = {};
        await this.queryCoreSys.faSend(MenuR.getCompactCategoryMenu.route, request);
    }

    /** Получить лендинги по родительской категории */
    async faGetLandingsByParentId(param: MenuR.getCategorySubMenu.RequestI) {
        this.queryCoreSys.fInit();
        this.queryCoreSys.fActionOk((data: MenuR.getCategorySubMenu.ResponseI) => {
            const ixLoadedLandings = mGroupBy(data.list_landing, 'category_id');
            this.menu.ixLanding = { ...this.menu.ixLanding, ...ixLoadedLandings };
        });
        this.queryCoreSys.fActionErr((err: any) => {});
        await this.queryCoreSys.faSend(MenuR.getCategorySubMenu.route, param);
    }

    /** Получить ссылку на страницу категорий закупок */
    public fGetPurchaseCategoryUrl(category_id: number) {
        const ixCategories = this.menu.ixCategoriesIndexed;
        const vCategory: CategoryIDsI = ixCategories[category_id];
        let sUrl: string = '/purchases';
        if (ixCategories[category_id]) {
            if (ixCategories[category_id].parent_id) {
                const vParentCategory: CategoryIDsI =
                    ixCategories[ixCategories[category_id].parent_id];
                sUrl += `/${mCyrillicToTranslitCleared(vParentCategory.category_name)}-${
                    vParentCategory.category_id
                }`;
            }
            sUrl += `/${mCyrillicToTranslitCleared(vCategory.category_name)}-${
                vCategory.category_id
            }`;
        }
        return sUrl;
    }

    /** Получить ссылку на страницу категорий товаров */
    public fGetItemCategoryUrl(category_id: number) {
        const ixCategories = this.menu.ixCategoriesIndexed;
        const vCategory: CategoryIDsI = ixCategories[category_id];
        let sUrl: string = '/category';
        if (ixCategories[category_id]) {
            if (ixCategories[category_id].parent_id) {
                const vParentCategory: CategoryIDsI =
                    ixCategories[ixCategories[category_id].parent_id];
                sUrl += `/${mCyrillicToTranslitCleared(vParentCategory.category_name)}-${
                    vParentCategory.category_id
                }`;
            }
            sUrl += `/${mCyrillicToTranslitCleared(vCategory.category_name)}-${
                vCategory.category_id
            }`;
        }
        return sUrl;
    }

    /** Получить ссылку на страницу категорий товаров */
    public fCheckEmailAddress(param: SubscriptionEmailR.getUserEmail.RequestI, cb: Function) {
        this.queryMsgSys.fInit();
        this.queryMsgSys.fActionOk((data: SubscriptionEmailR.getUserEmail.ResponseI) => {
            cb(data.email);
        });
        this.queryMsgSys.fActionErr((err: any) => {
            cb(null, err);
        });
        this.queryMsgSys.faSend(SubscriptionEmailR.getUserEmail.route, param);
    }

    /** Получить ссылку на страницу категорий товаров */
    public fSendEmailAddress(param: SubscriptionEmailR.subscribeAndChangeMail.RequestI) {
        this.queryMsgSys.fInit();
        this.queryMsgSys.fActionOk(
            (data: SubscriptionEmailR.subscribeAndChangeMail.ResponseI) => {}
        );
        this.queryMsgSys.fActionErr((err: any) => {});
        this.queryMsgSys.faSend(SubscriptionEmailR.subscribeAndChangeMail.route, param);
    }
}

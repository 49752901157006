import Component from 'vue-class-component';
import P63Vue from '@/system/P63Vue';
import { mIsClient } from '@/common/helpers/ContextH';
import { HomeCtrl, initHomeCtrl } from '@/pages/home/view/ctrl-home';
import SectionTab from '@/pages/home/el/section-tab/section-tab';
import HomeBtn from '@/pages/home/el/home-btn/home-btn';
import { ItemOfCollectionHomePageI, ProductOfCollectionHomePageI } from '@/ifc/core/CommonI/ItemCollectionI';
import SwipeCard from '@/common/el/SwipeCard/SwipeCard';

import { mFormatPriceSeparatorNoCoins } from '@/common/helpers/PriceFormatterH';
import ImageSwitch from '@/ds/el/image_switch/image_switch';
import { gImageS } from '@/common/service/ImageS';
import { gLinkS } from '@/common/service/LinkS';
import { Prop } from 'vue-property-decorator';
import { S3ItemImgIDsI } from '@/ifc/msg/EntitySpSQL/S3ItemImgE';
import { ImageSwitchI } from '@/ds/el/image_switch/ifc_image_switch';
import ArrowButton from '../arrow-button/arrow-button.vue';

// Высота, которую занимает административное меню на мобилке
const HEIGHT_MANAGER_MENU = 41;
const WIDTH_GAP_AND_BORDER = 14;
const WIDTH_BORDER = 2;

@Component({
    components: {
        ImageSwitch,
        SwipeCard,
        SectionTab,
        HomeBtn,
        ArrowButton,
    },
})
export default class SalesHits extends P63Vue {
    /** Показывано ли административное меню */
    @Prop({ type: Boolean, default: false }) isShowManagerMenu: boolean;
    /** IX collection_id: {tabName, icon} */
    ixCollectionName: Record<number, { name: string; icon: string }> = {
        1: { name: 'Автомобиль', icon: 'automobile' },
        2: { name: 'Дом', icon: 'house' },
        3: { name: 'Сад', icon: 'plant' },
        4: { name: 'Детям', icon: 'child' },
        5: { name: 'Женщинам', icon: 'woman' },
        6: { name: 'Мужчинам', icon: 'men' },
        7: { name: 'Аксессуары', icon: 'handbag' },
        8: { name: 'Животным', icon: 'trail' },
        9: { name: 'Косметика', icon: 'flacon' },
        10: { name: 'Спорт и отдых', icon: 'ball' },
        11: { name: 'Электроника', icon: 'phone' },
        12: { name: 'Продукты', icon: 'cake' },
        13: { name: 'Канцтовары', icon: 'book' },
        14: { name: 'Досуг и увлечения', icon: 'palette' },
        15: { name: 'Подарки и сувениры', icon: 'gift' },
        16: { name: 'Товары для детей', icon: 'toy' },
    };

    /** Плейсхолдер для изображений */
    noImgUrl = require('@/assets/images/tg_cat_image.png');

    /** Индекс текущего изображения */
    idCurrentImg: number = 0;

    /** avCurrentCollection */
    avCurrentCollection: (ProductOfCollectionHomePageI | ItemOfCollectionHomePageI)[] = [];

    /** ID текущей категории */
    idCurrentCategory: number = 0;

    /** Менялось ли таб */
    isChangedTab: boolean = false;

    /** Нажималась ли кнопка "Показать еще" */
    isClickLoadMore: boolean = false;

    /** Выполняется ли сейчас запрос */
    isRequestTime: boolean = false;

    /** Минимальное количество показываемых товаров/продуктов */
    nMinItemsCount: number = this.nUserRating < 1 ? 10 : 12;

    /** Показать стрелку назад */
    isShowLeftArrow: boolean = false;

    /** Показать стрелку вперед */
    isShowRightArrow: boolean = false;

    /** Устройство, для которого показываются стрелки */
    isShowArrowDevice: boolean = false;

    $refs: {
        scrollElement: HTMLElement; //элемент с прокруткой
    };

    /** Получить ссылку на продукт/товар */
    fGetItemHref(vItem) {
        let sHref = '';
        const sName = vItem?.name;
        if (vItem.product_id) {
            sHref = gLinkS.fProductUrl(vItem?.product_id, sName);
        } else {
            sHref = gLinkS.fItemUrl(vItem?.item_code, sName);
        }
        return sHref;
    }

    /** Переключение по табам */
    async fChangeTab(id: number) {
        if (id === 0) {
            // если это рекомендации
            this.avCurrentCollection = this.ctrl.list.recommendations;
        } else {
            // иначе коллекция
            //Сброс исключений и количества товаров/продуктов для предыдущей коллекции
            if (!this.ctrl.ix.collection_items[id]) {
                this.isRequestTime = true;
                await this.ctrl.faGetListItemOfCollectionNew(id);
                this.isRequestTime = false;
            }

            this.avCurrentCollection = this.ctrl.ix.collection_items[id];
        }
        this.idCurrentCategory = id;

        this.isChangedTab = true;

        /** Отправить данные в статистику о клике по табу в Хитах Продаж*/
        this.ctrl.statSys.faPushClickToStat({
            page: location.pathname,
            type: 'tab_new',
            alias: 'tab_click_sales_hits_new',
            entity_id: id,
        });
    }

    /** Загрузить новые товары коллекции/рекомендаций */
    async fHandleShowMore() {
        if (this.idCurrentCategory === 0) {
            // если это рекомендации
            this.isRequestTime = true;
            await this.ctrl.fGetRecommendations();
            this.isRequestTime = false;
            this.avCurrentCollection = this.ctrl.list.recommendations;
        } else {
            // иначе коллекция
            this.isRequestTime = true;
            await this.ctrl.faGetListItemOfCollectionNew(this.idCurrentCategory);
            this.isRequestTime = false;

            this.avCurrentCollection = this.ctrl.ix.collection_items[this.idCurrentCategory];
        }
        if (!this.isClickLoadMore) {
            this.isClickLoadMore = true;
        }

        /** Отправить данные в статистику о клике по кнопке "Показать еще" в каждой категории*/
        this.ctrl.statSys.faPushClickToStat({
            page: location.pathname,
            type: 'button_new',
            alias: 'button_click_sales_hits_show_more',
            entity_id: this.idCurrentCategory,
        });
    }

    /** Показываем ли кнопку 'Показать еще' */
    fIsEnableShowMoreBtn() {
        let result = false;
        if (this.idCurrentCategory === 0) {
            //рекомендации
            result = this.ctrl.one.recommendations_items_count.all > this.ctrl.one.recommendations_items_count.current;
        } else {
            // коллекция
            result =
                this.ctrl.ix.collection_items_count[this.idCurrentCategory]?.all >
                this.ctrl.ix.collection_items_count[this.idCurrentCategory]?.current;
        }

        return result;
    }

    /** Полуучить цену товара */
    fGetItemPrice(vItem) {
        let price = '';

        if (vItem.min_price) {
            price = mFormatPriceSeparatorNoCoins(Number(vItem.min_price * (1 + vItem.org_fee / 100)));
        } else {
            price = mFormatPriceSeparatorNoCoins(Number(vItem.price * (1 + vItem.org_fee / 100)));
        }

        return `${price} ₽`;
    }

    /** Получить URL картинки 256 */
    fGetItemImgUrl256(img) {
        let sImgSrc: string = this.noImgUrl;

        if (img.filename) {
            sImgSrc = gImageS.fItemImageUrlx256(img.filename);
        }

        return sImgSrc;
    }

    /** Получить инфо по акции */
    fGetAction(vItem): { name: string; gradient: string } {
        let idAction = 0;
        if (vItem.purchase_id) {
            idAction = this.ctrl.ix.action_by_purchase[vItem.purchase_id]?.action_id;
        } else {
            for (let i = 0; i < vItem.list_purchase.length; i++) {
                if (this.ctrl.ix.action_by_purchase[vItem.list_purchase[i]]) {
                    idAction = this.ctrl.ix.action_by_purchase[vItem.list_purchase[i]].action_id;
                    break;
                }
            }
        }

        if (idAction) {
            const vAction = this.ctrl.ix.action_info[idAction];
            return { name: vAction.alias, gradient: vAction.nameplate_color };
        }
    }

    /** Старая цена */
    fGetItemOldPrice(vItem) {
        if (vItem.min_price) {
            const price = vItem.min_price * (1 + vItem.org_fee / 100);
            if (vItem.old_price > price) {
                return `${mFormatPriceSeparatorNoCoins(vItem.old_price * (1 + vItem.org_fee / 100))} ₽`;
            }
        } else {
            const price = vItem.price * (1 + vItem.org_fee / 100);
            if (vItem.old_price > price) {
                return `${mFormatPriceSeparatorNoCoins(vItem.old_price * (1 + vItem.org_fee / 100))} ₽`;
            }
        }
    }

    /** Добавление alt для картинок */
    fAddAlt(aImages: S3ItemImgIDsI[], alt: string): ImageSwitchI.S3ItemImgIDsIWithAlt[] {
        let aImagesWithAlt: ImageSwitchI.S3ItemImgIDsIWithAlt[] = [];

        for (let i = 0; i < aImages.length; i++) {
            aImagesWithAlt[i] = aImages[i];
            aImagesWithAlt[i].alt = alt;
        }

        return aImagesWithAlt;
    }

    /** Получить данные для статистики */
    fGetStatInfo(vItem) {
        const idHitsItem = vItem.product_id ? vItem.product_id : vItem.item_id;
        const sStatType = vItem.product_id ? 'product_new' : 'item_new';

        return {
            type: sStatType,
            entity_id: idHitsItem,
        };
    }

    /** Скролл к следующему/предыдущему табу */
    fScroll(sType: 'left' | 'right') {
        const scrollElement: HTMLElement = this.$refs.scrollElement;
        /** Массив (HTMLCollection) табов */
        const avTab = scrollElement.children;

        if (sType === 'left') {
            /** Ширина крайнего левого видимого таба для прокрутки на десктопе */
            let nTabLeftWidth: number = 0;
            /** Сумма ширин табов до крайнего левого видимого */
            let nCurWidthTabsLeft: number = 0;
            // Перебираем табы для определения ширины крайнего левого видимого
            for (let i = 0; i < avTab.length; i++) {
                nCurWidthTabsLeft += avTab[i].clientWidth + WIDTH_GAP_AND_BORDER; // ширина табов с учетом gap и бордера
                if (nCurWidthTabsLeft > scrollElement.scrollLeft) {
                    nTabLeftWidth = avTab[i].clientWidth + WIDTH_BORDER;
                    break;
                }
            }
            scrollElement.scrollLeft -= nTabLeftWidth;
            // Если проскроленная невидимая зона табов меньше ширины крайнего левого видимого, проскроливаем в начало
            if (scrollElement.scrollLeft < nTabLeftWidth) {
                this.isShowLeftArrow = false;
                scrollElement.scrollLeft = 0;
            }
            this.isShowRightArrow = true;
        } else {
            /** Ширина крайнего правого видимого таба для прокрутки на десктопе */
            let nTabRightWidth: number = 0;
            /** Сумма ширин табов до крайнего правого видимого */
            let nCurWidthTabsRight: number = 0;
            // Перебираем табы для определения ширины крайнего правого видимого
            for (let i = 0; i < avTab.length; i++) {
                nCurWidthTabsRight += avTab[i].clientWidth + WIDTH_GAP_AND_BORDER; // ширина табов с учетом gap и бордера
                if (nCurWidthTabsRight > scrollElement.offsetWidth + scrollElement.scrollLeft) {
                    nTabRightWidth = avTab[i].clientWidth + WIDTH_BORDER;
                    break;
                }
            }
            // Если непроскроленная невидимая зона табов меньше ширины последнего, проскроливаем в конец
            if (
                scrollElement.scrollWidth - (scrollElement.scrollLeft + scrollElement.offsetWidth) <
                scrollElement.children[scrollElement.children.length - 1].clientWidth + WIDTH_GAP_AND_BORDER
            ) {
                this.isShowRightArrow = false;
                scrollElement.scrollLeft = scrollElement.scrollWidth - scrollElement.offsetWidth;
            }
            scrollElement.scrollLeft += nTabRightWidth;
            this.isShowLeftArrow = true;
        }
    }

    /** Следим за скроллом блока, чтобы скрывать/показывать кнопки переключения */
    fWatchScroll(e) {
        const scrollElement: HTMLElement = this.$refs.scrollElement;

        if (scrollElement) {
            const currentScrollWidth = scrollElement.scrollWidth - scrollElement.offsetWidth;
            if (e.target.scrollLeft === 0) {
                this.isShowLeftArrow = false;
                this.isShowRightArrow = true;
            } else {
                this.isShowLeftArrow = true;
            }
            if (Math.round(e.target.scrollLeft) === currentScrollWidth) {
                this.isShowLeftArrow = true;
                this.isShowRightArrow = false;
            } else {
                this.isShowRightArrow = true;
            }
        }
    }

    // Логика на стороне сервера
    serverPrefetch() {
        this.avCurrentCollection = this.ctrl.ix.collection_items[this.idCurrentCategory];
    }

    mounted() {
        this.isShowArrowDevice = window.innerWidth > 1023;
        this.ctrl.status.is_client = true;
        // По дефолту показываем рекомендации, если их нужное количество либо первую коллекцию
        this.idCurrentCategory = this.isShowRecommendations ? 0 : this.ctrl.status.id_first_collection;

        const scrollElement: HTMLElement = this.$refs.scrollElement;
        // Показываем стрелку справа, если табов больше, чем ширина контейнера
        if (scrollElement.scrollWidth > scrollElement.offsetWidth) {
            this.isShowRightArrow = true;
        }
        if (scrollElement && this.isShowArrowDevice) {
            scrollElement.addEventListener('scroll', this.fWatchScroll);
        }

        // Количество показываемых элементов
        this.nMinItemsCount = this.nUserRating < 1 ? 10 : 12;
    }

    destroyed() {
        const scrollElement: HTMLElement = this.$refs.scrollElement;
        if (scrollElement && this.isDesktop) {
            scrollElement.removeEventListener('scroll', this.fWatchScroll);
        }
    }

    /** Являестся ли устройство десктопом */
    get isDesktop(): boolean {
        return this.ctrl.status.is_desktop;
    }

    /** Мобильное ли устройство */
    get isMobile(): boolean {
        return this.ctrl.status.is_mobile;
    }

    /** Список коллекция для табов */
    get vCollections() {
        return this.ctrl.list.collection;
    }

    /** Рейтинг пользователя */
    get nUserRating() {
        return this.ctrl.userSys.user.isAuth ? this.ctrl.userSys.user.info.consumer_rating : -1;
    }

    /** Показываем ли рекомендации */
    get isShowRecommendations() {
        return this.ctrl.list.recommendations?.length >= this.nMinItemsCount;
    }

    /** Текущая колллекция или рекомендации */
    get aCurrentCollection() {
        let collection = [];
        if (this.ctrl.status.is_client && (this.isChangedTab || this.isClickLoadMore)) {
            // если юзер уже переключался по табу то показываем последние подгруженные товары/продукты
            collection = this.avCurrentCollection;
        } else {
            // ели это первая отрисовка, то показываем рекмендации или первую коллекцию из инит запроса
            collection = this.isShowRecommendations ? this.ctrl.list.recommendations : this.ctrl.list.collection_items;
        }
        return collection;
    }

    /** Позиция фиксации слайдера категорий при скролле страницы */
    get nPositionTop() {
        let nPositionTop = 0;
        if (this.isShowManagerMenu && !this.isApp) {
            nPositionTop = HEIGHT_MANAGER_MENU;
        }
        return nPositionTop;
    }

    /** Открыт ли сайт с приложения */
    get isApp() {
        return this.ctrl.isApp;
    }

    /** Хранилище */
    get ctrl(): HomeCtrl {
        return initHomeCtrl(mIsClient() ? this.$context : this.$ssrContext);
    }
}

import { mIsClient } from '@/common/helpers/ContextH';
import { gfInitContextCtrl } from '@/system/ContextSys';
import P63Vue from '@/system/P63Vue';
import { PageCtrl } from '@/system/PageCtrl';
import Component from 'vue-class-component';


@Component
export default class WarningMessage extends P63Vue {

    /** Показано ли меню со ссылками */
    get isUserMenuList() {
        return this.vUserInfo.isOrg || this.vUserInfo.isAdmin || this.vUserInfo.isModerator || this.vUserInfo.isBuh
    }

    get vUserInfo() {
        return this.ctrl.userSys.user;
    }

    get ctrl(): PageCtrl {
        return gfInitContextCtrl(mIsClient() ? this.$context : this.$ssrContext, PageCtrl);
    }
}

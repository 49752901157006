import Component from 'vue-class-component';
import P63Vue from '@/system/P63Vue';
import { HomeCtrl, initHomeCtrl } from '../../view/ctrl-home';
import { mIsClient } from '@/common/helpers/ContextH';
import { Prop } from 'vue-property-decorator';

@Component
export default class SectionTab extends P63Vue {
    @Prop({ type: String }) sTabText: string;
    @Prop({ type: String }) sIconName: string;
    @Prop({ type: Boolean }) isActive: boolean;
    
    fHandleClick() {
        this.$emit('handleClick');
    }

    /** Путь к спрайту с иконками */
    get sSpritePath() {
        return this.ctrl.status.sSpritePath;
    }

    /** Хранилище */
    get ctrl(): HomeCtrl {
        return initHomeCtrl(mIsClient() ? this.$context : this.$ssrContext);
    }
}

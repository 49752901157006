import { PaginationI } from "./CommonI/PaginationI";
import { NewsInfoI } from "./EntitySQL/NewsE";
import { TagI, TagT } from "./EntitySQL/TagE";
import { PostFileImgInfoI } from './ImgSQL/PostFileImgE';

export namespace NewsNoAuthR {
    /** Получить новости (с пагинацией) */
    export namespace getNewsList {
        /** APIURL */
        export const route = '/news/no-auth/get-news-list';

        /** Параметры api запроса */
        export interface RequestI {
            page: number; // Номер страницы с новостями
            limit: number; // Количество новостей на странице
            tag_id?: number; // Тег новости
            search_title?: string; // Строка для поиска по названию новости
            only_visible?: boolean; // Показывать только видимые новости
        }

        /** Параметры api ответа */
        export interface ResponseI {
            news_list: NewsInfoI[];
            pagination: PaginationI;
            list_post_img: PostFileImgInfoI[];
        }
    }

    /** Получить список тегов */
    export namespace getTagList {
        /** APIURL */
        export const route = '/news/no-auth/get-tag-list';

        /** Параметры api запроса */
        export interface RequestI {
            type: TagT; // Тип тегов
        }

        /** Параметры api ответа */
        export interface ResponseI {
            tag_list: TagI[];
        }
    }

    /** Получить новость по id поста */
    export namespace getNewsByPostId {
        /** APIURL */
        export const route = '/news/no-auth/get-news-by-post-id';

        export interface RequestI {
            post_id: number;
        }

        export interface ResponseI {
            news: NewsInfoI;
            list_post_img: PostFileImgInfoI[];
        }
    }
}

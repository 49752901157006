var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('button',{class:{
        'ds-button': true,
        primary: _vm.sAppearance === 'primary',
        'primary-hover': _vm.sAppearance === 'primary-hover',
        outlined: _vm.sAppearance === 'outlined',
        'outlined-hover': _vm.sAppearance === 'outlined-hover',
        'icon-position-right': _vm.sIconPosition === 'right',
        'icon-position-left': _vm.sIconPosition === 'left',
        block: _vm.isBlock,
        loading: _vm.isLoading,
    },style:(_vm.vStyleCustom),attrs:{"type":_vm.sType,"aria-label":_vm.sAriaLabel,"disabled":_vm.isDisabled},on:{"click":function($event){$event.stopPropagation();return _vm.fClick.apply(null, arguments)}}},[_vm._t("default",function(){return [_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.sText))])]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <section v-if="aSeoInfo.length" class="home-about-us-bottom">
        <div
            v-if="aSeoInfo[0]"
            v-html="aSeoInfo[0]"
            :class="['home-about-us-bottom__text', { 'home-about-us-bottom__text_blur': bHidden }]"
        ></div>
        <div
            v-if="aSeoInfo[1]"
            v-html="aSeoInfo[1]"
            :class="['home-about-us-bottom__text', { 'home-about-us-bottom__text_hidden': bHidden }]"
        ></div>
        <button class="home-about-us-bottom__button" @click="bHidden = !bHidden">
            {{ bHidden ? 'Показать больше' : 'Свернуть' }}
        </button>
    </section>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import HomeAboutsUsBottomSource from './home-about-us-bottom-source';

@Component
export default class HomeAboutsUsBottom extends HomeAboutsUsBottomSource {}
</script>

<style lang="scss">
.home-about-us-bottom {
    position: relative;

    max-width: 1120px;
    margin: 0 auto;

    &__text {
        &_blur {
            overflow: hidden;

            height: 140px;

            &::after {
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;

                width: 100%;
                height: 140px;

                content: '';

                background: linear-gradient(0deg, #fff, hsla(0, 0%, 100%, 0) 105.88%);
            }
        }

        &_hidden {
            display: none;
        }

        // Скрытые пустые абзацы с неразрывным пробелом (&nbsp;)
        & > p:nth-of-type(2n) {
            display: none;
        }
    }

    &__button {
        width: 100%;
        padding: 10px 0;

        cursor: pointer;
        text-align: center;
        text-decoration: underline;

        color: #36a6f2;

        font-weight: 700;
    }
}
</style>

import { AppT } from './CH/InputE';
import { ItemActionT } from './CH/NoauthCartItemE';
import { DeviceTypeT } from './CommonI/PageVisitI';

export namespace InputR {
    /**
     * Зарегистрировать действия пользователя на странице
     */
    export namespace userAction {
        /** API URL */
        export const route = '/input/user-action';

        /**
         * Параметры api запроса
         */
        export interface RequestI {
            page: string;
            action: string;
            app: AppT;
            user_id: number;
            created_at: string;
        }

        /**
         * Параметры api ответа
         */
        export interface ResponseI {
        }

    }

    /**
     * Зарегистрировать событие входа на страницу
     */
    export namespace pageVisit {
        /** API URL */
        export const route = '/input/page-visit';

        /**
         * Параметры api запроса
         */
        export interface RequestI {
            page: string;
            user_id: number;
            noauthkey: string;
            client_id: string;
            user_ip: string;
            device_type: DeviceTypeT;
            os: string,
            browser: string,
            is_bot_or_marketing: boolean;
            created_at: string;
        }

        /**
         * Параметры api ответа
         */
        export interface ResponseI {
        }

    }

    /**
     * Зарегистрировать действие по элементу на странице
     */
    export namespace writeElementAction {
        /** API URL */
        export const route = '/input/write-element-action';

        /**
         * Параметры api запроса
         */
        export interface RequestI {
            page: string; // Страница, на которой произведено действие
            device_type?: DeviceTypeT,
            type: string; // Тип элемента, над которым произведено действие
            alias: string; // Алиас элемента, над которым произведено действие
            entity_id: number; // id элемента в бд
        }

        /**
         * Параметры api ответа
         */
        export interface ResponseI {
        }
    }

    /**
     * Зарегистрировать действие с товарами в фантомной корзине
     */
    export namespace writeNoauthCartItem {
        /** API URL */
        export const route = '/input/write-noauth-cart-item';

        /**
         * Параметры api запроса
         */
        export interface RequestI {
            list_item_id: number[]; // Cписок id товаров из фантомной корзины
            action: ItemActionT; // Действие с товаром (положили/убрали/создали с ними заказ)
            action_datetime: string; // Время, когда было совершено действие
        }

        /**
         * Параметры api ответа
         */
        export interface ResponseI {
            // Ответа нет
        }
    }
}

import { PvzI, PvzWithParametersI } from './PvzSQL/PvzE';
import { BlockInfoI } from './EntitySQL/BlacklistE';
import { OrderHistoryI } from './EntitySQL/OrderHistoryE';
import { PurchaseIDsI, PurchaseInfoI } from './EntitySQL/PurchaseE';
import { PaymentConfirmationInfoI } from './EntitySQL/PurchasePaymentFormE';
import { PurchasePaymentLogI } from './EntitySQL/PurchasePaymentLogE';
import { RefundRequestI } from './EntitySQL/RefundRequestE';
import { UserDictionaryI, UserInfoI } from './EntitySQL/UserE';
import { WishesI, WishesInfoI } from './EntitySQL/WishesE';
import { PvzAddress, RatingInfoI } from './CommonI/CartI';
import { InvoiceDataI } from './CommonI/InvoiceI';
import { PaginationI } from './CommonI/PaginationI';
import { P63OrderCharacteristicIDsI } from './EntitySQL/P63OrderCharacteristicE';
import { ItemIDsI, ItemInfoI } from './EntitySQL/ItemE';
import { P63ItemCharacteristicIDsI } from './EntitySQL/P63ItemCharacteristicE';
import { S3ItemImgIDsI } from './EntitySQL/S3ItemImgE';
import { AddressI } from './EntitySQL/AddressE';
import { P63ItemBundleIDsI } from './EntitySQL/P63ItemBundleE';
import { OrderExtendI, OrderI } from './EntitySQL/OrderE';
import { InvoiceCartI } from './EntitySQL/PurchaseUserE';
import { PurchaseDiscountI } from './EntitySQL/PurchaseDiscountE';
import { OrganizationIxI, TaxSystemT } from './EntitySQL/OrganizationE';
import { P63AvgDeliveryTimeI } from './EntitySQL/P63AvgDeliveryTimeE';
import { PostsI } from './EntitySQL/PostsE';
import { MoneyI } from './EntitySQL/MoneyE';
import { ReviewI, ReviewInfoI } from './MsgSQL/ReviewE';
import { PvzAddressI } from './PvzSQL/PvzAddressE';
import { PurchasePvzI } from './EntitySQL/PurchasePvzE';
import { StockIDsI } from './EntitySQL/StockE';
import { CityIDsI } from './EntitySQL/CityE';
import { LogOrderI } from './LogSQL/LogOrderE';
import { PostI } from './MsgSQL/PostE';
import { MarketItemI, MarketItemOwnerInfoI } from './EntitySQL/MarketItemE';
import { DeliveryRequestInfoI } from './PvzSQL/DeliveryRequestE';
import { DeliveryRequestInvoiceI } from './PvzSQL/DeliveryRequestInvoiceE';
import { DeliveryRequestSavedAddressI } from './PvzSQL/DeliveryRequestSavedAddressE';
import { TransportCompanyI } from './PvzSQL/TransportCompanyE';
import { S3TransportCompanyFileI } from './EntitySQL/S3TransportCompanyFileE';
import { P63OrderCommentI } from './EntitySQL/P63OrderCommentE';
import { OrgReviewI } from './MsgSQL/OrgReviewE';
import { P63PromoActionOrderDiscountKeyI } from './EntitySQL/P63PromoActionOrderDiscountE';
import { P63PromoActionConditionKeyPurchaseI } from './EntitySQL/P63PromoActionConditionPurchaseE';
import { P63promoActionFunctionKeyPurchaseI } from './EntitySQL/P63PromoActionFunctionPurchasseE';
import { TcFieldSettingsI } from './PvzSQL/TcFieldSettingsE';

interface OrderCountListI {
    all: number;
    before_payment: number;
    receive: number;
    rate: number;
}

type PostalAddress = Record<number, string>;

/**
 * Корзина
 */
export namespace CartR {
    export const Alias = 'Cart';

    /** Добавление комментария к заказу */
    export namespace updateOrderComment {
        /** API URL */
        export const route = '/cart/update-order-comment';

        /** Параметры api запроса */
        export interface RequestI {
            list_order_id: number[];
            order_comment: string;
        }

        /** Параметры api ответа */
        export interface ResponseI {
            ix_order_comment: Record<number, number>; // индексируемый объект id_заказа : id_комментария
        }
    }

    /** Получить историю заказа */
    export namespace getOrderHistory {
        /** API URL */
        export const route = '/cart/get-order-history';

        /** Alias действия */
        export const action = 'cart-get-order-history';

        /** Параметры api запроса */
        export interface RequestI {
            order_id: number;
            is_old_history: boolean;
        }

        /** Параметры api ответа */
        export interface ResponseI {
            history: OrderHistoryI[];
            logs: LogOrderI[];
        }
    }

    /** Изменить характеристики заказа */
    export namespace updateOrder {
        /** APIURL */
        export const route = '/cart/update-order';

        /** Alias действия */
        export const action = 'cart-update-order';

        /** Параметры api запроса */
        export interface RequestI {
            list_order_id: number[];
            item_id: number;
            item_characteristic_list: { key_id: number; val_id: number }[];
        }

        /** Параметры api ответа */
        export interface ResponseI {
            status: number;
        }
    }

    /** Удалить заказ */
    export namespace deleteOrder {
        /** API URL */
        export const route = '/cart/delete-order';

        /** Alias действия */
        export const action = 'cart-delete-order';

        /** Параметры api запроса */
        export interface RequestI {
            list_order_id: number[];
            purchase_id: number;
            list_item_id: number[];
        }

        /** Параметры api ответа */
        export interface ResponseI {
            status: number;
            list_order_by_action: P63PromoActionOrderDiscountKeyI[], // Заказы с примененными скидками
        }
    }

    /** Создать отзыв */
    export namespace createReview {
        /** APIURL */
        export const route = '/cart/create-review';

        /** Параметры api запроса */
        export interface RequestI {
            title: string; // Заголовок поста
            text: string; // Текст поста
            order_id: number; // ID заказа
            rating: number; // Оценка отзыва
            post_file_id_list?: number[]; // Список ID файлов (добавлено для привязки картинок в форумные отзывы)
        }

        /** Параметры api ответа */
        export interface ResponseI {
            post: PostI; // Пост
            review: ReviewI; // Отзыв
        }
    }

    /**
     * Получить список отзывов по всем товарам в корзине
     */
    export namespace getReviewsOnActive {
        /** API URL */
        export const route = '/cart/get-reviews-on-active';

        /** Alias действия */
        export const action = 'cart-get-reviews-on-active';

        /** Параметры api запроса */
        export interface RequestI {
            user_id?: number; // номер УЗ для корзины админа
        }

        /** Параметры api ответа */
        export interface ResponseI {
            list_reviews: ReviewInfoI[];
            list_org_reviews: OrgReviewI[];
        }
    }

    /**
     * Получить список отзывов по всем товарам в корзине
     */
    export namespace getReviewsOnArchive {
        /** API URL */
        export const route = '/cart/get-reviews-on-archive';

        /** Alias действия */
        export const action = 'cart-get-reviews-on-archive';

        /** Параметры api запроса */
        export interface RequestI {
            user_id?: number; // номер УЗ для корзины админа
        }

        /** Параметры api ответа */
        export interface ResponseI {
            list_reviews: ReviewInfoI[];
            list_org_reviews: OrgReviewI[];
        }
    }

    /** Обновление в таблице Purchase_user (убрать в архив и обратно)
     * так же включить выключить компактное отображение
     */
    export namespace updatePurchaseUser {
        /** API URL */
        export const route = '/cart/update-purchase-user';

        /** Alias действия */
        export const action = 'cart-update-purchase-user';

        /** Параметры api запроса */
        export interface RequestI {
            invoice_id: number;
            archive?: boolean;
            hide?: boolean;
        }

        /** Параметры api ответа */
        export interface ResponseI {
            status: number;
        }
    }

    /** Получить список заказов пользователя */
    export namespace getOrderList {
        export const route = '/cart/get-order-list';

        export const action = 'cart-get-order-list';

        export interface RequestI {
            limit: number;
            offset: number;
            search: string;
            archive: boolean;
            status: number[];
            user_id?: number;
            org_ids?: number[]; // фильтр по организатору
            purchase_state_id?: number; // фильтр по статусу закупки
            order_state?: number; // фильтр по статусу заказа
        }

        export interface ResponseI {
            list_invoice: InvoiceDataI[];
            user_info: {
                user_id: number;
                user_name: string;
                is_block: boolean;
                is_confirmed_email: boolean;
            };
            pvz_address: PvzAddress;
            postal_address: PostalAddress;
            rate_info: RatingInfoI[];
            topic_post: Record<number, number>; // key- topic_id, val - post_id

            // Ориентировочный срок доставки
            ix_delivery_day: Record<number, number>; // key - office_id, val - delivery day
            list_block_order_comment: number[]; // массив номеров заказов у которых заблокированы комментарии
            list_order_characteristic: P63OrderCharacteristicIDsI[]; // характеристики заказанных товаров
        }
    }

    export namespace getListActiveOrder {
        export const route = '/cart/get-list-active-order';

        export interface RequestI {
            user_id?: number;
        }

        export interface ResponseI {
            list_invoice: InvoiceCartI[];
            list_purchase: PurchaseInfoI[];
            list_order: OrderExtendI[];
            list_order_comment: P63OrderCommentI[];
            list_user_dictionary: UserDictionaryI[]
            list_order_issued_date: { order_id: number, order_changed: string }[]; // Дата выдачи выполненных заказов
            list_order_char: P63OrderCharacteristicIDsI[];
            list_item: ItemInfoI[];
            list_market_item: MarketItemI[];
            list_item_char: P63ItemCharacteristicIDsI[];
            list_item_img: S3ItemImgIDsI[];
            list_bundle: P63ItemBundleIDsI[];
            list_post_office_address: AddressI[];
            list_pvz: PvzI[];
            list_pvz_address: PvzAddressI[];
            list_pvz_parameters: PvzWithParametersI[];
            list_money: MoneyI[];
            list_discount: PurchaseDiscountI[];
            list_payment_confirmation: PaymentConfirmationInfoI[];
            list_refund_request: RefundRequestI[];
            list_rate: RatingInfoI[];
            list_active_organization: OrganizationIxI[];
            list_delivery_time: P63AvgDeliveryTimeI[];
            list_purchase_pvz: PurchasePvzI[];
            list_purchase_ready_to_pay: number[];
            list_city_region: CityIDsI[];
            list_end_date_free_storage: { invoice_id: number; start_date_storage_payment: string }[],
            list_order_market: number[]; // Список ID заказов в пристрое пользователя
            list_market_stock: number[];
            list_wish: WishesI[];
            list_oversize_payment: { order_id: number, total: number }[], // Список данных о доплате за крупногабарит
            list_order_by_action: P63PromoActionOrderDiscountKeyI[], // Заказы с примененными скидками
            list_purchase_action_condition: P63PromoActionConditionKeyPurchaseI[]; // Условия акций в закупках в которых учавствует УЗ
            list_purchase_action_function: P63promoActionFunctionKeyPurchaseI[] // Действия акций в закупках в которых учавствует УЗ
        }
    }

    export namespace getListArchiveOrder {
        export const route = '/cart/get-list-archive-order';

        export interface RequestI {
            user_id?: number; // ID пользователя - Просмотр чужой корзины
            search?: string; // Поиск тексту по заказам
            page: number; // Текущая страница
            limit: number; // Количество записей на странице
            org_id?: number; // ID организатора
            purchase_state_id?: number; // Статус закупки
            order_state?: number; // Статус заказа
        }

        export interface ResponseI {
            list_invoice: InvoiceCartI[];
            list_purchase: PurchaseInfoI[];
            list_order: OrderExtendI[];
            list_order_comment: P63OrderCommentI[];
            list_user_dictionary: UserDictionaryI[]
            list_order_char: P63OrderCharacteristicIDsI[];
            list_item: ItemInfoI[];
            list_item_char: P63ItemCharacteristicIDsI[];
            list_item_img: S3ItemImgIDsI[];
            list_bundle: P63ItemBundleIDsI[];
            list_post_office_address: AddressI[];
            list_pvz: PvzI[];
            list_pvz_address: PvzAddressI[];
            list_money: MoneyI[];
            list_discount: PurchaseDiscountI[];
            list_payment_confirmation: PaymentConfirmationInfoI[];
            list_refund_request: RefundRequestI[];
            list_rate: RatingInfoI[];
            list_active_organization: OrganizationIxI[];
            list_delivery_time: P63AvgDeliveryTimeI[];
            list_purchase_pvz: PurchasePvzI[];
            list_purchase_ready_to_pay: number[];
            list_city_region: CityIDsI[];
            list_market_stock: number[];
            list_end_date_free_storage: { invoice_id: number; start_date_storage_payment: string }[],
            list_order_market: number[]; // Список ID товаров в пристрое пользователя
            list_wish: WishesI[];
            pagination: PaginationI;
        }
    }

    /** Получить историю запросов на возврат */
    export namespace getRefundRequestHistory {
        /** APIURL */
        export const route = '/cart/get-refund-request-history';

        /** Alias действия */
        export const action = 'cart-get-refund-request-history';

        /** Параметры api запроса */
        export interface RequestI {
            purchase_id: number;
            user_id?: number; // номер УЗ для корзины админа
        }

        /** Параметры api ответа */
        export interface ResponseI {
            refund_request_list: RefundRequestI[];
        }
    }

    /** Добавить запрос на возврат */
    export namespace addRefundRequest {
        /** APIURL */
        export const route = '/cart/add-refund-request';

        /** Alias действия */
        export const action = 'cart-add-refund-request';

        /** Параметры api запроса */
        export interface RequestI {
            refund_money: number;
            refund_card: string;
            refund_reason: string;
            refund_fullname: string;
            purchase_id: number;
        }

        /** Параметры api ответа */
        export interface ResponseI {
            refund_id: number;
        }
    }

    /** Обновить данные по запросу на возврат */
    export namespace updateRefundRequest {
        /** APIURL */
        export const route = '/cart/update-refund-request';

        /** Alias действия */
        export const action = 'cart-update-refund-request';

        /** Параметры api запроса */
        export interface RequestI {
            refund_id: number; // Номер запроса на возврат
            refund_money?: number; // Сумма возврата
            refund_card?: string; // Номер карты
            refund_reason?: string; // Причина возврата
            refund_org_comment?: string; // Комментарий организатора
            refund_fullname?: string; // ФИО
            payment_id?: number; // номер платежки
        }

        /** Параметры api ответа */
        export interface ResponseI {
            update_cnt: number;
        }
    }

    /** Удалить запрос на возврат */
    export namespace deleteRefundRequest {
        /** APIURL */
        export const route = '/cart/delete-refund-request';

        /** Alias действия */
        export const action = 'cart-delete-refund-request';

        /** Параметры api запроса */
        export interface RequestI {
            refund_id: number;
        }

        /** Параметры api ответа */
        export interface ResponseI {
            delete_cnt: number;
        }
    }

    /** Оценить */
    export namespace rate {
        /** APIURL */
        export const route = '/cart/rate';

        /** Alias действия */
        export const action = 'cart-rate';

        /** Параметры api запроса */
        export interface RequestI {
            purchase_id: number; // номер закупки
            type: string; // что оцениваем 'org' | 'stock'
            value: number; // оценка
            desc: string; // комментарий оценки
            entity_id: number; // номер того что оцениваем, шаблон или организатора
        }

        /** Параметры api ответа */
        export interface ResponseI {
            rating_id: number; // номер оценки
        }
    }

    /** Оценить организатора */
    export namespace addOrgRating {
        /** APIURL */
        export const route = '/cart/add-org-rating';

        /** Параметры api запроса */
        export interface RequestI {
            org_user_id: number; // id организатора
            invoice_id: number; // id инвойса
            purchase_id: number; // id закупки
            stock_id: number; // id шаблона
            rating_value: number; // оценка организатора
        }

        /** Параметры api ответа */
        export interface ResponseI {
            org_review_id: number; // id оценки
        }
    }

    /** Получить оценку организатора пользователем по закупке */
    export namespace getOrgRating {
        /** APIURL */
        export const route = '/cart/get-org-rating';

        /** Параметры api запроса */
        export interface RequestI {
            user_id: number; // id пользователя
            purchase_id: number; // id закупки
        }

        /** Параметры api ответа */
        export interface ResponseI {
            rating_value: number; // оценка организатора
        }
    }

    /** Получить среднюю оценку организатора */
    export namespace getAvgOrgRating {
        /** APIURL */
        export const route = '/cart/get-avg-org-rating';

        /** Параметры api запроса */
        export interface RequestI {
            org_user_id: number; // id организатора
        }

        /** Параметры api ответа */
        export interface ResponseI {
            avg_org_user_rating: number; // средняя оценка организатора
        }
    }

    /** Обновить Оценку */
    export namespace updateRate {
        /** API URL */
        export const route = '/cart/update-rate';

        /** Alias действия */
        export const action = 'cart-update-rate';

        /** Параметры api запроса */
        export interface RequestI {
            id: number; // номер оценки
            value: number; // оценка
            desc: string; // комментарий оценки
        }

        /** Параметры api ответа */
        export interface ResponseI {
            status: number;
        }
    }

    /** Агентский договор */
    export namespace contract {
        /** APIURL */
        export const route = '/cart/contract';

        /** Alias действия */
        export const action = 'cart-contract';

        /** Параметры api запроса */
        export interface SetRequestI {
            purchase_id: number;
            invoice_id: number;
            org_fee: number;
            username?: string;
            user_fullname?: string;
            user_email?: string;
            user_mobile?: string;
        }

        /** Параметры api ответа */
        export interface SetResponseI {
            token: string; // по токену в редисе находится пдф файл
        }

        /** Параметры api запроса */
        export interface GetRequestI {
            token: string; // по токену в редисе находится пдф файл
        }

        export interface GetResponseI {}
    }

    /** Получить историю подтверждения оплаты */
    export namespace getPaymentConfirmationHistory {
        /** APIURL */
        export const route = '/cart/get-payment-confirmation-history';

        /** Alias действия */
        export const action = 'cart-get-payment-confirmation-history';

        /** Параметры api запроса */
        export interface RequestI {
            payment_id: number;
        }

        /** Параметры api ответа */
        export interface ResponseI {
            payment_confirmation_history: PurchasePaymentLogI[];
            user_name_list: Record<number, string>;
        }
    }

    /** Получить историю подтверждения оплаты */
    export namespace getPaymentConfirmationList {
        /** APIURL */
        export const route = '/cart/get-payment-confirmation-list';

        /** Alias действия */
        export const action = 'cart-get-payment-confirmation-list';

        /** Параметры api запроса */
        export interface RequestI {
            purchase_id: number;
            user_id?: number; // номер УЗ для корзины админа
        }

        /** Параметры api ответа */
        export interface ResponseI {
            payment_confirmation_list: PaymentConfirmationInfoI[];
        }
    }

    /** Добавить подтверждение оплаты */
    export namespace addPaymentConfirmation {
        /** APIURL */
        export const route = '/cart/add-payment-confirmation';

        /** Alias действия */
        export const action = 'cart-add-payment-confirmation';

        /** Параметры api запроса */
        export interface RequestI {
            payment_card?: string;
            payment_money: number;
            payment_comment?: string;
            purchase_id: number;
            payment_date?: string; // Дата в формате 'YYYY-MM-DD'
            payment_time?: string; // Время в формате 'HH:mm:ss'
        }

        /** Параметры api ответа */
        export interface ResponseI {
            payment_id: number;
        }
    }

    /** Добавить новую транзакцию оплаты или пополнения */
    export namespace addNewInPayment {
        /** APIURL */
        export const route = '/cart/add-new-in-payment';

        /** Параметры api запроса */
        export interface RequestI {
            money: number; // Деньги
            acc_money: number; // Сумма оплаты с лиц. счета
            org_fee_money?: number; // Сумма агентского вознаграждения
            // datetime?: string; // ! DEPRECATED! Время поступления денег (его заполняет пользователь)
            invoice_id?: number; // ID инвойса
            comment?: string; // Комментарий
        }

        /** Параметры api ответа */
        export interface ResponseI {
            payment_id: number; // ID транзакции
        }
    }

    /**
     * Добавить новую транзакцию оплаты посылки
     */
    export namespace addNewInDeliveryPayment {
        /** APIURL */
        export const route = '/cart/add-new-in-delivery-payment';

        /** Параметры api запроса */
        export interface RequestI {
            money: number; // Деньги
            acc_money: number; // Сумма оплаты с лиц. счета
            delivery_request_id: number; // ID посылки
            invoice_id?: number; // ID заказа (при оплате с основной страницы корзины)
            comment?: string; // Комментарий
        }

        /** Параметры api ответа */
        export interface ResponseI {
            payment_id: number; // ID транзакции
        }
    }

    /**
     * Оплатить заказ частично/целиком со счёта пользователя
     */
    export namespace payFromAccount {
        /** APIURL */
        export const route = '/cart/pay-from-account';

        /** Параметры api запроса */
        export interface RequestI {
            money: number; // сумма оплаты
            org_fee_money: number; // Сумма агентского вознаграждения
            invoice_id: number; // номер заказа
        }

        /** Параметры api ответа */
        export interface ResponseI {
            is_ok: boolean // Результат выполнения операции
        }
    }

    /**
     * Оплатить посылку частично/целиком со счёта пользователя
     */
    export namespace payDeliveryFromAccount {
        /** APIURL */
        export const route = '/cart/pay-delivery-from-account';

        /** Параметры api запроса */
        export interface RequestI {
            money: number; // Сумма оплаты
            delivery_request_id: number; // ID посылки
            invoice_id?: number; // ID заказа (при оплате из основной корзины)
        }

        /** Параметры api ответа */
        export interface ResponseI {
            // Ответа нет
        }
    }

    /** Обновить данные подтверждения оплаты */
    export namespace updatePaymentConfirmation {
        /** APIURL */
        export const route = '/cart/update-payment-confirmation';

        /** Alias действия */
        export const action = 'cart-update-payment-confirmation';

        /** Параметры api запроса */
        export interface RequestI {
            payment_card?: string;
            payment_money: number;
            payment_comment?: string;
            payment_id: number;
            payment_date?: string; // Дата в формате 'YYYY-MM-DD'
            payment_time?: string; // Время в формате 'HH:mm:ss'
        }

        /** Параметры api ответа */
        export interface ResponseI {
            update_cnt: number;
        }
    }

    /** Удалить подтверждение оплаты */
    export namespace deletePaymentConfirmation {
        /** APIURL */
        export const route = '/cart/delete-payment-confirmation';

        /** Alias действия */
        export const action = 'cart-delete-payment-confirmation';

        /** Параметры api запроса */
        export interface RequestI {
            payment_id: number;
        }

        /** Параметры api ответа */
        export interface ResponseI {
            delete_cnt: number;
        }
    }

    /** Получить количество закупок по статусам/фильтру */
    export namespace getFilterCount {
        /** APIURL */
        export const route = '/cart/get-filter-count';

        /** Alias действия */
        export const action = 'cart-get-filter-count';

        /** Параметры api запроса */
        export interface RequestI {
            user_id?: number; // номер УЗ для корзины админа
        }

        /** Параметры api ответа */
        export interface ResponseI {
            order_count: OrderCountListI;
        }
    }

    /** Получить количество архивных закупок */
    export namespace getArchiveCount {
        /** APIURL */
        export const route = '/cart/get-archive-count';

        /** Alias действия */
        export const action = 'cart-get-archive-count';

        /** Параметры api запроса */
        export interface RequestI {
            search?: string;
            is_archive?: number; // C фронта булево, после валидации Псевдо булево 1 0
            user_id?: number; // номер УЗ для корзины админа
            org_ids?: number[]; // фильтр по организатору
            purchase_state_id?: number; // фильтр по статусу закупки
            order_state?: number; // фильтр по статусу заказа
        }

        /** Параметры api ответа */
        export interface ResponseI {
            archive_count: number;
        }
    }

    /** Сменить офис / адрес доставки */
    export namespace changeOffice {
        /** APIURL */
        export const route = '/cart/change-office';

        /** Alias действия */
        export const action = 'cart-change-office';

        /** Параметры api запроса */
        export interface RequestI {
            invoice_id: number; // номер счета
            office_id: number; // новый номер офиса
            address_id?: number; // новый адрес для доставки почтой, офис 120
        }

        /** Параметры api ответа */
        export interface ResponseI {
            status: number;
        }
    }
    /** Информация для окна оплаты, платежные реквизиты банка и данные для формирования qrcode */
    export namespace paymentWindow {
        /** APIURL */
        export const route = '/cart/payment-window';

        /** Alias действия */
        export const action = 'cart-payment-window';

        /** Параметры api запроса */
        export interface RequestI {
            purchase_id: number; // номер закупки
            sum: string; // сумма оплаты
            org_fee: string; // орг сбор
            invoice: number; // номер Заказа
            tax_system: TaxSystemT; // налоговая система
        }

        /** Параметры api ответа */
        export interface ResponseI {
            bank_details: {
                org_name: string; // Наименование организации
                org_inn: string; // ИНН организации
                bank_bik: string; // БИК банка
                bank_name: string; // Название банка
                bank_account: string; // Расчетный счет
                bank_corr_account: string; // Кор счет
                payment_comment: string; // Комментарий платежа
                // bank_recipient: string; // Владелец карты // !DEPRECATED
                // bank_card_number: string; // Номер карты // !DEPRECATED
                // qr_active: number; // 0 | 1 есть оплата по коду // !DEPRECATED
            };
            qrcode: {
                // org_id: number; // !DEPRECATED
                // user_id: number; // !DEPRECATED
                purchase_id: number;
                invoice: string;
                // user_fullname: string; // !DEPRECATED
                sum: string;
                total_org_fee: string;
                // pd_id: number; // !DEPRECATED
                tax_system: TaxSystemT; // налоговая система
            };
        }
    }

    /** Информация для окна оплаты посылки, платежные реквизиты банка и данные для формирования qrcode */
    export namespace deliveryPaymentWindow {
        /** APIURL */
        export const route = '/cart/delivery-payment-window';

        /** Параметры api запроса */
        export interface RequestI {
            sum: string; // Сумма оплаты
            delivery_request_id: number; // ID посылки
        }

        /** Параметры api ответа */
        export interface ResponseI {
            bank_details: {
                org_name: string; // Наименование организации
                org_inn: string; // ИНН организации
                bank_bik: string; // БИК банка
                bank_name: string; // Название банка
                bank_account: string; // Расчетный счет
                bank_corr_account: string; // Кор счет
                payment_comment: string; // Комментарий платежа
            };
            qrcode: {
                sum: string; // Сумма оплаты
                delivery_request_id: number; // ID посылки
            };
        }
    }

    /** Получить информацию о блокировке пользователя */
    export namespace getBlockInfo {
        /** APIURL */
        export const route = '/cart/get-block-info';

        /** Alias действия */
        export const action = 'cart-get-block-info';

        /** Параметры api запроса */
        export interface RequestI {
            user_id: number;
        }

        /** Параметры api ответа */
        export interface ResponseI {
            block_info: BlockInfoI[];
        }
    }

    /** Отправить запрос на оплату для выхода из ЧС */
    export namespace createPaymentRequest {
        /** APIURL */
        export const route = '/cart/create-payment-request';

        /** Параметры api запроса */
        export interface RequestI {
            // Нет запроса
        }

        /** Параметры api ответа */
        export interface ResponseI {
            // Нет ответа
        }
    }

    /** Получить информацию о пользователе для орга/модера */
    export namespace getUserInfo {
        /** APIURL */
        export const route = '/cart/get-user-info';

        /** Параметры api запроса */
        export interface RequestI {
            user_id: number;
        }

        /** Параметры api ответа */
        export interface ResponseI {
            user_info: UserInfoI;
            default_pvz: PvzI;
        }
    }

    export namespace getUserData {
        export const route = '/cart/get-user-data';

        export interface RequestI {
            user_id?: number; // ID пользователя
        }

        export interface ResponseI {
            user_info: UserInfoI;
            default_pvz: PvzI;
            is_block: boolean;
            is_confirmed_email: boolean;
        }
    }

    // ===========================================================
    // Список избранного
    // ===========================================================

    /** Получить список желаний */
    export namespace getWishList {
        /** APIURL */
        export const route = '/cart/get-wish-list';

        /** Alias действия */
        export const action = 'get-wish-list';

        /** Параметры api запроса */
        export interface RequestI {
            user_id?: number; // ID юзера (для просмотра чужого списка избранного)
            org_user_id?: number; // идентификатор организатора
            stock_id?: number; // идентификатор шаблона
            list_item_id?: number[]; // список идентификаторов товаров
            is_active?: boolean; // статус закупки активный или пассивный
            is_sort_stock?: boolean; // сортировать по шаблону
            search_field?: string; // поиск по наименованию товара
            list_category_id?: number[]; // IDs категорий товара
            limit: number; // Число выписок на странице
            page: number; // Номер страницы
        }

        /** Параметры api ответа */
        export interface ResponseI {
            list_wish_item: WishesInfoI[]; // товары из списка избранного
            list_item_info: ItemInfoI[]; // информация по товару
            list_purchase: PurchaseInfoI[]; // активные закупки
            list_characteristic: P63ItemCharacteristicIDsI[]; // характеристики
            list_image: S3ItemImgIDsI[]; // Картинки
            list_item_with_bundle: number[]; // ID товаров c рядами
            list_wish_stock_hide: number[]; // ids шаблонов скрытых для списка желаний
            list_is_active_item: number[]; // активный товар для покупок
            list_active_item_for_not_active_item: ItemInfoI[]; // список активных товаров для не активных из списка желаний
            list_market_item_user: MarketItemOwnerInfoI[];  //информация о товаре пристроя
        }
    }

    export namespace getWishPageCount {
        /** APIURL */
        export const route = '/cart/get-wish-page-count';

        /** Alias действия */
        export const action = 'get-wish-page-count';

        /** Параметры api запроса */
        export interface RequestI {
            user_id?: number; // ID юзера (для просмотра чужого списка избранного)
            org_user_id?: number; // идентификатор организатора
            stock_id?: number; // идентификатор шаблона
            list_item_id?: number[]; // список идентификаторов товаров
            is_active?: boolean; // статус закупки активный или пассивный
            search_field?: string; // поиск по наименованию товара
            list_category_id?: number[]; // IDs категорий товара
            limit: number; // Число выписок на странице
            page: number; // Номер страницы
        }

        /** Параметры api ответа */
        export interface ResponseI {
            wish_count: number; // Количество избранных товаров
            page_count: number; // Количество страниц
        }
    }

    /** Получить данные для поисковой строки */
    export namespace getWishAutocomplete {

        /** APIURL */
        export const route = '/cart/get-wish-autocomplete';

        /** Параметры api запроса */
        export interface RequestI {
            user_id?: number; // ID юзера (для просмотра чужого списка избранного)
        }

        /** Параметры api ответа */
        export interface ResponseI {
            list_wish_item: WishesInfoI[];      // список избранного
            list_stock: StockIDsI[];        // список шаблонов
            list_org: UserDictionaryI[];      // список организаторов
        }
    }

    /** Добавить шаблон в свернутые для списка желаний */
    export namespace addWishStockHide {

        /** APIURL */
        export const route = '/cart/add-wish-stock-hide';

        /** Параметры api запроса */
        export interface RequestI {
            stock_id: number; // ID шаблона
        }

        /** Параметры api ответа */
        export interface ResponseI {
            // не предусмотрено
        }
    }

    /** Удалить шаблон из свернутых в списке желаний */
    export namespace delWishStockHide {

        /** APIURL */
        export const route = '/cart/del-wish-stock-hide';

        /** Параметры api запроса */
        export interface RequestI {
            stock_id: number; // ID шаблона
        }

        /** Параметры api ответа */
        export interface ResponseI {
            // не предусмотрено
        }
    }

    /** Удалить из избранного */
    export namespace delItemFromWishList {
        /** APIURL */
        export const route = '/cart/del-item-from-wish-list';

        /** Alias действия */
        export const action = 'del-item-from-wish-list';

        /** Параметры api запроса */
        export interface RequestI {
            wish_id: number; // ID записи списка избранного
        }

        /** Параметры api ответа */
        export interface ResponseI {}
    }

    /** Удалить список товаров из списка желания */
    export namespace delWishListByPurchaseIds {
        /** APIURL */
        export const route = '/cart/del-wish-list-by-purchase-ids';

        /** Параметры api запроса */
        export interface RequestI {
            list_purchase_id: number[]; // список ID закупки
        }

        /** Параметры api ответа */
        export interface ResponseI {}
    }

    /**
     * Скрыть / показать список отказанных заказов в корзине
     */
    export namespace hideOrderList {
        /** API URL */
        export const route = '/cart/hide-order-list';

        /** Параметры api запроса */
        export interface RequestI {
            list_order_id: number[];
            order_hide_refused: number;
        }

        /** Параметры api ответа */
        export interface ResponseI {}
    }

    /**
     * Получить избранные шаблоны (закупки) пользователя
     */
    export namespace getListFavoriteStockID {
        export const route = '/cart/get-list-favorite-stock-id';

        export interface RequestI {
            user_id?: number;
        }

        export interface ResponseI {
            list_favorite_stock_id: number[]; // ID избранных шаблонов
        }
    }

    /**
     * Получить количество заказов которые нужно оплатить пользователю
     */
    export namespace getReadyInvoiceCount {
        export const route = '/cart/get-ready-invoice-count';
        export interface RequestI {
        }

        export interface ResponseI {
            count: number; // Количество заказов которые нужно оплатить
        }
    }

    /**
     * Получить баланс пользователя
     */
    export namespace getAccountBalance {
        /** API URL */
        export const route = '/cart/get-account-balance';

        /** Параметры api запроса */
        export interface RequestI {
            user_id?: number; // Необязательный параметр, если передали, показываем баланс для этого ID (только для админов)
        }

        /** Параметры api ответа */
        export interface ResponseI {
            money: number; // Сумма денег на счёте
        }
    }

    /**
     * Отправить товар в новый Пристрой
     */
    export namespace sendToPristroy {
        /** API URL */
        export const route = '/cart/send-to-pristroy';

        /** Параметры api запроса */
        export interface RequestI {
            order_id: number; // ID заказа для отправки в Пристрой
        }

        /** Параметры api ответа */
        export interface ResponseI {
            market_item: MarketItemI; // созданный товар для Пристроя
        }
    }

    /**
     * Отменить продажу товара в новом Пристрой
     */
    export namespace cancellationOrderInPristroy {
        /** API URL */
        export const route = '/cart/cancellation-order-in-pristroy';

        /** Параметры api запроса */
        export interface RequestI {
            item_id: number; // ID заказа для отправки в Пристрой
        }

        /** Параметры api ответа */
        export interface ResponseI {
            market_item: MarketItemI; // созданный товар для Пристроя
        }
    }

    // ============================= МОИ ПОСЫЛКИ (ДОСТАВКА ПО РОССИИ) =============================

    /**
     * Получение общих данных для доставки по РФ
     */
    export namespace initDeliveryRequest {
        /** API URL */
        export const route = '/cart/init-delivery-request';

        /** Параметры api запроса */
        export interface RequestI {
            // Нет запроса
        }

        /** Параметры api ответа */
        export interface ResponseI {
            list_transport_company: TransportCompanyI[];
            list_transport_company_img: S3TransportCompanyFileI[];
            list_tc_field_settings: TcFieldSettingsI[]; // Список настроек полей ТК
        }
    }

    /**
     * Получить список посылок для доставки по России
     */
    export namespace getListDeliveryRequest {
        /** API URL */
        export const route = '/cart/get-list-delivery-request';

        /** Параметры api запроса */
        export interface RequestI {
            user_id?: number;
            last_delivery_request_id?: number;
            delivery_request_id?: number;
        }

        /** Параметры api ответа */
        export interface ResponseI {
            list_delivery_purchase: Record<number, number[]>; // { delivery_request_id: purchase_id[] }
            list_delivery_request: DeliveryRequestInfoI[];
            list_delivery_request_invoice: DeliveryRequestInvoiceI[];
        }
    }

    /**
     * Получить список активных заказов на доставку по России
     */
    export namespace getListActiveOrderForDelivery {
        /** API URL */
        export const route = '/cart/get-list-active-order-for-delivery';

        /** Параметры api запроса */
        export interface RequestI {
            user_id?: number;
            // last_delivery_request_id: number;
        }

        /** Параметры api ответа */
        export interface ResponseI {
            list_invoice: InvoiceCartI[];
            list_purchase: PurchaseInfoI[];
            list_order: OrderExtendI[];
            list_order_char: P63OrderCharacteristicIDsI[];
            list_item: ItemInfoI[];
            list_item_char: P63ItemCharacteristicIDsI[];
            list_item_img: S3ItemImgIDsI[];
            list_bundle: P63ItemBundleIDsI[];
            list_post_office_address: AddressI[];
            list_amount: { purchase_id: number; sum: number }[];
            list_money: MoneyI[];
            list_discount: PurchaseDiscountI[];
            list_payment_confirmation: PaymentConfirmationInfoI[];
            list_refund_request: RefundRequestI[];
            list_rate: RatingInfoI[];
            list_active_organization: OrganizationIxI[];
            list_delivery_time: P63AvgDeliveryTimeI[];
            list_purchase_ready_to_pay: number[];
            list_order_market: number[]; // Список ID заказов в пристрое пользователя
            list_order_by_action: P63PromoActionOrderDiscountKeyI[], // Заказы с примененными скидками
            list_purchase_action_condition: P63PromoActionConditionKeyPurchaseI[]; // Условия акций в закупках в которых учавствует УЗ
            list_purchase_action_function: P63promoActionFunctionKeyPurchaseI[] // Действия акций в закупках в которых учавствует УЗ
        }
    }

    /**
     * Создать запрос на доставку по России
     */
    export namespace createDeliveryRequest {
        /** API URL */
        export const route = '/cart/create-delivery-request';

        /** Параметры api запроса */
        export interface RequestI {
            transport_company_id: number; // Номер выбранной транспортной компании
            delivery_address?: string; // Адрес доставки: Город, Улица, Дом
            zip_code?: string; // Почтовый индекс, 6 цифр
            recipient: string; // ФИО получателя
            recipient_phone: string; // Номер получателя
            list_invoice_id: number[]; // Список заказов пользователя, которые хочет отправить доставкой
            apartment?: string; // Номер квартиры/офиса
            address_tc?: string; // Адрес ПВЗ торговой компании в городе получателя
            pvz_id?: number;     // id пвз тк назначения
        }

        /** Параметры api ответа */
        export interface ResponseI {
            delivery_request_id: number; // Номер созданной заявки на доставку
        }
    }

    /**
     * Обновить запрос на доставку по России
     */
    export namespace updateDeliveryRequest {
        /** API URL */
        export const route = '/cart/update-delivery-request';

        /** Параметры api запроса */
        export interface RequestI {
            delivery_request_id: number; // Номер созданной заявки на доставку
            transport_company_id?: number; // Номер выбранной транспортной компании
            delivery_address?: string; // Адрес доставки: Город, Улица, Дом
            zip_code?: string; // Почтовый индекс, 6 цифр
            recipient?: string; // ФИО получателя
            recipient_phone?: string; // Номер получателя
            apartment?: string; // Номер квартиры/офиса
            address_tc?: string; // Адрес ПВЗ торговой компании в городе получателя
            pvz_id?: number;     // id пвз
        }

        /** Параметры api ответа */
        export interface ResponseI {
            // Нет ответа
        }
    }

    /**
     * Добавить заказы к запросу на доставку по России
     */
    export namespace addInvoiceDeliveryRequest {
        /** API URL */
        export const route = '/cart/add-invoice-delivery-request';

        /** Параметры api запроса */
        export interface RequestI {
            delivery_request_id: number;
            list_invoice_id: number[];
        }

        /** Параметры api ответа */
        export interface ResponseI {
            // Нет ответа
        }
    }

    /**
     * Убрать заказы из запроса на доставку по России
     */
    export namespace delInvoiceDeliveryRequest {
        /** API URL */
        export const route = '/cart/del-invoice-delivery-request';

        /** Параметры api запроса */
        export interface RequestI {
            delivery_request_id: number;
            list_invoice_id: number[];
        }

        /** Параметры api ответа */
        export interface ResponseI {
            // Нет ответа
        }
    }

    /**
     * Получить список подтвержденных платежей по посылке
     */
    export namespace listDeliveryPaymentConfirmation {
        /** API URL */
        export const route = '/cart/list-delivery-payment-confirmation';

        /** Параметры api запроса */
        export interface RequestI {
            delivery_request_id: number;
            user_id?: number;
        }

        /** Параметры api ответа */
        export interface ResponseI {
            list_payment_confirmation: PaymentConfirmationInfoI[];
        }
    }

    /**
     * Сохранить новый адрес доставки
     */
    export namespace createDeliveryAddress {
        /** API URL */
        export const route = '/cart/create-delivery-address';

        /** Параметры api запроса */
        export interface RequestI {
            tc_id: number; // Номер выбранной транспортной компании
            address?: string; // Адрес доставки: Город, Улица, Дом
            zip_code?: string; // Почтовый индекс, 6 цифр
            recipient: string; // ФИО получателя
            recipient_phone: string; // Номер получателя
            apartment?: string; // Номер квартиры/офиса
            tc_address?: string; // Адрес ПВЗ торговой компании в городе получателя
            pvz_id?: number;     // id пвз
        }

        /** Параметры api ответа */
        export interface ResponseI {
            address_id: number;
        }
    }

    /**
     * Обновить сохраненный адрес доставки
     */
    export namespace updateDeliveryAddress {
        /** API URL */
        export const route = '/cart/update-delivery-address';

        /** Параметры api запроса */
        export interface RequestI {
            address_id: number; // ID адреса для изменения
            tc_id?: number; // Номер выбранной транспортной компании
            address?: string; // Адрес доставки: Город, Улица, Дом
            zip_code?: string; // Почтовый индекс, 6 цифр
            recipient?: string; // ФИО получателя
            recipient_phone?: string; // Номер получателя
            apartment?: string; // Номер квартиры/офиса
            tc_address?: string; // Адрес ПВЗ торговой компании в городе получателя
            pvz_id?: number;     // id пвз
        }

        /** Параметры api ответа */
        export interface ResponseI {
            // Нет ответа
        }
    }

    /**
     * Удалить сохраненный адрес доставки
     */
    export namespace deleteDeliveryAddress {
        /** API URL */
        export const route = '/cart/delete-delivery-address';

        /** Параметры api запроса */
        export interface RequestI {
            address_id: number; // ID адреса для изменения
        }

        /** Параметры api ответа */
        export interface ResponseI {
            // Нет ответа
        }
    }

    /**
     * Получить список адресов доставки пользователя
     */
    export namespace listSavedDeliveryAddress {
        /** API URL */
        export const route = '/cart/list-saved-delivery-address';

        /** Параметры api запроса */
        export interface RequestI {
            // Нет запроса
        }

        /** Параметры api ответа */
        export interface ResponseI {
            list_address: DeliveryRequestSavedAddressI[];
            old_address: AddressI;
            user_phone: string;
            user_fullname: string;
        }
    }

    // ================================================
    //                  ВОЗВРАТ ТОВАРА
    // ================================================

    /** Создать заявку на возврат */
    export namespace createRefundOrderRequest {
        /** API URL */
        export const route = '/cart/create-refund-order-request';

        /** Параметры api запроса */
        export interface RequestI {
            list_order_id: number[]; // Список заказов для возврата
            cart_user_id?: number; // ID пользователя, если возврат оформляется из его корзины модератором
        }

        /** Параметры api ответа */
        export interface ResponseI {
            refund_order_request_id: number;
        }
    }

    /** Обновить заявку на возврат */
    export namespace updateRefundOrderRequest {
        /** API URL */
        export const route = '/cart/update-refund-order-request';

        /** Параметры api запроса */
        export interface RequestI {
            refund_order_request_id: number; // ID заявки
            pvz_id?: number;                 // ID ПВЗ
            description?: string;            // Описание
            list_add_order_id?: number[];    // Список заказов, которые добавить к заявке
            list_del_order_id?: number[];    // Список заказов, которые убрать из заявки
        }

        /** Параметры api ответа */
        export interface ResponseI {
            // нет ответа
        }
    }

    /** Удлаить заявку на возврат */
    export namespace deleteRefundOrderRequest {
        /** API URL */
        export const route = '/cart/delete-refund-order-request';

        /** Параметры api запроса */
        export interface RequestI {
            refund_order_request_id: number; // ID заявки
        }

        /** Параметры api ответа */
        export interface ResponseI {
            // нет ответа
        }
    }
}

/**
 * Функция ограничивает выполнение целевой функции по временным периодам
 * @param  {} f - целевая функция
 * @param  {} delay - пропуск выполнения в мс
 */
export function mThrottled(f, delay) {
    let lastCall = 0;
    return function() {
        let args = arguments;
        let now = new Date().getTime();
        if (now - lastCall < delay) {
            return;
        }
        lastCall = now;
        return f(args);
    };
}

import Component from 'vue-class-component';
import ds_button from '@/ds/el/button/button';
import P63Vue from '@/system/P63Vue';

@Component({
    components: {
        'ds-button': ds_button,
    },
})
export default class ModalDataUse extends P63Vue {
    /** Закрытие модального окна */
    fClosePopup() {
        this.$emit('close');
        document.body.classList.remove('stop-scrolling');
    }

    /** Подтверждение согласия пользователя на использование его данных*/
    fConfirmDataUse() {
        localStorage.setItem('isAgreeDataUse', 'true');
        this.fClosePopup();
    }

    mounted() {
        document.body.classList.add('stop-scrolling');
    }
}

import * as conf from '@/configs/config.public';
import { PurchasePageNoAuthR } from '@/ifc/core/PurchasePageNoAuthR';
import { ItemBundleN } from '@/common/interface/ItemBundle';
import groupBy from 'lodash/groupBy';
import { PurchasePageOrgR } from '@/ifc/core/PurchasePageOrgR';
import { PageCtrl } from './PageCtrl';
import { gfInitContextQuery } from './ContextSys';
import { QuerySys } from '@63pokupki/front';
import { mIsClient } from '@/common/helpers/ContextH';

/** ItemBundle Store */
class ItemBundleStoreI {
    toJSON() {
        return { ...this }; // here I make a POJO's copy of the class instance
    }

    // Индексированные списки
    /** Индексированный список рядов товаров списка желаний (обработанный ответ) */
    ix_items_bundle: Record<number, PurchasePageNoAuthR.getItemBundle.ResponseI> = {};

    // Статусы
    /** Статус загрузки */
    is_loading_fix_unfix_bundle = false;
}

/** System для работы c ItemBundle */
export class ItemBundleSys {
    public conf = conf;

    public item_bundle: ItemBundleStoreI = null;

    private queryClientSys: QuerySys = null;
    private queryMsgSys: QuerySys = null;

    constructor(ctrl: PageCtrl) {
        // Конфигурация запроса на core
        this.queryClientSys = new QuerySys();
        this.queryClientSys.fConfig(gfInitContextQuery(ctrl, conf.coreApi));

        this.queryMsgSys = new QuerySys();
        this.queryMsgSys.fConfig(gfInitContextQuery(ctrl, conf.msgAPI));

        let optionVuex = null;
        if (mIsClient()) {
            optionVuex = {
                preserveState: true, // сохранять состояние на сервере
            };
        }

        this.item_bundle = ctrl.vuexSys.registerModuleCustomKey(new ItemBundleStoreI(), 'item_bundle_sys', optionVuex);
    }

    /** Получение списка рядов товара */
    public fаGetItemBundle(idItem: number) {
        this.queryClientSys.fInit();

        this.queryClientSys.fActionOk((data: PurchasePageNoAuthR.getItemBundle.ResponseI) => {
            let vProcessedData = data;
            let aBundleSort = data.list_item_bundle;

            if (Number(data.list_item_bundle[0]?.val)) {
                aBundleSort = aBundleSort.slice().sort((a, b) => a.characteristic_val_id - b.characteristic_val_id);
            }

            vProcessedData = { ...vProcessedData, list_item_bundle: aBundleSort };

            this.item_bundle.ix_items_bundle = { ...this.item_bundle.ix_items_bundle, [idItem]: vProcessedData };
        });

        this.queryClientSys.fActionErr((e: Record<string, string>) => {
            console.log('Данные о рядах товара не были загружены =(');
        });

        const request: PurchasePageNoAuthR.getItemBundle.RequestI = {
            item_id: idItem,
        };

        this.queryClientSys.fSend(PurchasePageNoAuthR.getItemBundle.route, request);
    }

    /** Функция (общее тело функции) для фиксирования и расфиксирования ряда (функция помощник)  */
    public faFixUnfixCommon(
        route: string,
        request: PurchasePageOrgR.fixOrder.RequestI | PurchasePageOrgR.unfixOrder.RequestI,
        idItem: number
    ) {
        this.item_bundle.is_loading_fix_unfix_bundle = true;

        this.queryClientSys.fInit();

        this.queryClientSys.fActionOk(
            (data: PurchasePageOrgR.fixOrder.ResponseI | PurchasePageOrgR.unfixOrder.ResponseI) => {
                this.item_bundle.is_loading_fix_unfix_bundle = false;
                this.fаGetItemBundle(idItem);
            }
        );

        this.queryClientSys.fActionErr((e: Record<string, string>) => {
            this.item_bundle.is_loading_fix_unfix_bundle = false;
            this.fаGetItemBundle(idItem);
            console.log('Невозможно зафиксировать/расфиксировать ряды =(');
        });

        this.queryClientSys.fSend(route, request);
    }

    /** Коэффициент фикс/нефикс к общему кол-ву рядов */
    fGetRatioBundle(aBundleFixNoFix: Record<number, any[]>, nCharId: number, ixItemBundle: Record<number, any[]>) {
        return Math.ceil(aBundleFixNoFix[nCharId].length / ixItemBundle[nCharId].length);
    }

    /** Обработчик списка рядов (транспонирование матрицы данных товаров) */
    public fConvertItemBundle(
        respData: PurchasePageNoAuthR.getItemBundle.ResponseI,
        userId: number
    ): ItemBundleN.ItemBundelServiceOut {
        const aaBundleFix = [];
        const aaBundleNoFix = [];

        /* проверка на наличие поля списка рядов */
        if (respData && respData.list_item_bundle) {
            const list_item_bundle = respData.list_item_bundle; // ряды

            /* распределение (диструктуризация) */
            let list_item_bundle_fix = respData.list_item_bundle_fix;
            let list_item_bundle_no_fix = respData.list_item_bundle_no_fix;

            let bOk = true;

            bOk = list_item_bundle.length > 0;

            /* если кол-во рядов больше 0, начинаем обработку массивов */
            if (bOk) {
                // Индексируем ряды
                const ixItem_bundle = groupBy(list_item_bundle, 'characteristic_val_id');

                /* формируем индекс для фикс. рядов */
                const aBundleFixIdx = {};
                let nLengthFixBundle = 0;
                /* если фиксированных рядов больше 0 */
                if (list_item_bundle_fix.length > 0) {
                    /* заполнение индексированного списка фиксированных рядов */
                    for (let i = 0; i < list_item_bundle_fix.length; i++) {
                        let v = list_item_bundle_fix[i];
                        if (!aBundleFixIdx[v.characteristic_val_id]) {
                            aBundleFixIdx[v.characteristic_val_id] = [];
                        }
                        aBundleFixIdx[v.characteristic_val_id].push(v);

                        /* обновление кол-ва элементов в фикс. рядах */
                        if (
                            ixItem_bundle[v.characteristic_val_id] &&
                            nLengthFixBundle <
                                this.fGetRatioBundle(aBundleFixIdx, v.characteristic_val_id, ixItem_bundle)
                        ) {
                            nLengthFixBundle = this.fGetRatioBundle(
                                aBundleFixIdx,
                                v.characteristic_val_id,
                                ixItem_bundle
                            );
                        }
                    }
                }

                /* формируем индекс для нефикс. рядов */
                const aBundleNoFixIdx: { [key: number]: any[] } = {};
                let nLengthNoFixBundle = 0;
                /* если нефиксированных рядов больше 0 */
                if (list_item_bundle_no_fix.length > 0) {
                    /* заполнение индексированного списка фиксированных рядов */
                    for (let i = 0; i < list_item_bundle_no_fix.length; i++) {
                        let v = list_item_bundle_no_fix[i];
                        if (!aBundleNoFixIdx[v.characteristic_val_id]) {
                            aBundleNoFixIdx[v.characteristic_val_id] = [];
                        }

                        aBundleNoFixIdx[v.characteristic_val_id].push(v);

                        /* обновление кол-ва элементов в нефикс. рядах */
                        if (
                            ixItem_bundle[v.characteristic_val_id] &&
                            nLengthNoFixBundle <
                                this.fGetRatioBundle(aBundleNoFixIdx, v.characteristic_val_id, ixItem_bundle)
                        ) {
                            nLengthNoFixBundle = this.fGetRatioBundle(
                                aBundleNoFixIdx,
                                v.characteristic_val_id,
                                ixItem_bundle
                            );
                        }
                    }
                }

                /* транспонирование матрицы с фиксированными рядами товара  */
                if (!list_item_bundle_fix) {
                    list_item_bundle_fix = [];
                }

                let k = 0;
                let counter = 0;

                if (list_item_bundle.length > 0) {
                    /* заполнение массива с фиксированными рядам в правильной структуре для отображения на фронте */
                    for (let i = 0; i < nLengthFixBundle; i++) {
                        const aBandle: ItemBundleN.ItemBundleMatrixI[] = [];
                        for (let bundleKey = 0; bundleKey < list_item_bundle.length; bundleKey++) {
                            /* заполнение дефолтными значениями */
                            const bundle: ItemBundleN.ItemBundleMatrixI = {
                                characteristic_val_id: list_item_bundle[bundleKey].characteristic_val_id, // ID Значение переменной
                                val: list_item_bundle[bundleKey].val, // Значение характеристики
                                status: ItemBundleN.ItemBundleStatus.Free,
                                orderId: 0,
                            };

                            /* определение статуса ряда */
                            if (
                                aBundleFixIdx[bundle.characteristic_val_id] &&
                                aBundleFixIdx[bundle.characteristic_val_id].length > 0
                            ) {
                                /* проставляем статус */
                                bundle.status = ItemBundleN.ItemBundleStatus.Fixed;
                                const idx = 0;
                                const thisUserId = aBundleFixIdx[bundle.characteristic_val_id][idx].user_id;
                                bundle.orderId = aBundleFixIdx[bundle.characteristic_val_id][idx].order_id;

                                if (userId == thisUserId) {
                                    bundle.status = ItemBundleN.ItemBundleStatus.Your;
                                }

                                aBundleFixIdx[bundle.characteristic_val_id].shift();
                            }

                            aBandle.push(bundle);
                        }

                        aaBundleFix.push(aBandle);
                        counter++;

                        if (counter > 1000) {
                            break;
                        }
                    }
                }

                /* транспонирование матрицы с нефиксированными рядами товара  */
                k = 0;
                counter = 0;

                if (!list_item_bundle_no_fix) {
                    list_item_bundle_no_fix = [];
                }

                if (list_item_bundle.length > 0) {
                    /* заполнение массива с нефиксированными рядам в правильной структуре для отображения на фронте */
                    for (let i = 0; i < nLengthNoFixBundle; i++) {
                        const aBandle: ItemBundleN.ItemBundleMatrixI[] = [];

                        for (let bundleKey = 0; bundleKey < list_item_bundle.length; bundleKey++) {
                            /* заполнение дефолтными значениями */
                            const bundle: ItemBundleN.ItemBundleMatrixI = {
                                characteristic_val_id: list_item_bundle[bundleKey].characteristic_val_id, // ID Значение переменной
                                val: list_item_bundle[bundleKey].val, // Значение характеристики
                                status: ItemBundleN.ItemBundleStatus.Free,
                                orderId: 0,
                            };

                            /* определение статуса ряда */
                            if (
                                aBundleNoFixIdx[bundle.characteristic_val_id] &&
                                aBundleNoFixIdx[bundle.characteristic_val_id].length > 0
                            ) {
                                /* сначала занятый */
                                bundle.status = ItemBundleN.ItemBundleStatus.Busy;

                                /* ищем юзера */
                                const idx = 0;
                                /* тут есть order_id */
                                const thisUserId = aBundleNoFixIdx[bundle.characteristic_val_id][idx].user_id;
                                bundle.orderId = aBundleNoFixIdx[bundle.characteristic_val_id][idx].order_id;

                                if (userId == thisUserId) {
                                    /* проставляем статус */
                                    bundle.status = ItemBundleN.ItemBundleStatus.Your;
                                }

                                /* внимание поп удаляет все из памяти */
                                aBundleNoFixIdx[bundle.characteristic_val_id].shift();
                            }

                            aBandle.push(bundle);
                        }

                        aaBundleNoFix.push(aBandle);
                        counter++;
                        if (counter > 1000) {
                            break;
                        }
                    }
                }
            }
        }

        /* возвращение полученных транспонированных матриц */
        return {
            aItemBundleFix: aaBundleFix,
            aItemBundleNoFix: aaBundleNoFix,
        };
    }
}

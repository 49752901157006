<template>
    <div class="home-page">
        <!-- Блок поиска, главный (верхний) баннер, блок сториес и регистрации -->
        <div
            v-if="!isApp"
            ref="searchbar"
            :class="{
                'home-page__search-mobile': true,
                'home-page__search-mobile_no-auth': !isAuth,
                'hide-categories': ctrl.status.is_mobile || ctrl.status.is_tablet,
            }"
        >
            <SearchInput />
        </div>

        <div class="home-page__top-banners" :class="{'top-banners_app': isApp}" >
            <HomeBanner :bannerType="tBannerType.top" :banner="aTopBannerList" alias="main" />
        </div>

        <div v-if="list.all_banners_collection.length">
            <div v-if="status.is_desktop">
                <HomeStoryD />
            </div>
            <div v-else>
                <HomeStoryM />
            </div>
        </div>

        <HomeRegistration class="home-page__registration" v-if="!isAuth" />

        <!-- Топ-подборки (хиты продаж), баннеры, новости и баннерные подборки (бренды) -->
        <div class="home-page__content">
            <TopSelection
                v-if="
                    ixTopSelectionId[vRating.first] &&
                    ixTopSelection[ixTopSelectionId[vRating.first]] &&
                    ixTopSelection[ixTopSelectionId[vRating.first]].list_item &&
                    ixTopSelection[ixTopSelectionId[vRating.first]].list_item.length
                "
                :id="aSortedTopSelectionByRating[vRating.first].id"
                :sNameVisible="aSortedTopSelectionByRating[vRating.first].name_visible"
                :aItems="ixTopSelection[ixTopSelectionId[vRating.first]].list_item"
                :nRemainingItems="ixTopSelection[ixTopSelectionId[vRating.first]].cnt_item_of_collection"
                :bSelectionItemsLoading="bSelectionItemsLoading"
                :nSyncCard="nSyncItemCard"
                :fGetItems="fGetListItem(ixTopSelectionId[vRating.first])"
                :bFromHome="true"
            />

            <!-- Блок баннеров -->
            <div class="home-page__banners" v-if="status.is_desktop">
                <HomeBanner
                    :bannerType="tBannerType.mini"
                    :banner="ixBanners[tBannerPlaceAlias.homeBPageLeft1]"
                    alias="homeBPageLeft1"
                />
                <HomeBanner
                    :bannerType="tBannerType.mini"
                    :banner="ixBanners[tBannerPlaceAlias.homeBPageRight1]"
                    alias="homeBPageRight1"
                />
            </div>
            <div class="home-page__banners home-page__banners_mob" v-else>
                <HomeBanner
                    :bannerType="tBannerType.mini"
                    :banner="ixBanners[tBannerPlaceAlias.homeBPageMobile2]"
                    alias="homeBPageLeft1"
                >
                </HomeBanner>
            </div>

            <TopSelection
                v-if="
                    ixTopSelectionId[vRating.second] &&
                    ixTopSelection[ixTopSelectionId[vRating.second]] &&
                    ixTopSelection[ixTopSelectionId[vRating.second]].list_item &&
                    ixTopSelection[ixTopSelectionId[vRating.second]].list_item.length
                "
                :id="aSortedTopSelectionByRating[vRating.second].id"
                :sNameVisible="aSortedTopSelectionByRating[vRating.second].name_visible"
                :aItems="ixTopSelection[ixTopSelectionId[vRating.second]].list_item"
                :nRemainingItems="ixTopSelection[ixTopSelectionId[vRating.second]].cnt_item_of_collection"
                :bSelectionItemsLoading="bSelectionItemsLoading"
                :fGetItems="fGetListItem(ixTopSelectionId[vRating.second])"
                :bFromHome="true"
            />

            <!-- Новости -->
            <HomeNews />

            <!-- Лучшие подборки (верхние) -->
            <Brands
                v-if="
                    fGetSplicedBanners(tBannerSelectionNumber.first) &&
                    fGetSplicedBanners(tBannerSelectionNumber.first).length
                "
                :aBannerSelection="fGetSplicedBanners(tBannerSelectionNumber.first)"
            />

            <TopSelection
                v-if="
                    ixTopSelectionId[vRating.third] &&
                    ixTopSelection[ixTopSelectionId[vRating.third]] &&
                    ixTopSelection[ixTopSelectionId[vRating.third]].list_item &&
                    ixTopSelection[ixTopSelectionId[vRating.third]].list_item.length
                "
                :id="aSortedTopSelectionByRating[vRating.third].id"
                :sNameVisible="aSortedTopSelectionByRating[vRating.third].name_visible"
                :aItems="ixTopSelection[ixTopSelectionId[vRating.third]].list_item"
                :nRemainingItems="ixTopSelection[ixTopSelectionId[vRating.third]].cnt_item_of_collection"
                :bSelectionItemsLoading="bSelectionItemsLoading"
                :fGetItems="fGetListItem(ixTopSelectionId[vRating.third])"
                :bFromHome="true"
            />

            <!-- Блок баннеров -->
            <div class="home-page__banners" v-if="status.is_desktop">
                <HomeBanner
                    :bannerType="tBannerType.mini"
                    :banner="ixBanners[tBannerPlaceAlias.homeBPageLeft2]"
                    alias="homeBPageLeft2"
                />
                <HomeBanner
                    :bannerType="tBannerType.mini"
                    :banner="ixBanners[tBannerPlaceAlias.homeBPageRight2]"
                    alias="homeBPageRight2"
                />
            </div>
            <div class="home-page__banners home-page__banners_mob" v-else>
                <HomeBanner
                    :bannerType="tBannerType.mini"
                    :banner="ixBanners[tBannerPlaceAlias.homeBPageMobile3]"
                    alias="homeBPageRight1"
                >
                </HomeBanner>
            </div>

            <TopSelection
                v-if="
                    ixTopSelectionId[vRating.fourth] &&
                    ixTopSelection[ixTopSelectionId[vRating.fourth]] &&
                    ixTopSelection[ixTopSelectionId[vRating.fourth]].list_item &&
                    ixTopSelection[ixTopSelectionId[vRating.fourth]].list_item.length
                "
                :id="aSortedTopSelectionByRating[vRating.fourth].id"
                :sNameVisible="aSortedTopSelectionByRating[vRating.fourth].name_visible"
                :aItems="ixTopSelection[ixTopSelectionId[vRating.fourth]].list_item"
                :nRemainingItems="ixTopSelection[ixTopSelectionId[vRating.fourth]].cnt_item_of_collection"
                :bSelectionItemsLoading="bSelectionItemsLoading"
                :fGetItems="fGetListItem(ixTopSelectionId[vRating.fourth])"
                :bFromHome="true"
            />

            <!-- Лучшие подборки (нижние)-->
            <Brands
                v-if="
                    fGetSplicedBanners(tBannerSelectionNumber.first) &&
                    fGetSplicedBanners(tBannerSelectionNumber.first).length
                "
                :aBannerSelection="fGetSplicedBanners(tBannerSelectionNumber.second)"
            />

            <TopSelection
                v-if="
                    ixTopSelectionId[vRating.fifth] &&
                    ixTopSelection[ixTopSelectionId[vRating.fifth]] &&
                    ixTopSelection[ixTopSelectionId[vRating.fifth]].list_item &&
                    ixTopSelection[ixTopSelectionId[vRating.fifth]].list_item.length
                "
                :id="aSortedTopSelectionByRating[vRating.fifth].id"
                :sNameVisible="aSortedTopSelectionByRating[vRating.fifth].name_visible"
                :aItems="ixTopSelection[ixTopSelectionId[vRating.fifth]].list_item"
                :nRemainingItems="ixTopSelection[ixTopSelectionId[vRating.fifth]].cnt_item_of_collection"
                :bSelectionItemsLoading="bSelectionItemsLoading"
                :fGetItems="fGetListItem(ixTopSelectionId[vRating.fifth])"
                :bFromHome="true"
                ref="penultimate_static_selection"
            />

            <TopSelection
                v-if="
                    ixTopSelectionId[vRating.sixth] &&
                    ixTopSelection[ixTopSelectionId[vRating.sixth]] &&
                    ixTopSelection[ixTopSelectionId[vRating.sixth]].list_item &&
                    ixTopSelection[ixTopSelectionId[vRating.sixth]].list_item.length
                "
                :id="aSortedTopSelectionByRating[vRating.sixth].id"
                :sNameVisible="aSortedTopSelectionByRating[vRating.sixth].name_visible"
                :aItems="ixTopSelection[ixTopSelectionId[vRating.sixth]].list_item"
                :nRemainingItems="ixTopSelection[ixTopSelectionId[vRating.sixth]].cnt_item_of_collection"
                :bSelectionItemsLoading="bSelectionItemsLoading"
                :fGetItems="fGetListItem(ixTopSelectionId[vRating.sixth])"
                :bFromHome="true"
            />

            <template v-for="nNumber in nDisplayedTopSelectionCount">
                <TopSelection
                    v-if="
                        nNumber > nInitiaSelectionCount &&
                        aSortedTopSelectionByRating[nNumber - 1] &&
                        ixTopSelection[ixTopSelectionId[nNumber - 1]].list_item &&
                        ixTopSelection[ixTopSelectionId[nNumber - 1]].list_item.length
                    "
                    :key="nNumber"
                    :id="aSortedTopSelectionByRating[nNumber - 1].id"
                    :sNameVisible="aSortedTopSelectionByRating[nNumber - 1].name_visible"
                    :aItems="ixTopSelection[ixTopSelectionId[nNumber - 1]].list_item"
                    :nRemainingItems="ixTopSelection[ixTopSelectionId[nNumber - 1]].cnt_item_of_collection"
                    :bSelectionItemsLoading="bSelectionItemsLoading"
                    :fGetItems="fGetListItem(ixTopSelectionId[nNumber - 1])"
                    :bFromHome="true"
                />
            </template>

            <!-- Геолокация -->
            <HomeCities />

            <!-- О нас -->
            <HomeAboutsUsBottom />

            <!-- Доп. действия пользователя -->
            <HomeUserActions />

            <!-- Модальное окно вы не залогинены/не авторизованы -->
            <ModalUserIsNotSignIn
                v-if="status.is_modal_user_not_sign_in"
                @onClose="status.is_modal_user_not_sign_in = false"
                @onGoSignIn="fOnGoSignIn"
                @onGoSignUp="fOnGoSignUp"
            />
        </div>
        <div ref="background" class="background" />
        <!-- <Ballons v-if="isClient" /> -->
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import P63Vue from '@/system/P63Vue';
import { gJivoSys } from '@/system/JivoSys';
import { BannerPlaceAliasT } from '@/ifc/core/EntitySQL/P63BannerE';
import { BannerI } from '@/ifc/core/CommonI/BannerI';
import { gLinkS } from '@/common/service/LinkS';
import { mIsClient } from '@/common/helpers/ContextH';
import { mShuffleArray } from '@/common/helpers/ArrayH';
import SearchInput from '@/common/el/SearchInput/SearchInput.vue';
import TopSelection from '@/common/el/TopSelection/TopSelection.vue';
import Brands from '@/common/el/Brands/Brands.vue';

import { HomeCtrl, initHomeCtrl } from '@/pages/home/view/ctrl-home';
import { HomeN as N } from '@/pages/home/view/ifc-home';
import HomeRegistration from '@/pages/home/el/home-registration/home-registration.vue';
import HomeBanner from '@/pages/home/el/home-banner/home-banner.vue';
import HomeAboutsUsBottom from '@/pages/home/el/home-about-us-bottom/home-about-us-bottom.vue';
import HomeCities from '@/pages/home/el/home-cities/home-cities.vue';
import HomeUserActions from '@/pages/home/el/home-user-actions/home-user-actions.vue';
import HomeStoryD from '@/pages/home/el/home-story/home-story-d.vue';
import HomeStoryM from '@/pages/home/el/home-story/home-story-m.vue';
import HomeNews from '@/pages/home/el/home-news/home-news.vue';
// import Ballons from '@/pages/home/el/ballons/ballons.vue';

@Component({
    components: {
        HomeRegistration,
        HomeBanner,
        TopSelection,
        HomeNews,
        Brands,
        HomeStoryD,
        HomeStoryM,
        HomeAboutsUsBottom,
        HomeCities,
        HomeUserActions,
        SearchInput,
        // Ballons,
    },
})
export default class Page extends P63Vue {
    isClient: boolean = false;
    /** Родительский элемент поисковой строки */
    elSearchBar: HTMLElement = null;
    /** Элемент - инпут поисковой строки */
    elSearchInput: HTMLElement = null;
    /** Бэкграунд при фокусе на инпут */
    elBackground: HTMLElement = null;

    /** Позиции рейтинга топ-подборок */
    vRating: Record<string, number> = {
        first: 0,
        second: 1,
        third: 2,
        fourth: 3,
        fifth: 4,
        sixth: 5,
    };
    /** Контейнер для главной страницы в режиме App */
    vBodyApp: HTMLDivElement;

    /** Хранилище */
    get ctrl(): HomeCtrl {
        return initHomeCtrl(mIsClient() ? this.$context : this.$ssrContext);
    }

    get list() {
        return this.ctrl.list;
    }

    get status() {
        return this.ctrl.status;
    }

    get ix() {
        return this.ctrl.ix;
    }

    get tree() {
        return this.ctrl.tree;
    }

    /** Признак авторизации */
    get isAuth() {
        return this.ctrl.userSys.user.isAuth;
    }

    /** Бренды для подборок */
    get aBrands() {
        return this.ctrl.list.brands;
    }

    /** Номера баннерных подборок товаров */
    get tBannerSelectionNumber() {
        return N.BrandNumberT;
    }

    /** Количество топ-подборок при первичной отрисовке */
    get nInitiaSelectionCount() {
        return this.status.initial_selection_count;
    }

    /** Перемешанные товары для баннерных подборок */
    get aShuffledBrandBanner() {
        return mShuffleArray(this.ctrl.ix.banners_by_alias[this.tBannerPlaceAlias.homeSelections]) ?? [];
    }

    /** Количество баннеров в одной строке (подборке) */
    get nBrandBannersInRow() {
        return this.ctrl.status.is_mobile ? N.BrandGridColumnsT.mobile : N.BrandGridColumnsT.tablet_or_desktop;
    }

    /** Массив верхних баннеров */
    get aTopBannerList() {
        return this.ctrl.list.top_banner;
    }

    /** Индексированный по алиасу список массивов с баннерами */
    get ixBanners() {
        return this.ctrl.ix.banners_by_alias;
    }

    /** Количество отображенных топ-подборок */
    get nDisplayedTopSelectionCount() {
        return this.ctrl.status.displayed_top_selection_count;
    }

    /** Топ-подборки, сортированные по рейтингу */
    get aSortedTopSelectionByRating() {
        return this.ctrl.list.top_selection_sorted_by_rating ?? [];
    }

    /** Топ-подборки, сортированные по collection_id */
    get ixTopSelection() {
        return this.tree.ix_top_selection;
    }

    /** id топ-подборок, сортированные по рейтингу */
    get ixTopSelectionId() {
        return this.ix.top_selection_id_by_rating;
    }

    /** Загружаются ли товары в подборку */
    get bSelectionItemsLoading() {
        return this.status.is_selection_items_loading;
    }

    /** Алиасы баннерных мест */
    get tBannerPlaceAlias() {
        return BannerPlaceAliasT;
    }

    /** Типы баннеров (по положению и размеру) */
    get tBannerType() {
        return N.BannerTypeT;
    }

    /** Количество товаров в топ-подборках, которые видны при первой отрисовке */
    get nSyncItemCard() {
        let nSyncItemCard = 2;

        if (this.status.is_tablet) {
            nSyncItemCard = 4;
        }

        if (this.status.is_desktop) {
            nSyncItemCard = 6;
        }

        return nSyncItemCard;
    }

    /** Открыт ли сайт с приложения */
    get isApp() {
        return this.ctrl.isApp;
    }

    /** Получить диапазон товаров для текущей баннерной подборки */
    fGetSplicedBanners(nBrandNumber: N.BrandNumberT) {
        let aSplicedBanner: BannerI[] = [];

        if (this.aShuffledBrandBanner?.length > 0) {
            const nStart = nBrandNumber * this.nBrandBannersInRow;
            const nEnd = nStart + this.nBrandBannersInRow;

            aSplicedBanner = this.aShuffledBrandBanner.slice(nStart, nEnd);
        }

        return aSplicedBanner;
    }

    /** Залогиниться */
    fOnGoSignIn() {
        window.location.href = gLinkS.fGetLoginUrl(window.location.href);
    }

    /** Авторизоваться */
    fOnGoSignUp() {
        window.location.href = gLinkS.fGetRegisterUrl(window.location.href);
    }

    /** Обработчик фокуса на поисковой строке */
    fSearchFocusHandler() {
        this.elSearchBar.style.zIndex = '110';
        this.elBackground.style.display = 'inline-block';
        document.body.classList.add('stop-scrolling');
        gJivoSys.fHideWidget();
    }

    /** Обработчик блура поисковой строки */
    fSearchBlurHandler() {
        this.elSearchBar.style.zIndex = '10';
        this.elBackground.style.display = 'none';
        document.body.classList.remove('stop-scrolling');
        gJivoSys.fShowWidget();
    }

    /** Получить карточки коллекции */
    fGetListItem(nRating: number) {
        return () => this.ctrl.faGetListItemOfCollection(nRating);
    }

    /** Обработать скролл для подгрузки подборок */
    // Для разработчика: проверить аналогичный метод в Hit Category page, если вносятся изменения
    async fHandleSelectionScroll() {
        /** Топ-подобрка, которая будет отображена следующей */
        const vNextDisplayedSelection =
            this.list.top_selection_sorted_by_rating[this.status.displayed_top_selection_count];

        // Расстояние от низа документа до низа вьюпорта c учётом скролла
        const nFromDocumentToViewport = document.documentElement.scrollHeight - window.innerHeight - window.pageYOffset;

        // Условие для проверки того, достиг ли пользователь триггерной линии
        const bTriggerLineCrossed = nFromDocumentToViewport <= this.status.from_document_to_trigger_line;

        // Проверить достижение триггерной линии, отсутствие загрузки подборки и наличие следующей подборки
        const bScrollHandlerAllowed =
            bTriggerLineCrossed && !this.status.is_selections_loading && vNextDisplayedSelection;

        if (bScrollHandlerAllowed) {
            this.status.is_selections_loading = true;

            // Загрузить товары следующей топ-подборки
            await this.ctrl.faGetListItemOfCollection(vNextDisplayedSelection.id);
        }

        // Снять слушатель, если отобразилась последняя топ-подборка
        if (this.status.displayed_top_selection_count === this.list.top_selection_sorted_by_rating.length) {
            window.removeEventListener('scroll', this.fHandleSelectionScroll);
        }
    }

    /** Обработать скролл для подгрузки подборок в режиме App*/
    async fHandleSelectionScrollApp() {
        /** Топ-подобрка, которая будет отображена следующей */
        const vNextDisplayedSelection =
            this.list.top_selection_sorted_by_rating[this.status.displayed_top_selection_count];

        // Расстояние от низа документа до низа вьюпорта c учётом скролла
        const nFromDocumentToViewport =
            this.vBodyApp.scrollHeight - this.vBodyApp.clientHeight - this.vBodyApp.scrollTop;

        // Условие для проверки того, достиг ли пользователь триггерной линии
        const bTriggerLineCrossed = nFromDocumentToViewport <= this.status.from_document_to_trigger_line;
        // Проверить достижение триггерной линии, отсутствие загрузки подборки и наличие следующей подборки
        const bScrollHandlerAllowed =
            bTriggerLineCrossed && !this.status.is_selections_loading && vNextDisplayedSelection;

        if (bScrollHandlerAllowed) {
            this.status.is_selections_loading = true;

            // Загрузить товары следующей топ-подборки
            await this.ctrl.faGetListItemOfCollection(vNextDisplayedSelection.id);
        }

        // Снять слушатель, если отобразилась последняя топ-подборка
        if (this.status.displayed_top_selection_count === this.list.top_selection_sorted_by_rating.length) {
            this.vBodyApp.removeEventListener('scroll', this.fHandleSelectionScrollApp);
        }
    }

    /** Зафиксировать триггерную линию по низу предпоследней статичной топ-подборки */
    fFixTriggerLine(objStaticSelection: Vue) {
        if (objStaticSelection) {
            const vSelectionCoords = objStaticSelection.$el.getBoundingClientRect();
            if (this.isApp) {
                this.status.from_document_to_trigger_line = this.vBodyApp.scrollHeight - vSelectionCoords.bottom;
            } else {
                this.status.from_document_to_trigger_line =
                    document.documentElement.scrollHeight - vSelectionCoords.bottom;
            }
        }
    }

    mounted() {
        this.isClient = true;
        if (this.status.is_mobile && !this.isApp) {
            this.elSearchBar = this.$refs.searchbar as HTMLElement;
            this.elBackground = this.$refs.background as HTMLElement;
            this.elSearchInput = this.elSearchBar.querySelector('#inputSearchWithHints');

            if (this.elSearchInput && this.elBackground) {
                this.elSearchInput.onfocus = this.fSearchFocusHandler;
                this.elSearchInput.onblur = this.fSearchBlurHandler;
            }
        }

        if (this.isApp) {
            this.vBodyApp = document.documentElement.querySelector('.body-app') as HTMLDivElement;
            this.vBodyApp.addEventListener('scroll', this.fHandleSelectionScrollApp);
        }

        // Зафиксировать триггерную линию по низу предпоследней статичной топ-подборки,
        // чтобы при её достижении подгружались новые топ-подборки
        this.fFixTriggerLine(this.$refs.penultimate_static_selection as Vue);

        window.addEventListener('scroll', this.fHandleSelectionScroll);
    }

    beforeDestroy() {
        window.removeEventListener('scroll', this.fHandleSelectionScroll);
        if (this.isApp) {
            this.vBodyApp.removeEventListener('scroll', this.fHandleSelectionScrollApp);
        }
    }
}
</script>

<style lang="scss" scoped>
.home-page {
    &__search-mobile {
        position: relative;

        display: block;

        margin-bottom: 20px;
        padding: 0 15px;

        @media (min-width: 1024px) {
            display: none;
        }

        &_no-auth {
            padding: 0 15px 0;

            @media (min-width: 1024px) {
                padding-top: 0;
            }
        }
    }

    &__top-banners {
        height: auto;
        min-height: 219px;
        padding: 0;

        @media (min-width: 768px) {
            min-height: 269px;
            padding: 0 15px;
        }

        @media (min-width: 1024px) {
            min-height: 312px;
            padding: 0 20px;
        }
    }

    &__registration {
        margin: 24px 20px 0;
    }

    &__content {
        display: flex;
        gap: 40px;
        flex-direction: column;
        flex-wrap: nowrap;

        margin-top: 40px;
        padding: 0 20px;

        @media (min-width: 768px) {
            gap: 60px;

            margin-top: 60px;
        }
    }

    &__banners {
        display: flex;
        justify-content: center;
        gap: 24px;
        flex-wrap: wrap;

        @media (min-width: 1024px) {
            flex-wrap: nowrap;
        }
    }
}

.background {
    position: fixed;
    z-index: 70;
    top: 0;
    left: 0;

    display: none;

    width: 100vw;
    height: 100%;
    min-height: 100vh;

    background: rgba(96, 98, 102, 0.6);
}

.top-banners_app {
    margin-top: 15px;
}
</style>

<style lang="scss">
.home-page {
    .hide-categories {
        .category {
            display: none;
        }
    }
}

.stop-scrolling {
    overflow: hidden;

    height: 100%;
}
</style>

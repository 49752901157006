import { BaseCtrl, VuexSys } from '@63pokupki/front';
import { MenuSys } from './MenuSys';
import { UserSys } from './UserSys';
import { LogicSys } from './LogicSys';
import { RouteSys } from './RouteSys';
import { HeaderSys } from './HeaderSys';
import { SearchSys } from './SearchSys';
import { ItemBundleSys } from './ItemBundleSys';
import { mIsClient, mIsServer } from '@/common/helpers/ContextH';
import { StatSys } from './StatSys';
import { NotFoundSys } from './NotFoundSys';
import { ModalNotificationSys } from './ModalNotificationSys'
import { WishSys } from './WishSys';
import { FooterSys } from './FooterSys';

/** Конфигурация контрллера при его создании */
export interface OptionCtrlI {
  preserveState?: boolean; // Сохранять предыдущее состояние на фронте
  time?: number; // Время создания
  apikey?: string; // Ключ доступа для запроса сервер сервер
  srvkey?: string; // Ключ для межсерверного запроса
  deviceType?: string; // Тип устройства
  isApp?: boolean; // Является ли приложением
}

export class PageCtrl extends BaseCtrl {
  public _apikey: string = null; // ключ для доступа к api
  public _time: number = null; // время создания контроллера, используется для его удаления
  public _srvkey: string = null; // ключ для межсерверного запроса
  public deviceType: string = null; // Тип устройства
  public userSys: UserSys = null; // Система по управлению пользователями
  public menuSys: MenuSys = null; // Система по управлению пользователями
  public logicSys: LogicSys = null; // Логические операции
  public routeSys: RouteSys = null; // Система по управлению route
  public headerSys: HeaderSys = null; // Система по управлению header-a
  public searchSys: SearchSys = null; // Система по управлению search
  public itemBundleSys: ItemBundleSys = null; // Система по управлению item-bundle
  public statSys: StatSys = null; // Система по управлению статистикой
  public notFoundSys: NotFoundSys = null; // Система по управлению 404 компонентом
  public modalNotificationSys: ModalNotificationSys = null; // Система по управлению отображением модальных окон
  public wishSys: WishSys = null; // Система по управлению списком желаний
  public footerSys: FooterSys = null;
  public isApp: boolean = false; // Статус приложения

  public isClient = false;
  public isServer = false;

  constructor(vuexSys: VuexSys, option?: OptionCtrlI) {
    super(vuexSys);

    this.isClient = mIsClient();
    this.isServer = mIsServer();

    if (option) {
      if (this.isServer && option) {
        this._apikey = option.apikey;
        this._time = option.time;
        this._srvkey = option.srvkey
        this.deviceType = option.deviceType;
      }
      this.isApp = option.isApp;
    }

    this.userSys = new UserSys(this);
    this.menuSys = new MenuSys(this);
    this.logicSys = new LogicSys(this);
    this.routeSys = new RouteSys(this);
    this.headerSys = new HeaderSys(this);
    this.searchSys = new SearchSys(this);
    this.itemBundleSys = new ItemBundleSys(this);
    this.statSys = new StatSys(this);
    this.notFoundSys = new NotFoundSys(this);
    this.modalNotificationSys = new ModalNotificationSys(this);
    this.wishSys = new WishSys(this);
    this.footerSys = new FooterSys(this)
  }

  /** Инициализация */
  async faInit() {
    const aPromise = [];

    if (this.isServer) {
      if (this._apikey) {
        aPromise.push(this.userSys.faInit());
      }
      aPromise.push(this.menuSys.faInit());

      await Promise.all(aPromise);
    }
  }
}

<template>
    <div class="org-provider-banners">
        <div v-for="(vBannerInfo, idx) in aBannerInfo" :key="idx" class="org-provider-banners__banner">
            <!-- <a class="org-provider-banners__link" :href="vBannerInfo.link">Перейти</a> -->
            <picture>
                <source :srcset="vBannerInfo.webp" />
                <img
                    class="org-provider-banners__image"
                    width="592"
                    height="264"
                    loading="lazy"
                    decoding="async"
                    :src="vBannerInfo.png"
                    :alt="vBannerInfo.alt"
                    @click="fGoToLink(vBannerInfo.link)"
                />
            </picture>
        </div>
    </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import P63Vue from '@/system/P63Vue';
import { mGetTargetLink } from '@/common/helpers/TargetLinkH';
import { HomeCtrl, initHomeCtrl } from '@/pages/home/view/ctrl-home';
import { mIsClient } from '@/common/helpers/ContextH';

@Component
export default class HomeUserActions extends P63Vue {

    /** Переход по ссылке */
    fGoToLink(link: string){
    /** Отправить данные в статистику о клике по баннеру */
        if (link.includes('school')) {
            this.ctrl.statSys.faPushClickToStat({
            page: location.pathname,
            type: 'banner',
            alias: 'school_click',
            entity_id: 0,
          });
        } else {
            this.ctrl.statSys.faPushClickToStat({
            page: location.pathname,
            type: 'banner',
            alias: 'vendor_click',
            entity_id: 0,
          });
        }
        window.open(link, mGetTargetLink());
    }
    
    /** Ссылки и альт-текст для баннеров */
    get aBannerInfo() {
        return [
            {
                link: 'https://school.63pokupki.ru/',
                png: ' https://design.63pokupki.ru/common/home-page/user-actions/org2.png',
                webp: 'https://design.63pokupki.ru/common//home-page/user-actions/org2.webp',
                alt: 'Приглашаем организаторов',
            },
            {
                link: 'https://vendor.63pokupki.ru/',
                png: 'https://design.63pokupki.ru/common/home-page/user-actions/provider2.png',
                webp: 'https://design.63pokupki.ru/common/home-page/user-actions/provider2.webp',
                alt: 'Приглашаем поставщиков',
            },
        ];
    }

    /** Хранилище */
    get ctrl(): HomeCtrl {
        return initHomeCtrl(mIsClient() ? this.$context : this.$ssrContext);
    }
}
</script>

<style lang="scss" scoped>
.org-provider-banners {
    display: flex;
    gap: 38px;
    flex-direction: column;
    flex-wrap: nowrap;

    max-width: 592px;
    margin: 50px auto;

    @media (min-width: 768px) {
        flex-direction: row;

        max-width: 100%;
        margin: 50px 0 30px;
    }

    &__banner {
        position: relative;
    }

    &__banner:hover {
        cursor: pointer;
    }

    &__link {
        position: absolute;
        bottom: calc(39 / 264 * 100%);
        left: calc(50 / 592 * 100%);

        display: block;

        width: calc(171 / 592 * 100%);
        height: calc(41 / 264 * 100%);

        transition: box-shadow 0.3s;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;

        color: #ffffff;
        border-radius: 41px;
        background: #ff39a2;
        box-shadow: none;

        font-size: 3.46vw;
        font-weight: 700;
        line-height: 2;

        @media (min-width: 592px) {
            font-size: 21px;
        }

        @media (min-width: 768px) {
            font-size: 1.64vw;
        }

        @media (min-width: 1240px) {
            font-size: 21px;
        }

        &:hover {
            transition: none;

            box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.25);
        }
    }

    &__image {
        display: block;

        width: 100%;
        height: auto;
    }
}
</style>

const introJs = require('@63pokupki/onboarding-core');
import axios from 'axios';
import { v4 as uuid} from 'uuid';
import * as config from '@/configs/config.public';
import { OnboardingNoAuthR } from '@/ifc/core/OnboardingNoAuthR';

/**
 * Набор событий, которые могут быть выполнены перед/после шага
 */
interface IOnboardingEvents {
    [event: string]: Function;
}

/**
 * Интерфейс таблицы Onboarding - шаги подсказок на странице
 */
interface P63OnboardingStepI {
    id?: number; // ID
    onboarding_page_id?: number; // ID страницы
    name?: string; // понятное имя шага - например welcome / 1 шаг
    text?: string; // html - текст шага
    step_number?: number; // номер шага
    tpl?: string; // шаблон - внешний вид шага - css селектор
    selector?: string; // идентификатор класса или id элемента на странице на который навешан шаг
    type?: string; // шаг цепочки версии (мобильной или десктопной
    btn_view?: string; // текст кнопки просмотр
    btn_next?: string; // текст кнопки далее
    btn_skip?: string; // текст кнопки пропустить
    btn_end?: string; // текст кнопки закрыть',
    event_before?: string; // JS событие срабатывающее до отображения шага
    event_after?: string; // JS событие срабатывающее после скрытия шага
}

/**
 * Подсказки по сайту для пользователей
 */
class Onboarding {
    /**
     * Стандартные настройки
     */
    public options = {
        tooltipClass: 'onboarding-base-steps',
        skipLabel: 'Пропустить',
        doneLabel: 'Закончить',
        nextLabel: 'Далее',
        hidePrev: true,
        hideNext: true,
        showProgress: true,
        showBullets: false,
        showStepNumbers: false,
        scrollTo: 'tooltip',
        disableInteraction: true,
        exitOnOverlayClick: false,
        touchpoint: 1024,
    };

    /**
     * Список шагов для сценария
     */
    public steps;

    /**
     * Идентификатор человека
     */
    public uuid;

    /**
     * Ключ идентификатора человека в localstorage
     */
    public uuidLocalStorageKey: string = 'onboarding_uuid';

    /**
     * Идентификатор готовности
     */
    public isReady: boolean = true;

    /**
     * Коллбэк при клике на кнопку закрытия
     */
    private cbOnClickClose: Function;

    /**
     * Клик по кнопке закрытия онбординга
     */
    public fClickCloseBtn(cbOnClickClose: Function) {
        this.cbOnClickClose = cbOnClickClose;
    }
    private stockOnboardingAlias = 'onboarding_stock_page';

    private itemOnboardingAlias = 'onboarding_item_page';
    /**
     * Возможные ошибки
     */
    private _errors = {
        enviromentIsNotExist: 'Окружение исполнения не доступно',
        aliasIsNotExist: 'Идентификатор страницы не передан',
        scenarioIsNotExist: 'Сценарий для данной страницы отсутствует',
    };

    private intro;

    private scrollTo: Function;

    constructor(public alias: string, public events?: IOnboardingEvents) {
        this.intro = new introJs();
        this.scrollTo = scrollTo;
    }

    /**
     * Запуск сценария, основная функция
     */
    public play() {
        try {
            this.intro.start();

            this.initCloseButton();

            if(this.alias !== this.stockOnboardingAlias) {
                setTimeout(() => this.scrollTo(), 500);
            }

            this.setOverflowProperty();
        } catch (e) {
            console.error(e);
        }
    }

    /**
     * Инициализация
     */
    public async init() {
        try {
            // проверка на готовность
            if (!this.isEnviromentExist()) {
                throw new Error(this._errors.enviromentIsNotExist);
            }

            // проверка на идентификатор страницы
            if (!this.alias) {
                throw new Error(this._errors.aliasIsNotExist);
            }

            // получение uuid
            this.getUUID();

            // получение сценария с сервера
            await this.getStepsView();

            // сценария нет
            if (!this.steps) {
                console.log(this._errors.scenarioIsNotExist);
                return;
            }

            this.intro = this.fUpdatePropertiesStepByStepHook();

            return true;
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    /**
     * Получение и установка uuid
     */
    private getUUID() {
        try {
            // uuid существует
            const existedId = localStorage.getItem(this.uuidLocalStorageKey);
            if (existedId) {
                this.uuid = existedId;
                return existedId;
            }

            // uuid не существует, генерируем
            const id = uuid();
            localStorage.setItem(this.uuidLocalStorageKey, id);
            this.uuid = id;
            return id;
        } catch (e) {
            console.error(e);
        }
    }

    /**
     * Установка uuid
     */
    private setUUID(id) {
        try {
            localStorage.setItem(this.uuidLocalStorageKey, id);
        } catch (e) {
            console.error(e);
        }
    }

    /**
     * Проверка на доступность среды
     */
    private isEnviromentExist() {
        if (window && window.document) {
            return true;
        }

        return false;
    }

    /**
     * Получить шаги для отображения пользователю
     */
    private async getStepsView() {
        try {
            const options = {
                obkey: this.uuid,
                alias: this.alias,
            };

            // const {
            //     data: { is_active, list_m_step, list_d_step, obkey },
            // }
            let is_active = null;
            let list_m_step = null;
            let list_d_step = null;
            let obkey = null;
            await axios
                .post(config.coreApi.baseURL + OnboardingNoAuthR.listStep.route, options)
                .then(function(response) {
                    is_active = response.data.data.is_active;
                    list_m_step = response.data.data.list_m_step;
                    list_d_step = response.data.data.list_d_step;
                    obkey = response.data.data.obkey;
                })
                .catch(function(error) {
                    console.log(error);
                });

            // переназначаем uuid в случае если пользователь сменился (уменьшает кол-во запросов к бд на беке)
            if (obkey) {
                this.setUUID(obkey);
            }

            // если сценарий не должен запуститься ничего не возвращаем
            if (!is_active) {
                return;
            }

            let steps = null;

            // определение ширины устройства
            if (this.isTouchDevice()) {
                if (list_m_step.length == 0) {
                    return;
                }
                steps = this.transformStepsForLibrary(list_m_step);
            } else {
                if (list_d_step.length == 0) {
                    return;
                }
                steps = this.transformStepsForLibrary(list_d_step);
            }

            this.steps = steps;

            return steps;
        } catch (e) {}
    }

    private transformStepsForLibrary(steps: P63OnboardingStepI[]) {
        try {
            const sorted_steps = steps.sort((a, b) => a.step_number - b.step_number);

            return sorted_steps.map(step => {
                const optionsObj = {
                    intro: step.text || 'Текст отсутствует',
                    element: step.selector ? this.getElement(step.selector) : null,
                    tooltipClass: step.tpl || this.options.tooltipClass,
                    skipLabel: step.btn_skip || this.options.skipLabel,
                    doneLabel: step.btn_end || this.options.doneLabel,
                    nextLabel: step.btn_next || this.options.nextLabel,
                    beforeStep: step.event_before,
                    afterStep: step.event_after,
                };

                if(this.alias === this.stockOnboardingAlias || this.alias === this.itemOnboardingAlias) {
                    //@ts-ignore
                    optionsObj.scrollTo = "off";
                    //@ts-ignore
                    optionsObj.position = "top";
                }
                return optionsObj;
            });
        } catch (e) {
            console.error(e);
        }
    }

    /**
     * Определение режима показа - мобильные/десктоп
     */
    private isTouchDevice() {
        try {
            const w = document.documentElement.clientWidth;

            if (w <= this.options.touchpoint) {
                return true;
            } else {
                return false;
            }
        } catch (e) {
            console.error(e);
        }
    }

    /**
     * Проверка видимости (существования) элемента
     * @param {} element - элемент
     */
    private isElementExist(element) {
        try {
            if (!element) {
                throw new Error('Элемент не передан');
            }

            const { height, width } = element.getBoundingClientRect();

            if (height !== 0 && width !== 0) {
                return true;
            } else {
                return false;
            }
        } catch (e) {
            console.error(e);
        }
    }

    /**
     * Получение видимого элемента по css селектору
     * @param {String} selector - css валидный селектор
     */
    private getElement(selector) {
        try {
            if (!selector) {
                throw new Error('Селектор не передан');
            }

            const elements = Array.prototype.slice.call(document.querySelectorAll(selector));

            const visibleElement = elements.find(el => this.isElementExist(el));

            if (!visibleElement) {
                console.log('Элемент: ' + selector + ' не найден');
                return;
            }

            return visibleElement;
        } catch (e) {
            console.error(e);
        }
    }

    /**
     * Обновление настроек под каждый шаг подсказок, смена текста, оформления и тд
     */
    private fUpdatePropertiesStepByStepHook() {
        try {
            const self = this;
            // установка стандартных настроек
            self.intro.setOptions({
                ...self.options,
                steps: self.steps,
            });

            // переопределение настроек под каждый шаг
            self.intro.onbeforechange(() => {
                let step = self.steps[self.intro._currentStep];

                self.intro.setOptions({
                    ...self.options,
                    nextLabel: step.nextLabel || self.options.nextLabel,
                    doneLabel: step.doneLabel || self.options.doneLabel,
                    skipLabel: step.skipLabel || self.options.skipLabel,
                    tooltipClass: step.tooltipClass || self.options.tooltipClass,
                });

                // хук для события перед показом шага
                if (self.events && step.beforeStep) {
                    if (self.events.hasOwnProperty(step.beforeStep)) {
                        self.events[step.beforeStep]();
                    }
                }
            });

            // переопределение настроек под каждый шаг
            self.intro.onafterchange(() => {
                const step = self.steps[self.intro._currentStep];
                const lastStep = self.steps.length - 1;
     
                if((this.alias === this.stockOnboardingAlias || this.alias === this.itemOnboardingAlias) && step !== 0 && step !== lastStep) {
                    setTimeout(() => {
                        const area_label = document.querySelector('.introjs-helperLayer');
                        const tooltip = document.querySelector('.introjs-tooltip');
                 
                        let aStyleOfElem = area_label.getAttribute('style').trim().split("top:");
                        let sTopStyleOfElem = aStyleOfElem[aStyleOfElem.length - 1].split(';')[0];
                        let nPxCount = +sTopStyleOfElem.trim().substr(0, sTopStyleOfElem.length - 3);

                        window.scrollTo({
                            top: nPxCount - tooltip.getBoundingClientRect().height - 50,
                            behavior: "smooth"
                        });
                    }, 300);
                } else {
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    });
                }

                // хук для события после показа шага
                if (self.events && step.afterStep) {
                    if (self.events.hasOwnProperty(step.afterStep)) {
                        self.events[step.afterStep]();
                    }
                }
            });

            return self.intro;
        } catch (e) {
            console.error(e);
        }
    }

    /**
     * Инициализация кнопки Закрыть
     */
    private initCloseButton() {
        try {
            let el = document.querySelector('.introjs-tooltip');

            // создание иконки крестика
            let icon = document.createElement('i');
            icon.className = 'ds-icon icon-close';

            // создание кнопки
            const elCloseButton = document.createElement('button');
            elCloseButton.append(icon);
            elCloseButton.className = `introjs-tooltip__close`;
            elCloseButton.addEventListener('click', () => {
                if (this.cbOnClickClose){
                    this.cbOnClickClose();
                }
                this.intro.exit(true)
            });

            // добавление кнопки
            if (el) {
                el.append(elCloseButton);
            }
        } catch (e) {
            console.error(e);
        }
    }

    private setOverflowProperty() {
        try {
            let el = document.querySelector('.introjs-tooltip');

            if (!el || !this.isTouchDevice()) {
                return;
            }

            el.scrollTop = 0;
        } catch (e) {
            console.error(e);
        }
    }
}

/**
 * Прокрутка
 * @param {Number} x - координата X прокрутки
 * @param {Number} y - координата Y прокрутки
 */
export const scrollTo = (x: number = 0, y: number = 0) => {
    try {
        const options: ScrollToOptions = {
            top: x,
            left: y,
            behavior: 'smooth',
        };

        setTimeout(() => {
            window.scrollTo(options);
        }, 25);
    } catch {
        window.scrollTo(x, y);
    }
};

export default Onboarding;

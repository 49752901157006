import Component from 'vue-class-component';
import dayjs from 'dayjs';

import P63Vue from '@/system/P63Vue';
import { PageCtrl } from '@/system/PageCtrl';
import { gfInitContextCtrl } from '@/system/ContextSys';
import { gLinkS } from '@/common/service/LinkS';
import { mIsClient } from '@/common/helpers/ContextH';
import icon from '@/ds/el/icon/icon';
import HeaderLogo from '@/common/el/NewHeader/el/header-logo.vue';
import { SocialLinkN } from '@/common/interface/SocialLinkN';

@Component({
    components: {
        HeaderLogo,
        Icon: icon,
    },
})

export default class NewFooter extends P63Vue {
    /** Флаг для переключения списков */
    aToggleLinks = [true, true, true, true];

    isClient: boolean = false;

    /** Переключение списков */
    fToggleLinks(id: number) {
        if (!this.isDesktop) {
            this.aToggleLinks[id] = !this.aToggleLinks[id];
            this.aToggleLinks = [...this.aToggleLinks];
        }
    }

    /** Открыть модальное окно отправки жалобы */
    fOpenAddClaimModal() {
        this.ctrl.footerSys.footer.is_modal_send_claim_open = true;
    }

    /** Редирект на вход */
    onGoSignIn() {
        window.location.href = gLinkS.fGetLoginUrl(window.location.href);
    }
    /** Редирект на регистрацию */
    onGoSignUp() {
        window.location.href = gLinkS.fGetRegisterUrl(window.location.href);
    }

    /** Закрытие модального окна */
    fCloseAddClaimModal() {
        this.ctrl.footerSys.footer.is_modal_send_claim_open = false;
    }

    /** Отправить данные в статистику о клике по соцсети */
    fSendActionOpenSocialLink(sSocialName: string) {
        this.ctrl.statSys.faPushClickToStat({
            page: location.pathname,
            type: 'social_new',
            alias: `${sSocialName}_link_click`,
            entity_id: 0,
        });
    }

    mounted() {
        this.isClient = true;
        // скрываем списки если это мобилка
        if (!this.isDesktop) {
            for (let i = 0; i < this.aToggleLinks.length; i++) {
                this.aToggleLinks[i] = false;
            }
            this.aToggleLinks = [...this.aToggleLinks];
        }
    }

    // Геттеры
    /** Устройство пользователя */
    get isDesktop() {
        return this.ctrl.status.is_desktop || this.ctrl.status.isDesktop;
    }

    /** Интервал годов для copyright */
    get sYearsCopyright() {
        return `- ${dayjs().year()}`;
    }

    /** Авторизован ли пользователь */
    get bAuth() {
        return this.ctrl.userSys.user.isAuth;
    }

    /** Получить enum значений соцсетей */
    get SocialLinkT() {
        return SocialLinkN.SocialLinkT;
    }

    // Основные
    get ctrl(): PageCtrl {
        return gfInitContextCtrl(mIsClient() ? this.$context : this.$ssrContext, PageCtrl);
    }
}

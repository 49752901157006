import P63Vue from '@/system/P63Vue';
import Component from 'vue-class-component';
import { PageCtrl } from '@/system/PageCtrl';
import { gfInitContextCtrl } from '@/system/ContextSys';
import { mIsClient } from '@/common/helpers/ContextH';
import { Prop } from 'vue-property-decorator';
import { gImageS } from '@/common/service/ImageS';
import { S3ItemImgIDsI } from '@/ifc/msg/EntitySpSQL/S3ItemImgE';
import { S3ProductImgIDsI } from '@/ifc/core/EntitySQL/S3ProductImgE';
import ImageSwitch from '@/ds/el/image_switch/image_switch';
import { mPeriodName, mPluralize } from '@/common/helpers/TextFormatterH';
import dayjs from 'dayjs';

@Component({
    components: { ImageSwitch },
})
export default class SwipeCard extends P63Vue {
    @Prop({ type: Array }) aImages: (S3ItemImgIDsI | S3ProductImgIDsI)[]; // Список картинок
    @Prop({ type: String }) sName: string; // Наименование
    @Prop({ type: String }) sPrice: string; // Цена
    @Prop({ type: String }) sOldPrice: string; // Старая цена
    @Prop({ type: String }) sLink: string; // Ссылка на товар/продукт
    @Prop({ type: Number }) nOrgFee: number; // Орг. сбор
    @Prop({ type: Object }) vAction: { name: string; gradient: string }; // Название и фон акции
    @Prop({ type: Object }) vOrders: { count: number; date: string }; // Количество заказов и дата первого заказа
    @Prop({ type: Boolean, default: true }) isDesktop: boolean; // Разные ли закупки
    @Prop({ type: Object }) vStatInfo: { type: string; entity_id: number }; // Информация для сбора статистики

    /** Индекс текущего изображения */
    idCurrentImg: number = 0;

    /** Плейсхолдер для изображений */
    noImgUrl = require('@/assets/images/tg_cat_image.png');

    /** Ссылка к изображению */
    fChangeURI(id: number) {
        if (this.isDesktop) {
            this.idCurrentImg = id;
        }
    }

    /** Ссылка на изображение продукта */
    fGetImgUrl() {
        const sFileName = this.aImages[this.idCurrentImg]?.filename;
        return gImageS.fItemImageUrlx256(sFileName);
    }

    /** Получить URL картинки 256 */
    fGetItemImgUrl256(img: S3ItemImgIDsI | S3ProductImgIDsI) {
        let sImgSrc: string = this.noImgUrl;

        if (img.filename) {
            sImgSrc = gImageS.fItemImageUrlx256(img.filename);
        }

        return sImgSrc;
    }

    fRedirect() {
        this.fSendActionItemClick();
        window.location.assign(this.sLink);
    }

    /** Отправить данные в статистику о клике по карточке Товара/Продукта в Хитах Продаж*/
    fSendActionItemClick() {
        this.ctrl.statSys.faPushClickToStat({
            page: location.pathname,
            type: this.vStatInfo.type,
            alias: 'sales_hits_item_click_home_new',
            entity_id: this.vStatInfo.entity_id,
        });
    }

    get isMobile() {
        return this.ctrl.status.is_mobile;
    }

    get isTablet() {
        return this.ctrl.status.is_tablet;
    }

    /** Количество общих заказов у продукта с правильной формой слова 'раз' */
    get sOrdersCountText() {
        const sLabel = mPluralize(this.vOrders?.count, ['раз', 'раза', 'раз']);
        return `${this.vOrders?.count} ${sLabel} купили`;
    }

    // Основные
    get ctrl(): PageCtrl {
        return gfInitContextCtrl(mIsClient() ? this.$context : this.$ssrContext, PageCtrl);
    }
}

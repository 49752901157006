/* eslint-disable max-len */
import { MetaInfo } from 'vue-meta';
import configPublic from './config.public';

const support = {
  phone: '',//'88007006301',
  phoneFormatted: '', //'8 (800) 700-63-01',
  email: 'support@63pokupki.ru',
  workTimeFormatted: 'с 8.00 до 20.00',
};

const meta: Readonly<MetaInfo> = {
  title: 'Cовместные покупки 63pokupki.ru, сайт закупок с оптовыми ценами',
  htmlAttrs: {
    lang: 'ru',
  },
  script: [
    {
      type: 'application/ld+json',
      json: {
        '@context': 'https://schema.org/',
        '@type': 'Organization',
        url: 'https://www.63pokupki.ru',
        telephone: support.phoneFormatted,
        email: support.email,
        logo: `${configPublic.app.host}/public/static/images/logotype.svg`,
        name: 'ООО «63 ПОКУПКИ»',
        sameAs: [
          'https://vk.com/63pokupki',
          'https://ok.ru/group63pokupki',
          'https://www.instagram.com/63pokupki.ru',
          'https://www.youtube.com/channel/UCiRG7uK7jYLhM0DAzvTlNmg',
        ],
      },
    },
  ],
  noscript: [
    {
      hid: 'gtmn',
      innerHTML: `<iframe title="gtm" src="https://www.googletagmanager.com/ns.html?id=GTM-KC67P3" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
      pbody: true,
    },
  ],
  __dangerouslyDisableSanitizersByTagID: {
    gtmn: ['innerHTML'],
  },
  meta: [
    { charset: 'utf-8' },
    { name: 'viewport', content: 'width=device-width, initial-scale=1' },
    {
      hid: 'description',
      name: 'description',
      content:
        'Официальный сайт совместных покупок (СП) 63покупки. В закупках интернет-магазина товары по оптовым ценам с доставкой в Самару, Москву, Тольятти, Сызрань, Ульяновск, Димитровград, Уфу, Пермь, Краснодар и другие города России. Форум интернет-магазина СП.',
    },
    /** Подтверждение домена для Pinterest */
    { name: 'p:domain_verify', content: '214bed4af6504f12ba4ea665073d3bbf' },
    { name: 'msapplication-TileColor', content: '#349ce9' },
    {
      name: 'msapplication-TileImage',
      content: '/public/static/favicon/ms-icon-144x144.png',
    },
    {
      name: '#349ce9',
      content: '/public/static/favicon/ms-icon-144x144.png',
    },
    /** В браузерах ie включается последняя версия без режима совместимости */
    {
      'http-equiv': 'X-UA-Compatible',
      content: 'IE=edge',
    } as any,
  ],
  link: [
    {
      rel: 'search',
      type: 'application/opensearchdescription+xml',
      title: '63pokipki',
      href: '/public/static/opensearch.xml',
    },
    {
      rel: 'icon',
      type: 'image/x-icon',
      href: '/public/static/favicon/favicon.ico',
    },
    {
      rel: 'apple-touch-icon',
      sizes: '57x57',
      type: 'image/png',
      href: '/public/static/favicon/apple-icon-57x57.png',
    },
    {
      rel: 'apple-touch-icon',
      sizes: '60x60',
      type: 'image/png',
      href: '/public/static/favicon/apple-icon-60x60.png',
    },
    {
      rel: 'apple-touch-icon',
      sizes: '72x72',
      type: 'image/png',
      href: '/public/static/favicon/apple-icon-72x72.png',
    },
    {
      rel: 'apple-touch-icon',
      sizes: '76x76',
      type: 'image/png',
      href: '/public/static/favicon/apple-icon-76x76.png',
    },
    {
      rel: 'apple-touch-icon',
      sizes: '114x114',
      type: 'image/png',
      href: '/public/static/favicon/apple-icon-114x114.png',
    },
    {
      rel: 'apple-touch-icon',
      sizes: '120x120',
      type: 'image/png',
      href: '/public/static/favicon/apple-icon-120x120.png',
    },
    {
      rel: 'apple-touch-icon',
      sizes: '144x144',
      type: 'image/png',
      href: '/public/static/favicon/apple-icon-144x144.png',
    },
    {
      rel: 'apple-touch-icon',
      sizes: '152x152',
      type: 'image/png',
      href: '/public/static/favicon/apple-icon-152x152.png',
    },
    {
      rel: 'apple-touch-icon',
      sizes: '180x180',
      type: 'image/png',
      href: '/public/static/favicon/apple-icon-180x180.png',
    },
    /** Preconnect */
    {
      rel: 'preconnect',
      href: configPublic.storages.S3Selectel.url,
    },
    {
      rel: 'preconnect',
      href: configPublic.storages.S3Selectel256.url,
    },
    {
      rel: 'preconnect',
      href: configPublic.storages.S3Selectel512.url,
    },
    {
      rel: 'preconnect',
      href: configPublic.storages.S3SelectelPM.url,
    },
    {
      rel: 'preconnect',
      href: 'https://stats.g.doubleclick.net',
    },
    {
      rel: 'preconnect',
      href: 'https://www.google-analytics.com',
    },
    {
      rel: 'preconnect',
      href: 'https://www.google.com',
    },
    {
      rel: 'preconnect',
      href: 'https://mc.yandex.ru',
    },
    {
      rel: 'preconnect',
      href: 'https://vk.com',
    },
  ],
};

export const seo = {
  meta,
  support,
};

export default seo;

export type Seo = typeof seo;
export type SeoMeta = typeof meta;
export type SeoSupport = { support: typeof support };

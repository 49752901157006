import { CityIxI, CityWithPvzCountI } from "./EntitySQL/CityE";

export namespace HeaderR {
    /**
     * Получение города по координатам
     * Вызывается предположительно один раз для каждого
     * пользователя из шапки сайта при определении города
     * по координатам из GeolocationAPI
     */
    export namespace getCityByСoordinates {
        /** APIURL */
        export const route = '/header/get-city-by-coordinates';

        /** Параметры api запроса */
        export interface RequestI {
            latitude: number; // Широта
            longitude: number; // Долгота
        }

        /** Параметры api ответа */
        export interface ResponseI {
            city: CityIxI; // Город
        }

    }

    /**
     * Получение списка городов отсортированных по кол-ву
     * наших пвз.
     * Вызывается предположительно один раз для каждого
     * пользователя из шапки сайта при определении города
     */
    export namespace getOrderedListCity {
        /** APIURL */
        export const route = '/header/get-ordered-list-city';

        /** Параметры api запроса */
        export interface RequestI {}

        /** Параметры api ответа */
        export interface ResponseI {
            list_city: CityWithPvzCountI[]; // Города c кол-вом наших пвз в них
        }
    }
}

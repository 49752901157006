import { Component } from 'vue-property-decorator';
import P63Vue from '@/system/P63Vue';
import { HomeCtrl, initHomeCtrl } from '../../view/ctrl-home';
import { mIsClient } from '@/common/helpers/ContextH';
import HomeBtn from '@/pages/home/el/home-btn/home-btn';
import IconAboutUsMoney from '@/assets/images/home/icon-about-us-money.png';
import IconAboutUsOrder from '@/assets/images/home/icon-about-us-order.png';
import IconAboutUsWaiting from '@/assets/images/home/icon-about-us-waiting.png';

@Component({ components: { HomeBtn } })
export default class AboutUsSource extends P63Vue {
    /** Вариации преимуществ */
    avAdvantageTypes: {
        image: string;
        title: string;
        description: string;
    }[] = [
        {
            image: IconAboutUsOrder,
            title: 'Более 14 лет объединяем заказы пользователей',
            description: 'И ВМЕСТЕ выкупаем партию товаров напрямую от производителей или поставщиков по оптовой цене',
        },
        {
            image: IconAboutUsMoney,
            title: 'Экономим ваши деньги',
            description:
                'Вы покупаете по цене производителя, а мы берем сервисную комиссию от 0% до 16%.' +
                ' Часто цена выходит в 2-3 раза дешевле, чем в обычных магазинах',
        },
        {
            image: IconAboutUsWaiting,
            title: 'Срок ожидания заказа — от пары дней до 2-3 недель',
            description:
                'Нужно подождать, пока соберется оптовая партия и товар приедет от поставщика.' +
                ' Это особенность совместных покупок',
        },
    ];

    /** Хранилище */
    get ctrl(): HomeCtrl {
        return initHomeCtrl(mIsClient() ? this.$context : this.$ssrContext);
    }
}

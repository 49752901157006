import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { gfInitContextCtrl } from '@/system/ContextSys';
import { PageCtrl } from '@/system/PageCtrl';
import P63Vue from '@/system/P63Vue';
import { mIsClient } from '@/common/helpers/ContextH';
import { CategoryIDsI } from '@/ifc/core/EntitySQL/CategoryE';
import { LandingMenuI } from '@/ifc/core/EntitySQL/P63SearchLandingInfoE';

/** Типы категорий */
export const enum TypeCategoryT {
    by_item = 'По товарам',
    by_purchase = 'По закупкам'
}

@Component({})
export default class MultiLevelMenuDesktop extends P63Vue {
    /** Открыто ли меню */
    @Prop({default: false }) bOpenMenu: boolean;

    /** Поиск по товару / закупке */
    bSearchByItem: boolean = true;
    /** Открыт ли селектор типов категорий */
    bTypeCategorySelectorOpened: boolean = false;
    /** Список категорий, по которым был запрос на лендинги */
    ixRequestedLandings: Record<number, boolean> = {};

    /** Методы */

    /** Открать окно выбора категорий */
    fOpenSelector() {
        this.bTypeCategorySelectorOpened = true;
    }

    /** Закрыть окно выбора типа категорий */
    fCloseSelecctor() {
        this.bTypeCategorySelectorOpened = false;
    }

    /** Выбор типа категорий для поиска (по товарам, либо по закупкам) */
    fSelectCategoryType(bChangeCategory: boolean) {
        if (bChangeCategory) {
            this.bSearchByItem = !this.bSearchByItem;
        }
        localStorage.setItem('default_category_settings', this.sSelectedCategoryType)
        this.fCloseSelecctor();
    }

    /** Получить ссылку на категорию */
    fGetHref(idCategory: number, sLandingAlias?: string) {
        let sHref: string = '';
        sHref = this.bSearchByItem
        ? this.ctrl.menuSys.fGetItemCategoryUrl(idCategory) 
        : this.ctrl.menuSys.fGetPurchaseCategoryUrl(idCategory);
        if (sLandingAlias){
            sHref += `/${sLandingAlias}`;
        }
        return sHref;
    }

    /** Получить лендинги по текущей категории */
    fGetLandings(idCategory: number){
        if (!this.ixRequestedLandings[idCategory]){
           this.ctrl.menuSys.faGetLandingsByParentId({category_id: idCategory})
           this.ixRequestedLandings[idCategory] = true;
        }
    }

    /** Проверка, загружены ли лендинги по родителю */
    bNoLandings(idCategory, idSubcategory){
        return this.ixRequestedLandings[idCategory] && !this.ixLoadedLandings[idSubcategory];
    }

    /** Отправить данные в статистику о клике по ссылке в меню категорий */
    fSendActionOpenCategory(vCategory: CategoryIDsI | LandingMenuI) {
        this.ctrl.statSys.faPushClickToStat({
            page: location.pathname,
            type: 'menu',
            alias: 'link_click_menu_category',
            entity_id: vCategory.category_id,
          });
    }

    mounted() {
            if (this.isAuth){            
            const sDefaultCategorySettings: string = localStorage.getItem('default_category_settings');
            if (!sDefaultCategorySettings) {
                this.bSearchByItem = this.nConsumerRating < 1;
            } else {
                this.bSearchByItem = sDefaultCategorySettings === TypeCategoryT.by_item;
            }
        }
    }

    /** Геттеры */
    get ctrl(): PageCtrl {
        return gfInitContextCtrl(mIsClient() ? this.$context : this.$ssrContext, PageCtrl);
    }

    /** Массив меню категорий */
    get ixCategories() {
        return this.ctrl.menuSys.menu.ixCategoriesGrouped;
    }

    /** Выбранный тип категорий */
    get sSelectedCategoryType() {
        return this.bSearchByItem ? TypeCategoryT.by_item : TypeCategoryT.by_purchase;
    }

    /** Не выбранный тип категорий */
    get sDeselectedCategoryType() {
        return this.bSearchByItem ? TypeCategoryT.by_purchase : TypeCategoryT.by_item;
    }

    /** Ссылка на все категории */
    get sAllCategory() {
        return this.bSearchByItem ? '/category/' :'/purchases/';
    }

    /** Закгуженные лендинги */
    get ixLoadedLandings(){
        return this.ctrl.menuSys.menu.ixLanding
    }

    /** Авторизован ли пользователь */
    get isAuth() {
        return this.ctrl.userSys.user.isAuth;
    }

    /** Уровень доступа пользвателя */
    get nConsumerRating() {
        return this.ctrl.userSys.user.info.consumer_rating;
    }
}

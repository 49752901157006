import P63Vue from '@/system/P63Vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Icon from '@/ds/el/icon/icon';
import configPrivate from '@/configs/config.private';

@Component({
    components: {
        Icon
    }
})
export default class PopupNotification extends P63Vue {
    @Prop({ type: Boolean, default: false }) isAuth: Boolean;

    vServiceWorker: ServiceWorkerRegistration = null;
    isView: boolean = false;

    /** Запрос на разрешение прав для уведомлений */
    async faSubscribe() {
        const sPerm = await Notification.requestPermission();
        
        if (sPerm === 'granted') {
            const vNewSub = await this.vServiceWorker.pushManager.subscribe(configPrivate.webPush);
        
            const vSub = JSON.parse(JSON.stringify(vNewSub));
        
            const response = await fetch('/api/core/home-page/user-device-subscribe', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8;'
                },
                body: JSON.stringify({
                    endpoint: vSub.endpoint,
                    p256dh: vSub.keys.p256dh,
                    auth: vSub.keys.auth
                })
            });
            
            if(response.ok){
                localStorage.setItem('isPushCancel', 'false');
            }            
        }

        this.fClosePopup();
    }

    /** Отменяем предложение о подписке */
    fCancel() {
        localStorage.setItem('isPushCancel', 'true');

        this.fClosePopup();
    }

    /** Закрыть модальное окно */
    fClosePopup() {
        this.isView = false;
    }

    async mounted() {
        const isPushCancel = localStorage.getItem('isPushCancel') === 'true';

        if (navigator.serviceWorker && this.isAuth && !isPushCancel) {
            this.vServiceWorker = await navigator.serviceWorker.getRegistration();

            const vPushManager = this.vServiceWorker?.pushManager;
            const vSub = await vPushManager?.getSubscription();
            const sPerm = Notification.permission;

            // Если есть поддержка PUSH-уведомлений и нет подписки
            if (vPushManager && !vSub && sPerm === 'default') {
                setTimeout(() => {
                    this.isView = true;
                }, 3500);
            }
        }
    }
}

import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { gfInitContextCtrl } from '@/system/ContextSys';
import { PageCtrl } from '@/system/PageCtrl';
import P63Vue from '@/system/P63Vue';
import { mIsClient } from '@/common/helpers/ContextH';
import { CategoryIDsI } from '@/ifc/core/EntitySQL/CategoryE';
import Landings from '@/common/el/MainMenuDesktop/Landings.vue';
import Purchases from '@/common/el/MainMenuDesktop/Purchases.vue';
import { mGetDeviceType } from '@/common/helpers/DeviceTypeH';
import { mFormatPriceSeparatorNoCoins } from '@/common/helpers/PriceFormatterH';
import { gImageS } from '@/common/service/ImageS';

@Component({
    components: { Landings, Purchases },
})
export default class MainMenuDesktop extends P63Vue {
    /** Открыто ли меню */
    @Prop({ default: false }) bOpenMenu: boolean;

    /** Список категорий, по которым был запрос на лендинги */
    ixRequestedLandings: Record<number, boolean> = {};
    /** Соотнесение категорий с названиями иконок */
    ixCatIdByIconAlias: Record<number, string> = {
        6: 'woman',
        7: 'men',
        5: 'child',
        11: 'flacon',
        3: 'house',
        8: 'handbag',
        14: 'cake',
        141: 'toy',
        12: 'ball',
        16: 'palette',
        15: 'book',
        4: 'plant',
        137: 'gift',
        13: 'phone',
        10: 'trail',
        2: 'automobile',
    };
    /** Текущая выбранная категория */
    vCurrentCategory: CategoryIDsI = null;
    /** Показывать ли категории закупок */
    isPurchases: boolean = false;
    /** Высота хедера */
    nHeaderHeight: number = 92;    
    /** Расширение десктопа 1024*/
    isNestHubDesktop: boolean = false;

    /** Методы */
    /** Выбор категории */
    fSetSelectCategory(id: number) {
        this.vCurrentCategory = this.ixCategoriesIndexed[id];
    }

    /** Получить ссылку на категорию */
    fGetHref(idCategory: number) {
        let sHref: string = '';
        sHref = this.isPurchases
            ? this.ctrl.menuSys.fGetPurchaseCategoryUrl(idCategory)
            : this.ctrl.menuSys.fGetItemCategoryUrl(idCategory);
        return sHref;
    }

    /** Получить лендинги по текущей категории */
    fGetLandings(idCategory: number) {
        if (!this.ixRequestedLandings[idCategory]) {
            this.ctrl.menuSys.faGetLandingsByParentId({ category_id: idCategory });
            this.ixRequestedLandings[idCategory] = true;
        }
    }

    /** Получить ссылку при клике на баннер */
    fGetUrlBanner(idx: number): string {
        let objBanner = this.avBanner[idx];
        return objBanner.is_webp
            ? gImageS.fBannerImageUrlWebp(objBanner.filename)
            : gImageS.fBannerImageUrlJpg(objBanner.filename);
    }

    /** Форматирование количества товаров */
    fNumberFormat(n) {
        return mFormatPriceSeparatorNoCoins(n);
    }

    /** Отправить данные в статистику о клике по ссылке в меню */
    fSendActionOpenCategory(vCategory: CategoryIDsI) {
        this.ctrl.statSys.faPushClickToStat({
            page: location.pathname,
            type: 'menu_new',
            alias: 'link_click_menu_category',
            entity_id: vCategory.category_id,
          });
    }

    mounted() {
        // Учитываем при отступе сверху хедер
        const nCategoriesHeight = document.querySelector('nav.categories')?.clientHeight ?? 0;
        this.nHeaderHeight = document.querySelector('header.new-header')?.clientHeight - nCategoriesHeight - 4;

        // По умолчанию изначально выбрана категория 'Одежда и обувь для женщин', id = 8
        this.fSetSelectCategory(8);
        this.fGetLandings(8);
        
        this.isNestHubDesktop = window.innerWidth === 1024;
    }
    
    /** Геттеры */
    get ctrl(): PageCtrl {
        return gfInitContextCtrl(mIsClient() ? this.$context : this.$ssrContext, PageCtrl);
    }

    /** Массив меню категорий */
    get ixCategories() {
        return this.ctrl.menuSys.menu.ixCategoriesGrouped;
    }

    /** Получить баннеры для главного меню */
    get avBanner() {
        return this.ctrl.menuSys.menu.avBanner;
    }

    /** Категории по id */
    get ixCategoriesIndexed() {
        return this.ctrl.menuSys.menu.ixCategoriesIndexed;
    }

    /** Информация по категории */
    get ixCategoriesInfo() {
        return this.ctrl.menuSys.menu.ixCategoryOrderCount;
    }

    /** Загруженные лендинги */
    get ixLoadedLandings() {
        return this.ctrl.menuSys.menu.ixLanding;
    }

    /** Тип устройства */
    get vDeviceType() {
        return mGetDeviceType(this.ctrl.deviceType);
    }
}

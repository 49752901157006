var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"imageSwitchPagination",staticClass:"ds-image-switch-pagination",class:{
        'content-left': _vm.sAlign === 'left',
        'content-center': _vm.sAlign === 'center',
        'content-right': _vm.sAlign === 'right',
    },attrs:{"draggable":"false"}},[(_vm.isDotPagination)?_c('div',{staticClass:"dot-pagination-wrapper",attrs:{"draggable":"false"}},[_c('div',{staticClass:"dot-pagination",style:({
                width: `${_vm.nPaginationWidth}px`,
            })},[_c('ul',{staticClass:"list-circles",style:({
                    padding: `${_vm.nCirclePadding}px`,
                    transform: `translateX(${_vm.nTransform}px)`,
                })},_vm._l((_vm.nCountImagesLocal),function(circle,idx){return _c('li',{key:idx,staticClass:"circle",class:{
                        'circle-active': idx === _vm.idxCurrentSlide,
                    },style:({
                        width: `${_vm.nCircleSize}px`,
                        'margin-left': idx === 0 ? 0 : `${_vm.nCirclePadding}px`,
                    })})}),0)])]):_c('div',{staticClass:"image-pagination-wrapper"},[_c('div',{ref:"scroll",staticClass:"image-pagination",class:{ 'hide-scroll': _vm.bHideScrollBar }},[_c('ul',{staticClass:"list-images"},_vm._l((_vm.aImages),function(img,idx){return _c('li',{key:idx,staticClass:"image-container",class:{
                        'is-selected': idx === _vm.idxCurrentSlide,
                    },style:({
                        width: `${_vm.aCustomSize[0] ? _vm.aCustomSize[0] : _vm.nImageSize}px`,
                        height: `${_vm.aCustomSize[1] ? _vm.aCustomSize[1] : _vm.nImageSize}px`,
                        'margin-left': idx === 0 ? 0 : `${_vm.nImageSize / 10}px`,
                    }),attrs:{"id":`image-${idx}`},on:{"click":function($event){return _vm.fSelectCard(idx, $event)}}},[_c('img',{staticClass:"image",attrs:{"draggable":"false","src":_vm.fGetImgSrcLocal(img),"alt":img.alt}})])}),0)]),(_vm.bShowFadeLocal && !_vm.isHideRightScrollBtn)?_c('div',{staticClass:"fade",style:({
                width: `${_vm.nSlideDistance / 3}px`,
            })}):_vm._e(),(_vm.bDisplayButtons && _vm.aImages.length > 4)?_c('button',{staticClass:"slide-btn btn btn-left",class:{ hide: _vm.isHideLeftScrollBtn },on:{"click":function($event){return _vm.fSlideImageList(true)}}},[_c('i',{staticClass:"arrow arrow-position-left"})]):_vm._e(),(_vm.bDisplayButtons && _vm.aImages.length > 4)?_c('button',{staticClass:"slide-btn btn btn-right",class:{ hide: _vm.isHideRightScrollBtn },on:{"click":function($event){return _vm.fSlideImageList()}}},[_c('i',{staticClass:"arrow arrow-position-right"})]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }
import { render, staticRenderFns } from "./modal-review-image.vue?vue&type=template&id=71831490&scoped=true"
import script from "./modal-review-image.vue?vue&type=script&lang=ts"
export * from "./modal-review-image.vue?vue&type=script&lang=ts"
import style0 from "./modal-review-image.vue?vue&type=style&index=0&id=71831490&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71831490",
  null
  
)

export default component.exports
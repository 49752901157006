import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ButtonTypeT, ButtonAssignmentT } from './ifc_button';

import Icon from '../icon/icon';

/**
 * Компонент Button принимает следующие входные параметры:
 * @ sText — текст внутри кнопки
 * @ sType — тип кнопки (button, submit)
 * @ sAriaLabel — текст для скринридера
 * @ sAppearance — внешний вид кнопки (primary, primary-hover, outlined, outlined-hover)
 * @ nWidth — ширина кнопки
 * @ nHeight — высота кнопки
 * @ nFontSize — размер текста кнопки
 * @ vStyle — объект стилей кнопки
 * @ sIconPosition — расположение иконки
 * @ isBlock — растянуть ли кнопку на всю ширину родителя
 * @ isLoading — отображать ли лоадер на кнопке
 * @ isDisabled — отключить ли кнопку
 * @ onClick: () => void — функция клика
 */
@Component({
    components: {
        Icon,
    },
})
export default class ds_button extends Vue {
    /** Текст внутри кнопки */
    @Prop({ type: String, default: '' }) sText: string;
    /** Тип кнопки */
    @Prop({ type: String, default: 'button' }) sType: ButtonAssignmentT;
    /** Текст для скринридера */
    @Prop({ type: String, default: '' }) sAriaLabel: string;
    /** Внешний вид кнопки */
    @Prop({ type: String, default: 'primary' }) sAppearance: ButtonTypeT;
    /** Ширина кнопки */
    @Prop({ type: Number, default: null }) nWidth: number;
    /** Высота кнопки */
    @Prop({ type: Number, default: null }) nHeight: number;
    /** Размер текста кнопки */
    @Prop({ type: Number, default: null }) nFontSize: number;
    /** Объект стилей кнопки */
    @Prop({ type: Object, default: () => {} }) vStyle: object;
    /** Позиция иконки */
    @Prop({ type: String, default: 'right' }) sIconPosition: string;
    /** Растянуть ли кнопку на всю ширину родителя */
    @Prop({ type: Boolean, default: false }) isBlock: boolean;
    /** Отображать ли лоадер на кнопке */
    @Prop({ type: Boolean, default: false }) isLoading: boolean;
    /** Отключить ли кнопку */
    @Prop({ type: Boolean, default: false }) isDisabled: boolean;
    /** Функция клика */
    @Prop({ type: Function, default: () => false }) onClick: () => void;

    /** Вызвать функцию клика по кнопке */
    fClick() {
        if (!this.isLoading && !this.isDisabled) {
            this.onClick();
        }
    }

    /** Получить объект стилей кнопки */
    get vStyleCustom() {
        let vStyleCustom: { [key: string]: string } = {};

        if (this.nWidth) {
            vStyleCustom['width'] = this.nWidth + 'px';
        }

        if (this.nHeight) {
            vStyleCustom['height'] = this.nHeight + 'px';
        }

        if (this.nFontSize) {
            vStyleCustom['font-size'] = this.nFontSize + 'px';
        }

        vStyleCustom = { ...vStyleCustom, ...this.vStyle };

        return vStyleCustom;
    }
}

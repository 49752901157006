import { ItemI, ItemInfoI, SortByI } from './EntitySQL/ItemE';
import { PurchaseInfoI } from './EntitySQL/PurchaseE';
import { P63ItemCharacteristicIDsI } from './EntitySQL/P63ItemCharacteristicE';
import { S3ItemImgIDsI } from './EntitySQL/S3ItemImgE';
import { P63ItemBundleIDsI } from './EntitySQL/P63ItemBundleE';
import {
    ActiveCatalogSearchI,
    ActiveCategorySearchI,
    ActiveOrgSearchI,
    ActivePurchaseSearchI
} from './EntityXQL/ActiveItemE';
import { TagsI } from './EntitySQL/TagsE';
import { CategoryIDsI } from './EntitySQL/CategoryE';
import { AutocompleteI } from './EntityXQL/AutocompleteE';
import { OnePurchaseDataI } from './CommonI/PurchaseI';
import { AutocompleteBrandI } from './EntityXQL/AutocompleteBrandE';
import { AutocompleteActivePurchaseI } from './EntityXQL/AutocompleteActivePurchaseE';
import { P63AvgDeliveryTimeI } from './EntitySQL/P63AvgDeliveryTimeE';
import { PurchasePvzI } from './EntitySQL/PurchasePvzE';
import { P63ItemOrderCounterI } from './EntitySQL/P63ItemOrderCounterE';
import { SearchTypeT } from './EntitySQL/UserSearchHistoryE';
import { AutocompleteLandingI } from './EntityXQL/AutocompleteLandingE';
import { ManticoreCorrectionI } from './CommonI/ManticoreCorrectionI';
import { MarketItemOwnerInfoI } from './EntitySQL/MarketItemE';
import { UserDictionaryI } from './EntitySQL/UserE';
import { OneProductDataI, SearchSortByEqual } from './CommonI/ProductI';
import { OneItemDataI } from './CommonI/ItemI';
import { P63CharModerateI } from './EntitySQL/P63CharModerateE';

/**
 * Страница закупки функции не авторизированого пользователя
 */
export namespace SearchPageR {
    export const Alias = 'SearchPage';

    // =======================================================
    /** INIT - данные для первичной инициализации страницы */
    export namespace init {

        /** APIURL */
        export const route = '/search-page/init';

        /** Alias действия */
        export const action = 'init';

        /** Параметры api запроса */
        export interface RequestI {
        }

        /** Параметры api ответа */
        export interface ResponseI {
            list_category: CategoryIDsI[]; // Список категорий
        }
    }

    // =======================================================
    /** Получить доступные отфильтрованные фильтры */
    export namespace getAvailableFilter {

        /** APIURL */
        export const route = '/search-page/get-available-filters';

        /** Alias действия */
        export const action = 'get-available-filters';

        /** Параметры api запроса */
        export interface RequestI {
            search_text?: string; // Строка поиска
            tag_list_id?: number[]; // Список тегов
            category_list_id?: number[]; // Список категорий
            characteristic_val_list_id?: number[]; // Список значений характеристик
        }

        /** Параметры api ответа */
        export interface ResponseI {
            filter_characteristic: { // фильтр по характеристикам
                key_id: number; // ID характеристики
                key: string; // Ключ характеристики
            }[];
        }
    }

    // =======================================================
    /** Получить все характеристики */
    export namespace getAllCharacteristic {

        /** APIURL */
        export const route = '/search-page/get-all-characteristic';

        /** Alias действия */
        export const action = 'get-all-characteristic';

        /** Параметры api запроса */
        export interface RequestI {
            tag_list_id?: number[]; // Список каталогов
            category_id?: number; // Список категорий
            characteristic_key_id: number; // ID ключа характеристики
            characteristic_val_list_id?: number[]; // Список значений характеристик(выбранных пользователеми - их нужно возвращать всегда)
            search_text?: string; // текст из строки поиска
        }

        /** Параметры api ответа */
        export interface ResponseI {
            filter_characteristic: { // фильтр по характеристикам
                id: number; // ID характеристики
                characteristic_key_id: number; // ID названия переменной
                characteristic_val_id: number; // ID значения переменной
                key: string; // Ключ карактеристики
                val: string; // Значение характеристики
            }[];
        }
    }

    // =======================================================
    /** Получить доступные все  фильтры */
    export namespace getAllFilter {

        /** APIURL */
        export const route = '/search-page/get-all-filter';

        /** Alias действия */
        export const action = 'get-all-filter';

        /** Параметры api запроса */
        export interface RequestI {
            search_text?: string; // Строка поиска
            is_search_by_name?: boolean; // Искать по названию
            category_list_id?: number[]; // Для поиска по категориям у лендингов
            is_market?: boolean; // Поиск по пристрою
            product_only?: boolean; // Поиск по продуктам
        }

        /** Параметры api ответа */
        export interface ResponseI {
            filter_price: {
                price_min: number; // Минимальная стоимость товара в закупке
                price_max: number; // Максимальная стоимость товара в закупке
            };
            catalog_tag: TagsI[]; // Теги каталогов
            category_filter: ActiveCategorySearchI[]; // Список категорий
            org_id_list?: ActiveOrgSearchI[]; // массив айдишников организаторов
        }
    }

    /** Получить все доступные теги */
    export namespace getTagFilter {

        /** APIURL */
        export const route = '/search-page/get-tag-filter';

        /** Alias действия */
        export const action = 'get-tag-filter';

        /** Параметры api запроса */
        export interface RequestI {
            list_category_id?: number[]; // поиск по выбранным категориям
            search_text?: string; // Строка поиска
            is_search_by_name?: boolean; // Искать по названию
            is_correction_disable?: boolean; // Отключить коррекцию опечаток
            item_characteristic_list?: { key_id: number; val: string }[]; // список характеристик товара ID названия характеристики
        }

        /** Параметры api ответа */
        export interface ResponseI {
            catalog_tag: TagsI[]; // Теги каталогов
        }
    }

    // =======================================================
    /** Получить отфильтрованные товары */
    export namespace getFilteredItem {

        /** APIURL */
        export const route = '/search-page/get-filtered-item';

        /** Alias действия */
        export const action = 'get-filtered-item';

        /** Параметры api запроса */
        export interface RequestI { // При изменении интерфейса внести правки в модуль SearchLandingInfo
            page: number; // номер страницы
            limit: number; // количество товаров на странице

            price_min?: number; // минимальная цена
            price_max?: number; // максимальная цена

            item_characteristic_list?: { key_id: number; val: string }[]; // список характеристик товара ID названия характеристики
            tag_list_id?: number[]; // Список тегов
            category_list_id?: number[]; // Список категорий
            org_list_id?: number[]; // Список id организаторов
            stock_dodge_list_id?: number[]; // Список id избегаемых шаблонов

            sort_by?: SortByI;

            is_adult_exclude?: boolean; // 18+
            is_bundle_exclude?: boolean; // Ряды
            is_soon_stop?: boolean; // Показать только скоро стоп
            is_in_favourite?: boolean; // Показывать только от избранных закупок

            is_search_by_name?: boolean; // Искать только по названию
            is_search_by_stock_id?: boolean; // Искать по id шаблона

            search_text?: string; // Строка поиска
            is_market?: boolean; // Поиск по пристрою

            is_product_only?: boolean; // Поиск по продуктам
        }

        /** Параметры api ответа */
        export interface ResponseI {
            purchase_list: PurchaseInfoI[];
            list_item_ads_id: number[]; // Товары, которые организаторы добавили в популярные
            item_list: OneItemDataI[]; // Список товаров
            available_pvz: PurchasePvzI[]; // Список ограниченных по доступным ПВЗ закупок
            item_count: {
                item_total: number; // Всего товаров удовлетворяющих фильтру
                page_total: number; // Количество страниц удовлетворяющих фильтру
                page_limit: number; // Количество товаров на странице запрощенное фронтом
            };
            vi_list_stock_id: number[]; // Массив номеров интеграционных шаблонов закупок
            list_delivery_time: P63AvgDeliveryTimeI[]; // Среднее время доставки
            list_market_stock: number[]; // id стоков которые являются пристроем
            list_market_item_user: MarketItemOwnerInfoI[];
            ix_purchase_chart_img: Record<number, string[]>;
            ix_catalog_chart_img: Record<number, string[]>; // Картинки каталогов (catalog_id, url's)
            list_product: Partial<OneProductDataI>[]; // Список продуктов на странице
            // список информации по среднему рейтингу продуктов
            list_product_avg_rating: {
                product_id: number,
                rating: number,
                review_count: number
            }[];
            list_product_order_counter: {
                product_id: number,
                cnt_order: number,
                first_order_date: string
            }[]; // Список счётчиков покупок продуктов
            list_item_order_counter: {
                item_id: number,
                cnt_order: number,
                first_order_date: string
            }[]; // Список счётчиков покупок товаров
            list_sort_by: SearchSortByEqual[]; //Упорядоченный список по "сортировке по прочим равным"
            list_reference_characteristic: P63CharModerateI[]; // Эталонные характеристики
        }
    }

    // =======================================================
    /** Получить отфильтрованные закупок */
    export namespace getFilteredPurchase {

        /** APIURL */
        export const route = '/search-page/get-filtered-purchase';

        /** Alias действия */
        export const action = 'get-filtered-purchase';

        /** Параметры api запроса */
        export interface RequestI {
            page: number; // номер страницы
            limit: number; // количество товаров на странице

            price_min?: number; // минимальная цена
            price_max?: number; // максимальная цена

            item_characteristic_list?: { key_id: number; val: string }[]; // список характеристик товара ID названия характеристики
            tag_list_id?: number[]; // Список тегов
            category_list_id?: number[]; // Список категорий
            org_list_id?: number[]; // список айди оргов
            stock_dodge_list_id?: number[]; // список id избегаемых шаблонов

            sort_by?: SortByI;

            is_adult_exclude?:boolean; // 18+
            is_bundle_exclude?:boolean; // Ряды
            is_soon_stop?:boolean; // Показать только скоро стоп
            is_in_favourite?: boolean; // Показывать только от избранных закупок

            is_search_by_name?: boolean; // Искать только по названию

            search_text?: string; // Строка поиска
        }

        /** Параметры api ответа */
        export interface ResponseI {
            list_purchase: OnePurchaseDataI[]; // Информация по закупке
            ix_catalog: { [key: number]: ActiveCatalogSearchI[] }; // Список каталогов по закупке - key = purchase_id, val = catalog_list
            list_search_info: ActivePurchaseSearchI[]; // информация о товарах в закупке
            purchase_count: { // Количество закупок
                purchase_total: number; // Всего закупок удовлетворяющих фильтру
                page_total: number; // Количество страниц удовлетворяющих фильтру
                page_limit: number; // Количество товаров на странице запрощенное фронтом
            };
        }
    }

    // =======================================================
    /** Получить закупоки по брендам */
    export namespace getPurchaseByBrand {

        /** APIURL */
        export const route = '/search-page/get-purchase-by-brand';

        /** Alias действия */
        export const action = 'get-purchase-by-brand';

        /** Параметры api запроса */
        export interface RequestI {
            page: number; // номер страницы
            limit: number; // количество товаров на странице
            is_in_favourite?: boolean; // Показывать только от избранных закупок

            search_text?: string; // Строка поиска (бренд)
        }

        /** Параметры api ответа */
        export interface ResponseI {
            list_purchase: OnePurchaseDataI[]; // Информация по закупке
            ix_catalog: { [key: number]: ActiveCatalogSearchI[] }; // Список каталогов по закупке - key = purchase_id, val = catalog_list
            list_search_info: ActivePurchaseSearchI[]; // информация о товарах в закупке
            purchase_count: { // Количество закупок
                purchase_total: number; // Всего закупок удовлетворяющих фильтры
                page_total: number; // Количество страниц удовлетворяющих фильтры
                page_limit: number; // Количество товаров на странице запрощенное фронтом
            };
            list_market_stock_id: number[]; // ID шаблонов из пристроя
        }
    }

    // =======================================================
    /** Автозаполнение */
    export namespace autocomplete {

        /** APIURL */
        export const route = '/search-page/autocomplete';

        /** Alias действия */
        export const action = 'autocomplete';

        /** Параметры api запроса */
        export interface RequestI {
            text: string; // Поисковой запрос
        }

        /** Параметры api ответа */
        export interface ResponseI {
            list_suggestion: AutocompleteI[]; // Список подсказок
            list_suggestion_landing: AutocompleteLandingI[]; // Список подсказок лендингов
            list_suggestion_brand: AutocompleteBrandI[];
            list_suggestion_active_purchase_name: AutocompleteActivePurchaseI[];
            list_org: { user_id: number, org_name: string }[];
            list_item: { // Список товаров
                item_info: ItemI; // Информация по товару
                purchase_id: number; // ID выкупа
                characteristic_list: P63ItemCharacteristicIDsI[]; // Список характеристик
                image_list: S3ItemImgIDsI[]; // список картинок
                bundle_list: P63ItemBundleIDsI[]; // списко рядов
                order_cnt: number; // список заказов
            }[]; // Список товаров
            list_correction: ManticoreCorrectionI[]; // Список исправлений по товарам
            list_correction_landing: ManticoreCorrectionI[]; // Список исправлений по товарам
            list_correction_brand: ManticoreCorrectionI[]; // Список исправлений по брендам
        }
    }

    // =======================================================
    /** Автозаполнение */
    export namespace search {

        /** APIURL */
        export const route = '/search-page/search';

        /** Alias действия */
        export const action = 'search';

        /** Параметры api запроса */
        export interface RequestI {
            text: string; // Поисковой запрос
            type: SearchTypeT; // Тип поискового запроса (по товарам/брендам/закупкам/организаторам)
        }

        /** Параметры api ответа */
        export interface ResponseI {
            suggestion: string; // Возможно вы имели ввиду
        }
    }

    // =======================================================
    /** Получить избранные закупки */
    export namespace getFavorits {

        /** APIURL */
        export const route = '/search-page/get-favorits';

        /** Alias действия */
        export const action = 'get-favorits';

        /** Параметры api запроса */
        export interface RequestI {
            list_stock_id: number[]; // Получить закупки в избранном
        }

        /** Параметры api ответа */
        export interface ResponseI {
            list_stock_id: number[]; // Закупки в избранном
        }
    }

    // =======================================================
    /** */
    export namespace getPopularItemByListPurchase {

        /** APIURL */
        export const route = '/search-page/get-popular-item-by-list-purchase';

        /** Параметры api запроса */
        export interface RequestI {
            list_purchase_id: number[]; // Id закупок
        }

        /** Параметры api ответа */
        export interface ResponseI {
            list_item: ItemInfoI[]; // Информация по товарам
            order_cnt: P63ItemOrderCounterI[]; // Количество заказов
            list_image: S3ItemImgIDsI[]; // список картинок
        }
    }

    /** Получить историю поиска пользователя на сайте */
    export namespace getUserSearchHistory {
        /** APIURL */
        export const route = '/search-page/get-user-search-history';

        /** Параметры api запроса */
        export interface RequestI {
            // Данных нет
        }

        /** Параметры api ответа */
        export interface ResponseI {
            list_search_text: { search_text: string, type: SearchTypeT }[]; // Последние поисковые запросы пользователя
            list_stock_dictionary: { stock_id: number, stock_name: string }[]; // Словарь шаблонов
            list_org_dictionary: UserDictionaryI[]; // Словарь организаторов
        }
    }
}

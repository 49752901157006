import Vue from 'vue';
import Component from 'vue-class-component';
import { P63Context } from './P63Context';

@Component
export default class P63Vue extends Vue {
  public $router: any;
  public $context: P63Context;
  public $sTargetLink: string;
  public $sBodyHeightApp: string;
  public $exp: any;
  public $meta: any;
  public $query: any;
}
 
import * as conf from '../configs/config.public';
import { mIsClient } from '@/common/helpers/ContextH';
import { gfInitContextQuery } from './ContextSys';
import { NotificationPromptR } from '@/ifc/msg/NotificationPromptR';
import { QuerySys } from '@63pokupki/front';
import { mKeyBy } from '../common/helpers/ArrayH';
import { PageCtrl } from './PageCtrl';
import { ItemCollectionR } from '@/ifc/core/ItemCollectionR';
import { ItemOfCollectionI } from '@/ifc/core/CommonI/ItemCollectionI';
import Onboarding from '@/common/service/OnboardingS';
import '@63pokupki/onboarding-core/index.css';
import '@63pokupki/onboarding-core/index.js';
import '@63pokupki/design-system/dist/css/onboarding.css';

/** Два часа в миллисекундах*/
const TWO_HOURS = 7200000;
/** Месяц в миллисекундах */
const ONE_MONTH = 2592000000;

interface PromptMessageLocalStorageI {
    alias: string;
    date: number;
}

/** Хранилище сервиса */
class ModalNotificationStoreI {
    /** Активное модальное уведомление */
    active_modal: string = '';
    /** Статус с сервера для открытия уведомлений */
    need_to_show: boolean = false;
    /** Текущий онбординг */
    onboarding: Onboarding = null;
    /** Индекс i-го элемента массива отсортированных алиасов */
    index_sorted_alias: number = 0;
    /** Количество элементов отсортированного массива алиасов */
    count_sorted_alias: number = 0;
    /** Статус показа онбординга */
    is_onboarding_displayed: boolean = false;
    /** Статус показа модалки "товар добавлен в корзину" */
    is_modal_add_to_cart_displayed: boolean = false;
    /** Массив объектов модальных уведомлений страницы */
    prompt_notification: { alias: string }[] = [];
    /** Отсортированный массив алиасов*/
    sorted_aliases: string[] = [];
    /** Подборка товаров по уровню доступа */
    list_item_of_collection_by_rating: ItemOfCollectionI[] = [];
    /** Сохраненные алиас модалок для отложенного запуска fModalNotification */
    modal_stored_aliases: {alias: string}[] = [];
    /** Сохраненный алиас онбординга для отложенного запуска */
    onboarding_stored_alias: string = '';
}

/** Сервис для работы с модальными уведомлениями */

export class ModalNotificationSys {
    public conf = conf;

    public modal_notification: ModalNotificationStoreI = null;

    private queryMsgSys: QuerySys = null;
    private queryCoreSys: QuerySys = null;

    constructor(ctrl: PageCtrl) {
        this.queryMsgSys = new QuerySys();
        this.queryMsgSys.fConfig(gfInitContextQuery(ctrl, conf.msgAPI));
        this.queryCoreSys = new QuerySys();
        this.queryCoreSys.fConfig(gfInitContextQuery(ctrl, conf.coreApi));

        let optionVuex = null;
        if (mIsClient()) {
            optionVuex = {
                preserveState: true, // сохранять состояние на сервере
            };
        }
        this.modal_notification = ctrl.vuexSys.registerModuleCustomKey(
            new ModalNotificationStoreI(),
            'modal_notification_sys',
            optionVuex
        );
    }

    //** Контроль запросов на сервер для показа модальных уведомлений */
    public fModalNotification(aParam: { alias: string }[] = this.modal_notification.prompt_notification) {
        /** Если отображается модалка добавления в корзину, или онбординг, откладываем работу с модалками */
        if (this.modal_notification.is_onboarding_displayed || this.modal_notification.is_modal_add_to_cart_displayed) {
            this.modal_notification.modal_stored_aliases = [...this.modal_notification.modal_stored_aliases, ...aParam];
        } else {
            /** Определение текущего времени */
            const iDateNow: number = Date.now();

            /** Очищаем локальное хранилище от записей,которым больше месяца */
            const aPromptNotificationLS = this.fGetNotificationFromLocalStorage(iDateNow);

            /** Индексированный список из локального хранилища */
            const ixPromptNotificationLS = mKeyBy(aPromptNotificationLS, 'alias');

            /** Задаем начальный индекс для первого элемента массива алиасов */
            this.modal_notification.index_sorted_alias = 0;

            /** Получаем массив уведомлений, для которых ожидается запрос на сервер */
            const aAliasNotifications = this.fGetActualAliases(aParam, iDateNow, ixPromptNotificationLS);

            /** Сортируем массив по времени для определения очередности запросов на сервер */
            this.modal_notification.sorted_aliases = this.fSortArrayByTime(aAliasNotifications, ixPromptNotificationLS);

            /** Вызываем метод запроса на сервер для определения активной модалки */
            this.fRequestForModalArray(iDateNow, ixPromptNotificationLS);
        }

    }

    //**  Отправка алиаса уведомления, чтобы закрыть модальное окно и больше не показывать */
    public fSendOpeningStatus(objParam: NotificationPromptR.setStatusWhenClosed.RequestI) {
        this.queryMsgSys.fInit();
        this.queryMsgSys.fActionOk(() => {});
        this.queryMsgSys.fActionErr((e: Record<string, string>) => {});
        this.queryMsgSys.fSend(NotificationPromptR.setStatusWhenClosed.route, objParam);
    }

    //**  Подборка товаров по уровню доступа */
    public fGetItemCollectionByUserRating() {
        this.queryCoreSys.fInit();
        this.queryCoreSys.fActionOk((data: ItemCollectionR.getListByRating.ResponseI) => {
            this.modal_notification.list_item_of_collection_by_rating = data.list_item_of_collection;
        });
        this.queryCoreSys.fActionErr((e: Record<string, string>) => {});
        const request: ItemCollectionR.getListByRating.RequestI = {};
        this.queryCoreSys.fSend(ItemCollectionR.getListByRating.route, request);
    }

    /** Инициализация онбординга */
    public async fInitOnboarding(sOnboardingAlias: string){
        /** Timeout для поочередной обработки поступающих в сервис модалок и онбординга */
        setTimeout(async () => {
            /** Если отображается модалка добавления в корзину, откладываем онбординг до её закрытия */
            if (this.modal_notification.is_modal_add_to_cart_displayed || this.modal_notification.active_modal){
                this.modal_notification.onboarding_stored_alias = sOnboardingAlias;
            } else {
                this.modal_notification.is_onboarding_displayed = true;
                this.modal_notification.onboarding_stored_alias = '';
                /** Создание экземпляра онбордигнга по алиасу */
                this.modal_notification.onboarding = new Onboarding(sOnboardingAlias);
                /** Инициализация онбординга */
                const isReady = await this.modal_notification.onboarding.init();
                /** Запуск онбординга */
                if (isReady) {
                    this.modal_notification.onboarding.play();
                    /** Слушатель клика по кнопке закрытия онбординга */
                    this.modal_notification.onboarding.fClickCloseBtn(() => {
                        this.modal_notification.is_onboarding_displayed = false;
                        this.modal_notification.onboarding = null;
                        this.fRunModalQuery();
                    })
                }
            }
        }, 500);
    }

    /** Показать модалку добавления в корзину */
    public fShowModalAddToCart(){
        this.modal_notification.is_modal_add_to_cart_displayed = true;
    }

    /** Закрыть модалку добавления в корзину */
    public fCloseModalAddToCart(){
        this.modal_notification.is_modal_add_to_cart_displayed = false;
        this.fRunModalQuery();
    }

    /** Запуск отложенных модалок и онбординга */
    public fRunModalQuery(){
        /** Отложенный ззапуск онбординга */
        if (this.modal_notification.onboarding_stored_alias){
            this.fInitOnboarding(this.modal_notification.onboarding_stored_alias)
        }
        /** Отложенный запуск логики работы с модалками */
        else if (this.modal_notification.modal_stored_aliases.length){
            this.fModalNotification(this.modal_notification.modal_stored_aliases);
        }
    }

    //** Получить актуальные записи из локального хранилища */
    private fGetNotificationFromLocalStorage(iDateNow: number) {
        // Массив объектов из локального хранилища
        let aPromptNotificationLS: PromptMessageLocalStorageI[] = localStorage.getItem('prompt_notification')
            ? JSON.parse(localStorage.getItem('prompt_notification'))
            : [];

        // Очищение записей из локального хранилища, которым больше 30 дней
        if (aPromptNotificationLS.length) {
            const aResult: PromptMessageLocalStorageI[] = [];

            for (let i = 0; i < aPromptNotificationLS.length; i++) {
                if (iDateNow - aPromptNotificationLS[i].date < ONE_MONTH) {
                    aResult.push(aPromptNotificationLS[i]);
                }
            }
            aPromptNotificationLS = aResult;
            localStorage.setItem('prompt_notification', JSON.stringify(aPromptNotificationLS));
        }
        return aPromptNotificationLS;
    }

    //** Добавление в массив уведомлений, для которых ожидается запрос на сервер */
    private fGetActualAliases(
        aParam: { alias: string }[],
        iDateNow: number,
        ixPromptNotificationLS: Record<string, PromptMessageLocalStorageI>
    ) {
        const aResult: string[] = [];

        for (let i = 0; i < aParam.length; i++) {
            const sAlias = aParam[i].alias;

            // В первую очередь записываются уведомления, по которым нет информации в локальном хранилище
            if (!ixPromptNotificationLS[sAlias]) {
                aResult.push(sAlias);
            }
        }
        for (let i = 0; i < aParam.length; i++) {
            const sAlias = aParam[i].alias;
            const isDateMore = iDateNow - ixPromptNotificationLS[sAlias]?.date > TWO_HOURS;

            // Далее записываются уведомления, у которых прошло больше двух часов
            if (isDateMore) {
                aResult.push(sAlias);
            }
        }
        return aResult;
    }

    //** Сортируем массив по времени для определения очередности запросов на сервер */
    private fSortArrayByTime(
        aAliasNotifications: string[],
        ixPromptNotificationLS: Record<string, PromptMessageLocalStorageI>
    ) {
        return aAliasNotifications.sort((a, b) => ixPromptNotificationLS[a]?.date - ixPromptNotificationLS[b]?.date);
    }

    //** Запрос на сервер по массиву названий модалок */
    private fRequestForModalArray(iDateNow: number, ixPromptNotificationLS: Record<string, PromptMessageLocalStorageI>) {
        this.modal_notification.count_sorted_alias = this.modal_notification.sorted_aliases.length;
        const sCurrentAlias: string =
            this.modal_notification.sorted_aliases[this.modal_notification.index_sorted_alias];

        if (this.modal_notification.index_sorted_alias < this.modal_notification.sorted_aliases.length) {
            // Запрос на сервер для проверки статуса показа
            this.fGetOpeningStatus({ alias: sCurrentAlias }, iDateNow, ixPromptNotificationLS);
        }
    }

    //** Получение информации о статусе открытия для показа модального уведомления */
    private fGetOpeningStatus(
        objParam: NotificationPromptR.getNotificationStatus.RequestI,
        iDateNow: number,
        ixPromptNotificationLS: Record<string, PromptMessageLocalStorageI>
    ) {
        this.queryMsgSys.fInit();

        this.queryMsgSys.fActionOk((data: NotificationPromptR.getNotificationStatus.ResponseI) => {
            this.modal_notification.index_sorted_alias++;
            // Если вернулся положительный ответ с сервера, возвращаем название активной модалки
            if (data.need_to_show && !this.modal_notification.active_modal) {
                this.modal_notification.need_to_show = data.need_to_show;
                this.modal_notification.active_modal = data.alias_to_show;                
            } else {
                // Если i-ый элемент массива алиасов меньше длины массива, делаем запрос на сервер для следующего алиаса
                if (this.modal_notification.index_sorted_alias < this.modal_notification.sorted_aliases.length) {
                    const sCurrentAlias: string =
                        this.modal_notification.sorted_aliases[this.modal_notification.index_sorted_alias];
                    // Запрос на сервер для проверки статуса показа
                    this.fGetOpeningStatus({ alias: sCurrentAlias }, iDateNow, ixPromptNotificationLS);
                }
            }
        });

        this.queryMsgSys.fActionErr((e: Record<string, string>) => {});
        this.queryMsgSys.fAction(() => {
            // Обновляем время запроса  в локальном хранилище
            ixPromptNotificationLS[objParam.alias] = { alias: objParam.alias, date: iDateNow };
            localStorage.setItem('prompt_notification', JSON.stringify(Object.values(ixPromptNotificationLS)));
        });

        return this.queryMsgSys.faSend(NotificationPromptR.getNotificationStatus.route, objParam);
    }
}

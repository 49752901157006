import { BannerCollectionInfoI } from './EntitySQL/BannerCollectionE';
import { BannerOfCollectionInfoI } from './EntitySQL/BannerOfCollectionE';
import { BrandInfoI } from './EntitySQL/BrandE';
import { P63HomeSeoI } from './EntitySQL/P63HomeSeoE';
import { P63ItemCollectionI } from './EntitySQL/P63ItemCollectionE';
import { BannerI } from './CommonI/BannerI';
import { HomePageNewsI } from './CommonI/HomePageI';
import { ItemOfCollectionI, ProductOfCollectionI } from './CommonI/ItemCollectionI';
import { ReviewI } from '@/ifc/core/MsgSQL/ReviewE';
import { ItemI } from '@/ifc/core/EntitySQL/ItemE';
import { PostI } from '@/ifc/img/MsgSQL/PostE';
import { PostFileImgI } from '@/ifc/msg/ImgSQL/PostFileImgE';
import { UserInfoPublicI } from '@/ifc/msg/UserUSQL/UserUE';
import { UserAvatarI } from '@/ifc/core/EntitySQL/UserAvatarE';
import { ItemOfTopDayCollectionI } from '@/ifc/msg/CommonI/ItemCollectionI';
import { ItemOfCollectionHomePageI } from '@/ifc/core/CommonI/ItemCollectionI';
import { ProductOfCollectionHomePageI } from '@/ifc/core/CommonI/ItemCollectionI';
import { P63PromoActionI } from '@/ifc/core/EntitySQL/P63PromoActionE';
import { P63PromoActionConditionKeyPurchaseI } from '@/ifc/core/EntitySQL/P63PromoActionConditionPurchaseE';
import { P63promoActionFunctionKeyPurchaseI } from './EntitySQL/P63PromoActionFunctionPurchasseE';

/**
 * Главная страница
 */
export namespace HomePageR {
    // =================================================
    // Баннеры
    // =================================================

    /** Получить баннеры, прикрепленные к  баннерным местам */
    export namespace getListBanner {
        /** APIURL */
        export const route = '/home-page/get-list-bnr';

        /** Параметры api запроса */
        export interface RequestI {}

        /** Параметры api ответа */
        export interface ResponseI {
            list_banner: BannerI[]; // баннеры
        }
    }

    // =================================================
    // Подборки баннеров
    // =================================================

    /** Получить подборки баннеров для главной страницы*/
    export namespace getListBannerCollection {
        /** APIURL */
        export const route = '/home-page/get-list-bnr-collection';

        /** Параметры api запроса */
        export interface RequestI {}

        /** Параметры api ответа */
        export interface ResponseI {
            list_bnr_collection: BannerCollectionInfoI[]; // подборки баннеров
            list_bnr_of_collection: BannerOfCollectionInfoI[]; // баннеры подборки
            list_bnr_collection_read_id: number[]; // ID просмотренных подборок
        }
    }

    /** Пометить подборку баннеров и баннер внутри просмотренными */
    export namespace markBannerCollectionAsRead {
        /** APIURL */
        export const route = '/home-page/mark-bnr-collection-as-read';

        /** Параметры api запроса */
        export interface RequestI {
            bnr_collection_id: number; // ID подборки
            bnr_id: number; // ID баннера
        }

        /** Параметры api ответа */
        export interface ResponseI {}
    }

    // ==================================================
    // Товар дня
    // ==================================================

    /** Получить список товаров дня */
    export namespace getTopDayList {
        /** APIURL */
        export const route = '/home-page/get-top-day-list';

        /** Параметры api запроса */
        export interface RequestI {}

        /** Параметры api ответа */
        export interface ResponseI {
            list_item_top_day_collection: ItemOfTopDayCollectionI[];
        }
    }

    // ==================================================
    // Инициализация Рекомендаций/Хитов продаж
    // ==================================================

    /** Получить порядок расположения табов коллекции "Хиты продаж" */
    export namespace getInitialSalesHits {
        /** APIURL */
        export const route = '/home-page/get-initial-sales-hits';

        /** Параметры api запроса */
        export interface RequestI {
            cache?: number; // Кэш для повторной выборки
        }

        /** Параметры api ответа */
        export interface ResponseI {
            list_collection: P63ItemCollectionI[]; // Список подборок
            list_recommendation_item: ItemOfCollectionHomePageI[]; // Товары рекомендаций
            list_recommendation_product: ProductOfCollectionHomePageI[]; // Продукты рекомендаций
            list_category_item: ItemOfCollectionHomePageI[]; // Товары подборки
            list_category_product: ProductOfCollectionHomePageI[]; // Продукты подборки
            list_action: P63PromoActionI[]; // Акции и их параметры
            list_purchase_action_condition: P63PromoActionConditionKeyPurchaseI[]; // Закупка + акция + условия акции
            list_purchase_action_function: P63promoActionFunctionKeyPurchaseI[]; // Закупка + акция + действия акции
            cnt_item_of_recommendation: number; // Количество товаров в подборке
            cnt_product_of_recommendation: number; // Количество продуктов в подборке
            cnt_item_of_collection: number; // Количество товаров в подборке
            cnt_product_of_collection: number; // Количество продуктов в подборке
            cache: number, // Кэш для повторной выборки
        }
    }

    // =================================================
    // Рекомендации
    // =================================================

    /** Получить список рекомендаций */
    export namespace getRecommendationList {
        /** APIURL */
        export const route = '/home-page/get-recommendation-list';

        /** Параметры api запроса */
        export interface RequestI {
            cache?: number; // кэш для повторной выборки
            list_exclude_item_id?: number[]; // список ID товаров, которые нужно исключить из выборки
            list_exclude_product_id?: number[]; // список ID продуктов, которые нужно исключить из выборки
        }

        /** Параметры api ответа */
        export interface ResponseI {
            list_item: ItemOfCollectionHomePageI[]; // товары подборки
            list_product: ProductOfCollectionHomePageI[]; // продукты подборки
            list_action: P63PromoActionI[]; // Акции и их параметры
            list_purchase_action_condition: P63PromoActionConditionKeyPurchaseI[]; // Закупка + акция + условия акции
            list_purchase_action_function: P63promoActionFunctionKeyPurchaseI[]; // Закупка + акция + действия акции
            cache: number; // кэш, к которому обращались
            cnt_item_of_recommendation: number; // Количество товаров в подборке
            cnt_product_of_recommendation: number; // Количество продуктов в подборке
        }
    }

    // =================================================
    // Подборки товаров
    // =================================================

    /** Получить информацию о топ-подборках */
    export namespace getListCollection {
        /** APIURL */
        export const route = '/home-page/get-list-collection';

        /** Параметры api запроса */
        export interface RequestI {}

        /** Параметры api ответа */
        export interface ResponseI {
            collection: P63ItemCollectionI[]; // коллекции
        }
    }

    /** Получить товары и продукты одной подборки для главной страницы */
    export namespace getListItemOfCollectionNew {
        /** APIURL */
        export const route = '/home-page/get-list-item-of-collection-new';

        /** Параметры api запроса */
        export interface RequestI {
            collection_id: number; // ID коллекции
            cache?: number; // кэш для повторной выборки
            list_exclude_item_id?: number[]; // список ID товаров, которые нужно исключить из выборки
            list_exclude_product_id?: number[]; // список ID продуктов, которые нужно исключить из выборки
        }

        /** Параметры api ответа */
        export interface ResponseI {
            collection_id: number; // ID коллекции
            list_item: ItemOfCollectionHomePageI[]; // Товары подборки
            list_product: ProductOfCollectionHomePageI[]; // Продукты подборки
            list_action: P63PromoActionI[]; // Акции и их параметры
            list_purchase_action_condition: P63PromoActionConditionKeyPurchaseI[]; // Закупка + акция + условия акции
            list_purchase_action_function: P63promoActionFunctionKeyPurchaseI[]; // Закупка + акция + действия акции
            cache: number; // Кэш, к которому обращались
            cnt_item_of_collection: number; // Количество товаров в подборке
            cnt_product_of_collection: number; // Количество продуктов в подборке
        }
    }

    /** Получить товары и продукты одной подборки для главной страницы */
    export namespace getListItemOfCollection {
        /** APIURL */
        export const route = '/home-page/get-list-item-of-collection';

        /** Параметры api запроса */
        export interface RequestI {
            collection_id: number; // ID коллекции
            cache?: number; // кэш для повторной выборки
            list_exclude_item_id?: number[]; // список ID товаров, которые нужно исключить из выборки
            list_exclude_product_id?: number[]; // список ID продуктов, которые нужно исключить из выборки
        }

        /** Параметры api ответа */
        export interface ResponseI {
            collection_id: number; // ID коллекции
            list_item: ItemOfCollectionI[]; // товары подборки
            list_product: ProductOfCollectionI[]; // продукты подборки
            cache: number; // кэш, к которому обращались
            cnt_item_of_collection: number; // количество товаров в коллекции
            cnt_product_of_collection: number; // количество продуктов в коллекции
        }
    }

    // ==================================================
    // Клиенты рекомендуют
    // ==================================================

    /** Получить последние 4 отзыва со страницы "Клиенты рекомендуют" */
    export namespace getReviewList {
        /** APIURL */
        export const route = '/home-page/get-review-list';

        /** Параметры api запроса */
        export interface RequestI {}

        /** Параметры api ответа */
        export interface ResponseI {
            list_review: ReviewI[];
            list_items: ItemI[];
            list_post: PostI[];
            list_post_image: PostFileImgI[];
            list_user: UserInfoPublicI[];
            list_user_avatar: UserAvatarI[];
        }
    }

    // ==================================================
    // Бренды
    // ==================================================

    /** Получение всех брендов */
    export namespace getListBrand {
        /** APIURL */
        export const route = '/home-page/get-list-brand';

        /** Параметры api запроса */
        export interface RequestI {}

        /** Параметры api ответа */
        export interface ResponseI {
            list_brand: BrandInfoI[]; // бренды
        }
    }

    // ====================================================
    // Новости
    // ====================================================

    /** Получение новостей */
    export namespace getNews {
        /** APIURL */
        export const route = '/home-page/get-news';

        /** Параметры api запроса */
        export interface RequestI {}

        /** Параметры api ответа */
        export interface ResponseI {
            list_news: HomePageNewsI[]; // новости
        }
    }

    // ====================================================
    // Сео
    // ====================================================

    /** Получить сео поля по умолчанию 63 регион */
    export namespace getSeo {
        /** APIURL */
        export const route = '/home-page/get-seo';

        /** Параметры api запроса */
        export interface RequestI {}

        /** Параметры api ответа */
        export interface ResponseI {
            seo: P63HomeSeoI; // Сео поля одного региона
        }
    }

    // ====================================================
    // Подписка на Web Push Notification
    // ====================================================

    /** Подписать устройство пользователя на Web Push уведомления */
    export namespace userDeviceSubscribe {
        /** APIURL */
        export const route = '/home-page/user-device-subscribe';

        /** Параметры api запроса */
        export interface RequestI {
            endpoint: string; // Ссылка на устройство пользователя
            p256dh: string; // Публичный ключ устройства
            auth: string; // Ключ аутентификации пользователя
        }

        /** Параметры api ответа */
        export interface ResponseI {
            id_user_device_subs: number; // ID подписки
        }
    }

    /** Получить текущие показатели для информативного блока */
    export namespace getCurrentStats {
        /** APIURL */
        export const route = '/home-page/get-current-stats';

        /** Параметры api запроса */
        export interface RequestI {}

        /** Параметры api ответа */
        export interface ResponseI {
            item_delivered_cnt: number; // Количество доставленных товаров
            brand_cnt: number; // Количество брендов
            active_purchase_cnt: number; // Количество активных закупок
            review_cnt: number; // Количество отзывов
        }
    }
}

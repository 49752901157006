import { QuerySys } from '@63pokupki/front';
import * as conf from '@/configs/config.public';
import { PageCtrl } from './PageCtrl';
import { gfInitContextQuery } from './ContextSys';
import { AuthR } from '@/ifc/auth/AuthR';
import { UserR } from '@/ifc/core/UserR';
import { mIsClient } from '@/common/helpers/ContextH';
import { CartR } from '@/ifc/core/CartR';
import { catchErrorApiResponse } from '@/common/helpers/ErrorHandler';
import { PvzI } from '@/ifc/core/PvzSQL/PvzE';
import { UserInfoI } from '@/ifc/core/EntitySQL/UserE';
import { NotificationR } from '@/ifc/msg/NotificationR';

export class UserStoreI {
    toJSON() {
        return { ...this }; // here I make a POJO's copy of the class instance
    }

    isDisplayModalUserNotSignIn = false;
    info: UserInfoI = null; // Информация по пользователю
    isAuth = false; // Авторизирован ли пользователь
    isOrg = false; // Является ли организатором
    isBuh = false; // Являеися ли бухгалтером
    isAdmin = false; // Является ли Администратором
    isModerator = false; // Является ли модератором
    isMarketing = false; // Является ли маркетингом
    countUnreadNotifications = 0; // Количество непрочитанных уведомлений
    countUnreadEvents = 0; // Количество непрочитанных событий в календаре
    countUnreadPrivmsg = 0; // Количество непрочитанных личных сообщений
    countReadyToPayOrders: number = 0; // Количество заказов готовых к оплате
    countUnreadSystemNotifications = 0; // Количество непрочитанных системных уведомлений
    sCityName = ''; // Название города пользователя
    roles = null; // Роли в организации доступные пользователю
    pvz: PvzI = null; // ПВЗ пользователя

    /** Полная блокировка пользователя */
    bIsFullBlock: boolean = false;
    /** Частичная блокировка пользователя */
    bIsPartialBlock: boolean = false;

    // Персональные условия по закупкам
    delivery_day_self_pvz = 0; // Надбавка времени доставки до своего ПВЗ
}

export class UserSys {
    public user: UserStoreI = null;

    public queryCoreSys: QuerySys = null;

    public queryMsgSys: QuerySys = null;

    constructor(ctrl: PageCtrl) {
        // Конфигурация запроса на core
        this.queryCoreSys = new QuerySys();
        this.queryCoreSys.fConfig(gfInitContextQuery(ctrl, conf.coreApi));
        // Конфигурация запроса на auth.core
        this.queryMsgSys = new QuerySys();
        this.queryMsgSys.fConfig(gfInitContextQuery(ctrl, conf.msgAPI));

        let optionVuex = null;
        if (mIsClient()) {
            optionVuex = {
                preserveState: true, // сохранять состояние на сервере
            };
        }

        this.user = ctrl.vuexSys.registerModuleCustomKey(new UserStoreI(), 'user_sys', optionVuex);
    }

    /** Инициализация */
    async faInit() {
        await Promise.all([
            this.faGetUserInfo(),
            this.faGetNotifications(),
            this.faGetUserCondition(),
            this.faGetReadyToPayOrderCount(),
            this.fGetUserDefaultPvz(),
        ]);
    }

    /** Получить данные пользователя */
    async faGetUserInfo() {
        this.queryCoreSys.fInit();
        this.queryCoreSys.fActionOk(async (data: UserR.getUserInfo.ResponseI) => {
            this.user.isDisplayModalUserNotSignIn = false;
            this.user.info = data.user_info;
            this.user.isAuth = true;
            this.user.isOrg = data.is_org;
            this.user.isBuh = data.is_buh;
            this.user.isAdmin = data.is_admin;
            this.user.isModerator = data.is_moderator;
            this.user.isMarketing = data.is_marketing;
            this.user.roles = data.ix_organization_role;
            this.user.bIsFullBlock = data.is_full_block;
            this.user.bIsPartialBlock = data.is_partial_block;
            this.user.sCityName = data.city_name;
        });
        this.queryCoreSys.fActionErr((err: any) => {
            catchErrorApiResponse({
                error: new Error('Ошибка получения информации по пользователю'),
                response: err,
                meta: 'UserSys.faGetUserInfo',
            });
        });
        let request: AuthR.getUserInfo.RequestI = null;
        await this.queryCoreSys.faSend(UserR.getUserInfo.route, request);
    }

    /** Получить информацию о ПВЗ пользователя */
    async fGetUserDefaultPvz() {
        this.queryCoreSys.fInit();
        this.queryCoreSys.fActionOk((data: UserR.getUserDefaultPvz.ResponseI) => {
            this.user.pvz = data.pvz;
        });
        this.queryCoreSys.fActionErr((err: any) => {
            catchErrorApiResponse({
                error: new Error('Ошибка получения информацию о ПВЗ пользователя'),
                response: err,
                meta: 'UserSys.fGetUserDefaultPvz',
            });
        });
        let request: UserR.getUserDefaultPvz.RequestI = {};
        await this.queryCoreSys.faSend(UserR.getUserDefaultPvz.route, request);
    }

    /** Получить данные о нотификациях */
    async faGetNotifications() {
        this.queryMsgSys.fInit();
        this.queryMsgSys.fActionOk((data: NotificationR.getNotificationCounters.ResponseI) => {
            this.user.countUnreadEvents = data.countUnreadEvents;
            this.user.countUnreadPrivmsg = data.countUnreadPrivmsg;
            this.user.countUnreadSystemNotifications = data.countUnreadSystemNotifications;
        });
        this.queryMsgSys.fActionErr((err: any) => {
            catchErrorApiResponse({
                error: new Error('Ошибка получения нотификаций по пользователю'),
                response: err,
                meta: 'UserSys.faGetNotifications',
            });
        });
        let request: NotificationR.getNotificationCounters.RequestI = null;
        await this.queryMsgSys.faSend(NotificationR.getNotificationCounters.route, request);
    }

    /** Получить данные о количестве заказов готовых к оплате*/
    async faGetReadyToPayOrderCount() {
        this.queryCoreSys.fInit();
        this.queryCoreSys.fActionOk((data: CartR.getReadyInvoiceCount.ResponseI) => {
            this.user.countReadyToPayOrders = data.count;
        });
        this.queryCoreSys.fActionErr((err: any) => {
            catchErrorApiResponse({
                error: new Error('Ошибка получения количества заказов готовых к оплате'),
                response: err,
                meta: 'UserSys.faGetReadyToPayOrderCount',
            });
        });
        let request: CartR.getReadyInvoiceCount.RequestI = null;
        await this.queryCoreSys.faSend(CartR.getReadyInvoiceCount.route, request);
    }

    /** Получить данные о нотификациях */
    async faGetUserCondition() {
        this.queryCoreSys.fInit();
        this.queryCoreSys.fActionOk((data: UserR.getUserCondition.ResponseI) => {
            this.user.delivery_day_self_pvz = data.delivery_day_self_pvz;
        });
        this.queryCoreSys.fActionErr((err: any) => {
            catchErrorApiResponse({
                error: new Error('Ошибка получения нотификаций по пользователю'),
                response: err,
                meta: 'UserSys.faGetUserCondition',
            });
        });
        let request: UserR.getUserCondition.RequestI = null;
        await this.queryCoreSys.faSend(UserR.getUserCondition.route, request);
    }
}

import { render, staticRenderFns } from "./ModalNewApp.vue?vue&type=template&id=1c22d0d2&scoped=true"
import script from "./ModalNewApp.ts?vue&type=script&lang=ts&external"
export * from "./ModalNewApp.ts?vue&type=script&lang=ts&external"
import style0 from "./ModalNewApp.vue?vue&type=style&index=0&id=1c22d0d2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c22d0d2",
  null
  
)

export default component.exports
<script lang="ts">
import Component from 'vue-class-component';
import UserCitySource from './UserCitySource';
import SelectCityModalD from '../SelectCityModal/SelectCityModalD.vue';

@Component({
    components: {
        SelectCityModalD,
    },
})
export default class UserCityD extends UserCitySource {}
</script>

<template>
    <div v-if="vCity" class="user-city">
        <div class="city-button" @click="fCityClick()">
            <svg width="14" height="19" viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M7 18C6.84625 18 6.70814 17.9058 6.65144 17.7623L4.58916 12.5429C3.56174 12.0901 2.68525 11.3609 2.04723 10.4261C1.3621 9.42224 1 8.24606 1 7.02474C1 3.7027 3.69156 1 7 1C10.3084 1 13 3.7027 13 7.02474C13 8.2461 12.6379 9.42224 11.9528 10.4261C11.3148 11.3609 10.4383 12.0901 9.41084 12.5429L7.34856 17.7623C7.29186 17.9058 7.15371 18 7 18ZM7 1.75309C4.10515 1.75309 1.75 4.11795 1.75 7.02474C1.75 9.17903 3.03404 11.0961 5.02128 11.9088C5.11589 11.9475 5.19074 12.0233 5.22839 12.1187L7 16.6024L8.77158 12.1187C8.80926 12.0233 8.88408 11.9475 8.97869 11.9088C10.966 11.0961 12.25 9.17903 12.25 7.02474C12.25 4.11791 9.89485 1.75309 7 1.75309Z"
                    fill="white"
                    stroke="white"
                    stroke-width="0.4"
                />
                <path
                    d="M7 10.4136C5.13903 10.4136 3.625 8.8933 3.625 7.02466C3.625 5.15601 5.13903 3.63574 7 3.63574C8.86098 3.63574 10.375 5.15601 10.375 7.02466C10.375 8.8933 8.86098 10.4136 7 10.4136ZM7 4.38883C5.55254 4.38883 4.375 5.57126 4.375 7.02466C4.375 8.47805 5.55254 9.66048 7 9.66048C8.44743 9.66048 9.625 8.47805 9.625 7.02466C9.625 5.57126 8.44743 4.38883 7 4.38883Z"
                    fill="white"
                    stroke="white"
                    stroke-width="0.4"
                />
            </svg>
            {{ vCity.city_name }}
        </div>

        <CityTooltip v-if="isShowTooltip" :sCity="vCity.city_name" :idCity="vCity.city_id" @close="fCloseTooltip" />

        <SelectCityModalD v-if="isShowSelectCity" :sCity="vCity.city_name" @closeTooltip="fCloseTooltip" />
    </div>
</template>

<style lang="scss" scoped>
.ssc-wrapper {
    padding: 6px 24px !important;
}
.user-city {
    position: relative;
}
.city-button {
    display: flex;
    align-items: center;
    color: #fff;
    margin-right: 40px;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
    position: relative;
    gap: 8px;
}
.city-tooltip {
    top: 25px !important;
}
</style>

import { Component, Prop } from 'vue-property-decorator';
import P63Vue from '@/system/P63Vue';
import { HomeCtrl, initHomeCtrl } from '../../view/ctrl-home';
import { mIsClient } from '@/common/helpers/ContextH';
import dayjs from 'dayjs';
import RatingStars from '@/common/el/RatingStars/RatingStars';
import { gImageS } from '@/common/service/ImageS';
import { mReplaceTextLink } from '@/common/helpers/TextFormatterH';

@Component({
    components: {
        RatingStars,
    },
})
export default class ReviewCardSource extends P63Vue {
    /** id поста */
    @Prop({ type: Number, default: 0 }) idPost: number;

    /** Отображать ли кнопку "Показать все"*/
    bShowAllButton: boolean = false;

    /** Раскрывать ли текст полностью */
    bExpandReviewText: boolean = false;

    /** Получить URL изображения по его filename */
    fGetImageUrlByFileName(sFileName: string, isBig: boolean): string {
        return gImageS.fPostImageUrlx256(sFileName);
    }

    /** Открыть окно просмотра картинок к посту */
    fOpenPopupMessageImage(nIndexImg: number): void {
        this.ctrl.status.id_current_post = this.idPost;
        this.ctrl.status.id_current_item = this.idItem;
        this.ctrl.status.index_img = nIndexImg;
        this.ctrl.status.is_open_modal_review_image = true;
    }

    /** Показать весь текст в отзыве */
    fShowAllReviewText(): void {
        this.bExpandReviewText = !this.bExpandReviewText;
    }

    /** Отправить данные в статистику о клике по Товару/Продукту в разделе отзывов */
    fSendActionItemClick() {
        const sStatType = this.vItem.product_id ? 'product_new' : 'item_new';
        const idReviewItem = this.vItem.product_id ? this.vItem.product_id : this.vItem.item_id;

        this.ctrl.statSys.faPushClickToStat({
            page: location.pathname,
            type: sStatType,
            alias: 'review_item_click_home_new',
            entity_id: idReviewItem,
        });
    }

    mounted() {
        const text = document.getElementById(`review-text ${this.idPost}`);
        this.bShowAllButton = text.offsetHeight < text.scrollHeight;
    }

    /** Получить информацию о посте */
    get vPost() {
        return this.ix.post[this.idPost];
    }

    /** Получить информацию об отзыве */
    get vReview() {
        return this.ix.review[this.idPost];
    }

    /** Получить информациию о товаре, на который был оставлен отзыв */
    get vItem() {
        return this.ix.item[this.idItem];
    }

    /** Получить изображения поста */
    get vPostImages() {
        return this.ix.post_image[this.idPost];
    }

    /** Получить ID пользователя, оставившего отзыв*/
    get idUser() {
        return this.vPost.author_user_id;
    }

    /** Получить ID товара, на который был оставлен отзыв */
    get idItem() {
        return this.vReview.item_id;
    }

    /** Получить информацию об аватарке пользователя */
    get vUserAvatar() {
        return this.ix.user_avatar[this.idUser];
    }

    /** Получить инфорамцию о пользователе */
    get vUserInfo() {
        return this.ix.user[this.idUser];
    }

    /** Получить имя пользователя */
    get sUsername() {
        return this.vUserInfo.username;
    }

    /** Получить путь до аватарки пользователя */
    get sUserAvatar() {
        return gImageS.fAvatarImageUrlx100(this.vUserAvatar.filename);
    }

    /** Путь к спрайту с иконками */
    get sSpritePath() {
        return this.ctrl.status.sSpritePath;
    }

    /** Дата создания поста */
    get sCreatePostDate() {
        return dayjs(this.vPost.created_at).format('DD.MM.YY, HH:mm');
    }

    /** Получить наименование товара */
    get sItemName() {
        return this.vItem.item_name;
    }

    /** Получить ссылку на профиль пользователя */
    get sUserUrl() {
        return `/profile#/${this.vPost.author_user_id}`;
    }

    /** Ссылка на товар */
    get sItemHref() {
        return this.vItem.product_id ? `/product/${this.vItem.product_id}` : `/item/${this.vItem.item_code}`;
    }

    /** Форматированный текст поста */
    get sFormatPostText() {
        return mIsClient() ? mReplaceTextLink(this.vPost.text, 'ourLink') : this.vPost.text;
    }

    /** Получить текст для кнопки раскрытия отзыва */
    get sShowButtonText(): string {
        let sText = 'Показать все';
        if (this.bExpandReviewText) {
            sText = 'Свернуть';
        }
        return sText;
    }

    get ix() {
        return this.ctrl.ix;
    }

    /** Хранилище */
    get ctrl(): HomeCtrl {
        return initHomeCtrl(mIsClient() ? this.$context : this.$ssrContext);
    }
}

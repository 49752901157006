import { ItemInfoI } from './EntitySpSQL/ItemE';
import { UserDictionaryI } from './UserUSQL/UserUE';
import { NotificationDurationI } from './EntitySQL/NotificationDurationE';
import {
    NotificationI,
    NotificationWithUsernameI,
    SystemNotificationT,
    UserNotificationT,
} from './EntitySQL/NotificationE';
import { NotificationInvoiceI } from './EntitySQL/NotificationInvoiceE';
import {
    NotificationMarketingI,
    NotificationMarketingWithStockI,
    NotificationSubscribeStatusI,
} from './EntitySQL/NotificationMarketingE';
import { NotificationPurchaseInfoI } from './EntitySQL/NotificationPurchaseE';
import { NotificationStatusT, NotificationRecipientI } from './EntitySQL/NotificationRecipientE';
import { PaginationI } from './CommonI/PaginationI';
import { S3NotificationFileInfoI } from './EntitySpSQL/S3NotificationFileE';

/** CRUD по уведомлениям */
export namespace NotificationR {
    /** Получить все пользовательские уведомления пользователя с пагинацией */
    export namespace getUserNotificationList {
        /** APIURL */
        export const route = '/notification/get-user-notification-list';

        /** Параметры api запроса */
        export interface RequestI {
            filter_status?: NotificationStatusT; // Фильтр по статусу уведомления
            filter_type?: UserNotificationT[]; // Фильтр по типу пользовательских уведомлений
            org_user_id?: number; // ID организатора
            search?: string; // Поиск по названию закупки
            date?: string; // Дата уведомления
            page?: number; // Страница
            limit?: number; // Количество на странице
        }

        /** Параметры api ответа */
        export interface ResponseI {
            notification: NotificationWithUsernameI[]; // Основное инфо об уведомлениях c юзернеймами
            notification_recipient: NotificationRecipientI[]; // Инфо о состоянии уведомлений пользователей
            notification_duration: NotificationDurationI[]; // Инфо о продолжительности
            notification_invoice: NotificationInvoiceI[]; // Инфо по заказам
            notification_purchase: NotificationPurchaseInfoI[]; // Инфо по закупкам
            notification_marketing: NotificationMarketingWithStockI[]; // Инфо по маркетинговым уведомлениям
            notification_marketing_banner?: Record<number, string>; // Record<notification_id, banner_url>
            notification_marketing_item?: Record<number, number[]>; // Record<notification_id, item_id[]>
            notification_subscribe_status: NotificationSubscribeStatusI[]; // Инфо по подпискам пользователя
            list_notification_image: S3NotificationFileInfoI[]; // Список вложений-картинок постов
            list_notification_not_image: S3NotificationFileInfoI[]; // Список вложений-не-картинок постов
            item_list?: ItemInfoI[]; // Инфо о товарах, включенных в маркетинговые уведомления
            pagination: PaginationI;
        }
    }

    /** Получить общую информацию по системных уведомлениям */
    export namespace getSystemNotificationInfo {
        /** APIURL */
        export const route = '/notification/get-system-notification-info';

        /** Параметры api запроса */
        export interface RequestI {
            user_id?: number; // ID пользователя (для модераторов)
        }

        /** Параметры api ответа */
        export interface ResponseI {
            list_notification_count: { type: SystemNotificationT; count: number; count_unread: number }[]; // Объект числа уведомлений
        }
    }

    /** Получить все системные уведомления пользователя с пагинацией */
    export namespace getUserSystemNotificationList {
        /** APIURL */
        export const route = '/notification/get-user-system-notification-list';

        /** Параметры api запроса */
        export interface RequestI {
            filter_status?: NotificationStatusT; // Фильтр по статусу уведомления
            filter_type?: SystemNotificationT[]; // Фильтр по типу системных уведомлений
            user_id?: number; // Фильтр по ID пользователя
            date_start?: string; // Дата начала поиска
            date_end?: string; // Дата окончания поиска
            search?: string; // Поиск по названию
            page?: number; // Страница
            limit?: number; // Количество на странице
        }

        /** Параметры api ответа */
        export interface ResponseI {
            notification: NotificationI[]; // Основное инфо об уведомлениях
            list_notification_image: S3NotificationFileInfoI[]; // Список вложений-картинок уведомлений
            list_notification_not_image: S3NotificationFileInfoI[]; // Список вложений-не-картинок уведомлений
            notification_recipient: NotificationRecipientI[]; // Инфо о состоянии уведомлений пользователей
            pagination: PaginationI;
        }
    }

    /** Получить одно уведомление */
    export namespace getNotification {
        /** APIURL */
        export const route = '/notification/get';

        /** Параметры api запроса */
        export interface RequestI {
            notification_id: number; // ID уведомления
        }

        /** Параметры api ответа */
        export interface ResponseI {
            main: NotificationI; // Основное инфо
            recipient?: NotificationRecipientI; // Инфо о получателе
            duration?: NotificationDurationI; // Продолжительность инфо
            invoice?: NotificationInvoiceI; // Инфо по заказу
            purchase?: NotificationPurchaseInfoI; // Инфо по закупке
            marketing?: NotificationMarketingI; // Инфо маркетинг
        }
    }

    /**
     * Пометить уведомления как прочитанные
     *
     * Если список ID уведомлений не передан -
     * будут помечены прочитанными все уведомления текущего пользователя
     */
    export namespace markAsRead {
        /** APIURL */
        export const route = '/notification/mark-as-read';

        /** Параметры api запроса */
        export interface RequestI {
            notification_id_list?: number[]; // Список ID уведомлений
        }

        /** Параметры api ответа */
        export interface ResponseI {
            // Нет ответа
        }
    }

    /**
     * Пометить уведомления как непрочитанные
     *
     * Если список ID уведомлений не передан -
     * будут помечены как непрочитанные все уведомления текущего пользователя
     */
    export namespace markAsUnread {
        /** APIURL */
        export const route = '/notification/mark-as-unread';

        /** Параметры api запроса */
        export interface RequestI {
            notification_id_list?: number[]; // Список ID уведомлений
        }

        /** Параметры api ответа */
        export interface ResponseI {
            // Нет ответа
        }
    }

    /**
     * Пометить все уведомления по фильтру как прочитанные
     */
    export namespace markAsReadAll {
        /** APIURL */
        export const route = '/notification/mark-as-read-all';

        /** Параметры api запроса */
        export interface RequestI {
            notification_id_list?: number[]; // Список ID уведомлений, которые не будут помечены
            filter_type?: UserNotificationT; // Тип уведомлений
        }

        /** Параметры api ответа */
        export interface ResponseI {
            // Нет ответа
        }
    }

    /** Получить число непрочитанных уведомлений пользователя */
    export namespace getUnreadNotificationNumber {
        /** APIURL */
        export const route = '/notification/get-unread-number';

        /** Параметры api запроса */
        export interface RequestI {
            // Нет параметров
        }

        /** Параметры api ответа */
        export interface ResponseI {
            unread_user_notification_cnt: number;
            unread_system_notification_cnt: number;
        }
    }

    /** Пометить уведомления как удалённые */
    export namespace markAsDeleted {
        /** APIURL */
        export const route = '/notification/mark-as-deleted';

        /** Параметры api запроса */
        export interface RequestI {
            notification_id_list?: number[]; // Список ID уведомлений
        }

        /** Параметры api ответа */
        export interface ResponseI {
            // Нет ответа
        }
    }

    /** Пометить все уведомления по фильтру как удаленные */
    export namespace markAsDeletedAll {
        /** APIURL */
        export const route = '/notification/mark-as-deleted-all';

        /** Параметры api запроса */
        export interface RequestI {
            notification_id_list?: number[]; // Список ID уведомлений, которые не будут удалены
            filter_status?: NotificationStatusT; // Статус уведомлений
            filter_type?: UserNotificationT; // Тип уведомлений
        }

        /** Параметры api ответа */
        export interface ResponseI {
            // Нет ответа
        }
    }

    /** Восстановить уведомления */
    export namespace restore {
        /** APIURL */
        export const route = '/notification/restore';

        /** Параметры api запроса */
        export interface RequestI {
            notification_id_list?: number[]; // Список ID уведомлений
        }

        /** Параметры api ответа */
        export interface ResponseI {
            // Нет ответа
        }
    }

    /** Подписаться/отписаться от уведомлений закупок */
    export namespace addSubscribed {
        /** APIURL */
        export const route = '/notification/add-subscribed';

        /** Параметры api запроса */
        export interface RequestI {
            stock_id_list?: number[]; // Список ID шаблонов закупки
        }

        /** Параметры api ответа */
        export interface ResponseI {
            // Нет ответа
        }
    }

    /** Отписаться от всех рекламных уведомлений закупок */
    export namespace unsubscribeAll {
        /** APIURL */
        export const route = '/notification/unsubscribe-all';

        /** Параметры api запроса */
        export interface RequestI {
            notification_id_list?: number[]; // Список ID уведомлений, от которых не будет отписки
        }

        /** Параметры api ответа */
        export interface ResponseI {
            // Нет ответа
        }
    }

    /** Получить список отправителей уведомлений */
    export namespace getSenderList {
        /** APIURL */
        export const route = '/notification/get-sender-list';

        /** Параметры api запроса */
        export interface RequestI {
            // Нет запроса
        }

        /** Параметры api ответа */
        export interface ResponseI {
            sender_list: UserDictionaryI[];
        }
    }

    /** Получить информацию об организаторах (для модераторов) */
    export namespace getOrgs {
        /** APIURL */
        export const route = '/notification/get-org-list';

        /** Параметры api запроса */
        export interface RequestI {
            // Нет запроса
        }

        /** Параметры api ответа */
        export interface ResponseI {
            list_orgs: UserDictionaryI[];
        }
    }

    /** Получить счетчики уведомлений/сообщений пользователя */
    export namespace getNotificationCounters {
        /** APIURL */
        export const route = '/notification/get-notification-counters';

        /** Параметры api запроса */
        export interface RequestI {
            // Нет запроса
        }

        /** Параметры api ответа */
        export interface ResponseI {
            countUnreadEvents: number;
            countUnreadPrivmsg: number;
            countUnreadSystemNotifications: number;
        }
    }

    /** Получить счетчики организатора */
    export namespace getOrgNotificationCounters {
        /** APIURL */
        export const route = '/notification/get-org-notification-counters';

        /** Параметры api запроса */
        export interface RequestI {
            // Нет запроса
        }

        /** Параметры api ответа */
        export interface ResponseI {
            count_unprocessed_discussion: number; // Число необработанных вопросов/отзывов
        }
    }

    /**
     * Пометить все уведомления определенного типа у пользователя как прочитанные
     */
    export namespace markAsReadAllByType {

        /** APIURL */
        export const route = '/notification/mark-as-read-all-by-type';

        /** Параметры api запроса */
        export interface RequestI {
            notification_type: SystemNotificationT
        }

        /** Параметры api ответа */
        export interface ResponseI {
        }
    }
}

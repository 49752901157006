<template>
    <section class="slider__section" aria-label="Баннер">
        <!-- Со слайдером, если больше одного баннера, и они верхние -->
        <div v-if="bTop && banner.length > 1">
            <div class="main-slider main-slider_banner slider__wrapper" id="mainBanner" ref="mainBanner">
                <button
                    class="main-slider__button main-slider__button_left"
                    aria-label="влево"
                    @click="fOnSliderSwap('left', null, false)"
                ></button>

                <button
                    class="main-slider__button main-slider__button_right"
                    @click="fOnSliderSwap('right', null, false)"
                    aria-label="вправо"
                ></button>
                <ul ref="main-slider" :class="['main-slider__list', { 'main-slider__list_top': bTop }]">
                    <li
                        v-for="item in banner"
                        :key="item.banner_id"
                        class="main-banner main-banner_top"
                        :style="!bMobile ? { width: `${iSwipeWidth}px` } : ''"
                    >
                        <a
                            v-if="item"
                            @click="fOnBannerClick(item)"
                            :href="ixResUrls[item.banner_id]"
                            :target="$sTargetLink"
                            class="main-banner__link main-banner__link-top"
                        >
                            <picture>
                                <source
                                    v-if="item.is_webp"
                                    :srcset="fGetImgUrl(item.filename, item.is_webp)"
                                    type="image/webp"
                                />
                                <img
                                    width="1184"
                                    height="264"
                                    :loading="bTop && item.banner_id === vFirstBanner.banner_id ? 'eager' : 'lazy'"
                                    :decoding="bTop && item.banner_id === vFirstBanner.banner_id ? 'sync' : 'async'"
                                    :alt="item.banner_name"
                                    class="main-banner__img banner-top__img"
                                    :src="fGetImgUrl(item.filename, item.is_webp)"
                                />
                            </picture>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="indicator-circle__flex">
                <ul class="indicator-circle__container">
                    <li v-for="(circle, idx) in banner" :key="idx" class="indicator-circle">
                        <div
                            class="indicator-circle__circle"
                            :class="{
                                'indicator-circle__circle_big': idx === nDisplayedBannerIdx,
                                'indicator-circle__circle_regular':
                                    idx === nDisplayedBannerIdx + 1 || idx === nDisplayedBannerIdx - 1,
                                'indicator-circle__circle_small':
                                    idx < nDisplayedBannerIdx - 1 || idx > nDisplayedBannerIdx + 1,
                            }"
                        >
                            <div
                                @click="fOnSliderSwap('', idx, false)"
                                class="indicator-circle__clickable-field"
                                :class="{
                                    'indicator-circle__clickable-field_big': idx === nDisplayedBannerIdx,
                                    'indicator-circle__clickable-field_regular':
                                        idx === nDisplayedBannerIdx + 1 || idx === nDisplayedBannerIdx - 1,
                                    'indicator-circle__clickable-field_small':
                                        idx < nDisplayedBannerIdx - 1 || idx > nDisplayedBannerIdx + 1,
                                }"
                            />
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <!-- Без слайдера, если один баннер -->
        <div
            v-else-if="banner.length && fGetImgUrl(vFirstBanner.filename, vFirstBanner.is_webp)"
            class="main-banner"
            :class="[
                bannerType ? `main-banner_${bannerType}` : '',
                bTop && bMobile ? 'main-banner_top-mobile' : '',
                bTop && !bMobile ? 'main-banner_top-desktop' : '',
            ]"
        >
            <a
                @click="fOnBannerClick(vFirstBanner)"
                :target="$sTargetLink"
                :href="ixResUrls[vFirstBanner.banner_id]"
                class="main-banner__link"
            >
                <picture>
                    <source
                        v-if="vFirstBanner.is_webp"
                        :srcset="fGetImgUrl(vFirstBanner.filename, vFirstBanner.is_webp)"
                        type="image/webp"
                    />
                    <img
                        :width="bTop ? '1184' : '580'"
                        :height="bTop ? '264' : '252'"
                        :loading="bTop ? 'eager' : 'lazy'"
                        :decoding="bTop ? 'sync' : 'async'"
                        :alt="vFirstBanner.banner_name"
                        :src="fGetImgUrl(vFirstBanner.filename, vFirstBanner.is_webp)"
                        class="main-banner__img banner-top__img"
                    />
                </picture>
            </a>
        </div>

        <!-- Заглушка, если нет баннеров -->
        <div
            v-else
            class="main-banner"
            :class="[
                bannerType ? `main-banner_${bannerType}` : '',
                bTop && bMobile ? 'main-banner_type_place-top-view-mobile' : '',
                bTop && !bMobile ? 'main-banner_type_place-top-view-desktop' : '',
            ]"
        >
            <a
                v-if="isClient && !banner.length"
                @click="fOnBannerClick(banner)"
                href="/purchases?parentCategory&category&tags=15&soonStop=false&first=false&sort=date&search=&offset=0&show=true&newMode"
                :target="$sTargetLink"
                class="main-banner__link"
            >
                <img
                    class="main-banner__img plug"
                    :src="sPlugHref"
                    :width="bTop ? '1184' : '580'"
                    :height="bTop ? '264' : '252'"
                    :loading="bTop ? 'eager' : 'lazy'"
                    :decoding="bTop ? 'sync' : 'async'"
                    alt="Заглушка вместо баннера"
                />
            </a>
        </div>
    </section>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import HomeBannerSource from './home-banner-source';

@Component
export default class HomeBanner extends HomeBannerSource {}
</script>

<style lang="scss" scoped>
.main-slider {
    position: relative;

    width: fit-content;
    max-width: 100%;
    padding: 16px 12px;

    @media (max-width: 767px) {
        &#slider-2,
        &#slider-5 {
            padding-top: 0;
        }

        &#slider-4,
        &#slider-1 {
            .main-slider__footer {
                margin-top: 0;
            }
        }
    }

    &_without-buttons {
        .main-slider__button-wrapper {
            display: none;
        }
    }

    &__title {
        margin: 12px 0;

        font-size: 12px;
        font-weight: normal;

        @media (max-width: 767px) {
            margin-top: 0;
        }

        &_subtitle {
            color: #1d1e1f;

            font-size: 12px;
            font-weight: bold;
            line-height: 20px;
        }

        .main-slider__footer-link {
            display: none;
        }
    }

    &__button-wrapper {
        display: none;
    }

    &__list {
        display: flex;
        overflow-x: scroll;
        overflow-y: hidden;

        min-height: 150px;
        margin: 0;
        padding: 0;
        padding-bottom: 15px;

        list-style: none;

        will-change: transform;
        scrollbar-width: thin;
        scrollbar-color: #36a5f2 #36a5f226;

        &::-webkit-scrollbar {
            width: 11px;

            background: linear-gradient(
                to top,
                transparent 0,
                transparent 2px,
                #36a5f226 2px,
                #36a5f226 4px,
                transparent 4px
            );
        }

        &::-webkit-scrollbar-thumb {
            background: linear-gradient(
                to top,
                transparent 0,
                transparent 2px,
                #36a5f2 2px,
                #36a5f2 4px,
                transparent 4px
            );
        }

        &_top {
            overflow: visible;

            min-width: 100%;
            padding: 0;

            @media (max-width: 580px) {
                height: auto;
            }
        }
    }

    &__link {
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        height: 100%;

        text-decoration: none;

        &:hover {
            text-decoration: none;
        }
    }

    &__item {
        display: flex;
        flex-direction: column;

        width: 150px;
        min-width: 150px;
        height: fit-content;
        margin-right: 12px;

        &:last-child {
            margin: 0;
        }

        &-img-container {
            display: flex;
            justify-content: center;
            align-items: center;

            width: 142px;
            height: 214px;
        }

        &-img {
            max-width: 100%;
            max-height: 100%;
        }

        &-name {
            margin-top: 19px;
            margin-bottom: 10px;
            padding-right: 5px;

            transition: color 0.2s ease;

            color: #1e2023;

            font-size: 12px;
            line-height: 18px;
        }

        &-price {
            margin-top: auto;

            color: #1e2023;

            font-size: 16px;
            font-weight: bold;
            line-height: 16px;

            &_penny {
                margin-left: -3px;

                color: #a2a3a5;

                font-size: 14px;
                line-height: 14px;
            }

            &_tax {
                opacity: 0.8;
                color: #a2a3a5;

                font-size: 12px;
                font-weight: normal;
                line-height: 1;
            }
        }

        &:hover {
            .main-slider__item-name {
                color: #36a6f2;
            }
        }
    }

    &__footer {
        margin-top: 10px;

        &-link {
            display: flex;
            justify-content: space-between;
            align-items: center;

            padding: 10px 0;

            text-decoration: none;
        }

        &-name {
            color: #36a6f2;

            font-size: 14px;
            line-height: 17px;

            text-decoration-line: underline;
        }

        .ds-icon {
            margin-top: 4px;

            color: #36a6f2;
        }
    }

    &_big {
        .main-slider__list {
            max-height: 465px;
        }

        .main-slider__nested-wrapper:nth-child(2) {
            display: none;
        }

        .main-slider__nested-list {
            display: flex;

            padding: 0;
        }

        .main-slider__item {
            width: 150px;
            margin-right: 10px;
        }

        .main-slider__title {
            font-size: 14px;
            font-weight: bold;
        }
    }

    &_banner {
        overflow: visible;

        width: 100%;
        max-width: 100%;
        padding: 0;

        .main-slider__button {
            position: absolute;
            z-index: 10;
            top: 50%;

            width: 50px;
            height: 50px;

            opacity: 0.7;
            border-radius: 50%;
            background: rgba(255, 255, 255, 0.35);
            // background-color: #c5c4c4;
            box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15);
            &:hover {
                opacity: 1;
                background: #ffffff;
            }

            @media (max-width: 1024px) {
                display: none;
            }

            &_left {
                left: 20px;
            }

            &_right {
                right: 20px;
            }

            &_left,
            &_right {
                transform: translateY(-50%);
            }

            &_left::before,
            &_left::after {
                position: absolute;
                left: 15px;

                display: block;

                width: 15px;
                height: 2px;

                content: '';

                border-radius: 5px;
                background-color: #c5c4c4;
            }

            &_left:hover:before,
            &_left:hover:after,
            &_right:hover:before,
            &_right:hover:after {
                background-color: #36a6f2;
            }

            &_right::before,
            &_right::after {
                position: absolute;
                left: 19px;

                display: block;

                width: 15px;
                height: 2px;

                content: '';

                border-radius: 5px;
                background-color: #c5c4c4;
            }

            &_left::before {
                transform: translateY(5px) rotate(45deg);
            }

            &_left::after {
                transform: translateY(-5px) rotate(-45deg);
            }

            &_right::before {
                transform: translateY(5px) rotate(-45deg);
            }

            &_right::after {
                transform: translateY(-5px) rotate(45deg);
            }
        }

        .main-slider__list {
            width: max-content;
        }
    }

    &#slider-1 {
        .main-slider__title {
            @media (max-width: 767px) {
                margin-top: 6px;
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 1183px) {
    .main-slider {
        &__title {
            margin-top: 0;
        }
    }
}

@media (min-width: 768px) {
    .main-slider {
        &__title {
            margin: 20px 0;

            line-height: 20px;

            &_hot {
                font-size: 12px;
            }

            &_subtitle {
                font-size: 14px;
            }
        }

        &__item {
            margin-right: 28px;

            &-img-container {
                display: flex;
                justify-content: center;
                align-items: center;

                width: 150px;
                height: 225px;
            }
        }

        &_big {
            .main-slider__list {
                max-height: 940px;
            }

            .main-slider__item {
                width: 150px;
                margin-right: 28px;
            }
        }
    }
}

@media (min-width: 1184px) {
    .main-slider {
        overflow: hidden;

        max-width: 791px;
        padding: 16px 12px;

        &__title {
            display: flex;
            align-items: center;

            margin-top: 28px;
            margin-left: -11px;

            &_hot {
                margin-right: 5px;

                font-size: 14px;
            }

            &_subtitle {
                margin-right: 10px;

                color: #1d1e1f;

                font-size: 16px;
                font-weight: bold;
                line-height: 20px;
            }

            .main-slider__footer-link {
                display: flex;

                padding: 0;
            }
        }

        &__button-wrapper {
            position: absolute;
            z-index: 10;
            top: 50%;

            display: flex;
            justify-content: space-between;

            width: calc(100% - 14px);

            transform: translateY(-50%);
        }

        &__button {
            // top: 50%;
            // top: calc(50% - 11px);
            // transform: translateY(-50%);
            position: absolute;

            width: 15px;
            height: 22px;

            &::after {
                position: absolute;
                left: 0;

                display: block;

                width: 15px;
                height: 2px;

                content: '';

                border-radius: 5px;
                background-color: #c5c4c4;
            }

            &::before {
                position: absolute;
                left: 0;

                display: block;

                width: 15px;
                height: 2px;

                content: '';

                border-radius: 5px;
                background-color: #c5c4c4;
            }
            &:hover:after {
                background-color: #36a6f2;
            }
            &:hover:before {
                background-color: #36a6f2;
            }

            &_left {
                left: -14px;

                &::after {
                    transform: translateY(-5px) rotate(-45deg);
                }

                &::before {
                    transform: translateY(5px) rotate(45deg);
                }
            }

            &_right {
                right: -4px;

                margin-left: auto;

                &::after {
                    right: 0;
                    left: auto;

                    transform: translateY(-5px) rotate(45deg);
                }

                &::before {
                    right: 0;
                    left: auto;

                    transform: translateY(5px) rotate(-45deg);
                }
            }

            &:hover {
                opacity: 0.6;
            }

            &:active {
                opacity: 0.4;
            }
        }

        &__list {
            overflow: visible;

            width: max-content;
            margin: 0 8px;
            padding: 0 5px 15px;
        }

        &__item {
            width: 160px;
            height: 346px;
            margin-right: 35px;

            &-img-container {
                width: 160px;
                height: 240px;
            }
        }

        &__item-name {
            margin-top: 23px;

            font-size: 14px;
            line-height: 20px;
        }

        &__item-price {
            font-size: 16px;
            font-weight: 700;

            &-container {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            &_tax {
                display: block;

                margin-top: 6px;
            }
        }

        &__item-old-price {
            position: relative;

            padding: 0 5px;

            opacity: 0.3;
            color: #1e2023;

            font-size: 16px;
            font-weight: bold;
            line-height: 20px;

            &::after {
                position: absolute;
                top: 50%;
                right: 0;
                left: 0;

                display: block;

                height: 2px;

                content: '';
                transform: translateY(-50%) rotate(-12deg);

                background-color: #cb000d;
            }
        }

        &__footer {
            display: none;
        }

        &_big {
            width: 100%;
            padding: 0;

            .main-slider__list {
                display: flex;
                overflow-y: auto;
                flex-flow: wrap;
                flex-direction: column;

                width: 100%;
                height: 730px;
                padding: 0;

                &::-webkit-scrollbar {
                    height: auto;

                    background-color: rgba(54, 165, 242, 0.15);
                }

                &::-webkit-scrollbar-thumb {
                    background: #36a5f2;
                }
            }

            .main-slider__item {
                width: 160px;
                margin-right: 7%;
                margin-bottom: 20px;

                &:nth-of-type(3n) {
                    margin-right: 0;
                }
            }

            .main-slider__item-img {
                width: auto;
                height: auto;
                margin: 0 auto 10px;
            }

            .main-slider__nested-wrapper:nth-child(2) {
                display: none;
            }
        }

        &_banner {
            width: 100%;
            max-width: 100%;
            padding: 0;

            .main-slider__list {
                margin: 0;
                padding: 0;
            }

            .main-slider__button_left {
                top: 50%;

                transform: translate(0, -50%);
            }

            .main-slider__button_right {
                top: 50%;

                transform: translate(0, -50%);
            }
        }
    }
}
:root {
    --scrollbar-width: calc(100vw - 100%);
}

.main-banner {
    width: 353px;
    max-width: 100%;
    height: 520px;
    margin: 0 auto;

    @media (min-width: 768px) {
        margin: 0;
    }

    &_top {
        display: flex;
        justify-content: center;

        width: calc(100vw - 30px);
        max-width: 100vw;
        height: auto;
        margin-left: 5px;

        @media (min-width: 768px) {
            overflow: hidden;

            width: 100%;
            margin-left: 0;

            object-fit: contain;
            border-radius: 8px;
        }

        @media (min-width: 1184px) {
            max-width: 1184px;
            height: auto;
            min-height: 260px;
        }
    }

    &_top-mobile {
        width: calc(100% - 30px);
        margin: 0px auto 28px;
    }

    &_top-desktop {
        width: 100%;
        margin: 0px 0px 48px 0px;
    }

    &_mini {
        width: 580px;
        max-width: 100%;
        height: auto;
        max-height: 252px;
    }

    &__link,
    &__img {
        width: 100%;
        height: auto;

        border-radius: 6px;

        @media (min-width: 768px) {
            width: 100%;
            height: 100%;
            max-height: 264px;
        }
    }
}

// Зона прокликивания для кнопок
.click-zone {
    position: relative;

    width: 0;
    height: 0;

    &::after {
        position: absolute;
        top: -100px;

        width: 50px;
        min-height: 200px;

        content: '';
    }
}

// зона левой кнопки
.main-slider__button_left {
    .click-zone::after {
        left: -15px;
    }
}

// зона правой кнопки
.main-slider__button_right {
    .click-zone::after {
        left: -35px;
    }
}

.main-slider__list {
    // padding-left: 12px;
    transition: transform 0.5s ease 0s;
}

// New styles
.slider {
    &__wrapper,
    &__section {
        overflow: hidden;

        height: auto;
    }
}

.banner {
    &-top {
        &__img {
            width: 100%;

            @media (max-width: 1023px) {
                border-radius: 6px;
            }
        }
    }
}

.plug {
    border-radius: 8px;
}

.indicator-circle {
    list-style-type: none;

    &__flex {
        display: flex;
    }

    &__container {
        display: inline-flex;
        align-items: center;
        gap: 30px;

        margin: 15px auto;
        padding: 0;
    }

    &__clickable-field {
        position: absolute;

        width: 18px;
        height: 18px;

        &_regular {
            transform: translate(-3px, -3px);
        }

        &_small {
            transform: translate(-6px, -6px);
        }
    }

    &__circle {
        display: flex;

        margin: auto;

        cursor: pointer;

        border-radius: 50%;
        background: #36a5f2;

        &_big {
            width: 18px;
            height: 18px;
        }

        &_regular {
            width: 12px;
            height: 12px;

            opacity: 0.5;
        }

        &_small {
            width: 6px;
            height: 6px;

            opacity: 0.5;
        }
    }

    @media (max-width: 767px) {
        &__container {
            gap: 16px;
        }

        &__clickable-field {
            width: 24px;
            height: 24px;

            &_big {
                transform: translate(-7px, -7px);
            }

            &_regular {
                transform: translate(-8px, -8px);
            }

            &_small {
                transform: translate(-10px, -10px);
            }
        }

        &__circle {
            &_big {
                width: 10px;
                height: 10px;
            }

            &_regular {
                width: 7px;
                height: 7px;

                opacity: 0.5;
            }

            &_small {
                width: 4px;
                height: 4px;

                opacity: 0.5;
            }
        }
    }
}
</style>

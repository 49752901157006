import { Prop, Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import { gLocalDateS } from '../../service/LocalDateS'
import { PageCtrl } from '@/system/PageCtrl';
import { gfInitContextCtrl } from '@/system/ContextSys';
import P63Vue from '@/system/P63Vue';
import { mIsClient } from '@/common/helpers/ContextH';

@Component
export default class ModalCheckNotifications extends P63Vue {
    @Prop({ type: Number, default: 0 }) userId: number;

    aUsersNotifications = [];

      /** Контроллер */
    get ctrl(): PageCtrl {
        return gfInitContextCtrl(mIsClient() ? this.$context : this.$ssrContext, PageCtrl);
        
    }

    /** Показывать ли модалку? */
    get bShowModal(){
        return this.ctrl.headerSys.header.is_shown_modal_check_notifications;
    }

    /** Закрыть модалку */
    fCloseModal() {
        this.ctrl.headerSys.header.is_shown_modal_check_notifications = false;
        this.$emit('onCloseModal');
    }

    /** Проверка даты последнего визита*/
    fCheckDate(){
        const sDateFromStorage: string = gLocalDateS.fGetAndUpdateDateFromLocalStorage(this.userId);
        if (!sDateFromStorage){
            this.ctrl.headerSys.fGetUserActivityStatus();
        }
        else {
            const nowDate = new Date();
            const lastDate = new Date(sDateFromStorage);

            // Прибавляем 30 дней к последней дате визита (из LocalStorage)
            lastDate.setDate(lastDate.getDate() + 30);
            
            //Проверяем, не прошло ли 30 дней с последнего визита
            if (nowDate > lastDate){
                this.ctrl.headerSys.fGetUserActivityStatus();
            }
        }       
    }

    mounted() {
        this.fCheckDate();
    }
}

import { ItemOfCollectionI } from '@/ifc/core/CommonI/ItemCollectionI';

export namespace HomeN {
    /** Параметры api ответа */
    export interface CityI {
        region: number;
        counter: number;
        region_name: string;
    }

    /** Тип изображения отзыва */
    export enum ImgType {
        post = 'post',
        item = 'item',
    }

    /** Интерфейс объекта для карточки с преимуществом */
    export interface AdvantageI {
        title: string;
        text: string;
        image: string;
        link: string;
        alias: string;
    }

    /** Интерфейс объекта для карточки со счетчиком */
    export interface InfoCountsI {
        count: string;
        text: string;
        icon: string;
        link: string;
        alias: string;
    }

    /** Интерфейс новости */
    export interface NewsDataI {
        /** Ссылка на изображение новости */
        imageUrl: string;
        /** Дата создания новости в формате DD-MM-YYYY */
        date: string;
        /** Ссылка на отдельную новость */
        detailLink: string;
    }

    /** Типы баннеров по положению и размеру */
    export enum BannerTypeT {
        /** Стандартный */
        none = '',
        /** Верхний */
        top = 'top',
        /** Уменьшенный */
        mini = 'mini',
    }

    /** Номера подборок */
    export enum BrandNumberT {
        first = 0,
        second = 1,
    }

    /** Количество колонок в сетке с подборками */
    export enum BrandGridColumnsT {
        mobile = 2, // при мобильном просмотре
        tablet_or_desktop = 3, // при планшетном или десктопном просмотре
    }
}

import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { clickOutside } from '../../directives/clickOutside';
import { CategoriesSearchT, HintT, IxHintT, SearchPayloadT, СategoryHintT } from './ifc_input_search_with_hints';
import Icon from '../icon/icon';

/**
 * Компонент input_search_with_hints принимает
 * @ value - Текст отображаемый в инпуте
 * @ isHintsVisible - Показывать список подсказок
 * @ ixHint - Индексированный список подсказок для отображения
 * @ avCategoryHint - Список категорий подсказок
 * @ avCategoriesSearch - Список категорий типов поиска
 * @ sPurchasesAlias - Алиас для закупок, по умолчанию всегда 'purchases'
 * @ sDefaultType - Алиас для типа поиска по умолчанию, всегда 'items'
 * @ onInput: (valued: string) => void - Функция обработки вводимых данных пользователем
 * @ onSearch: (params: SearchPayloadT) => void - Функция поиска общая для всех категорий подсказок
 * @ onChangeOpenHints: (value: boolean) => void - Функция открытия/закрытия подсказок (изменения isHintsVisible в родителе)
 * @ onChangeCategorySearch: (category: CategoriesSearchT) => void функция выбора категории поиска
 */
@Component({
    directives: {
        'click-outside': clickOutside,
    },
    components: {
        Icon,
    }
})
export default class input_search_with_hints extends Vue {
    /** Значение введенное для поиска */
    @Prop({ type: String, default: '' }) value: string;
    /** Показывать ли список подсказок под поиском */
    @Prop({ type: Boolean, default: false }) isHintsVisible: boolean;
    /** Индексированный список подсказок */
    @Prop({ type: Object, default: () => {} }) ixHint: IxHintT;
    /** Список категорий подсказок */
    @Prop({ type: Array, default: () => [] }) avCategoryHint: СategoryHintT[];
    /** Список категорий поиска */
    @Prop({ type: Array, default: () => [] }) avCategoriesSearch: CategoriesSearchT[];
    /** Алиас для закупок */
    @Prop({ type: String, default: 'purchases' }) sPurchasesAlias: СategoryHintT['alias'];
    /** Тип поиска по умолчанию */
    @Prop({ type: String, default: 'items' }) sDefaultType: СategoryHintT['alias'];
    /** Функция обработки вводимых данных пользователем */
    @Prop({ type: Function }) onInput: (valued: string) => void;
    /** Функция поиска общая для всех категорий подсказок */
    @Prop({ type: Function }) onSearch: (params: SearchPayloadT) => void;
    /** Функция открытия/закрытия подсказок (изменения isHintsVisible в родителе) */
    @Prop({ type: Function }) onChangeOpenHints: (value: boolean) => void;
    /** Функция выбора категории поиска */
    @Prop({ type: Function }) onChangeCategorySearch: (
        category: CategoriesSearchT
    ) => void;

    /** Состояние открыто/закрыто выпадающее меню выбора категории поиска */
    isOpenCategorySearch: boolean = false;
    /** Индекс выбранной категории поиска */
    nCategorySearchIndex: number = 0;
    /** Индекс элемента, в списке выбранной категории, который находится в фокусе */
    nFocusIndex: number = -1;
    /** Элемент подсказки над которым фокус */
    vFocusHintItem: SearchPayloadT = null;

    $refs!: {
        input: HTMLInputElement; // Input поиска
        [key: `hint-${number}`]: HTMLDivElement[]; // Элементы подсказки
    };

    /** Вызов общей функции поиска */
    fSearch(params: SearchPayloadT) {
        if (this.onSearch) {
            this.onSearch(params);
        }
        if (this.onChangeOpenHints) {
            this.onChangeOpenHints(false);
        }
        this.nFocusIndex = -1;
    }

    /** Функция поиска если человек не кликает по подсказке */
    fOnSearchByEnter() {
        // Дефолтно всегда ищется в товарах
        const params = {
            value: this.value,
            type: this.sDefaultType,
            id: 0,
        };

        // Если человек ввел цифры и они равны ID закупки из подсказок, то поиск по этой закупке
        if (this.fMatchedPurchase()) {
            params.value = this.fMatchedPurchase();
            params.type = this.sPurchasesAlias;
            params.id = Number(this.value.replace(/ /g,''))
        }

        // Если у человека в фокусе есть какая-то подсказка, то поиск по типу и лейблу этой подсказки осуществляется
        else if (this.nFocusIndex >= 0 && this.isHintsVisible) {
            params.value = this.vFocusHintItem.value;
            params.type = this.vFocusHintItem.type;
            params.id = this.vFocusHintItem.id;
        }

        this.fSearch(params);
    }

    /** Поймать фокус на подсказке */
    fFocusHint(category: СategoryHintT, hint: HintT) {
        this.vFocusHintItem = {
            value: hint.label,
            type: category.alias,
            id: hint.id,
        }
    }

    /** Клик по подсказке */
    fClickOnHint(category: СategoryHintT, hint: HintT) {
        // Если у категории подсказок есть своя функция то вызывается она, если её нет вызывается общий поиск
        if (category.fClick) {
            category.fClick(hint);
        } else {
            const params = {
                value: hint.label,
                type: category.alias,
                id: hint.id,
            };
            this.fSearch(params);
        }
    }

    /** Обработчик вводимых данных в инпут */
    fInput(value: string) {
        if (this.onInput) {
            this.onInput(value);
        }
    }

    /** Получить список подсказок по категории */
    fGetElementsByAlias(alias: string) {
        return this.ixHint[alias] ?? [];
    }

    /** Подсвечивание текста */
    fHighlight(hint: HintT) {
        const sLable = hint.label.toLowerCase();
        const sSearchText = this.value.toLowerCase();

        const hl = sLable.replace(sSearchText, "<span style='font-weight: bold; color: #36a6f2;'>$&</span>");

        return hl;
    }

    /** Поставить фокус на подсказку, затем убрать его обратно на инпут (нужно для скролла) */
    fSetFocusOnHint(nIndex: number) {
        this.$refs[`hint-${nIndex}`][0].focus()
        this.$refs.input.focus()
    }

    /** Функция по клику кнопки вверх */
    fOnKeyUp() {
        if (!this.isHintsVisible || !this.avCategoryHint.length || !this.nHintsLength) {
            return;
        }
        if (this.nFocusIndex <= 0) {
            this.nFocusIndex = this.nHintsLength-1
        } else {
            this.nFocusIndex--
        }
        this.fSetFocusOnHint(this.nFocusIndex)
    }

    /** Функция по клику кнопки вниз */
    fOnKeyDown() {
        if (!this.isHintsVisible || !this.avCategoryHint.length || !this.nHintsLength) {
            return;
        }
        if (this.nFocusIndex >= this.nHintsLength-1) {
            this.nFocusIndex = 0
        } else {
            this.nFocusIndex++
        }
        this.fSetFocusOnHint(this.nFocusIndex)
    }

    /** Валидация на цифру введенной поисковой строки */
    fValidateNumberRegexp() {
        const value = this.value.trim();
        return Boolean(value) && /^\d+$/gim.test(value);
    }

    /** Проверка на соответсвие введенных цифр к ID закупки */
    fMatchedPurchase() {
        let isMatchedPurchase: string = '';
        const avPurchases = this.ixHint[this.sPurchasesAlias];
        if (avPurchases && this.fValidateNumberRegexp()) {
            const vCurrentPurchase = avPurchases.find((vPurchases) => String(vPurchases.id) === this.value.trim());
            isMatchedPurchase = vCurrentPurchase ? this.fHighlight(vCurrentPurchase) : '';
        }
        return isMatchedPurchase;
    }

    /** Выбор категории поиска */
    fClickOnCategorySearch(index: number) {
        this.nCategorySearchIndex = index;
        this.isOpenCategorySearch = false;
        if (this.onChangeCategorySearch) {
            this.onChangeCategorySearch(this.avCategoriesSearch[index]);
        }
    }

    /** Скрыть список выбора категорий поиска */
    fCloseCategorySearch() {
        this.isOpenCategorySearch = false;
    }

    /** Скрыть список подсказок для поиска */
    fCloseHints() {
        if (this.onChangeOpenHints) {
            this.onChangeOpenHints(false);
        }
    }

    /** Сколько всего всех подсказок */
    get nHintsLength() {
        let nLength = 0
        const aHints = Object.values(this.ixHint)
        for (let i = 0; i<aHints.length; i++) {
            nLength+=aHints[i].length
        }
        return nLength
    }

    /** Массив количества подсказок во всех прошлых подкатегориях */
    get aCountInAllPrevious() {
        const aCountInAllPrevious: number[] = [0]
        let summ = 0;
        for(let i = 1; i<this.avCategoryHint.length; i++) {
            const category = this.avCategoryHint[i-1]
            const hints = this.ixHint[category.alias]
            summ = summ+hints.length
            aCountInAllPrevious.push(summ)
        }
        return aCountInAllPrevious
    }
}

import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { TooltipPositionT, TooltipTypesT } from './ifc_tooltip';
import { StyleValue } from 'vue/types/jsx';

@Component
export default class tooltip extends Vue {
    /** Тип тултипа */
    @Prop({ type: String, default: '' }) sType: TooltipTypesT;
    /** Позиция показа */
    @Prop({ type: String, default: 'bottom' }) sPosition: TooltipPositionT;
    /** В центре или нет */
    @Prop({ type: Boolean, default: false }) isCentered: boolean;
    /** Принудительный показ */
    @Prop({ type: Boolean, default: false }) forced: boolean;
    /** Текст тултипа */
    @Prop({ type: String, default: '' }) sText: string;
    /** Видимость тултипа при наведении */
    @Prop({ type: Boolean, default: true }) isVisible: boolean;
    /** Стили для тултипа */
    @Prop({type: Object, default: () => {}}) vStyleTooltip: StyleValue;

    /** Класс для центрирования */
    get sCentered() {
        let sCenteredClass = ''
        if (this.isCentered) {
            sCenteredClass = 'ta-c';
        }
        return sCenteredClass
    }

    /** Показ всегда */
    get sForced() {
        return this.forced ? 'has-tooltip-force' : 'has-tooltip'
    }

}
import { Prop, Watch } from 'vue-property-decorator';
import Component from 'vue-class-component';
import { PageCtrl } from '@/system/PageCtrl';
import P63Vue from '@/system/P63Vue';
import { gfInitContextCtrl } from '@/system/ContextSys';
import { mIsClient } from '@/common/helpers/ContextH';
import dayjs from 'dayjs';
import ModalCheckNotifications from '../ModalCheckNotifications/ModalCheckNotifications';
import NotificationBonus from '../NotificationBonus/NotificationBonus'
import { BonusExpireInfoI } from '@/ifc/core/BonusActionUserE';

/** Семь минут в миллисекундах */
const SEVEN_MINUTES_MS = 420000;

interface ModalABRegisterI{
    last_show_date: string;   // Дата последнего показа модалки (YYYY/MM/DD)
    timer_start_time: number; // Время запуска таймера
}
@Component({
    components:{
        ModalCheckNotifications,
        NotificationBonus
    }
})
export default class ModalRightTopContainer extends P63Vue {
    @Prop({ type: Boolean, default: false }) bAuth: boolean; // Авторизован ли пользователь
    @Prop({ type: Number, default: 0 }) userId: number; // ID пользователя
    @Prop({ type: Boolean, default: false }) isMobile: boolean; // Мобильное ли устройство

    /** Модалки для определения очередности показа */
    prompt_notification = [
        {alias: 'bonus_expired'},        
        {alias: 'user_rating_up_1'},
        {alias: 'user_rating_up_2'},
        {alias: 'user_rating_up_3'},
        { alias: 'social-network-subscribe' },
    ];

    /** Показана ли модалка Cookies */
    bCookiesShown: boolean = true;
    /** Показ А-Б модалки "Зарегистрируйтесь" */
    bShowModalABRegister: boolean = false;
    /** Данные о модалке А-Б регистрации из хранилища */
    vModalStoreData: ModalABRegisterI = localStorage['modal_ab_register'] ? JSON.parse(localStorage['modal_ab_register']) : {};
    /** Рандомный тип А-Б модалки */
    bShowModalA: boolean = Boolean(Math.floor(Math.random() * 2));

    /** Проверка, показывается ли модалка "Cookies" */
    fCheckCookiesModal(){
        if (localStorage['bUseCookies']){
            this.bCookiesShown = false;
        }
    }

    /** Получить и записать последний показ модалки */
    fGetLastShowModalABRegister(){
        /** Если в локалсторе есть дата, сверяем её с сегодняшней */
        if (this.vModalStoreData?.last_show_date){
            /** Сегодняшняя дата */
            const sNowDate = dayjs().format('YYYY/MM/DD')
            /** Если сегодня модалку не показывали, запускаем таймер */            
            if (sNowDate !== this.vModalStoreData.last_show_date){
                this.fRunDisplayTimer();
            }
        } else {
            /** При пустом локалсторе запускаем таймер */
            this.fRunDisplayTimer();
        }
    }

    /** Запуск таймера, отсчитывающего семь минут от начала сессии пользователя */
    fRunDisplayTimer(){
        let nTimerStartTime: number;
        /** Получаем время запуска таймера из localStorage */
        if (this.vModalStoreData?.timer_start_time){
            nTimerStartTime = this.vModalStoreData.timer_start_time;
        } else {
            nTimerStartTime = new Date().getTime();
            /** Записываем данные в стор, чтобы не потерять таймер при переходах по страницам */
            const vNewStoreData: ModalABRegisterI = {
                last_show_date: this.vModalStoreData?.last_show_date ? this.vModalStoreData.last_show_date : '',
                timer_start_time: nTimerStartTime,
            }
            localStorage['modal_ab_register'] = JSON.stringify(vNewStoreData)
        }

        /** Проверка таймера каждые 5 секунд */
        this.fLocalStorageCheckTimer(nTimerStartTime);
    }

    /**
     * Таймер, сверяющий текущее время с таймером.
     * Работает семь минут, сверяясь каждые 5 секунд.
     */
    fLocalStorageCheckTimer(nTimerStartTime: number){
        /** Сверять время каждые 5 секунд до истечения семи минут */
        setTimeout(() => {
            /** Текущее время */
            const nNowTime: number = new Date().getTime();

            /** Если время не вышло, продолжаем отсчитывать */
            if (nNowTime < nTimerStartTime + SEVEN_MINUTES_MS) {
                this.fLocalStorageCheckTimer(nTimerStartTime);
            } else {
                /** Сделать новую запись в local storage */
                const vNewStoreData: ModalABRegisterI = {
                    last_show_date: dayjs().format('YYYY/MM/DD'),
                    timer_start_time: -1,
                }
                localStorage['modal_ab_register'] = JSON.stringify(vNewStoreData);

                /** Показать модалку */
                this.bShowModalABRegister = true;
            }
        }, 5000);
    }

    /** Закрытие модалки */
    fOnCloseModal(){
        this.ctrl.modalNotificationSys.modal_notification.active_modal = '';
        this.ctrl.modalNotificationSys.fRunModalQuery();
    }

    mounted(){
        this.fCheckCookiesModal();
        if (!this.bCookiesShown && Boolean(localStorage.getItem('isPushCancel')) && !this.bShowBonusNotification){
            if (this.bAuth){
                /** Запрос в систему управления модалками */
                this.ctrl.modalNotificationSys.fModalNotification(this.prompt_notification);
            } else if (this.isMobile) {
                this.fGetLastShowModalABRegister();
            }
        }
    }

    /** Контроллер */
    get ctrl(): PageCtrl {
        return gfInitContextCtrl(mIsClient() ? this.$context : this.$ssrContext, PageCtrl);
    }

    /** Алиас модалки для отображения */
    get sDisplayedModalAlias(): string {
        return this.ctrl.modalNotificationSys.modal_notification.active_modal;
    }

    /** Показывать ли модалку Подписки на соцсеть */
    get bShowModalSocialSubscribe(): boolean {
        return (
            (this.sDisplayedModalAlias === 'viber' ||
                this.sDisplayedModalAlias === 'telegram_main' ||
                this.sDisplayedModalAlias === 'pesholga' ||
                this.sDisplayedModalAlias === 'telegram_showroom' ||
                this.sDisplayedModalAlias === 'telegram_premium' ||
                this.sDisplayedModalAlias === 'vkontakte') &&
            !this.bShowBonusNotification  && 
            !this.bCookiesShown 
        );
    }

    /** Показывать ли модалку поздравления с увеличением уровня пользователя */
    get bShowModalUserRatingUp(){
        return this.sDisplayedModalAlias === 'user_rating_up_1' 
            || this.sDisplayedModalAlias === 'user_rating_up_2'
            || this.sDisplayedModalAlias === 'user_rating_up_3'
            && !this.bShowBonusNotification;
    }

    /** Показывать ли бонусное уведомление */
    get aExpireBonusNotification(): BonusExpireInfoI[] {
        return this.ctrl.headerSys.header.bonus_expired;
    }

    /** Показывать ли бонусное уведомление */
    get bShowBonusNotification(): boolean {
        return this.ctrl.headerSys.header.is_show_notification_bonus;
    }

    /** Показывать ли бонусное уведомление */
    get bAliasDisplayBonusExpired(): boolean {
        return this.sDisplayedModalAlias === 'bonus_expired';
    }

    /** Получитить данные о предстоящих списаниях бонусов */
    @Watch('bAliasDisplayBonusExpired')
    fGetDataBonusExpired(): void {
        if (this.bAliasDisplayBonusExpired === true) {
            this.ctrl.headerSys.fGetBonusExpired();
        }
    }
}

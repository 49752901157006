<template>
    <section v-if="aNews.length > 0" class="home-news">
        <h3 class="home-news__title">Новости</h3>
        <ul class="home-news__list">
            <li v-for="vNews in aNews" :key="vNews.post_id" class="home-news__item">
                <img
                    class="home-news__image"
                    :src="ixNewsData[vNews.post_id].imageUrl"
                    width="512"
                    height="218"
                    loading="lazy"
                    decoding="async"
                    alt="Обложка новости"
                />
                <div class="home-news__text-box">
                    <div class="home-news__tag-date-box">
                        <span class="home-news__tag" :title="vNews.tag">{{ vNews.tag }}</span>
                        <span>{{ ixNewsData[vNews.post_id].date }}</span>
                    </div>
                    <h4 class="home-news__item-title" :title="vNews.title">{{ vNews.title }}</h4>
                    <a
                        :href="ixNewsData[vNews.post_id].detailLink"
                        :target="$sTargetLink"
                        class="home-news__detail-link"
                        @click="fPushClickToStat(vNews)"
                        >Подробнее
                        <Icon
                            sClassName="home-news__detail-link-icon"
                            sIconName="arrow-line-right"
                            sHeight="15"
                            sWidth="15"
                    /></a>
                </div>
            </li>
        </ul>

        <a href="/news" :target="$sTargetLink" class="ds-btn home-news__other-link" @click="fSendActionOpenMoreNews"> Смотреть другие новости </a>
    </section>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import HomeNewsSource from './home-news-source';

@Component
export default class HomeNews extends HomeNewsSource {}
</script>

<style lang="scss" scoped>
.home-news {
    padding: 0 16px;

    @media screen and (min-width: 768px) {
        padding: 0;
    }

    &__title {
        margin: 0;
        padding: 0;

        font-size: 14px;
        font-weight: 700;
        line-height: 1;

        @media screen and (min-width: 768px) {
            font-size: 20px;
        }
    }

    &__list {
        display: grid;
        justify-content: center;
        column-gap: 20px;

        margin: 0;
        margin-top: 24px;
        padding: 0;

        list-style-type: none;

        @media screen and (min-width: 768px) {
            margin-top: 32px;
        }
    }

    &__item {
        display: none;

        min-width: 280px;
        max-width: 295px;
        min-height: 263px;
        max-height: 269px;

        border-radius: 6px;
        box-shadow: 0px 2px 16px 0px #6999f62e;

        &:nth-child(1) {
            display: block;
            grid-column: 1/2;
            grid-row: 1/2;
        }

        &:nth-child(2) {
            grid-column: 2/3;
            grid-row: 1/2;

            @media screen and (min-width: 613px) {
                display: block;
            }
        }

        &:nth-child(3) {
            grid-column: 3/4;
            grid-row: 1/2;

            @media screen and (min-width: 915px) {
                display: block;
            }
        }

        &:nth-child(4) {
            grid-column: 4/5;
            grid-row: 1/2;
            @media screen and (min-width: 1217px) {
                display: block;
            }
        }
    }

    &__image {
        display: block;
        width: 100%;
        height: 126px;
        border-radius: 5px;
        object-fit: cover;
    }

    &__text-box {
        position: relative;

        display: flex;
        flex-direction: column;

        padding: 16px 10px 10px;

        &::after {
            position: absolute;
            bottom: 10px;
            left: 10px;

            width: calc(100% - 20px);
            height: 2px;

            content: '';

            border-radius: 2px;
            background-color: #36a5f2;
        }
    }

    &__tag-date-box {
        display: flex;
        justify-content: space-between;
        gap: 3px;
        flex-direction: row;
        flex-wrap: nowrap;

        white-space: nowrap;

        opacity: 0.5;
        color: #1d1e1f;

        font-size: 12px;
        font-weight: 400;
        line-height: 1;
    }

    &__tag {
        overflow: hidden;
        max-width: 160px;
        text-overflow: ellipsis;
    }

    &__item-title {
        display: -webkit-box;
        overflow: hidden;

        min-height: 48px;
        margin: 0;
        margin-top: 14px;
        padding: 0;

        color: #1d1e1f;

        font-size: 16px;
        font-weight: 600;
        line-height: 1.5;

        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    &__detail-link {
        display: flex;
        align-items: center;
        align-self: flex-end;
        gap: 3px;

        box-sizing: border-box;
        margin: 12px 0;

        transition: border-bottom 0.2s ease;
        text-decoration: none;
        text-transform: uppercase;

        color: #36a5f2;
        border-bottom: 1px solid transparent;

        font-size: 14px;
        font-weight: 600;

        &:hover {
            border-bottom: 1px solid #36a5f2;
        }
    }

    &__detail-link-icon {
        fill: #36a5f2;
    }

    &__other-link {
        display: block;

        width: 100%;
        max-width: 292px;
        height: 44px;
        margin: 20px auto 0;

        text-align: center;
        text-decoration: none;

        color: rgba(#1d1e1f, 0.8);
        border: 1px solid rgba(#1d1e1f, 0.8);
        border-radius: 22px;
        background-color: #ffffff;

        font-size: 16px;
        font-weight: 700;
        line-height: 28px;

        &:hover {
            color: #ffffff;
            background-color: rgba(#1d1e1f, 0.8);
        }

        @media screen and (min-width: 768px) {
            max-width: 398px;
            height: 56px;
            margin: 30px auto 0;

            border-radius: 28px;

            font-size: 20px;
            line-height: 40px;
        }
    }
}
</style>

import { UserInfoI, UserIxI } from './EntitySQL/UserE';
import { OrgTypeT, UserGroupIDsI } from './EntitySQL/UserGroupE';
import { OrganizationUserI } from './EntitySQL/OrganizationUserE';
import { PvzI } from './PvzSQL/PvzE';
import { PvzAddressI } from './PvzSQL/PvzAddressE';

/**
 * Страница закупки функции администратора
 */
export namespace UserR {
    export const Alias = 'User';

    // =======================================================
    /** Проверить или обновить токен */
    export namespace checkToken {

        /** APIURL */
        export const route = '/user/check-token';

        /** Alias действия */
        export const action = 'check-token';

        /** Параметры api запроса */
        export interface RequestI {
        }

        /** Параметры api ответа */
        export interface ResponseI {
            token: string;
        }
    }

    // =======================================================
    /** Получить организаторов */
    export namespace listOrg {

        /** APIURL */
        export const route = '/user/list-org';

        /** Alias действия */
        export const action = 'list-org';

        /** Параметры api запроса */
        export interface RequestI {
        }

        /** Параметры api ответа */
        export interface ResponseI {
            list_org: UserGroupIDsI[];
            list_red_org_id: number[];
            list_blue_org_id: number[];
        }
    }

    /** Получить активных организаторов */
    export namespace listActiveOrg {

        /** APIURL */
        export const route = '/user/list-active-org';

        /** Параметры api запроса */
        export interface RequestI {
        }

        /** Параметры api ответа */
        export interface ResponseI {
            list_org: UserGroupIDsI[];
            list_red_org_id: number[];
            list_blue_org_id: number[];
        }
    }

    // =======================================================
    /** Получить синих организаторов */
    export namespace listBlueOrg {

        /** APIURL */
        export const route = '/user/list-blue-org';

        /** Alias действия */
        export const action = 'list-blue-org';

        /** Параметры api запроса */
        export interface RequestI {
        }

        /** Параметры api ответа */
        export interface ResponseI {
            list_org: UserIxI[];
        }
    }

    // =======================================================
    /** Получить информацию о пользователе */
    export namespace getUserInfo {

        /** APIURL */
        export const route = '/user/get-user-info';

        /** Alias действия */
        export const action = 'get-user-info';

        /** Параметры api запроса */
        export interface RequestI {
        }

        /** Параметры api ответа */
        export interface ResponseI {
            user_info: UserInfoI;
            city_name: string; // Выбранный город пользователя
            is_org: boolean; // Является ли пользователь организатором
            is_admin: boolean; // Является ли пользователь администратором
            is_moderator: boolean; // Является ли пользователь модератором
            is_buh: boolean; // Является ли пользователь бухгалтером
            is_pvz_moderator: boolean; // Является ли пользователь модератором ПВЗ
            is_pvz_user: boolean; // Является ли пользователь работником ПВЗ
            is_pvz_driver: boolean; // Является ли пользователь водителем ПВЗ
            is_hard_change_pswd: boolean; // Активированна процедура принудительной смены пароля
            ix_organization_role: { [key: number]: OrganizationUserI[] }; // key organization_id, // val role
            is_caravan_employee: boolean; // является ли пользователь 1)оператором 2)склада 3)караван
            is_sorter: boolean; // Разборщик на складе
            is_blue_org: boolean; // "синий" организатор
            is_red_org: boolean; // "красный" организатор
            is_cvz_user: boolean; // Сотрудник ЦВЗ
            is_cvz_moderator: boolean; // Модератор ЦВЗ
            is_pvz_control: boolean; // контроль ПВЗ
            is_marketing: boolean; // Маркетинг
            is_buh_pvz: boolean; // Бухгалтер ПВЗ
            is_full_block: boolean; // Полная блокировка
            is_partial_block: boolean; // Частичная блокировка
        }
    }

    // =======================================================
    // todo перенести в модуль UserOrg
    // todo поправить роутер соответсвтенно модулю и перевязать взаимодействующий функционал
    /** Получить информацию об организациях пользователя */
    export namespace getOrganization {

        /** APIURL */
        export const route = '/user/organization';

        /** Alias действия */
        export const action = 'organization';

        /** Параметры api запроса */
        export interface RequestI {
        }

        /** Параметры api ответа */
        export interface ResponseI {
            user_info: any; // todo Здесь должен быть интерфейс
        }
    }

    // =======================================================
    /** Получить информацию об уведомлениях пользователя */
    export namespace getNotifications {

        /** APIURL */
        export const route = '/user/notifications';

        /** Alias действия */
        export const action = 'notifications';

        /** Параметры api запроса */
        export interface RequestI {
        }

        /** Параметры api ответа */
        export interface ResponseI {
            countUnreadEvents: number;
            countUnreadNotifications: number;
            countUnreadPrivmsg: number;
            countUnreadSystemNotifications: number;
        }
    }

    // =======================================================
    /** Получить информацию об уведомлениях пользователя */
    export namespace getUserCondition {

        /** APIURL */
        export const route = '/user/get-user-condition';

        /** Параметры api запроса */
        export interface RequestI {
        }

        /** Параметры api ответа */
        export interface ResponseI {
            delivery_day_self_pvz: number; // Ориентировочна стоимость доставки в днях со склада до своего пункта
        }
    }

    // =======================================================
    /** Получить информацию о ПВЗ пользователя */
    export namespace getUserDefaultPvz {

        /** APIURL */
        export const route = '/user/get-user-default-pvz';

        /** Параметры api запроса */
        export interface RequestI {
        }

        /** Параметры api ответа */
        export interface ResponseI {
            pvz: PvzI; // Данные о ПВЗ
            pvz_address: PvzAddressI; // Адрес ПВЗ
        }
    }

    // =======================================================
    /** Получить информацию по флагу отсутствия пользователя */
    export namespace checkMissingFlag {

        /** APIURL */
        export const route = '/user/check-missing-flag';

        /** Параметры api запроса */
        export interface RequestI {
        }

        /** Параметры api ответа */
        export interface ResponseI {
            is_missing: boolean;
        }
    }

    /** Получить список ID пользователей по логину */
    export namespace listUser {

        /** APIURL */
        export const route = '/user/list-user';

        /** Параметры api запроса */
        export interface RequestI {
            login: string; // Логин пользователя
        }

        /** Параметры api ответа */
        export interface ResponseI {
            list_user: UserIxI[]; // Список ID пользователей по логину (20 записей)
        }
    }
}

import Component from 'vue-class-component';
import P63Vue from '@/system/P63Vue';
import { HomeCtrl, initHomeCtrl } from '../../../view/ctrl-home';
import { mIsClient } from '@/common/helpers/ContextH';
import dayjs from 'dayjs';
import { Prop } from 'vue-property-decorator';
import { ItemOfTopDayCollectionI } from '@/ifc/msg/CommonI/ItemCollectionI';
import { gImageS } from '@/common/service/ImageS';
import { mFormatPriceSeparatorNoCoins } from '@/common/helpers/PriceFormatterH';
import { mPluralize } from '@/common/helpers/TextFormatterH';
import { mFormatMillisecondsToString } from '@/common/helpers/DateTimeH';
import { mTruncateDecimal } from '@/common/helpers/NumberFormatterH';
import { gLinkS } from '@/common/service/LinkS';

@Component
export default class DayItem extends P63Vue {
    /** Товар дня */
    @Prop({ type: Object, default: () => {} }) vItem: ItemOfTopDayCollectionI;
    /** Расширение планшета */
    @Prop({ type: Boolean, default: false }) isTablet: boolean;
    /** Активация анимации перемещения слайдов в Товаре дня */
    @Prop({ type: Boolean, default: false }) isAnimationStart: boolean;
    /** Расширение мобильного устройства */
    @Prop({ type: Boolean, default: false }) isMobile: boolean;

    /** Показать ли таймер */
    isShowTimer: boolean = false;

    /** Миллисекунды таймера */
    nTimerMilliseconds: number = 0;

    /** Данные для таймера */
    vTimer = {
        h: '00',
        m: '00',
        s: '00',
    };

    /** Таймер */
    timer = null;

    /** Разница в процентах */
    fGetPercentDifferent() {
        if (this.vItem?.difference_type === 'percent') {
            return Math.round(Number(100 - (this.nPrice * 100) / this.vItem?.min_price));
        }
    }

    /** Получить ссылку на продукт/товар */
    fGetItemHref() {
        let sHref = '';
        const sName = this.vItem?.name;
        if (this.vItem.product_id) {
            sHref = gLinkS.fProductUrl(this.vItem?.product_id, sName);
        } else {
            sHref = gLinkS.fItemUrl(this.vItem?.item_code, sName);
        }
        return sHref;
    }

    /** Разница в деньгах */
    fGetMoneyDifferent() {
        if (this.vItem?.difference_type === 'money') {
            return mFormatPriceSeparatorNoCoins(Math.round(Math.abs(this.vItem?.min_price - this.nPrice)));
        }
    }

    /** Запуск таймера */
    fInitTimer() {
        this.timer = setInterval(() => {
            if (this.vTimer.s === '00' && this.vTimer.m !== '00') {
                // восполняем количестово секунд
                this.vTimer.s = '60';

                // декремент минут
                this.vTimer.m = `${Number(this.vTimer.m) - 1}`;
            }

            if (this.vTimer.m === '00' && this.vTimer.h !== '00') {
                //декремент часов
                this.vTimer.h = `${Number(this.vTimer.h) - 1}`;
            }

            // декремент секунд
            this.vTimer.s = `${Number(this.vTimer.s) - 1}`;

            // добавляем '0' при количестве меньше 10, чтобы было 09-01
            if (this.vTimer.s.length < 2) {
                this.vTimer.s = '0' + this.vTimer.s;
            }
            if (this.vTimer.m.length < 2) {
                this.vTimer.m = '0' + this.vTimer.m;
            }
            if (this.vTimer.h.length < 2) {
                this.vTimer.h = '0' + this.vTimer.h;
            }

            // конец таймера
            if (this.vTimer.h === '00' && this.vTimer.m === '00' && this.vTimer.s === '00') {
                this.isShowTimer = false;
                clearInterval(this.timer);
            }
        }, 1000);
    }

    /** Отправить данные в статистику о клике по Товару/Продукту дня */
    fSendActionOpenDayItem() {
        const idDayItem = this.vItem.product_id ? this.vItem.product_id : this.vItem.item_id;
        const sStatType = this.vItem.product_id ? 'product_new' : 'item_new';

        this.ctrl.statSys.faPushClickToStat({
            page: location.pathname,
            type: sStatType,
            alias: 'day_item_click_home_new',
            entity_id: idDayItem,
        });
    }

    mounted() {
        this.nTimerMilliseconds = this.vItem?.purchase_state_begin
            ? dayjs(this.vItem?.purchase_state_begin).diff(dayjs())
            : 0;
        this.isShowTimer = this.nTimerMilliseconds > 100;

        if (this.isShowTimer) {
            const aTimer = mFormatMillisecondsToString(this.nTimerMilliseconds).split(':');
            this.vTimer.h = aTimer[0];
            this.vTimer.m = aTimer[1];
            this.vTimer.s = aTimer[2];

            this.fInitTimer();
        }
    }

    beforeDestroy() {
        clearInterval(this.timer);
    }

    /** Количество привязанных к продукту товаров */
    get nProductItemsLength(): number {
        return this.vItem.product_id ? this.vItem.item_count : 0;
    }

    /** Ссылка на картинку */
    get sItemSrc() {
        return gImageS.fItemImageUrlx512(this.vItem?.filename);
    }

    /** Показываем ли старую цену */
    get isShowOldPrice() {
        return this.nProductItemsLength < 2 ? this.vItem?.old_price && this.vItem?.old_price > this.nPrice : false;
    }

    /** Правильная форма слова 'отзывы'  */
    get sReviewCountText() {
        return mPluralize(this.vItem?.review_count, ['отзыв', 'отзыва', 'отзывов']);
    }

    /** Получить рейтинг */
    get nItemRating() {
        return mTruncateDecimal(this.vItem?.rating, 1);
    }

    /** Цена с орг. сбором */
    get nPrice() {
        return Number(this.vItem?.price * (1 + this.vItem?.org_fee / 100));
    }

    /** Отформатированная цена(2000 => 2 000) */
    get sPrice() {
        return mFormatPriceSeparatorNoCoins(this.nPrice);
    }

    /** Отформатированная старая цена */
    get sOldPrice() {
        return mFormatPriceSeparatorNoCoins(Number(this.vItem?.old_price * (1 + this.vItem?.org_fee / 100)));
    }

    /** Отформатированная цена в других магазинах */
    get sOtherPrice() {
        return mFormatPriceSeparatorNoCoins(this.vItem?.min_price);
    }

    /** Хранилище */
    get ctrl(): HomeCtrl {
        return initHomeCtrl(mIsClient() ? this.$context : this.$ssrContext);
    }
}

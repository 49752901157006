import Component from 'vue-class-component';
import { HomeCtrl, initHomeCtrl } from '@/pages/home/view/ctrl-home';

import P63Vue from '@/system/P63Vue';
import { mIsClient } from '@/common/helpers/ContextH';

@Component({
  components: {},
})
export default class HomeCitiesSource extends P63Vue {
/** Отправить данные в статистику о клике по карте городов*/
fSendActionOpenGeo() {
  this.ctrl.statSys.faPushClickToStat({
      page: location.pathname,
      type: 'geo',
      alias: 'geo_click',
      entity_id: 0,
    });
}

/** Отправить данные в статистику о клике по кнопке выбора адреса ПВЗ*/
fSendActionOpenChoicePvzAddress() {
    this.ctrl.statSys.faPushClickToStat({
        page: location.pathname,
        type: 'geo',
        alias: 'button_open_choice_pvz_address',
        entity_id: 0,
      });
}

  /** Хранилище */
  get ctrl(): HomeCtrl {
    return initHomeCtrl(mIsClient() ? this.$context : this.$ssrContext);
  }

  /** Массив городов */
  get aCityList() {
    return this.ctrl.list.city;
  }

  /** Отсортированные города */
  get aSortCities() {
    const firstItem = [this.aCityList.find(({ region }) => region === 63)];
    const sort = this.aCityList.filter(({ region }) => region !== 63).sort();
    return [...firstItem, ...sort];
  }
}

<script lang="ts">
import Component from 'vue-class-component';
import AboutUsSource from '../about-us/about-us-source';

@Component
export default class AboutUs extends AboutUsSource {}
</script>

<template>
    <section class="about-us">
        <ul class="about-us__list">
            <li v-for="vAdvantageType in avAdvantageTypes" :key="vAdvantageType.title" class="about-us__item">
                <img
                    :src="vAdvantageType.image"
                    class="about-us__image"
                    width="100"
                    height="100"
                    loading="lazy"
                    decoding="async"
                    alt="Иконка преимуществ"
                />
                <div class="about-us__text">
                    <h4 class="about-us__title">{{ vAdvantageType.title }}</h4>
                    <p class="about-us__description">
                        {{ vAdvantageType.description }}
                    </p>
                </div>
            </li>
            <li>
                <a class="about-us__button" href="/about-us" :target="$sTargetLink">
                    <HomeBtn sStatAlias="about_us">Подробнее о совместных покупках</HomeBtn>
                </a>
            </li>
        </ul>
        <img
            src="../../../../assets/images/home/icon-about-us-logo.png"
            class="about-us__logo"
            width="597"
            height="485"
            loading="lazy"
            decoding="async"
            alt="63pokupki.ru"
        />
    </section>
</template>

<style lang="scss" scoped>
@import '../../view/mixins.scss';

.about-us {
    background-color: #f5f6fb;
    min-height: 485px;
    border-radius: 16px;
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include md {
        justify-content: center;
    }

    @include lg {
        margin: 0;
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: 16px;
        list-style: none;
        max-width: 650px;
        min-width: 570px;
        padding: 48px 32px 24px;
        margin: 0;

        @include md {
            min-width: 336px;
            padding: 40px 16px 24px;
        }
    }

    &__item {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        @include md {
            align-items: center;
            flex-direction: column;
        }
    }

    &__image {
        display: block;
        object-fit: cover;
    }

    &__text {
        padding-left: 24px;
        max-width: 446px;

        @media (max-width: 768px) {
            padding-left: 0;
        }
    }

    &__title {
        color: #2b2a29;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.5;
        margin: 0;

        @include md {
            text-align: center;
        }
    }

    &__description {
        font-weight: 400;
        font-size: 14px;
        line-height: 1.5;
        margin: 0;
        color: #2b2a29;

        @include md {
            text-align: center;
        }
    }

    &__detail {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__logo {
        position: relative;
        display: block;
        border-radius: 16px;
        object-fit: cover;

        @include md {
            display: none;
        }
    }

    &__button {
        display: flex;
        width: 100%;
        padding-left: 124px;
        text-decoration: none;

        button {
            padding: 10px 48.5px;
        }

        @include md {
            padding-left: 0;

            button {
                padding: 10px 24px;
                width: 100%;
            }
        }
    }
}
</style>

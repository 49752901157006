<script lang="ts">
import Component from 'vue-class-component';
import SliderControlSource from './slider-control-source';

@Component
export default class SliderControl extends SliderControlSource {}
</script>

<template>
    <div class="slider-control" @click.stop>
        <button
            v-for="(_, idx) in nCountElement"
            :key="idx"
            class="slider-control__button"
            type="button"
            :aria-label="`Перейти на слайд ${idx}`"
            :class="{ 'slider-control__button_active': idElement === idx }"
            @click="() => fSelectElement(idx)"
        ></button>
    </div>
</template>

<style lang="scss" scoped>
.slider-control {
    height: 16px;
    border-radius: 8px;
    background-color: #0008;
    display: flex;
    align-items: center;
    padding: 0 8px;
    &__button {
        border: none;
        padding: 0;
        height: 6px;
        width: 6px;
        border-radius: 50%;
        background-color: #a2a3a5;
        margin-right: 4px;
        transform: scale(1);
        transition: transform 0.3s ease, background-color 0.3s ease;
        &:last-of-type {
            margin-right: 0;
        }
        &_active {
            background-color: #fff;
            transform: scale(1.3);
            transition: all 0.3s ease;
        }

        &:hover {
            background-color: #fff;
        }
    }
}
</style>

import StackTrace from 'stacktrace-js';
import configPublic from '@/configs/config.public';
import ErrorApi from '@/api/error/error.api';
import Axios, { AxiosError } from 'axios';
import type { ErrorResponceI } from '@/api/base.api';

const axios = Axios.create({ withCredentials: true });

export const errorApi = new ErrorApi({
    base: configPublic.api.apiCoreUrl,
    axios,
});

/** Интерфейс отправляемой в мониторинг ошибки */
export interface IClientErrorRequest {
    errors: Array<{
        title: string;
        value: string;
    }>;
}

/** Интерфейс стандартизированной ошибки */
export interface IClientError {
    message: string;
    stack: string;
    metainfo: string;
}

/** Отправить ошибку в маттермост из кода vue или ts */
export const catchError = (data: { error: Error; meta: string }) => {
    try {
        console.error(data.error, data.meta);

        if (!configPublic.errors.isSendErrorToMattermost) {
            return;
        }

        const metainfo = `Type: ssr/ts;\nMeta: ${data.meta};`;

        getStandartError(data.error, metainfo).then((standartErr) => {
            const req = createError(standartErr);
            errorApi.sendErrorToMattermost(req);
        });
    } catch (e) {
        console.error(e);
    }
};
/** Отправить ошибку ответа апи в маттермост */
export const catchErrorApiResponse = (data: { error: Error; response: AxiosError<ErrorResponceI>; meta: string }) => {
    try {
        console.error('ERROR:', '\n', data.meta, '\n', JSON.stringify(data.response));

        if (!configPublic.errors.isSendErrorToMattermost) {
            return;
        }

        const metainfo = `Type: ssr/ts/api;\nMeta: ${data.meta};\nApi: ${JSON.stringify(data.response)};`;

        getStandartError(data.error, metainfo).then((standartErr) => {
            const req = createError(standartErr);
            errorApi.sendErrorToMattermost(req);
        });
    } catch (e) {
        console.error(e);
    }
};

/** Установить проперти в ошибке */
const prop = (title: string, value: string) => ({ title, value });

/** Получить стандартизированный объект ошибки */
export const getStandartError = async (error: Error, metainfo: string): Promise<IClientError> => {
    try {
        const stackframes = await StackTrace.fromError(error, { offline: true });
        const stacktrace = stackframes.toString();

        return {
            message: error.message,
            stack: stacktrace,
            metainfo,
        };
    } catch (e) {
        console.error(e);

        return {
            message: 'Ошибка при получении стандартизированной ошибки',
            stack: '',
            metainfo: '',
        };
    }
};

/** Получить подготовленный объект ошибки для отправки - обогащает данные */
export const createError = (error: IClientError): IClientErrorRequest => {
    try {
        const info = {
            errors: [
                prop('Hash', getHashCodeFromStack(error.stack)),
                prop('Message', error.message),
                prop('Stack', error.stack),
                prop('Metainfo', error.metainfo),
            ],
        };

        if (window) {
            info.errors = [
                ...info.errors,
                prop('Url', window.location.href),
                prop('User Agent', window.navigator.userAgent),
                prop('Platform', window.navigator.platform),
                prop('Screen Resolution', window.outerWidth + 'x' + window.outerHeight),
            ];
        }

        return info;
    } catch (e) {
        console.error(e);
        return {
            errors: [],
        };
    }
};

/** Хеш функция для ошибок, чтобы отсекать одинаковые ошибки */
const getHashCodeFromStack = (stack: string) => {
    let hash = 0;
    try {
        if (stack.length === 0) {
            return `h${hash}`;
        }
        for (let i = 0; i < stack.length; i++) {
            const char = stack.charCodeAt(i);
            hash = (hash << 5) - hash + char;
            hash = hash & hash;
        }
        return `h${hash}`;
    } catch (e) {
        console.error(e);
        return `h${hash}`;
    }
};

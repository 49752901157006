import Vue from 'vue';
import ModalPreloader from '@/common/el/ModalPreloader/ModalPreloader.vue';
import ModalError from '@/common/el/ModalError/ModalError';

class AsyncComponentSys {
    /**
     * @param sName // Имя основного компонента
     * @param cbGetComponent // Колбэк с динамическим импортом основного компонента
     */

    // Получение асинхронного компонента
    private fGetAsyncComponent(cbResolve: Function, cbGetMainComponent: Function) {
        return {
            component: cbGetMainComponent(cbResolve), // Основной компонент, который загружается асинхронно
            loading: ModalPreloader, // Компонент loading, который появляется во время загрузки основного компонента
            error: ModalError, // Компонент error, который появляется, если основной компонент не загрузился
            delay: 500, // Задержка перед отображением компонента loading
            timeout: 20000, // Если основной компонент загружается дольше этого времени, то отобразится компонент error
        };
    }

    /** Регистрация асинхронного компонента */
    public fRegisterAsyncComponent = (sName: string, cbGetComponent: Function) =>
        Vue.component(sName, (resolve: () => void) => {
            return this.fGetAsyncComponent(resolve, cbGetComponent);
        });
}

export const gAsyncComponentSys = new AsyncComponentSys();

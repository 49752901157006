import { mIsClient } from '@/common/helpers/ContextH';
import P63Vue from '@/system/P63Vue';
import Component from 'vue-class-component';
import { HomeCtrl, initHomeCtrl } from '../../view/ctrl-home';
import BannerNew from '../banner-new/banner-new.vue';
import DaySales from '../day-sales/day-sales.vue';
import Infoblock from '../infoblock/infoblock.vue';
import ListCounts from '../list-counts/list-counts.vue';
import ClientRec from '../client-rec/client-rec.vue';
import HomeNewsNew from '../home-news-new/home-news-new.vue';
import AboutUs from '../about-us/about-us.vue';
import SalesHits from '../sales-hits/sales-hits.vue';
import RegBlockM from '../reg-block/reg-block-m.vue';
import RegBlockD from '../reg-block/reg-block-d.vue';
import Benefits from '../benefits/benefits.vue';

@Component({
    components: {
        BannerNew,
        DaySales,
        Infoblock,
        ListCounts,
        ClientRec,
        HomeNewsNew,
        AboutUs,
        SalesHits,
        RegBlockM,
        RegBlockD,
        Benefits,
    },
})
export default class PageNewSource extends P63Vue {
    async mounted() {
        // await this.ctrl.faGetRecommendationList({ cache: this.status.cache_recommendations });
    }

    /** Мобильное ли устройство */
    get isMobile(): boolean {
        return this.status.is_mobile;
    }

    /** Неавторизованный пользователь */
    get isUnauth(): boolean {
        return !this.ctrl.userSys.user?.isAuth;
    }

    /** Пользователь с нулевым уровнем рейтинга */
    get isNewUser(): boolean {
        return this.ctrl.userSys.user?.info?.consumer_rating === 0;
    }

    /** Колллекция товаров дня */
    get ItemOfDayCollection() {
        return this.ctrl.list.item_of_day_collection;
    }

    /** Информация о пользователе */
    get vUserInfo() {
        return this.ctrl.userSys.user;
    }

    /** Показано ли административное меню на мобилке*/
    get isShowManagerMenu() {
        return (
            this.vUserInfo.isAuth && this.isMobile &&
            (this.vUserInfo.isAdmin ||
            this.vUserInfo.isModerator ||
            this.vUserInfo.isBuh ||
            this.vUserInfo.isOrg ||
            this.vUserInfo.isMarketing)
        );
    }

    get status() {
        return this.ctrl.status;
    }

    /** Хранилище */
    get ctrl(): HomeCtrl {
        return initHomeCtrl(mIsClient() ? this.$context : this.$ssrContext);
    }
}

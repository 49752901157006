export type ObjKey = number | string;

type ObjKeyProps<T> = {
    [K in keyof T]: T[K] extends ObjKey ? K : never;
}[keyof T];

/** Групировка один к одному */
export function mKeyBy<T, K extends ObjKeyProps<T>>(aInitial: T[], sKeyName: K): Record<ObjKey, T> {
    const ixResult: Record<ObjKey, T> = {};

    for (let i = 0; i < aInitial.length; i++) {
        const nKey = aInitial[i][sKeyName];
        if (typeof nKey === 'number' || typeof nKey === 'string') {
            ixResult[nKey] = aInitial[i];
        }
    }

    return ixResult;
}

/** Групировка один к одному с кэллбэком */
export function mKeyByWithCallback<T>(aInitial: T[], fGetField: (el: T) => ObjKey): Record<ObjKey, T> {
    const ixResult: Record<ObjKey, T> = {};

    for (let i = 0; i < aInitial.length; i++) {
        const nKey = fGetField(aInitial[i]);
        if (typeof nKey === 'number' || typeof nKey === 'string') {
            ixResult[nKey] = aInitial[i];
        }
    }

    return ixResult;
}

/** Групировка один ко многим */
export function mGroupBy<T, K extends ObjKeyProps<T>>(aInitial: T[], sKeyName: K): Record<ObjKey, T[]> {
    const ixResult: Record<ObjKey, T[]> = {};

    for (let i = 0; i < aInitial.length; i++) {
        const nKey = aInitial[i][sKeyName];
        if (typeof nKey === 'number' || typeof nKey === 'string') {
            if (!ixResult[nKey]) {
                ixResult[nKey] = [];
            }
            ixResult[nKey].push(aInitial[i]);
        }
    }

    return ixResult;
}

/** Групировка один ко многим с кэллбэком */
export function mGroupByWithCallback<T>(aInitial: T[], fGetField: (el: T) => ObjKey): Record<ObjKey, T[]> {
    const ixResult: Record<ObjKey, T[]> = {};

    for (let i = 0; i < aInitial.length; i++) {
        const nKey = fGetField(aInitial[i]);
        if (typeof nKey === 'number' || typeof nKey === 'string') {
            if (!ixResult[nKey]) {
                ixResult[nKey] = [];
            }
            ixResult[nKey].push(aInitial[i]);
        }
    }

    return ixResult;
}

/** Двойная групировка: один к одному, один ко многим */
export function mDoubleGroupBy<T, K extends ObjKeyProps<T>>(
    aInitial: T[],
    sFirstKeyName: K,
    sSecondKeyName: K,
    fCheckCallback?: (aExistingList: T[], oElem: T) => boolean
): Record<ObjKey, Record<ObjKey, T[]>> {
    const ixTreeResult: Record<ObjKey, Record<ObjKey, T[]>> = {};
    for (let i = 0; i < aInitial.length; i++) {
        const nFirstKey = aInitial[i][sFirstKeyName];
        const nSecondKey = aInitial[i][sSecondKeyName];
        if (
            (typeof nFirstKey === 'number' || typeof nFirstKey === 'string') &&
            (typeof nSecondKey === 'number' || typeof nSecondKey === 'string')
        ) {
            if (!ixTreeResult[nFirstKey]) {
                ixTreeResult[nFirstKey] = {};
            }
            if (!ixTreeResult[nFirstKey][nSecondKey]) {
                (ixTreeResult[nFirstKey] as Record<ObjKey, T[]>)[nSecondKey] = [];
            }

            if (!fCheckCallback || fCheckCallback(ixTreeResult[nFirstKey][nSecondKey], aInitial[i])) {
                ixTreeResult[nFirstKey][nSecondKey].push(aInitial[i]);
            }
        }
    }

    return ixTreeResult;
}

/** Убрать дупликаты из массива и вытащить их в отдельный индексированный список */
export function mFindDuplicatesByCallback<T, K extends ObjKeyProps<T>>(
    aInitial: T[],
    sKeyName: K,
    fCheckCallback: (a: T, b: T) => boolean
) {
    const ixDuplicates: Record<ObjKey, T[K][]> = {};
    const aUniq: T[] = [];

    for (let i = 0; i < aInitial.length; i++) {
        let vDuplicate: T | null = null;
        for (let j = 0; j < aUniq.length; j++) {
            if (fCheckCallback(aInitial[i], aUniq[j])) {
                vDuplicate = aUniq[j];
                break;
            }
        }

        if (vDuplicate) {
            const nDuplKey = vDuplicate[sKeyName];
            if (typeof nDuplKey === 'number' || typeof nDuplKey === 'string') {
                if (!ixDuplicates[nDuplKey]) {
                    ixDuplicates[nDuplKey] = [];
                    ixDuplicates[nDuplKey].push(nDuplKey);
                }
                const nKey = aInitial[i][sKeyName];
                if (typeof nKey === 'number' || typeof nKey === 'string') {
                    ixDuplicates[nDuplKey].push(nKey);
                }
            }
        } else {
            const nKey = aInitial[i][sKeyName];
            if (typeof nKey === 'number' || typeof nKey === 'string') {
                if (!ixDuplicates[nKey]) {
                    ixDuplicates[nKey] = [];
                    ixDuplicates[nKey].push(nKey);
                }
            }
            aUniq.push(aInitial[i]);
        }
    }

    return ixDuplicates;
}

/** Сравнить два массива */
export function mIsEqualArraysByCallback<T, K>(
    aFirstInitial: T[],
    aSecondInitial: K[],
    fCheckCallback: (a: T, b: K) => boolean
): boolean {
    if (aFirstInitial.length !== aSecondInitial.length) {
        return false;
    }
    const idx: Record<number, boolean> = {};
    for (let i = 0; i < aFirstInitial.length; i++) {
        let isEqual = false;
        for (let j = 0; j < aSecondInitial.length; j++) {
            if (idx[j]) {
                continue;
            }
            if (fCheckCallback(aFirstInitial[i], aSecondInitial[j])) {
                idx[j] = true;
                isEqual = true;
                break;
            }
        }
        if (!isEqual) {
            return false;
        }
    }
    return true;
}

/** Перемешать элементы массива случайным образом */
export function mShuffleArray<T>(aItems: T[]): T[] {
    if (aItems && aItems.length !== 0) {
        for (let i = aItems.length - 1; i > 0; i--) {
            let j = Math.floor(Math.random() * (i + 1));

            [aItems[i], aItems[j]] = [aItems[j], aItems[i]];
        }
    }

    return aItems;
}

/** Найти минимальное значение массива */
export function mArrayMin(arr: number[]): number {
    let len = arr.length, min = Infinity;
    while (len--) {
        if (arr[len] < min) {
            min = arr[len];
        }
    }
    return min;
};

/** Найти максимальное значение массива */
export function mArrayMax(arr: number[]): number {
    let len = arr.length, max = -Infinity;
    while (len--) {
        if (arr[len] > max) {
            max = arr[len];
        }
    }
    return max;
};

/** Сложить два массива по уникальному ключу */
export function mMergeArraysByUniqKey<T extends object, K extends ObjKeyProps<T>>(aFirst: T[], aSecond: T[], key: K) {
    const idxUniq: Record<ObjKey, boolean> = {}
    const aResult: T[] = []
    for (let i = 0; i<aFirst.length; i++) {
        const el = aFirst[i]
        aResult.push(el)
        idxUniq[el[key] as unknown as ObjKey] = true
    }
    for (let i = 0; i<aSecond.length; i++) {
        const vItemChar = aSecond[i]
        if (!idxUniq[vItemChar[key] as unknown as ObjKey]) {
            aResult.push(vItemChar)
        }
    }

    return aResult
}

export default {
    mKeyBy,
    mKeyByWithCallback,
    mGroupBy,
    mGroupByWithCallback,
    mDoubleGroupBy,
    mFindDuplicatesByCallback,
    mIsEqualArraysByCallback,
    mShuffleArray,
    mArrayMin,
    mArrayMax,
    mMergeArraysByUniqKey
};

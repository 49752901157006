import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import ItemCard from './ItemCard';
import { mThrottled } from '@/common/helpers/ThrottledH';
import { mIsElementVisible } from '@/common/helpers/ElementVisibleH';
import P63Vue from '@/system/P63Vue';
import { ItemOfCollectionI, ProductOfCollectionI } from '@/ifc/core/CommonI/ItemCollectionI';
import { PageCtrl } from '@/system/PageCtrl';
import { gfInitContextCtrl } from '@/system/ContextSys';
import { mIsClient } from '@/common/helpers/ContextH';
import ProductCard from './ProductCard';


@Component({
  components: {
    'item-card': ItemCard,
    'product-card': ProductCard
  },
})
export default class SliderSales extends P63Vue {
  /** Количество карточек */
  @Prop({ type: Number, default: 6, required: false }) itemCount: number;
  /** Количество  отображаемых карточек */
  @Prop({ type: Number, default: 6, required: false }) displayedItemCount: number;
  /** Товары */
  @Prop({ type: Array, default: () => [] }) items: (ItemOfCollectionI | ProductOfCollectionI)[];
  /** ID слайдера */
  @Prop({ type: String, default: '', required: false }) sliderId: string;
  /** Имя списка */
  @Prop({ type: String }) gtmListName: string;
  /** Флаг для слайдера в модальном окне "Товар добавлен в корзину" */
  @Prop({ type: Boolean, default: false }) bToCart: boolean;
  /** Ширина карточки товара */
  @Prop({ type: Number, default: 156, required: false }) itemCardWidth: number;
  /** Margin между карточками товаров */
  @Prop({ type: Number, default: 30, required: false }) margin: number;
  /** Количество товаров в подборке, не обязательный параметр, необходим для динамической загрузки товаров с сервера, если он есть, а товаров нет загруженнных, будут скелетоны показываться */
  @Prop({ type: Number, default: 0 }) cntItems: number;
  /** Загружаются ли данные с сервера */
  @Prop({ type: Boolean, default: false }) isLoading: boolean;
  /** Алиас слайдера для отправки в статистику клика по товару из него */
  @Prop({ type: String, default: '' }) sStatAlias: string;
  /** ID товаров из пристроя */
  @Prop({ type: Object, required: false }) ixItemFromPristroyUz: Record<number,number>;

  /** Изменение при прокрутке слайдера */
  iTransform: number = 0;
  /** Левая кнопка слайдера */
  sliderLeftButton: boolean = true;
  /** Правая кнопка слайдера */
  sliderRightButton: boolean = true;
  /** Был ли виден элемент */
  itWasVisible: boolean = false;
  /** Обработчик видимости слайдера */
  throttleVisibleHandlerFn = null;
  /** Показывать ли склетоны слева */
  isVisibleLeftSkeletons = false
  /** Показывать ли скелетоны справа */
  isVisibleRightSkeletons = false
  /** Позиция крайнего товара в подборке */
  xPosition: number = 0;

  /** Проверка позиции элемента */
  fCheckPosi() {
    const vLastElem = document.getElementById(`item-${this.sliderId}-idx-${this.items.length-1}`) as HTMLInputElement;
    if (vLastElem && this.xPosition === vLastElem.getBoundingClientRect().x && this.items.length < this.cntItems && (vLastElem.getBoundingClientRect().x <= (2 *this.objSliderSize.swipeWidth)) && !this.isLoading) {
      this.isVisibleRightSkeletons = true;
      this.$emit('getItems', 'right');
    } else if (vLastElem && this.xPosition !== vLastElem.getBoundingClientRect().x) {
      this.xPosition = vLastElem.getBoundingClientRect().x
      setTimeout(() => {
        this.fCheckPosi()
      }, 200);
    }
  }

  /** Если у нас динамическая подгрузка карточек, то мы проверяем необходимость догрузки */
  @Watch('items')
  fUpdateData() {
    this.isVisibleRightSkeletons = false;
    this.isVisibleLeftSkeletons = false;

    if (this.items.length && this.items.length < this.cntItems && !this.isLoading) {
      this.isVisibleRightSkeletons = true;
      this.$nextTick(() => {
          const vLastElem = document.getElementById(`item-${this.sliderId}-idx-${this.items.length-1}`) as HTMLInputElement;
          if (this.items.length < this.cntItems && (vLastElem && vLastElem.getBoundingClientRect().x <= (2 *this.objSliderSize.swipeWidth))) {
            this.$emit('getItems', 'right');
          }
      });
    } else if (this.items.length === this.cntItems) {
      /** Если были загружены все товары из подборки, то снимается слушатель */
      const vSliderSales = document.getElementById('SliderSales') as HTMLInputElement;
      if (vSliderSales) {
        vSliderSales.removeEventListener('touchmove', this.fSwipe);
      }
    }
  }

  /** Функция отслеживания свайпа */
  fSwipe() {
    this.fCheckPosi()
  }

  /** Авторизация */
  get bIsAuth(){
    return this.ctrl.userSys.user.isAuth;
  }

  /** Получить размер слайдера */
  get objSliderSize() {
    const sliderSize = {
      swipeWidth: this.itemCardWidth * this.itemCount + this.margin * this.itemCount, //ширина контейнера (прокрутки)
      viewWidth: this.itemCardWidth * this.displayedItemCount + this.margin * this.displayedItemCount, // ширина отображаемой части
      width: (this.itemCardWidth + this.margin) * (this.cntItems ? this.cntItems : this.items.length), //общая ширина элементов слайдера
    };
    return sliderSize;
  }

  get ctrl(): PageCtrl {
    return gfInitContextCtrl(mIsClient() ? this.$context : this.$ssrContext, PageCtrl);
  }

  /** Отправить аналитику при нажатии на товар и перейти на страницу товара */
  fOnItemClick({ item, i, callbackUrl }) {
    const idItem = 'product_id' in item ? item.product_id : (item as ItemOfCollectionI).item_id;
    const nPrice = 'product_id' in item ? item.min_price : (item as ItemOfCollectionI).price;
    this.ctrl.statSys.fSendEcommerceAction({
      action: 'Product Click',
      bNonInteraction: false,
      data: {
        ecommerce: {
          click: {
            actionField: { list: `${this.gtmListName}-${this.sliderId}` },
            products: [
              {
                id: String(idItem),
                name: item.name,
                price: String(nPrice),
                position: i,
              },
            ],
          },
        },
      }
    })

    window.open(callbackUrl, this.$sTargetLink);
  }

  /** Прокрутка слайдера */
  fOnSliderSwap(direction: 'left' | 'right') {
    //крутим слайдер
    // кнопка влево
    if (direction === 'left') {

      /** Если товары грузятся динамически и они не все загружены на фронт, и мы сдвигаемся левее первого товара,
       * то выполняется условие в следующем if
       */
      if (this.items.length < this.cntItems && this.iTransform === 0 && !this.isLoading) {
        this.isVisibleLeftSkeletons = true;
        this.$emit('getItems', direction);
        return
      }

      if (this.objSliderSize.width < this.objSliderSize.swipeWidth) return;
      if (this.iTransform < 0) {
        if (this.iTransform + this.objSliderSize.swipeWidth < 0) {
          this.iTransform += this.objSliderSize.swipeWidth;
        } else {
          this.iTransform = 0;
        }
      } else {
        this.iTransform = -this.objSliderSize.width + this.objSliderSize.viewWidth;
      }
    }
    // кнопка вправо
    if (direction === 'right') {
      if (this.iTransform > -this.objSliderSize.width + this.objSliderSize.viewWidth) {
        if (this.iTransform - this.objSliderSize.swipeWidth > -this.objSliderSize.width + this.objSliderSize.viewWidth) {
          this.iTransform -= this.objSliderSize.swipeWidth
        } else {
          this.iTransform = -this.objSliderSize.width + this.objSliderSize.viewWidth;
        }
      } else {
        this.iTransform = 0;
      }

      /** После сдвигания слайдера происходит проверка о необходимости загрузить дополнительные элементы */
      if (this.items.length < this.cntItems && !this.isLoading) {
        this.$emit('getItems', direction);
      }
    }
  }

  /** Генерирует событие resize для принудительного срабатывания v-lazy директивы изнутри компонента */
  dispatchResizeEventFromVueComponent(context) {
    context.$nextTick(() => {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 500);
    });
  }

  /** Проверка, находятся ли товары в вишлисте */
  fCheckWishList(){
    /** Массив ID товаров из данной подборки */
    const aidItem: number[] = [];
    const aidProduct: number[] = [];
    if (this.items?.length) {
      for (let i = 0; i < this.items.length; i++) {
          const vItem = this.items[i];
          if ('item_id' in vItem) {
              aidItem.push(vItem.item_id)
          } else if ('product_id' in vItem) {
              aidProduct.push(vItem.product_id)
          }
      }
  }
  this.ctrl.wishSys.fGetItemsInWishList({
    list_item_id: aidItem,
    list_product_id: aidProduct
  });
  }

  /** Проверка, что товар из пристроя УЗ */
  fCheckItemFromPristroyUz(idItem: number) {
    return Boolean(this.ixItemFromPristroyUz?.[idItem]);
  }

  async mounted() {
    if (this.bIsAuth){
      this.fCheckWishList();
    }

    const section = this.$refs.section;
    const throttledFn = mThrottled(() => {
      const isVisible = mIsElementVisible(section);
      if (isVisible && !this.itWasVisible) {
        const aItems = []
        for (let i = 0; i < this.items.length; i++) {
            const vItemCollection = this.items[i];
            const idItem = 'item_id' in vItemCollection ? vItemCollection.item_id : (vItemCollection as ProductOfCollectionI).product_id;
            const nPrice = 'item_id' in vItemCollection ? vItemCollection.price : (vItemCollection as ProductOfCollectionI).min_price;
            const vItem = {
                id: String(idItem),
                name: vItemCollection.name,
                price: String(nPrice),
                position: i,
                list: `${this.gtmListName}-${this.sliderId}`,
            }
            aItems.push(vItem)
        }

        this.ctrl.statSys.fSendEcommerceAction({
          action: 'Product Impressions',
          bNonInteraction: true,
          data: {
            ecommerce: {
              impressions: aItems,
            },
          }
        })

        this.itWasVisible = true;
      }
    }, 500);
    this.throttleVisibleHandlerFn = throttledFn;
  }

  beforeDestroy() {
    document.removeEventListener('scroll', this.throttleVisibleHandlerFn);
  }
}

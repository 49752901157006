import Component from 'vue-class-component';
import P63Vue from '@/system/P63Vue';
import { PageCtrl } from '@/system/PageCtrl';
import { gfInitContextCtrl } from '@/system/ContextSys';
import { mIsClient } from '@/common/helpers/ContextH';


@Component
export default class ModalQrUser extends P63Vue {
    /** Ссылка на Qr пользователя */
    sQrImg: string = '';

    /** Закрыть модальное окно */
    fCloseModal() {
        this.ctrl.headerSys.header.is_open_user_qr = false;
    }

    async mounted() {
        this.sQrImg = this.ctrl.headerSys.fGetQrImage();
    }

    /** Хранилище */
    get ctrl(): PageCtrl {
        return gfInitContextCtrl(mIsClient() ? this.$context : this.$ssrContext, PageCtrl);
    }
}

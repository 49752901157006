
/**
 * Модуль для страницы доставки
 */
export namespace DeliveryPageR {
    export const Alias = 'DeliveryPage';


    // =======================================================
    /** Список количества пунктов доставки по областям */
    export namespace getDeliveryList {

        /** APIURL */
        export const route = '/delivery-page/get-delivery-list';

        /** Alias действия */
        export const action = 'get-delivery-list';

        /** Параметры api запроса */
        export interface RequestI {
            // ВХОДНЫХ ДАННЫХ НЕТ
        }

        /** Параметры api ответа */
        export interface ResponseI {
            region: number;
            counter: number;
            region_name: string;
        }
    }

}

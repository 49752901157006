import { Component } from 'vue-property-decorator';
import P63Vue from '@/system/P63Vue';
import { HomeCtrl, initHomeCtrl } from '../../view/ctrl-home';
import { mIsClient } from '@/common/helpers/ContextH';
import { gImageS } from '@/common/service/ImageS';
import { S3ItemImgIDsI } from '@/ifc/core/EntitySQL/S3ItemImgE';
import image_switch from '@/ds/el/image_switch/image_switch';
import image_switch_pagination from '@/ds/el/image_switch_pagination/image_switch_pagination';

@Component({
    components: {
        ImageSwitch: image_switch,
        ImageSwitchPagination: image_switch_pagination,
    },
})
export default class ModalReviewImageSource extends P63Vue {
    /** Индекс текущего изображения */
    idCurrentImg: number = 0;

    /** Закрытие модального окна */
    fCloseModal(): void {
        this.status.is_open_modal_review_image = false;
        this.status.id_current_post = 0;
        this.ctrl.status.id_current_item = 0;
        this.ctrl.status.index_img = 0;
    }

    /** Получение изображения поста в зависимости от типа */
    fGetImageUrlByFileName(vFile: S3ItemImgIDsI): string {
        let sImgUrl: string = '';
        sImgUrl = vFile.is_big
            ? gImageS.fPostImageUrlx1024(vFile.filename)
            : gImageS.fPostImageUrlx512(vFile.filename);
        return sImgUrl;
    }

    mounted() {
        this.idCurrentImg = this.status.index_img;
    }

    /** Получить картинки из отзыва */
    get aImages() {
        return this.ix.post_image[this.status.id_current_post] ?? [];
    }

    get status() {
        return this.ctrl.status;
    }

    get ix() {
        return this.ctrl.ix;
    }

    /** Хранилище */
    get ctrl(): HomeCtrl {
        return initHomeCtrl(mIsClient() ? this.$context : this.$ssrContext);
    }
}

import { BrandInfoI } from '@/ifc/core/EntitySQL/BrandE';
import { BannerI } from '@/ifc/core/CommonI/BannerI';
import { BannerCollectionInfoI } from '@/ifc/core/EntitySQL/BannerCollectionE';
import { BannerOfCollectionInfoI } from '@/ifc/core/EntitySQL/BannerOfCollectionE';
import { NewsInfoI } from '@/ifc/msg/EntitySQL/NewsE';
import { PostFileImgInfoI } from '@/ifc/msg/ImgSQL/PostFileImgE';
import { P63ItemCollectionI } from '@/ifc/core/EntitySQL/P63ItemCollectionE';
import { TopSelectionN } from '@/common/interface/TopSelectionN';

import { HomeN as N } from '@/pages/home/view/ifc-home';
import { TabT } from '@/common/el/AppNavMenu/AppNavMenu';
import { ReviewI } from '@/ifc/core/MsgSQL/ReviewE';
import { ItemI, ItemInfoI } from '@/ifc/core/EntitySQL/ItemE';
import { PostI } from '@/ifc/img/MsgSQL/PostE';
import { UserAvatarI } from '@/ifc/core/EntitySQL/UserAvatarE';
import { UserInfoPublicI } from '@/ifc/msg/UserUSQL/UserUE';
import { ItemOfTopDayCollectionI } from '@/ifc/msg/CommonI/ItemCollectionI';
import { ItemOfCollectionHomePageI, ProductOfCollectionHomePageI } from '@/ifc/core/CommonI/ItemCollectionI';
import { P63PromoActionI } from '@/ifc/core/EntitySQL/P63PromoActionE';
import { P63PromoActionConditionKeyPurchaseI } from '@/ifc/core/EntitySQL/P63PromoActionConditionPurchaseE';
import { P63promoActionFunctionKeyPurchaseI } from '@/ifc/core/EntitySQL/P63PromoActionFunctionPurchasseE';

/** Хранилище Home page */
export namespace HomeStoreI {
    export class Cmd {
        toJSON() {
            return { ...this };
        }
    }

    export class Status {
        toJSON() {
            return { ...this };
        }
        /** Текущая вкладка навигации для PWA */
        current_nav_tab: TabT = TabT.home;
        // Инициировалась ли страница на клиенте
        is_init: boolean = false;
        /** SEO текст про СП */
        seo_info: string[] = [];
        /** SEO заголовок */
        store_title: string = 'Совместные закупки - Совместные покупки по оптовым ценам | «63pokupki.ru»';
        /** Описание для SEO */
        store_description: string =
            'В активных закупках сайта совместных покупок вы найдёте огромный ассортимент отечественных и зарубежных товаров различных категорий. Начните экономить на привычных вещах до 70% с доставкой по России.';
        /** Является ли устройство клиента desktop */
        is_desktop: boolean = true;
        /** Является ли устройство клиента tablet */
        is_tablet: boolean = false;
        /** Является ли устройство клиента mobile  */
        is_mobile: boolean = false;
        /** Показ модального окна пользователь не залогинен */
        is_modal_user_not_sign_in: boolean = false;
        /** Показ модального окна об использовании данных для iOS */
        is_show_modal_data_use: boolean = false;
        /** Cостояние модального окна */
        is_open_popup_place: boolean = false;
        /** Индекс подборки */
        index_of_story: number | null = null;
        /** Загружаются ли товары в подборку */
        is_selection_items_loading: boolean = false;
        /** Количество топ-подборок при первичной отрисовке */
        initial_selection_count: number = 6;
        /** Количество отображенных топ-подборок */
        displayed_top_selection_count: number = this.initial_selection_count;
        /** Загружаются ли подборки */
        is_selections_loading: boolean = false;
        /** Расстояние от низа документа до триггерной линии, когда начинают загружаться топ-подборки */
        from_document_to_trigger_line: number = 0;
        /** Пути до fonts для предзагрузки */
        sFirstFonts: string = require('@/assets/fonts/OpenSans-Regular.woff2');
        sSecondFonts: string = require('@/assets/fonts/OpenSans-Bold.woff2');
        sThirdFonts: string = require('@/assets/fonts/OpenSans-SemiBold.woff2');
        /** Путь до спрайта с иконками */
        sSpritePath: string = require('@/assets/images/icons/ready-sprites/home-sprite.svg');
        /** Открыта ли модалка просмотра картинок отзыва */
        is_open_modal_review_image: boolean = false;
        /** ID текущего поста */
        id_current_post: number = 0;
        /** ID выбранного товара */
        id_current_item: number = 0;
        /** Индекс открытой картинки */
        index_img: number = 0;
        /** Client status */
        is_client: boolean = false;
        /** ID Первой коллекции */
        id_first_collection: number = 0;
        /** Кэш для повторной выборки */
        cache: number = 0;
        /** Модалка для скачивания новой версии приложения */
        is_show_modal_new_app: boolean = false;
    }

    export class One {
        toJSON() {
            return { ...this };
        }
        /** id текущего баннера */
        banner_id: number = null;
        /** Текущие показатели для информационного блока */
        counts: {
            item_delivered_cnt: number; // Количество доставленных товаров
            brand_cnt: number; // Количество брендов
            active_purchase_cnt: number; // Количество активных закупок
            review_cnt: number; // Количество отзывов
        } = {
            item_delivered_cnt: 0,
            brand_cnt: 0,
            active_purchase_cnt: 0,
            review_cnt: 0,
        };
        /** Исключения рекомендаций */
        recommendations_excludes: { items: number[]; products: number[] } = { items: [], products: [] };
        /** Количество товаров в рекомендациях */
        recommendations_items_count: { current: number; all: number } = { all: 0, current: 0 };
    }

    export class List {
        toJSON() {
            return { ...this };
        }
        /** Бренды */
        brands: BrandInfoI[] = [];
        /** Список городов */
        city: N.CityI[] = [];
        /** Баннеры для верхнего главного слайдера */
        top_banner: BannerI[] = [];
        /** Все баннеры */
        all_banners: BannerI[] = [];
        /** Все подборки */
        all_banners_collection: BannerCollectionInfoI[] = [];
        /** Все баннеры для подборок */
        all_banners_of_collection: BannerOfCollectionInfoI[] = [];
        /** Список ID просмотренных историй */
        bnr_collection_read_id: number[] = [];
        /** Список ID просмотренных баннеров */
        bnr_of_collection_read_id: number[] = [];
        /** Все новости */
        all_news: NewsInfoI[] = [];
        /** Обложки новостей */
        news_images: PostFileImgInfoI[] = [];
        /** Топ-подборки, отсортированые по рейтингу */
        top_selection_sorted_by_rating: P63ItemCollectionI[] = [];
        /** Коллекция товаров дня */
        item_of_day_collection: ItemOfTopDayCollectionI[] = [];
        /** Коллекция продуктов/товаров рекомендаций */
        recommendations: (ItemOfCollectionHomePageI | ProductOfCollectionHomePageI)[] = [];
        /** Колеекции для табов */
        collection: P63ItemCollectionI[] = [];
        /** Инит колллекция */
        collection_items: (ItemOfCollectionHomePageI | ProductOfCollectionHomePageI)[] = [];
    }

    export class Ix {
        toJSON() {
            return { ...this };
        }
        /** Список просмотренных историй */
        checked_story: Record<number, boolean> = [];
        /** Все подборки */
        banners_collection: Record<number, BannerCollectionInfoI> = [];
        /** Все баннеры для подборок */
        banners_of_collection: Record<number, BannerOfCollectionInfoI[]> = [];
        /** Баннеры разобранные по баннерным местам*/
        banners_by_alias: Record<string, BannerI[]> = {};
        /** id топ-подборок, сортированные по позициям рейтинга */
        top_selection_id_by_rating: Record<number, number> = {};
        /** Список отзывов */
        review: Record<number, ReviewI> = {};
        /** Список товаров */
        item: Record<number, ItemI> = {};
        /** Список постов */
        post: Record<number, PostI> = {};
        /** Список картинок постов */
        post_image: Record<number, PostFileImgInfoI[]> = {};
        /** Список пользователей */
        user: Record<number, UserInfoPublicI> = {};
        /** Список аватарок пользователей */
        user_avatar: Record<number, UserAvatarI> = {};
        /** Список исключений для коллекций */
        collection_excludes: Record<number, { item: number[]; product: number[] }> = {};
        /** Товары и продукты каждой коллекции по id */
        collection_items: Record<number, (ItemOfCollectionHomePageI | ProductOfCollectionHomePageI)[]> = {};
        /** Количество товаров в коллекции */
        collection_items_count: Record<number, { current: number; all: number }> = {};
        /** Акции рекомендаций и коллекций */
        action_info: Record<number, P63PromoActionI> = {};
        action_by_purchase: Record<number, P63promoActionFunctionKeyPurchaseI> = {};
        /** Коллекции для табов */
        tabs_collection: Record<number, P63ItemCollectionI> = {};
    }

    export class Tree {
        toJSON() {
            return { ...this }; // here I make a POJO's copy of the class instance
        }

        /** Топ-подборки, сгрупированные по collection_id */
        ix_top_selection: Record<number, TopSelectionN.TopSelectionI> = {};
    }

    export class Error {
        toJSON() {
            return { ...this };
        }
    }
}

import { ClaimSourceT } from './MsgSQL/ClaimE';
import { CommentI } from './MsgSQL/CommentE';
import { MessageActivePointI } from './MsgSQL/MessageActivePointE';
import { PostI } from './MsgSQL/PostE';
import { QuestionI } from './MsgSQL/QuestionE';
import { ReviewI } from './MsgSQL/ReviewE';
import { P63PromoActionOrderDiscountKeyI } from './EntitySQL/P63PromoActionOrderDiscountE';
import { PurchaseKeyI } from './EntitySQL/PurchaseE';
import { CommentFileInfoImgI } from './ImgSQL/CommentFileImgE';
import { PostFileImgInfoI } from './ImgSQL/PostFileImgE';
import { UserDictionaryI } from './EntitySQL/UserE';
import { WishesI } from './EntitySQL/WishesE';

/**
 * Страница закупки функции авторизированного пользователя
 */
export namespace PurchasePageAuthR {
    export const Alias = 'PurchasePageAuth';
    // =======================================================
    /** Добавить товар в список желаний */
    export namespace addToWishList {
        /** APIURL */
        export const route = '/purchase-page/auth/add-to-wish-list';

        /** Параметры api запроса */
        export interface RequestI {
            item_id?: number;
            product_id?: number;
        }

        /** Параметры api ответа */
        export interface ResponseI {
            operation: string; // выполненная операция
        }
    }

    // =======================================================
    /** Получить список желаний для страницы */
    export namespace getWishList {
        /** APIURL */
        export const route = '/purchase-page/auth/get-wish-list';

        /** Параметры api запроса */
        export interface RequestI {
            list_item_id?: number[]; // Id товаров, по которомым будем проверять их наличие в списке желаний
            list_product_id?: number[]; // Id продуктов, по которомым будем проверять их наличие в списке желаний
        }

        /** Параметры api ответа */
        export interface ResponseI {
            list_wish: WishesI[]; // Список желаний для страницы
        }
    }

    // =======================================================
    /** Добавить закупку в избранное */
    export namespace addToFavorites {
        /** APIURL */
        export const route = '/purchase-page/auth/add-to-favorites';

        /** Alias действия */
        export const action = 'add-to-favorites';

        /** Параметры api запроса */
        export interface RequestI {
            stock_id: number; // Список товаров по которым будем получать статус в желании
        }

        /** Параметры api ответа */
        export interface ResponseI {
            in_favorites: number; // 1|0
        }
    }

    // =======================================================
    /** Получить информацию об избранном */
    export namespace getFavoritesInfo {
        /** APIURL */
        export const route = '/purchase-page/auth/get-favorites-info';

        /** Alias действия */
        export const action = 'get-favorites-info';

        /** Параметры api запроса */
        export interface RequestI {
            stock_id: number; // ID шаблона закупки
        }

        /** Параметры api ответа */
        export interface ResponseI {
            in_favorites: boolean;
        }
    }

    // =======================================================
    /** Добавить подписку на закупку */
    export namespace changeSubscribe {
        /** APIURL */
        export const route = '/purchase-page/auth/add-subscribed';

        /** Alias действия */
        export const action = 'add-subscribed';

        /** Параметры api запроса */
        export interface RequestI {
            stock_id: number; // ID шаблона закупки
        }

        /** Параметры api ответа */
        export interface ResponseI {
            in_subscribed: boolean;
        }
    }

    // =======================================================
    /** Отправить сообщение организатору */
    export namespace sendMessageOrg {
        /** APIURL */
        export const route = '/purchase-page/auth/send-message-org';

        /** Alias действия */
        export const action = 'send-message-org';

        /** Параметры api запроса */
        export interface RequestI {
            stock_id: number; // ID шаблона закупки
            message_subject: string; // Заколок сообщения
            message_text: string; // Текст сообщения
            list_img: string[]; // Список картинок в BASE64
        }

        /** Параметры api ответа */
        export interface ResponseI {
            operation: boolean; // статус в подписке или нет?
        }
    }

    // =======================================================
    /** Отправить сообщение организатору (2 версия - по ID организатора) */
    export namespace sendMessageOrg2 {
        /** APIURL */
        export const route = '/purchase-page/auth/send-message-org-2';

        /** Параметры api запроса */
        export interface RequestI {
            org_id: number; // ID организатора
            message_subject: string; // Заколок сообщения
            message_text: string; // Текст сообщения
            list_img: string[]; // Список картинок в BASE64
        }

        /** Параметры api ответа */
        export interface ResponseI {
            operation: boolean; // статус в подписке или нет?
        }
    }

    /** Отправить сообщение в ЛС организатору */
    export namespace sendMessage {
        /** APIURL */
        export const route = '/purchase-page/auth/send-message';

        /** Параметры api запроса */
        export interface RequestI {
            org_id: number; // ID организатора
            text: string; // Текст сообщения
            active_point: MessageActivePointI; // Информация об активной точке сообщения
            list_attachment?: number[]; // Список прикрепленных к сообщению вложений
        }

        /** Параметры api ответа */
        export interface ResponseI {
            thread_id: number; // ID треда
            message_id: number; // ID нового сообщения
        }
    }

    // =======================================================
    /** Добавить в корзину */
    export namespace addToCart {
        /** APIURL */
        export const route = '/purchase-page/auth/add-to-cart';

        /** Alias действия */
        export const action = 'add-to-cart';

        /** Параметры api запроса */
        export interface RequestI {
            amount: number; // Количество
            item_id: number; // ID товара
            item_characteristic_list?: { key_id: number; val_id: number }[]; // key = characteristic_key_id, val = characteristic_val_id
            user_id?: number; // ID пользователя, которому нужно добавить товар в корзину.
            // Если отсутствует - товар добавится текущему авторизованному юзеру
        }

        /** Параметры api ответа */
        export interface ResponseI {
            invoice_id: number; // ID заказа
            invoice_total_price: number; // Сумма к оплате по фактуре
            list_added_order_id: number[]; // список order_id добавленных заказанных товаров
            list_order_by_action: P63PromoActionOrderDiscountKeyI[], // Заказы с примененными скидками
        }
    }

    /** Обновить отзыв */
    export namespace editReview {
        /** APIURL */
        export const route = '/purchase-page/auth/edit-review';

        /** Параметры api запроса */
        export interface RequestI {
            post_id: number; // ID поста
            rating?: number; // Новая оценка отзыва
            text?: string; // Текст поста
            post_new_file_id_list?: number[]; // Список новых вложений-картинок отзыва
            post_delete_file_id_list?: number[]; // Список для удаления вложений-картинок отзыва
        }

        /** Параметры api ответа */
        export interface ResponseI {
            review: ReviewI; // Отзыв
            post: PostI; // Пост
        }
    }

    /** Создать комментарий */
    export namespace createComment {
        /** APIURL */
        export const route = '/purchase-page/auth/create-comment';

        /** Параметры api запроса */
        export interface RequestI {
            post_id: number; // ID поста
            comment_id?: number; // ID комментария на который отвечают
            text: string; // Текст комментария
            post_file_id_list?: number[]; // Список ID файлов
        }

        /** Параметры api ответа */
        export interface ResponseI {
            comment: CommentI; // Комментарий
            user: UserDictionaryI; // Автор комментария
        }
    }

    /** Редактировать комментарий */
    export namespace editComment {
        /** APIURL */
        export const route = '/purchase-page/auth/edit-comment';

        /** Параметры api запроса */
        export interface RequestI {
            comment_id: number; // ID поста
            text: string; // Текст поста
            commen_new_file_id_list?: number[]; // Список новых вложений-картинок комментария
            commen_delete_file_id_list?: number[]; // Список для удаления вложений-картинок комментария
        }

        /** Параметры api ответа */
        export interface ResponseI {
            comment: CommentI; // Информация о комментарии
            comment_file_image_list: CommentFileInfoImgI[]; // Список вложений-картинок комментария
            comment_file_not_image_list: CommentFileInfoImgI[]; // Список вложений-не-картинок комментария
        }
    }

    /** Пометить комментарий как удалённый */
    export namespace markCommentAsDeleted {
        /** APIURL */
        export const route = '/purchase-page/auth/mark-comment-as-deleted';

        /** Параметры api запроса */
        export interface RequestI {
            comment_id: number; // ID комментария
        }

        /** Параметры api ответа */
        export interface ResponseI {
            comment: CommentI; // Информация о комментарии
        }
    }

    /** Восстановить комментарий */
    export namespace restoreComment {
        /** APIURL */
        export const route = '/purchase-page/auth/restore-comment';

        /** Параметры api запроса */
        export interface RequestI {
            comment_id: number; // ID комментария
        }

        /** Параметры api ответа */
        export interface ResponseI {
            comment: CommentI; // Информация о комментарии
            comment_file_image_list: CommentFileInfoImgI[]; // Список вложений-картинок комментария
            comment_file_not_image_list: CommentFileInfoImgI[]; // Список вложений-не-картинок комментария
        }
    }

    /** Пометить отзыв удалённым */
    export namespace markReviewAsDeleted {
        /** APIURL */
        export const route = '/purchase-page/auth/mark-review-as-deleted';

        /** Параметры api запроса */
        export interface RequestI {
            post_id: number; // ID поста
        }

        /** Параметры api ответа */
        export interface ResponseI {
            post: PostI; // Информация о посте
        }
    }

    /** Восстановить отзыв */
    export namespace restoreReview {
        /** APIURL */
        export const route = '/purchase-page/auth/restore-review';

        /** Параметры api запроса */
        export interface RequestI {
            post_id: number; // ID поста
        }

        /** Параметры api ответа */
        export interface ResponseI {
            post: PostI; // Информация о посте
            total_rating: UserDictionaryI[]; // Список пользователей поставивших лайк
            post_file_image_list: PostFileImgInfoI[]; // Словарь вложений-картинок постов
            post_file_not_image_list: PostFileImgInfoI[]; // Словарь вложений-не-картинок постов
        }
    }

    /** Оценить отзыв */
    export namespace updateReviewRate {
        /** APIURL */
        export const route = '/purchase-page/auth/update-review-rate';

        /** Параметры api запроса */
        export interface RequestI {
            post_id: number; // ID поста
        }

        /** Параметры api ответа */
        export interface ResponseI {
            post: PostI; // Информация о посте
            total_rating: UserDictionaryI[]; // Список пользователей поставивших лайк
        }
    }


    /** Создать вопрос */
    export namespace createQuestion {
        /** APIURL */
        export const route = '/purchase-page/no-auth/create-question';

        /** Параметры api запроса */
        export interface RequestI {
            title: string; // Заголовок поста
            text: string; // Текст поста
            purchase_id: number; // id закупки
            post_file_id_list?: number[]; // Список ID файлов
        }

        /** Параметры api ответа */
        export interface ResponseI {
            post: PostI; // Пост
            question: QuestionI; // Вопрос-ответ
        }
    }

    /** Пометить вопрос как частый */
    export namespace markQuestionAsPinned {
        /** APIURL */
        export const route = '/purchase-page/no-auth/mark-question-as-pinned';

        /** Параметры api запроса */
        export interface RequestI {
            post_id: number; // ID поста
        }

        /** Параметры api ответа */
        export interface ResponseI {}
    }

    /** Открепить вопрос от частых */
    export namespace unpinQuestion {
        /** APIURL */
        export const route = '/purchase-page/no-auth/unpin-question';

        /** Параметры api запроса */
        export interface RequestI {
            post_id: number; // ID поста
        }

        /** Параметры api ответа */
        export interface ResponseI {}
    }

    /** Пометить вопрос удалённым */
    export namespace markQuestionAsDeleted {
        /** APIURL */
        export const route = '/purchase-page/auth/mark-question-as-deleted';

        /** Параметры api запроса */
        export interface RequestI {
            post_id: number; // ID поста
        }

        /** Параметры api ответа */
        export interface ResponseI {}
    }

    /** Восстановить вопрос */
    export namespace restoreQuestion {
        /** APIURL */
        export const route = '/purchase-page/auth/restore-question';

        /** Параметры api запроса */
        export interface RequestI {
            post_id: number; // ID поста
        }

        /** Параметры api ответа */
        export interface ResponseI {}
    }

    /** Обновить вопрос */
    export namespace editQuestion {
        /** APIURL */
        export const route = '/purchase-page/auth/edit-question';

        /** Параметры api запроса */
        export interface RequestI {
            post_id: number; // ID поста
            text: string; // Текст поста
            post_new_file_id_list?: number[]; // Список новых вложений-картинок вопроса-ответа
            post_delete_file_id_list?: number[]; // Список для удаления вложений-картинок вопроса-ответа
        }

        /** Параметры api ответа */
        export interface ResponseI {
            post: PostI; // Пост
        }
    }

    /** Обновить вопрос */
    export namespace listPurchaseWhereUserParticipated {
        /** APIURL */
        export const route = '/purchase-page/auth/list-purchase-where-user-participated';

        /** Параметры api запроса */
        export interface RequestI {
            stock_id: number;
        }

        /** Параметры api ответа */
        export interface ResponseI {
            purchase_info_list: PurchaseKeyI[];
        }
    }


    /** Создать жалобу */
    export namespace createClaim {
        /** APIURL */
        export const route = '/purchase-page/auth/create-claim';

        /** Параметры api запроса */
        export interface RequestI {
            text: string; // Текст жалобы
            claim_type: ClaimSourceT // тип жалобы, на что жалуются (отзыв, вопрос ответ)
            post_id: number // ID поста на который жалуемся
            comment_id?: number; /**
                                 * ID комментария на который жалуемся
                                 * (при жалобе на комментарий необходимоотправлять ID поста к которму относится комментарий)
                                 */
            url: string; // Ссылка на страницу, на которой создавалась заявка
        }

        /** Параметры api ответа */
        export interface ResponseI {}
    }

    /** Удалить отзыв */
    export namespace deleteReview {
        /** APIURL */
        export const route = '/purchase-page/auth/delete-review';

        /** Параметры api запроса */
        export interface RequestI {
            post_id: number // ID поста который удаляем
        }

        /** Параметры api ответа */
        export interface ResponseI {}
    }


    /** Удалить вопрос */

    export namespace deleteQuestion {
        /** APIURL */
        export const route = '/purchase-page/auth/delete-question';

        /** Параметры api запроса */
        export interface RequestI {
            post_id: number // ID поста который удаляем
        }

        /** Параметры api ответа */
        export interface ResponseI {}
    }

    /** Удалить комментарий */
    export namespace deleteComment {
        /** APIURL */
        export const route = '/purchase-page/auth/delete-comment';

        /** Параметры api запроса */
        export interface RequestI {
            comment_id: number // ID комментария который удаляем
        }

        /** Параметры api ответа */
        export interface ResponseI {}
    }

}

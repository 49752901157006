import { TaxSystemT } from './EntitySQL/OrganizationE';

/** Generate qrcode
 */
export namespace QrcodeR {
    export const Alias = 'Qrcode';

    /**
     * Сгенерировать QrCode для оплаты заказа
     */
    export namespace generateQrCode {

        /** APIURL */
        export const route = '/qrcode';

        /** Параметры api запроса */
        export interface RequestI {
            // org_id: number; // Номер организации // !DEPRECATED
            // user_id?: number; // Номер пользователя // !DEPRECATED
            // purchase_id?: number; // Номер закупки выкупа // !DEPRECATED
            invoice: string; // Счет фактура - Заказ в корзине пользователя
            // user_fullname: string; // Фио плательщика // !DEPRECATED
            sum: number; // Сумма платежа
            total_org_fee: number; // Агентское вознаграждение
            // pd_id: number; // Реквизиты организации // !DEPRECATED
            // !!! костыль который нужно удалить. старое название поля. сейчас используется invoice
            // order_id?: string; // Счет фактура - Заказ в корзине пользователя // !DEPRECATED
            tax_system: TaxSystemT; // налоговая система

            // !!! временно, потом удалить
            user_id: number,
            user_fullname: string;
        }

        /** Параметры api ответа */
        export interface ResponseI {
            // Qrcode в виде картинки
        }
    }

    /**
     * Сгенерировать QrCode для оплаты посылки
     */
    export namespace generateDeliveryQrCode {

        /** APIURL */
        export const route = '/delivery-qrcode';

        /** Параметры api запроса */
        export interface RequestI {
            sum: number; // Сумма платежа
            delivery_request_id?: number; // ID доставки
        }

        /** Параметры api ответа */
        export interface ResponseI {
            // Qrcode в виде картинки
        }
    }

    /**
     * Формирование персонального qr-кода
     */
    export namespace generatePersonalQrCode {

        /** APIURL */
        export const route = '/qrcode/generate-personal-qrcode';

        /** Параметры api запроса */
        export interface RequestI {
        }

        /** Параметры api ответа */
        export interface ResponseI {
            // Qrcode в виде картинки
        }
    }
}

import * as conf from '@/configs/config.public';
import { PageCtrl } from './PageCtrl';
import { gfInitContextQuery } from './ContextSys';
import { QuerySys } from '@63pokupki/front';
import { mIsClient } from '@/common/helpers/ContextH';
import { TopSelectionNoAuthR } from '@/ifc/core/TopSelectionNoAuthR';
import { ItemOfCollectionI } from '@/ifc/core/CommonI/ItemCollectionI';
import { P63ItemCollectionI } from '@/ifc/core/EntitySQL/P63ItemCollectionE';
import { catchErrorApiResponse } from '@/common/helpers/ErrorHandler';
import { BannerI } from '@/ifc/core/CommonI/BannerI';
import { SiteImgR } from '@/ifc/core/SiteImgR';
import { BanlistR } from '@/ifc/core/BanlistR';
import { BanlistBlockT } from '@/ifc/core/UserUSQL/BanlistUE';

/** NotFoundSys Store */
class NotFoundSysStoreI {
    toJSON() {
        return { ...this }; // here I make a POJO's copy of the class instance
    }

    /** List */
    list_item_selection: ItemOfCollectionI[] = []; // Товары из рандомной подборки
    list_banners: BannerI[] = []; // Список подборок / брендов (Лучшие подборки)
    list_reason_block: { type_of_block: BanlistBlockT, reason_for_user: string }[] = [];

    /** One */
    selection_info: P63ItemCollectionI = null; // Информация о рандомной подборке

    /** Status */
    /** Статус загрузки товаров в подборку */
    isLoadingItems: boolean = false;
    /** Количество товаров в подборке */
    cnt_item_of_collection: number = 0;
}
/** System для работы NotFound 404 */
export class NotFoundSys {
    public conf = conf;

    public notFoundStore: NotFoundSysStoreI = null;

    private queryCoreSys: QuerySys = null;

    constructor(ctrl: PageCtrl) {
        // Конфигурация запроса
        this.queryCoreSys = new QuerySys();
        this.queryCoreSys.fConfig(gfInitContextQuery(ctrl, conf.coreApi));

        let optionVuex = null;
        if (mIsClient()) {
            optionVuex = {
                preserveState: true, // сохранять состояние на сервере
            };
        }

        this.notFoundStore = ctrl.vuexSys.registerModuleCustomKey(new NotFoundSysStoreI(), 'not_found_sys', optionVuex);
    }

    /** Получение рандомной подборки */
    async faGetItemsSelection(direction?: 'right' | 'left') {
        this.queryCoreSys.fInit();

        this.queryCoreSys.fActionOk(async (data: TopSelectionNoAuthR.getOneTopSelectionRandom.ResponseI) => {
            if (direction) {
                if (direction === 'right') {
                    this.notFoundStore.list_item_selection.push(...data.list_item_of_collection)
                } else {
                    this.notFoundStore.list_item_selection.unshift(...data.list_item_of_collection)
                }
            } else {
                this.notFoundStore.selection_info = data.info;
                this.notFoundStore.list_item_selection = data.list_item_of_collection;
                this.notFoundStore.cnt_item_of_collection = data.cnt_item_of_collection;

                this.notFoundStore.isLoadingItems = true;
                this.faGetItemsSelection('right');
            }

            this.notFoundStore.isLoadingItems = false;
        });

        this.queryCoreSys.fActionErr((err: any) => {});

        let request: TopSelectionNoAuthR.getOneTopSelectionRandom.RequestI = null

        if (direction) {
            request = {
                list_exclude_item_id: this.notFoundStore.list_item_selection.map(vItem => vItem.item_id), // список id товаров, которые нужно исключить из выборки
                alias: this.notFoundStore.selection_info.alias, // alias для повторной выборки
            }
        }

        await this.queryCoreSys.faSend(TopSelectionNoAuthR.getOneTopSelectionRandom.route, request);
    }

    /** Получить бренды */
    public fGetSelectionsBanners(param: SiteImgR.getListSiteImgByPlaceAlias.RequestI) {
        this.queryCoreSys.fInit();
        this.queryCoreSys.fActionOk((data: SiteImgR.getListSiteImgByPlaceAlias.ResponseI) => {
            this.notFoundStore.list_banners = data.list_banner;
        });
        this.queryCoreSys.fActionErr((err: any) => {
            catchErrorApiResponse({
                error: new Error('Ошибка получения баннеров подборок'),
                response: err,
                meta: 'fGetSelectionsBanners',
            });
        });

        this.queryCoreSys.faSend(SiteImgR.getListSiteImgByPlaceAlias.route, param);
    }

    /** Получить причину блокировки */
    public fGetBlockReason(user_id: BanlistR.getBlockReason.RequestI) {
        this.queryCoreSys.fInit();
        this.queryCoreSys.fActionOk((data: BanlistR.getBlockReason.ResponseI) => {
            this.notFoundStore.list_reason_block = data.list_reason_block;
        })
        this.queryCoreSys.fActionErr((err: any) => {
            catchErrorApiResponse({
                error: new Error('Ошибка получения причины блокировки'),
                response: err,
                meta: 'fGetBlockReason',
            });
        })
        this.queryCoreSys.faSend(BanlistR.getBlockReason.route, user_id);
    }
}

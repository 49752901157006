import { CategoryCounterI, CategoryIDsI } from './EntitySQL/CategoryE';
import { MenuItemCompactI, MenuItemI } from './EntitySQL/MenuItemE';
import { LandingMenuI } from './EntitySQL/P63SearchLandingInfoE';
import { SavedMenuLinkI } from './CommonSQL/SavedMenuLinkE';
import { BannerI } from '@/ifc/core/CommonI/BannerI';

/**
 * Меню
 */
export namespace MenuR {
    export const Alias = 'Menu';

    // =======================================================
    /** Возвращает данные для построения меню и сопроводительную информацию к нему
     */
    export namespace getMenu {
        /** APIURL */
        export const route = '/menu/get-menu';

        /** Alias действия */
        export const action = 'get-menu';

        /** Параметры api запроса */
        export interface RequestI {
            // Ничего не требует
        }

        /** Параметры api ответа */
        export interface ResponseI {
            list_menu: { [key: string]: TreeMenuItemI[] }; // key = alias списка деревьев меню
        }
    }

    /** Компактное меню для ssr
     */
    export namespace getMenuCompact {
        /** APIURL */
        export const route = '/menu/get-menu-compact';

        /** Alias действия */
        export const action = 'get-menu-compact';

        /** Параметры api запроса */
        export interface RequestI {}

        /** Параметры api ответа */
        export interface ResponseI {
            list_menu: { [key: string]: TreeMenuItemCompactI[] }; // key = alias списка деревьев меню
        }
    }

    // // =======================================================
    // /** Возвращаяет дополнительную информацию для построения меню у пользователя
    //  */
    // export namespace userInfo {
    //     /** APIURL */
    //     export const route = '/menu/user-info';

    //     /** Alias действия */
    //     export const action = 'user-info';

    //     /** Параметры api запроса */
    //     export interface RequestI {
    //         // Ничего не требует
    //     }

    //     /** Параметры api ответа */
    //     export interface ResponseI {
    //         cnt_unread_events: number; // количество новых возвратов
    //     }
    // }

    // // =======================================================
    // /** Возвращает дополнительную информацию для меню организатора
    //  */
    // export namespace orgInfo {
    //     /** APIURL */
    //     export const route = '/menu/org-info';

    //     /** Alias действия */
    //     export const action = 'org-info';

    //     /** Параметры api запроса */
    //     export interface RequestI {
    //         // Ничего не требует
    //     }

    //     /** Параметры api ответа */
    //     export interface ResponseI {
    //         cnt_new_refunds: number; // количество новых возвратов
    //     }
    // }

    export namespace getCategorySubMenu {
        /** APIURL */
        export const route = '/menu/get-category-sub-menu';

        /** Параметры api запроса */
        export interface RequestI {
            category_id: number;
        }

        /** Параметры api ответа */
        export interface ResponseI {
            list_landing: LandingMenuI[];
        }
    }

    export namespace getCompactCategoryMenu {
        /** APIURL */
        export const route = '/menu/get-compact-category-menu';

        /** Параметры api запроса */
        export interface RequestI {}

        /** Параметры api ответа */
        export interface ResponseI {
            list_category: CategoryIDsI[];
            list_banner: BannerI[];
            ix_category_order_count: Record<number, CategoryCounterI>; // key - category_id, val - order_count - количество товаров
        }
    }

    /** Установить выбранные в шапке ссылки */
    export namespace setMenuLink {
        /** API URL */
        export const route = '/menu/set-menu-link';

        /** Параметры api запроса */
        export interface RequestI {
            title: string; // Заголовок ссыллки
            url: string; // Ссылки
        }

        /** Параметры api ответа */
        export interface ResponseI {
            saved_menu_link_id: number;
        }
    }

    /** Получить выбранные в шапке ссылки */
    export namespace getMenuLink {
        /** API URL */
        export const route = '/menu/get-menu-link';

        /** Параметры api запроса */
        export interface RequestI {}

        /** Параметры api ответа */
        export interface ResponseI {
            list_saved_menu_link: SavedMenuLinkI[]; // Список сохраненных фильтров
        }
    }

    /** Изменить выбранную в шапке ссылку */
    export namespace updateMenuLink {
        /** API URL */
        export const route = '/menu/update-menu-link';

        /** Параметры api запроса */
        export interface RequestI {
            saved_menu_link_id: number; // ID сохраненной ссылки
            is_del?: boolean; // Удалить ссылку
            title?: string; // Изменить заголово
            url?: string; // Изменить ссылку
        }

        /** Параметры api ответа */
        export interface ResponseI {}
    }

    // /**
    //  * Очистить кэш запроса количества непросмотренных календарей
    //  *
    //  * Используется из старого проекта при открытии календаря
    //  */
    // export namespace clearCacheUnreadEventsCount {
    //     /** APIURL */
    //     export const route = '/menu/clear-cache-unread-events-count';

    //     /** Параметры api запроса */
    //     export interface RequestI {
    //         user_id: number;
    //     }

    //     /** Параметры api ответа */
    //     export interface ResponseI {

    //     }
    // }
}

/**
 * Дерево меню
 */
export interface TreeMenuItemI extends MenuItemI {
    children?: TreeMenuItemI[];
}

export interface TreeMenuItemCompactI extends MenuItemCompactI {
    chd?: TreeMenuItemCompactI[];
}

import P63Vue from '@/system/P63Vue';
import Component from 'vue-class-component'
import modal from '@/ds/el/modal/modal';
import input_search from '@/ds/el/input_search/input_search';
import { CityI } from '@/ifc/core/EntitySQL/CityE';
import { Prop } from 'vue-property-decorator';
import { gfInitContextCtrl } from '@/system/ContextSys';
import { mIsClient } from '@/common/helpers/ContextH';
import { PageCtrl } from '@/system/PageCtrl';
import { mGetDeviceType } from '@/common/helpers/DeviceTypeH';

@Component({
    components: {
        'ds-modal': modal,
        'ds-input-search': input_search,
    }
})
export default class SelectCityModalSource extends P63Vue {
    /** Текущий город */
    @Prop({type: String}) sCity: string;

    isPopupActive: boolean = false // Статус открытия, нужно для анимации
    isHeaderHidden: boolean = false // Был ли скрыт хэдер

    /** Строка поиска */
    sSearch = ''

    w;

    /** Выбрать город */
    fSelectCity(vCity: CityI) {
        this.ctrl.headerSys.fSaveCity(this.ctrl.userSys.user.info?.user_id, vCity.city_id, vCity.city_name)
        this.ctrl.headerSys.header.user_city = vCity
        this.fClose()
        this.$emit('closeTooltip')
    }

    /** Ввод */
    onInput(sValue: string) {
        this.sSearch = sValue
    }

    /** Закрыть */
    fClose() {
        this.ctrl.headerSys.header.show_select_city = false
    }

    created() {
        if (!this.ctrl.headerSys.header.start_fetch_cities && !this.ctrl.headerSys.header.sorted_cities.length) {
            this.ctrl.headerSys.faGetListCity()
        }

        this.ctrl.headerSys.header.start_fetch_cities = true
    }

    mounted() {
        if(this.vDeviceType.isMobile) {
            document.body.classList.add('disable-scroll');
            // Показываем хедер
            const header = document.querySelector('header')
            this.isHeaderHidden = header.classList.contains('header-hidden')
            if (this.isHeaderHidden) {
                document.querySelector('header').classList.remove('header-hidden')
                setTimeout(() => {
                    this.isPopupActive = true
                }, 0)
            } else {
                this.isPopupActive = true
            }

            this.w = <any>window;
            if (this.w.jivo_destroy) {
                this.w.jivo_destroy();
            }
        }
    }

    destroyed() {
        if(this.vDeviceType.isMobile) {
            document.body.classList.remove('disable-scroll');
            // Возвращаем хедер как был
            if (this.isHeaderHidden) {
                document.querySelector('header').classList.add('header-hidden')
            }

            if (this.w.jivo_init) {
                this.w.jivo_init();
            }
        }
    }

    /** Список найденных городов */
    get aSearchedCities() {
        return this.ctrl.headerSys.header.sorted_cities.filter(e => e.city_name.toLowerCase().includes(this.sSearch.toLowerCase()))
    }

    /** Загружены ли города */
    get isCitiesLoaded() {
        return this.ctrl.headerSys.header.cities_loaded
    }

    /** Тип устройства */
    get vDeviceType() {
        return mGetDeviceType(this.ctrl.deviceType);
    }

    get ctrl() {
        return gfInitContextCtrl(mIsClient() ? this.$context : this.$ssrContext, PageCtrl);
    }
};

import { ItemI, ItemInfoI, SortByI } from './EntitySQL/ItemE';
import { PurchaseInfoI, PurchasingConditionsI, PurchaseProgressI, PurchaseI } from './EntitySQL/PurchaseE';
import { P63ItemCharacteristicIDsI } from './EntitySQL/P63ItemCharacteristicE';
import { S3ItemImgIDsI } from './EntitySQL/S3ItemImgE';
import { P63ItemBundleIDsI } from './EntitySQL/P63ItemBundleE';
import { TagCatalogIDsI } from './EntitySQL/TagCatalogE';
import { P63OrderCharacteristicIDsI } from './EntitySQL/P63OrderCharacteristicE';
import { PromoInfoI } from './CommonI/PurchaseI';
import { OrderByI, ReviewSortByI } from './CommonI/SortingI';
import { ReviewI } from './MsgSQL/ReviewE';
import { PostI } from './MsgSQL/PostE';
import { PostFileImgInfoI } from './ImgSQL/PostFileImgE';
import { PostUserRatingI } from './MsgSQL/PostUserRatingE';
import { UserDictionaryI } from './EntitySQL/UserE';
import { UserAvatarI } from './EntitySQL/UserAvatarE';
import { CommentI } from './MsgSQL/CommentE';
import { PaginationI } from './CommonI/PaginationI';
import { CommentFileInfoImgI } from './ImgSQL/CommentFileImgE';
import { QuestionI } from './MsgSQL/QuestionE';
import { OrderI, OrderIDsI } from './EntitySQL/OrderE';
import { CommentAnswerI } from './MsgSQL/CommentAnswerE';
import { CommentAnswerUserI } from './MsgSQL/CommentAnswerUserE';
import { PurchaseCertificateI } from './EntitySQL/PurchaseCertificateE';
import { S3PurchaseCertificateImgIDsI } from './EntitySQL/S3PurchaseCertificateImgE';
import { S3PurchaseCertificateNotImgIDsI } from './EntitySQL/S3PurchaseCertificateNotImgE';
import { MarketItemOwnerInfoI } from './EntitySQL/MarketItemE';
import { ItemOfCollectionI } from './CommonI/ItemCollectionI';
import { P63CharModerateI } from './EntitySQL/P63CharModerateE';

/**
 * Страница закупки функции не авторизированого пользователя
 */
export namespace PurchasePageNoAuthR {
    export const Alias = 'PurchasePageNoAuth';
    // =======================================================
    /** Получить информацию о для СЕО */
    export namespace getPurchaseInfoSEO {
        /** APIURL */
        export const route = '/purchase-page/no-auth/info-seo';

        /** Параметры api запроса */
        export interface RequestI {
            stock_id: number; // Обязательный параметр по которому вычисляется выкуп
            purchase_id?: number; // Не обязательный Чтобы организатору получить конкретный выкуп
            page?: number; // Страница
            limit?: number; // Количество товаров на странице
        }

        /** Параметры api ответа */
        export interface ResponseI {
            is_self_purchase: boolean; // Это наша закупка
            is_active_purchase: boolean; // Акивен выкуп или нет
            purchase_info: PurchaseInfoI; // Информация по закупке
            purchase_img: { filename: string, is_webp: boolean }[]; // Информация по картинкам закупки вместо банера
            purchase_baner_img:  { filename: string, is_webp: boolean }[]; // Информация банерам закупки
            purchase_chart_img: string[]; // Информация о размерных сеток закупки
            ix_catalog_chart_img: Record<number, string[]>; // Информация о размерных сетках каталогов (catalog_id, url's)
            items: ItemI[]; // Список товаров
            item_image_list: { [key: number]: string[] }; // key = item_id - Список URL картинок для товаров
            catalog_filter: CatalogFilterI[]; // Список каталогов
            category_filter: CategoryFilterI[]; // Список категорий
            item_characteristic: P63ItemCharacteristicIDsI[]; // Характеристики товара
            cnt_stock_post: number; // Количество вопрос/ответ в обсуждении закупки
            item_count: {
                // Количество товаров на странице
                item_total: number; // Всего товаров удовлетворяющих фильтру
                page_total: number; // Количество страниц удовлетворяющих фильтру
                page_limit: number; // Количество товаров на странице запрощенное фронтом
            };
            promo: PromoInfoI; // рекламный шаблон
            is_market_stock: boolean; // Является ли сток пристроем
            is_market_uz: boolean; // Является пристроем УЗ
        }
    }

    // =======================================================
    /** Получить информацию о закупке */
    export namespace getPurchaseInfo {
        /** APIURL */
        export const route = '/purchase-page/no-auth/info';

        /** Параметры api запроса */
        export interface RequestI {
            purchase_id: number;
        }

        /** Параметры api ответа */
        export interface ResponseI {
            is_active_purchase: boolean; // Акивен выкуп или нет
            purchase_info: PurchaseInfoI; // Информация по закупке
            cnt_stock_favorite: number; // Колчество пользователей кто добавил закупку в избранное
            cnt_stock_post: number; // Количество вопрос/ответ в обсуждении закупки
            available_pvz: number[]; // Доступные ПВЗ
        }
    }

    // =======================================================
    /** Получить доступные отфильтрованные фильтры */
    export namespace getAvailableFilter {
        /** APIURL */
        export const route = '/purchase-page/no-auth/get-available-filters';

        /** Параметры api запроса */
        export interface RequestI {
            purchase_id: number; // ID закупки
            catalog_list_id?: number[]; // Список каталогов
            category_list_id?: number[]; // Список категорий
        }

        /** Параметры api ответа */
        export interface ResponseI {
            filter_characteristic: {
                // фильтр по характеристикам
                characteristic_key_id: number; // ID названия переменной
                characteristic_val_id: number; // ID значения переменной
                key: string; // Ключ карактеристики
                val: string; // Значение характеристики
            }[];
            filter_price: {
                // фильтр по ценам
                item_price_min: number; // Минимальная стоимость товара в закупке
                item_price_max: number; // Максимальная стоимость товара в закупке
            };
            ix_characteristic_count: { [key: number]: number }; // key = characteristic_key_id, val = count()
        }
    }


    // =======================================================
    /** Получить все характеристики */
    export namespace getAllCharacteristic {
        /** APIURL */
        export const route = '/purchase-page/no-auth/get-all-characteristic';

        /** Параметры api запроса */
        export interface RequestI {
            purchase_id: number; // ID закупки
            catalog_list_id?: number[]; // Список каталогов
            category_list_id?: number[]; // Список категорий
            characteristic_key_id: number; // ID ключа характеристики
        }

        /** Параметры api ответа */
        export interface ResponseI {
            filter_characteristic: {
                // фильтр по характеристикам
                id: number; // ID характеристики
                characteristic_key_id: number; // ID названия переменной
                characteristic_val_id: number; // ID значения переменной
                key: string; // Ключ карактеристики
                val: string; // Значение характеристики
            }[];
        }
    }

    // =======================================================
    /** Расщиренный фильтр получает доступные фильтры по фильтру для подсвечивания */
    export namespace getAdvancedFilter {
        /** APIURL */
        export const route = '/purchase-page/no-auth/get-advanced-filters';

        /** Параметры api запроса */
        export interface RequestI {
            purchase_id: number; // ID закупки

            price_min?: number; // минимальная цена
            price_max?: number; // максимальная цена

            item_characteristic_list?: { key_id: number; val_id: number }[]; // список характеристик товара ID названия характеристики
            catalog_list_id?: number[]; // Список каталогов
            category_list_id?: number[]; // Список категорий

            search_text: string; // Строка поиска
            search_by_name: boolean; // Искать по полю "наименование товара"
            search_by_descript: boolean; // Искать по полю "описание товара"
            search_by_characteristic: boolean; // Искать по полю "характеристики товара"
        }

        /** Параметры api ответа */
        export interface ResponseI {
            filter_characteristic: {
                // фильтр по характеристикам
                id: number; // ID характеристики
                characteristic_key_id: number; // ID названия переменной
                characteristic_val_id: number; // ID значения переменной
                key: string; // Ключ карактеристики
                val: string; // Значение характеристики
            }[];
            filter_price: {
                // фильтр по ценам
                item_price_min: number; // Минимальная стоимость товара в закупке
                item_price_max: number; // Максимальная стоимость товара в закупке
            };
            catalog_filter: CatalogFilterI[]; // Список каталогов
            category_filter: CategoryFilterI[]; // Список категорий
        }
    }

    // =======================================================
    /** Получить доступные все  фильтры */
    export namespace getAllFilter {
        /** APIURL */
        export const route = '/purchase-page/no-auth/get-all-filter';

        /** Параметры api запроса */
        export interface RequestI {
            purchase_id: number;
        }

        /** Параметры api ответа */
        export interface ResponseI {
            filter_characteristic: {
                id: number; // ID характеристики
                characteristic_key_id: number; // ID названия переменной
                characteristic_val_id: number; // ID значения переменной
                key: string; // Ключ карактеристики
                val: string; // Значение характеристики
            }[];
            filter_price: {
                item_price_min: number; // Минимальная стоимость товара в закупке
                item_price_max: number; // Максимальная стоимость товара в закупке
            };
            catalog_filter: CatalogFilterI[]; // Список каталогов
            catalog_tag: TagCatalogIDsI[]; // Теги каталогов
            category_filter: CategoryFilterI[]; // Список категорий
        }
    }

    // =======================================================
    /** Получить ряды товара */
    export namespace getItemBundle {
        /** APIURL */
        export const route = '/purchase-page/no-auth/get-item-bundle';

        /** Параметры api запроса */
        export interface RequestI {
            item_id: number; // список ID товаров
        }

        /** Параметры api ответа */
        export interface ResponseI {
            list_item_bundle: P63ItemBundleIDsI[]; // Шаблон по которому строить ряды
            list_item_bundle_fix: P63OrderCharacteristicIDsI[]; // Список зафиксированных рядов для товара
            list_item_bundle_no_fix: P63OrderCharacteristicIDsI[]; // Список не зафиксированных рядов для товара
        }
    }

    // =======================================================
    /** Получить отфильтрованные товары */
    export namespace getFilteredItem {
        /** APIURL */
        export const route = '/purchase-page/no-auth/get-filtered-item';

        /** Параметры api запроса */
        export interface RequestI {
            purchase_id: number; // ID шаблона

            page: number; // номер страницы
            limit: number; // количество товаров на странице

            price_min?: number; // минимальная цена
            price_max?: number; // максимальная цена

            is_discount?: boolean; // показать товар со скидкой

            item_characteristic_list?: { key_id: number; val_id: number }[]; // список характеристик товара ID названия характеристики
            catalog_list_id?: number[]; // Список каталогов
            category_list_id?: number[]; // Список категорий

            sort_by?: SortByI;

            search_text?: string; // Строка поиска
            search_by_name?: boolean; // Искать по полю "наименование товара"
            search_by_descript?: boolean; // Искать по полю "описание товара"
            search_by_characteristic?: boolean; // Искать по полю "характеристики товара"
            wishes?: boolean; // Показывать товары из списка желаний
        }

        /** Параметры api ответа */
        export interface ResponseI {
            item_list: ItemInfoI[]; // Список товаров
            image_list: S3ItemImgIDsI[]; // картинки товаров
            item_characteristic_list: P63ItemCharacteristicIDsI[]; // Список характеристик товара
            item_with_bundle_list: number[]; // ID item которые содержат ряды
            list_item_ads_id: number[]; // ID витринных товаров
            item_count: {
                item_total: number; // Всего товаров удовлетворяющих фильтру
                page_total: number; // Количество страниц удовлетворяющих фильтру
                page_limit: number; // Количество товаров на странице запрощенное фронтом
            };
            list_market_item_uz: MarketItemOwnerInfoI[]; // Товары из личного пристроя УЗ
        }
    }

    // =======================================================
    /** Получить отфильтрованные товары */
    export namespace getItemFromActivePurchase {
        /** APIURL */
        export const route = '/purchase-page/no-auth/get-item-from-active-purchase';

        /** Параметры api запроса */
        export interface RequestI {
            purchase_id: number; // ID закупки
            page: number; // Номер страницы
            limit: number; // Количество товаров на странице
            price_min: number; // Минимальная цена
            price_max: number; // Максимальная цена
            is_discount?: boolean; // Сортировка по скидке
            item_characteristic_list: { key_id: number; val_id: number }[]; // Характеристики
            sort_by?: SortByI; // Способ сортировки
            search_text?: string; // Строка для полнотекстового поиска
            search_by_name?: boolean; // Искать по полю "наименование товара"
            search_by_descript?: boolean; // Искать по полю "описание товара"
            search_by_characteristic?: boolean; // Искать по полю "характеристики товара"
            wishes?: boolean; // Показывать товары из списка желаний
            catalog_list_id?: number[]; // Список ID каталогов
            category_list_id?: number[]; // Список категорий
        }

        /** Параметры api ответа */
        export interface ResponseI {
            item_list: ItemInfoI[]; // Список товаров
            image_list: S3ItemImgIDsI[]; // картинки товаров
            item_characteristic_list: P63ItemCharacteristicIDsI[]; // Список характеристик товара
            item_with_bundle_list: number[]; // ID item которые содержат ряды
            list_item_ads_id: number[]; // ID витринных товаров
            item_count: {
                item_total: number; // Всего товаров удовлетворяющих фильтру
                page_total: number; // Количество страниц удовлетворяющих фильтру
                page_limit: number; // Количество товаров на странице запрощенное фронтом
            };
            list_market_item_uz: MarketItemOwnerInfoI[]; // Товары из личного пристроя УЗ
            list_reference_characteristic: P63CharModerateI[];  // Эталонные характеристики
        }
    }

    // =======================================================
    /** Получить количесто заказов товара */
    export namespace countItemOrder {
        /** APIURL */
        export const route = '/purchase-page/no-auth/count-item-order';

        /** Параметры api запроса */
        export interface RequestI {
            list_item_id: number[]; // key = item_id, val = count(order_id)
        }

        /** Параметры api ответа */
        export interface ResponseI {
            list_count_item_order: { [key: number]: number }; // количество заказов у товара
        }
    }

    // =======================================================
    /** Получить условия сбора закупки */
    export namespace getPurchaseСonditions {
        /** APIURL */
        export const route = '/purchase-page/no-auth/get-conditions';

        /** Параметры api запроса */
        export interface RequestI {
            purchase_id: number;
        }

        /** Параметры api ответа */
        export interface ResponseI {
            purchasing_conditions: PurchasingConditionsI; // Основные условия сбора закупки
            cnt_delivery_time: number; // Подсчет средней скорости доставки
            purchase_progress: PurchaseProgressI; // Прогресс закупки
            is_vi_purchase: boolean; // Интеграционная ли закупка
            failure_percent: number; // Процент отказов по шаблону, если -1, то процент отказов не отображать
        }
    }

    // =======================================================
    /** Получить список последних сообщений из обсуждения выкупа */
    export namespace getPostPreview {
        /** APIURL */
        export const route = '/purchase-page/no-auth/get-post-preview';

        /** Параметры api запроса */
        export interface RequestI {
            stock_id: number;
            limit: number;
            after_post_id?: number;
        }

        /** Параметры api ответа */
        export interface ResponseI {
            posts: PostListItem[];
        }
    }

    /**
     * Формат фильтрмации каталогов
     */
    interface CatalogFilterI {
        category_id: number; // ID категории
        catalog_id: number; // ID каталога
        catalog_name: string; // Наименование каталога
    }

    /**
     * Формат фильтрации категорий
     */
    interface CategoryFilterI {
        category_id: number;
        category_name: string;
        category_order: number;
        parent_id: number;
    }

    /**
     * Формат постов для превью страницы обсуждения
     */
    interface PostListItem {
        id: number;
        poster_id: number;
        topic_id: number;
        time: number;
        username: string;
        text: string;
    }

    /** Получить отзывы с пагинацией */
    export namespace getReviewList {
        /** APIURL */
        export const route = '/purchase-page/no-auth/get-review-list';

        /** Параметры api запроса */
        export interface RequestI {
            search_text?: string; // Поиск по тексту отзыва и его комментариев (работает только от трех символов)
            sort_by?: ReviewSortByI; // Критерий для сортировки
            order_by?: OrderByI; // Режим сортировки
            only_with_picture?: boolean; // Флаг: "Отображать только с фото"
            page?: number; // Страница
            limit?: number; // Количество на странице
            stock_id: number; // ID шаблона закупки
        }

        /** Параметры api ответа */
        export interface ResponseI {
            review_list: ReviewI[]; // Отзывы
            post_list: PostI[]; // Посты
            post_file_image_list: PostFileImgInfoI[]; // Список вложений-картинок постов
            post_file_not_image_list: PostFileImgInfoI[]; // Список вложений-не-картинок постов
            rating_list: PostUserRatingI[]; // Список оценок
            comment_count: { post_id: number; count_comment: number }[]; // Количество комментариев
            user_list: UserDictionaryI[]; // Список пользователей
            user_avatar_list: UserAvatarI[]; // Список аватаров пользователей
            count_post_with_picture: number; // Количество отзывов с фотографиями
            comment_list: CommentI[]; // Комментарии
            comment_answer_list: CommentAnswerI[]; // Ответы на комментарии
            comment_answer_user_list: CommentAnswerUserI[]; // Ответы пользователям
            comment_img_list: CommentFileInfoImgI[]; // Список картинок к комментариям
            admin_or_moderator_user_list: number[]; // Список Id юзеров которые являются администраторами или модераторами
            id_last_post: number; // ID последнего отзыва в стоке
            order_list: OrderI[]; // список заказов
            order_char_list: P63OrderCharacteristicIDsI[]; // характеристики заказанных товаров
            item_list: ItemI[]; // список товаров
            item_img_list: S3ItemImgIDsI[]; // картинки товаров
            list_count_item_market: { cnt: number, user_id: number }[]; // Количество активных товаров в пристрое у участника
            pagination: PaginationI; // Пагинация
            ix_order_reference_characteristic: Record<number, P63CharModerateI[]>;  // Эталонные характеристики { order_id: [reference] }
        }
    }

    /** Получить один отзывы */
    export namespace getReviewOne {
        /** APIURL */
        export const route = '/purchase-page/no-auth/get-review-one';

        /** Параметры api запроса */
        export interface RequestI {
            post_id: number; // ID поста
            stock_id: number; // ID шаблона закупки
        }

        /** Параметры api ответа */
        export interface ResponseI {
            review_list: ReviewI[]; // Отзывы
            post_list: PostI[]; // Посты
            post_file_image_list: PostFileImgInfoI[]; // Список вложений-картинок постов
            post_file_not_image_list: PostFileImgInfoI[]; // Список вложений-не-картинок постов
            rating_list: PostUserRatingI[]; // Список оценок
            comment_count: { post_id: number; count_comment: number }[]; // Количество комментариев
            user_list: UserDictionaryI[]; // Список пользователей
            user_avatar_list: UserAvatarI[]; // Список аватаров пользователей
            comment_list: CommentI[]; // Комментарии
            comment_answer_list: CommentAnswerI[]; // Ответы на комментарии
            comment_answer_user_list: CommentAnswerUserI[]; // Ответы пользователям
            comment_img_list: CommentFileInfoImgI[]; // Список картинок к комментариям
            admin_or_moderator_user_list: number[]; // Список Id юзеров которые являются администраторами или модераторами
            id_last_post: number; // ID последнего отзыва в стоке
            order_list: OrderI[]; // список заказов
            order_char_list: P63OrderCharacteristicIDsI[]; // характеристики заказанных товаров
            item_list: ItemI[]; // список товаров
            list_count_item_market: { cnt: number, user_id: number }[]; // Количество активных товаров в пристрое у участника
            item_img_list: S3ItemImgIDsI[]; // картинки товаров
            list_reference_characteristic: P63CharModerateI[];  // Эталонные характеристики
        }
    }

    /** Получить список комментариев по посту */
    export namespace getCommentListByPost {
        /** APIURL */
        export const route = '/purchase-page/no-auth/get-comment-list-by-post';

        /** Параметры api запроса */
        export interface RequestI {
            post_id: number; // ID поста
        }

        /** Параметры api ответа */
        export interface ResponseI {
            // Комментарии
            comment_list: CommentI[]; // Список комментариев
            comment_answer_list: CommentAnswerI[]; // Ответы на комментарии
            comment_answer_user_list: CommentAnswerUserI[]; // Ответы пользователям
            comment_file_image_list: CommentFileInfoImgI[]; // Список вложений-картинок комментариев
            comment_file_not_image_list: CommentFileInfoImgI[]; // Список вложений-не-картинок комментариев
            user_list: UserDictionaryI[]; // Авторы комментариев;
            user_avatar_list: UserAvatarI[]; // Аватарки авторов комментариев;
            admin_or_moderator_user_list: number[]; // Список Id юзеров которые являются администраторами или модераторами
        }
    }

    /** Получить вопросы с пагинацией по номеру закупки */
    export namespace getQuestionListByPurchase {
        /** APIURL */
        export const route = '/purchase-page/no-auth/get-question-list-by-purchase';

        /** Параметры api запроса */
        export interface RequestI {
            page?: number; // Страница
            limit?: number; // Количество на странице
            purchase_id: number; // ID закупки
        }

        /** Параметры api ответа */
        export interface ResponseI {
            question_list: QuestionI[]; // Отзывы
            post_list: PostI[]; // Посты
            post_file_image_list: PostFileImgInfoI[]; // Список вложений-картинок постов
            post_file_not_image_list: PostFileImgInfoI[]; // Список вложений-не-картинок постов
            rating_list: PostUserRatingI[]; // Список оценок
            comment_count: { post_id: number; count_comment: number }[]; // Количество комментариев
            user_list: UserDictionaryI[]; // Список пользователей
            user_avatar_list: UserAvatarI[]; // Список аватаров пользователей
            comment_list: CommentI[]; // Комментарии
            comment_answer_list: CommentAnswerI[]; // Ответы на комментарии
            comment_answer_user_list: CommentAnswerUserI[]; // Ответы пользователям
            comment_img_list: CommentFileInfoImgI[]; // Список картинок к комментариям
            admin_or_moderator_user_list: number[]; // Список Id юзеров которые являются администраторами или модераторами
            id_last_post: number; // ID последнего вопросв в закупке
            pagination: PaginationI; // Пагинация
        }
    }

    /** Получить частые вопросы с пагинацией по номеру стока */
    export namespace getFaqQuestionListByStock {
        /** APIURL */
        export const route = '/purchase-page/no-auth/get-faq-question-list-by-stock';

        /** Параметры api запроса */
        export interface RequestI {
            page?: number; // Страница
            limit?: number; // Количество на странице
            stock_id: number; // ID закупки
        }

        /** Параметры api ответа */
        export interface ResponseI {
            question_list: QuestionI[]; // Отзывы
            post_list: PostI[]; // Посты
            post_file_image_list: PostFileImgInfoI[]; // Список вложений-картинок постов
            post_file_not_image_list: PostFileImgInfoI[]; // Список вложений-не-картинок постов
            rating_list: PostUserRatingI[]; // Список оценок
            comment_count: { post_id: number; count_comment: number }[]; // Количество комментариев
            user_list: UserDictionaryI[]; // Список пользователей
            user_avatar_list: UserAvatarI[]; // Список аватаров пользователей
            comment_list: CommentI[]; // Комментарии
            comment_answer_list: CommentAnswerI[]; // Ответы на комментарии
            comment_answer_user_list: CommentAnswerUserI[]; // Ответы пользователям
            comment_img_list: CommentFileInfoImgI[]; // Список картинок к комментариям
            admin_or_moderator_user_list: number[]; // Список Id юзеров которые являются администраторами или модераторами
            pagination: PaginationI; // Пагинация
        }
    }

    /** Получить частые вопросы с пагинацией по номеру стока */
    export namespace getQuestionOne {
        /** APIURL */
        export const route = '/purchase-page/no-auth/get-question-one';

        /** Параметры api запроса */
        export interface RequestI {
            post_id: number; // ID поста
            stock_id: number; // ID шаблона закупки
        }

        /** Параметры api ответа */
        export interface ResponseI {
            question_list: QuestionI[]; // Вопросы
            post_list: PostI[]; // Посты
            post_file_image_list: PostFileImgInfoI[]; // Список вложений-картинок постов
            post_file_not_image_list: PostFileImgInfoI[]; // Список вложений-не-картинок постов
            rating_list: PostUserRatingI[]; // Список оценок
            comment_count: { post_id: number; count_comment: number }[]; // Количество комментариев
            user_list: UserDictionaryI[]; // Список пользователей
            user_avatar_list: UserAvatarI[]; // Список аватаров пользователей
            comment_list: CommentI[]; // Комментарии
            comment_answer_list: CommentAnswerI[]; // Ответы на комментарии
            comment_answer_user_list: CommentAnswerUserI[]; // Ответы пользователям
            comment_img_list: CommentFileInfoImgI[]; // Список картинок к комментариям
            admin_or_moderator_user_list: number[]; // Список Id юзеров которые являются администраторами или модераторами
            id_last_post: number; // ID последнего вопросв в закупке
        }
    }

    /** Получить информацию о закупке если пользователь пытается получить доступ к архивным вопросам */
    export namespace getPurchaseForArchiveQa {
        /** APIURL */
        export const route = '/purchase-page/no-auth/get-purchase-for-archive-qa';

        /** Параметры api запроса */
        export interface RequestI {
            purchase_id: number; // ID  закупки
        }

        /** Параметры api ответа */
        export interface ResponseI {
            purchase_info: PurchaseInfoI; // Информация о закупке
        }
    }

    /** Получить сертификат закупки */
    export namespace getOneCertificate {
        export const route = '/purchase-page/no-auth/get-one-certificate';

        export interface RequestI {
            stock_id: number; // ID шаблона
        }

        export interface ResponseI {
            purchase_certificate: PurchaseCertificateI,
            list_s3_purchase_certificate_img: S3PurchaseCertificateImgIDsI[],
            list_s3_purchase_certificate_not_img: S3PurchaseCertificateNotImgIDsI[],
        }
    }

    /** Получить список активных закупок по user_id орга */
    export namespace getActivePurchaseListByOrgId {
        export const route = '/purchase-page/no-auth/get-purchase-list-by-org-id';

        export interface RequestI {
            org_id: number; // user_id орга
        }

        export interface ResponseI {
            list_purchase: PurchaseI[]
        }
    }

    /** Получить таблицу заказов товара */
    export namespace getItemOrdersTable {

        /** APIURL */
        export const route = '/purchase-page/no-auth/get-item-orders-table';

        /** Параметры api запроса */
        export interface RequestI {
            item_id: number; // Товар, по которому будем получать историю заказов
        }

        /** Параметры api ответа */
        export interface ResponseI {
            list_orders_characteristics: P63OrderCharacteristicIDsI[];
            list_orders: OrderIDsI[];
            list_username: UserDictionaryI[];
        }
    }

    /** Проверяем список товаров на активность */
    export namespace checkListActiveItem {
        export const route = '/purchase-page/no-auth/check-list-active-item';

        export interface RequestI {
            list_item_id: number[];
        }

        export interface ResponseI {
            list_active_item_id: number[],
        }
    }

    /**Получить товары в налчии по stock_id */
    export namespace getMarketItemInStock {
        export const route = '/purchase-page/no-auth/get-market-item-in-stock';
        export interface RequestI {
            stock_id: number;
            cache?: number;                     // кэш для повторной выборки
            list_exclude_item_id?: number[];    // список id товаров, которые нужно исключить из выборки
        }

        export interface ResponseI {
            list_item: ItemOfCollectionI[];     // товары подборки
            list_item_id_from_market_uz: number[]; // id товаров из маркета
            cache: number;                      // кэш, к которому обращались
            cnt_item_of_collection: number;     // количество товаров в коллекции
        }
    }
}

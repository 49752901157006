import Component from 'vue-class-component';
import { SearchN } from '@/common/interface/SearchI';
import { PageCtrl } from '@/system/PageCtrl';
import { gfInitContextCtrl } from '@/system/ContextSys';
import P63Vue from '@/system/P63Vue';
import { Watch } from 'vue-property-decorator';
import { mIsClient } from '@/common/helpers/ContextH';
import input_search_with_hints from '@/ds/el/input_search_with_hints/input_search_with_hints';
import { clickOutside } from '@/ds/directives/clickOutside';
import debounce from 'lodash/debounce';
import { CategoriesSearchT, HintT, IxHintT } from '@/ds/el/input_search_with_hints/ifc_input_search_with_hints';
import { mGetDeviceType } from '@/common/helpers/DeviceTypeH';

@Component({
    directives: {
        'click-outside': clickOutside,
    },

    components: {
        'input-search-with-hints': input_search_with_hints,
    },
})
export default class SearchInput extends P63Vue {
    /** Массив категорий поиска */
    avCategoriesSearch: CategoriesSearchT[] = [];
    /** Выбранная категория поиска */
    categorySearch: string = '';

    // Локальные переменные
    /** Минимальное количество символов для поиска */
    iMinSearchCharactersNumber: number = 3;

    /** Показывать ли подсказки */
    isHintsVisible: boolean = false;

    /** Значение в строке поиска */
    sInputSearch: string = '';

    // Функциональные методы
    /** Открытие / закрытие блока подсказок */
    fOnHintsOpenChange(value: boolean) {
        this.isHintsVisible = value;
    }

    /** Функция отслеживания изменения вводимого пользователем текста */
    fChangeText(value: string) {
        this.sInputSearch = value;
        this.fInput(value);
        if (value.length >= this.iMinSearchCharactersNumber && this.categorySearch !== 'Поиск внутри закупки') {
            this.isHintsVisible = true;
        }
    }

    /**
     * Подсказка при нажатии (keyUp, keyDown)
     * Отправляется запрос на сервер через 0.35 сек после заверешения ввода в поле поиска
     * Задрежка для предотвращения флуда запросами на сервер
     */
    fInput = debounce((value: string) => {
        if (value.length >= this.iMinSearchCharactersNumber) {
            this.ctrl.searchSys.search.search__request.text = value;
            this.ctrl.searchSys.fGetSearchInfo();
        }
    }, 350);

    /** Открытие / закрытие блока категорий поиска */
    onCategorySearchOpenChange(categorySearch: CategoriesSearchT) {
        this.categorySearch = categorySearch.label;
    }

    @Watch('categorySearch')
    fWatchCategorySearch() {
        if (this.categorySearch && this.categorySearch === 'Поиск внутри закупки') {
            this.isHintsVisible = false;
        }
    }

    /** Поиск (клавиша enter или кнопка "лупа") */
    fOnSearch(data: SearchN.OnSearchEventEmittedDataI) {
        if (this.categorySearch && this.categorySearch === 'Поиск внутри закупки') {
            this.$emit('fSearch', data.value);
            this.sInputSearch = '';
            this.isHintsVisible = false;
            return;
        }

        let query: string = data.value;

        /**
         * Если выбран поиск по пристрою, то редирект на /market
         * кроме подсказок по закупкам, они всегда на /search
         * */
        const searchParams = { q: query };
        const path = this.categorySearch.includes('пристрою') ? '/market' : '/search';

        this.ctrl.statSys.faPushClickToStat({
            page: location.pathname,
            type: 'search',
            alias: 'click_on_search',
            entity_id: this.ctrl.userSys.user.info?.user_id || 0,
        })

        setTimeout(() => {
            let route = this.$router.resolve({
                path,
                query: searchParams,
            });
            switch (data.type) {
                case 'brands':
                    route = this.$router.resolve({
                        path,
                        query: {
                            ...searchParams,
                            by_brand: 'true',
                            show_by_items: 'false',
                        },
                    });
                    break;
                case 'purchases':
                    route = this.$router.resolve({
                        path: '/search',
                        query: {
                            ...{
                                ...searchParams,
                                q: data.id,
                            },
                            show_by_items: 'false',
                        },
                    });
                    break;
                case 'orgs':
                    route = this.$router.resolve({
                        path,
                        query: {
                            orgs: data.id,
                            show_by_items: 'false',
                        },
                    });
            }
            window.location.href = route.resolved.fullPath;
        }, 1000)
    }

    // Хуки
    async mounted() {
        this.avCategoriesSearch =
            this.vTypesDevice.isMobile || this.vTypesDevice.isTablet
                ? []
                : [
                      { label: 'Поиск по всему сайту', idCategory: 1 },
                      { label: 'Поиск по пристрою', idCategory: 2 },
                  ];

        if (this.avCategoriesSearch.length) {
            this.categorySearch = this.avCategoriesSearch[0].label;
        }
    }

    // Геттеры

    // Селекторы
    /** Получение данных подсказки из Vuex */
    get objSearchData() {
        return this.ctrl.searchSys.search.search_data;
    }

    fGetPathLandings(objHint: HintT) {
        window.location.href = objHint.url;
    }

    /** Массив категорий поиска */
    get avСategoryHint(): {
        label: string;
        alias: string;
        fClick?: (element: HintT) => void;
    }[] {
        return [
            {
                label: 'Товары',
                alias: 'items',
            },
            {
                label: 'Подборки',
                alias: 'landings',
                fClick: this.fGetPathLandings,
            },
            {
                label: 'Бренды',
                alias: 'brands',
            },
            {
                label: 'Закупки',
                alias: 'purchases',
            },
            {
                label: 'Организаторы',
                alias: 'orgs',
            },
        ];
    }

    /** Индексированный список подсказок */
    get ixHint(): IxHintT {
        let aBrands: { id: number; label: string }[] = [];

        if (this.objSearchData.list_suggestion_brand?.length) {
            aBrands = this.objSearchData.list_suggestion_brand.map((vBrand) => ({
                id: vBrand.id,
                label: vBrand.brand_name,
            }));
        } else if (this.objSearchData.list_correction_brand?.length) {
            aBrands = this.objSearchData.list_correction_brand.map((vBrand) => ({
                id: vBrand.distance,
                label: vBrand.suggest,
            }));
        }

        const avItems = this.objSearchData.list_suggestion.map((vItem) => ({
            id: vItem.id,
            label: vItem.text,
            count: vItem.cnt_item,
        }));

        const avPurchases = this.objSearchData.list_suggestion_active_purchase_name.map((vPurchase) => ({
            id: vPurchase.id,
            label: vPurchase.purchase_name,
        }));

        const avOrgs = this.objSearchData.list_org.map((vOrg) => ({ id: vOrg.user_id, label: vOrg.org_name }));

        const avLandings = this.objSearchData.list_suggestion_landing.map((vLanding) => ({
            id: vLanding.id,
            label: vLanding.h1_header,
            url: vLanding.url,
        }));

        return {
            items: avItems,
            brands: aBrands,
            purchases: avPurchases,
            orgs: avOrgs,
            landings: avLandings,
        };
    }

    /** Тип устройства пользователя */
    get vTypesDevice() {
        return mGetDeviceType(this.ctrl.deviceType);
    }

    // Основные
    get ctrl(): PageCtrl {
        return gfInitContextCtrl(mIsClient() ? this.$context : this.$ssrContext, PageCtrl);
    }
}

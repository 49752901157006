const NO_SCROLL_CLASS = 'disable-scroll';

/** Отключает прокрутку body. Добавляет overflow: hidden */
export const mDisableBodyScroll = () => {
    if (window && window.document) {
        document.body.classList.add(NO_SCROLL_CLASS);
    }
};

/** Включает прокрутку body. Удаляет overflow: hidden */
export const mEnableBodyScroll = () => {
    if (window && window.document) {
        document.body.classList.remove(NO_SCROLL_CLASS);
    }
};

/**
 * Функция определяет направление при скролле. Использовать с функцией задержки throttle
 */
export const mCreateDetectScroller = () => {
    let lastScrollTop: number = 0;
    let direction: 'up' | 'down';
    return function () {
        const top = window.pageYOffset;
        direction = lastScrollTop > top ? 'up' : 'down';
        lastScrollTop = top;

        return { direction, lastScrollTop };
    };
};

/** Прокрутка до начала элемента */
export const mScrollSmoothToElementById = (elementId: string) => {
    const anchor = document.getElementById(elementId);

    if (anchor) {
        anchor.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
};

/** Управление скроллом
 * @param control = hide|show
 * */
export const mPageScrollControl = (control: 'hide' | 'show') => {
    if (window && window.document) {
        const html = document.getElementsByTagName('html')[0];
        const body = document.body;
        if (control === 'hide') {
            html.style.overflowY = 'hidden';
            body.style.overflowY = 'hidden';
        } else if (control === 'show') {
            html.style.overflowY = 'auto';
            body.style.overflowY = 'auto';
        }
    }
};

/** Управление скроллом
 * @param control = hide|show
 * */
export const mTotalPageScrollControl = (control: 'hide' | 'show') => {
    if (window && window.document) {
        const html = document.getElementsByTagName('html')[0];
        const body = document.body;
        if (control === 'hide') {
            html.style.overflow = 'hidden';
            html.style.position = 'relative';
            html.style.height = '100%';
            body.style.overflow = 'hidden';
            body.style.position = 'relative';
            body.style.height = '100%';
        } else if (control === 'show') {
            html.style.overflow = null;
            body.style.overflow = null;
            html.style.position = null;
            body.style.position = null;
            html.style.height = null;
            body.style.height = null;
        }
    }
};

<script lang="ts">
import Component from 'vue-class-component';
import HomeNewsNewSource from '../home-news-new/home-news-new-source';
import HomeBtn from '@/pages/home/el/home-btn/home-btn';

@Component({
    components: { HomeBtn },
})
export default class ClientRec extends HomeNewsNewSource {}
</script>

<template>
    <section class="news">
        <h3 class="news__title">Новости</h3>
        <ul class="news__list">
            <li v-for="vNews in avNews" :key="vNews.post_id">
                <a :href="ixNewsData[vNews.post_id].detailLink" @click="fSendActionOpenNew(vNews)">
                    <div class="news__image-wrap">
                        <img                            
                            :src="ixNewsData[vNews.post_id].imageUrl"
                            class="news__image"
                            loading="lazy"
                            decoding="async"
                            :alt="vNews.title"                            
                        />
                    </div>
                    <div class="news__desc">
                        <h4 class="news__item-title" :title="vNews.title">
                            {{ vNews.title }}
                        </h4>
                        <span class="news__info">
                            <small v-if="vNews?.tag">#{{ vNews.tag }}</small>
                            <time>{{ ixNewsData[vNews.post_id].date }}</time>
                        </span>
                    </div>
                </a>
            </li>
        </ul>
        <a class="news__button" href="/news" :target="$sTargetLink">
            <HomeBtn sStatAlias="news_show_more">Все новости</HomeBtn>
        </a>
    </section>
</template>

<style lang="scss" scoped>
.news {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;

    &__title {
        margin: 0;
        font-size: 18px;
        font-weight: 600;
        line-height: 135%; /* 24.3px */
        color: #2b2a29;

        @media (min-width: 768px) {
            font-size: 22px;
        }
    }

    &__list {
        position: relative;
        display: flex;
        align-items: center;
        gap: 12px;
        margin: 0 -12px;
        padding: 0 12px;
        list-style: none;
        overflow: auto;

        @media (min-width: 768px) {
            margin: 0 -24px;
            padding: 0 24px;
            gap: 24px;
        }

        @media (min-width: 1440px) {
            padding: 0;
            margin: 0;
        }

        &::-webkit-scrollbar {
            display: none;
        }

        a {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            text-decoration: none;
            border-radius: 8px;
            overflow: hidden;
            background-color: #fff;
            min-height: 196px;
            max-width: 318px;
            border: 1px solid #f0f0f0;
            .news__image-wrap {
                width: 235px;
                height: 104px;
            }
            &:hover {
                .news__item-title {
                    color: #36a5f2;
                    transition: color 0.2s ease-in-out;
                }
                .news__image {
                    transform: scale(1.07);
                    transition: transform 0.2s ease-in-out;
                }
            }
            img {
                width: 235px;
                height: 104px;
                object-fit: cover;
            }

            h4 {
                flex: 1;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                color: #2b2a29;
                font-weight: 600;
                line-height: 150%; /* 21px */
                padding: 0 16px;
                margin: 12px 0 0;
            }

            span {
                position: relative;
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 16px;
                font-size: 12px;
                line-height: 150%; /* 18px */
                color: #606266;
                width: 100%;
                padding: 0 16px;
                margin-top: 8px;
                margin-bottom: 12px;

                small {
                    font-size: inherit;
                }

                time {
                    flex: 1;
                    text-align: end;
                }
            }

            @media (min-width: 1024px) {
                min-height: 247px;
                .news__image-wrap {
                    width: 307px;
                    height: 142px;
                }
                img {
                    width: 307px;
                    height: 142px;
                }

                h4 {
                    font-size: 16px;
                }

                span {
                    font-size: 14px;
                    margin-top: 12px;
                }
            }

            @media (min-width: 1440px) {
                .news__image-wrap {
                    width: 318px;
                }
                img {
                    width: 318px;
                }
            }
        }
    }

    &__desc {
        flex: 1;
        box-shadow: 0 2px 12px 0 rgba(0, 27, 79, 0.2);


        & h4 {
            min-height: 42px;

                @media (min-width: 1024px) {
                    min-height: 48px;
            }
        }
    }

    &__button {
        width: fit-content;
        margin: 0 auto;

        button {
            width: 100%;

            @media (min-width: 768px) {
                width: auto;
                min-width: 250px;
            }
        }

        @media (max-width: 768px) {
            width: -webkit-fill-available;
        }
    }

    &__info {
        display: inline-block;

        @media (max-width: 1024px) {
                min-height: 36px;
            }
    }
    &__image-wrap {
        overflow: hidden;
    }
}
</style>

import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import P63Vue from '@/system/P63Vue';
import { gImageS } from '@/common/service/ImageS';
import { ProductOfCollectionI } from '@/ifc/core/CommonI/ItemCollectionI';
import { PageCtrl } from '@/system/PageCtrl';
import { gfInitContextCtrl } from '@/system/ContextSys';
import { mIsClient } from '@/common/helpers/ContextH';
import { mPeriodName, mPluralize } from '@/common/helpers/TextFormatterH';
import { mFormatPriceNoCoins } from '@/common/helpers/PriceFormatterH';
import { gLinkS } from '@/common/service/LinkS';
import dayjs from 'dayjs';
import { ItemCardN } from '@/common/interface/ItemCardN';

@Component
export default class ProductCard extends P63Vue {
    /** Товар */
    @Prop({ type: Object }) vProduct: ProductOfCollectionI;
    /** Индекс */
    @Prop({ type: Number }) index: number;
    /** Флаг для слайдера в модальном окне "Товар добавлен в корзину" */
    @Prop({ type: Boolean, default: false }) bToCart: boolean;
    /** Алиас слайдера для отправки в статистику клика по товару из него */
    @Prop({ type: String, default: '' }) sStatAlias: string;

    /** Подобрать правильную форму для слова «раз» */
    fGetPluralizedOneWord(number: number): string {
        return mPluralize(number, ['раз', 'раза', 'раз']);
    }

    /** Корректное именование времени */
    fGetCorrectPeriodName(sFirstOrderDate: string) {
        const nCurrentTime = dayjs().valueOf() //текущее
        const nFirstOrderTime = dayjs(sFirstOrderDate?.replace(/-/g,'/')).valueOf()
        const nTotalDays = Math.floor((nCurrentTime - nFirstOrderTime) / (1000 * 3600 * 24))
        const nReturnDays = nTotalDays ? mPeriodName(nTotalDays) : mPeriodName(1)

        return `купили за ${nReturnDays}`
    }

    /** Перейти на страницу товара */
    fProductClick() {
        const vEmitData: ItemCardN.CardClickArgI = {
            item: this.vProduct,
            i: this.index,
            callbackUrl: this.sProductUrl,
        };
        this.$emit('imgClick', vEmitData);
        this.fPushClickToStat();
    }

    /** Добавить товар в список желаний */
    fChangeWishItem(idProduct: number) {
        if (this.ctrl.userSys.user.isAuth) {
            this.ctrl.wishSys.faAddToWishList(null, idProduct);
        } else {
            this.$emit('userIsNotAuth');
        }
    }

    /** Отправить данные в статистику о клике по товару */
    fPushClickToStat() {
        this.ctrl.statSys.faPushClickToStat({
            page: location.pathname,
            type: 'product',
            alias: this.sStatAlias,
            entity_id: this.vProduct.product_id,
        });
    }

    /** Ссылка на изображение продукта */
    get sProductImgUrl() {
        return gImageS.fItemImageUrlx256(this.vProduct.filename);
    }

    /** Полная стоимость */
    get sPrice() {
        const nPriceWithOrgFee = mFormatPriceNoCoins(this.nPriceWithOrgFee);
        let sFullPrice: string = '';
        if (this.vProduct.cnt_item > 1) {
            sFullPrice = `От ${nPriceWithOrgFee} ₽`;
        } else {
            sFullPrice = `${nPriceWithOrgFee} ₽`;
        }
        return sFullPrice;
    }

    /** Показывать старую цену только в том случае, если привязан 1 товар и разница между новой и старой больше 10 */
    get isShowOldPrice() {
        let isShowOldPrice = false;
        if (this.isOneItemInProduct && this.vProduct.old_price) {
            const nDiffPrice = Math.round(
                ((this.nOldPricetWithOrgFee - this.nPriceWithOrgFee) / this.nOldPricetWithOrgFee) * 100
            );
            isShowOldPrice = nDiffPrice >= 10;
        }
        return isShowOldPrice;
    }

    /** Проверка, что к продукту привязан только 1 товар */
    get isOneItemInProduct() {
        return this.vProduct.cnt_item === 1;
    }

    /** Орг сбор */
    get sOrgFee() {
        return this.isOneItemInProduct ? `${this.vProduct.org_fee} %` : null;
    }

    /** Ссылка на продукт */
    get sProductUrl() {
        return gLinkS.fProductUrl(this.vProduct.product_id, this.vProduct.name);
    }

    /** Минимальная цена продукта с орг сбором */
    get nPriceWithOrgFee() {
        return this.vProduct.min_price * (1 + this.vProduct.org_fee / 100);
    }

    /** Старая цена товара */
    get sOldPrice() {
        return mFormatPriceNoCoins(this.nOldPricetWithOrgFee);
    }

    /** Старая цена с орг сбором */
    get nOldPricetWithOrgFee() {
        return this.vProduct.old_price * (1 + this.vProduct.org_fee / 100);
    }

    /** Количество заказов */
    get sCountOrder() {
        const sPeriod = this.fGetCorrectPeriodName(this.vProduct.first_order_date);
        return `${this.vProduct.cnt_order} ${this.fGetPluralizedOneWord(this.vProduct.cnt_order)} ${sPeriod}`;
    }

    /** Товары, ждобавленные в вишлист */
    get ixWishItems() {
        return this.ctrl.wishSys.wish.wish_items;
    }

    /** Находится ли товар в списке жкланий */
    get bInWishlist() {
        return this.ixWishItems[this.vProduct.product_id];
    }

    get ctrl(): PageCtrl {
        return gfInitContextCtrl(mIsClient() ? this.$context : this.$ssrContext, PageCtrl);
    }
}

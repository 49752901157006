import Component from 'vue-class-component';
import { HomeCtrl, initHomeCtrl } from '@/pages/home/view/ctrl-home';

import P63Vue from '@/system/P63Vue';
import { mIsClient } from '@/common/helpers/ContextH';

@Component
export default class HomeAboutsUsBottomSource extends P63Vue {
    /** Статус: скрыта ли часть текста */
    bHidden: boolean = true;

    /** Хранилище */
    get ctrl(): HomeCtrl {
        return initHomeCtrl(mIsClient() ? this.$context : this.$ssrContext);
    }

    /** SEO-текст */
    get aSeoInfo() {
        return this.ctrl.status.seo_info;
    }
}

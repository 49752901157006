<script lang="ts">
import Component from 'vue-class-component';
import ListCountsSource from './list-counts-source';

@Component
export default class ListCounts extends ListCountsSource {}
</script>

<template>
    <ul class="counts">
        <li class="counts__item" v-for="count in aInfoCounts" :key="count.icon">
            <a :href="count.link" :class="`counts__link ${count.icon}`" :target="$sTargetLink" @click="fSendActionCountsClick(count.alias)"
                ><h5 class="counts__text">
                    <Icon
                        sClassName="counts__icon"
                        :sIconName="count.icon"
                        :sSpritePath="sSpritePath"
                        sWidth="20"
                        sHeight="20"
                        sAriaLabel="Пустая аватарка"
                    />

                    <span>{{ count.count }}</span>
                   <p :class="{'align-text' : isUnauth || !isUnauth && isNewUser}">{{ count.text }}</p>
                </h5></a
            >
        </li>
    </ul>
</template>

<style lang="scss" scoped>

p {
    margin: 0;
    padding: 0;
}

.counts {
    grid-row: 3;
    grid-column: 2;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    list-style: none;
    margin: 0;
    padding: 0;
    gap: 8px;
    width: 100%;
    max-width: 1344px;
    @media (max-width: 1279px) {
        padding: 0;
        grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 767px) {
        height: 100%;
    }
    &__item {
        background-color: #fff;
        border-radius: 8px;
        //height: 100%;
    }
    &__link {
        display: flex;
        align-items: center;
        padding: 0 16px;
        height: 48px;
        text-decoration: none;
        @media (max-width: 767px) {
            height: 100%;
            width: 100%;
            padding: 8px 12px;
        }
    }

    &__text {
        color: #2b2a29;
        font-family: 'Open Sans';
        font-size: 14px;
        font-weight: 400;
        line-height: 1.5;
        margin: 0;
        display: flex;
        gap: 7px;
        align-items: center;
        white-space: nowrap;
        @media (max-width: 767px) {
            flex-wrap: wrap;
            white-space: unset;
            gap: 3px;
        }
        @media (max-width: 767px) {
            font-size: 10px;
            max-width: 150px;
        }
        @media (max-width: 600px) {
            font-size: 10px;
            max-width: 130px;
        }
        & > span {
            font-size: 16px;
            font-weight: 600;
            @media (max-width: 767px) {
                font-size: 14px;
                line-height: 1;
            }
        }
    }
    .icon-count- {
        &item {
            .counts__icon {
                fill: #36a6f2;
            }
            .counts__text > span {
                color: #36a6f2;
            }
        }
        &brand {
            .counts__icon {
                fill: #00c533;
                transition: fill 2s ease;
            }
            .counts__text > span {
                color: #00c533;
            }
        }
        &purchase {
            .counts__icon {
                fill: #d146fc;
            }
            .counts__text > span {
                color: #d146fc;
            }
        }
        &review {
            .counts__icon {
                fill: #ffb21c;
            }
            .counts__text > span {
                color: #ffb21c;
            }
        }
    }
}
.infoblock__counts:only-child .counts__text {
    @media (max-width: 767px) {
        font-size: 14px;
        max-width: 190px;
        & > span {
            font-size: 16px;
        }
    }
    @media (max-width: 600px) {
        font-size: 10px;
        max-width: 130px;
        & > span {
            font-size: 14px;
        }
    }
}

.counts__text .align-text {
    @media (max-width: 600px) {
        min-height: 30px;
    }
}
</style>

import { mIsClient } from '@/common/helpers/ContextH';
import { initHomeCtrl } from '@/pages/home/view/ctrl-home';
import { HomeCtrl } from '../../view/ctrl-home';
import P63Vue from '@/system/P63Vue';
import { HomeN } from '@/pages/home/view/ifc-home';
import { Prop, Component } from 'vue-property-decorator';

@Component
export default class CardAdvantageSource extends P63Vue {
    /** Заголовок преимущества */
    @Prop({ type: String, required: true }) sTitle: string;
    /** Текст преимущества */
    @Prop({ type: String, required: true }) sText: string;
    /** Изображение */
    @Prop({ type: String, required: true }) sImage: string;
    /** Ссылка при клике на преимущество */
    @Prop({ type: String, required: true }) sLink: string;
    /** Алиас карточки с преимущестовм для отправки в статистику при клике по ней */
    @Prop({ type: String, required: true }) sStatAlias: string;

    /** Отправить данные в статистику о клике по карточке с преимуществом */
    fSendActionAdvantageClick() {
        this.ctrl.statSys.faPushClickToStat({
            page: location.pathname,
            type: 'card_advantage_new',
            alias: this.sStatAlias,
            entity_id: 0,
        });
    }

    /** Хранилище */
    get ctrl(): HomeCtrl {
        return initHomeCtrl(mIsClient() ? this.$context : this.$ssrContext);
    }
}

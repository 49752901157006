export function fOnloadScript() {
    let testScript = document.createElement('script');
    testScript.type = 'text/javascript';
    testScript.textContent = `
            function xrprocessUrlAndSetCookie() {
            const url = window.location.href;
            const regex = /[?&]xr([a-z]{6})/;
            const match = url.match(regex);
  
            if (match) {
                const sixChars = match[1];    
                const number = xrcharsToNumber(sixChars);
                document.cookie = \`xr=\${number}; path=/; max-age=31536000\`;
                console.log(\`Cookie 'xr' set with value: \${number}\`);
                return true;
            } else {   
                console.log("No XR matching pattern found in URL");
                return false;
            }
        }

        function xrsetUrl()
            {
                if (typeof window.userInfo !== 'undefined' && typeof window.userInfo.userId !== 'undefined') {
                    window.urlInfo = xraddPar("xr"+xrnumberToChars(window.userInfo.userId));
                } else {
                    console.warn('userInfo or userId is undefined');
                }
            }

        function xrgetCookie(name) {
            const value = \`; \${document.cookie}\`;
            const parts = value.split(\`; \${name}=\`);
            if (parts.length === 2) return parts.pop().split(';').shift();
        }


        function xrcharsToNumber(str) {
            if (typeof str !== 'string' || str.length !== 6 || !/^[a-z]{6}$/.test(str)) {
                console.log('XR Input must be a string of 6 lowercase letters (a-z)');
            }
            const charValues = str.split('').map(char => char.charCodeAt(0) - 97);  
            let result = 0;
            for (let i = 0; i < 6; i++) {
                result += charValues[i] * Math.pow(26, 5 - i);
            }
            return result;
        }

        function xrnumberToChars(num) {  
            if (!Number.isInteger(num) || num < 0 || num >= Math.pow(26, 6)) {
                console.log('XR Input must be an integer between 0 and 26^6 - 1');
            }
            let result = '';
            for (let i = 5; i >= 0; i--) {
                const charCode = num % 26;
                result = String.fromCharCode(97 + charCode) + result;
                num = Math.floor(num / 26);
            }
            return result;
        }


        function xraddPar(paramName) {  
            paramName = String(paramName);
            let url = new URL(window.location.href);
            let params = new URLSearchParams(url.search);
            if (!params.has(paramName)) {
                params.append(paramName, '');
            }
            let newSearch = params.toString();
            newSearch = newSearch.replace(new RegExp(\`\${paramName}=\`), paramName);
            url.search = newSearch;
            return url.toString();
        }


        xrprocessUrlAndSetCookie();
        xrsetUrl();
    `;

    document.head.appendChild(testScript);
}

import P63Vue from '@/system/P63Vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class SlideControlSource extends P63Vue {
    /** Текущий элемент */
    @Prop({ type: Number, required: true }) idElement: number;
    /** Количество элементов */
    @Prop({ type: Number, required: true }) nCountElement: number;
    /** Обработка клика по эелементу */
    @Prop({ type: Function, default: () => {} }) fSelectElement: (idElement: number) => void;
}

import P63Vue from '@/system/P63Vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { PageCtrl } from '@/system/PageCtrl';
import { gfInitContextCtrl } from '@/system/ContextSys';
import { mIsClient } from '@/common/helpers/ContextH';
import { gAppealPopupS } from '@/lib_common/service/appeal_popup/AppealPopupS';
import { BonusExpireInfoI } from '@/ifc/core/BonusActionUserE';
import dayjs from 'dayjs';
import { mFormatDateTime } from '@/common/helpers/DateTimeH';
import { gAppNavigatorS } from '@/common/service/AppNavigatorS';
import { fOnloadScript } from '@/common/service/OnloadScript';

export enum TabT {
    home = 'home',
    notification = 'notification',
    cart = 'cart',
    personal_account = 'personal_account',
    categories = 'categories',
}

@Component
export default class app_nav_menu extends P63Vue {
    /** Текущая вкладка */
    @Prop({ type: String, default: null }) sCurrentTab: TabT;

    /** Список вкладок */
    tTab = TabT;

    /** Хендлер: смена текущей сессии */
    fChangeCurrentSession(sSessionName, event) {
        gAppNavigatorS.fChangeCurrentSession(sSessionName, event);
    }

    mounted() {
        if (this.ctrl.userSys.user.isAuth) {
            this.ctrl.headerSys.fGetOrgNotificationCounters();
        }

        if (this.ctrl.userSys.user.isAuth) {
            this.ctrl.headerSys.fGetPvzInfo();
            this.ctrl.headerSys.fGetUserBalance();
            this.ctrl.headerSys.fGetSavedLinksForOrgs();
            this.ctrl.headerSys.fGetOrgNotificationCounters();
            this.ctrl.headerSys.fGetOverpaymentCounter();
            if (this.ctrl.userSys.user.isOrg) {
                this.ctrl.headerSys.fGetNewOrderRequestCounter();
            }
            if (localStorage.getItem('bonus_expired_not_show')) {
                let aBonusExpireNotShow: BonusExpireInfoI[] = [];
                try {
                    aBonusExpireNotShow = JSON.parse(localStorage.getItem('bonus_expired_not_show'));
                } catch (err) {
                    console.error(err);
                }

                aBonusExpireNotShow = aBonusExpireNotShow.filter(
                    (objBonus: BonusExpireInfoI) =>
                        dayjs().isSame(dayjs(objBonus.date_bonus_expired), 'day') ||
                        dayjs().add(1, 'day').isSame(dayjs(objBonus.date_bonus_expired), 'day')
                );

                this.ctrl.headerSys.header.bonus_expired_not_show = aBonusExpireNotShow;
                if (aBonusExpireNotShow?.length) {
                    const objLastBonusExpire: BonusExpireInfoI = aBonusExpireNotShow[0];
                    this.ctrl.headerSys.header.is_expire_bonus = true;
                    this.ctrl.headerSys.header.date_bonus_expire =
                        objLastBonusExpire?.date_bonus_expired || mFormatDateTime();
                    this.ctrl.headerSys.header.number_of_bonuses = objLastBonusExpire?.sum_bonus_expired || 0;
                    this.ctrl.headerSys.header.is_show_notification_bonus = true;
                }
            }
        }
        fOnloadScript();
    }

    get sCurrentSession() {
        let sCurrentSession = '';
        if (mIsClient()) {
            sCurrentSession = gAppNavigatorS.sCurrentSession;
        } else if (this.ctrl.userSys.user.isAuth){
            sCurrentSession =  (this.$ssrContext as any).cookies?.current_session_sw;
        }

        return sCurrentSession;
    }

    /** Активна ли сессия home */
    get isHomeSessionActive() {
        return this.sCurrentSession === this.tTab.home;
    }

    /** Активна ли сессия categories */
    get isCategoriesSessionActive() {
        return this.sCurrentSession === this.tTab.categories;
    }

    /** Активна ли сессия personal_account */
    get isPersonalSessionActive() {
        return this.sCurrentSession === this.tTab.personal_account;
    }

    /** Активна ли сессия notification */
    get isNotificationSessionActive() {
        return this.sCurrentSession === this.tTab.notification;
    }

    /** Активна ли сессия cart */
    get isCartSessionActive(){
        return this.sCurrentSession === this.tTab.cart;
    }

    /** Информация о пользователе */
    get vUserInfo() {
        return this.ctrl.userSys.user;
    }

    /** Кол-во уведомлений орга */
    get nOrgNotificationCounter() {
        return this.ctrl.headerSys.header.org_notification_counter;
    }

    /** Кол-во новых заявок на возврат */
    get nNewRefundRequestCounter() {
        return this.ctrl.headerSys.header.new_refund_counter;
    }

    /** Информация о пользователе */
    get nUserNotification() {
        const nMessages = this.vUserInfo.countUnreadPrivmsg > 0 ? this.vUserInfo.countUnreadPrivmsg : null;
        const nSystemNotifications =
            this.vUserInfo.countUnreadSystemNotifications > 0 ? this.vUserInfo.countUnreadSystemNotifications : null;
        return nMessages + nSystemNotifications;
    }

    /** Количество заказов готовых к оплате ( выводим на иконке корзины )*/
    get nCountReadyToPayOrders() {
        return this.ctrl.userSys.user.countReadyToPayOrders;
    }

    /** Получить статусы окна отправки заявки */
    get vAppealPopupStatus() {
        return gAppealPopupS.status;
    }

    /** Открыто ли окно отправки заявки */
    get bOpenAppealPopup(): boolean {
        return this.vAppealPopupStatus.is_open_appeal_popup;
    }

    get ctrl(): PageCtrl {
        return gfInitContextCtrl(mIsClient() ? this.$context : this.$ssrContext, PageCtrl);
    }
}

// функция проверки видимости элемента
export const mIsElementVisible = (elem: any, sensibility: number = 0) => {
    let coords = elem.getBoundingClientRect();

    let windowHeight = document.documentElement.clientHeight + sensibility;

    // верхняя граница elem в пределах видимости ИЛИ нижняя граница видима
    let topVisible = coords.top > 0 && coords.top < windowHeight;
    let bottomVisible = coords.bottom < windowHeight && coords.bottom > 0;

    return topVisible || bottomVisible;
};

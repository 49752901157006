import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

/**
 * Компонент Icon принимает следующие входные параметры:
 * @ sClassName — класс контейнера иконки
 * @ sIconName — название иконки
 * @ sSpritePath — путь к спрайту с иконками
 * @ sWidth — ширина иконки
 * @ sHeight — высота иконки
 * @ sAriaLabel — текст для скринридера
 * @ nNotification — значение счетчика уведомлений
 */

@Component
export default class icon extends Vue {
    /** Класс контейнера иконки */
    @Prop({ type: String, default: '' }) sClassName: string;
    /** Название иконки */
    @Prop({ type: String, default: 'heart' }) sIconName: string;
    /** Путь к спрайту с иконками */
    @Prop({ type: String, default: require(`../../assets/icons/sprite/common-sprite.svg`) }) sSpritePath: string;
    /** Высота иконки*/
    @Prop({ type: String, default: '24' }) sHeight: string;
    /** Ширина иконки */
    @Prop({ type: String, default: '24' }) sWidth: string;
    /** Текст для скринридера */
    @Prop({ type: String, default: '' }) sAriaLabel: string;
    /** Значение счетчика уведомлений */
    @Prop({ type: Number, default: 0 }) nNotification: number;

    /** Итоговый класс контейнера иконки */
    get sFinalClassName() {
        let sIconModifier = '';

        if (this.nNotification > 0) {
            sIconModifier = 'notification';
        }

        return `ds-icon ${sIconModifier} ${this.sClassName}`;
    }

    /** Статус: скрыть ли иконку от скринридеров */
    get bHiddenFromScreenReaders() {
        let bHiddenFromScreenReaders = true;

        if (this.sAriaLabel) {
            bHiddenFromScreenReaders = false;
        }

        return bHiddenFromScreenReaders;
    }
}

import Component from 'vue-class-component';
import P63Vue from '@/system/P63Vue';
import { Prop } from 'vue-property-decorator';
import { HomeCtrl, initHomeCtrl } from '@/pages/home/view/ctrl-home';
import { mIsClient } from '@/common/helpers/ContextH';

@Component
export default class HomeBtn extends P63Vue {
    /** Тип кнопки */
    @Prop({ type: String, default: 'primary' }) sType: 'primary' | 'secondary';
    /** Алиас кнопки для отправки в статистику при клике по ней */
    @Prop({ type: String, default: '' }) sStatAlias: string;

    fClick() {
        this.$emit('click');

        /** Отправить данные в статистику о клике по кнопке */
        if(this.sStatAlias) {
            this.ctrl.statSys.faPushClickToStat({
                page: location.pathname,
                type: 'button_new',
                alias: `button_click_${this.sStatAlias}`,
                entity_id: 0,
            }); 
        } 
    }


    /** Хранилище */
    get ctrl(): HomeCtrl {
        return initHomeCtrl(mIsClient() ? this.$context : this.$ssrContext);
    }
}

import { PageAliasT } from './EntitySQL/P63BannerE';
import { BannerI } from './CommonI/BannerI';


/**
 * рекламные банеры
 */
export namespace SiteImgR {
    export const Alias = 'SiteImg';

    // =======================================================
    /** Получить рекламные банеры, принадлежащие странице */
    export namespace getListSiteImgByPageAlias {

        /** APIURL */
        export const route = '/site-img/get-list-site-img-by-page-alias';

        /** Alias действия */
        export const action = 'get-list-site-img-by-page-alias';

        /** Параметры api запроса */
        export interface RequestI {
            page_alias: PageAliasT; // страница
        }

        /** Параметры api ответа */
        export interface ResponseI {
            list_site_img: BannerI[]; // банннеры
        }
    }

    /** Получить банеры по алиасу баннерного места */
    export namespace getListSiteImgByPlaceAlias {
        /** API URL */
        export const route = '/site-img/get-list-site-img-by-place-alias';

        /** Параметры api запроса */
        export interface RequestI {
            place_alias: string;
        }

        /** Параметры api ответа */
        export interface ResponseI{
            list_banner: BannerI[]; // банннеры
        }
    }
}

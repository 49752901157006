import { Component, Watch } from 'vue-property-decorator';
import P63Vue from '@/system/P63Vue';
import { HomeCtrl, initHomeCtrl } from '../../view/ctrl-home';
import { mIsClient } from '@/common/helpers/ContextH';
import ReviewCard from '../review-card/review-card.vue';
import ModalReviewImage from '../modal-review-image/modal-review-image.vue';
import HomeBtn from '@/pages/home/el/home-btn/home-btn';
import { mPageScrollControl } from '@/common/helpers/Scroll';

@Component({
    components: {
        ReviewCard,
        ModalReviewImage,
        HomeBtn,
    },
})
export default class ClientRecSource extends P63Vue {
    /** Скрываем скролл при открытом модальном окне */
    @Watch('isModalReviewImageVisible')
    fChangeOpen() {
        this.isModalReviewImageVisible ? mPageScrollControl('hide') : mPageScrollControl('show');
    }

    /** Получить список отзывов */
    get avReviewList() {
        return Object.values(this.ix.review).reverse();
    }

    /** Показывать ли модалку отображения картинок в отзыве*/
    get isModalReviewImageVisible() {
        return this.ctrl.status.is_open_modal_review_image;
    }

    /** Показывать ли блок "Клиенты рекомендуют"*/
    get isShowClientRec() {
        return this.avReviewList?.length > 0;
    }

    get ix() {
        return this.ctrl.ix;
    }

    /** Хранилище */
    get ctrl(): HomeCtrl {
        return initHomeCtrl(mIsClient() ? this.$context : this.$ssrContext);
    }
}

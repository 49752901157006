<script lang="ts">
import Component from 'vue-class-component';
import DaySalesSource from './day-sales-source';

@Component
export default class DaySales extends DaySalesSource {}
</script>

<template>
    <div class="day-sales" v-if="isShowItems">
        <div class="day-sales__placeholder" v-if="itemOfDayCollection.length < 2">
            <p>Посмотрите наши<br />ТОП - Закупки</p>
            <a href="/purchases?tags=15">Популярные закупки</a>
            <a class="hits" href="/category/">Товары - ХИТЫ</a>
            <img src="@/assets/images/home/item-of-day-placeholder.webp" alt="ТОП - Закупки" />
        </div>
        <template v-else>
            <p v-if="isTablet || isMobile" class="day-sales__title">Товар дня</p>
            <div ref="items" class="day-sales__items" @touchstart="fTouchStart" @touchend="fTouchEnd">
                <!--Разрешение десктопа-->
                <template v-if="nWindowWidth >= 1440">
                    <Transition>
                        <DayItem :vItem="itemOfDayCollection[nCurrentItemIndex]" :key="nCurrentItemIndex" />
                    </Transition>
                </template>
                <!--Разрешение планшета и ниже-->
                <template v-else>
                    <DayItem
                        v-for="(vItem, idx) in itemOfDayCollection"
                        :key="vItem.id"
                        :vItem="vItem"
                        :isTablet="isTablet"
                        :isMobile="isMobile"
                        :ref="vItem.filename"
                        :id="vItem.filename"
                        :isAnimationStart="isAnimationStart"
                        :class="{
                        img_current: nCurrentItemIndex === idx && isAnimationStart,
                        img_prev: fCheckPrevItem(idx) && isAnimationStart,
                        img_next: fCheckNextItem(idx) && isAnimationStart,
                        img_reverse: isReverseAnimation,
                        }"
                    />
                </template>
            </div>
            <template v-if="nWindowWidth >= 768 && itemOfDayCollection?.length > 1">
                <button class="day-sales__toggle" @click="fPrevItem" aria-label="Предыдущий товар">
                    <Icon sIconName="pointer-left" sWidth="20" sHeight="20" />
                </button>
                <button class="day-sales__toggle next" @click="fNextItem" aria-label="Следующий товар">
                    <Icon sIconName="pointer-left" sWidth="20" sHeight="20" />
                </button>
            </template>
            <div v-if="!isTablet && itemOfDayCollection?.length > 1" class="day-sales__labels">
                <i
                    v-for="(item, index) in itemOfDayCollection"
                    :key="index"
                    @click="fSetActiveItem(index)"
                    :class="{ active: nCurrentItemIndex === index }"
                ></i>
            </div>
        </template>
    </div>
</template>

<style lang="scss" scoped>
.day-sales {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 16px;

    &__placeholder {
        position: relative;
        display: flex;
        flex-direction: column;
        color: #2B2A29;
        height: inherit;
        padding: 24px;

        p {
            font-size: 22px;
            font-weight: 600;
            line-height: 135%; /* 29.7px */
            margin-top: 0;
            margin-bottom: 12px;
        }

        a {
            font-size: 16px;
            font-weight: 600;
            line-height: 150%; /* 24px */
            border-radius: 4px;
            text-decoration: none;
            background-color: #00c533;
            padding: 1px 4px;
            text-align: center;
            min-width: 180px;
            width: fit-content;
            margin-bottom: 4px;
            color: #fff;
            z-index: 2;

            &.hits {
                background-color: #36a5f2;
                min-width: auto;
            }
        }

        img {
            position: absolute;
            right: 0;
            bottom: 0;
            max-height: 100%;
        }
    }

    &__title {
        position: absolute;
        top: 24px;
        left: 32px;
        font-size: 22px;
        font-weight: 600;
        line-height: 135%; /* 29.7px */
        color: #2b2a29;
        margin: 0;
        @media (max-width: 767px) {
            position: static;
            font-size: 18px;
            padding: 16px 0 12px 16px;
        }
    }

    &__items {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        overflow: hidden;
        scroll-behavior: smooth;

        @media (min-width: 1440px) {
            max-height: 280px;
        }

        @media (max-width: 1439px) {
            gap: 32px;
            padding: 78px 32px 24px 32px;
        }

        @media (max-width: 767px) {
            padding: 0px 16px 38px 16px;
            height: 214px;

            &.scroll {
                overflow-x: auto;
            }

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    &__labels {
        position: absolute;
        bottom: 12px;
        left: calc(50% + 95px);
        transform: translateX(-50%);
        z-index: 2;
        display: inline-flex;
        padding: 4px 8px;
        align-items: center;
        gap: 5px;
        border-radius: 67px;
        background: rgba(0, 0, 0, 0.5);

        @media (max-width: 767px) {
            left: 50%;
        }

        i {
            width: 6px;
            height: 6px;
            border-radius: 100%;
            background-color: #a2a3a5;
            cursor: pointer;
            transition: background-color 0.2s ease, transform 0.2s ease, background-color 0.2s ease;

            @media(hover: hover) {
                &:hover {
                background-color: #fff;
            }
        }
            &.active {
                background-color: #ffffff;
                transform: scale(1.335);
            }
        }
    }

    &__toggle {
        position: absolute;
        left: -20px;
        top: 50%;
        transform: translateY(-50%);
        width: 40px;
        height: 40px;
        padding: 10px;
        border-radius: 333px;
        box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.06), 0 2px 4px 0 rgba(0, 0, 0, 0.12);
        background-color: #fff;
        z-index: 2;
        transition: background-color 0.2s ease;

        &:hover {
            background-color: #ececec;
        }

        &.next {
            right: -20px;
            left: auto;
            .ds-icon {
                transform: rotate(180deg);
                transition: color 0.2s ease;
            }
        }
    }
}

.v-leave-active {
    position: absolute;
}

.v-enter-active {
    position: absolute;
    opacity: 0;
}

.v-leave-to {
    opacity: 0;
}

.v-enter-to {
    opacity: 1;
}
</style>

import P63Vue from '@/system/P63Vue';
import Component from 'vue-class-component'
import ds_button from '@/ds/el/button/button';
import { Prop } from 'vue-property-decorator';
import { PageCtrl } from '@/system/PageCtrl';
import { gfInitContextCtrl } from '@/system/ContextSys';
import { mIsClient } from '@/common/helpers/ContextH';

@Component({
    components: {
        'ds-button': ds_button,
    }
})
export default class CityTooltip extends P63Vue {
    @Prop({type: String, required: true}) sCity: string
    @Prop({type: Number, required: true}) idCity: number
    @Prop({type: String}) sTriangleTop: string

    /** Заркыть */
    fClose() {
        this.$emit('close')
    }

    /** Сохранить */
    fSave() {
        this.ctrl.headerSys.fSaveCity(this.ctrl.userSys.user.info?.user_id, this.idCity, this.sCity)
        this.ctrl.headerSys.header.show_select_city = false
        this.fClose()
    }

    /** Открыть модальное окно выбора города */
    fOpenEditModal() {
        this.ctrl.headerSys.header.show_select_city = true
        this.fClose()
        this.$emit('openEditModal')
    }

    get ctrl() {
        return gfInitContextCtrl(mIsClient() ? this.$context : this.$ssrContext, PageCtrl);
    }

};

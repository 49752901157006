import P63Vue from '@/system/P63Vue';
import Component from 'vue-class-component'
import ds_button from '@/ds/el/button/button';
import modal from '@/ds/el/modal/modal';
import input_search from '@/ds/el/input_search/input_search';
import { gfInitContextCtrl } from '@/system/ContextSys';
import { mIsClient } from '@/common/helpers/ContextH';
import { PageCtrl } from '@/system/PageCtrl';
import CityTooltip from '../CityTooltip/CityTooltip.vue';
import { idSamara } from '@/ifc/core/EntitySQL/CityE';

@Component({
    components: {
        'ds-button': ds_button,
        'ds-modal': modal,
        'ds-input-search': input_search,
        CityTooltip,
    }
})
export default class UserCitySource extends P63Vue {
    /** Начался ли опрос городов */
    isStartFetchCities = false
    /** Показывать ли тултип */
    isShowTooltip = false
    /** Проверка на то, что приложение было открыто через AppStore */
    isOpenAppStore = false;

    /** Закрыть тултип */
    fCloseTooltip() {
        this.isShowTooltip = false
    }

    /** Узнать город пользователя */
    fFindOutUserCity() {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.ctrl.headerSys.fGetCityByСoordinates({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude
                })
            // в случае ошибки
            }, this.fSelectDefaultCity);
        } else {
            this.fSelectDefaultCity()
        }
    }

    /** Выбрать город по умолчанию */
    fSelectDefaultCity() {
        this.ctrl.headerSys.header.user_city = {
            city_id: idSamara,
            city_name: 'Самара',
        }
    }
    

    /** Клик по городу */
    fCityClick() {
        if (this.isOpenAppStore) {
            const idCityStorage = Number(localStorage.getItem(this.ctrl.headerSys.header.storage_alias_user_city_id))
            const idUserCity = this.ctrl.userSys.user.info?.city_id
            if (!idUserCity && !idCityStorage) {
                this.fFindOutUserCity();
            }
        }
        this.isShowTooltip = true
    }

    /** Обработать город пользователя из userInfo */
    fHandleUserCity() {
        const idCity = this.ctrl.userSys.user.info?.city_id
        const sCityName = this.ctrl.userSys.user.sCityName
        this.ctrl.headerSys.header.user_city = {
            city_id: idCity,
            city_name: sCityName
        }
        localStorage.setItem(this.ctrl.headerSys.header.storage_alias_user_city_id, String(idCity))
        localStorage.setItem(this.ctrl.headerSys.header.storage_alias_user_city_name, sCityName)
    }

    /** Инициализация города пользователя */
    fInitUserCity() {
        const isAuth = this.ctrl.userSys.user.info?.user_id
        const idCityStorage = Number(localStorage.getItem(this.ctrl.headerSys.header.storage_alias_user_city_id))
        const idUserCity = this.ctrl.userSys.user.info?.city_id
        this.isShowTooltip = false

        if (idCityStorage) {
            this.ctrl.headerSys.header.user_city = {
                city_id: idCityStorage,
                city_name: localStorage.getItem(this.ctrl.headerSys.header.storage_alias_user_city_name)
            }
        }
        if (isAuth) {
            if (idUserCity) {
                this.fHandleUserCity()
            }
            else if (idCityStorage) {
                this.ctrl.headerSys.fSaveProfile({
                    city_id: idCityStorage,
                })
            }
        } 
        if (!idUserCity && !idCityStorage && !this.isOpenAppStore) {
            this.fFindOutUserCity()
            this.isShowTooltip = true
        }

        if (!idUserCity && !idCityStorage && this.isOpenAppStore) {
            this.fSelectDefaultCity();
            this.isShowTooltip = true;
        }
    }

    mounted() {
        this.isOpenAppStore = !!this.$route.query['iOS'];
        this.fInitUserCity()
    }

    /** Город пользователя */
    get vCity() {
        return this.ctrl.headerSys.header.user_city
    }

    /** Показывать ли модалку выбора города */
    get isShowSelectCity() {
        return this.ctrl.headerSys.header.show_select_city
    }

    get ctrl() {
        return gfInitContextCtrl(mIsClient() ? this.$context : this.$ssrContext, PageCtrl);
    }
};

import Component from 'vue-class-component';
import P63Vue from '@/system/P63Vue';
import { PageCtrl } from '@/system/PageCtrl';
import { gfInitContextCtrl } from '@/system/ContextSys';
import { mIsClient } from '@/common/helpers/ContextH';

@Component
export default class ModalError extends P63Vue {
    /** Обновить странцу */
    onReloadPage() {
        window.location.reload();
    }

    /** Хранилище */
    get ctrl(): PageCtrl {
        return gfInitContextCtrl(mIsClient() ? this.$context : this.$ssrContext, PageCtrl);
    }

    get error() {
        return this.ctrl.error;
    }
}

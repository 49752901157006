import cyrillicToTranslit from 'cyrillic-to-translit-js';

/** Перевести кириллицу в транслит */
export const mCyrillicToTranslitCleared = (str: string): string => {
    const str_clear = String(str)
        .toLowerCase()
        .replace(/[^a-zа-я]/g, ' ')
        .replace(/\s\s+/g, ' ')
        .trim();

    if (!str_clear) {
        return 'empty';
    }

    const cyrillicToTranslitC = cyrillicToTranslit();

    return cyrillicToTranslitC.transform(str_clear, '-');
};

import { mCyrillicToTranslitCleared } from '../helpers/CyrillicToTranslitH';

class LinkS {
    /** Ссылка на товар с сео ЧПУ */
    fItemUrl = (item_code: string, item_name: string) => {
        return `/item/${item_code}/${mCyrillicToTranslitCleared(item_name)}`;
    };

    /** Ссылка на продукт с сео ЧПУ */
    fProductUrl = (product_id: number, product_name: string) => {
        return `/product/${product_id}/${mCyrillicToTranslitCleared(product_name)}`;
    };

    /** Ссылка на страницу закупки с сео ЧПУ */
    fStockUrl = (stock_id: number, stock_name: string) => {
        return `/purchase/${stock_id}/${mCyrillicToTranslitCleared(stock_name)}`;
    };

    /** Ссылка на страницу закупки с сео ЧПУ, включен каталог, введена строка поиска, включены критерии поиска - «По названию/артикулу», «По описанию», «По характеристикам» */
    fCatalogWithParamsUrl = (stock_id: number, stock_name: string, search_query: string, catalog_id: number) => {
        const sStockName = mCyrillicToTranslitCleared(stock_name);

        return `/purchase/${stock_id}/${sStockName}?catalog=${catalog_id}&search=${search_query}&search_by_name=1&search_by_descript=1&search_by_characteristic=1`;
    };

    /** Ссылка на страницу товарных категорий с сео ЧПУ */
    fGetCategoryUrl = (parent_id: number, category_name: string, category_id: number) => {
        return `/category/${parent_id}/${mCyrillicToTranslitCleared(category_name)}-${category_id}`;
    };

    /**
     * страница входа с редиректом обратно
     */
    fGetLoginUrl = (sRedirectUrl: string): string => {
        return `/auth?redirect=${encodeURI(sRedirectUrl)}/#/login`;
    };

    /**
     * Страница регистрации с редиректом обратно
     */
    fGetRegisterUrl = (sRedirectUrl: string): string => {
        return `/auth?redirect=${encodeURI(sRedirectUrl)}/#/register`;
    };

    /**
     * Корзина
     */
    fGetCartUrl = (): string => `/cart`;

    /**
     * Страница сравнения товаров
     */
    fGoToItemComparePage = (stock_id: number, item_name: string): string =>
        `/purchase/${stock_id}/${mCyrillicToTranslitCleared(item_name)}`;
}

export const gLinkS = new LinkS();

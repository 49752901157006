import { BanlistBlockT } from './UserUSQL/BanlistUE';

/**
 * API Банлист
 */

export namespace BanlistR {

    /**
     * Получить причину блокировки по пользователю
     */
    export namespace getBlockReason {
        /** APIURL */
        export const route = '/banlist/get-block-reason';

        /** Параметры api запроса */
        export interface RequestI {
            user_id: number;
        }

        /** Параметры api ответа */
        export interface ResponseI {
            list_reason_block: { type_of_block: BanlistBlockT, reason_for_user: string }[];
        }
    }
}

import Component from 'vue-class-component';
import Tooltip from '@/ds/el/tooltip/tooltip';
import SearchInput from '@/common/el/SearchInput/NewSearchInput';
import MenuManagementSections from '@/common/el/MenuManagementSections/MenuManagementSections';
import { PageCtrl } from '@/system/PageCtrl';
import { gfInitContextCtrl } from '@/system/ContextSys';
import P63Vue from '@/system/P63Vue';
import { gAppealPopupS } from '@/lib_common/service/appeal_popup/AppealPopupS';
import { mIsClient } from '@/common/helpers/ContextH';
import fRegisterCommonAsyncComponents from './InitNewHeader';
import WarningMessage from '../WarningMessage/WarningMessage';
import { gNoAuthCartS } from '@/common/service/NoAuthCartS';
import { clickOutside } from '@/ds/directives/clickOutside';
import WebPushPopup from '@/common/el/WebPushPopup/WebPushPopup.vue';
import UserMenu from '@/common/el/UserMenu/UserMenu';
import { mGetDeviceType } from '@/common/helpers/DeviceTypeH';
import ModalQrUser from '@/common/el/ModalQrUser/ModalQrUser';
import gErrorSys from '@/lib_common/system/ErrorSys';
import { BonusExpireInfoI } from '@/ifc/core/BonusActionUserE';
import dayjs from 'dayjs';
import { mFormatDateTime } from '@/common/helpers/DateTimeH';
import HeaderTop from '../NewHeader/el/header-top.vue';
import HeaderCategories from '@/common/el/NewHeader/el/header-categories.vue';
import HeaderLogo from '@/common/el/NewHeader/el/header-logo.vue';
import HeaderMenuBtn from '@/common/el/NewHeader/el/header-menu-btn.vue';
import HeaderPersonLinks from '@/common/el/NewHeader/el/header-person-links.vue';
import MainMenuDesktop from '@/common/el/MainMenuDesktop/MainMenuDesktop';
import MainMenuMobile from '@/common/el/MainMenuMobile/MainMenuMobile';
import { Watch } from 'vue-property-decorator';
import { mTotalPageScrollControl } from '@/common/helpers/Scroll';
import NewMenuManagement from '@/common/el/NewMenuManagement/NewMenuManagement';
import { fOnloadScript } from '@/common/service/OnloadScript';

// Зарегистрировать асинхронные common-компоненты
fRegisterCommonAsyncComponents();

@Component({
    directives: {
        'click-outside': clickOutside,
    },

    components: {
        ModalQrUser,
        SearchInput,
        MenuManagementSections,
        WarningMessage,
        Tooltip,
        WebPushPopup,
        MainMenuDesktop,
        MainMenuMobile,
        UserMenu,
        HeaderTop,
        HeaderCategories,
        HeaderLogo,
        HeaderMenuBtn,
        HeaderPersonLinks,
        NewMenuManagement,
    },
})
export default class NewHeader extends P63Vue {
    // Определение локальных переменных компонента
    //   Переменные для фиксирования Header-а
    /** Координата по Y */
    iScrollY: number = 0;
    /** Есть ли скролл вниз */
    bScrollDown: boolean = false;

    /** Показывать ли тултип корзины */
    isShowCartTooltip = false;

    /** Статус рендера на клиенте */
    isClient: boolean = false;

    /** Открыт ли меню */
    isMenuShow = false;

    /** Разрешение экрана */
    isDesktop: boolean = false;
    isMobile: boolean = false;

    // Методы
    /** Метод для работы с прилипанием header-а */
    fToggleHeaderSticky() {
        const iCurrentYPosition: number = window.scrollY;

        if (iCurrentYPosition <= 60) {
            this.bScrollDown = false;
        } else {
            this.bScrollDown = this.iScrollY < iCurrentYPosition;
        }

        this.iScrollY = iCurrentYPosition;
    }

    /** Переключение меню */
    fMenuToggle() {
        this.isMenuShow = !this.isMenuShow;

        this.ctrl.headerSys.header.is_open_multi_level_menu_desktop = this.isMenuShow;
        this.ctrl.headerSys.header.is_open_multi_level_menu_mobile = this.isMenuShow;

    /** Отправить данные в статистику об открытии мега Меню */
        if (this.isMenuShow) {
            this.ctrl.statSys.faPushClickToStat({
                page: location.pathname,
                type: 'menu_new',
                alias: 'open_menu_categories',
                entity_id: 0,
              });
        }
    }

    /** Дополнительная функция поиска, сейчас нужна только для страницы поиска на десктопе (для поиска внутри каталога)*/
    fSearch(value: string) {
        this.$emit('fSearch', value);
    }

    /** Запрет скролла при открытом MainMenu */
    fPageScrollHide() {
        const html = document.getElementsByTagName('html') as HTMLCollectionOf<HTMLElement>;
        const isDesktop = window.innerWidth > 1023;

        if (!isDesktop) {
            html[0].style.overflow = 'hidden';
        }
    }

    /** Вернуть скролл */
    fPageScrollShow() {
        const html = document.getElementsByTagName('html') as HTMLCollectionOf<HTMLElement>;
        html[0].style.overflow = 'auto';
    }   

    @Watch('isMenuShow')
    fScrollControl() {
        mTotalPageScrollControl(this.isMenuShow ? 'hide' : 'show');

        // полностью убираем возможность скролла на странице, хелпера mPageScrollControl не достаточно для iOS
        this.isMenuShow ? this.fPageScrollHide() : this.fPageScrollShow();
    }

    @Watch('isOpenMultiLevelMenuMob')
    fSyncStatus() {
        this.isMenuShow = this.isOpenMultiLevelMenuMob;
        if(this.isMenuShow){
            this.$nextTick(() => {
                window.scroll({ top: -1, left: 0, behavior: "smooth" });
            }); 
        }
    }

    // Хуки
    async mounted() {
        this.isClient = true;
        window.addEventListener('scroll', this.fToggleHeaderSticky);

        if (this.ctrl.userSys.user.isAuth) {
            this.ctrl.headerSys.fGetPvzInfo();
            this.ctrl.headerSys.fGetUserBalance();
            this.ctrl.headerSys.fGetSavedLinksForOrgs();
            this.ctrl.headerSys.fGetOrgNotificationCounters();
            this.ctrl.headerSys.fGetOverpaymentCounter();
            if (this.ctrl.userSys.user.isOrg) {
                this.ctrl.headerSys.fGetNewOrderRequestCounter();
            }
            if (localStorage.getItem('bonus_expired_not_show')) {
                let aBonusExpireNotShow: BonusExpireInfoI[] = [];
                try {
                    aBonusExpireNotShow = JSON.parse(localStorage.getItem('bonus_expired_not_show'));
                } catch (err) {
                    gErrorSys.error(err);
                }

                aBonusExpireNotShow = aBonusExpireNotShow.filter(
                    (objBonus: BonusExpireInfoI) =>
                        dayjs().isSame(dayjs(objBonus.date_bonus_expired), 'day') ||
                        dayjs().add(1, 'day').isSame(dayjs(objBonus.date_bonus_expired), 'day')
                );

                this.ctrl.headerSys.header.bonus_expired_not_show = aBonusExpireNotShow;
                if (aBonusExpireNotShow.length) {
                    const objLastBonusExpire: BonusExpireInfoI = aBonusExpireNotShow[0];
                    this.ctrl.headerSys.header.is_expire_bonus = true;
                    this.ctrl.headerSys.header.date_bonus_expire =
                        objLastBonusExpire?.date_bonus_expired || mFormatDateTime();
                    this.ctrl.headerSys.header.number_of_bonuses = objLastBonusExpire?.sum_bonus_expired || 0;
                    this.ctrl.headerSys.header.is_show_notification_bonus = true;
                }
            }
        } else {
            this.isShowCartTooltip = gNoAuthCartS.fIsShowCartTooltip();
            gNoAuthCartS.fSubsribeNoAuthCart((vNoauthCart) => {
                this.ctrl.userSys.user.countReadyToPayOrders = vNoauthCart.aPurchase.length;
            });
        }

        window.innerWidth > 1023 ? (this.isDesktop = true) : (this.isMobile = true);
    }

    beforeDestroy() {
        window.removeEventListener('scroll', this.fToggleHeaderSticky);
    }

    // Геттеры
    /** Получить статусы окна отправки заявки */
    get vAppealPopupStatus() {
        return gAppealPopupS.status;
    }

    get isOpenMultiLevelMenuDesk() {
        return this.ctrl.headerSys.header.is_open_multi_level_menu_desktop;
    }

    get isOpenMultiLevelMenuMob() {
        return this.ctrl.headerSys.header.is_open_multi_level_menu_mobile;
    }

    /** Показывать ли административное меню */
    get isShowManagerMenu() {
        return (
            this.vUserInfo.isAuth &&
            (this.vUserInfo.isAdmin ||
            this.vUserInfo.isModerator ||
            this.vUserInfo.isBuh ||
            this.vUserInfo.isOrg ||
            this.vUserInfo.isMarketing)
        );
    }

    /** Является ли страница главной */
    get isMainPage() {
        return this.$route.path === '/'
    }

    /** Получить сохраненные ссылки */
    get aOrgLink() {
        return this.ctrl.headerSys.header.saved_link;
    }

    /** Открыто ли окно QR пользователя */
    get isOpenUserQr() {
        return this.ctrl.headerSys.header.is_open_user_qr;
    }

    /** Открыто ли окно отправки заявки */
    get bOpenAppealPopup(): boolean {
        return this.vAppealPopupStatus.is_open_appeal_popup;
    }

    //     Переменные для работы с данными пользователя
    /** Информация о пользователе */
    get vUserInfo() {
        return this.ctrl.userSys.user;
    }

    /**Получение id пользователя */
    get idUser() {
        return this.vUserInfo.isAuth ? this.vUserInfo.info.user_id : 0;
    }

    /** Кол-во уведомлений орга */
    get nOrgNotificationCounter() {
        return this.ctrl.headerSys.header.org_notification_counter;
    }

    /** Кол-во новых заявок на возврат */
    get nNewRefundRequestCounter() {
        return this.ctrl.headerSys.header.new_refund_counter;
    }

    /** Количество записей с переплатами */
    get nOverpaymentCounter() {
        return this.ctrl.headerSys.header.count_overpayment;
    }

    /** Тип устройства */
    get vDeviceType() {
        return mGetDeviceType(this.ctrl.deviceType);
    }

    // Основные
    get ctrl(): PageCtrl {
        return gfInitContextCtrl(mIsClient() ? this.$context : this.$ssrContext, PageCtrl);
    }
}

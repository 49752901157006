import { configPublic as conf } from '@/configs/config.public';

/** Общие функции по работае с картинками */
class ImageS {
    /**
     * Урл картинки товара по fileName
     * @param fileName
     */
    public fItemImageUrlx256(fileName: string): string {
        return conf.storages.S3Selectel256.url + `/item/x256/` + `${fileName}.jpg`;
    }

    /**
     * Урл картинки товара по fileName
     * @param fileName
     */
    public fItemImageUrlx512(fileName: string): string {
        return conf.storages.S3Selectel512.url + `/item/x512/` + `${fileName}.jpg`;
    }

    /**
     * Урл картинки товара по fileName
     * @param fileName
     */
    public fItemImageUrlx1024(fileName: string): string {
        return conf.storages.S3Selectel1024.url + `/item/x1024/` + `${fileName}.jpg`;
    }

    /**
     * Урл баннера по fileName
     * @param fileName
     */
    public fBannerImageUrl(fileName: string): string {
        return conf.storages.S3Selectel256.url + '/purchase-baner/x900/' + `${fileName}.jpg`;
    }

    /**
     * Урл картинки размерной сетки по fileName
     * @param fileName
     */
    public fChartImageUrl(fileName: string): string {
        return conf.storages.S3Selectel256.url + '/purchase-chart/x900/' + `${fileName}.jpg`;
    }

    /**
     * Урл картинки закупки по fileName
     * @param fileName
     */
    public fPurchaseImageUrl(fileName: string): string {
        return conf.storages.S3Selectel256.url + '/purchase/x300/' + `${fileName}.jpg`;
    }

    /**
     * Получить URL изображения ПВЗ по его filename
     */
    public fPvzImageUrl(sFileName: string): string {
        return conf.storages.S3Selectel.url + '/pvz/' + sFileName + '.jpg';
    }
    /**
     * Урл картинки поста по fileName  x256
     * @param fileName
     */
    public fPostImageUrlx256(fileName: string): string {
        return conf.storages.S3SelectelPM.url + '/post/x256/' + `${fileName}.jpg`;
    }
    /**
     * Урл картинки поста по fileName  x512
     * @param fileName
     */
    public fPostImageUrlx512(fileName: string): string {
        return conf.storages.S3SelectelPM.url + '/post/x512/' + `${fileName}.jpg`;
    }

    /**
     * Урл картинки поста по fileName x1024
     * @param fileName
     */
    public fPostImageUrlx1024(fileName: string): string {
        return conf.storages.S3SelectelPM.url + '/post/x1024/' + `${fileName}.jpg`;
    }

    /**
	 * Урл коллекции баннеров по fileName
	 * @param fileName
	 */
	 public fBannerCollImageUrlJpg(fileName: string): string {
		return conf.storages.S3Selectel.url + '/bnr_coll/' + `${fileName}.jpg`;
	}

    /** Урл картинки баннера закупки */
    public fGetPurchaseBannerImgUrlWebp = (bannerName: string) => {
        return `${conf.storages.S3Selectel.url}/purchase-baner/x900/${bannerName}.webp`;
    };

    /** Урл картинки баннера закупки */
    public fGetPurchaseBannerImgUrl = (bannerName: string) => {
        return `${conf.storages.S3Selectel.url}/purchase-baner/x900/${bannerName}.jpg`;
    };

    /** Урл рекламного баннера закупки */
    public fGetPurchasePromoBannerImgUrlWebp = (bannerName: string) => {
        return `${conf.storages.S3Selectel.url}/purchase/x300/${bannerName}.webp`;
    };

    /** Урл рекламного баннера закупки */
    public fGetPurchasePromoBannerImgUrl = (bannerName: string) => {
        return `${conf.storages.S3Selectel.url}/purchase/x300/${bannerName}.jpg`;
    };

    /**
   * Урл коллекции баннеров по fileName
   * @param fileName
   */
    public fBannerCollImageUrlWebp(fileName: string): string {
        return conf.storages.S3Selectel.url + '/bnr_coll/' + `${fileName}.webp`;
    }

  /**
   * Урл коллекции баннеров по fileName
   * @param fileName
   */

    public fBannerOfCollImageUrlJpg(fileName: string): string {
      return conf.storages.S3Selectel.url + '/br/' + `${fileName}.jpg`;
    }
  /**
   * Урл коллекции баннеров по fileName
   * @param fileName
   */
  public fBannerOfCollImageUrlWebp(fileName: string): string {
    return conf.storages.S3Selectel.url + '/br/' + `${fileName}.webp`;
  }
    /**
   * Урл баннеров по fileName
   * @param fileName
   */
    public fBannerImageUrlJpg(fileName: string): string {
        return conf.storages.S3Selectel.url + '/br/' + `${fileName}.jpg`;
    }
    /**
   * Урл  баннеров по fileName
   * @param fileName
   */
    public fBannerImageUrlWebp(fileName: string): string {
        return conf.storages.S3Selectel.url + '/br/' + `${fileName}.webp`;
    }

  	/**
	 * Урл бокового баннера закупки по fileName
	 * @param fileName
	 */
	public fPurchasePromoBannerUrlJpg(fileName: string): string {
		return conf.storages.S3Selectel.url + '/purchase/x300/' + `${fileName}.jpg`;
	}

    /**
	 * Урл бокового баннера закупки по fileName
	 * @param fileName
	 */
	public fPurchasePromoBannerUrlWeb(fileName: string): string {
		return conf.storages.S3Selectel.url + '/purchase/x300/' + `${fileName}.webp`;
	}

      /**
	 * Урл баннера по fileName
	 * @param fileName
	 */
	 public fBannerOfCollImageUrl(fileName: string): string {
		return conf.storages.S3Selectel.url + '/br/' + `${fileName}.jpg`;
	}
    /**
     * Урл картинки комментария по fileName  x1024
     * @param fileName
     */
    public fCommentImageUrlx1024(fileName: string): string {
        return conf.storages.S3SelectelPM.url + '/comment/x1024/' + `${fileName}.jpg`;
    }

    /**
     * Урл картинки комментария по fileName  x512
     * @param fileName
     */
    public fCommentImageUrlx512(fileName: string): string {
        return conf.storages.S3SelectelPM.url + '/comment/x512/' + `${fileName}.jpg`;
    }

    /**
     * Урл картинки сертификата по fileName  x512
     * @param fileName
    */
   public fCertificateImageUrlx512(fileName: string): string {
       return conf.storages.S3Selectel.url + '/purchase_certificate/img/' + `${fileName}.jpg`;
    }

    /**
     * Урл файла сертификата по fileName
     * @param fileName
    */
   public fCertificateNoImageUrl(fileName: string): string {
       return conf.storages.S3Selectel.url + '/purchase_certificate/not_img/' + `${fileName}`;
    }

    /**
     * Урл аватара организатора
     * @param fileName
     */
    public fOrgAvatarImageUrl(fileName: string): string {
        return conf.storages.S3SelectelPM.url + '/avatar/x100/' + `${fileName}`;
    }

    /**
     * Урл картинки аватарки по fileName
     * @param fileName
     */
    public fAvatarImageUrlx100(fileName: string): string {
        return conf.storages.S3SelectelPM.url + `/avatar/x100/` + `${fileName}`;
    }

    /**
     * Урл баннера лендинга
     * @param filename
     * @returns
     */
    public fLandingBannerUrl(filename: string) {
        return conf.storages.S3Selectel.url + '/landing/' + `${filename}.jpg`;
    }

    /**
	 * Урл картинки акции по fileName
	 * @param fileName
	 */
	public fPromotionImageUrl(fileName: string): string {
		return conf.storages.S3Selectel.url + '/auto_action/' + `${fileName}.jpg`;
	}

    /**
	 * Урл картинки категории по fileName
	 * @param fileName
	 */
	public fLandingImageUrl(fileName: string): string {
		return conf.storages.S3Selectel.url + '/landing/' + `${fileName}.png`;
	}

    /**
	 * Урл картинки категории по fileName
	 * @param fileName
	 */
	public fLandingImageUrlWebp(fileName: string): string {
		return conf.storages.S3Selectel.url + '/landing/' + `${fileName}.webp`;
	}
}

export const gImageS = new ImageS();

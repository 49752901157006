<script lang="ts">
import P63Vue from '@/system/P63Vue';
import Component from 'vue-class-component';
import HomeBtn from '@/pages/home/el/home-btn/home-btn';
import { HomeCtrl, initHomeCtrl } from '../../view/ctrl-home';
import { mIsClient } from '@/common/helpers/ContextH';

@Component({
    components: {
        HomeBtn,
    },
})
export default class RegBlockD extends P63Vue {
    /** Отправить данные в статистику о клике по кнопке "Зарегистрироваться" */
    fSendActionRegClick() {
        this.ctrl.statSys.faPushClickToStat({
            page: location.pathname,
            type: 'button_new',
            alias: 'button_click_reg_new',
            entity_id: 0,
        });
    }

    /** Хранилище */
    get ctrl(): HomeCtrl {
        return initHomeCtrl(mIsClient() ? this.$context : this.$ssrContext);
    }
}
</script>

<template>
    <div class="reg-block-d">
        <div class="reg-block-d__content">
            <h4 class="reg-block-d__title">Зарегистрируйтесь и начните экономить!</h4>
            <p class="reg-block-d__info">
                Получите доступ к популярным брендам по оптовой цене. Выгода до 90% от цены других маркетплейсов!
            </p>
        </div>
        <a class="reg-block-d__button" href="/auth#/register/" :target="$sTargetLink" @click="fSendActionRegClick">
            <HomeBtn>Быстрая регистрация</HomeBtn>
        </a>
    </div>
</template>

<style lang="scss" scoped>
p,
h4 {
    margin: 0;
    padding: 0;
}

.reg-block-d {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fafafa;
    border-radius: 16px;
    margin-bottom: -24px;
    padding: 24px;

    @media (max-width: 1439px) {
            padding: 32px 24px;
        }
        @media (max-width: 949px) {
            padding: 24px;
        }

    &__content {
        @media (max-width: 1439px) {
            max-width: 501px;
        }
        @media (max-width: 949px) {
            max-width: 450px;
        }
    }

    &__title {
        color: #2b2a29;
        font-size: 22px;
        font-weight: 600;
        line-height: 135%;
        margin-bottom: 4px;

        @media (max-width: 949px) {
            font-size: 16px;
        }
    }

    &__info {
        color: #606266;
        font-size: 16px;
        font-weight: 400;
        line-height: 150%;

        @media (max-width: 949px) {
            font-size: 14px;
        }
    }

    &__button {
        button {
            padding: 14px 24px;
            font-size: 22px;
            width: 408px;

            @media (max-width: 1439px) {
                width: 371px;
            }
            @media (max-width: 949px) {
                padding: 10px 24px;
                font-size: 14px;
                width: 306px;
        }
        }
    }
}
</style>

<script lang="ts">
import Component from 'vue-class-component';
import ArrowButtonSource from './arrow-button-source';

@Component
export default class ArrowButton extends ArrowButtonSource {}
</script>

<template>
    <button
        class="arrow"
        type="button"
        :aria-label="bLeftArrow ? 'Предыдущий слайд' : 'Следующий слайд'"
        @click.stop="() => fChangeElement(sArrowType)"
    >
        <div class="arrow__image" :class="{ arrow__image_left: bLeftArrow }" role="img" aria-hidden="true">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M14.13 9.25H2.848v1.5H14.13L8.7 16.18l1.06 1.061L17 9.999 9.76 2.758 8.7 3.818l5.43 5.431z" />
            </svg>
        </div>
    </button>
</template>

<style lang="scss" scoped>
.arrow {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #fff;
    position: absolute;
    z-index: 50;
    top: 50%;
    transform: translateY(-50%) !important;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.06), 0 2px 4px 0 rgba(0, 0, 0, 0.12);

    &__image {
        width: 20px;
        height: 20px;
        &_left {
            transform: rotate(180deg);
        }
    }
}
</style>

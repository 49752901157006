import { QuerySys, VuexSys } from '@63pokupki/front';
import { AxiosRequestConfig } from 'axios';
import keyBy from 'lodash/keyBy';
import groupBy from 'lodash/groupBy';

import * as conf from '@/configs/config.public';
import { PageCtrl } from '@/system/PageCtrl';
import { gfInitContextCtrl, gfInitContextQuery, gfServerContextCtrl } from '@/system/ContextSys';
import { DeliveryPageR } from '@/ifc/core/DeliveryPageR';
import { P63HomeSeoI } from '@/ifc/core/EntitySQL/P63HomeSeoE';
import { BrandInfoI } from '@/ifc/core/EntitySQL/BrandE';
import { BannerI } from '@/ifc/core/CommonI/BannerI';
import { HomePageR } from '@/ifc/core/HomePageR';
import { TopSelectionN } from '@/common/interface/TopSelectionN';
import { catchErrorApiResponse } from '@/common/helpers/ErrorHandler';
import { HomeStoreI } from './vuex-home';
import { HomeN } from '@/pages/home/view/ifc-home';
import { ItemOfCollectionI, ProductOfCollectionI } from '@/ifc/core/CommonI/ItemCollectionI';
import { NewsNoAuthR } from '@/ifc/msg/NewsNoAuthR';
import { P63PromoActionI } from '@/ifc/core/EntitySQL/P63PromoActionE';
import { P63promoActionFunctionKeyPurchaseI } from '@/ifc/core/EntitySQL/P63PromoActionFunctionPurchasseE';
import { merge } from 'lodash';

export class HomeCtrl extends PageCtrl {
    public cmd: HomeStoreI.Cmd = null;
    public status: HomeStoreI.Status = null;
    public one: HomeStoreI.One = null;
    public list: HomeStoreI.List = null;
    public tree: HomeStoreI.Tree = null;
    public error: HomeStoreI.Error = null;
    public ix: HomeStoreI.Ix = null;

    private queryCoreSys: QuerySys;
    private queryMsgSys: QuerySys;

    constructor(
        vuexSys: VuexSys,
        optionCtrl?: {
            preserveState: boolean; // сохранять состояние на сервере
        }
    ) {
        super(vuexSys, optionCtrl);

        // Конфигурация запроса на core
        this.queryCoreSys = new QuerySys();
        const options: AxiosRequestConfig = {};
        this.queryCoreSys.fConfig(gfInitContextQuery(this, conf.coreApi));

        // Конфигурация запроса на msg
        this.queryMsgSys = new QuerySys();
        this.queryMsgSys.fConfig(gfInitContextQuery(this, conf.msgAPI));

        let optionVuex = null;
        if (optionCtrl?.preserveState) {
            optionVuex = {
                preserveState: true, // сохранять состояние на сервере
            };
        }

        this.ix = vuexSys.registerModuleIx(new HomeStoreI.Ix(), optionVuex);
        this.cmd = vuexSys.registerModuleCmd(new HomeStoreI.Cmd(), optionVuex);
        this.status = vuexSys.registerModuleStatus(new HomeStoreI.Status(), optionVuex);
        this.one = vuexSys.registerModuleOne(new HomeStoreI.One(), optionVuex);
        this.list = vuexSys.registerModuleList(new HomeStoreI.List(), optionVuex);
        this.tree = vuexSys.registerModuleTree(new HomeStoreI.Tree(), optionVuex);
        this.error = vuexSys.registerModuleError(new HomeStoreI.Error(), optionVuex);
    }

    /** Инициализировать страницу */
    public async faInit() {
        // Основные запросы
        await Promise.all([
            super.faInit(),
            this.faGetBrands(),
            this.fGetNews(),
            this.faGetSeo(),
            this.faGetDeliveryList(),
            this.faGetListBanner(),
            this.faGetCurrentStats(),
            this.faGetReviewList(),
            this.faGetListCollection(),
            this.faGetListBannerCollection(),
            this.faGetItemOfDayCollection(),
            this.faInitRecommendations(),
        ]);

        if (this.userSys.user.bIsPartialBlock && this.userSys.user.isAuth) {
            this.status.is_init = false;
            return;
        }

        // Получить товары подборок после получения списка подборок
        const aSortedSelectionByRating = this.list.top_selection_sorted_by_rating;
        const aPromise = [];

        for (let i = 0; i < this.status.initial_selection_count; i++) {
            const vSelection = aSortedSelectionByRating[i];

            if (vSelection) {
                aPromise.push(this.faGetListItemOfCollection(vSelection.id));
            }
        }

        await Promise.all(aPromise);

        this.status.is_init = true;
    }

    /** Получить подборки баннеров (коллекции) */
    public async faGetListBannerCollection() {
        this.queryCoreSys.fInit();
        this.queryCoreSys.fActionOk((data: HomePageR.getListBannerCollection.ResponseI) => {
            this.list.all_banners_collection = data.list_bnr_collection;
            this.list.all_banners_of_collection = data.list_bnr_of_collection;
            this.list.bnr_collection_read_id = data.list_bnr_collection_read_id;
            if (this.list.bnr_collection_read_id.length) {
                this.ix.checked_story = Object.fromEntries(
                    data.list_bnr_collection_read_id.map((idBanner) => [idBanner, true])
                );
            }
            this.ix.banners_collection = keyBy(data.list_bnr_collection, 'id');
            this.ix.banners_of_collection = groupBy(data.list_bnr_of_collection, 'bnr_collection_id');
        });
        this.queryCoreSys.fActionErr((err: any) => {
            catchErrorApiResponse({
                error: new Error('Ошибка получения баннеров'),
                response: err,
                meta: 'faGetListBanner',
            });
        });

        let request: HomePageR.getListBannerCollection.RequestI = {};
        await this.queryCoreSys.faSend(HomePageR.getListBannerCollection.route, request);
    }

    /** Получить коллекцию товаров дня */
    public async faGetItemOfDayCollection() {
        this.queryCoreSys.fInit();
        this.queryCoreSys.fActionOk((data: HomePageR.getTopDayList.ResponseI) => {
            this.list.item_of_day_collection =
                data.list_item_top_day_collection.length > 10
                    ? data.list_item_top_day_collection.slice(0, 10)
                    : data.list_item_top_day_collection;
        });

        this.queryCoreSys.fActionErr((err: any) => {
            catchErrorApiResponse({
                error: new Error('Ошибка получения товаров дня'),
                response: err,
                meta: 'faGetItemOfDayCollection',
            });
        });

        const request: HomePageR.getTopDayList.RequestI = {};
        await this.queryCoreSys.faSend(HomePageR.getTopDayList.route, request);
    }

    /** Пометить подборку баннеров просмотренной */
    public async fMarkCollectionAsRead(request: HomePageR.markBannerCollectionAsRead.RequestI) {
        this.queryCoreSys.fInit();
        this.queryCoreSys.fActionOk((data: {}) => {});
        this.queryCoreSys.fActionErr((err: any) => {
            catchErrorApiResponse({
                error: new Error('Ошибка пометки истории как отмеченную'),
                response: err,
                meta: 'fMarkCollectionAsRead',
            });
        });
        await this.queryCoreSys.faSend(HomePageR.markBannerCollectionAsRead.route, request);
    }

    /** Получить баннеры */
    public async faGetListBanner() {
        this.queryCoreSys.fInit();
        this.queryCoreSys.fActionOk((data: { list_banner: BannerI[] }) => {
            this.list.all_banners = data.list_banner;
        });
        this.queryCoreSys.fActionErr((err: any) => {
            catchErrorApiResponse({
                error: new Error('Ошибка получения баннеров'),
                response: err,
                meta: 'faGetListBanner',
            });
        });

        let request: HomePageR.getListBanner.RequestI = {};
        await this.queryCoreSys.faSend(HomePageR.getListBanner.route, request);
    }

    /** Получить текущие показатели для информационного блока */
    public async faGetCurrentStats() {
        this.queryCoreSys.fInit();
        this.queryCoreSys.fActionOk((data: HomePageR.getCurrentStats.ResponseI) => {
            this.one.counts = data;
        });
        this.queryCoreSys.fActionErr((err: any) => {
            catchErrorApiResponse({
                error: new Error('Ошибка получения баннеров'),
                response: err,
                meta: 'faGetCurrentStats',
            });
        });

        let request: HomePageR.getCurrentStats.RequestI = {};
        await this.queryCoreSys.faSend(HomePageR.getCurrentStats.route, request);
    }

    /** Получить коллекцию рекомендаций */
    public async faInitRecommendations() {
        this.queryCoreSys.fInit();
        this.queryCoreSys.fActionOk((data: HomePageR.getInitialSalesHits.ResponseI) => {
            this.status.cache = data.cache;
            const nRecommendationsCount: number = data.cnt_product_of_recommendation + data.cnt_item_of_recommendation;

            this.status.id_first_collection = [
                ...data.list_category_product,
                ...data.list_category_item,
            ][0].collection_id;

            this.list.collection = data.list_collection;
            this.ix.tabs_collection = keyBy(data.list_collection, 'id');

            this.list.recommendations = [...data.list_recommendation_product, ...data.list_recommendation_item];

            // Количества товаров в рекомендациях
            this.one.recommendations_items_count = {
                all: nRecommendationsCount,
                current: data.list_recommendation_item?.length + data.list_recommendation_product?.length,
            };

            if (this.status.id_first_collection) {
                this.list.collection_items = [...data.list_category_product, ...data.list_category_item];

                if (!this.ix.collection_items[this.status.id_first_collection]) {
                    this.ix.collection_items[this.status.id_first_collection] = [
                        ...data.list_category_product,
                        ...data.list_category_item,
                    ];
                } else {
                    this.ix.collection_items[this.status.id_first_collection].push(
                        ...data.list_category_product,
                        ...data.list_category_item
                    );
                }

                // Количество товаров в коллекции
                this.ix.collection_items_count[this.status.id_first_collection] = {
                    current: this.ix.collection_items[this.status.id_first_collection].length,
                    all: data.cnt_item_of_collection + data.cnt_product_of_collection,
                };
            }

            // Добавление в исключения
            // Рекомендации
            if (nRecommendationsCount >= 12) {
                let aItemExcludes: number[] = [];
                let aProductExcludes: number[] = [];

                for (let i = 0; i < data.list_recommendation_item.length; i++) {
                    aItemExcludes.push(data.list_recommendation_item[i].item_id);
                }

                for (let i = 0; i < data.list_recommendation_product.length; i++) {
                    aProductExcludes.push(data.list_recommendation_product[i].product_id);
                }

                this.one.recommendations_excludes = {
                    items: aItemExcludes,
                    products: aProductExcludes,
                };
            } 
            if (this.ix.collection_items_count[this.status.id_first_collection]?.all >= 12) {
                // Коллекция
                let aItemExcludes: number[] = [];
                let aProductExcludes: number[] = [];

                if (data.list_category_item.length) {
                    for (let i = 0; i < data.list_category_item.length; i++) {
                        aItemExcludes.push(data.list_category_item[i].item_id);
                    }
                }

                if (data.list_category_product.length) {
                    for (let i = 0; i < data.list_category_product.length; i++) {
                        aProductExcludes.push(data.list_category_product[i].product_id);
                    }
                }

                this.ix.collection_excludes[this.status.id_first_collection] = {
                    item: aItemExcludes,
                    product: aProductExcludes,
                };

                // Акции
                this.ix.action_info = keyBy(data.list_action, 'id');
                this.ix.action_by_purchase = keyBy(data.list_purchase_action_function, 'purchase_id');
            }
        });

        this.queryCoreSys.fActionErr((err: any) => {
            catchErrorApiResponse({
                error: new Error('Ошибка инициализации рекомендаций/хитов продаж'),
                response: err,
                meta: 'faInitRecommendations',
            });
        });

        const request = {};

        await this.queryCoreSys.faSend(HomePageR.getInitialSalesHits.route, request);
    }

    /** Получить информацию о последних отзывах со страницы "Клиенты рекомендуют" */
    public async faGetReviewList() {
        this.queryCoreSys.fInit();
        this.queryCoreSys.fActionOk((data: HomePageR.getReviewList.ResponseI) => {
            this.ix.review = keyBy(data.list_review, 'post_id');
            this.ix.item = keyBy(data.list_items, 'item_id');
            this.ix.post = keyBy(data.list_post, 'id');
            this.ix.post_image = groupBy(data.list_post_image, 'post_id');
            this.ix.user = keyBy(data.list_user, 'user_id');
            this.ix.user_avatar = keyBy(data.list_user_avatar, 'user_id');
        });

        this.queryCoreSys.fActionErr((err: any) => {
            catchErrorApiResponse({
                error: new Error('Ошибка получения информации об отзывах'),
                response: err,
                meta: 'faGetReviewList',
            });
        });

        const request: HomePageR.getReviewList.RequestI = {};
        await this.queryCoreSys.faSend(HomePageR.getReviewList.route, request);
    }

    /** Получить новости */
    async fGetNews() {
        this.queryMsgSys.fInit();
        this.queryMsgSys.fActionOk((data: NewsNoAuthR.getNewsList.ResponseI) => {
            // Получить только те новости, у которых есть заполненный заголовок
            this.list.all_news = data.news_list.filter((vNews) => vNews.title);
            this.list.news_images = data.list_post_img;
        });
        this.queryMsgSys.fActionErr((err) => {
            catchErrorApiResponse({
                error: new Error('Ошибка получения новостей'),
                response: err,
                meta: 'fGetNewsList',
            });
        });

        // Загрузить последние 4 отбражаемых новости
        const request: NewsNoAuthR.getNewsList.RequestI = {
            page: 0,
            limit: 4,
            only_visible: true,
        };
        await this.queryMsgSys.faSend(NewsNoAuthR.getNewsList.route, request);
    }

    /** Получить бренды */
    public async faGetBrands() {
        this.queryCoreSys.fInit();
        this.queryCoreSys.fActionOk((data: { list_brand: BrandInfoI[] }) => {
            const aListBrand = data.list_brand;
            const iIndexOfBeginUrl = 21;

            for (let i = 0; i < aListBrand.length; i++) {
                aListBrand[i].url = aListBrand[i].url.slice(iIndexOfBeginUrl);
            }
            this.list.brands = aListBrand;
        });
        this.queryCoreSys.fActionErr((err: any) => {
            catchErrorApiResponse({
                error: new Error('Ошибка получения брендов'),
                response: err,
                meta: 'faGetBrands',
            });
        });

        let request: HomePageR.getListBrand.RequestI = {};
        await this.queryCoreSys.faSend(HomePageR.getListBrand.route, request);
    }

    /** Получить сео поля */
    public async faGetSeo() {
        this.queryCoreSys.fInit();
        this.queryCoreSys.fActionOk((data: { seo: P63HomeSeoI }) => {
            this.status.store_title = data.seo.title;
            this.status.store_description = data.seo.description;
            this.status.seo_info = JSON.parse(data.seo.seo_text);
        });
        this.queryCoreSys.fActionErr((err: any) => {
            catchErrorApiResponse({
                error: new Error('Ошибка получения сео полей'),
                response: err,
                meta: 'faGetSeo',
            });
        });

        let request: HomePageR.getSeo.RequestI = {};
        await this.queryCoreSys.faSend(HomePageR.getSeo.route, request);
    }

    /** Получить города */
    public async faGetDeliveryList() {
        this.queryCoreSys.fInit();
        this.queryCoreSys.fActionOk((data: HomeN.CityI[]) => {
            this.list.city = data;
        });
        this.queryCoreSys.fActionErr((err: any) => {
            catchErrorApiResponse({
                error: new Error('Ошибка получения список городов'),
                response: err,
                meta: 'faGetDeliveryList',
            });
        });

        let request: DeliveryPageR.getDeliveryList.RequestI = {};
        await this.queryCoreSys.faSend(DeliveryPageR.getDeliveryList.route, request);
    }

    /** Зарегистрировать устройства пользователя для уведомений */
    public async faSubscribeUserDevice(vUserDevice: HomePageR.userDeviceSubscribe.RequestI) {
        this.queryCoreSys.fInit();

        this.queryCoreSys.fActionOk((data: HomePageR.userDeviceSubscribe.ResponseI) => {});

        this.queryCoreSys.fActionErr((err: any) => {
            catchErrorApiResponse({
                error: new Error('Ошибка подписки устройства пользователя'),
                response: err,
                meta: 'faSubscribeUserDevice',
            });
        });

        await this.queryCoreSys.faSend(HomePageR.userDeviceSubscribe.route, vUserDevice);
    }

    /** Получить информацию по подборкам */
    public async faGetListCollection() {
        const request: HomePageR.getListCollection.RequestI = {};

        this.queryCoreSys.fInit();
        this.queryCoreSys.fActionOk((data: HomePageR.getListCollection.ResponseI) => {
            // Получить список топ-подборок, отсортированный по рейтингу на сервере,
            // а затем отфильтровать по признаку "распродажа"
            this.list.top_selection_sorted_by_rating = data.collection.filter((select) => select.type !== 'sale');

            // Получить объект со свойствами вида 'позиция топ-подборки в рейтинге': 'id топ-подборки',
            // например: {'0': 12, '1': 5, '2': 9 }
            for (let i = 0; i < this.list.top_selection_sorted_by_rating.length; i++) {
                this.ix.top_selection_id_by_rating[i] = this.list.top_selection_sorted_by_rating[i].id;
            }
        });

        this.queryCoreSys.fActionErr((err: any) => {
            catchErrorApiResponse({
                error: new Error('Ошибка получения подборок'),
                response: err,
                meta: 'faGetListCollection',
            });
        });

        await this.queryCoreSys.faSend(HomePageR.getListCollection.route, request);
    }

    /** Получить список рекомендаций */
    public async fGetRecommendations() {
        this.queryCoreSys.fInit();

        const request: HomePageR.getRecommendationList.RequestI = {
            list_exclude_item_id: this.one.recommendations_excludes.items,
            list_exclude_product_id: this.one.recommendations_excludes.products,
            cache: this.status.cache,
        };

        this.queryCoreSys.fInit();

        this.queryCoreSys.fActionOk((data: HomePageR.getRecommendationList.ResponseI) => {
            // Добавляем полученные товары в исключения для повторного запроса
            if (data.list_item) {
                for (let i = 0; i < data.list_item.length; i++) {
                    this.one.recommendations_excludes.items.push(data.list_item[i].item_id);
                }
            }
            // Добавляем полученные продукты в исключения для повторного запроса
            if (data.list_product) {
                for (let i = 0; i < data.list_product.length; i++) {
                    this.one.recommendations_excludes.products.push(data.list_product[i].product_id);
                }
            }
            this.list.recommendations = [...this.list.recommendations, ...data.list_product, ...data.list_item];

            // Акции
            // полученные в этом запросе акции
            const newActions: Record<number, P63PromoActionI> = keyBy(data.list_action, 'id');
            const newActionByPurchase: Record<number, P63promoActionFunctionKeyPurchaseI> = keyBy(
                data.list_purchase_action_function,
                'purchase_id'
            );
            // слияем с уже имеющимися
            this.ix.action_info = merge(newActions, this.ix.action_info);
            this.ix.action_by_purchase = merge(newActionByPurchase, this.ix.action_by_purchase);
            // увеличиваем количество загруженных товаров/продуктов
            this.one.recommendations_items_count.current += data.list_item.length + data.list_product.length;
        });

        this.queryCoreSys.fActionErr((err: any) => {
            catchErrorApiResponse({
                error: new Error('Ошибка получения рекомендаций'),
                response: err,
                meta: 'fGetRecommendations',
            });
        });

        await this.queryCoreSys.faSend(HomePageR.getRecommendationList.route, request);
    }

    /** Получить товары коллекции по id */
    public async faGetListItemOfCollectionNew(idCollection: number) {
        this.queryCoreSys.fInit();

        this.queryCoreSys.fActionOk((data: HomePageR.getListItemOfCollectionNew.ResponseI) => {
            let excludeItems: number[] = [];
            let excludeProducts: number[] = [];
            // Добавляем полученные товары в исключения
            if (data.cnt_item_of_collection) {
                for (let i = 0; i < data.list_item.length; i++) {
                    excludeItems.push(data.list_item[i].item_id);
                }
            }
            // Добавляем полученные продукты в исключения
            if (data.cnt_product_of_collection) {
                for (let i = 0; i < data.list_product.length; i++) {
                    excludeProducts.push(data.list_product[i].product_id);
                }
            }

            // Исключения из предыдущих запросов
            const oldExcludesProduct = this.ix.collection_excludes[idCollection]?.product;
            const oldExcludesItem = this.ix.collection_excludes[idCollection]?.item;

            this.ix.collection_excludes[idCollection] = {
                product: oldExcludesProduct ? [...oldExcludesProduct, ...excludeProducts] : excludeProducts,
                item: oldExcludesItem ? [...oldExcludesItem, ...excludeItems] : excludeItems,
            };

            // Записываем продукты и товары в стор
            if (!this.ix.collection_items[idCollection]) {
                this.ix.collection_items[idCollection] = [...data.list_item, ...data.list_product];
            } else {
                this.ix.collection_items[idCollection].push(...data.list_item, ...data.list_product);
            }
            // Общее количество товаров и продуктов в коллекции(all - кол-во всех товаров, current - кол-во полученных в текущем запросе)
            if (this.ix.collection_items_count[idCollection]) {
                this.ix.collection_items_count[idCollection].current +=
                    data.list_product.length + data.list_item.length;
            } else {
                this.ix.collection_items_count[idCollection] = {
                    all: data.cnt_product_of_collection + data.cnt_item_of_collection,
                    current: data.list_product.length + data.list_item.length,
                };
            }

            // Акции
            // полученные в этом запросе акции
            const newActions: Record<number, P63PromoActionI> = keyBy(data.list_action, 'id');
            const newActionByPurchase: Record<number, P63promoActionFunctionKeyPurchaseI> = keyBy(
                data.list_purchase_action_function,
                'purchase_id'
            );
            // слияем с уже имеющимися
            this.ix.action_info = merge(newActions, this.ix.action_info);
            this.ix.action_by_purchase = merge(newActionByPurchase, this.ix.action_by_purchase);
        });

        this.queryCoreSys.fActionErr((err: any) => {
            catchErrorApiResponse({
                error: new Error('Ошибка получения товаров коллекции'),
                response: err,
                meta: 'faGetListItemOfCollectionNew',
            });
        });

        const request: HomePageR.getListItemOfCollectionNew.RequestI = {
            collection_id: idCollection,
            list_exclude_item_id: this.ix.collection_excludes[idCollection]?.item,
            list_exclude_product_id: this.ix.collection_excludes[idCollection]?.product,
            cache: this.status.cache,
        };

        await this.queryCoreSys.faSend(HomePageR.getListItemOfCollectionNew.route, request);
    }

    /** Получить товары для одной подборки */
    // Для разработчика: проверить аналогичный метод в Hit Category page, если вносятся изменения
    public async faGetListItemOfCollection(idCollection: number) {
        const request: HomePageR.getListItemOfCollection.RequestI = {
            collection_id: idCollection,
        };

        // Добавить кэш в повторный запрос
        if (this.tree.ix_top_selection[idCollection]?.cache) {
            request.cache = this.tree.ix_top_selection[idCollection].cache;
        }

        // Добавить исключаемые товары в повторный запрос
        if (this.tree.ix_top_selection[idCollection]?.list_item) {
            const vExecludeItem: {
                execlude_item: number[];
                execlude_product: number[];
            } = this.tree.ix_top_selection[idCollection].list_item.reduce(
                (accummulator, vItem) => {
                    if ('item_id' in vItem) {
                        accummulator.execlude_item.push(vItem.item_id);
                    } else {
                        accummulator.execlude_product.push((vItem as ProductOfCollectionI).product_id);
                    }
                    return accummulator;
                },
                { execlude_item: [], execlude_product: [] }
            );

            request.list_exclude_item_id = vExecludeItem.execlude_item;
            request.list_exclude_product_id = vExecludeItem.execlude_product;
        }

        this.status.is_selection_items_loading = true;

        this.queryCoreSys.fInit();

        this.queryCoreSys.fActionOk((data: HomePageR.getListItemOfCollection.ResponseI) => {
            /** Товары и продукты подборки */
            const aItemsAndProducts: (ItemOfCollectionI | ProductOfCollectionI)[] = [
                ...data.list_product,
                ...data.list_item,
            ];
            const nCountItemOfCollection = data.cnt_item_of_collection + data.cnt_product_of_collection;

            if (!this.tree.ix_top_selection[idCollection]) {
                // Создать объект с данными при первом запросе
                this.tree.ix_top_selection = {
                    ...this.tree.ix_top_selection,
                    [idCollection]: {
                        list_item: aItemsAndProducts.slice(0, TopSelectionN.SelectionItemCountT.one_section),
                        cache: data.cache,
                        cnt_item_of_collection: nCountItemOfCollection,
                    },
                };
            } else {
                // Обновить данные при повторном запросе
                this.tree.ix_top_selection[idCollection].list_item.push(
                    ...aItemsAndProducts.slice(0, TopSelectionN.SelectionItemCountT.one_section)
                );
                this.tree.ix_top_selection[idCollection].cnt_item_of_collection = nCountItemOfCollection;
            }

            // Увеличить количество отображаемых подборок, когда начала загружаться новая топ-подборка
            if (this.status.is_selections_loading) {
                this.status.displayed_top_selection_count += 1;

                this.status.is_selections_loading = false;
            }

            this.status.is_selection_items_loading = false;
        });

        this.queryCoreSys.fActionErr((err: any) => {
            catchErrorApiResponse({
                error: new Error('Ошибка получения подборок'),
                response: err,
                meta: 'faGetListItemOfCollection',
            });

            this.status.is_selections_loading = false;

            this.status.is_selection_items_loading = false;
        });

        await this.queryCoreSys.faSend(HomePageR.getListItemOfCollection.route, request);
    }
}

// Объект конекст серверный и фронтовый, аналог синглтона
export function initHomeCtrl(store: any): HomeCtrl {
    return gfInitContextCtrl(store, HomeCtrl);
}

export function serverHomeCtrl(ctx: any): HomeCtrl {
    return gfServerContextCtrl(ctx, HomeCtrl);
}

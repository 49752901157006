import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import P63Vue from '@/system/P63Vue';
import { PageCtrl } from '@/system/PageCtrl';
import { gfInitContextCtrl } from '@/system/ContextSys';
import { ProductOfCollectionI } from '@/ifc/core/CommonI/ItemCollectionI';
import { mIsClient } from '@/common/helpers/ContextH';
import { gLinkS } from '@/common/service/LinkS';
import { gImageS } from '@/common/service/ImageS';
import { mPeriodName, mPluralize } from '@/common/helpers/TextFormatterH';
import { ItemCardN } from '@/common/interface/ItemCardN';
import { mFormatPriceNoCoins } from '@/common/helpers/PriceFormatterH';
import button_favorite from '@/ds/el/button_favorite/button_favorite';
import tooltip from '@/ds/el/tooltip/tooltip';
import dayjs from 'dayjs';

@Component({
    components: {
        ButtonFavorite: button_favorite,
        Tooltip: tooltip,
    }
})
export default class ProductCard extends P63Vue {
    /** Продукт */
    @Prop({ type: Object, required: false }) vProduct: ProductOfCollectionI;
    /** Индекс товара */
    @Prop({ type: Number, required: false }) nIndex: number;
    /** Является ли карточка заглушкой */
    @Prop({ type: Boolean, required: false }) bPlug: boolean;
    /** Нужно ли загружать изображение карточки синхронно */
    @Prop({ type: Boolean, required: false }) bSync: boolean;
    /** Находится ли карточка в подборке на главной странице */
    @Prop({ type: Boolean, required: false }) bFromHome: boolean;

    /** Обработать нажатие на карточку */
    fOnClick(): void {
        const vEmitData: ItemCardN.CardClickArgI = {
            i: this.nIndex,
            item: this.vProduct,
            callbackUrl: this.sProductUrl,
        }
        this.$emit('card-click', vEmitData);
    }

    /** Подобрать правильную форму для слова «раз» */
    fGetPluralizedOneWord(number: number): string {
        return mPluralize(number, ['раз', 'раза', 'раз']);
    }

    /** Минимальная цена продукта с орг сбором */
    get nPriceWithOrgFee() {
        return this.vProduct.min_price * (1 + this.vProduct.org_fee / 100);
    }

    /** Полная стоимость */
    get sPrice() {
        const nPriceWithOrgFee = mFormatPriceNoCoins(this.nPriceWithOrgFee);
        let sFullPrice: string = "";
        // На главной странице "От" не пишется
        if (this.vProduct.cnt_item > 1 && !this.bFromHome) {
            sFullPrice = `От ${nPriceWithOrgFee} ₽`
        } else {
            sFullPrice = `${nPriceWithOrgFee} ₽`
        }
        return sFullPrice;
    }

    /** Старая цена товара */
    get sOldPrice() {
        return mFormatPriceNoCoins(this.nOldPricetWithOrgFee);
    }

    /** Старая цена с орг сбором */
    get nOldPricetWithOrgFee() {
        return this.vProduct.old_price * (1 + this.vProduct.org_fee / 100);
    }

    /** Количество заказов */
    get sCountOrder(){
        const sPeriod = this.fGetCorrectPeriodName(this.vProduct.first_order_date)
        return `${this.vProduct.cnt_order} ${this.fGetPluralizedOneWord(this.vProduct.cnt_order)} ${sPeriod}`
    }

    /** Корректное именование времени */
    fGetCorrectPeriodName(sFirstOrderDate: string) {
        const nCurrentTime = dayjs().valueOf() //текущее
        const nFirstOrderTime = dayjs(sFirstOrderDate?.replace(/-/g,'/')).valueOf()
        const nTotalDays = Math.floor((nCurrentTime - nFirstOrderTime) / (1000 * 3600 * 24))
        const nReturnDays = nTotalDays ? mPeriodName(nTotalDays) : mPeriodName(1)

        return `купили за ${nReturnDays}`
    }

    /** Добавить товар в список желаний, если пользователь залогинен */
    fAddToWishList(idProduct: number) {
        if (this.bIsAuth) {
            this.ctrl.wishSys.faAddToWishList(null,idProduct);
        } else {
            this.ctrl.status.is_modal_user_not_sign_in = true;
        }
    }

    /** Ссылка на изображение продукта */
    get sProductImgUrl(){
        return gImageS.fItemImageUrlx256(this.vProduct.filename);
    }

    /** Ссылка на продукт */
    get sProductUrl(){
        return gLinkS.fProductUrl(this.vProduct.product_id, this.vProduct.name);
    }

    /** Проверка, что к продукту привязан только 1 товар */
    get isOneItemInProduct() {
        return this.vProduct.cnt_item === 1;
    }

    /** Орг сбор */
    get sOrgFee() {
        return this.isOneItemInProduct ? `${this.vProduct.org_fee} %` : null
    }

    /** Показывать старую цену только в том случае, если привязан 1 товар и разница между новой и старой больше 10 */
    get isShowOldPrice() {
        let isShowOldPrice = false;
        if (this.isOneItemInProduct && this.vProduct.old_price) {
            const nDiffPrice = Math.round(((this.nOldPricetWithOrgFee - this.nPriceWithOrgFee) / this.nOldPricetWithOrgFee) * 100);
            isShowOldPrice = nDiffPrice >= 10;
        }
        return isShowOldPrice;
    }

    /** Статус авторизации пользователя */
    get bIsAuth() {
        return this.ctrl.userSys.user.isAuth;
    }

    /** Товары, добавленные в список желаний */
    get ixWishItems() {
        return this.ctrl.wishSys.wish.wish_items;
    }

    /** Тултип кнопки добавления в список желаний */
    get sTooltipBtnFavorite() {
        return this.ixWishItems[this.vProduct.product_id] ? 'Убрать из списка желаний' : 'Добавить в список желаний';
    }

    /** Хранилище */
    get ctrl(): PageCtrl {
        return gfInitContextCtrl(mIsClient() ? this.$context : this.$ssrContext, PageCtrl);
    }
}

import { ItemOfCollectionI, ProductOfCollectionI } from '@/ifc/core/CommonI/ItemCollectionI';

/** Интерфейсы для работы с подборками */
export namespace TopSelectionN {
    /** Интерфейс подборки на основе HomePageR.getListItemOfCollection */
    export interface TopSelectionI {
        /** Кэш для повторной выборки */
        cache?: number;
        /** Товары */
        list_item?: (ItemOfCollectionI | ProductOfCollectionI)[];
        /** Количество оставшихся товаров */
        cnt_item_of_collection?: number;
    }

    /** Количество товаров в топ-подборках */
    export enum SelectionItemCountT {
        /** в одной секции */
        one_section = 12,
    }
}

import { PurchaseI } from './EntitySpSQL/PurchaseE';
import { DocumentTopicI } from './EntitySQL/DocumentTopicE';
import { PostI } from './EntitySQL/PostE';
import { TopicI, TopicT } from './EntitySQL/TopicE';

/** CRUD по темам */
export namespace TopicR {
    /** Получить список тем по фильтру с пагинацией */
    export namespace getTopicListByFilter {
        /** APIURL */
        export const route = '/topic/get-topic-list-by-filter';

        /** Параметры api запроса */
        export interface RequestI {
            page?: number; // Страница
            limit?: number; // Количество на странице
            filter_date?: string; // Фильтр по дате
            filter_type?: TopicT; // Фильтр по типу темы
            filter_closed?: boolean; // Фильтр по статусу темы (открыта/закрыта)
        }

        /** Параметры api ответа */
        export interface ResponseI {
            topic_list: TopicI[]; // Список тем
            topic_order_list: DocumentTopicI[]; // Список тем с их местоположением
            // Пагинация
            topic_count: {
                curr_page: number; // Текущая страница
                topic_total: number; // Всего тем
                page_total: number; // Всего страниц
                page_limit: number; // Всего количество на странице
            };
        }
    }
    /** Получить тему */
    export namespace getTopic {
        /** APIURL */
        export const route = '/topic/get';

        /** Параметры api запроса */
        export interface RequestI {
            topic_id: number; // ID темы
        }

        /** Параметры api ответа */
        export interface ResponseI {
            topic: TopicI; // Тема
            post_list: PostI[]; // Список постов из темы
        }
    }

    /** Создать обычную тему */
    export namespace createTopic {
        /** APIURL */
        export const route = '/topic/create';

        /** Параметры api запроса */
        export interface RequestI {
            title: string; // Заголовок темы и первого поста в ней
            text: string; // Текст первого поста в теме
        }

        /** Параметры api ответа */
        export interface ResponseI {
            topic: TopicI; // Тема
            post: PostI; // Пост
        }
    }

    /** Создать тему-заявку */
    export namespace createAppealTopic {
        /** APIURL */
        export const route = '/topic/create-appeal-topic';

        /** Параметры api запроса */
        export interface RequestI {
            title: string; // Заголовок темы
        }

        /** Параметры api ответа */
        export interface ResponseI {
            topic: TopicI; // Тема
        }
    }

    /** Создать тему-жалобу */
    export namespace createClaimTopic {
        /** APIURL */
        export const route = '/topic/create-claim-topic';

        /** Параметры api запроса */
        export interface RequestI {
            title: string; // Заголовок темы
        }

        /** Параметры api ответа */
        export interface ResponseI {
            topic: TopicI; // Тема
        }
    }


    /** Создать тему-отзыв */
    export namespace createReviewTopic {
        /** APIURL */
        export const route = '/topic/create-review-topic';

        /** Параметры api запроса */
        export interface RequestI {
            title: string; // Заголовок темы и первого поста в ней
            text: string; // Текст первого поста в теме
            stock_id: number; // ID шаблона закупки
        }

        /** Параметры api ответа */
        export interface ResponseI {
            topic: TopicI; // Тема
            post: PostI; // Пост
        }
    }

    /** Создать тему-документацию */
    export namespace createDocumentTopic {
        /** APIURL */
        export const route = '/topic/create-document-topic';

        /** Параметры api запроса */
        export interface RequestI {
            title: string; // Заголовок темы
            alias: string; // Алиас темы
        }

        /** Параметры api ответа */
        export interface ResponseI {
            topic: TopicI; // Тема
        }
    }

    /** Закрыть тему */
    export namespace closeTopic {
        /** APIURL */
        export const route = '/topic/close';

        /** Параметры api запроса */
        export interface RequestI {
            topic_id: number; // ID темы
        }

        /** Параметры api ответа */
        export interface ResponseI {
            topic: TopicI; // Тема
        }
    }

    /** Переоткрыть тему */
    export namespace reopenTopic {
        /** APIURL */
        export const route = '/topic/reopen';

        /** Параметры api запроса */
        export interface RequestI {
            topic_id: number; // ID темы
        }

        /** Параметры api ответа */
        export interface ResponseI {
            topic: TopicI; // Тема
        }
    }

    /** Пометить тему удалённой */
    export namespace markTopicAsDeleted {
        /** APIURL */
        export const route = '/topic/mark-as-deleted';

        /** Параметры api запроса */
        export interface RequestI {
            topic_id: number; // ID темы
        }

        /** Параметры api ответа */
        export interface ResponseI {
            topic: TopicI; // Тема
        }
    }

    /** Пометить тему удалённой */
    export namespace restoreTopic {
        /** APIURL */
        export const route = '/topic/restore';

        /** Параметры api запроса */
        export interface RequestI {
            topic_id: number; // ID темы
        }

        /** Параметры api ответа */
        export interface ResponseI {
            topic: TopicI; // Тема
        }
    }

    /** Обновить тему */
    export namespace updateTopic {
        /** APIURL */
        export const route = '/topic/update';

        /** Параметры api запроса */
        export interface RequestI {
            topic_id: number; // ID темы
            title: string; // Заголовок темы и первого поста в ней
            alias?: string
        }

        /** Параметры api ответа */
        export interface ResponseI {
            topic: TopicI; // Тема
        }
    }

    /** Создать тему 'ВОПРОС-ОТВЕТ по закупке' */
    export namespace createTopicPurchaseQuestion {
        /** APIURL */
        export const route = '/topic/create-purchase-question';

        /** Параметры api запроса */
        export interface RequestI {
            purchase_id: number; // id закупки
        }

        /** Параметры api ответа */
        export interface ResponseI {
            topic: TopicI; // Тема 'ВОПРОС-ОТВЕТ по закупке'
            purchase: PurchaseI; // закупка
        }
    }

    /** Закрыть тему 'ВОПРОС-ОТВЕТ по закупке' */
    export namespace closeTopicPurchaseQuestion {
        /** APIURL */
        export const route = '/topic/close-purchase-question';

        /** Параметры api запроса */
        export interface RequestI {
            purchase_id: number; // id закупки
        }

        /** Параметры api ответа */
        export interface ResponseI {
            topic: TopicI; // Тема
        }
    }

}

import { groupBy, keyBy } from 'lodash';
import * as conf from "../../../configs/config.public";
import { TreeMenuItemCompactI } from "@/ifc/core/MenuR";
import { UploadFilePostR } from "@/ifc/img/UploadFilePostR";
import { AppealR } from "@/ifc/msg/AppealR";
import { AppealPriorityT } from "@/ifc/msg/EntitySQL/AppealE";
import { TopicI, TopicT } from "@/ifc/msg/EntitySQL/TopicE";
import { TopicR } from "@/ifc/msg/TopicR";
import { BaseCtrl, QuerySys, VuexSys } from "@63pokupki/front";
import gVuexSys from "../../system/VuexSys";
import "v-markdown-editor/dist/v-markdown-editor.css";
import { AppealTypeI } from '@/ifc/msg/EntitySQL/AppealTypeE';
import { AppealPurposeI } from '@/ifc/msg/EntitySQL/AppealPurposeE';


/** Хранилище сервиса */
namespace AppealPopupServiceStoreI {
    export class Ix {
        /** Типы заявок */
        appeal_type: Record<number, AppealTypeI> = {};
        /** Приоритеты заявок */
        appeal_priority: Record<AppealPriorityT, string> = {
            no_priority: "Без приоритета",
            insignificant: "Незначительный",
            common: "Обычный",
            serious: "Серьёзный",
            critical: "Критический",
            urgent: "Неотложный",
        };

        topics: Record<number, TopicI> = {}

        /** Связи топиков заявок и тем */
        appeal_purpose: Record<number, AppealPurposeI[]> = {};
    }

    export class Status {
        /** Информация о загрузке данных */
        is_loading: boolean = false;

        /** Информация о состоянии скролла */
        is_scroll_on: boolean = true;

        /** Открыто/Закрыто модальное окно отправки заявки */
        is_open_appeal_popup: boolean = false;

        /** Статус загрузки списка топиков */
        is_loading_topic: boolean = false;

        /** Статус загрузки файла */
        is_loading_file: boolean = false;

        /** Ошибка загрузки файла к заявке */
        error_loading_file: string = "";

        action_end = false;

        action_ok = false;
        /** Поисковый звпрос */
        query: string = '';
        /** Текст шаблона для заявки на  предложение */
        suggestionTpl: string = `**Что хочу доработать? Какой функционал?** Ссылка на страницу, которую нужно изменить/доработать.\n\n**Кто будет использовать новый функционал? Как часто? При каких условиях?**\n\n**Как этот функционал работает сейчас?**\n\n**Как это должно работать?**\n\n**Как мы оценим, что данные изменения имеют положительное влияние?**\n\n**Что произойдет, если новый функционал не будет разработан?**\n\nИнформативно: Для рассмотрения заявки, все поля должны быть заполнены. Если нет показателей (метрик) по которым можно оценить влияние, заявка будет переведена в статус «Банк идей, для будущих проектов/разработок»`;
        /** Текст шаблона для заявки на ошибку */
        reportTpl: string = `**Разовая/постоянная:** разовая\n\n**ШАГИ:*(описать последовательность действий)**\n\n**1.**\n\n**2.**\n\n**Ожидаемый результат:**\n\n**Фактический результат:**\n\n**Кого затрагивает данная ошибка:**\n\n"Сколько обращений зафиксировано на данный момент:"`;
    }

    /** Списки */
    export class List {
        /** Список элементов меню (категории) */
        menu_categories_data: TreeMenuItemCompactI[] = [];

        /** Список вложений для новой заявки */
        new_appeal_files: {
            post_file_id: number; // ID файла
            filename: string; // Уникальное имя файла
            extension?: string;
        }[] = [];

        /** Список тем */
        topics: TopicI[] = [];

        /** Список типов заявки */
        appeal_type: AppealTypeI[] = [];
    }
}

/** Сервис для работы c Header */
class AppealPopupS extends BaseCtrl {
    public conf = conf;

    public ix: AppealPopupServiceStoreI.Ix = gVuexSys.registerModuleCustom(new AppealPopupServiceStoreI.Ix());
    public status: AppealPopupServiceStoreI.Status = gVuexSys.registerModuleCustom(new AppealPopupServiceStoreI.Status());
    public list: AppealPopupServiceStoreI.List = gVuexSys.registerModuleCustom(new AppealPopupServiceStoreI.List());

    private queryClientSys: QuerySys = null;
    private queryMsgSys: QuerySys = null;
    private queryImgSys: QuerySys = null;

    constructor(vuexSys: VuexSys) {
        super(vuexSys);

        this.queryClientSys = new QuerySys();
        this.queryClientSys.fConfig(conf.coreApi);

        this.queryMsgSys = new QuerySys();
        this.queryMsgSys.fConfig(conf.msgAPI);

        this.queryImgSys = new QuerySys();
        this.queryImgSys.fConfig(conf.imgAPI);
    }

    /**
     * Получение типов заявки
     */
    public fGetAppealTypeList() {
        this.queryMsgSys.fInit();
        this.queryMsgSys.fActionOk((data: AppealR.init.ResponseI) => {
            this.ix.appeal_type = keyBy(data.list_appeal_type, 'id')
            this.list.appeal_type = data.list_appeal_type
            this.ix.appeal_purpose = groupBy(data.list_appeal_purpose, 'type_id');
        })
        this.queryMsgSys.fActionErr(err => {
            this.status.is_open_appeal_popup = false
        })
        this.queryMsgSys.fSend(AppealR.init.route, {})
    }

    /**
     * Получить список тем
     * Метод с пагинацией (получаем 100 тем которые относятся к заявкам)
     */
    public fGetTopicListByFilter(request: AppealR.getTopicByType.RequestI) {
        this.status.is_loading_topic = true;

        this.queryMsgSys.fInit();
        this.queryMsgSys.fActionOk(
            (data: AppealR.getTopicByType.ResponseI) => {
                this.list.topics = [...this.list.topics, ...data.list_topic].sort((a,b) => {
                    if(a.title < b.title) return -1
                });
                this.ix.topics = {
                    ...this.ix.topics,
                    ...keyBy(data.list_topic, 'id')
                };
                this.status.is_loading_topic = false;
            }
        );
        this.queryMsgSys.fActionErr((err) => {
            this.status.is_loading_topic = false;
        });

        this.queryMsgSys.fSend(AppealR.getTopicByType.route, request);
    }

    /**
     * Загрузить новый файл к заявке
     */
    public fUploadPostNewFile(
        request: UploadFilePostR.uploadPostFileWithoutLinkByBase64.RequestI
    ) {
        this.status.is_loading_file = true;
        this.status.error_loading_file = "";
        this.queryImgSys.fInit();
        this.queryImgSys.fActionOk(
            (
                data: UploadFilePostR.uploadPostFileWithoutLinkByBase64.ResponseI
            ) => {
                /** Проверяем повторно ли загружают файл */
                let isFound: boolean = false;
                for (let i = 0; i < this.list.new_appeal_files.length; i++) {
                    if (
                        this.list.new_appeal_files[i].post_file_id ===
                        data.post_file_id
                    ) {
                        isFound = true;
                        break;
                    }
                }
                /** Если файл загружают повторно */
                if (isFound) {
                    console.log("Файл уже загружен");
                } else {
                    this.list.new_appeal_files.push(data);
                }
                this.status.is_loading_file = false;
            }
        );
        this.queryImgSys.fActionErr((err) => {
            if (err.throw_logic) {
                this.status.error_loading_file = err.throw_logic;
            }
            this.status.is_loading_file = false;
        });

        this.queryImgSys.fSend(
            UploadFilePostR.uploadPostFileWithoutLinkByBase64.route,
            request
        );
    }

    /**
     * Создать связь вложений и поста
     */
    public fCreatePostFileLink(
        request: UploadFilePostR.createPostFileLink.RequestI
    ) {
        this.queryImgSys.fInit();
        this.queryImgSys.fActionOk(
            (data: UploadFilePostR.createPostFileLink.ResponseI) => {
                this.status.is_open_appeal_popup = false;
                this.status.is_scroll_on = true;
            }
        );
        this.queryImgSys.fActionErr((err) => {});

        this.queryImgSys.fSend(
            UploadFilePostR.createPostFileLink.route,
            request
        );
    }

    /* Создать заявку */
    public fCreateAppeal(request: AppealR.createAppeal.RequestI) {
        this.queryMsgSys.fInit();
        this.queryMsgSys.fActionOk((data: AppealR.createAppeal.ResponseI) => {
            this.status.action_end = true;
            this.status.action_ok = true;
            if (this.list.new_appeal_files.length) {
                this.fCreatePostFileLink({
                    post_id: data.post.id,
                    post_file_id_list: this.list.new_appeal_files.map(
                        (file) => file.post_file_id
                    ),
                });
            }

        });
        this.queryMsgSys.fActionErr((err) => {
            this.status.action_end = true;
            this.status.action_ok = false;
        });

        this.queryMsgSys.fSend(AppealR.createAppeal.route, request);
    }
}

export const gAppealPopupS = new AppealPopupS(gVuexSys);

import { DeviceTypeN as N } from '../../common/interface/DeviceTypeI';

/**
 * Определить типа устройства на стороне клиента.
 * Используется только при монтировании страницы.
 * @return {string} строка с типом устройства
 */
export function mDetectDeviceTypeOnClient(): string {
    const nCurrentWidthDisplay: number = window.innerWidth;
    let sDeviceType: string = N.DeviceTypeT.desktop;

    if (nCurrentWidthDisplay >= 768 && nCurrentWidthDisplay < 1024) {
        sDeviceType = N.DeviceTypeT.tablet;
    } else if (nCurrentWidthDisplay < 768) {
        sDeviceType = N.DeviceTypeT.mobile;
    }

    return sDeviceType;
}

/**
 * Получить тип устройства в формате объекта. Используется при монтировании страницы,
 * а также для часто используемых или глубоко вложенных компонентов, чтобы уйти от лишних пропсов.
 * @param {string} sTypeDevice строка с типом устройства
 * @return {N.TypeDeviceI} объект с типами устройств
 */
export function mGetDeviceType(sTypeDevice: string): N.DeviceTypeI {
    return {
        isMobile: sTypeDevice === N.DeviceTypeT.mobile,
        isTablet: sTypeDevice === N.DeviceTypeT.tablet,
        isDesktop: sTypeDevice === N.DeviceTypeT.desktop,
    };
}

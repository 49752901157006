export namespace NotificationPromptR { 
    export namespace getNotificationStatus {

        export const route = '/notification-prompt/get-notification-status';

        export interface RequestI {
            alias?: string; // Алиас уведомления, для которого отправлен запрос
        }

        export interface ResponseI {
            need_to_show?: boolean; // Необходимость показа
            alias_to_show?: string; // Алиас уведомления для показа 
        }
    }

    export namespace setStatusWhenClosed {

        export const route = '/notification-prompt/set-status-when-closed';

        export interface RequestI {
            alias?: string; // Алиас уведомления, для которого отправлен запрос
        }

        export interface ResponseI {
        }
    }
}
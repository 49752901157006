import { UtmN } from '../interface/UtmI';

export class UtmS {
    /**
     * Получение Utm
     * @param sUrl - Текущий url
     * @param vUtmInfo - Объект utm - параметров
     */
    public fGetUrlWithUtm(sUrl: string, vUtmInfo: UtmN.UtmI): string {
        let sUrlRes = sUrl;

        if (vUtmInfo[UtmN.UtmT.source]) {
            sUrlRes = this.fGetGeneratedUtm(sUrlRes, UtmN.UtmT.source, vUtmInfo[UtmN.UtmT.source]);
        }
        if (vUtmInfo[UtmN.UtmT.medium]) {
            sUrlRes = this.fGetGeneratedUtm(sUrlRes, UtmN.UtmT.medium, vUtmInfo[UtmN.UtmT.medium]);
        }
        if (vUtmInfo[UtmN.UtmT.campaign]) {
            sUrlRes = this.fGetGeneratedUtm(sUrlRes, UtmN.UtmT.campaign, vUtmInfo[UtmN.UtmT.campaign]);
        }
        if (vUtmInfo[UtmN.UtmT.content]) {
            sUrlRes = this.fGetGeneratedUtm(sUrlRes, UtmN.UtmT.content, vUtmInfo[UtmN.UtmT.content]);
        }
        if (vUtmInfo[UtmN.UtmT.term]) {
            sUrlRes = this.fGetGeneratedUtm(sUrlRes, UtmN.UtmT.term, vUtmInfo[UtmN.UtmT.term]);
        }

        return sUrlRes;
    }

    /**
     * Получение сгенерированного Utm
     * @param sUrl - Текущий utm
     * @param sUtmProp - Название свойства
     * @param sUtmPropVal - Значение свойства utm
     */
    public fGetGeneratedUtm(sUrl: string, sUtmProp: UtmN.UtmT, sUtmPropVal: string) {
        let sUtm = '';

        if (sUrl.includes('?')) {
            sUtm = sUtm + `&utm_${sUtmProp}=${sUtmPropVal}`;
        } else {
            sUtm = `?utm_${sUtmProp}=${sUtmPropVal}`;
        }
        return sUrl + sUtm;
    }
}

export const gUtmS = new UtmS();

import dayjs from 'dayjs';
require('dayjs/locale/ru');
const customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);

/** Пример: 2012-02-02 10:30:00 */
const FORMAT_DATE_FULL_TIME = 'YYYY-MM-DD HH:mm:ss';
/** Пример: 2012-02-02 */
const FORMAT_DATE_DASHS = 'YYYY-MM-DD';
/** Пример: 02.02.12, 10:30 */
const FORMAT_INVERTED_DATE_DOTS_TIME = 'DD.MM.YY, HH:mm';
/** Пример: 02.02.12 */
const FORMAT_INVERTED_DATE_DOTS = 'DD.MM.YY';
/** Пример: 10:30, 02.02.12 */
const FORMAT_TIME_INVERTED_DATE_DOTS = 'HH:mm, DD.MM.YY';
/** Пример: 02 февр. 2012, 10:30 */
const FORMAT_INVERTED_TEXT_DATE_SPACES_TIME = 'DD MMM YYYY, HH:mm';
/** Пример: 2 февраля */
const FORMAT_INVERTED_TEXT_DATE_DAY_MOUTH = 'D MMMM';
/** Пример: 2 фев */
const FORMAT_INVERTED_TEXT_DATE_DAY_SHORT_MOUTH = 'D MMM';
/** Пример: 10:30:00 */
const FORMAT_FULL_TIME = 'HH:mm:ss';
/** Пример: 15 октября 2023 */
export const FORMAT_DAY_MONTH_YEAR = 'D MMMM YYYY';

export const SQL_FORMAT_DATE_TIME = '%Y-%m-%d %H:%i:%S'; // формат даты и времени для SQL
export const SQL_FORMAT_DATE = '%Y-%m-%d'; // формат даты для SQL
export const SQL_FORMAT_TIME = '%H:%i:%S'; // формат времени для SQL

export const V_FORMAT_DATE_TIME = '/^d{4}-([0-1][0-9])-([0-3][0-9]) ([0-2][0-9]):([0-5][0-9]):([0-5][0-9])$/'; // формат даты и времени для валидатора
export const V_FORMAT_DATE = '/^d{4}-([0-1][0-9])-([0-3][0-9])$/'; // формат даты для валидатора
export const V_FORMAT_TIME = '/^([0-2][0-9]):([0-5][0-9]):([0-5][0-9])$/'; // формат времени для валидатора

/**
 * Форматирование даты в число и месяц
 * @param inp - Дата
 * @returns - string
 */
export function mFormatDateDayOfTheMonth(inp?: dayjs.ConfigType): string {
    return dayjs(inp).locale('ru').format(FORMAT_INVERTED_TEXT_DATE_DAY_MOUTH);
}

/**
 * Форматирование даты в число и сокращенное название месяца
 * @param inp - Дата
 * @returns - string
 */
export function mFormatDateDayOfTheShortMonth(inp?: dayjs.ConfigType): string {
    return dayjs(inp).locale('ru').format(FORMAT_INVERTED_TEXT_DATE_DAY_SHORT_MOUTH);
}

/**
 * Форматирование даты в число, месяц и год
 * @param inp - Дата
 * @returns - string
 */
export function mFormatDateDayOfTheMonthYear(inp?: dayjs.ConfigType): string {
    return dayjs(inp).locale('ru').format(FORMAT_DAY_MONTH_YEAR);
}

/**
 * Форматирование даты
 * @param inp - Дата
 * @returns - string
 */
export function mFormatDate(inp?: dayjs.ConfigType): string {
    return dayjs(inp).format(FORMAT_DATE_DASHS);
}

/**
 * Форматирование даты и времени
 * @param inp - Дата и время
 * @returns - string
 */
export function mFormatDateTime(inp?: dayjs.ConfigType): string {
    return dayjs(inp).format(FORMAT_DATE_FULL_TIME);
}

/**
 * Форматирование даты и времени
 * @param inp - Дата и время
 * @returns - string
 */
export function mFormatDateTimeForPostUnix(inp?: dayjs.ConfigType): string {
    return dayjs(inp).locale('ru').format(FORMAT_INVERTED_TEXT_DATE_SPACES_TIME);
}

/**
 * Форматирование даты и времени
 * @param inp - Дата и время
 * @returns - string
 */
export function mFormatTimeDateForPostUnix(inp?: dayjs.ConfigType): string {
    return dayjs(inp).locale('ru').format(FORMAT_TIME_INVERTED_DATE_DOTS);
}

/**
 * Форматирование даты и времени
 * @param inp - Дата и время
 * @returns - string
 */
export function mFormatDateYearSecondNumberForPostUnix(inp?: dayjs.ConfigType): string {
    return dayjs(inp).locale('ru').format(FORMAT_INVERTED_DATE_DOTS_TIME);
}

/**
 * Привести дату к формату `DD.MM.YY`
 * @param {dayjs.ConfigType} input - дата
 */
export function mFormatInvertedDateDotsTime(input?: dayjs.ConfigType): string {
    return dayjs(input).locale('ru').format(FORMAT_INVERTED_DATE_DOTS);
}

/**
 * Форматирование времени
 * @param inp - Время
 * @returns - string
 */
export function mFormatTime(inp?: dayjs.ConfigType): string {
    return dayjs(inp).format(FORMAT_FULL_TIME);
}

/** Секунд от начала времен */
export function mDateValue(inp?: dayjs.ConfigType): number {
    return Math.round(dayjs(inp).valueOf() / 1000);
}

/** Сделать значение времени двузначным числом, если значение меньше 10 */
export function mMakeTimeTwoDigitNumber(nValue: number): string {
    return nValue < 10 ? `0${nValue}` : String(nValue);
}

/**
 * Преобразование миллисекунд
 * Получить строку в формате часы:минуты:секунды
 * @duration - миллисекунды
 * */
export function mFormatMillisecondsToString(duration) {
    let seconds: string | number = Math.floor((duration / 1000) % 60),
        minutes: string | number = Math.floor((duration / (1000 * 60)) % 60),
        hours: string | number = Math.floor(duration / (1000 * 60 * 60));

    hours = hours < 10 ? `0${hours}` : hours;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    seconds = seconds < 10 ? `0${seconds}` : seconds;

    return hours + ':' + minutes + ':' + seconds;
}

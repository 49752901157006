import Vue from 'vue';
import { mIsClient } from '@/common/helpers/ContextH';
import { gAsyncComponentSys } from '@/system/AsyncComponentSys';

// Зарегистрировать общие синхронные компоненты
import icon from '@/ds/el/icon/icon';
Vue.component('Icon', icon);

import ds_button from '@/ds/el/button/button';
Vue.component('Button', ds_button);

import modal from '@/ds/el/modal/modal';
Vue.component('Modal', modal);

import ModalRightTopContainer from '@/common/el/ModalRightTopContainer/ModalRightTopContainer';
Vue.component('ModalRightTopContainer', ModalRightTopContainer);

/** Зарегистрировать общие асинхронные компоненты */
function fRegisterCommonAsyncComponents() {
    if (mIsClient()) {
        // Для Header
        gAsyncComponentSys.fRegisterAsyncComponent('FavoriteMenu', (resolve: () => void) =>
            require(['@/common/el/FavoriteMenu/FavoriteMenu'], resolve)
        );

        gAsyncComponentSys.fRegisterAsyncComponent('ModalQrUser', (resolve: () => void) =>
            require(['@/common/el/ModalQrUser/ModalQrUser'], resolve)
        );

        gAsyncComponentSys.fRegisterAsyncComponent('AppealPopup', (resolve: () => void) =>
            require(['@/lib_common/el/appeal_popup/appeal_popup'], resolve)
        );

        gAsyncComponentSys.fRegisterAsyncComponent('UnpaidOrderInCartPopup', (resolve: () => void) =>
            require(['@/common/el/UnpaidOrderInCartPopup/UnpaidOrderInCartPopup'], resolve)
        );

        // Для Footer
        // Модальное окно для отправки жалобы
        gAsyncComponentSys.fRegisterAsyncComponent('ModalSendClaim', (resolve: () => void) =>
            require(['@/common/el/ModalSendClaim/ModalSendClaim'], resolve)
        );

        // Для остальных компонентов
        gAsyncComponentSys.fRegisterAsyncComponent('ModalError', (resolve: () => void) =>
            require(['@/common/el/ModalError/ModalError'], resolve)
        );

        gAsyncComponentSys.fRegisterAsyncComponent('ModalAddToCartSuccess', (resolve: () => void) =>
            require(['@/common/el/ModalAddToCartSuccess/ModalAddToCartSuccess'], resolve)
        );

        gAsyncComponentSys.fRegisterAsyncComponent('ModalLinkIsCopied', (resolve: () => void) =>
            require(['@/common/el/ModalLinkIsCopied/ModalLinkIsCopied'], resolve)
        );

        gAsyncComponentSys.fRegisterAsyncComponent('ModalOrgMessage', (resolve: () => void) =>
            require(['@/common/el/ModalOrgMessage/ModalOrgMessage'], resolve)
        );

        gAsyncComponentSys.fRegisterAsyncComponent('ModalThanksMessage', (resolve: () => void) =>
            require(['@/common/el/ModalThanksMessage/ModalThanksMessage'], resolve)
        );

        gAsyncComponentSys.fRegisterAsyncComponent('ModalUserInBlackList', (resolve: () => void) =>
            require(['@/common/el/ModalUserInBlackList/ModalUserInBlackList'], resolve)
        );

        gAsyncComponentSys.fRegisterAsyncComponent('ModalUserIsNotSignIn', (resolve: () => void) =>
            require(['@/common/el/ModalUserIsNotSignIn/ModalUserIsNotSignIn'], resolve)
        );

        gAsyncComponentSys.fRegisterAsyncComponent('ModalUserIsNotSignInForCart', (resolve: () => void) =>
            require(['@/common/el/ModalUserIsNotSignInForCart/ModalUserIsNotSignInForCart'], resolve)
        );

        gAsyncComponentSys.fRegisterAsyncComponent('ModalUserRatingUp', (resolve: () => void) =>
            require(['@/common/el/ModalUserRatingUp/ModalUserRatingUp'], resolve)
        );
        gAsyncComponentSys.fRegisterAsyncComponent('ModalSocialSubscribe', (resolve: () => void) =>
            require(['@/common/el/ModalSocialSubscribe/ModalSocialSubscribe'], resolve)
        );

        gAsyncComponentSys.fRegisterAsyncComponent('ModalABRegister', (resolve: () => void) =>
            require(['@/common/el/ModalABRegister/ModalABRegister'], resolve)
        );

        gAsyncComponentSys.fRegisterAsyncComponent('ModalItemIsCopied', (resolve: () => void) =>
            require(['@/common/el/ModalItemIsCopied/ModalItemIsCopied'], resolve)
        );

        gAsyncComponentSys.fRegisterAsyncComponent('VSelector', (resolve: () => void) =>
            require(['@/common/el/VSelector/VSelector'], resolve)
        );

        // Страница 404
        gAsyncComponentSys.fRegisterAsyncComponent('NotFound404', (resolve: () => void) =>
            require(['@/common/el/NotFound404/NotFound404'], resolve)
        );

        // Защита клиента
        gAsyncComponentSys.fRegisterAsyncComponent('ModalUserProtection', (resolve: () => void) =>
            require(['@/common/el/ModalUserProtection/ModalUserProtection'], resolve)
        );
    }
}

export default fRegisterCommonAsyncComponents;

<script lang="ts">
import Component from 'vue-class-component';
import BannerNewSource from './banner-new-source';

@Component
export default class BannerNew extends BannerNewSource {}
</script>

<template>
    <div
        class="banner"
        @click="fOnBannerClick"
        @mouseenter="fStopTimer"
        @mouseleave="fRerunTimer"
        @touchstart="fHandlerStartTouch"
        @touchend="fHandlerEndTouch"
    >
        <template v-if="aBanners.length > 1">
            <ul class="banner__wrapper">
                <li class="banner__item" v-for="(objBanners, idx) in aBanners" :key="objBanners.banner_id">
                    <a :href="objBanners.banner_url" :target="$sTargetLink">
                        <picture>
                            <source v-if="objBanners.is_webp" :srcset="fGetUrlBanner(idx)" type="image/webp" />
                            <img
                                class="banner__img"
                                :class="{
                                    banner__img_current: idBanner === idx,
                                    banner__img_prev: fCheckPrevBanner(idx),
                                    banner__img_next: fCheckNextBanner(idx),
                                    banner__img_reverse: isReverseAnimation,
                                    mobile: isMobile,
                                }"
                                :src="fGetUrlBanner(idx)"
                                @mouseenter.stop
                                @mouseleave.stop
                                :loading="idx === 0 ? 'eager' : 'lazy'"
                                :decoding="idx === 0 ? 'sync' : 'async'"
                                :alt="objBanners.banner_name"
                            />
                        </picture>
                    </a>
                </li>
            </ul>
            <SliderControl
                v-if="isMobile"
                class="banner__control"
                :idElement="idBanner"
                :nCountElement="aBanners.length"
                :fSelectElement="fSetBanner"
            />
            <template v-else>
                <SliderControl
                    @click.stop
                    class="banner__control"
                    :idElement="idBanner"
                    :nCountElement="aBanners.length"
                    :fSelectElement="fSetBanner"
                />
                <ArrowButton
                    @click.stop
                    sArrowType="left"
                    :fChangeElement="fReturnBanner"
                    class="banner__arrow banner__arrow_left"
                />
                <ArrowButton
                    @click.stop
                    sArrowType="right"
                    :fChangeElement="fChangeBanner"
                    class="banner__arrow banner__arrow_right"
                />
            </template>
        </template>
        <div class="banner__wrapper" v-else-if="aBanners.length === 1">
            <a :href="aBanners[0].banner_url" :target="$sTargetLink">
                <picture>
                    <source v-if="aBanners[0].is_webp" :srcset="fGetUrlBanner(0)" type="image/webp" />
                    <img
                        class="banner__img-plug"
                        :src="fGetUrlBanner(0)"
                        :width="isMobile ? 336 : 888"
                        :height="isMobile ? 140 : 280"
                        loading="eager"
                        decoding="sync"
                        :alt="aBanners[0].banner_name"
                    />
                </picture>
            </a>
        </div>
        <div class="banner__wrapper" v-else>
            <a
                href="/purchases?parentCategory&category&tags=15&soonStop=false&first=false&sort=date&search=&offset=0&show=true&newMode"
                :target="$sTargetLink"
            >
                <picture>
                    <source :srcset="sPlugHref" type="image/webp" />
                    <img
                        class="banner__img-plug"
                        :src="sPlugHref"
                        :width="isMobile ? 336 : 888"
                        :height="isMobile ? 140 : 280"
                        loading="eager"
                        decoding="sync"
                        alt="Заглушка вместо баннера"
                    />
                </picture>
            </a>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.banner {
    border-radius: 16px;
    width: 100%;
    overflow: hidden;
    position: relative;
    min-height: 140px;
    &__item {
        list-style: none;
        padding: 0;
    }
    &__wrapper {
        width: 100%;
        height: 100%;
        touch-action: none;
        list-style: none;
        padding: 0;
        margin: 0;
    }
    &__img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        opacity: 0;
        visibility: hidden;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transition: opacity 0.3s ease-in, visibility 0.3s;
        border-radius: 16px;
        will-change: transform;
        &_current {
            opacity: 1;
            visibility: visible;
        }
        @media (max-width: 767px) {
            transition: none;
            &_current {
                animation: InCur 1s forwards;
            }
            &_prev {
                animation: InPrev 1s forwards;
            }

            &_next {
                animation: InNext 1s forwards;
            }
            &_reverse.banner__img_current {
                animation: InCurReverse 1s forwards;
            }
            &_reverse.banner__img_next {
                animation: InNextReverse 1s forwards;
            }
            &_reverse.banner__img_prev {
                animation: InPrevReverse 1s forwards;
            }
        }
    }

    .banner__img_current,
    .banner__img_next,
    .banner__img_prev {
        opacity: 1;
        visibility: visible;
    }
    &__img-plug {
        object-fit: cover;
        object-position: left;
        width: 100%;
        height: 100%;
        border-radius: 16px;
    }
    &__control {
        position: absolute;
        bottom: 12px;
        left: 50%;
        transform: translateX(-50%);

        @media (max-width: 767px) {
            bottom: 8px;
        }
    }

    &__arrow {
        opacity: 0;
        transition: opacity 0.3s 1s ease-out;
        &_left {
            left: 16px;
        }
        &_right {
            right: 16px;
        }
    }
    &:hover .banner__arrow {
        opacity: 1;
        transition: opacity 0.3s ease-out, background-color 0.3s ease-out;
        &:hover {
            transition: background-color 0.3s ease-out;
            background-color: #d7d7d7;
        }
    }
}

@keyframes InNext {
    0% {
        transform: translateX(calc(200%)) scale(0.9);
    }

    100% {
        transform: translateX(calc(100% - 3.6% + 8px)) scale(0.928);
    }
}
@keyframes InPrev {
    0% {
        transform: translateX(0) scale(1);
    }

    100% {
        transform: translateX(calc(-100% + 3.6% - 8px)) scale(0.928);
    }
}

@keyframes InCur {
    0% {
        transform: translateX(calc(100% - 3.6% + 8px)) scale(0.928);
    }

    100% {
        transform: translateX(0px) scale(1);
    }
}

@keyframes InNextReverse {
    0% {
        transform: translateX(0) scale(1);
    }

    100% {
        transform: translateX(calc(100% - 3.6% + 8px)) scale(0.928);
    }
}
@keyframes InPrevReverse {
    0% {
        transform: translateX(calc(-200%)) scale(0.9);
    }

    100% {
        transform: translateX(calc(-100% + 3.6% - 8px)) scale(0.928);
    }
}

@keyframes InCurReverse {
    0% {
        transform: translateX(calc(-100% + 3.6% - 8px)) scale(0.928);
    }

    100% {
        transform: translateX(0px) scale(1);
    }
}
</style>

import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ItemBadge from '@/ds/el/item_badge/item_badge';
import { mCyrillicToTranslitCleared } from '@/common/helpers/CyrillicToTranslitH';
import P63Vue from '@/system/P63Vue';
import { gImageS } from '@/common/service/ImageS';
import { mGetDeviceType } from '@/common/helpers/DeviceTypeH';
import { ItemOfCollectionI } from '@/ifc/core/CommonI/ItemCollectionI';
import { PageCtrl } from '@/system/PageCtrl';
import { gfInitContextCtrl } from '@/system/ContextSys';
import { mIsClient } from '@/common/helpers/ContextH';
import { mPeriodName, mPluralize } from '@/common/helpers/TextFormatterH';
import dayjs from 'dayjs';

@Component
export default class ItemCard extends P63Vue {
    /** Товар */
    @Prop({ type: Object }) item: ItemOfCollectionI;
    /** Индекс */
    @Prop({ type: Number }) index: number;
    /** Флаг для слайдера в модальном окне "Товар добавлен в корзину" */
    @Prop({ type: Boolean, default: false }) bToCart: boolean;
    /** Алиас слайдера для отправки в статистику клика по товару из него */
    @Prop({ type: String, default: '' }) sStatAlias: string;
    /** Флаг для определения, что товар из пристроя УЗ" */
    @Prop({ type: Boolean, required: false }) isItemFromPristroyUz: boolean;

    /** Переводим название товара в транслит */
    fTransformCyrillicToTranslit = mCyrillicToTranslitCleared;

    //Url картинки товара
    get sItemImage() {
        return gImageS.fItemImageUrlx256(this.item.filename);
    }


    /** Актуальная цена товара */
    get iPrice() {
        return this.item?.price;
    }

    /** Старая цена товара */
    get iOldPrice() {
        return this.item?.old_price;
    }

    /*
    Разница между старой ценой и актуальной ценой товара в процентах
    a > b = ((a-b)/a) * 100
    */
    get iPercentageDiffPrice() {
        let iPercentage = 0;
        if (this.iOldPrice && this.iPrice) {
            iPercentage = ((this.iOldPrice - this.iPrice) / this.iOldPrice) * 100;
            iPercentage = Math.round(iPercentage);
        }
        return iPercentage;
    }

    /** Показывать ли старую цену */
    get bShowOldPrice() {
        const iMinDiffInPrice = 10;
        return this.iOldPrice && (this.iPercentageDiffPrice >= iMinDiffInPrice);
    }

    /** Подобрать правильную форму для слова «раз» */
      fGetPluralizedOneWord(number: number): string {
        return mPluralize(number, ['раз', 'раза', 'раз']);
    }

    /** Корректное именование времени */
    fGetCorrectPeriodName(sFirstOrderDate: string) {
        const nCurrentTime = dayjs().valueOf() //текущее
        const nFirstOrderTime = dayjs(sFirstOrderDate?.replace(/-/g,'/')).valueOf()
        const nTotalDays = Math.floor((nCurrentTime - nFirstOrderTime) / (1000 * 3600 * 24))
        const nReturnDays = nTotalDays ? mPeriodName(nTotalDays) : mPeriodName(1)

        return `купили за ${nReturnDays}`
    }

    /** Получить utm тэги для /item/ */
    get sUtmTag() {
        const translit = this.fTransformCyrillicToTranslit(this.item.name);

        // Cсылка для страниц item
        let sUtm: string = `/item/${this.item.item_code}/${translit}/?utm_source=ITEMSLIDER&utm_medium=SALE&utm_campaign=${this.item.item_id}&utm_term=${translit}`;

        if (this.sFullPath === `/item/` && this.vDeviceType.isDesktop) {
            sUtm = `${sUtm}&utm_content=desktop`;
        } else if (this.sFullPath === `/item/` && this.vDeviceType.isMobile) {
            sUtm = `${sUtm}&utm_content=mobile`;
        } else {
            sUtm = `/item/${this.item.item_code}/${translit}`;
        }
        return sUtm;
    }

    /** Получить ссылку текущей страницы */
    get sFullPath() {
        let sPath: string = this.$router.currentRoute.path.split('/')[1];
        sPath = `/${sPath}/`;
        return sPath;
    }

    get ctrl(): PageCtrl {
        return gfInitContextCtrl(mIsClient() ? this.$context : this.$ssrContext, PageCtrl);
    }

    /** Товары, ждобавленные в вишлист */
    get ixWishItems(){
        return this.ctrl.wishSys.wish.wish_items;
    }

    /** Находится ли товар в списке жкланий */
    get bInWishlist(){
        return this.ixWishItems[this.item.item_id];
    }

    /** Тип устройства */
    get vDeviceType() {
        return mGetDeviceType(this.ctrl.deviceType);
    }

    /** Перейти на страницу товара */
    onItemClick(item, i, callbackUrl) {
        this.$emit('imgClick', { item, i, callbackUrl });
        this.fPushClickToStat();
    }

    /** Добавить товар в список желаний */
    fChangeWishItem(idItem: number) {
        if (this.ctrl.userSys.user.isAuth) {
            this.ctrl.wishSys.faAddToWishList(idItem);
        } else {
            this.$emit('userIsNotAuth');
        }
    }

    fPushClickToStat() {
        /** Отправить данные в статистику о клике по товару */
      this.ctrl.statSys.faPushClickToStat({
        page: location.pathname,
        type: 'item',
        alias: this.sStatAlias,
        entity_id: this.item.item_id,
      });
    }
}

import { P63ItemCollectionI } from './EntitySQL/P63ItemCollectionE';
import { UserDictionaryI } from './EntitySQL/UserE';
import { SearchTypeT } from './EntitySQL/UserSearchHistoryE';
import { ItemOfCollectionI, ProductOfCollectionI } from './CommonI/ItemCollectionI';

export namespace TopSelectionNoAuthR {
    export const Alias = 'TopSelectionNoAuth';

    /** Получить список всех товаров в подборке */
    export namespace listItemOfCollection {
        /** APIURL */
        export const route = '/top-selection/list-item-of-collection';

        /** Параметры api запроса */
        export interface RequestI {
            collection_id: number; // ID подборки
        }

        /** Параметры api ответа */
        export interface ResponseI {
            item_collection_info: P63ItemCollectionI; // Информация о подборке
            list_item_of_collection: ItemOfCollectionI[]; // Список всех товаров в подборке
        }
    }

    /** Получить товары в Топ-подборке по alias */
    export namespace oneTopSelection {
        /** APIURL */
        export const route = '/top-selection/get-list-by-alias';

        /** Alias действия */
        export const action = 'top-selection-top-by-alias';

        /** Параметры api запроса */
        export interface RequestI {
            alias: string;
        }

        /** Параметры api ответа */
        export interface ResponseI {
            info: P63ItemCollectionI; // Информация по подборке
            list_item_of_collection: ItemOfCollectionI[]; // Товары в подборке
            list_product_of_collection: ProductOfCollectionI[]; // продукты подборки
        }
    }

    /** Получить рекомендованные товары при добавлении товара в корзину */
    export namespace getCartRecommendations {
        /** APIURL */
        export const route = '/top-selection/get-cart-recommendations';

        /** Alias действия */
        export const action = 'get-cart-recommendations';

        /** Параметры api запроса */
        export interface RequestI {
            item_id: number; // Идентификатор товара
        }

        /** Параметры api ответа */
        export interface ResponseI {
            info: P63ItemCollectionI; // Информация по подборке
            list_item_of_collection: ItemOfCollectionI[]; // Товары в подборке
            list_product_of_collection: ProductOfCollectionI[]; // продукты подборки
        }
    }

    /** Получить товары, продвигаемые/рекламируемые организатором */
    export namespace getRecommendedItemsByOrg {
        /** APIURL */
        export const route = '/top-selection/get-recommended-items-by-org';

        /** Параметры api запроса */
        export interface RequestI {
            item_id: number;
        }

        /** Параметры api ответа */
        export interface ResponseI {
            info: P63ItemCollectionI; // Информация по подборке
            list_item_of_collection: ItemOfCollectionI[]; // Товары в подборке
            list_product_of_collection: ProductOfCollectionI[]; // продукты подборки
        }
    }

    /** Получить продукты, продвигаемые/рекламируемые организатором */
    export namespace getRecommendedProductsByOrg {
        export const route = '/top-selection/get-recommended-product-by-org';

        /** Параметры api запроса */
        export interface RequestI {
            product_id: number;
        }

        /** Параметры api ответа */
        export interface ResponseI {
            info: P63ItemCollectionI; // Информация по подборке
            list_item_of_collection: ItemOfCollectionI[]; // Товары в подборке
            list_product_of_collection: ProductOfCollectionI[]; // продукты подборки
        }
    }

    /** Получить подборку топ товаров в зависимости от рейтинга коллекции случайным образом */
    export namespace getOneTopSelectionRandom {
        /** APIURL */
        export const route = '/top-selection/get-one-top-selection-random';

        /** Параметры api запроса */
        export interface RequestI {
            list_exclude_item_id?: number[]; // список id товаров, которые нужно исключить из выборки
            list_exclude_product_id?: number[]; // список ID продуктов, которые нужно исключить из выборки
            alias?: string; // alias для повторной выборки
        }

        /** Параметры api ответа */
        export interface ResponseI {
            info: P63ItemCollectionI; // информация по подборке
            list_item_of_collection: ItemOfCollectionI[]; // товары в подборке
            alias: string; // alias для повторной выборки
            cnt_item_of_collection: number; // количество товаров в коллекции
            list_product_of_collection: ProductOfCollectionI[]; // продукты подборки
            cnt_product_of_collection: number; // количество продуктов в коллекции
        }
    }

    /** Получить историю поиска пользователя на сайте - "Вы искали" */
    export namespace getUserSearchHistory {
        /** APIURL */
        export const route = '/top-selection/get-user-search-history';

        /** Параметры api запроса */
        export interface RequestI {
            // Входящих данных нет
        }

        /** Параметры api ответа */
        export interface ResponseI {
            list_search_text: { search_text: string; type: SearchTypeT }[]; // Последние поисковые запросы пользователя
            list_stock_dictionary: { stock_id: number; stock_name: string }[]; // Словарь шаблонов
            list_org_dictionary: UserDictionaryI[]; // Словарь организаторов
        }
    }

    /** Получить последние просмотренные пользователем товары - "Вы смотрели" */
    export namespace getUserViewHistory {
        /** APIURL */
        export const route = '/top-selection/get-user-view-history';

        /** Параметры api запроса */
        export interface RequestI {
            // Входящих данных нет
        }

        /** Параметры api ответа */
        export interface ResponseI {
            info: P63ItemCollectionI; // Информация по подборке
            list_item_of_collection: ItemOfCollectionI[]; // Последние просмотренные пользователем товары
            list_product_of_collection: ProductOfCollectionI[]; // продукты подборки
            list_item_id_from_market_uz: number[]; // ids товаров из пристроя уз
        }
    }

    /** Получить подборку товаров, связанных по подкатегориям товара - "С этим товаром покупают" */
    export namespace getRecommendedItemByCategory {
        /** APIURL */
        export const route = '/top-selection/get-recommended-item-by-category';

        /** Параметры api запроса */
        export interface RequestI {
            item_id: number; // ID товара
        }

        /** Параметры api ответа */
        export interface ResponseI {
            info: P63ItemCollectionI; // Информация по подборке
            list_item_of_collection: ItemOfCollectionI[]; // Товары в подборке
            list_product_of_collection: ProductOfCollectionI[]; // продукты подборки
        }
    }
}

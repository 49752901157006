import { PostFileImgInfoI } from './ImgSQL/PostFileImgE';
import { UserDictionaryI, UserInfoPublicI } from './UserUSQL/UserUE';
import { ClaimI, ClaimSourceT, ClaimStatusT } from './EntitySQL/ClaimE';
import { ClaimFilterI } from './EntitySQL/ClaimFilterE';
import { PostI } from './EntitySQL/PostE';
import { PostUserRatingI } from './EntitySQL/PostUserRatingE';
import { PaginationI } from './CommonI/PaginationI';
import { MessageClaimI } from './EntitySQL/MessageClaimE';
import { DiscussionClaimI } from './EntitySQL/DiscussionClaimE';
import { CommentI } from './EntitySQL/CommentE';
import { ItemI } from './EntitySpSQL/ItemE';
import { ClaimItemI } from './EntitySQL/ClaimItemE';
import { ClaimMessageAnswerI } from './EntitySQL/ClaimMessageAnswerE';
import { MessageI, MessageAttachmentInfoI } from './EntitySQL/MessageE';
import { TopicStockI } from './EntitySQL/TopicStockE';
import { ClaimResponsibleUserI } from './EntitySQL/ClaimResponsibleUserE';
import { ThreadDictI } from './EntitySQL/ThreadE';
import { ClaimOrgI } from './EntitySQL/ClaimOrgE ';

/** CRUD по жалобам */
export namespace ClaimR {
    /**
     *
     * ===================================== CREATE ===========================================
     *
     */

    /** Подать жалобу */
    export namespace createClaim {
        /** APIURL */
        export const route = '/claim/create-claim';

        /** Параметры api запроса */
        export interface RequestI {
            title?: string; // Заголовок жалобы
            text: string; // Текст жалобы
            claim_type: ClaimSourceT; // На что подали жалобу
            url: string; // Ссылка на страницу, на которой создавалась заявка
            item_id?: number; // Если жалоба на цену товара, необходимо указать ID товара
        }

        /** Параметры api ответа */
        export interface ResponseI {
            post: PostI; // Пост
            claim: ClaimI; // жалоба
        }
    }

    /** Сохранить фильтр */
    export namespace saveFilter {
        export const route = '/claim/save-filter';

        export interface RequestI {
            name: string; // Название фильтра
            filter_topic_id_list: number[];  // Список ID тем
            filter_author_id_list: number[]; // Список ID авторов жалоб
            filter_responsible_user_id?: number; // Фильтрация по ответственным
            filter_type?: ClaimSourceT;      // Фильтр по типу жалобы
            filter_status?: ClaimStatusT;    // Фильтр по статусу жалобы
            sort_by_old?: boolean;           // Сортировать от старых к новым
        }

        export interface ResponseI {
            filter_id: number; // ID сохранённого фильтра
        }
    }

    /** Ответить пользователю, подавшему жалобу */
    export namespace respondToClaimAuthor {
        export const route = '/claim/respond-to-author';

        export interface RequestI {
            claim_id: number; // ID жалобы на которую отвечают
            thread_id: number; // ID треда в который хотим отправить
            user_id: number; // ID пользователя которому хотим отправить
            text: string; // Текст сообщения
            list_attachment?: number[]; // Список прикрепленных к сообщению вложений
        }

        export interface ResponseI {
            message_id: number;
        }
    }

    /**
     *
     * ===================================== READ ===========================================
     *
     */

    /** Получить жалобу */
    export namespace getClaim {
        /** APIURL */
        export const route = '/claim/get-claim';

        /** Параметры api запроса */
        export interface RequestI {
            claim_id: number; // ID жалобы
        }

        /** Параметры api ответа */
        export interface ResponseI {
            claim: ClaimI; // жалоба
            post: PostI; // Пост
            post_file_image_list: PostFileImgInfoI[]; // Список вложений-картинок постов
            post_file_not_image_list: PostFileImgInfoI[]; // Список вложений-не-картинок постов
            rating_list: PostUserRatingI[]; // Список оценок
            comment_count: { post_id: number; count_comment: number }[]; // Количество комментариев
            user_list: UserInfoPublicI[]; // Список пользователей
        }
    }

    /** Получить список жалоб с пагинацией */
    export namespace getClaimListSearch {
        /** API URL */
        export const route = '/claim/get-claim-list-search';

        /** Параметры api запроса */
        export interface RequestI {
            page: number;                        // Страница
            limit: number;                       // Кол-во результатов на странице
            filter_text?: string;                // Поиск по строке в заголовке или сообщение жалобы
            filter_by_title?: boolean;           // Поиск только по заголовку
            filter_by_text?: boolean;            // Только по сообщению
            filter_by_author_username?: boolean; // Поиск по нику автора жалобы
        }

        /** Параметры api ответа */
        export interface ResponseI {
            claim_list: ClaimI[];                                        // жалобы
            post_list: PostI[];                                          // Посты
            post_file_image_list: PostFileImgInfoI[];                     // Список вложений-картинок постов
            post_file_not_image_list: PostFileImgInfoI[];                 // Список вложений-не-картинок постов
            rating_list: PostUserRatingI[];                              // Список оценок
            comment_count: { post_id: number; count_comment: number }[]; // Количество комментариев
            list_user_info: UserInfoPublicI[];                           // Список пользователей
            list_id_user_author: number[];                               // Список ID авторов жалоб
            list_id_user_rating: number[];                               // Список ID оценивших посты
            pagination: PaginationI;                                     // Пагинация
        }

    }

    /** Получить жалобы по фильтру с пагинацией */
    export namespace getClaimListByFilter {
        /** APIURL */
        export const route = '/claim/get-claim-list-by-filter';

        /** Параметры api запроса */
        export interface RequestI {
            page: number;                           // Страница
            limit: number;                          // Количество на странице
            claim_filter_id?: number;               // ID сохранённого фильтра
            topic_id_list?: number[];               // Список ID тем
            author_id_list?: number[];              // Список ID авторов заявок
            filter_type?: ClaimSourceT[];           // Фильтр по типу заявки
            filter_status?: ClaimStatusT[];         // Фильтр по статусу заявки
            filter_date_end?: string;               // конец периода фильтрации
            filter_date_start?: string;             // начало периода фильтрации
            filter_text?: string;                   // Поиск по строке в сообщение жалобы
            filter_responsible_user_id?: number;    // Фильтрация по ответственным
            claim_id?: number;                      // ID жалобы
            sort_by_old?: boolean;                  // Сортировать от новых к старым
        }

        /** Параметры api ответа */
        export interface ResponseI {
            claim_list: ClaimI[];                        // Жалобы
            post_list: PostI[];                          // Посты
            list_topic_stock: Partial<TopicStockI>[];    // stock_id поста на который пожаловались
            list_message: MessageI[];                    // Сообщения
            list_claim_answer: ClaimMessageAnswerI[];    // Ответы на жалобы автору
            list_discussion_claim: DiscussionClaimI[];   // Посты на которые пожаловались
            list_message_claim: MessageClaimI[];         // Сообщения на которые пожаловались
            list_comment: CommentI[];                    // Комментарии на которые пожаловались
            list_item: ItemI[];                          // Инфа о товарах на которые пожаловались
            list_claim_item: ClaimItemI[];               // Связи жалоб и товаров на который пожаловались
            list_org: UserDictionaryI[];                 // Инфа о организаторах на которых пожаловались
            list_claim_org: ClaimOrgI[];                 // Связи жалоб и организаторов на которых пожаловались
            list_user_info: UserInfoPublicI[];           // Инфа о пользователях
            list_thread_user: ThreadDictI[];             // Список тредов авторов жалоб в администрацией
            list_access_thread_id: number[];             // Список ID доступных тредов
            list_id_user_author: number[];               // Список авторов жалоб
            list_claim_responsible_user: ClaimResponsibleUserI[]; // Связь ответственного с жалобой
            list_responsible_user_dict: UserDictionaryI[]; // Список ответственных на выбор
            post_file_image_list: PostFileImgInfoI[];     // Список вложений-картинок постов
            post_file_not_image_list: PostFileImgInfoI[]; // Список вложений-не-картинок постов
            list_attachment: MessageAttachmentInfoI[]; // Список вложений в ответе модератора
            pagination: PaginationI;                     // Пагинация
        }
    }

    /** Получить сохраненные фильтры пользователя */
    export namespace getUserFilterList {
        /** APIURL */
        export const route = '/claim/get-user-filter-list';

        /** Параметры api запроса */
        export interface RequestI {
            // Нет параметров
        }

        /** Параметры api ответа */
        export interface ResponseI {
            claim_filter_list: ClaimFilterI[]; // Фильтры по жалобам
        }
    }

    /** Получить список авторов жалоб с кол-вом жалоб за последний */
    export namespace getAuthorLastYear {
        /** API URL */
        export const route = '/claim/get-author-last-year';

        /** Параметры api запроса */
        export interface RequestI {
        }

        /** Параметры api ответа */
        export interface ResponseI {
            list_user: UserDictionaryI[]; // Авторы жалоб
            list_claim_cnt: {
                user_id: number,          // ID пользователя
                cnt: number               // Кол-во оставленных им жалоб
            }[];
        }
    }

    /**
     *
     * ===================================== UPDATE ===========================================
     *
     */

    /** Редактировать жалобу */
    export namespace editClaim {
        /** APIURL */
        export const route = '/claim/edit-claim';

        /** Параметры api запроса */
        export interface RequestI {
            claim_id: number; // ID жалобы
            type?: ClaimSourceT; // Тип жалобы
            status?: ClaimStatusT; // Статус жалобы
            title?: string; // Заголовок жалобы
            text?: string; // Текст жалобы
            topic_id?: number; // ID темы
            responsible_user_id?: number; // ID ответственного
        }

        /** Параметры api ответа */
        export interface ResponseI {
            claim: ClaimI; // жалоба
        }
    }

    /** Перенести жалобы в другую тему */
    export namespace updateClaimTopic {
        /** APIURL */
        export const route = '/claim/update-claim-topic';

        /** Параметры api запроса */
        export interface RequestI {
            claim_id_list: number[]; // Список ID жалоб
            topic_id: number; // ID темы
        }

        /** Параметры api ответа */
        export interface ResponseI {
            // Нет ответа
        }
    }

    /**
     *
     * ===================================== DELETE ===========================================
     *
     */

    /** Удалить фильтр */
    export namespace deleteFilter {
        export const route = '/claim/delete-filter';

        export interface RequestI {
            filter_id_list: number[]; // Список ID фильтров
        }

        export interface ResponseI {
            // Нет ответа
        }
    }
}

export namespace ItemBundleN {
  export enum ItemBundleStatus {
    Free = 0,
    Fixed = 1,
    Busy = 2,
    Your = 3,
  }

  /**
   * Матрица отображения рядов
   */
  export interface ItemBundleMatrixI {
    characteristic_val_id?: number; // ID Значение переменной
    val: string; // Значение характеристики
    status: ItemBundleStatus;
    orderId: number;
  }

  /**
   * Выход ф-и обработки рядов
   */
  export interface ItemBundelServiceOut {
    aItemBundleFix: ItemBundleMatrixI[][];
    aItemBundleNoFix: ItemBundleMatrixI[][];
  }
}

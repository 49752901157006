import * as conf from '@/configs/config.public'
import { QuerySys } from '@63pokupki/front'
import { PageCtrl } from './PageCtrl';
import { gfInitContextQuery } from './ContextSys';
import { mIsClient } from '@/common/helpers/ContextH';
import { ClaimR } from '@/ifc/msg/ClaimR'

export class FooterStoreI {
    toJSON() {
        return { ...this }; // here I make a POJO's copy of the class instance
    }

    /** Статус открытия модального окна отправки жалобы */
    is_modal_send_claim_open: boolean = false;
    /** Статус успешной отправки жалобы */
    is_create_claim_success: boolean = false;
    /** Статус отправки жалобы */
    is_loading_create_claim: boolean = false;
}

export class FooterSys {
    public conf = conf;

    public footer: FooterStoreI = null;

    private queryMsgSys: QuerySys = null;

    constructor(ctrl: PageCtrl) {
        this.queryMsgSys = new QuerySys();
        this.queryMsgSys.fConfig(gfInitContextQuery(ctrl, conf.msgAPI));

        let optionVuex = null;
        if (mIsClient()) {
            optionVuex = {
                preserveState: true, // сохранять состояние на сервере
            };
        }

        this.footer = ctrl.vuexSys.registerModuleCustomKey(new FooterStoreI(), 'footer_sys', optionVuex)
    }

    /** Создание жалобы из футера */
    fCreateClaim(request: ClaimR.createClaim.RequestI) {
        this.footer.is_loading_create_claim = true;
        this.queryMsgSys.fInit();

        this.queryMsgSys.fActionOk((data: ClaimR.createClaim.ResponseI) => {
            this.footer.is_create_claim_success = true;
            this.footer.is_loading_create_claim = false;
        })

        this.queryMsgSys.fActionErr(e => {})

        this.queryMsgSys.fSend(ClaimR.createClaim.route, request)
    }
}
<script lang="ts">
import Component from 'vue-class-component';
import P63Vue from '@/system/P63Vue';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class HeaderMenuBtn extends P63Vue {
    /** Active status */
    @Prop({ type: Boolean }) active: boolean;
}
</script>

<template>
    <button class="header-menu-btn" aria-label="Menu">
        <span class="header-menu-btn__icon" :class="{ active: active }">
            <i></i>
            <i></i>
            <i></i>
        </span>
        <span class="header-menu-btn__text">Категории</span>
    </button>
</template>

<style scoped lang="scss">
.header-menu-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: #36a6f2;
    padding: 9px 24px;
    border-radius: 8px;
    cursor: pointer;
    border: none;
    appearance: none;
    -webkit-appearance: none;
    transition: background-color 0.2s ease;

    &:hover {
        background-color: #0080f6;
    }

    &__icon {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 4.1px;
        width: 20px;
        height: 20px;
        align-items: center;
        justify-content: center;
        will-change: contents;

        i {
            width: 15px;
            height: 1.75px;
            border-radius: 1px;
            background-color: #fff;
            transition: all 0.2s ease;
            will-change: transform;
        }

        &.active {
            i:nth-child(1) {
                transform: rotate(45deg) translateX(4.5px) translateY(4px);
            }
            i:nth-child(2) {
                opacity: 0;
            }
            i:nth-child(3) {
                transform: rotate(-45deg) translateX(4px) translateY(-4px);
            }
        }
    }

    &__text {
        color: #ffffff;
        margin-left: 8px;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
    }

    @media only screen and (min-width: 1025px) and (max-width: 1150px) {
        padding: 10px;

        &__text {
            display: none;
        }
    }

    @media (max-width: 768px) {
        padding: 10px;
        margin: 0;

        &__text {
            display: none;
        }
    }
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"slider__section",attrs:{"aria-label":"Баннер"}},[(_vm.bTop && _vm.banner.length > 1)?_c('div',[_c('div',{ref:"mainBanner",staticClass:"main-slider main-slider_banner slider__wrapper",attrs:{"id":"mainBanner"}},[_c('button',{staticClass:"main-slider__button main-slider__button_left",attrs:{"aria-label":"влево"},on:{"click":function($event){return _vm.fOnSliderSwap('left', null, false)}}}),_c('button',{staticClass:"main-slider__button main-slider__button_right",attrs:{"aria-label":"вправо"},on:{"click":function($event){return _vm.fOnSliderSwap('right', null, false)}}}),_c('ul',{ref:"main-slider",class:['main-slider__list', { 'main-slider__list_top': _vm.bTop }]},_vm._l((_vm.banner),function(item){return _c('li',{key:item.banner_id,staticClass:"main-banner main-banner_top",style:(!_vm.bMobile ? { width: `${_vm.iSwipeWidth}px` } : '')},[(item)?_c('a',{staticClass:"main-banner__link main-banner__link-top",attrs:{"href":_vm.ixResUrls[item.banner_id],"target":_vm.$sTargetLink},on:{"click":function($event){return _vm.fOnBannerClick(item)}}},[_c('picture',[(item.is_webp)?_c('source',{attrs:{"srcset":_vm.fGetImgUrl(item.filename, item.is_webp),"type":"image/webp"}}):_vm._e(),_c('img',{staticClass:"main-banner__img banner-top__img",attrs:{"width":"1184","height":"264","loading":_vm.bTop && item.banner_id === _vm.vFirstBanner.banner_id ? 'eager' : 'lazy',"decoding":_vm.bTop && item.banner_id === _vm.vFirstBanner.banner_id ? 'sync' : 'async',"alt":item.banner_name,"src":_vm.fGetImgUrl(item.filename, item.is_webp)}})])]):_vm._e()])}),0)]),_c('div',{staticClass:"indicator-circle__flex"},[_c('ul',{staticClass:"indicator-circle__container"},_vm._l((_vm.banner),function(circle,idx){return _c('li',{key:idx,staticClass:"indicator-circle"},[_c('div',{staticClass:"indicator-circle__circle",class:{
                            'indicator-circle__circle_big': idx === _vm.nDisplayedBannerIdx,
                            'indicator-circle__circle_regular':
                                idx === _vm.nDisplayedBannerIdx + 1 || idx === _vm.nDisplayedBannerIdx - 1,
                            'indicator-circle__circle_small':
                                idx < _vm.nDisplayedBannerIdx - 1 || idx > _vm.nDisplayedBannerIdx + 1,
                        }},[_c('div',{staticClass:"indicator-circle__clickable-field",class:{
                                'indicator-circle__clickable-field_big': idx === _vm.nDisplayedBannerIdx,
                                'indicator-circle__clickable-field_regular':
                                    idx === _vm.nDisplayedBannerIdx + 1 || idx === _vm.nDisplayedBannerIdx - 1,
                                'indicator-circle__clickable-field_small':
                                    idx < _vm.nDisplayedBannerIdx - 1 || idx > _vm.nDisplayedBannerIdx + 1,
                            },on:{"click":function($event){return _vm.fOnSliderSwap('', idx, false)}}})])])}),0)])]):(_vm.banner.length && _vm.fGetImgUrl(_vm.vFirstBanner.filename, _vm.vFirstBanner.is_webp))?_c('div',{staticClass:"main-banner",class:[
            _vm.bannerType ? `main-banner_${_vm.bannerType}` : '',
            _vm.bTop && _vm.bMobile ? 'main-banner_top-mobile' : '',
            _vm.bTop && !_vm.bMobile ? 'main-banner_top-desktop' : '',
        ]},[_c('a',{staticClass:"main-banner__link",attrs:{"target":_vm.$sTargetLink,"href":_vm.ixResUrls[_vm.vFirstBanner.banner_id]},on:{"click":function($event){return _vm.fOnBannerClick(_vm.vFirstBanner)}}},[_c('picture',[(_vm.vFirstBanner.is_webp)?_c('source',{attrs:{"srcset":_vm.fGetImgUrl(_vm.vFirstBanner.filename, _vm.vFirstBanner.is_webp),"type":"image/webp"}}):_vm._e(),_c('img',{staticClass:"main-banner__img banner-top__img",attrs:{"width":_vm.bTop ? '1184' : '580',"height":_vm.bTop ? '264' : '252',"loading":_vm.bTop ? 'eager' : 'lazy',"decoding":_vm.bTop ? 'sync' : 'async',"alt":_vm.vFirstBanner.banner_name,"src":_vm.fGetImgUrl(_vm.vFirstBanner.filename, _vm.vFirstBanner.is_webp)}})])])]):_c('div',{staticClass:"main-banner",class:[
            _vm.bannerType ? `main-banner_${_vm.bannerType}` : '',
            _vm.bTop && _vm.bMobile ? 'main-banner_type_place-top-view-mobile' : '',
            _vm.bTop && !_vm.bMobile ? 'main-banner_type_place-top-view-desktop' : '',
        ]},[(_vm.isClient && !_vm.banner.length)?_c('a',{staticClass:"main-banner__link",attrs:{"href":"/purchases?parentCategory&category&tags=15&soonStop=false&first=false&sort=date&search=&offset=0&show=true&newMode","target":_vm.$sTargetLink},on:{"click":function($event){return _vm.fOnBannerClick(_vm.banner)}}},[_c('img',{staticClass:"main-banner__img plug",attrs:{"src":_vm.sPlugHref,"width":_vm.bTop ? '1184' : '580',"height":_vm.bTop ? '264' : '252',"loading":_vm.bTop ? 'eager' : 'lazy',"decoding":_vm.bTop ? 'sync' : 'async',"alt":"Заглушка вместо баннера"}})]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import P63Vue from '@/system/P63Vue';

@Component
export default class RatingStars extends P63Vue {
    /** Рейтинг */
    @Prop({ type: Number, default: 0 }) nRating: number;
    /** Возможность менять оценку */
    @Prop({ type: Boolean, default: false }) isActive: boolean;

    /** Изменить оценку */
    fEditRating(nRating: number) {
        if (this.isActive) {
            this.$emit('editRating', nRating);
        }
    }
}

import currency from 'currency.js';

/** Оставляет указанное количество символов в дробной части числа
 * @num - исходное число
 * @decimalPlaces - количество символов дробной части
 * */
export const mTruncateDecimal = (num: number, decimalPlaces: number) => {
    const factor = Math.pow(10, decimalPlaces);
    return Math.trunc(num * factor) / factor;
};

/** Оставляет указанное количество символов в дробной части числа 1456789 ==>1.456789
 * @num - исходное число
 * @decimalPlaces - количество символов дробной части
 * */
export const mChangeNumberPlace = (num: number, decimalPlaces: number) => {
    const factor = Math.pow(10, decimalPlaces);
    return num / factor;
};

/**
 * Форматирование числа с разделителем 1456789 ==> 1 456 789
 * @num - исходное число
 */
export const mFormatNumber = (num: number): string => {
    return currency(num, { symbol: '', separator: ' ', precision: 0 }).format();
};

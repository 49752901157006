import { CalculatedPaymentInfoExI } from './EntitySQL/P63CalculatedPaymentInfoE';
import { PurchaseKeyI } from './EntitySQL/PurchaseE';
import { UserDictionaryI } from './EntitySQL/UserE';

/**
 * Получение переплат пользователей для организаторов
 */
export namespace OverPaymentReportR {
    export const Alias = 'OverPaymentReportR';

    export namespace listOverpayByOrg {

        /** APIURL */
        export const route = '/overpayment-report/list-overpay-by-org';

        /** Параметры api запроса */
        export interface RequestI {
            org_user_id?: number // id организатора указанный админом
            user_id?: number; // id УЗ
            invoice_id?: number // id инвойсов
            purchase_id?: number // id закупок (с проверкой на орга или админа)
            overpay_sum?: number // сумма долга, равно или меньше
            page?: number // Текущая страница пагинации
            limit_to_page?: number // Лимит данных на страницу
        }

        /** Параметры api ответа */
        export interface ResponseI {
            overpayment_list: CalculatedPaymentInfoExI[],
            total_count: number,
            list_user_dict: UserDictionaryI[],
            list_purchase_key: PurchaseKeyI[],
        }
    }

    /** Получить количество заказов с переплатой у организатора */
    export namespace cntOverpaymentByOrg {

        /** APIURL */
        export const route = '/overpayment-report/count-overpay-by-org';

        /** Параметры api запроса */
        export interface RequestI {
        }

        /** Параметры api ответа */
        export interface ResponseI {
            count_overpayment: number;
        }
    }

}

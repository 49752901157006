import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import SearchInput from '@/common/el/SearchInput/SearchInput';
import { PageCtrl } from '@/system/PageCtrl';
import { gfInitContextCtrl } from '@/system/ContextSys';
import P63Vue from '@/system/P63Vue';
import { mIsClient } from '@/common/helpers/ContextH';
import { CategoryIDsI } from '@/ifc/core/EntitySQL/CategoryE';
import { gJivoSys } from '@/system/JivoSys';
import tooltip from '@/ds/el/tooltip/tooltip';
import CityTooltip from '../CityTooltip/CityTooltip';
import SelectCityModalM from '../SelectCityModal/SelectCityModalM.vue';
import { idSamara } from '@/ifc/core/EntitySQL/CityE';

/** Типы категорий */
export const enum TypeCategoryT {
    by_item = 'По товарам',
    by_purchase = 'По закупкам',
}

@Component({
    components: {
        SearchInput,
        Tooltip: tooltip,
        CityTooltip,
        SelectCityModalM,
    },
})
export default class MultiLevelMenuMobile extends P63Vue {
    /** Показывать ли административное меню */
    @Prop({ type: Boolean, default: false }) isShowManagerMenu: boolean;

    /** Поиск по товару / закупке */
    bSearchByItem: boolean = true;
    /** Открыт ли селектор типов категорий */
    bTypeCategorySelectorOpened: boolean = false;
    /** Выбранная категория */
    vSelectedCategory: CategoryIDsI = null;
    /** Алиас категории на которую последней кликнули */
    sClickedCategoryIconAlias: string = '';
    /** Боковое меню */
    elMenu: HTMLElement = null;
    /** Родительский элемент поисковой строки */
    elSearchBar: HTMLElement = null;
    /** Элемент - инпут поисковой строки */
    elSearchInput: HTMLElement = null;
    /** Бэкграунд при фокусе на инпут */
    elBackground: HTMLElement = null;
    /** Путь до svg-спрайта */
    sSpritePath: string = require('@/assets/images/icons/ready-sprites/multi-level-menu-mobile-sprite.svg');
    /** Показывать ли тултип */
    isShowTooltip = false;
    /** Проверка на то, что приложение было открыто через AppStore */
    isOpenAppStore = false;

    /** Закрыть тултип */
    fCloseTooltip() {
        this.isShowTooltip = false;
    }

    // Методы
    /** Закрытие меню пользователя */
    fCloseMenu() {
        this.ctrl.headerSys.header.is_open_multi_level_menu_mobile = false;
        document.body.classList.remove('stop-scrolling');
        this.fCloseSelecctor();
    }

    /** Открать окно выбора категорий */
    fOpenSelector() {
        this.bTypeCategorySelectorOpened = true;
    }

    /** Закрыть окно выбора типа категорий */
    fCloseSelecctor() {
        this.bTypeCategorySelectorOpened = false;
    }

    /** Выбор типа категорий для поиска (по товарам, либо по закупкам) */
    fSelectCategoryType(bChangeCategory: boolean) {
        if (bChangeCategory) {
            this.bSearchByItem = !this.bSearchByItem;
        }
        this.fCloseSelecctor();
        localStorage.setItem('default_category_settings', this.sSelectedCategoryType);
    }

    /** Перейти в категорию */
    fClickCategory(category: CategoryIDsI) {
        if (!this.ixCategories[category.category_id]?.length) {
            this.fGoCategory(category.category_id);
        } else {
            this.vSelectedCategory = category;
        }

        if (this.$refs.sidebar_body) {
            const sidebar = this.$refs.sidebar_body as HTMLDivElement;
            sidebar.scrollTop = 0;
        }

       /** Отправить данные в статистику о клике по ссылке в меню категорий*/
        this.ctrl.statSys.faPushClickToStat({
            page: location.pathname,
            type: 'menu',
            alias: 'link_click_menu_category',
            entity_id: category.category_id,
          });
    }
    /** Перейти на страницу категории / закупки */
    fGoCategory(idCategory: number) {
        document.location.href = this.bSearchByItem
            ? this.ctrl.menuSys.fGetItemCategoryUrl(idCategory)
            : this.ctrl.menuSys.fGetPurchaseCategoryUrl(idCategory);

        /** Отправить данные в статистику о клике по ссылке в меню категорий */
            this.ctrl.statSys.faPushClickToStat({
                page: location.pathname,
                type: 'menu',
                alias: 'link_click_menu_category',
                entity_id: idCategory,
              });
    }

    /** Вернуться к выбору основных категорий */
    fGoBackToCategories() {
        this.vSelectedCategory = null;
    }

    /** Обработчик фокуса на поисковой строке */
    fSearchFocusHandler() {
        this.elMenu.style.overflow = 'visible';
        this.elSearchBar.style.zIndex = '110';
        this.elSearchBar.style.background = '#ffffff';
        this.elBackground.style.display = 'inline-block';
        document.body.classList.add('stop-scrolling');
    }

    /** Обработчик блура поисковой строки */
    fSearchBlurHandler() {
        this.elMenu.style.overflow = 'auto';
        this.elSearchBar.style.zIndex = '10';
        this.elBackground.style.display = 'none';
        document.body.classList.remove('stop-scrolling');
    }

    /** Клик по городу */
    fCityClick() {
        if (this.isOpenAppStore) {
            const idCityStorage = Number(localStorage.getItem(this.ctrl.headerSys.header.storage_alias_user_city_id))
            const idUserCity = this.ctrl.userSys.user.info?.city_id
            if (!idUserCity && !idCityStorage) {
                this.fFindOutUserCity();
            }
        }

        this.isShowTooltip = true;
    }

    /** Авторизован ли пользователь */
    get bAuth() {
        return this.ctrl.userSys.user.isAuth;
    }

    /** Город пользователя */
    get vCity() {
        return this.ctrl.headerSys.header.user_city;
    }

    /** Открыть модальное окно отправки жалобы */
    fOpenAddClaimModal() {
        this.ctrl.footerSys.footer.is_modal_send_claim_open = true;
    }

    /** Закрытие модального окна */
    fCloseAddClaimModal() {
        this.ctrl.footerSys.footer.is_modal_send_claim_open = false;
    }

    /** Узнать город пользователя */
    fFindOutUserCity() {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.ctrl.headerSys.fGetCityByСoordinates({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude
                })
            // в случае ошибки
            }, this.fSelectDefaultCity);
        } else {
            this.fSelectDefaultCity()
        }
    }

    /** Выбрать город по умолчанию */
    fSelectDefaultCity() {
        this.ctrl.headerSys.header.user_city = {
            city_id: idSamara,
            city_name: 'Самара',
        }
    }

    mounted() {
        if (this.isApp && this.ctrl.headerSys.header.is_open_multi_level_menu_mobile) {
            document.body.classList.add('stop-scrolling');
            this.ctrl.headerSys.header.is_open_user_menu = false;
            this.ctrl.headerSys.header.is_open_user_qr = false;
            this.ctrl.headerSys.header.is_open_appeal_popup = false;
        }

        this.elMenu = this.$refs.sidebar_body as HTMLElement;
        this.elSearchBar = this.$refs.searchbar as HTMLElement;
        this.elBackground = this.$refs.background as HTMLElement;
        this.elSearchInput = this.elSearchBar.querySelector('#inputSearch');

        if (this.elSearchInput && this.elBackground) {
            this.elSearchInput.onfocus = this.fSearchFocusHandler;
            this.elSearchInput.onblur = this.fSearchBlurHandler;
        }

        if (this.isAuth) {
            const sDefaultCategorySettings: string = localStorage.getItem('default_category_settings');
            if (!sDefaultCategorySettings) {
                this.bSearchByItem = this.nConsumerRating < 1;
            } else {
                this.bSearchByItem = sDefaultCategorySettings === TypeCategoryT.by_item;
            }
        }

        this.isOpenAppStore = !!this.$route.query['iOS'];
        gJivoSys.fHideWidget();
    }

    beforeDestroy() {
        gJivoSys.fShowWidget();
        if (!this.ctrl.headerSys.header.is_open_user_menu) {
            document.body.classList.remove('stop-scrolling');
        }
    }

    /** Открыт ли сайт с приложения */
    get isApp() {
        return this.ctrl.isApp;
    }

    // Геттеры
    get ctrl(): PageCtrl {
        return gfInitContextCtrl(mIsClient() ? this.$context : this.$ssrContext, PageCtrl);
    }

    /** Массив меню категорий */
    get ixCategories() {
        return this.ctrl.menuSys.menu.ixCategoriesGrouped;
    }

    /** Выбранный тип категорий */
    get sSelectedCategoryType() {
        return this.bSearchByItem ? TypeCategoryT.by_item : TypeCategoryT.by_purchase;
    }

    /** Ссылка на все категории */
    get sAllCategory() {
        return this.bSearchByItem ? '/category/' : '/purchases/';
    }

    /** Не выбранный тип категорий */
    get sDeselectedCategoryType() {
        return this.bSearchByItem ? TypeCategoryT.by_purchase : TypeCategoryT.by_item;
    }

    /** Авторизован ли пользователь */
    get isAuth() {
        return this.ctrl.userSys.user.isAuth;
    }

    /** Уровень доступа пользвателя */
    get nConsumerRating() {
        return this.ctrl.userSys.user.info.consumer_rating;
    }

    /** Информация по социальным сетям */
    get aSocial() {
        return [
            {
                ariaLabel: 'Вконтакте',
                link: 'https://vk.com/63pokupki',
                iconName: 'vk',
            },
            {
                ariaLabel: 'Одноклассники',
                link: 'https://ok.ru/group63pokupki',
                iconName: 'ok',
            },
            {
                ariaLabel: 'Ютуб',
                link: 'https://www.youtube.com/channel/UCiRG7uK7jYLhM0DAzvTlNmg',
                iconName: 'youtube',
            },
            {
                ariaLabel: 'Вайбер',
                link: 'https://invite.viber.com/?g2=AQAFp66898%2Fk%2Bkx32C6tpavFlXMi5KjeI6L5re3LuO2qsCSDH8759aNMAsP%2BkZps',
                iconName: 'viber',
            },
            {
                ariaLabel: 'Телеграм',
                link: 'https://t.me/sp63pokupki',
                iconName: 'tg',
            },
            {
                ariaLabel: 'Дзен',
                link: 'https://dzen.ru/63pokupki',
                iconName: 'zen',
            },
            // {
            //     ariaLabel: 'Инстаграм',
            //     link: 'https://www.instagram.com/63pokupki.ru',
            //     iconName: 'instagram',
            // },
        ];
    }
}

<script lang="ts">
import Component from 'vue-class-component';
import ClientRecSource from './client-rec-source';

@Component
export default class ClientRec extends ClientRecSource {}
</script>

<template>
    <section v-if="isShowClientRec" class="client-rec">
        <h3 class="client-rec__title">Клиенты рекомендуют</h3>
        <div class="client-rec__container">
            <ReviewCard
                v-for="(vReview, index) in avReviewList"
                :key="vReview.post_id"
                v-if="index === 0 || index === 2"
                :idPost="vReview.post_id"
            ></ReviewCard>
        </div>
        <div class="client-rec__container">
            <ReviewCard
                v-for="(vReview, index) in avReviewList"
                :key="vReview.post_id"
                v-if="index === 1 || index === 3"
                :idPost="vReview.post_id"
            ></ReviewCard>
        </div>
        <a class="client-rec__button" href="/client-rec" :target="$sTargetLink">
            <HomeBtn sStatAlias="review_show_more"> Посмотреть все </HomeBtn>
        </a>
        <ModalReviewImage v-if="isModalReviewImageVisible" />
    </section>
</template>

<style lang="scss" scoped>
@import '../../view/mixins.scss';

.client-rec {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    @include lg {
        padding: 0;
    }

    @include md {
        height: 100%;
        flex-direction: column;
        max-width: 100%;
    }

    &__title {
        margin: 0;
        font-size: 22px;
        font-weight: 600;
        color: #2B2A29;
        line-height: 135%; /* 29.7px */
        width: 100%;

        @include md {
            font-size: 18px;
        }
    }

    &__container {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
        width: calc(50% - 12px);
        flex-direction: column;
        @include md {
            flex-direction: column;
            width: 100%;
        }
    }

    &__button {
        text-decoration: none;
        margin: 0 auto;
        @include xs {
            margin: inherit;
            button {
                width: 100%;
            }
        }
    }
}
</style>

import { ErrorSys, ModelRulesC, ModelValidatorSys } from '@63pokupki/components/lib';
import { PageCtrl } from './PageCtrl';

/**
 * Система логическая система
 * Логические функции управления приложением
 */
export class LogicSys {

	protected ctrl: PageCtrl;

	constructor(ctrl: PageCtrl) {
		this.ctrl = ctrl;
	}

	/**
     * Блок для валидации входных данных
     * Выбрасывает ошибку в случае не правильности данных
     */
	fValidData<RequestT>(vModelRules: ModelRulesC, data: RequestT): RequestT {
        const vErrorSys = new ErrorSys()
		const validator = new ModelValidatorSys(vErrorSys);

        // console.log('fValidData>>>',data);

		let validData: RequestT = null;
		if (validator.fValid(vModelRules.get(), data)) {

            // console.log('fValidData>>>', validator.getResult());
			validData = validator.getResult();
		} else {
            validData = validator.getResult();
            console.log('error_validData>>>',validData);
			vErrorSys.error('valid_data', 'Ошибка входных данных');
            console.error('valid_errors', validator.errorSys.getErrors())
		}

		return validData;
	}

}

import { mFormatDateTime, mFormatInvertedDateDotsTime } from '@/common/helpers/DateTimeH';
import { PageCtrl } from '@/system/PageCtrl';
import P63Vue from '@/system/P63Vue';
import Component from 'vue-class-component';
import { mIsClient } from '@/common/helpers/ContextH';
import { gfInitContextCtrl } from '@/system/ContextSys';
import { mGetDeviceType } from '@/common/helpers/DeviceTypeH';
import { BonusExpireInfoI } from '@/ifc/core/BonusActionUserE';
import { mPluralize } from '@/common/helpers/TextFormatterH';

@Component
export default class NotificationBonus extends P63Vue {
    /** Глобальный объект window на клиенте  */
    w;
    /** Фиксированная позиция ли у уведомления */
    bPositionFixed:boolean = false;

    /** Проверка очереди на показ бонусных уведомлений */
    fCheckShowQueueNotification(): void {
        this.fCloseNotification();
        const aBonusExpireNotShow: BonusExpireInfoI[] = this.ctrl.headerSys.header.bonus_expired_not_show;
        aBonusExpireNotShow.shift();
        if (aBonusExpireNotShow.length) {
            localStorage.setItem('bonus_expired_not_show', JSON.stringify(aBonusExpireNotShow));
        } else {
            localStorage.removeItem('bonus_expired_not_show');
        }

        const nQueueLength: number = aBonusExpireNotShow.length;
        if (nQueueLength) {
            setTimeout(() => {
                this.ctrl.headerSys.header.date_bonus_expire = aBonusExpireNotShow[0].date_bonus_expired || mFormatDateTime();
                this.ctrl.headerSys.header.number_of_bonuses = aBonusExpireNotShow[0].sum_bonus_expired || 0;
                this.ctrl.headerSys.header.is_show_notification_bonus = true;
            }, 1000);
        } else {
            localStorage.removeItem('bonus_expired_not_show');
        }
    }

    /** Закрыть уведомление */
    fCloseNotification(): void {
        window.removeEventListener('scroll', this.fToggleNotificationSticky);
        this.ctrl.headerSys.header.is_show_notification_bonus = false;
    }

    /** Убираем живочат на мобилке при бонусном уведомлении */
    fDestroyJivo() {
        const vJivoEl: HTMLElement = document.querySelector('stub-button');
        if (vJivoEl && this.w.jivo_destroy && this.isMobile) {
            this.w.jivo_destroy();
            vJivoEl.style.display = 'none';
        } else if (!vJivoEl && this.isMobile) {
            setTimeout(() => {
                this.fDestroyJivo();
            }, 500);
        }
    }

    /** Определить нужно ли "приклеить" уведомление */
    fToggleNotificationSticky() {
        const nCurrentYPosition: number = window.scrollY;

        if (nCurrentYPosition >= 150) {
            this.bPositionFixed = true;
        } else {
            this.bPositionFixed = false
        }
    }

    mounted() {
        window.addEventListener('scroll', this.fToggleNotificationSticky);
        this.w = <any>window;
        this.fDestroyJivo();
    }

    destroy() {
        this.w.jivo_init();
        const vJivoEl: HTMLElement = document.querySelector('stub-button');
        vJivoEl.style.display = 'block';
    }

    /** Мобильное ли устройство */
    get isMobile() {
        return mGetDeviceType(this.ctrl.deviceType).isMobile;
    }

    /** Флаг отображения бонусного уведомления (сгорание/начисление) */
    get bExpire(): boolean {
        return this.ctrl.headerSys.header.is_expire_bonus;
    }

    /** Количество бонусов */
    get sBonuses(): string {
        return `${this.ctrl.headerSys.header.number_of_bonuses} ${mPluralize(this.ctrl.headerSys.header.number_of_bonuses, [
            'бонус',
            'бонуса',
            'бонусов',
        ])}`;
    }

    /** Дата сгорания бонусов */
    get sDateExpire(): string {
        return mFormatInvertedDateDotsTime(this.ctrl.headerSys.header.date_bonus_expire);
    }

    /** Контроллер */
    get ctrl(): PageCtrl {
        return gfInitContextCtrl(mIsClient() ? this.$context : this.$ssrContext, PageCtrl);
    }
}

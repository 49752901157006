import Component from 'vue-class-component';
import { gfInitContextCtrl } from '@/system/ContextSys';
import { PageCtrl } from '@/system/PageCtrl';
import P63Vue from '@/system/P63Vue';
import { mIsClient } from '@/common/helpers/ContextH';
import { CategoryIDsI } from '@/ifc/core/EntitySQL/CategoryE';
import Landings from '@/common/el/MainMenuDesktop/Landings.vue';
import Purchases from '@/common/el/MainMenuDesktop/Purchases.vue';
import UserCityM from '@/common/el/UserCity/NewUserCityM.vue';

@Component({
    components: { Landings, Purchases, UserCityM },
})
export default class MainMenuMobile extends P63Vue {
    /** Соотнесение категорий с названиями иконок */
    ixCatIdByIconAlias: Record<number, string> = {
        6: 'woman',
        7: 'men',
        5: 'child',
        11: 'flacon',
        3: 'house',
        8: 'handbag',
        14: 'cake',
        141: 'toy',
        12: 'ball',
        16: 'palette',
        15: 'book',
        4: 'plant',
        137: 'gift',
        13: 'phone',
        10: 'trail',
        2: 'automobile',
    };
    /** Текущая выбранная категория */
    vCurrentCategory: CategoryIDsI = null;
    /** Показывать ли категории закупок */
    isPurchases: boolean = false;
    /** Переход на экран подкатегорий */
    isChild: boolean = false;
    /** Высота хедера для отступа сверху */
    nHeaderHeight: number = 0;

    /** Методы */
    /** Выбор категории */
    fSetSelectCategory(id: number) {
        const mainMenu = this.$refs.mainMenu as HTMLElement;
        mainMenu.scrollTop = 0;
        this.vCurrentCategory = this.ixCategoriesIndexed[id];

        if (this.isChild) {
            this.fSendActionOpenCategory(id);
        }
    }

    /** Отправить данные в статистику о клике по ссылке в меню */
    fSendActionOpenCategory(id: number) {
        this.ctrl.statSys.faPushClickToStat({
            page: location.pathname,
            type: 'menu_new',
            alias: 'link_click_menu_category',
            entity_id: id,
          });
    }


    /** Получить ссылку на категорию */
    fGetHref(idCategory: number) {
        let sHref: string = '';
        sHref = this.isPurchases
            ? this.ctrl.menuSys.fGetPurchaseCategoryUrl(idCategory)
            : this.ctrl.menuSys.fGetItemCategoryUrl(idCategory);
        return sHref;
    }

    mounted() {
        //Высота хедера для отступа сверху
        this.nHeaderHeight = document.querySelector('header.new-header')?.clientHeight - 2;

        // По умолчанию изначально выбрана категория 'Одежда и обувь для женщин', id = 8
        this.fSetSelectCategory(8);
    }

    /** Геттеры */
    get ctrl(): PageCtrl {
        return gfInitContextCtrl(mIsClient() ? this.$context : this.$ssrContext, PageCtrl);
    }

    /** Массив меню категорий */
    get ixCategories() {
        return this.ctrl.menuSys.menu.ixCategoriesGrouped;
    }

    /** Категории по id */
    get ixCategoriesIndexed() {
        return this.ctrl.menuSys.menu.ixCategoriesIndexed;
    }
}

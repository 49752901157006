/** Загрузка файлов-вложений в посты */
export namespace UploadFilePostR {

    export const Alias = 'UploadFilePost';
    /**
	 * Загрузить файл-вложение в пост c привязкой к посту по BASE64
	 */
    export namespace uploadPostFileWithLinkByBase64 {
        export const route = '/upload-file/upload-post-file-with-link-by-base-64';

        /** Параметры api запроса */
        export interface RequestI {
            post_id: number;        // ID поста, в который должен быть вложен файл
            real_filename?: string; // Реальное название файла
            file_base64: string;    // Файл в BASE64
        }

        /** Параметры api ответа */
        export interface ResponseI {
            post_file_id: number;   // ID файла
            real_filename: string;  // Реальное имя файла
            filename: string;       // Уникальное имя файла
            extension: string;      // Расширение
            is_big: boolean;        // Флаг "Имеет большой вариант (х1024)"
        }
    }

    /**
	 * Загрузить файл-вложение в пост без привязки к посту по BASE64
	 */
    export namespace uploadPostFileWithoutLinkByBase64 {
        export const route = '/upload-file/upload-post-file-without-link-by-base-64';

        /** Параметры api запроса */
        export interface RequestI {
            file_base64: string;    // Файл в BASE64
            real_filename?: string; // Реальное название файла
        }

        /** Параметры api ответа */
        export interface ResponseI {
            post_file_id: number;   // ID файла
            real_filename: string;  // Реальное имя файла
            filename: string;       // Уникальное имя файла
            extension: string;      // Расширение
            is_big: boolean;        // Флаг "Имеет большой вариант (х1024)"
        }
    }

    /**
	 * Создать связь вложений в пост и поста
	 */
    export namespace createPostFileLink {
        export const route = '/upload-file/create-post-file-link';

        /** Параметры api запроса */
        export interface RequestI {
            post_file_id_list: number[]; // Список ID файлов
            post_id: number;             // ID поста
        }

        /** Параметры api ответа */
        export interface ResponseI {
            // Нет ответа
        }
    }

    /**
	 * Удалить связь вложений в пост и поста
	 */
    export namespace deletePostFileLink {
        export const route = '/upload-file/delete-post-file-link';

        /** Параметры api запроса */
        export interface RequestI {
            post_file_id_list: number[]; // Список ID файлов
            post_id: number;             // ID поста
        }

        /** Параметры api ответа */
        export interface ResponseI {
            // Нет ответа
        }
    }
}

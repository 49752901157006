var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.bStubButtonDisplay),expression:"bStubButtonDisplay"}],class:[
        'stub-button',
        _vm.isMobile ? 'stub-button_type_mobile' : 'stub-button_type_desktop',
        _vm.bStubButtonBlock ? 'stub-button_is-loading' : '',
        _vm.isApp ? 'stub-button_app' : '',
        _vm.isTopOnlineAssistant ? 'top' : '',
    ],attrs:{"disabled":_vm.bStubButtonBlock,"aria-label":"Открыть онлайн-помощника"},on:{"click":_vm.fBlockStumpThenLoadAndOpenWidget}},[_c('span',{class:[
            'stub-button__text',
            _vm.isMobile ? 'stub-button__text_type_mobile' : 'stub-button__text_type_desktop',
        ]},[_vm._v("Онлайн-консультант")]),_c('div',{class:[
            'stub-button__logo-container',
            _vm.isMobile ? 'stub-button__logo-container_type_mobile' : 'stub-button__logo-container_type_desktop',
        ]})])
}
var staticRenderFns = []

export { render, staticRenderFns }
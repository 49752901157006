export namespace SubscriptionEmailR {
    /** Сменить основную почту и подписаться */
    export namespace subscribeAndChangeMail {
        export const route = '/subscription-email/subscribe-and-change-mail';

        export interface RequestI {
            email: string;    // Почта
            is_main?: boolean; // Установить казанную почту как основную
        }

        export interface ResponseI {
        }
    }

    /** Получить почту пользователя */
    export namespace getUserEmail {
        export const route = '/subscription-email/get-user-email';

        export interface RequestI {
        }

        export interface ResponseI {
            email: string;
        }
    }
}

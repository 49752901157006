import { PaginationI } from "./CommonI/PaginationI";
import { AppealDictonaryI, AppealI, AppealPriorityT, AppealStatusT } from "./EntitySQL/AppealE";
import { AppealFilterI } from "./EntitySQL/AppealFilterE";
import { AppealPurposeI } from "./EntitySQL/AppealPurposeE";
import { AppealTypeI } from "./EntitySQL/AppealTypeE";
import { AppealTypeResponsibleI } from "./EntitySQL/AppealTypeResponsibleE";
import { AppealUserI } from "./EntitySQL/AppealUserE";
import { PostI } from "./EntitySQL/PostE";
import { PostUserRatingI } from "./EntitySQL/PostUserRatingE";
import { ResponsibleI } from "./EntitySQL/ResponsibleE";
import { TopicI } from "./EntitySQL/TopicE";
import { PostFileImgInfoI } from "./ImgSQL/PostFileImgE";
import { UserDictionaryI, UserInfoPublicI } from "./UserUSQL/UserUE";


/** CRUD по заявкам */
export namespace AppealR {

    /** Получить статичные данные для страницы заявок */
    export namespace init {

        /** APIURL */
        export const route = '/appeal/init';

        /** Параметры api запроса */
        export interface RequestI {
        }

        /** Параметры api ответа */
        export interface ResponseI {
            list_appeal_type: AppealTypeI[]; // Список типов заявок
            list_responsible: ResponsibleI[]; // Список всех отвественных
            list_responsible_type: AppealTypeResponsibleI[]; // Список связей ответсвенных с типами заявок
            list_appeal_purpose: AppealPurposeI[];
        }
    }

    /**
     *
     * ===================================== CREATE ===========================================
     *
     */

    /** Создать заявку */
    export namespace createAppeal {
        /** APIURL */
        export const route = '/appeal/create-appeal';

        /** Параметры api запроса */
        export interface RequestI {
            title: string; // Заголовок поста
            text: string; // Текст поста
            topic_id: number; // ID темы
            appeal_type_id: number; // ID типа заявки
            appeal_priority?: AppealPriorityT; // Приоритет заявки
            url?: string; // Ссылка на страницу, на которой создавалась заявка
            subscribed?: boolean; // Флаг "Подписка на новые комментарии по заявке"
        }

        /** Параметры api ответа */
        export interface ResponseI {
            post: PostI; // Пост
            appeal: AppealI; // Заявка
        }
    }

    /** Создать тему-заявку */
    export namespace createAppealTopic {
        /** APIURL */
        export const route = '/appeal/create-appeal-topic';

        /** Параметры api запроса */
        export interface RequestI {
            title: string; // Заголовок темы
            appeal_type_id:number; // Тип заявки
        }

        /** Параметры api ответа */
        export interface ResponseI {
            topic: TopicI; // Тема
        }
    }

    /** Создать тип заявки */
    export namespace createAppealType {
        /** APIURL */
        export const route = '/appeal/create-appeal-type';

        /** Параметры api запроса */
        export interface RequestI {
            alias_type: string; // alias типа заявки
            name_type: string; // название типа заявки
        }

        /** Параметры api ответа */
        export interface ResponseI {
            appeal_type_id: number; // ID ти
        }
    }


    /** список типов заявки */
    export namespace listAppealType {
        /** APIURL */
        export const route = '/appeal/list-appeal-type';

        /** Параметры api запроса */
        export interface RequestI {
        }

        /** Параметры api ответа */
        export interface ResponseI {
            list_appeal_type:AppealTypeI[];
        }
    }

    /** Получить список тем по фильтру с пагинацией */
    export namespace getTopicByType {
        /** APIURL */
        export const route = '/appeal/get-topic-by-type';

        /** Параметры api запроса */
        export interface RequestI {
            appeal_type_id: number;
        }

        /** Параметры api ответа */
        export interface ResponseI {
            list_topic: TopicI[]; // Список тем
            list_appeal_purpose: AppealPurposeI[];
        }
    }

    /** Сохранить фильтр */
    export namespace saveFilter {
        export const route = '/appeal/save-filter';

        export interface RequestI {
            name: string; // Название фильтра
            filter_topic_id_list?: number[]; // Список ID тем
            filter_author_id_list?: number[]; // Список ID авторов заявок
            filter_responsible_id_list?: number[]; // Список ID ответственных по заявкам
            filter_priority?: AppealPriorityT; // Фильтр по приоритету заявки
            filter_appeal_type_id?: number; // Фильтр по типу заявки
            filter_status?: AppealStatusT; // Фильтр по статусу заявки
            filter_active?: boolean; // Фильтр по только активным заявкам
        }

        export interface ResponseI {
            filter_id: number; // ID сохранённого фильтра
        }
    }

    /**
     *
     * ===================================== READ ===========================================
     *
     */

    /** Получить заявку */
    export namespace getAppeal {
        /** APIURL */
        export const route = '/appeal/get-appeal';

        /** Параметры api запроса */
        export interface RequestI {
            appeal_id: number; // ID заявки
        }

        /** Параметры api ответа */
        export interface ResponseI {
            appeal: AppealI; // Заявка
            post: PostI; // Пост
            post_file_image_list: PostFileImgInfoI[]; // Список вложений-картинок постов
            post_file_not_image_list: PostFileImgInfoI[]; // Список вложений-не-картинок постов
            rating_list: PostUserRatingI[]; // Список оценок
            comment_count: { post_id: number; count_comment: number }[]; // Количество комментариев
            user_list: UserInfoPublicI[]; // Список пользователей
            sub_list: AppealUserI[]; // Список подписок
        }
    }

    /** Получить заявки по фильтру с пагинацией */
    export namespace getAppealListByFilter {
        /** APIURL */
        export const route = '/appeal/get-appeal-list-by-filter';

        /** Параметры api запроса */
        export interface RequestI {
            page?: number; // Страница
            limit?: number; // Количество на странице
            appeal_filter_id?: number // ID сохранённого фильтра
            topic_id_list?: number[]; // Список ID тем
            author_id_list?: number[]; // Список ID авторов заявок
            post_id_list?: number[]; // Список ID определенных заявок
            filter_priority?: AppealPriorityT; // Фильтр по приоритету заявки
            filter_appeal_type_id?: number; // Фильтр по ID типа заявки
            filter_status?: AppealStatusT; // Фильтр по статусу заявки
            filter_active?: boolean; // Фильтр по только активным заявкам
            filter_date_end?: string; // конец периода фильтрации
            filter_date_start?: string; // начало периода фильтрации
            search_appeal?: string; // Поиск по наименованию заявки
            responsible_id_list?: number[]; // Список ID ответственных по заявкам
        }

        /** Параметры api ответа */
        export interface ResponseI {
            appeal_list: AppealI[]; // Заявки
            post_list: PostI[]; // Посты
            post_file_image_list: PostFileImgInfoI[]; // Список вложений-картинок постов
            post_file_not_image_list: PostFileImgInfoI[]; // Список вложений-не-картинок постов
            rating_list: PostUserRatingI[]; // Список оценок
            comment_count: { post_id: number; count_comment: number }[]; // Количество комментариев
            user_list: UserInfoPublicI[]; // Список пользователей
            sub_list: AppealUserI[]; // Список подписок
            pagination: PaginationI; // Пагинация
        }
    }

    /** Получить сохраненные фильтры пользователя */
    export namespace getUserFilterList {
        /** APIURL */
        export const route = '/appeal/get-user-filter-list';

        /** Параметры api запроса */
        export interface RequestI {
            // Нет параметров
        }

        /** Параметры api ответа */
        export interface ResponseI {
            appeal_filter_list: AppealFilterI[]; // Фильтры по заявкам
        }
    }

    /** Получить список ников, найденных по поисковой строке */
    export namespace listUser {
        /** APIURL */
        export const route = '/appeal/list-user';

        /** Параметры api запроса */
        export interface RequestI {
            search: string; // Данные для поиска ников
        }

        /** Параметры api ответа */
        export interface ResponseI {
            user_list: UserDictionaryI[]; // Список ников
        }
    }

    /** Получить список названий постов, реализующих заявки, найденных по поисковой строке */
    export namespace listAppeal {
        /** APIURL */
        export const route = '/appeal/list-appeal-name';

        /** Параметры api запроса */
        export interface RequestI {
            search: string; // Данные для поиска названия завки
        }

        /** Параметры api ответа */
        export interface ResponseI {
            post_data: AppealDictonaryI[]; // Список названий заявок
        }
    }

    /** Получить ответственных по типу заявки */
    export namespace getResponsible {

        /** APIURL */
        export const route = '/appeal/get-responsible';

        /** Параметры api запроса */
        export interface RequestI {
            appeal_type_id?: number; // ID типа заявки
        }

        /** Параметры api ответа */
        export interface ResponseI {
            list_responsible: ResponsibleI[]; // Список ответственных
        }
    }

    /** Получить список авторов */
    export namespace getUser {

        /** APIURL */
        export const route = '/appeal/get-user';

        /** Параметры api запроса */
        export interface RequestI {
            list_user_id: number[];
        }

        /** Параметры api ответа */
        export interface ResponseI {
            list_user: { id: number; login: string }[] // Список авторов
        }
    }

    /** Получить типы заявок */
    export namespace getAppealTypeList {

        /** APIURL */
        export const route = '/appeal/get-appeal-type-list';

        /** Параметры api запроса */
        export interface RequestI {
            // нет запроса
        }

        /** Параметры api ответа */
        export interface ResponseI {
            list_appeal_type: AppealTypeI[]; // Типы заявок
        }
    }

    /**
     * ========================================================================================
     */

    /** Добавить/убрать подписку на заявку */
    export namespace changeSubscribe {
        /** APIURL */
        export const route = '/appeal/change-subscribe';

        /** Параметры api запроса */
        export interface RequestI {
            appeal_id: number; // ID заявки
        }

        /** Параметры api ответа */
        export interface ResponseI {
            in_subscribed: boolean; // Статус подписки на заявку
            appeal: AppealI; // Информация о заявке
            total_sub: { user_id: number; username: string }[]; // Список пользователей поставивших лайк
        }
    }

    /** Добавить/убрать ответсвенного для данного типа заявки */
    export namespace changeResponsible {
        /** APIURL */
        export const route = '/appeal/change-responsible';

        /** Параметры api запроса */
        export interface RequestI {
            responsible_id?: number; // ID ответственного
            responsible_name: string; // ФИО ответственного
            appeal_type_id: number; // Тип заявки
        }

        /** Параметры api ответа */
        export interface ResponseI {
            responsible?: ResponsibleI; // созданный ответственный
        }
    }

    /**
     *
     * ===================================== UPDATE ===========================================
     *
     */

    /** Редактировать заявку */
    export namespace editAppeal {
        /** APIURL */
        export const route = '/appeal/edit-appeal';

        /** Параметры api запроса */
        export interface RequestI {
            appeal_id: number; // ID заявки
            appeal_type_id?: number; // ID типа заявки
            priority?: AppealPriorityT; // Приоритет заявки
            status?: AppealStatusT; // Статус заявки
            title?: string; // Заголовок поста
            text?: string; // Текст поста
            comment?: string; // Комментарий к отклоненной заявке
        }

        /** Параметры api ответа */
        export interface ResponseI {
            appeal: AppealI; // Заявка
            post: PostI; // Пост
        }
    }

    /** Редактировать тип заявки */
    export namespace editAppealType {
        /** APIURL */
        export const route = '/appeal/edit-appeal-type';

        /** Параметры api запроса */
        export interface RequestI {
            appeal_type_id: number; // ID заявки
            alias_type: string; // Название альяса
            name_type: string; // Наименование типа
        }

        /** Параметры api ответа */
        export interface ResponseI {
            // ответ не предусмотрен
        }
    }

    /** Перенести заявки в другую тему */
    export namespace updateAppealTopic {
        /** APIURL */
        export const route = '/appeal/update-appeal-topic';

        /** Параметры api запроса */
        export interface RequestI {
            appeal_id_list: number[]; // Список ID заявок
            topic_id: number; // ID темы
        }

        /** Параметры api ответа */
        export interface ResponseI {
            // Нет ответа
        }
    }

    /** Указать ответственного по заявке */
    export namespace updateAppealResponsible {
        /** APIURL */
        export const route = '/appeal/update-appeal-responsible';

        /** Параметры api запроса */
        export interface RequestI {
            appeal_id: number; // ID заявки
            responsible_id: number; // ID ответственного
        }

        /** Параметры api ответа */
        export interface ResponseI {
            // Нет ответа
        }
    }

    /**
     *
     * ===================================== DELETE ===========================================
     *
     */

    /** Удалить фильтр */
    export namespace deleteFilter {
        export const route = '/appeal/delete-filter';

        export interface RequestI {
            filter_id_list: number[]; // Список ID фильтров
        }

        export interface ResponseI {
            // Нет ответа
        }
    }

    /** Удалить заявку */
    export namespace deleteAppeal {
        export const route = '/appeal/delete-appeal';

        export interface RequestI {
            appeal_id: number; // id удаляемой заявки
            comment: string; // Комментарий к удаляемой заявке
        }

        export interface ResponseI {
            // Нет ответа
        }
    }

    /** Удалить тип заявки */
    export namespace deleteAppealType {
        export const route = '/appeal/delete-appeal-type';

        export interface RequestI {
            appeal_type_id: number; // id удаляемого типа заявки
        }

        export interface ResponseI {
            // Нет ответа
        }
    }
}

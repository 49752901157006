export namespace SocialLinkN {

/** Перечень соцсетей */
    export enum SocialLinkT {
        'viber' = 'viber',
        'telegram' = 'telegram',
        'odnoklassniki' = 'odnoklassniki',
        'dzen' = 'dzen',
        'vkontakte' = 'vkontakte',
        'youtube' = 'youtube',
    }
}

import { render, staticRenderFns } from "./NewMenuManagement.vue?vue&type=template&id=301991b6&scoped=true"
import script from "./NewMenuManagement.ts?vue&type=script&lang=ts&external"
export * from "./NewMenuManagement.ts?vue&type=script&lang=ts&external"
import style0 from "./NewMenuManagement.vue?vue&type=style&index=0&id=301991b6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "301991b6",
  null
  
)

export default component.exports
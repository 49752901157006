<script lang="ts">
import Component from 'vue-class-component';
import SelectCityModalSource from './SelectCityModalSource';

@Component
export default class SelectCityModalM extends SelectCityModalSource {}
</script>

<template>
    <div class="select-city-modal wrapper-mobile" :class="{ 'popup-active': isPopupActive }">
        <div class="close" @click="fClose()">
            <Icon sIconName="close" sWidth="20" sHeight="20" />
        </div>
        <div class="header">Выберите город</div>
        <ds-input-search class="city-search" :sValue="sSearch" sSize="lg" :onInput="onInput" />
        <div class="city-list">
            <template v-if="isCitiesLoaded">
                <div
                    v-for="vCity in aSearchedCities"
                    :key="vCity.city_id"
                    class="item"
                    :class="{
                        selected: vCity.city_name === sCity,
                    }"
                    @click="fSelectCity(vCity)"
                >
                    {{ vCity.city_name }}
                    <svg
                        v-if="vCity.city_name === sCity"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M9.78407 13.9295L6.25598 10.4014L4.5 12.1657L9.78392 17.4496L19.4711 7.76142L17.7074 6L9.78407 13.9295Z"
                            fill="#36A5F2"
                        />
                    </svg>
                </div>
            </template>
            <div v-else class="ssc ssc-city-list">
                <div v-for="i in 10" :key="i" class="ssc-card ssc-wrapper">
                    <div class="ssc-head-line w-60 ml mb"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.ssc-wrapper {
    padding: 5px !important;
}
.wrapper-mobile {
    width: 100%;
    height: 100%;
    background: white;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 15;
    transition: top 0.2s ease-in-out;
    display: flex;
    flex-direction: column;
}
.popup-active {
    top: 60px;
    height: calc(100vh - 60px);
}
.close {
    position: absolute;
    width: 18px;
    height: 18px;
    top: 14px;
    right: 25px;
    cursor: pointer;
    z-index: 5;
}
.select-city-modal {
    .header {
        color: #1d1e1f;
        font-family: Open Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        margin-left: 24px;
        margin-top: 14px;
    }
    .city-search {
        margin-left: 24px;
        margin-right: 24px;
        margin-top: 18px;
        margin-bottom: 18px;
    }
    .city-list {
        overflow-y: auto;
        margin-bottom: 12px;

        .item {
            cursor: pointer;
            padding-left: 24px;
            padding-right: 24px;
            height: 40px;
            color: #1d1e1f;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
            display: flex;
            align-items: center;
            &:hover {
                background: #e8f2f9;
            }
        }
    }
}
</style>

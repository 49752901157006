<script lang="ts">
import Component from 'vue-class-component';
import P63Vue from '@/system/P63Vue';
import { Prop } from 'vue-property-decorator';
import { LandingMenuI } from '@/ifc/core/EntitySQL/P63SearchLandingInfoE';
import { CategoryIDsI } from '@/ifc/core/EntitySQL/CategoryE';
import { PageCtrl } from '@/system/PageCtrl';
import { gfInitContextCtrl } from '@/system/ContextSys';
import { mIsClient } from '@/common/helpers/ContextH';

@Component
export default class Landings extends P63Vue {
    /** Категория лендингов */
    @Prop({ type: Object, default: () => {} }) vCategory: CategoryIDsI;
    /** Список лендингов */
    @Prop({ type: Array, default: () => [] }) aLandings: LandingMenuI[];

    /** Количество показываемых элементов */
    nLandingsCount = 6;
    /** Показать все */
    isShowAll = false;

    /** Получить ссылку на категорию */
    fGetHref(idCategory: number, sLandingAlias?: string) {
        let sHref: string = '';
        sHref = this.ctrl.menuSys.fGetItemCategoryUrl(idCategory);
        if (sLandingAlias) {
            sHref += `/${sLandingAlias}`;
        }
        return sHref;
    }

    /** Отправить данные в статистику о клике по ссылке в меню категорий*/
    fSendActionOpenCategory(id: number) {
        this.ctrl.statSys.faPushClickToStat({
            page: location.pathname,
            type: 'menu_new',
            alias: 'link_click_menu_category',
            entity_id: id,
          });
    }

    /** Геттеры */
    get ctrl(): PageCtrl {
        return gfInitContextCtrl(mIsClient() ? this.$context : this.$ssrContext, PageCtrl);
    }
}
</script>

<template>
    <div class="landings">
        <a class="landings__parent" v-if="vCategory" :href="fGetHref(vCategory.category_id)" @click="fSendActionOpenCategory(vCategory.category_id)">
            {{ vCategory.category_name }}
        </a>
        <a
            class="landings__item"
            v-for="landing in aLandings"
            :class="{ show: isShowAll }"
            :key="landing.alias"
            :href="fGetHref(landing.category_id, landing.alias)"
            @click="fSendActionOpenCategory(landing.category_id)"
        >
            {{ landing.display_name ? landing.display_name : landing.h1_header }}
        </a>
        <button class="landings__btn" v-if="aLandings.length > nLandingsCount" @click="isShowAll = !isShowAll">
            {{ isShowAll ? 'Скрыть' : 'Ещё' }}
            <Icon :class="{ hide: isShowAll }" sIconName="pointer-down" sWidth="20" sHeight="20" />
        </button>
    </div>
</template>

<style scoped lang="scss">
.landings {
    display: flex;
    flex-direction: column;
    width: 204px;
    margin-bottom: 24px;

    @media (max-width: 1160px) {
        width: 169px;
    }

    &__parent {
        color: #2b2a29;
        font-weight: 600;
        line-height: 150%; /* 21px */
        margin-bottom: 8px;
        text-decoration: none;
        transition: color 0.2s ease;

        &:hover {
            color: #36a6f2;
        }
    }

    &__item {
        display: none;
        padding: 2px 0;
        color: #606266;
        line-height: 150%; /* 21px */
        text-decoration: none;
        transition: color 0.2s ease;

        &:nth-child(-n + 6) {
            display: inherit;
        }

        &:hover {
            color: #36a6f2;
        }

        &.show {
            display: inherit;
        }
    }

    &__btn {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 4px;
        color: #36a6f2;
        font-weight: 600;
        line-height: 150%; /* 21px */
        margin-top: 4px;
        transition: color 0.2s ease;

        .ds-icon {
            fill: #36a6f2;
            transition: transform 0.2s ease;

            &.hide {
                transform: rotate(180deg);
            }
        }

        &:hover {
            color: #0080f6;

            .ds-icon {
                fill: #0080f6;
            }
        }
    }
}
</style>

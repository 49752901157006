import { mGetTargetLink } from '@/common/helpers/TargetLinkH';

/** Выбирает слово для числа в правильной форме */
export const pluralize = (number, words) => words[
      number % 10 === 1 && number % 100 !== 11
          ? 0
          : number % 10 >= 2 &&
                number % 10 <= 4 &&
                (number % 100 < 10 || number % 100 >= 20)
              ? 1
              : 2
];

export const mPluralize = (number: number, strings: string[]): string => {
  return pluralize(number, strings);
};

/** Возвращает строку с заглавной буквой */
export const mCapitalize = (str: string): string => {
  if (!str || typeof str !== 'string') return null;
  return str[0].toUpperCase() + str.slice(1);
};

/**Удаляет смайлы */
export const mRemoveSmile = (s: string): string => {
  return s.replace(/[^http|^https]\:[^а-яА-Я\s]{1,}\s|\:[A-Za-z]{1,}:|=@|\:\?|\:[^>а-яА-Я]+\:|[^a-zA-Zа-яА-Я0-9]\)|\[\/quote\]|@}[\S]{1,}/g, '');
  // return s.replace(/\:[^>]+\:/g, '') // Старый вариант
}

/**Удаляет эмотиконы */
export const mRemoveEmotiсons = (s: string): string => {
  return s.replace(/((?::|;|=)(?:-)?(?:\)|D|P))/g, '')
}

/** Найти ссылку по тексту и заменить ее на тег a */
export const mReplaceTextLink = (s: string, sClass?: string): string => {
    return s.replace(
        /(^|\s)(http|ftp|https):\/\/([\wЁёА-я_-]+(?:(?:\.[\w_-]+)+))([\wЁёА-я.,@?^=%(&|&amp;):\/~+#-]*[\wЁёА-я@?^=%&\/~+#-])/g,
        match => {
            const sWithoutNewLine = match.replaceAll('\n', '')
            return `<a class="${sClass}" target="${mGetTargetLink()}" href="${sWithoutNewLine}">${match}</a>`
        }
    )
}

/**Удаляет <br> */
export const mReplaceBR = (s: string): string => {
  return s.replace(/(?:\\[rn]|[\r\n]+)+/g, '<br>')
          .replace("\r", '<br>')
          .replace("\n", '<br>')
}

/**Удаляет BBCode */
export const mRemoveBBCode = (s: string): string => {
  let sText = s.replace(/\[[^\[]{1,}\[\/[^а-яА-Я]{1,}\]/g, '<br>');

  if (sText.startsWith('<br>')) {
    sText = sText.slice(4);
  }

  return sText;

  // return s.replace(/\[[^>]+\]/g, '') // Старый вариант
}

/** Экранировать тэговые символы */
export const mEscapeTagСhars = (sString: string): string => {
    const escapeReplacements = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#39;'
    };

    return sString.replace(/[&<>"']/g, match => escapeReplacements[match])
}

/**Удаляет эмотиконы */
export const mRemoveSizeAndColor = (s: string): string => {
  return s.replace(/size\S{1,}>|color\S{1,}>/g, '')
}

/**Удаляет эмотиконы */
export const mRemoveMoji = (s: string): string => {
  return s.replace(/(MOJI[\s\S]+?<\/EMOJI>)/g, '')
}

/**Удаляет тэги */
export const mRemoveTag = (s: string): string => {
  return s.replace(/<[^>]+>/g, '')
}

/**Очищает описание товара от смайлов*/
export const mClearItemDescription = (s: string): string => {
  return mRemoveSmile(mRemoveBBCode(mReplaceBR(s)))
}

/**Очищает описание товара от эмотиконов*/
export const mClearEmoticonsItemDescription = (s: string): string => {
  return mRemoveEmotiсons(mRemoveBBCode(mReplaceBR(s)))
}

/**
 * Обрезает описание товара
 */
 export const fCutDescription = (s: string): string => {
  if (s.length > 200) {
    // Проверяем чтоб в окончание строки не осталось незакрытого тега <br>
    const sDescription = s.slice(0, nMinDescrLength).replace(/(\<br>|\<[b,r]{1,}|\<)+$/ig, '')
    return sDescription + '...'; }
  return s;
};

/**
 * Обрезает описание на странице продукта
 */
 export const fCutProductDescription = (s: string): string => {
  if (s.length > 200) { return s.slice(0, nMinDescrLength); }
  return s;
};

/**
 * Обрезает описание товара в модалках товара
 */
 export const fCutModalsDescription = (s: string): string => {
  return s.length > 100 ? s.slice(0, nMinModalsDescrLength) + '...'  :  s;
};

export const nMinModalsDescrLength = 95

export const nMinDescrLength = 150

// Конструктор стилей иконок
export const mCreateIcons = (status: number) => {
  switch (status) {
    case 1:
      return 'assembling-icon icon-box';
    case 2:
      return 'fix-icon icon-box';
    case 3:
      return 'stop-icon icon-box';
    case 4:
      return 'icon-sandglass';
    case 5:
      return 'add-order-icon icon-purchase-status-add-order';
    case 6:
      return 'icon-ruble';
    case 7:
      return 'icon-car';
    case 8:
      return 'icon-sort';
    case 9:
      return 'icon-boxes';
    case 11:
      return 'icon-stop';
    case 12:
      return 'icon-status-edit';
  }
};

// Конструктор стилей текста лэйбла
export const mCreateText = (status: number) => {
  switch (status) {
    case 1:
      return 'ds-purchase-status__assembling-txt';
    case 2:
      return 'ds-purchase-status__fix-txt';
    case 3:
      return 'ds-purchase-status__stop-txt';
    case 4:
      return 'ds-purchase-status__waiting-acc-txt';
    case 5:
      return 'ds-purchase-status__add-order-txt';
    case 6:
      return 'ds-purchase-status__payment-txt';
    case 7:
      return 'ds-purchase-status__waiting-cargo-txt';
    case 8:
      return 'ds-purchase-status__sorting-txt';
    case 9:
      return 'ds-purchase-status__delivery-txt';
    case 11:
      return 'ds-purchase-status__fail-txt';
    case 12:
      return 'ds-purchase-status__edit-txt';
  }
};

// Конструктор подходящего именования периода
export const mPeriodName = (days: number) => {
    let result = ''
    const weeks = Math.floor(days / 7)
    const months = Math.floor(days / 30)
    const years = Math.floor(days / 365)

    if (days === 1) {
        result = `${days} день`
    } else if (days < 5) {
        result = `${days} дня`
    } else if (days < 7) {
        result = `${days} дней`
    } else if (days < 14) { // меньше 14 дней - одна неделя с учетом округления
        result = `${weeks} неделю`
    } else if (days < 28) { // меньше 4х недель
        result = `${weeks} недели`
    } else if (days < 60) {
        result = `${months ? months : 1} месяц` //если больше 4х недель но меньше месяца(30 дн)
    } else if (days < 150) { // меньше 4х месяцев
        result = `${months} месяца`
    } else if (days < 365) {
        result = `${months} месяцев`
    } else if (days < 730) { // меньше 2х лет
        result = `${years} год`
    } else if (days < 1825) { // меньше 5 лет
        result = `${years} года`
    } else {
        result = `${years} лет`
    }

    return result
};

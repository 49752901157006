import { Component, Prop } from 'vue-property-decorator';
import P63Vue from '@/system/P63Vue';
import { BannerI } from '@/ifc/core/CommonI/BannerI';
import { gImageS } from '@/common/service/ImageS';

@Component
export default class BrandsSource extends P63Vue {
    @Prop({ type: Array }) aBannerSelection: BannerI[]; // подборки в виде баннеров

    /** Формируем ссылку для картинки в формате jpg или webp*/
    fGetImgUrl(filename: string, isWebp: boolean = false) {
        return isWebp ? gImageS.fBannerImageUrlWebp(filename) : gImageS.fBannerImageUrlJpg(filename)
    }
}

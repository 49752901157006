<script lang="ts">
import Component from 'vue-class-component';
import P63Vue from '@/system/P63Vue';
import UserMenu from '@/common/el/UserMenu/UserMenu';
import { Prop, Watch } from 'vue-property-decorator';
import { UserStoreI } from '@/system/UserSys';
import { clickOutside } from '@/ds/directives/clickOutside';
import { PageCtrl } from '@/system/PageCtrl';
import { gfInitContextCtrl } from '@/system/ContextSys';
import { mIsClient } from '@/common/helpers/ContextH';
import { mTotalPageScrollControl } from '@/common/helpers/Scroll';

@Component({
    components: { UserMenu },
    directives: { 'click-outside': clickOutside },
})
export default class HeaderPersonLinks extends P63Vue {
    /** Информация о пользователе */
    @Prop({ type: Object }) vUserInfo: UserStoreI;
    /** Показывать ли административное меню */
    @Prop({ type: Boolean, default: false }) isShowManagerMenu: boolean;

    /** Открыт ли меню пользователя */
    isUserMenuShow = false;

    /** Открыт ли меню избранное */
    isFavoriteMenuShow = false;

    /** При открытом меню закрываем мегаменю  */
    @Watch('isUserMenuShow')
    fToggleMainMenu() {
        if (this.isUserMenuShow) {
            this.ctrl.headerSys.header.is_open_multi_level_menu_mobile = false;
            this.ctrl.headerSys.header.is_open_multi_level_menu_desktop = false;
            if (this.ctrl.status.is_mobile) {
                setTimeout(() => window.scroll(0, 0), 100);
                document.getElementsByTagName('html')[0].style.maxHeight = window.innerHeight + 'px';
            }
        } else {
            if (this.ctrl.status.is_mobile) {
                document.getElementsByTagName('html')[0].style.maxHeight = 'auto';
            }
        }

        if (!this.ctrl.status.is_desktop) {
            if (this.isUserMenuShow) {
                setTimeout(() => {
                    mTotalPageScrollControl('hide');
                }, 100);
            } else {
                mTotalPageScrollControl('show');
            }

            // полностью убираем возможность скролла на странице, хелпера mPageScrollControl не достаточно для iOS
            this.isUserMenuShow ? this.fPageScrollHide() : this.fPageScrollShow();
        }
    }

    /** Запрет скролла при открытом MainMenu */
    fPageScrollHide() {
        const HTMLElement = document.getElementsByTagName('html') as HTMLCollectionOf<HTMLElement>;
        const HTMLBodyElement = document.getElementsByTagName('body') as HTMLCollectionOf<HTMLElement>;
        const isDesktop = window.innerWidth > 1023;

        if (!isDesktop) {
            HTMLElement[0].style.overflow = 'hidden';
            HTMLBodyElement[0].style.overflow = 'hidden';
        }
    }

    /** Вернуть скролл */
    fPageScrollShow() {
        const HTMLElement = document.getElementsByTagName('html') as HTMLCollectionOf<HTMLElement>;
        const HTMLBodyElement = document.getElementsByTagName('body') as HTMLCollectionOf<HTMLElement>;
        HTMLElement[0].style.overflow = 'auto';
        HTMLBodyElement[0].style.overflow = 'auto';
    }

    /** Закрытие пользовательского меню */
    fCloseMenu() {
        this.isUserMenuShow = false;
    }

    /** Переключение пользовательского меню */
    fToggleMenu() {
        if (window.innerWidth < 1023) {
            window.scrollTo(0, 0);
            setTimeout(() => {
                window.scroll({ top: -1, left: 0, behavior: 'smooth' });
            }, 10);
        }
        this.isUserMenuShow = !this.isUserMenuShow;
    }

    /** Количество уведомлений */
    get nUserNotification() {
        const nMessages = this.vUserInfo.countUnreadPrivmsg > 0 ? this.vUserInfo.countUnreadPrivmsg : null;
        const nSystemNotifications =
            this.vUserInfo.countUnreadSystemNotifications > 0 ? this.vUserInfo.countUnreadSystemNotifications : null;
        return nMessages + nSystemNotifications;
    }

    // Основные
    get ctrl(): PageCtrl {
        return gfInitContextCtrl(mIsClient() ? this.$context : this.$ssrContext, PageCtrl);
    }
}
</script>

<template>
    <div class="header-person-links">
        <template v-if="vUserInfo.isAuth">
            <button class="header-person-links__item" @click.stop="fToggleMenu">
                <Icon sIconName="account" sWidth="24" sHeight="24" :nNotification="nUserNotification" />
                <span class="header-person-links__text">{{ vUserInfo.info.username }}</span>
            </button>

            <UserMenu
                class="header-person-links__user-menu"
                :class="{ 'user-menu-top': isShowManagerMenu }"
                v-if="isUserMenuShow"
                :bOpenMenu="isUserMenuShow"
                @close="fCloseMenu"
                v-click-outside="() => (isUserMenuShow = false)"
            />

            <button
                class="header-person-links__item mobile-hide"
                @click.stop="isFavoriteMenuShow = !isFavoriteMenuShow"
            >
                <Icon sIconName="heart" sWidth="24" sHeight="24" />
                <span class="header-person-links__text">Избранное</span>
            </button>

            <FavoriteMenu v-if="isFavoriteMenuShow" v-click-outside="() => (isFavoriteMenuShow = false)" />

            <a class="header-person-links__item first" href="/notification">
                <Icon sIconName="bell-regular" sWidth="24" sHeight="24" :nNotification="vUserInfo.countUnreadEvents" />
                <span class="header-person-links__text">Уведомления</span>
            </a>
        </template>

        <a v-else href="/auth" class="header-person-links__item">
            <Icon sIconName="account" sWidth="24" sHeight="24" />
            <span class="header-person-links__text">Вход</span>
        </a>

        <a class="header-person-links__item" href="/cart">
            <Icon sIconName="cart" sWidth="24" sHeight="24" :nNotification="vUserInfo.countReadyToPayOrders" />
            <span class="header-person-links__text">Корзина</span>
        </a>
    </div>
</template>

<style scoped lang="scss">
.header-person-links {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: end;

    @media (max-width: 767px) {
        min-width: auto;
    }

    &__item {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        cursor: pointer;
        background-color: transparent;
        border: none;
        padding: unset;
        text-decoration: none;
        -webkit-appearance: none;
        min-width: 60px;
        max-width: 80px;
        margin-right: 16px;

        @media (max-width: 767px) {
            min-width: 45px;
            max-width: 67px;
            margin-right: 12px;

            &.first {
                order: -1;
            }

            .header-person-links__text {
                font-size: 10px;
                line-height: normal;
            }

            .ds-icon {
                ::v-deep svg {
                    width: 26px;
                    height: 26px;
                }
            }
        }

        .ds-icon {
            transition: fill 0.2s ease;
            fill: #606266;

            &::after {
                top: 0;
                right: 0;
                transform: translateX(50%);
            }
        }

        &:hover {
            .ds-icon {
                fill: #36a6f2;
            }

            span {
                color: #36a6f2;
            }
        }

        &:last-child {
            margin-right: 0;
        }

        @media screen and (max-width: 768px) {
            &.mobile-hide {
                display: none;
            }
        }
    }

    &__text {
        max-width: 100%;
        overflow: hidden;
        user-select: none;
        white-space: nowrap;
        text-decoration: none;
        text-overflow: ellipsis;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        text-decoration-color: unset;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        transition: color 0.2s ease;
        color: #606266;
    }

    &__user-menu {
        z-index: 101;
        ::v-deep {
            @media (max-width: 768px) {
                &:before {
                    top: 45px;
                }
                &.user-menu-top:before {
                    top: 86px;
                }

                &.user-menu-top {
                    .vertical-menu.user-profile-menu.profile-menu {
                        top: 86px;
                    }
                }

                .vertical-menu.user-profile-menu.profile-menu {
                    width: auto !important;
                    left: unset;
                    top: 45px;
                    border-radius: unset;
                    box-shadow: unset;
                    border-top: 1px solid rgba(49, 140, 202, 0.25);
                }
            }
        }
    }
}
</style>

/** Тип устройства */
export namespace DeviceTypeN {
    export interface DeviceTypeI {
        isMobile: boolean;
        isTablet: boolean;
        isDesktop: boolean;
    }

    export enum DeviceTypeT {
        mobile = 'mobile',
        tablet = 'tablet',
        desktop = 'desktop',
    }
}

<script lang="ts">
import Component from 'vue-class-component';
import UserCitySource from './UserCitySource';
import SelectCityModalD from '../SelectCityModal/SelectCityModalD.vue';

@Component({
    components: {
        SelectCityModalD,
    },
})
export default class UserCityD extends UserCitySource {}
</script>

<template>
    <div v-if="vCity" class="user-city">
        <div class="city-button" @click="fCityClick()">
            <Icon sIconName="marker" sWidth="20" sHeight="20" />
            {{ vCity.city_name }}
        </div>

        <CityTooltip v-if="isShowTooltip" :sCity="vCity.city_name" :idCity="vCity.city_id" @close="fCloseTooltip" />

        <SelectCityModalD v-if="isShowSelectCity" :sCity="vCity.city_name" @closeTooltip="fCloseTooltip" />
    </div>
</template>

<style lang="scss" scoped>
.ssc-wrapper {
    padding: 6px 24px !important;
}
.user-city {
    position: relative;
}
.city-button {
    display: flex;
    align-items: end;
    color: #606266;
    cursor: pointer;
    position: relative;
    gap: 4px;
    font-size: 12px;
    font-weight: 400;
    line-height: 150%;

    &:hover {
        text-decoration: underline;
    }

    .ds-icon {
        fill: #606266;
    }
}
.city-tooltip {
    top: 25px !important;
}
</style>

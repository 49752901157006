<script lang="ts">
    import Component from 'vue-class-component';
    import  HomeStory  from './home-story';

    @Component
    export default class HomeStoryM extends HomeStory {}
</script>

<template>
    <div>
        <div class="container-stories">
            <div class="home-stories">
                <div  class="home-stories__item" v-for="(item, index) in ctrl.list.all_banners_collection" :key="item.id">
                    <div
                    class="home-stories__item-img-border"
                    :class="{
                        'viewed': ixChekecdStory[item.id]
                    }"
                    @click="fOpenPopup(index, item.id)"
                    >
                      <picture>
                        <source :srcset="sBannerImageLinkWebp(item.filename)" type="image/webp">
                        <img class="home-stories__item-img" :alt="item.title" :src="sBannerImageLinkJpg(item.filename)" />
                      </picture>
                    </div>
                    <span class="home-stories__item-title">{{ fFormatTitle(item.title) }}</span>
                </div>
            </div>
        </div>
        <div v-if="isSliderOpen">
            <HomePopupStory />
        </div>
    </div>


</template>

<style lang="scss" scoped>
.home-stories {
    display: flex;
    flex-direction: row;
    justify-content: start;
    overflow-x: scroll;
    padding: 0 20px 0 0;

    &__item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        margin-top: 20px;
        margin-left: 20px;
        margin-bottom: 20px;

        &-title {
            font-size: 13px;
            font-weight: 300;
        }

        &-img {
            display: block;
            margin: 0 auto;
            height: 60px;
            width: 60px;
            border-radius: 50%;

            &-border {
                border-radius: 50%;
                border: 2px solid rgb(209, 70, 252);
                padding: 4px;
                transition: all 0.15s ease-in;
                cursor: pointer;
            }&-border:hover {
                transition: border 0.15s ease-in-out;
                border: 2px solid rgb(192, 192, 192);
            }
        }
    }
    .viewed {
        border-radius: 50em;
        border: 2px solid rgb(190, 190, 190);
        padding: 4px;
        cursor: pointer;

        &:hover {
            transition: border 0.15s ease-in-out;
            border: 2px solid rgb(90, 90, 90);
        }
    }
}

.container-stories{
        overflow: hidden;
        height: 110px;
}
</style>

import * as conf from '@/configs/config.public';
import { PurchasePageAuthR } from '@/ifc/core/PurchasePageAuthR';
import { QuerySys } from '@63pokupki/front';
import { catchErrorApiResponse } from '../common/helpers/ErrorHandler';
import { PageCtrl } from './PageCtrl';
import { gfInitContextQuery } from './ContextSys';
import { mIsClient } from '@/common/helpers/ContextH';

/** Хранилище системы (список желаний) */
class WishStoreI {
    toJSON() {
        return { ...this }; // here I make a POJO's copy of the class instance
    }

    /** Открыть модального окна пользователь не залогинен */
    open_modal_user_not_sign_in: boolean = false;
    /** Список желаний пользователя Record<item_id, true>*/
    wish_items: Record<number, boolean> = {};
}

/** Сервис для работы со списком желаний */
export class WishSys {
    public conf = conf;

    public wish: WishStoreI = null;

    private queryClientSys: QuerySys = null;
    private queryMsgSys: QuerySys = null;

    constructor(ctrl: PageCtrl) {
        // Конфигурация запроса на core
        this.queryClientSys = new QuerySys();
        this.queryClientSys.fConfig(gfInitContextQuery(ctrl, conf.coreApi));

        this.queryMsgSys = new QuerySys();
        this.queryMsgSys.fConfig(gfInitContextQuery(ctrl, conf.msgAPI));

        let optionVuex = null;
        if (mIsClient()) {
            optionVuex = {
                preserveState: true, // сохранять состояние на сервере
            };
        }

        this.wish = ctrl.vuexSys.registerModuleCustomKey(new WishStoreI(), 'wish_sys', optionVuex);
    }

    /** Добавить товар в список желаний */
    public faAddToWishList(idItem?: number, idProduct?: number) {
        this.queryClientSys.fInit();

        this.queryClientSys.fActionOk((data: PurchasePageAuthR.addToWishList.ResponseI) => {
            const ixWishItems = this.wish.wish_items;            

            if (idItem) {
                if (data.operation === "add item" && !ixWishItems[idItem]){
                    ixWishItems[idItem] = true;
                } else {
                    delete ixWishItems[idItem];
                }
            } else {
                if (data.operation === "add product" && !ixWishItems[idProduct]){
                    ixWishItems[idProduct] = true;
                } else {
                    delete ixWishItems[idProduct];
                }
            }
            this.wish.wish_items = {...ixWishItems};
        });

        this.queryClientSys.fActionErr((err: any) => {
            catchErrorApiResponse({
                error: new Error('Ошибка при добавлении товара в избранное'),
                response: err,
                meta: 'WishS.faAddToWishList',
            });
        });

        const request: PurchasePageAuthR.addToWishList.RequestI = {};
        if (idItem) {
            request.item_id = idItem;
        } else if (idProduct) {
            request.product_id = idProduct;
        }
        this.queryClientSys.faSend(PurchasePageAuthR.addToWishList.route, request);
    }

    /** Получить список желаний */
    public fGetItemsInWishList(request: PurchasePageAuthR.getWishList.RequestI) {
        this.queryClientSys.fInit();

        this.queryClientSys.fActionOk((data: PurchasePageAuthR.getWishList.ResponseI) => {

            const wishList = data.list_wish;
            if (wishList.length) {
                const ixNewWishItems = this.wish.wish_items;

                for (let i = 0; i < wishList.length; i++) {
                    if (wishList[i].item_id) {
                        ixNewWishItems[wishList[i].item_id] = true;
                    } else if (wishList[i].product_id) {
                        ixNewWishItems[wishList[i].product_id] = true;
                    }

                }

                this.wish.wish_items = {...this.wish.wish_items, ...ixNewWishItems};
            }
            
        });

        this.queryClientSys.fActionErr((err: any) => {
            catchErrorApiResponse({
                error: new Error('Ошибка при получении списка избранных товаров'),
                response: err,
                meta: 'WishS.fGetItemsInWishList',
            });
        });
        
        this.queryClientSys.faSend(PurchasePageAuthR.getWishList.route, request);
    }
}

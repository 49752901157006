
/**
 * Интерфейс таблицы p63_banner
 */
export interface P63BannerI {
    id?: number; // ID
    name: string; // Заголовок баннера
    banner_place_id: number; // ID баннерного места, к которому привязан баннер
    url?: string; // URL по клику на баннер
    is_visible?: boolean; // показывается ли баннер
    s3_banner_file_id?: number; // ID картинки баннера
    order?: number; // Номер очереди баннера в баннерном месте
    status?: RequestAdvertisingT; // статус рекламы (баннера) или заявки на рекламу (баннер)
    comment?: string; // Комментарий / описание баннера
    date_start: string; // Начальная дата расположения баннера в месте
    date_stop?: string; // Конечная дата расположения баннера в месте
    consumer_rating?: number; // Минимальный рейтинг пользователя для отображения у него баннера (по умолчанию -1)

    user_who_changed?: number; // Пользователь, внесший последние изменения
    created_at?: string; // дата создания записи
    updated_at?: string; // дата обновления записи
}

/**
 * Интерфейс, объединяющий в себе данные таблиц p63_banner и p63_banner_request после join
 */
export interface P63BannerInfoI {
    user_who_changed?: number; // Пользователь, внесший последние изменения
    url?: string; // URL баннера
    s3_banner_file_id?: number; // ID картинки баннера
    date_stop?: string; // Конечная дата расположения баннера в месте
}

/** захардкоденные псведонимы реальных страниц всего сайта */
export enum PageAliasT {
    homeAPage = 'home-a-page', // Главная А страница
    homeBPage = 'home-b-page', // Главная В страница
    cart = 'cart', // корзина
    forum = 'forum', // страницы на форумном движке (рекламные баннеры)
    forumDesign = 'forum-design', // страницы на форумном движке (отрисовка баннеров)
    promo = 'promo', // Страница акций
}

/** Статус рекламы (баннера) или заявки на рекламу (баннер) */
export enum RequestAdvertisingT {
    unprocessed = 'unprocessed', // Необработанная заявка
    confirmed = 'confirmed', // Подтверждённая заявка
    rejected = 'rejected', // Отклонённая заявка
    deleted = 'deleted' // Удаленная заявка
}

/** Причины отказа размещения банера*/
export enum BannerCancelReasonT {
    another = 0, // другая причина
    not_place = 1, // нет места
    bad_banner = 2, // Плохой баннер (мутный, не читаемый текст, много мелких элементов, несочетаемые цвета)
    mistake = 3, // Баннер с ошибкой (орфографическая, грамматическая, логическая)
    weak_USP = 4, // Слабое УТП на баннере (если на баннере можно заменить торговую марку и ничего не поменяется, значит УТП слабое)
    wrong_size = 5, // Неправильный размер баннера
    out_of_limit = 6, // Исчерпан лимит размещений
    delivery_long_time = 7, // Срок доставки более 21 дня, кроме исключительных случаев
    too_late = 8, // Заявка подана слишком поздно
    dublicate = 9, // Дубль заявки
    not_actual = 10, // Баннер неактуальный (не соответствует сезону, не соответствует ЦА сайта)
    bad_purchase = 11, // Негатив в отзывах закупки (3 последних отзыва должны быть положительными или отработанными)
    banned_org = 12, // Организатор в бане за закрытый сбор на баннере/за уровень доступа
    bad_view = 13, // Закупка плохо оформлена (проверьте название закупки, условия, баннер, УТП, каталоги на соответствие стандартам сайта)
    wrong_link = 14, // Неверная ссылка
    low_rating = 15, // Рейтинг закупки ниже 4.7
    action_priority = 16, //  Приоритет отдается участникам текущей акции
}

/** Размещение баннера */
export enum BannerPlaceAliasIdT {
    homeBPageTop = 10,
    homeBPageLeft1 = 11,
    homeBPageRight1 = 12,
    homeBPageLeft2 = 13,
    homeBPageRight2 = 14,
    homeBPageMobile1 = 15,
    homeBPageMobile2 = 16,
    homeBPageMobile3 = 17,
    forumTop = 18,
    forumBottom = 19,
    homeSelections = 20,
}

/** Размещение баннера */
export enum BannerPlaceAliasT {
    homeAPageTop = 'home-a-page-top',               // Главная А, верхнее горизонтальное место со сладйдером
    homeAPageTopMobile = 'home-a-page-top-mobile',  // Главная А, верхнее горизонтальное место со сладйдером (для мобилки)
    homeAPageRightVertical1 = 'home-a-page-right-vertical-1', // 1 вертикальный баннер справа
    homeAPageRightVertical2 = 'home-a-page-right-vertical-2', // 2 вертикальный баннер справа
    homeAPageActual1 = 'home-a-page-actual-1',      // горизонтальный баннер "актуальное" 1
    homeAPageActual2 = 'home-a-page-actual-2',      // горизонтальный баннер "актуальное" 2
    homeAPageActual3 = 'home-a-page-actual-3',      // горизонтальный баннер "актуальное" 3
    homeAPageLeftVertical1 = 'home-a-page-left-vertical-1', // 1 вертикальный баннер слева
    homeAPageLeftVertical2 = 'home-a-page-left-vertical-2', // 2 вертикальный баннер слева
    homeBPageTop = 'home-b-page-top',               // Главная А, верхнее горизонтальное место со сладйдером
    homeBPageLeft1 = 'home-b-page-left-1',          // Левый баннер (первый сверху ряд)
    homeBPageRight1 = 'home-b-page-right-1',        // Правый баннер (первый сверху ряд)
    homeBPageLeft2 = 'home-b-page-left-2',          // Левый баннер (второй сверху ряд)
    homeBPageRight2 = 'home-b-page-right-2',        // Правый баннер (второй сверху ряд)]
    homeBPageMobile1 = 'home-b-page-mobile-1',
    homeBPageMobile2 = 'home-b-page-mobile-2',
    homeBPageMobile3 = 'home-b-page-mobile-3',
    homeSelections = 'home-selections',
}

/**
 * Сущность баннера
 */

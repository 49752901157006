import { Component } from 'vue-property-decorator';
import P63Vue from '@/system/P63Vue';
import { HomeCtrl, initHomeCtrl } from '../../view/ctrl-home';
import { mIsClient } from '@/common/helpers/ContextH';
import { gImageS } from '@/common/service/ImageS';
import { mFormatInvertedDateDotsTime } from '@/common/helpers/DateTimeH';
import { HomeN as N } from '@/pages/home/view/ifc-home';
import HomeBtn from '@/pages/home/el/home-btn/home-btn';
import { NewsInfoI } from '@/ifc/msg/EntitySQL/NewsE';

@Component
export default class HomeNewsNewSource extends P63Vue {
    /** Получить URL изображения по его filename */
    fGetImageUrlByFileName(sFileName: string): string {
        let sUrl = 'https://614119.selcdn.ru/design/home-page/no-news-image.jpg';
        if (sFileName) {
            sUrl = gImageS.fPostImageUrlx512(sFileName);
        }
        return sUrl;
    }    
 
    /** Отправить данные в статистику о клике по Новости */
    fSendActionOpenNew(vNews: NewsInfoI) {
        this.ctrl.statSys.faPushClickToStat({
            page: location.pathname,
            type: 'button_new',
            alias: 'news_click',
            entity_id: vNews.post_id,
        });
    }

    /** filename изображений, сортированные по post_id  */
    get ixImageFileName() {
        const ixImageFileName = {};

        for (let i = 0; i < this.aImage.length; i++) {
            const vImage = this.aImage[i];

            ixImageFileName[vImage.post_id] = vImage.filename;
        }

        return ixImageFileName;
    }

    /** URL и дата новости, индексированные по post_id */
    get ixNewsData() {
        const ixNewsData: Record<number, N.NewsDataI> = {};

        for (let i = 0; i < this.avNews.length; i++) {
            const vNews = this.avNews[i];

            ixNewsData[vNews.post_id] = {
                imageUrl: this.fGetImageUrlByFileName(this.ixImageFileName[vNews.post_id]),
                date: mFormatInvertedDateDotsTime(vNews.created_at),
                detailLink: '/news/' + vNews.post_id,
            };
        }

        return ixNewsData;
    }

    /** Получить список новостей */
    get avNews() {
        return this.ctrl.list.all_news;
    }

    /** Получить изображения новостей  */
    get aImage() {
        return this.ctrl.list.news_images;
    }

    /** Хранилище */
    get ctrl(): HomeCtrl {
        return initHomeCtrl(mIsClient() ? this.$context : this.$ssrContext);
    }
}

import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { gfInitContextCtrl } from '@/system/ContextSys';
import { PageCtrl } from '@/system/PageCtrl';
import { gAppealPopupS } from '@/lib_common/service/appeal_popup/AppealPopupS';
import P63Vue from '@/system/P63Vue';
import { mIsClient } from '@/common/helpers/ContextH';
import { mCyrillicToTranslitCleared } from '@/common/helpers/CyrillicToTranslitH';
import { mPluralize } from '@/common/helpers/TextFormatterH';
import { mFormatPriceSeparatorNoCoins } from '@/common/helpers/PriceFormatterH';

@Component
export default class UserMenu extends P63Vue {
    /** Открыто ли меню */
    @Prop({ default: false }) bOpenMenu: boolean;
    /** Показывать ли административное меню */
    @Prop({ type: Boolean, default: false }) isShowManagerMenu: boolean;

    // Флаг для рендера на клиенте
    isClient = false;

    // Методы
    /** Закрыть меню */
    fCloseMenu() {
        this.$emit('close');
        this.ctrl.headerSys.header.is_open_user_menu = false;
        document.body.classList.remove('stop-scrolling');
    }

    /** Открыть модальное окно добавления заявки */
    fOpenAppealPopup() {
        this.ctrl.headerSys.header.is_open_user_menu = false;
        this.ctrl.headerSys.header.new_appeal_files = [];
        this.vAppealPopupStatus.is_open_appeal_popup = true;
    }

    /** Открыть окно QR кода */
    fOpenQrModal() {
        this.ctrl.headerSys.header.is_open_user_menu = false;
        this.ctrl.headerSys.header.is_open_user_qr = true;
    }

    /** Удалить данные из хранилица браузера перед разлогиниванием */
    fRemoveBonusData(): void {
        localStorage.removeItem('bonus_expired_not_show');
    }

    async mounted() {
        this.isClient = true;
        if (this.isApp && this.ctrl.headerSys.header.is_open_user_menu) {
            document.body.classList.add('stop-scrolling');
            this.ctrl.headerSys.header.is_open_multi_level_menu_mobile = false;
            this.ctrl.headerSys.header.is_open_user_qr = false;
            this.ctrl.headerSys.header.is_open_appeal_popup = false;
        }
    }

    beforeDestroy() {
        if (!this.ctrl.headerSys.header.is_open_multi_level_menu_mobile) {
            document.body.classList.remove('stop-scrolling');
        }
    }

    /** Открыт ли сайт с приложения */
    get isApp() {
        return this.ctrl.isApp;
    }

    get vAppealPopupStatus() {
        return gAppealPopupS.status;
    }
    // Геттеры
    //   Селекторы
    /** Информация о пользователе */
    get vUserInfo() {
        return this.ctrl.userSys.user;
    }

    /** Количество непрочитанных сообщений */
    get nMsgCount() {
        return this.vUserInfo.countUnreadPrivmsg > 0 ? this.vUserInfo.countUnreadPrivmsg : null;
    }

    /** Количество непрочитанных системных уведомлений */
    get nNotificationCount() {
        return this.vUserInfo.countUnreadSystemNotifications > 0 ? this.vUserInfo.countUnreadSystemNotifications : null;
    }

    /** Сокращенное имя пользователя */
    get sShortUsername() {
        let sShortName = '';

        if (this.vUserInfo.info.username) {
            sShortName = this.vUserInfo.info.username.slice(0, 12);
        }

        return sShortName;
    }

    get sTitleGeo() {
        return this.ctrl.headerSys.header.pvz?.name ?? 'Пункт выдачи';
    }

    /** Баланс пользователя */
    get iBalance() {
        return mFormatPriceSeparatorNoCoins(this.ctrl.headerSys.header.user_balance);
    }

    /** Баланс пользователя */
    get sBonusBalance(): string {
        return `${mFormatPriceSeparatorNoCoins(this.ctrl.headerSys.header.bonus_balance)} ${mPluralize(
            this.ctrl.headerSys.header.bonus_balance,
            ['бонус', 'бонуса', 'бонусов']
        )}`;
    }

    get sLinkGeo() {
        let sLink = '';

        if (this.ctrl.headerSys.header.pvz_address?.city_name && this.ctrl.headerSys.header.pvz?.id) {
            sLink = `/${mCyrillicToTranslitCleared(this.ctrl.headerSys.header.pvz_address.city_name)}/${
                this.ctrl.headerSys.header.pvz.id
            }`;
        }

        return sLink;
    }

    //    Основные
    get ctrl(): PageCtrl {
        return gfInitContextCtrl(mIsClient() ? this.$context : this.$ssrContext, PageCtrl);
    }
}

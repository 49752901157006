<script lang="ts">
import Component from 'vue-class-component';
import P63Vue from '@/system/P63Vue';

@Component({})
export default class HeaderLogo extends P63Vue {}
</script>

<template>
    <a class="header-logo" href="/">
        <img class="header-logo__img" src="@/assets/header/new-logo.svg" alt="63 покупки" />
    </a>
</template>

<style scoped lang="scss">
.header-logo {
    position: relative;
    max-height: 42px;

    &__img {
        width: auto;
        height: 30px;

        @media (min-width: 768px) {
            height: auto;
            width: 193px;
            margin-top: -2px;
        }
    }
}
</style>

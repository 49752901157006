import P63Vue from '@/system/P63Vue';
import { Component, Prop } from 'vue-property-decorator';
import DayItem from './el/day-item';
import { ItemOfTopDayCollectionI } from '@/ifc/msg/CommonI/ItemCollectionI';
import { mFormatPriceSeparatorNoCoins } from '@/common/helpers/PriceFormatterH';
import { HomeCtrl, initHomeCtrl } from '@/pages/home/view/ctrl-home';
import { mIsClient } from '@/common/helpers/ContextH';

@Component({
    components: {
        DayItem,
    },
})
export default class DaySalesSource extends P63Vue {
    /** Коллекция товаров дня */
    @Prop({ type: Array, default: () => [] }) itemOfDayCollection: ItemOfTopDayCollectionI[];

    /** Ширина экрана */
    nWindowWidth: number = 0;

    /** Индекс текущего товара */
    nCurrentItemIndex: number = 0;

    /** Расширение планшета */
    isTablet: boolean = false;

    /** Ширина товара дня */
    nItemOfDayWidth: number = 0;

    /** Точка начала касания */
    nValueOnTouchStart: number = 0;

    /** Расширение небольшого десктопа */
    isSmallDesktop: boolean = true;

    /** Активация анимации перемещения слайдов в Товаре дня */
    isAnimationStart: boolean = false;

    /** Обратное прокручивание анимации */
    isReverseAnimation: boolean = false;

    /** Запись ширины webview */
    fSetWindowWidth() {
        this.nWindowWidth = window.innerWidth;
        this.isTablet = 767 < this.nWindowWidth && this.nWindowWidth < 1440;
        this.isSmallDesktop = this.nWindowWidth < 1440;

        if (this.nWindowWidth < 768) {
            const itemsHtmlElement = this.$refs.items as HTMLElement;

            this.nItemOfDayWidth = itemsHtmlElement?.children[0].clientWidth;
        }
    }

    /** Слайд к следущему элементу */
    fNextItem() {
        if (this.isTablet) {
            const itemsHtmlElement = this.$refs.items as HTMLElement;
            itemsHtmlElement.scrollLeft = itemsHtmlElement.scrollLeft + 377;
        } else {
            this.isAnimationStart = true;
            this.isReverseAnimation = false;
            if (this.nCurrentItemIndex + 1 === this.itemOfDayCollection.length) {
                this.nCurrentItemIndex = 0;
            } else {
                this.nCurrentItemIndex += 1;
            }
        }
    }

    /** Слайд к предыдущему элементу */
    fPrevItem() {
        if (this.isTablet) {
            const itemsHtmlElement = this.$refs.items as HTMLElement;
            itemsHtmlElement.scrollLeft = itemsHtmlElement.scrollLeft - 377;
        } else {
            this.isAnimationStart = true;
            this.isReverseAnimation = true;
            if (this.nCurrentItemIndex === 0) {
                this.nCurrentItemIndex = this.itemOfDayCollection.length - 1;
            } else {
                this.nCurrentItemIndex -= 1;
            }
        }
    }

    /** Обработчик начала касания */
    fTouchStart(e: TouchEvent): void {
        this.nValueOnTouchStart = e.changedTouches[0].clientX;
    }

    /** Обработчик окончания касания */
    fTouchEnd(e: TouchEvent): void {
        const nCoorX = e.changedTouches[0].clientX - this.nValueOnTouchStart;

        const cbChangeBanner = nCoorX < 0 ? this.fNextItem : this.fPrevItem;
        if (Math.abs(nCoorX) > 50) {
            cbChangeBanner();
        }
    }

    /** Установить Товар дня по клику */
    fSetActiveItem(idBanner: number): void {
        this.nCurrentItemIndex = idBanner;
        if (this.isMobile) {
            this.isAnimationStart = true;
        }
    }

    /** Нужно ли установить класс для карточки Товара Дня (как предыдущая)*/
    fCheckPrevItem(idx: number): boolean {
        let isPrevItemClass: boolean = false;
        if (this.isMobile) {
            if (this.itemOfDayCollection.length === 2) {
                isPrevItemClass = this.nCurrentItemIndex === 1 ? idx === 0 : false;
            } else {
                isPrevItemClass =
                    (this.nCurrentItemIndex ? this.nCurrentItemIndex - 1 : this.itemOfDayCollection.length - 1) === idx;
            }
        } 
        return isPrevItemClass;
    }

    /** Нужно ли установить класс для карточки Товара Дня (как следующая)*/
    fCheckNextItem(idx: number): boolean {
        let isNextItemClass: boolean = false;
        if (this.isMobile) {
            if (this.itemOfDayCollection.length === 2) {
                isNextItemClass = this.nCurrentItemIndex === 0 ? idx === 1 : false;
            } else {
                isNextItemClass =
                    (this.nCurrentItemIndex === this.itemOfDayCollection.length - 1
                        ? 0
                        : this.nCurrentItemIndex + 1) === idx;
            }
        } 
        return isNextItemClass;
    }

    mounted() {
        // следим за изменениями webview и перезаписываем ширину
        window.addEventListener('resize', this.fSetWindowWidth);
        this.fSetWindowWidth();
    }

    destroyed() {
        window.removeEventListener('resize', this.fSetWindowWidth);
    }

    /** Мобильное ли устройство */
    get isMobile(): boolean {
        return this.status.is_mobile;
    }

    /** Отформатированная цена в других магазинах */
    get isShowItems() {
        return !(this.isSmallDesktop && this.itemOfDayCollection.length < 2);
    }

    get status() {
        return this.ctrl.status;
    }

    /** Хранилище */
    get ctrl(): HomeCtrl {
        return initHomeCtrl(mIsClient() ? this.$context : this.$ssrContext);
    }
}

import * as conf from '@/configs/config.public';
import { QuerySys } from '@63pokupki/front';
import { AppealT } from '@/ifc/msg/EntitySQL/AppealE';
import { TopicI, TopicT } from '@/ifc/msg/EntitySQL/TopicE';
import { TopicR } from '@/ifc/msg/TopicR';
import { UploadFilePostR } from '@/ifc/img/UploadFilePostR';
import { AppealR } from '@/ifc/msg/AppealR';
import { PageCtrl } from './PageCtrl';
import { gfInitContextQuery } from './ContextSys';
import { mIsClient } from '@/common/helpers/ContextH';
import { UserR } from '@/ifc/core/UserR';
import { PvzI } from '@/ifc/core/PvzSQL/PvzE';
import { PvzAddressI } from '@/ifc/core/PvzSQL/PvzAddressE';
import { UserAccountR } from '@/ifc/core/UserAccountR';
import { SavedMenuLinkI } from '@/ifc/core/CommonSQL/SavedMenuLinkE';
import { MenuR } from '@/ifc/core/MenuR';
import { QrcodeR } from '@/ifc/core/QrcodeR';
import { NotificationR } from '@/ifc/msg/NotificationR';
import { BonusExpireInfoI } from '@/ifc/core/BonusActionUserE';
import dayjs from 'dayjs';
import { mFormatDateTime } from '@/common/helpers/DateTimeH';
import { PersonalR } from '@/ifc/core/PersonalR';
import { HeaderR } from '@/ifc/core/HeaderR';
import { CityI, CityWithPvzCountI } from '@/ifc/core/EntitySQL/CityE';
import { RefundOrderOrgR } from '@/ifc/core/RefundOrderOrgR';
import { OverPaymentReportR } from '@/ifc/core/OverPaymentReportR';

// Header Store
export class HeaderStoreI {
    toJSON() {
        return { ...this }; // here I make a POJO's copy of the class instance
    }

    /** Индексированные списки */
    /** Типы заявок */
    appeal_type: Record<AppealT, string> = {
        suggestion: 'Пожелание/предложение',
        report: 'Жалоба',
        work_questions: 'Рабочие вопросы',
    };

    /** Статусы */
    /** Информация о загрузке данных */
    is_loading: boolean = false;

    /** Информация о блоке многоуровневого меню Desktop */
    is_open_multi_level_menu_desktop: boolean = false;

    /** Информация о блоке многоуровневого меню Mobile */
    is_open_multi_level_menu_mobile: boolean = false;

    /** Информация о меню пользователя */
    is_open_user_menu: boolean = false;

    /** Модальное окно QR пользователя */
    is_open_user_qr: boolean = false;

    /** Информация о меню избранное */
    is_open_favorite_menu: boolean = false;

    /** Информация о состоянии скролла */
    is_scroll_on: boolean = true;

    /** Открыто/Закрыто модальное окно отправки заявки */
    is_open_appeal_popup: boolean = false;

    /** Статус загрузки файла */
    is_loading_file: boolean = false;

    /** Ошибка загрузки файла к заявке */
    error_loading_file: string = '';

    /** Списки */
    /** Список вложений для новой заявки */
    new_appeal_files: {
        post_file_id: number; // ID файла
        filename: string; // Уникальное имя файла
        extension?: string;
    }[] = [];

    /** Список тем */
    topics: TopicI[] = [];

    /** Избранные ссылки для оргов */
    saved_link: SavedMenuLinkI[] = [];

    /** Корзина локальное хранение */
    cart_local_storage: {
        unpaid_order_popup_date_closed?: number;
    } = {};

    /** Закрыта ли модалка неоплаченного заказа */
    is_closed_unpaid_order_in_cart_popup = false;

    /** Показывать ли модалку "Проверить настройки уведомлений" */
    is_shown_modal_check_notifications = false;

    /** Характеристики ПВЗ */
    pvz: PvzI = {};

    /** Кол-во уведомлений орга */
    org_notification_counter: number = 0;

    /** Кол-во новых заявок на возврат товара */
    new_refund_counter: number = 0;

    /** Количество записей с переплатами */
    count_overpayment: number = 0;

    /** Информация об адресе ПВЗ */
    pvz_address: PvzAddressI = {};

    /** Баланс пользователя */
    user_balance: number = 0;

    /** Бонусный баланс пользователя */
    bonus_balance: number = 0;

    /** Всплывающее бонусное уведомление */
    is_show_notification_bonus: boolean = false;

    /** Флаг отображения бонусного уведомления (сгорание/начисление) */
    is_expire_bonus: boolean = false;

    /** Количество бонусов */
    number_of_bonuses: number = 0;

    /** Дата сгорания бонусов */
    date_bonus_expire: string = '';

    /** Ближайшие списания бонусов */
    bonus_expired: BonusExpireInfoI[] = [];
    /** Непоказанные уведомления о сгорании бонусов */
    bonus_expired_not_show: BonusExpireInfoI[] = [];
    /** Город пользователя */
    user_city: CityI = null;
    /** Отсортрованные города */
    sorted_cities: CityWithPvzCountI[] = [];
    /** Загружены ли города */
    cities_loaded = false;
    /** Показывать ли модалку выбора города */
    show_select_city = false;
    /** Начался ли опрос городов */
    start_fetch_cities = false;
    /** AB тест главной */
    is_ab_test: boolean = false;

    /** Алиас выбранного города неавторизованного пользователя в localstorage */
    storage_alias_user_city_id = 'storage_alias_user_city_id';
    storage_alias_user_city_name = 'storage_alias_user_city_name';
}

/** System для работы c Header */
export class HeaderSys {
    public conf = conf;

    public header: HeaderStoreI = null;

    private queryClientSys: QuerySys = null;
    private queryMsgSys: QuerySys = null;
    private queryImgSys: QuerySys = null;

    constructor(ctrl: PageCtrl) {
        // Конфигурация запроса на core
        this.queryClientSys = new QuerySys();
        this.queryClientSys.fConfig(gfInitContextQuery(ctrl, conf.coreApi));

        this.queryMsgSys = new QuerySys();
        this.queryMsgSys.fConfig(gfInitContextQuery(ctrl, conf.msgAPI));

        this.queryImgSys = new QuerySys();
        this.queryImgSys.fConfig(conf.imgAPI);

        let optionVuex = null;
        if (mIsClient()) {
            optionVuex = {
                preserveState: true, // сохранять состояние на сервере
            };
        }

        this.header = ctrl.vuexSys.registerModuleCustomKey(new HeaderStoreI(), 'header_sys', optionVuex);
    }

    /**
     * Получить список тем
     * Метод с пагинацией (получаем 100 тем которые относятся к заявкам)
     */
    public fGetTopicListByFilter() {
        this.queryMsgSys.fInit();
        this.queryMsgSys.fActionOk((data: TopicR.getTopicListByFilter.ResponseI) => {
            this.header.topics = data.topic_list;
        });
        this.queryMsgSys.fActionErr((err) => {});

        const request: TopicR.getTopicListByFilter.RequestI = {
            filter_type: TopicT.appeal,
            filter_closed: false,
            page: 0,
            limit: 100,
        };
        this.queryMsgSys.fSend(TopicR.getTopicListByFilter.route, request);
    }

    /**
     * Загрузить новый файл к заявке
     */
    public fUploadPostNewFile(request: UploadFilePostR.uploadPostFileWithoutLinkByBase64.RequestI) {
        this.header.is_loading_file = true;
        this.header.error_loading_file = '';
        this.queryImgSys.fInit();
        this.queryImgSys.fActionOk((data: UploadFilePostR.uploadPostFileWithoutLinkByBase64.ResponseI) => {
            /** Проверяем повторно ли загружают файл */
            var isFound: boolean = false;
            for (var i = 0; i < this.header.new_appeal_files.length; i++) {
                if (this.header.new_appeal_files[i].post_file_id === data.post_file_id) {
                    isFound = true;
                    break;
                }
            }
            /** Если файл загружают повторно */
            if (isFound) {
                console.log('Файл уже загружен');
            } else {
                this.header.new_appeal_files.push(data);
            }
            this.header.is_loading_file = false;
        });
        this.queryImgSys.fActionErr((err) => {
            if (err.throw_logic) {
                this.header.error_loading_file = err.throw_logic;
            }
            this.header.is_loading_file = false;
        });

        this.queryImgSys.fSend(UploadFilePostR.uploadPostFileWithoutLinkByBase64.route, request);
    }

    /**
     * Создать связь вложений и поста
     */
    public fCreatePostFileLink(request: UploadFilePostR.createPostFileLink.RequestI) {
        this.queryImgSys.fInit();
        this.queryImgSys.fActionOk((data: UploadFilePostR.createPostFileLink.ResponseI) => {
            this.header.is_open_appeal_popup = false;
            this.header.is_scroll_on = true;
        });
        this.queryImgSys.fActionErr((err) => {});

        this.queryImgSys.fSend(UploadFilePostR.createPostFileLink.route, request);
    }

    /* Создать заявку */
    public fCreateAppeal(request: AppealR.createAppeal.RequestI) {
        this.queryMsgSys.fInit();
        this.queryMsgSys.fActionOk((data: AppealR.createAppeal.ResponseI) => {
            this.header.is_open_appeal_popup = false;
            if (this.header.new_appeal_files.length) {
                this.fCreatePostFileLink({
                    post_id: data.post.id,
                    post_file_id_list: this.header.new_appeal_files.map((file) => file.post_file_id),
                });
            } else {
                this.header.is_open_appeal_popup = false;
                this.header.is_scroll_on = true;
                console.log('Заявка успешно создана.');
            }
        });
        this.queryMsgSys.fActionErr((err) => {});

        this.queryMsgSys.fSend(AppealR.createAppeal.route, request);
    }

    /** Получить кол-во уведомлений моих обсуждений */
    public fGetOrgNotificationCounters() {
        this.queryMsgSys.fInit();

        this.queryMsgSys.fActionOk((data: NotificationR.getOrgNotificationCounters.ResponseI) => {
            this.header.org_notification_counter = data.count_unprocessed_discussion;
        });

        this.queryMsgSys.fActionErr((err) => {});

        this.queryMsgSys.fSend(NotificationR.getOrgNotificationCounters.route, {});
    }

    /* Получить количество новых заявок на возврат товара */
    public fGetNewOrderRequestCounter() {
        this.queryClientSys.fInit();
        this.queryClientSys.fActionOk((data: RefundOrderOrgR.countNewRefundRequest.ResponseI) => {
            this.header.new_refund_counter = data.refund_count;
        });
        this.queryClientSys.fActionErr((err) => {});

        const request: RefundOrderOrgR.countNewRefundRequest.RequestI = {};

        this.queryClientSys.fSend(RefundOrderOrgR.countNewRefundRequest.route, request);
    }

    /** Получить количество переплат */
    public fGetOverpaymentCounter() {
        this.queryClientSys.fInit();

        this.queryClientSys.fActionOk((data: OverPaymentReportR.cntOverpaymentByOrg.ResponseI) => {
           this.header.count_overpayment = data.count_overpayment;
        });
        this.queryClientSys.fActionErr((err) => {});

        const request: OverPaymentReportR.cntOverpaymentByOrg.RequestI = {}

        this.queryClientSys.fSend(OverPaymentReportR.cntOverpaymentByOrg.route, request)
    }

    /* Получить статус активности пользователя (Последний визит >/< 30 дней назад)*/
    public fGetUserActivityStatus() {
        this.queryClientSys.fInit();
        this.queryClientSys.fActionOk((data: UserR.checkMissingFlag.ResponseI) => {
            this.header.is_shown_modal_check_notifications = Boolean(data.is_missing);
        });
        this.queryClientSys.fActionErr((err) => {});

        this.queryClientSys.fSend(UserR.checkMissingFlag.route, {});
    }

    /* Получить ПВЗ пользователя */
    public fGetPvzInfo() {
        this.queryClientSys.fInit();
        this.queryClientSys.fActionOk((data: UserR.getUserDefaultPvz.ResponseI) => {
            this.header.pvz = data.pvz;
            this.header.pvz_address = data.pvz_address;
        });
        this.queryClientSys.fActionErr((err) => {});

        const request: UserR.getUserDefaultPvz.RequestI = {};

        this.queryClientSys.fSend(UserR.getUserDefaultPvz.route, request);
    }

    /* Получить баланс пользователя */
    public fGetUserBalance() {
        this.queryClientSys.fInit();
        this.queryClientSys.fActionOk((data: UserAccountR.getAccountBalance.ResponseI) => {
            this.header.user_balance = data.total_money - data.block_money;
            this.header.bonus_balance = data.total_bonus;
        });
        this.queryClientSys.fActionErr((err) => {});
        const request: UserAccountR.getAccountBalance.ResponseI = {};
        this.queryClientSys.fSend(UserAccountR.getAccountBalance.route, request);
    }

    /** Получить избранные ссылки для оргов */
    public fGetSavedLinksForOrgs() {
        this.queryClientSys.fInit();

        this.queryClientSys.fActionOk((data: MenuR.getMenuLink.ResponseI) => {
            this.header.saved_link = data.list_saved_menu_link;
        });

        this.queryClientSys.fActionErr((err) => {});

        this.queryClientSys.fSend(MenuR.getMenuLink.route, {});
    }

    /** Получить город по координатам */
    public fGetCityByСoordinates(request: HeaderR.getCityByСoordinates.RequestI) {
        this.queryClientSys.fInit();

        this.queryClientSys.fActionOk((data: HeaderR.getCityByСoordinates.ResponseI) => {
            this.header.user_city = data.city;
        });

        this.queryClientSys.fActionErr((err) => {});

        this.queryClientSys.fSend(HeaderR.getCityByСoordinates.route, request);
    }

    /** Получить список городов */
    public async faGetListCity() {
        this.queryClientSys.fInit();

        this.queryClientSys.fActionOk((data: HeaderR.getOrderedListCity.ResponseI) => {
            this.header.sorted_cities = data.list_city;
            this.header.cities_loaded = true;
        });

        this.queryClientSys.fActionErr((e) => {});

        const request: HeaderR.getOrderedListCity.RequestI = {};
        await this.queryClientSys.faSend(HeaderR.getOrderedListCity.route, request);
    }

    /** Сохранить инфу о профиле */
    public fSaveProfile(request: PersonalR.saveProfile2.RequestI, fOk?: () => void) {
        this.queryClientSys.fInit();

        this.queryClientSys.fActionOk((data: PersonalR.saveProfile2.ResponseI) => {
            if (fOk) {
                fOk();
            }
        });

        this.queryClientSys.fActionErr((e) => {});

        this.queryClientSys.faSend(PersonalR.saveProfile2.route, request);
    }

    /** Сохранить город */
    fSaveCity(idUser: number, idCity: number, sCity: string) {
        if (idUser) {
            this.fSaveProfile({
                city_id: idCity,
            });
        } else {
            localStorage.setItem(this.header.storage_alias_user_city_id, String(idCity));
            localStorage.setItem(this.header.storage_alias_user_city_name, sCity);
        }
    }

    /** Изображение QR кода для оплаты */
    fGetQrImage() {
        return conf.coreApi.baseURL + QrcodeR.generatePersonalQrCode.route;
    }

    /** Получить информацию о сгораемых бонусах */
    public fGetBonusExpired() {
        this.queryClientSys.fInit();

        this.queryClientSys.fActionOk((data: UserAccountR.getBonusExpired.ResponseI) => {
            this.header.bonus_expired = data.list_bonus_expired;
            this.header.bonus_expired_not_show = data.list_bonus_expired;

            this.header.bonus_expired_not_show = data.list_bonus_expired.filter(
                (objBonus: BonusExpireInfoI) =>
                    dayjs().isSame(dayjs(objBonus.date_bonus_expired), 'day') ||
                    dayjs().add(1, 'day').isSame(dayjs(objBonus.date_bonus_expired), 'day')
            );

            const aBonusNtfNotShow: BonusExpireInfoI[] = this.header.bonus_expired_not_show;

            if (aBonusNtfNotShow.length) {
                const objLastBonusExpire: BonusExpireInfoI = aBonusNtfNotShow[0];
                this.header.is_expire_bonus = true;
                this.header.date_bonus_expire = objLastBonusExpire?.date_bonus_expired || mFormatDateTime();
                this.header.number_of_bonuses = objLastBonusExpire?.sum_bonus_expired || 0;

                localStorage.setItem('bonus_expired_not_show', JSON.stringify(this.header.bonus_expired_not_show));
                this.header.is_show_notification_bonus = true;
            }
        });

        this.queryClientSys.fActionErr((e) => {});

        this.queryClientSys.faSend(UserAccountR.getBonusExpired.route, {});
    }
}

import Component from 'vue-class-component';
import P63Vue from '@/system/P63Vue';

@Component
export default class ModalOrgMessage extends P63Vue {
    mounted() {
        /* чтобы не прокручивалось */
        document.body.classList.add('disable-scroll');
    }

    destroyed() {
        /* чтобы прокручивалось */
        document.body.classList.remove('disable-scroll');
    }
}

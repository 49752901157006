var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.isShowItems)?_c('div',{staticClass:"day-sales"},[(_vm.itemOfDayCollection.length < 2)?_c('div',{staticClass:"day-sales__placeholder"},[_vm._m(0),_c('a',{attrs:{"href":"/purchases?tags=15"}},[_vm._v("Популярные закупки")]),_c('a',{staticClass:"hits",attrs:{"href":"/category/"}},[_vm._v("Товары - ХИТЫ")]),_c('img',{attrs:{"src":require("@/assets/images/home/item-of-day-placeholder.webp"),"alt":"ТОП - Закупки"}})]):[(_vm.isTablet || _vm.isMobile)?_c('p',{staticClass:"day-sales__title"},[_vm._v("Товар дня")]):_vm._e(),_c('div',{ref:"items",staticClass:"day-sales__items",on:{"touchstart":_vm.fTouchStart,"touchend":_vm.fTouchEnd}},[(_vm.nWindowWidth >= 1440)?[_c('Transition',[_c('DayItem',{key:_vm.nCurrentItemIndex,attrs:{"vItem":_vm.itemOfDayCollection[_vm.nCurrentItemIndex]}})],1)]:_vm._l((_vm.itemOfDayCollection),function(vItem,idx){return _c('DayItem',{key:vItem.id,ref:vItem.filename,refInFor:true,class:{
                    img_current: _vm.nCurrentItemIndex === idx && _vm.isAnimationStart,
                    img_prev: _vm.fCheckPrevItem(idx) && _vm.isAnimationStart,
                    img_next: _vm.fCheckNextItem(idx) && _vm.isAnimationStart,
                    img_reverse: _vm.isReverseAnimation,
                    },attrs:{"vItem":vItem,"isTablet":_vm.isTablet,"isMobile":_vm.isMobile,"id":vItem.filename,"isAnimationStart":_vm.isAnimationStart}})})],2),(_vm.nWindowWidth >= 768 && _vm.itemOfDayCollection?.length > 1)?[_c('button',{staticClass:"day-sales__toggle",attrs:{"aria-label":"Предыдущий товар"},on:{"click":_vm.fPrevItem}},[_c('Icon',{attrs:{"sIconName":"pointer-left","sWidth":"20","sHeight":"20"}})],1),_c('button',{staticClass:"day-sales__toggle next",attrs:{"aria-label":"Следующий товар"},on:{"click":_vm.fNextItem}},[_c('Icon',{attrs:{"sIconName":"pointer-left","sWidth":"20","sHeight":"20"}})],1)]:_vm._e(),(!_vm.isTablet && _vm.itemOfDayCollection?.length > 1)?_c('div',{staticClass:"day-sales__labels"},_vm._l((_vm.itemOfDayCollection),function(item,index){return _c('i',{key:index,class:{ active: _vm.nCurrentItemIndex === index },on:{"click":function($event){return _vm.fSetActiveItem(index)}}})}),0):_vm._e()]],2):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',[_vm._v("Посмотрите наши"),_c('br'),_vm._v("ТОП - Закупки")])
}]

export { render, staticRenderFns }
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Icon from '../icon/icon';

/**
 * Компонент input_search принимает
 * @ sValue - Значение строки поиска
 * @ sPlaceholder - Текст placeholder
 * @ sSize - Размер окна поиска 'sm' | 'md' | 'lg'
 * @ isSearchBtnVisible - Показывать ли кнопку поиска
 * @ isClearBtnVisible - Показывать ли кнопку очистки поиска
 * @ vInputStyle - Стили для инпута
 * @ vButtonStyle - Стили для кнопок
 * @ onInput - Функция по событию инпут
 * @ onSearch - Функция по нажатию на кнопку поиска и кнопку энтер
 * @ onClear - Функция по нажатию на кнопку очистить поле поиска
 */
@Component({
    components: {
        Icon,
    }
})
export default class input_search extends Vue {
    /** Значение строки поиска */
    @Prop({ type: String, default: '', required: true }) sValue: string;
    /** Текст placeholder */
    @Prop({ type: String, default: 'Поиск' }) sPlaceholder: string;
    /** Размер окна поиска 'sm' | 'md' | 'lg' */
    @Prop({ type: String, default: 'sm' }) sSize: 'sm' | 'md' | 'lg';
    /** Показывать ли кнопку поиска */
    @Prop({ type: Boolean, default: true }) isSearchBtnVisible: boolean;
    /** Показывать ли кнопку очистки поиска */
    @Prop({ type: Boolean, default: false }) isClearBtnVisible: boolean;
    /** Стили для инпута */
    @Prop({ type: Object, default: () => {} }) vInputStyle: object;
    /** Стили для кнопок */
    @Prop({ type: Object, default: () => {} }) vButtonStyle: object;
    /** Функция по событию инпут */
    @Prop({ type: Function }) onInput: (value: string) => void;
    /** Функция по нажатию на кнопку поиска и кнопку энтер */
    @Prop({ type: Function }) onSearch: () => void;
    /** Функция по нажатию на кнопку очистить поле поиска */
    @Prop({ type: Function }) onClear: () => void;

    fOnSearch () {
        if (this.onSearch) {
            this.onSearch();
        }
    };

    fOnClear () {
        if (this.onClear) {
            this.onClear();
        }
    };

    fOnKeyUpSearch (event) {
        if (
            (event.code === 13 ||
                event.keyCode === 13 ||
                event.key === 'Enter') &&
            this.onSearch
        ) {
            this.onSearch();
        }
    };

    fOnInput(event) {
        if (this.onInput) {
            this.onInput(event.target.value);
        }
    };
}

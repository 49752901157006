import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Icon from '../icon/icon';

/**
 * Компонент button_favorite принимает
 * @ isActive - Активное, нажатое состояние
 * @ vIconStyle — Стили контейнера иконки
 * @ vIconActiveStyle — Стили активного контейнера иконки
 * @ onClick () => void - Функция обработки клика пользователе
 */
@Component({
    components: {
        Icon,
    }
})
export default class button_favorite extends Vue {
    /** Активное, нажатое состояние */
    @Prop({ type: Boolean, default: false }) isActive: boolean;
    /** Стили активного контейнера иконки */
    @Prop({ type: Object, default: () => {} }) vIconActiveStyle: object;
    /** Стили контейнера иконки */
    @Prop({ type: Object, default: () => {}  }) vIconStyle: object;
    /** Высота иконки */
    @Prop({ type: String, default: '12px' }) sWidth: string;
    /** Ширина иконки */
    @Prop({ type: String, default: '12px' }) sHeight: string;
    /** Функция нажатия на кнопку */
    @Prop({ type: Function }) onClick: () => void;

    /** Вызов функции клика по кнопке */
    fClick(){
        if (this.onClick) {
            this.onClick();
        }
    }


    /** Получить объект стилей*/
    get vIconStyleCustom() {
        let vStyleCustom = { ...this.vIconStyle};

        if (this.isActive) {
            vStyleCustom = {...vStyleCustom, ...this.vIconActiveStyle};
        }

        return vStyleCustom;
    }
}

import Vue from 'vue';
import { mIsClient } from '@/common/helpers/ContextH';


// Суммарная высота, которую занимает навигационное меню сверху и снизу в режиме App
const SUM_HEIGHT_APP_MENU = 103;

if (process.env.SPRITE) {
  // Сделать иконки для спрайтов видимыми для Webpack
  const svgModules = require.context('../src/assets/images/icons/sprite-sources', true, /\.svg$/);
  svgModules.keys().forEach(svgModules);
}

export const fCreateClientApp = (fCreateApp: Function) => {
    if (mIsClient()) {
        const { app, router, store } = fCreateApp();

        Vue.prototype.$context = {};
        Vue.prototype.$store = store;
        Vue.prototype.$device = {
            isDesktop: true,
            isTablet: false,
            isMobile: false,
        };

        const isApp = document.cookie.indexOf('isApp=true') !== -1 ? true : false;

        Vue.prototype.$sTargetLink = isApp ? '_self' : '_blank';
        Vue.prototype.$sBodyHeightApp = isApp ? `${document.documentElement.clientHeight - SUM_HEIGHT_APP_MENU}px` : '';

        app.$context.store = store;

        // replacing store from server
        if ((<any>window).__INITIAL_STATE__) {
            store.replaceState((<any>window).__INITIAL_STATE__);
        }

        app.$mount('#anchor-global');
        app.$context.ctrl.statSys.fInitAnalytics();
    }
  };

export const fCreateServerApp = (context, fCreateApp: Function) =>
  new Promise((resolve, reject) => {
    // console.log('app.$context>>',(<any>Vue.prototype.$context));

    // Vue instance creates on every request
    const { app, router, store } = fCreateApp();

    context.store = store;

    const isApp = Boolean(context.cookies['isApp']);
    Vue.prototype.$sTargetLink = isApp ? '_self' : '_blank';
    
    context.meta = {
      title: '',
      description: '',
    };

    // Vue.prototype.$device = {
    //     isDesktop:true,
    //     isTablet:false,
    //     isMobile:false,
    // }

    // $meta - method, was added in Vue instance by package vue-meta
    // const meta =
    // context.store = store;
    // for (const k in context) {
    //     app.$context[k] = context[k]
    // }

    // for (const k in store) {
    //     app.$store[k] = store[k]
    // }

    // push current url to router
    router.push(context.url.pathname);

    // assign meta to context to have the ability to render it in template

    // wait until router has resolved possible async components and hooks
    router.onReady(() => {
      context.rendered = () => {
        // assign state to context, it’ll we render in template as window.__INITIAL_STATE__
        context.state = store.state;
      };

      const matchedComponents = router.getMatchedComponents();


      // no matched routes, reject with 404
      if (!matchedComponents.length) {
        return reject(new Error('404'));
      }

      // the Promise should resolve to the app instance so it can be rendered
      return resolve(app);
    }, reject);
  });

// export {fCreateServerApp};

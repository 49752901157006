import seo from '@/configs/seo';
import P63Vue from '@/system/P63Vue';
import Component from 'vue-class-component';
import SliderSales from '../itemSlider/SliderSales'
import Brands from '@/common/el/Brands/Brands.vue'
import { PageCtrl } from '@/system/PageCtrl';
import { gfInitContextCtrl } from '@/system/ContextSys';
import { mIsClient } from '@/common/helpers/ContextH';
import { Prop } from 'vue-property-decorator';
import { gLinkS } from '@/common/service/LinkS';
import { BannerI } from '@/ifc/core/CommonI/BannerI';
import { mShuffleArray } from '@/common/helpers/ArrayH';

@Component({
  components: { 
    Brands,
    SliderSales,
  },
})
export default class not_found404 extends P63Vue {
  // Props
  @Prop({ type: Boolean, default: false }) bErr403: boolean;

  /** Текст и ссылка на предыдущую / главную страницу */
  vGoBackButton: {
    text: string,
    href: string
  } = null;
  /** Ссылки после текста ошибки */
  aMainLinks = this.bIsAuth ? [
    {
      text: 'На главную',
      href: '/'
    },
    {
      text: 'В личный кабинет',
      href: '/lk#/profile'
    },
    {
      text: 'Топ-закупки',
      href: '/purchases?tags=15'
    },
    {
      text: 'Избранное',
      href: '/wishlist#/'
    },
  ] : [
    {
      text: 'Вход',
      href: '/auth#/'
    },
    {
      text: 'Быстрая регистрация',
      href: '/auth#/register'
    },
    ]
  
  /** получить товары с сервера для подборки */
  fGetItems(direction: 'left' | 'right') {
    this.ctrl.status.isLoadingItems = true;
    this.ctrl.notFoundSys.faGetItemsSelection(direction)
  }

  get vSeo() {
    return seo;
  }

  /** Открыт ли сайт с приложения */
  get isApp() {
    return this.ctrl.isApp;
  }

  /** Авторизация */
  get bIsAuth(){
    return this.ctrl.userSys.user.isAuth;
  }

  /** Пользователь заблокирован полностью */
  get bIsFullBlock() {
    return this.ctrl.userSys.user.bIsFullBlock;
  }

  /** Заголовок подборки */
  get vSelectionInfo(){
    return this.ctrl.notFoundSys.notFoundStore.selection_info;
  }

  /** Массив причин блокировок */
  get aListReasonBlock() {
    return this.ctrl.notFoundSys.notFoundStore.list_reason_block;
  }

  /** Товары для отображения в подборке */
  get aSelectionItem(){
    return this.ctrl.notFoundSys.notFoundStore.list_item_selection;
  }

  /** Код ошибки */
  get sErrCode(){
    return this.bErr403 ? '403' : '404'
  }

  /** Текст ошибки */
  get sErrText(){
    let sMessage: string = ''
    if (this.sErrCode === '403' && !this.bIsFullBlock){
      sMessage = this.bIsAuth ? 'Доступ к странице ограничен' : 'Доступ к странице ограничен, попробуйте войти на сайт'
    } else if (this.bIsFullBlock && this.bIsAuth) {
      sMessage = 'Доступ к сайту ограничен'
    } else {
      sMessage = 'Такой страницы не существует'
    }
    return sMessage;
  }

  get isOpenModalUserNotSignIn() {
    return this.ctrl.wishSys.wish.open_modal_user_not_sign_in
  }

  fCloseModal() {
    this.ctrl.wishSys.wish.open_modal_user_not_sign_in = false;
  }

  fOpenModal() {
    this.ctrl.wishSys.wish.open_modal_user_not_sign_in = true;
  }

  /** Получить ссылку и текст для пререхода на предыдущую страницу */
  fGetPreviousPage(){
    let sText: string = ''; // Текст ссылки
    let sHref: string = ''; // Адрес
    const sPreviousPage: string = document.referrer;
    if (sPreviousPage && sPreviousPage.includes('63pokupki.ru') && !this.bIsFullBlock){
      sText = 'Вернуться на предыдущую страницу';
      sHref = sPreviousPage;
    } else if (this.bIsFullBlock) {
      sText = 'Вернуться на cтраницу авторизации';
      sHref= '/logout?redirect=auth'
    } else {
      sText = 'Перейти на главную страницу';
      sHref = '/';
    }
    this.vGoBackButton = {
      text: sText,
      href: sHref,
    }
  }

   /** Войти */
   onGoSignIn() {
    window.location.href = gLinkS.fGetLoginUrl(window.location.href);
  }

  /** Авторизоваться */
  onGoSignUp() {
      window.location.href = gLinkS.fGetRegisterUrl(window.location.href);
  }

  /** Список брендов / подборок */
  get aBanners() {
    const aBanner: BannerI[] = mShuffleArray(this.ctrl.notFoundSys.notFoundStore.list_banners) ?? []
    return aBanner.slice(0, 6);;
  }

  /** Количество товаров в подборке */
  get cntItemOfCollection() {
    return this.ctrl.notFoundSys.notFoundStore.cnt_item_of_collection
  }

  /** Статус загрузка товаров в подборки */
  get isLoadingItems() {
      return this.ctrl.notFoundSys.notFoundStore.isLoadingItems
  }

  get ctrl(): PageCtrl {
    return gfInitContextCtrl(mIsClient() ? this.$context : this.$ssrContext, PageCtrl);
  }

  mounted(){
    this.ctrl.notFoundSys.fGetSelectionsBanners({
      place_alias: 'home-selections'
    });
    if (this.ctrl.userSys.user.bIsFullBlock) {
      this.ctrl.notFoundSys.fGetBlockReason({
        user_id: this.ctrl.userSys.user.info.user_id,
      });
    }
    this.fGetPreviousPage();
    this.ctrl.notFoundSys.faGetItemsSelection();
  }
}

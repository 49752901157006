import * as conf from '@/configs/config.public';
import { PageCtrl } from './PageCtrl';
import { gfInitContextQuery } from './ContextSys';
import { QuerySys } from '@63pokupki/front';
import { mIsClient } from '@/common/helpers/ContextH';
import { catchErrorApiResponse } from '@/common/helpers/ErrorHandler';
import { InputR } from '@/ifc/stat/InputR';
import { yandexAPI } from '@/configs/config.public';
import { GtmN } from '@/common/interface/GtmI';
import { DeviceTypeT } from '@/ifc/stat/CH/UserActionE';
import { fOnloadScript } from '@/common/service/OnloadScript';
declare global {
    interface Window {
        dataLayer: {}[];
        userInfo: {
            userId: number;
        };
    }
}

/** StatSys Store */
class StatSysStoreI {
    toJSON() {
        return { ...this }; // here I make a POJO's copy of the class instance
    }

    /** Флаг инициализации Яндекс Метрики */
    isYandexInit: boolean = false;

    /** Очередь событий Яндекс Метрики (ecommerce) */
    aEcommerceActiontToSend: { 
        action: string,
        bNonInteraction: boolean,
        data: GtmN.IEcommerceActionData 
    }[] = [];

    /** Очередь событий Яндекс Метрики (reachGoal) */
    aActionsToSend: string[] = [];
}

/** System для работы c ItemBundle */
export class StatSys {
    public conf = conf;

    public stat: StatSysStoreI = null;

    private queryStatSys: QuerySys = null;

    private ctrl: PageCtrl = null;

    constructor(ctrl: PageCtrl) {
        // Конфигурация запроса на stat.core
        this.queryStatSys = new QuerySys();
        this.queryStatSys.fConfig(gfInitContextQuery(ctrl, conf.statAPI));

        this.ctrl = ctrl;

        let optionVuex = null;
        if (mIsClient()) {
            optionVuex = {
                preserveState: true, // сохранять состояние на сервере
            };
        }

        this.stat = ctrl.vuexSys.registerModuleCustomKey(new StatSysStoreI(), 'stat_sys', optionVuex);
    }

    /** Отправить клик по баннеру в статистику */
    public async faPushClickToStat(request: InputR.writeElementAction.RequestI) {
        this.queryStatSys.fInit();
        this.queryStatSys.fActionOk((data: InputR.writeElementAction.ResponseI) => { });
        this.queryStatSys.fActionErr((err: any) => {
            catchErrorApiResponse({
                error: new Error('Ошибка отправки клика'),
                response: err,
                meta: 'faPushClickToStat',
            });
        });

        request.device_type = DeviceTypeT[this.ctrl.deviceType] ?? DeviceTypeT.not_detected;
        await this.queryStatSys.faSend(InputR.writeElementAction.route, request);
    }

    /** Инициализация Гугл менеджера и аналитик */
    public fInitAnalytics() {
        /** DOM элемент - <script/> */
        let elGtmScript = document.createElement('script');
        elGtmScript.type = 'text/javascript';
        elGtmScript.textContent = `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-KC67P3');
        `
        
        /** Флаг авторизации */
        const isAuth = this.ctrl.userSys.user.isAuth;

        /** Добавить <script> в <head> */
        window.onload = () => {
            /** Сразу после загрузки страницы, если пользователь авторизован */
            if (isAuth) {
                document.head.appendChild(elGtmScript);
                this.fSendUserDataToYandex();
                this.fSendUserDeviceToYandex();
                window.userInfo = {
                    userId: this.ctrl.userSys.user.info.user_id
                };
                fOnloadScript()
            } else {
                /** Через 3 секунды после загрузки страницы, если пользователь не авторизован */
                setTimeout(() => {
                    document.head.appendChild(elGtmScript);
                    this.fSendUserDataToYandex();
                    this.fSendUserDeviceToYandex();
                    fOnloadScript()
                }, 3000);

            }
        };
    }

    /** Отправка данных о устройстве пользователя в Яндекс Метрику */
    public fSendUserDeviceToYandex() {

        /** Ключ яндекс метрики */
        const nMetricKey = yandexAPI.counter;

        const userAgent = window.navigator.userAgent;
        const aCookies = document.cookie;
        const isApp = aCookies.includes('isApp=true'); // Проверка на приложение
        const isMobile = userAgent.includes('Mobile'); // Проверка на mobile device

        const intervalYandexMetric = setInterval(() => {

            if (globalThis.ym !== undefined) {
                if(isApp) {
                    const isAndroid = userAgent.includes('Android'); // Проверка на android device
                    const isIOS = userAgent.includes('iPhone') || userAgent.includes('iPad'); // Проверка на ios device
                    if(isIOS) {
                        /** Вход с приложения IOS */
                        globalThis.ym(nMetricKey, 'params', {
                            device: 'IOS'
                        })
                    }
                    if(isAndroid) {     
                        /** Вход с приложения ANDROID */
                        globalThis.ym(nMetricKey, 'params', {
                            device: 'ANDROID'
                        })
                    }
                } else if(isMobile) {
                    /** Вход с мобильной версии браузера */
                    globalThis.ym(nMetricKey, 'params', {
                        device: 'BROWSER MOBILE'
                    })
                }
                /** Очистка интервала */
                clearInterval(intervalYandexMetric);
            }

        }, 500)

    }

    /** Отправка данных о пользователе в яндекc метрику */
    private fSendUserDataToYandex() {
        /** Флаг авторизации */
        const isAuth = this.ctrl.userSys.user?.isAuth;
        /** Если пользователь авторизован, отправляем его id в метрику */
        const idUser = this.ctrl.userSys.user?.info?.user_id || 0;
        /** Ключ счетчика Яндекс */
        const nMetricKey = yandexAPI.counter;

        /** Интервал дожидается инициализации Яндекс метрики */
        const intervalYandexMetric = setInterval(() => {
            if (globalThis.ym !== undefined) {

                this.stat.isYandexInit = true;
                
                if (isAuth) {
                    /** Отправка id пользователя в метрику */
                    globalThis.ym(nMetricKey, 'userParams', {
                        UserID: idUser
                    });
                    /** Отправка данных об авторизации */
                    globalThis.ym(nMetricKey, 'params', {
                        auth: 'user'
                    })
                } else {
                    /** Отправка данных об авторизации */
                    globalThis.ym(nMetricKey, 'params', {
                        auth: 'guest'
                    })
                }

                /** Отправка отложенных событий В ЯМетрику (ecommerce) */
                if (this.stat.aEcommerceActiontToSend.length){
                    for (let i = 0; i < this.stat.aEcommerceActiontToSend.length; i++){
                        this.fSendEcommerceAction(this.stat.aEcommerceActiontToSend[i])
                    }
                }

                /** Отправка отложенных событий В ЯМетрику (reachGoal) */
                if (this.stat.aActionsToSend.length){
                    for (let i = 0; i < this.stat.aActionsToSend.length; i++){
                        this.fSendActionYM(this.stat.aActionsToSend[i])
                    }
                }

                /** Очистка интервала */
                clearInterval(intervalYandexMetric);
            }
        }, 500)
    }

    /** Отправить данные в аналитику */
    public fSendEcommerceAction (param: { action: string, bNonInteraction: boolean, data: GtmN.IEcommerceActionData }) {

        if (this.stat.isYandexInit) {

            if (!param.data) {
                throw new Error('Данные отсутствуют');
            }
    
            if (!param.data.ecommerce) {
                throw new Error('Параметр ecommerce отсутствует');
            }
    
            const request: GtmN.IEcommerceEventRequestData = {
                action: param.action,
                nonInteraction: String(Boolean(param.bNonInteraction)),
                category: 'ee',
                event: 'ee-event',
                ecommerce: {...param.data.ecommerce, currencyCode: 'RUB'},
            };
    
            if (window.dataLayer) {
                //отправка данных в аналитику - проверять инициализацию не нужно
                window.dataLayer.push(request);
            }

        } else {
            this.stat.aEcommerceActiontToSend.push({
                action: param.action,
                bNonInteraction: param.bNonInteraction,
                data: param.data
            })
        }
    };

    /** Отправка некоммерческого события в яндекс */
    fSendActionYM(sAlias: string){
        if (this.stat.isYandexInit){
            /** Ключ счетчика Яндекс */
            const nMetricKey = yandexAPI.counter;
            globalThis.ym(nMetricKey, 'reachGoal', `${sAlias}`);
        } else {
            this.stat.aActionsToSend.push(sAlias);
        }
    }
}

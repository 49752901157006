import { RefundOrderI } from './EntitySQL/RefundOrderE';
import { RefundOrderImgImgIDsI } from './ImgSQL/RefundOrderImgImgE';
import { ItemIDsI } from './EntitySQL/ItemE';
import { PurchaseIDsI } from './EntitySQL/PurchaseE';
import { PaginationI } from './CommonI/PaginationI';
import { CommentI } from './MsgSQL/CommentE';
import { PostI } from './MsgSQL/PostE';
import { OrderI } from './EntitySQL/OrderE';
import { CommentFileImgI } from './ImgSQL/CommentFileImgE';
import { UserDictionaryI } from './EntitySQL/UserE';
import {
    RefundOrderPoStateT, RefundOrderRequestCloseStateT, RefundOrderRequestI,
    RefundOrderRequestStateT, RefundOrderPvzAndWarehouseStateT
} from './MsgSQL/RefundOrderRequestE';
import { P63OrderCharacteristicIDsI } from './EntitySQL/P63OrderCharacteristicE';
import { MessageActivePointI } from './MsgSQL/MessageActivePointE';
import { MessageI } from './MsgSQL/MessageE';

export namespace RefundOrderOrgR {

    /**
     * Получить список заявок
     */
    export namespace getListRequest {
        /** API URL */
        export const route = '/refund-order-org/get-list-request';

        /** Параметры api запроса */
        export interface RequestI {
            filter_by_purchase_id?: number; // Заявки в закупке
            filter_by_user_id?: number; // Получить заявки по УЗ
            filter_by_list_state?: RefundOrderRequestStateT[]; // Получить заявки со статусами
            filter_by_refund_order_id?: number; // Номер заявки
            filter_by_list_po_state?: RefundOrderPoStateT[]; // Статусы заявки в претензионном отделе
            filter_by_list_pvz_warehouse_state?: RefundOrderPvzAndWarehouseStateT[]; // Статусы заявки в ПВЗ и складе
            filter_by_org_id?: number; // Заявки по организатору

            // Фильтр по дате подачи заявки
            filter_by_date_start?: string; // DATE
            filter_by_date_end?: string; // DATE

            page?: number; // default 1
            limit?: number; // default 30
        }

        /** Параметры api ответа */
        export interface ResponseI {
            list_post: PostI[];
            list_request: RefundOrderRequestI[];
            list_refund_order: RefundOrderI[];
            list_order: OrderI[];
            list_order_characteristic: P63OrderCharacteristicIDsI[],
            list_comment: CommentI[];
            list_comment_file: CommentFileImgI[];
            list_image: RefundOrderImgImgIDsI[];
            list_item: ItemIDsI[];
            list_user_dict: UserDictionaryI[];
            list_purchase: PurchaseIDsI[];
            list_first_org_comment: MessageI[];
            pagination: PaginationI;
        }
    }

    /**
     * Ответить пользователю, подавшему заявку
     */
    export namespace respondToRequestAuthor {
        /** API URL */
        export const route = '/refund-order-org/respond-to-author';

        export interface RequestI {
            refund_order_request_id: number; // ID заявки на которую отвечают
            text: string;                    // Текст сообщения
            list_attachment?: number[];      // Список прикрепленных к сообщению вложений
            active_point: MessageActivePointI; // Точка входа для возврата
        }

        export interface ResponseI {
            thread_id: number;  // ID треда
            message_id: number; // ID нового сообщения
        }
    }

    /**
     * Применить действие по заявке
     */
    export namespace setActionOnRequest {
        /** API URL */
        export const route = '/refund-order-org/set-action-to-request';

        export interface RequestI {
            refund_order_request_id: number; // ID заявки
            send_to_po?: boolean;            // Отправить в претензионный отдел
            require_order?: boolean;   // Запросить товар на проверку
        }

        export interface ResponseI {
            // нет ответа
        }
    }

    /**
     * Закрыть заявку
     */
    export namespace closeRequest {
        /** API URL */
        export const route = '/refund-order-org/close-request';

        export interface RequestI {
            refund_order_request_id: number;                     // ID заявки
            request_close_reason: RefundOrderRequestCloseStateT; // Причины закрытия
        }

        export interface ResponseI {
            // нет ответа
        }
    }

    /** Оставить комментарий к заявке */
    export namespace addCommentToRequest {
        /** API URL */
        export const route = '/refund-order-org/add-comment-to-request';

        export interface RequestI {
            refund_order_request_id: number; // ID заявки, к которой оставляют комментарий
            text: string;                    // Текст комментария
        }

        export interface ResponseI {
            // нет ответа
        }
    }

    /** Получить список ников, найденных по поисковой строке */
    export namespace listUser {
        /** APIURL */
        export const route = '/refund-order-org/list-user';

        /** Параметры api запроса */
        export interface RequestI {
            search: string; // Данные для поиска ников
        }

        /** Параметры api ответа */
        export interface ResponseI {
            list_user: UserDictionaryI[]; // Список ников
        }
    }

    /** Получить количество новых заявок на возврат товара */
    export namespace countNewRefundRequest {
        /** APIURL */
        export const route = '/refund-order-org/count-new-refund-request';

        /** Параметры api запроса */
        export interface RequestI {
            // Нет запроса
        }

        /** Параметры api ответа */
        export interface ResponseI {
            refund_count: number; // Количество новых заявок на возврат товара
        }
    }
}

<script lang="ts">
import Component from 'vue-class-component';
import ReviewCardSource from '../review-card/review-card-source';

@Component
export default class ReviewCard extends ReviewCardSource {}
</script>

<template>
    <div class="review">
        <div class="review__header">
            <div class="review__user">
                <a :target="$sTargetLink" :href="`profile#/${idUser}`" class="review__user-avatar">
                    <img
                        v-if="vUserAvatar && vUserAvatar.filename"
                        class="review__user-avatar-img"
                        :alt="sUsername"
                        :src="sUserAvatar"
                    />
                    <Icon
                        v-else
                        sClassName="review__user-avatar-img"
                        sIconName="icon_review_user_avatar"
                        :sSpritePath="sSpritePath"
                        sWidth="40"
                        sHeight="40"
                        :sAriaLabel="sUsername"
                    />
                </a>
                <div class="review__user-info">
                    <a class="review__user-name" :target="$sTargetLink" :href="sUserUrl">{{ sUsername }}</a>
                    <!--      Рейтинг отзыва      -->
                    <RatingStars v-if="vReview.rating" class="message__rating" :nRating="vReview.rating" />
                </div>
            </div>
            <span class="review__date" v-if="sCreatePostDate">{{ sCreatePostDate }}</span>
        </div>
        <div class="review__body">
            <a v-if="vItem" :href="sItemHref" :target="$sTargetLink" class="review__item-name" @click="fSendActionItemClick">{{ sItemName }}</a>
            <!-- Картинки к отзыву -->
            <ul v-if="vPostImages?.length" class="review__list-image">
                <li v-for="(file, i) in vPostImages" :key="i" class="review__image" @click="fOpenPopupMessageImage(i)">
                    <img
                        v-if="!file.extension"
                        class="review__file"
                        loading="lazy"
                        :src="fGetImageUrlByFileName(file.filename, file.is_big)"
                        :alt="file.filename"
                    />
                </li>
            </ul>
            <p
                class="review__text-close"
                :class="{ 'review__text-open': bExpandReviewText }"
                :id="`review-text ${idPost}`"
            >
                {{ sFormatPostText }}
                <span
                    v-if="bShowAllButton"
                    @click="fShowAllReviewText"
                    :class="{ 'review__show-all__expand': !bExpandReviewText }"
                    class="review__show-all"
                >
                    <i v-if="!bExpandReviewText">...</i>
                    <i v-else>&nbsp;</i>
                    {{ sShowButtonText }}
                </span>
            </p>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import '../../view/mixins.scss';

.review {
    background-color: #fafafa;
    border-radius: 16px;
    padding: 24px;
    min-height: 309px;
    max-width: 100%;
    @include md {
        flex: 1;
        min-height: auto;
        padding: 16px;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;
    }

    &__body {
        display: flex;
        flex-direction: column;
    }

    &__user-avatar {
        display: flex;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        overflow: hidden;
    }

    &__user-avatar-img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        object-position: 50% 50%;
    }

    &__user {
        display: flex;
        gap: 8px;
    }

    &__date {
        color: #606266;
        line-height: 150%;
    }

    &__user-info {
        display: flex;
        flex-direction: column;
        gap: 2px;

        ::v-deep .modal__rate {
            gap: 2px;

            .star {
                margin: 0;
                transform: scale(0.7);
            }
        }
    }

    &__user-name {
        color: #2b2a29;
        font-weight: 600;
        cursor: pointer;
        text-decoration: none;
        line-height: 20px;
        font-size: 14px;
        text-align: left;
        transition: color 0.2s ease;

        &:hover {
            color: #0080f6;
            text-decoration: underline;
        }
    }

    &__item-name {
        color: #36a6f2;
        font-weight: 600;
        cursor: pointer;
        font-size: 16px;
        margin-bottom: 12px;
        text-decoration: none;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
        transition: color 0.2s ease;
        line-height: 150%;

        &:hover {
            color: #0080f6;
        }

        @include md {
            font-size: 14px;
        }
    }

    &__image {
        height: 98px;
        cursor: pointer;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__file {
        width: auto;
        height: 100%;
        border-radius: 4px;
        max-width: 100px;
        object-fit: cover;
    }

    &__list-image {
        padding-left: 0;
        list-style: none;
        max-width: 600px;
        display: flex;
        overflow-x: auto;
        margin: 0 0 12px;
        gap: 12px;
    }

    &__text-close {
        position: relative;
        max-height: 5em;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
        margin: 0;
        color: #2b2a29;
        line-height: 150%; /* 21px */
        transition: all 0.5s ease;
        will-change: contents;
    }

    &__text-open {
        -webkit-line-clamp: 40;
        max-height: 40em;
    }

    &__show-all {
        cursor: pointer;
        color: #36a6f2;
        background: #fafafa;
        white-space: nowrap;
        font-weight: 600;        
        i {
            color: #2b2a29;
        }
        
        &__expand {
            right: 0;
            position: absolute;
            bottom: 0;
        }
    }
}
</style>

<script lang="ts">
import Component from 'vue-class-component';
import Header from '@/common/el/Header/Header.vue';
import OnlineAssistant from '@/common/el/OnlineAssistant/OnlineAssistant.vue';
import Footer from '@/common/el/Footer/Footer.vue';
import Page from '../el/page/page.vue';
import ModalNewApp from '@/common/el/ModalNewApp/ModalNewApp.vue';
import ModalDataUseIos from '@/common/el/ModalDataUseIos/ModalDataUseIos.vue';
import Cookie from '@/common/el/Cookie/Cookie.vue';
import AppNavMenu from '@/common/el/AppNavMenu/AppNavMenu.vue';
import AppHeader from '@/common/el/AppHeader/AppHeader.vue';
import HomeSource from './page-home-source';
import AppPopup from '@/common/el/AppPopup/AppPopup';
import NewHeader from '@/common/el/NewHeader/NewHeader.vue';
import PageNew from '../el/page-new/page-new.vue';
import NewFooter from '@/common/el/NewFooter/NewFooter.vue';
import NotFound404 from '@/common/el/NotFound404/NotFound404.vue';

@Component({
    components: {
        Header,
        OnlineAssistant,
        Footer,
        Page,
        PageNew,
        ModalNewApp,
        ModalDataUseIos,
        Cookie,
        AppNavMenu,
        AppHeader,
        AppPopup,
        NewHeader,
        NewFooter,
        NotFound404,
    },
})
export default class Home extends HomeSource {}
</script>

<template>
    <div v-if="isInit" id="anchor-global">
        <ModalNewApp v-if="isShowModalNewApp" />
        <template v-else>
            <AppNavMenu v-if="isApp" :sCurrentTab="status.current_nav_tab" />
            <AppHeader
                :isShowSearchInput="true"
                :isShowBackWay="false"
                :isShowShareButton="false"
                :isShowNewMenuManagement="isShowNewMenuManagement"
                :aOrgLink="aOrgLink"
                v-if="isApp"
            />
            <template v-else>
                <NewHeader v-if="isABTest" />
                <Header v-else />
            </template>

            <div :class="{ 'body-app': isApp }" :style="vBodyStyleApp">
                <OnlineAssistant :isMobile="status.is_mobile" :isApp="isApp" />
                <ModalDataUseIos v-if="status.is_show_modal_data_use" @close="status.is_show_modal_data_use = false" />
                <Cookie />
                <AppPopup v-if="!isProd" />
                <template v-if="!bIsFullBlock">
                    <main v-if="isABTest">
                        <PageNew />
                    </main>
                    <main v-else class="home">
                        <Page />
                    </main>
                </template>
                <main v-else class="home">
                    <NotFound404 :bErr403="bIsFullBlock" />
                </main>

                <NewFooter v-if="isABTest" />
                <Footer v-else />
            </div>
        </template>
    </div>
</template>

<style lang="scss" scoped>
.home {
    max-width: 1224px;
    margin: 0 auto;
}

main {
    min-width: 360px;
}

.body-app {
    max-height: calc(100vh - 103px);
    margin-top: 47px;
    overflow-y: auto;
}
</style>

<style>
body {
    font-family: 'Open Sans', 'Helvetica', 'Arial', 'sans-serif';
    margin: 0;
    overflow-y: visible !important;
}

[class^='globalClass_'] {
    display: block !important;
}
</style>

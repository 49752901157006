<script lang="ts">
import Component from 'vue-class-component';
import P63Vue from '@/system/P63Vue';
import { Prop } from 'vue-property-decorator';
import { LandingMenuI } from '@/ifc/core/EntitySQL/P63SearchLandingInfoE';
import { CategoryIDsI } from '@/ifc/core/EntitySQL/CategoryE';
import { PageCtrl } from '@/system/PageCtrl';
import { gfInitContextCtrl } from '@/system/ContextSys';
import { mIsClient } from '@/common/helpers/ContextH';

@Component
export default class Purchases extends P63Vue {
    /** Категории закупок */
    @Prop({ type: Array, default: () => [] }) vCategory: CategoryIDsI[];

    /** Получить ссылку на категорию */
    fGetHref(idCategory: number) {
        return this.ctrl.menuSys.fGetPurchaseCategoryUrl(idCategory);
    }

    /** Отправить данные в статистику о клике по закупке в меню категорий*/
    fSendActionOpenCategory(id: number) {
        this.ctrl.statSys.faPushClickToStat({
            page: location.pathname,
            type: 'menu_new',
            alias: 'link_purchases_click_menu_category',
            entity_id: id,
          });
    }

    /** Геттеры */
    get ctrl(): PageCtrl {
        return gfInitContextCtrl(mIsClient() ? this.$context : this.$ssrContext, PageCtrl);
    }
}
</script>

<template>
    <div class="purchases">
        <a v-for="category in vCategory" :key="category.category_id" :href="fGetHref(category.category_id)" @click="fSendActionOpenCategory(category.category_id)">
            <span>{{ category.category_name }}</span>
            <img
                :alt="category.category_name"
                :src="`https://design.63pokupki.ru/common/category/${category.category_id}.webp`"
            />
        </a>
    </div>
</template>

<style scoped lang="scss">
.purchases {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    max-width: 660px;

    a {
        position: relative;
        width: 204px;
        height: 146px;
        padding: 16px;
        text-decoration: none;
        border-radius: 8px;
        background-color: #f5f6fb;
        cursor: pointer;

        span {
            position: relative;
            font-size: 16px;
            font-weight: 600;
            line-height: 120%; /* 19.2px */
            color: #2b2a29;
            z-index: 2;
            transition: color 0.2s ease;
        }

        img {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            transition: transform 0.2s ease;
        }

        &:hover {
            span {
                color: #36a6f2;
            }

            img {
                transform: scale(0.95) translateX(2%) translateY(2%);
            }
        }
    }
}
</style>

import Component from 'vue-class-component';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';

import P63Vue from '@/system/P63Vue';
import { BannerPlaceAliasT } from '@/ifc/core/EntitySQL/P63BannerE';
import { BannerI } from '@/ifc/core/CommonI/BannerI';
import { gLinkS } from '@/common/service/LinkS';
import { gImageS } from '@/common/service/ImageS';
import { mDetectDeviceTypeOnClient, mGetDeviceType } from '@/common/helpers/DeviceTypeH';
import { mIsClient } from '@/common/helpers/ContextH';

import { HomeCtrl, initHomeCtrl, serverHomeCtrl } from './ctrl-home';
import { TabT } from '@/common/el/AppNavMenu/AppNavMenu';
import { gAppNavigatorS } from '@/common/service/AppNavigatorS';

// Суммарная высота, которую занимает навигационное меню сверху и снизу с учетом панели меню для оргов в режиме App
const SUM_HEIGHT_APP_MENU_WITH_PANEL = 144;

@Component
export default class HomeSource extends P63Vue {
    /** Является ли хост продом или релизом */
    isProdOrRelease: boolean = false;

    /** Cмонтировалась ли страница на клиенте */
    isClient = false;
    /** Высота body при показе адм. панели меню */
    sComputedHeight: string = '';

    /** Хранилище */
    get ctrl(): HomeCtrl {
        return initHomeCtrl(mIsClient() ? this.$context : this.$ssrContext);
    }

    /** Флаг production сборки */
    get isProd() {
        let sHost = '';
        const isClient = mIsClient();
        if (isClient) {
            sHost = window.location.host;
        } else {
            sHost = this.$ssrContext.url.host;
        }
        return sHost === '63pokupki.ru';
    }

    /** Показывать ли административное меню */
    get isShowNewMenuManagement() {
        return (
            this.$route.path === '/' &&
            this.vUserInfo.isAuth &&
            (this.vUserInfo.isAdmin ||
                this.vUserInfo.isModerator ||
                this.vUserInfo.isBuh ||
                this.vUserInfo.isOrg ||
                this.vUserInfo.isMarketing)
        );
    }

    /** Информация о пользователе */
    get vUserInfo() {
        return this.ctrl.userSys.user;
    }

    /** Получить сохраненные ссылки */
    get aOrgLink() {
        return this.ctrl.headerSys.header.saved_link;
    }

    /** Высота body в зависимости от показа адм. панели меню */
    get vBodyStyleApp() {
        let sMaxHeight = this.$sBodyHeightApp ? this.$sBodyHeightApp : '';
        let sMarginTop = '';

        if (this.isApp) {
            sMarginTop = '47px';
            if (this.isShowNewMenuManagement) {
                sMaxHeight = this.sComputedHeight;
                sMarginTop = '88px';
            }
        }
        return {
            'max-height': sMaxHeight,
            'margin-top': sMarginTop,
        };
    }

    /** AB тестирование главной */
    get isABTest() {
        return this.ctrl.headerSys.header.is_ab_test;
    }

    /** Забанен ли пользователь */
    get bIsFullBlock() {
        return this.ctrl.userSys.user.bIsFullBlock;
    }

    /** Статусы (примитивы) */
    get status() {
        return this.ctrl.status;
    }

    /** Списки (массивы) */
    get list() {
        return this.ctrl.list;
    }

    /** Индексированные списки (ключ-значение) */
    get ix() {
        return this.ctrl.ix;
    }

    /** Инициализировалась ли страница на клиенте */
    get isInit() {
        return this.status.is_init;
    }

    /** Авторизован ли пользователь */
    get isAuth() {
        return this.ctrl.userSys.user.isAuth;
    }

    /** Показывать ли меню организаторов */
    get isMenuManagementSectionsVisible() {
        return this.ctrl.userSys.user.isOrg;
    }

    /** Открыт ли сайт с приложения */
    get isApp() {
        return this.ctrl.isApp;
    }

    // Выполнить код на сервере
    async serverPrefetch() {
        const ctrl = serverHomeCtrl(this.$ssrContext);

        // * ОПРЕДЕЛЯЕМ ТЕКУЩУЮ ВКЛАДКУ ДЛЯ НАВИГАЦИИ ПРИЛОЖЕНИЯ
        const sUrl: string = this.$ssrContext.req.url;
        const sPathname: string = sUrl.split('?')[0];

        if (sPathname === '/') {
            ctrl.status.current_nav_tab = TabT.home;
        }

        // Определить тип устройства пользователя на стороне сервера
        const { isMobile, isTablet, isDesktop } = mGetDeviceType(ctrl.deviceType);

        ctrl.status.is_mobile = isMobile;
        ctrl.status.is_tablet = isTablet;
        ctrl.status.is_desktop = isDesktop;

        const isApp = ctrl.isApp; // Проверка на приложение
        const isOldApp = sUrl.includes('isOldApp'); // Проверка на использование старой версии приложения
        if (isApp && isMobile && isOldApp) {
            this.ctrl.status.is_show_modal_new_app = true;
        }

        // Инициализировать страницу
        await ctrl.faInit();

        // Вставить метаданные и разметку Open Graph
        this.$ssrContext['meta'] = {
            title: this.status.store_title,
            description: this.status.store_description,
            og_type: 'website',
            og_title: this.status.store_title,
            og_url: 'https://63pokupki.ru',
            og_image: require('@/assets/header/logotype.svg'),
            og_sitename: '63pokupki',
            og_description: `${this.status.store_description}`,
        };

        // Алиасы верхних баннеров
        const sTopBannerAlias: string =
            isDesktop || isTablet ? BannerPlaceAliasT.homeBPageTop : BannerPlaceAliasT.homeBPageMobile1;

        // Массивы баннеров, сгруппированные и индексированные по алиасу
        const ixBannersByAlias: Record<string, BannerI[]> = groupBy(this.list.all_banners, 'banner_place_alias');

        this.list.top_banner = sortBy(ixBannersByAlias[sTopBannerAlias], 'banner_order');
        this.ix.banners_by_alias = { ...this.ix.banners_by_alias, ...ixBannersByAlias };

        if (sTopBannerAlias === BannerPlaceAliasT.homeBPageMobile1) {
            // Предзагрузить первый и второй мобильные баннеры, если они есть
            const vFirstTopBanner = this.list.top_banner[0];
            const vSecondTopBanner = this.list.top_banner[1];

            this.$ssrContext['top_banner'] = {
                first_url: vFirstTopBanner?.filename ? gImageS.fBannerImageUrlWebp(vFirstTopBanner.filename) : '',
                second_url: vSecondTopBanner?.filename ? gImageS.fBannerImageUrlWebp(vSecondTopBanner?.filename) : '',
            };
        } else {
            // Предзагрузить первый десктопный баннер, если он есть
            const vFirstTopBanner = this.list.top_banner[0];

            this.$ssrContext['top_banner'] = {
                first_url: vFirstTopBanner?.filename ? gImageS.fBannerImageUrlWebp(vFirstTopBanner.filename) : '',
                second_url: '',
            };
        }

        this.$ssrContext['font_first'] = this.status.sFirstFonts;
        this.$ssrContext['font_second'] = this.status.sSecondFonts;
        this.$ssrContext['font_third'] = this.status.sThirdFonts;
    }

    // Хуки
    async mounted() {
        /** Тип устройства пользователя на стороне клиента */
        const sClientDeviceType = mDetectDeviceTypeOnClient();

        // Получить тип устройства из серверного контекста
        if (this.ctrl.deviceType !== sClientDeviceType) {
            // Обновить переменные типов устройств
            this.ctrl.deviceType = sClientDeviceType;

            const { isMobile, isTablet, isDesktop } = mGetDeviceType(this.ctrl.deviceType);

            this.status.is_mobile = isMobile;
            this.status.is_tablet = isTablet;
            this.status.is_desktop = isDesktop;

            // Обновить списки баннеров
            const { homeBPageTop, homeBPageMobile1 } = BannerPlaceAliasT;
            const sTopBannerAlias: string = isDesktop || isTablet ? homeBPageTop : homeBPageMobile1;
            const ixBannersByAlias: Record<string, BannerI[]> = groupBy(this.list.all_banners, 'banner_place_alias');

            this.list.top_banner = sortBy(ixBannersByAlias[sTopBannerAlias], 'banner_order');
            this.ix.banners_by_alias = { ...this.ix.banners_by_alias, ...ixBannersByAlias };
        }

        // Перенаправить пользователя в корзину, если он частично заблокирован
        if (this.ctrl.userSys.user.bIsPartialBlock) {
            window.location.href = gLinkS.fGetCartUrl();
        }

        // Подключение SERVICE WORKER PWA, если браузер поддерживает это
        if (navigator.serviceWorker) {
            try {
                await navigator.serviceWorker.register('/sw.js');
            } catch (e) {
                console.error('<ERROR> Ошибка регистрации SERVICE WORKER', e);
            }
        }

        if (window.location.host === '63pokupki.ru' || window.location.host === 'release.63pokupki.ru') {
            this.isProdOrRelease = true;
        }

        if (this.isShowNewMenuManagement) {
            this.sComputedHeight = `${document.documentElement.clientHeight - SUM_HEIGHT_APP_MENU_WITH_PANEL}px`;
        }

        this.isClient = true;

        gAppNavigatorS.fAddPosition();
        const isShowModalDataUse = localStorage.getItem('isAgreeDataUse'); // Проверка показывалась ли уже модалка об использовании данных пользователя
        const isOpenAppStore = this.$route.query['iOS']; // Проверка на то, что приложение было открыто через AppStore
        if (isOpenAppStore && !isShowModalDataUse) {
            this.ctrl.status.is_show_modal_data_use = true;
        }

        if (isOpenAppStore) {
            localStorage.setItem('isIos', 'true');
        }
    }

    /** Модалка для скачивания новой версии приложения */
    get isShowModalNewApp() {
        return this.ctrl.status.is_show_modal_new_app;
    }
}


/**
 * Интерфейс таблицы city
 */
export interface CityI {
    city_id?: number;				// ID города
    region_id?: number;				// ID региона
    city_alias?: string;			// Псевдоним города
    city_name?: string;				// Название города
    city_latitude?: string;			// Широта
    city_longitude?: string;		// Долгота
    city_prefix?: string;			// Префикс города
    city_weight?: number;			// Вес города
    city_meta_keywords?: string;	// Ключевые слова
    city_meta_description?: string;	// Описание
    city_seo_title?: string;		// СЕО заголовок
    city_seo_text?: string;			// СЕО текст
}

/**
 * Краткая информация о городе
 */
export interface CityIDsI {
    city_id?: number;			// ID города
    city_alias?: string;        // Alias города
    region_id?: number;			// ID региона
}

/**
 * Краткая информация о городе
 */
export interface CityIxI {
    city_id?: number;			// ID города
    region_id?: number;			// ID региона
    city_name?: string;			// Название города
    city_latitude?: string;		// Широта
    city_longitude?: string;	// Долгота
    city_prefix?: string;		// Префикс города
}

/**
 * Кол-во наших пвз в городе
 */
export interface CityWithPvzCountI {
    city_id?: number;
    city_name?: string;
    pvz_count?: number;
}

/** ID города "Самара" */
export const idSamara = 1;

/**
 * Переменные товаров
 */

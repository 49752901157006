import { BaseApi } from '@/api/base.api';
import { ErrorR as Routes } from './error.routes';

export default class ErrorApi extends BaseApi {
  /** Отправка ошибки в маттермост */
  async sendErrorToMattermost(request: Routes.sendToMattermost.RequestI) {
    return await this.fetch<Routes.sendToMattermost.ResponseI>(
      Routes.sendToMattermost.route,
      request
    );
  }
}

import type { AxiosRequestConfig, AxiosError, AxiosInstance } from 'axios';

/** Интерфейс общего ответа от api */
export interface SuccessResponceI<T> {
  data: T;
  e: boolean;
  errors: { [key: string]: string };
  msg: string;
  ok: boolean;
  warning: { [key: string]: string };
}
export interface ErrorResponceI {
  data: null;
  e: true;
  errors: { [key: string]: string };
  msg: string;
  ok: false;
  warning: { [key: string]: string };
}

/** Базовый сервис для запросов к api */
class BaseApi {
  public base: string;
  public axios: AxiosInstance;

  constructor(config: { base: string; axios: AxiosInstance }) {
    this.base = config.base;
    this.axios = config.axios;
  }

  /** Запрос на наш апи */
  protected async fetch<Responce>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<SuccessResponceI<Responce> | AxiosError<ErrorResponceI>> {
    try {
      const response = await this.axios.post<SuccessResponceI<Responce>>(
        `${this.base}${url}`,
        data,
        config
      );

      return response.data;
    } catch (error) {
      return error;
    }
  }
}

export default BaseApi;

export { BaseApi };

import Component from 'vue-class-component';

import P63Vue from '@/system/P63Vue';

@Component
export default class Cookie extends P63Vue {
    // Статус показа уведомления
    bCookiesAlertOn: boolean = false;

    /** Сохраняем подтверждение использование куки пользователем в localStorage */
    fAcceptUseCookies(): void {
        localStorage.setItem("bUseCookies", "true");
        this.bCookiesAlertOn = false;
    };

    async mounted() {
        // Если не нашли подтверждающий ключ, то показываем окно с уведомлением
        if (!localStorage.getItem("bUseCookies")) {
            this.bCookiesAlertOn = true;
        };
    };
};
import { Component } from 'vue-property-decorator';
import { HomeCtrl, initHomeCtrl } from '@/pages/home/view/ctrl-home';
import P63Vue from '@/system/P63Vue';
import { mIsClient } from '@/common/helpers/ContextH';
import { gImageS } from '@/common/service/ImageS';

@Component
export default class HomeStory extends P63Vue {
    /** Форматирование подборки */
    fFormatTitle(sTitle: string) {
        let formatTitle = sTitle;
        if (sTitle.length > 8) {
            formatTitle = formatTitle.slice(0, 7);
        }
        return formatTitle;
    }

    /** Открытие слайдера*/
    fOpenPopup(index: number, id: number) {
        this.ctrl.status.is_open_popup_place = true;
        this.ctrl.status.index_of_story = index;
        if (this.isAuth) {
            if (!this.isBannerViewed && !this.ixChekecdStory[id]) {
                this.aCheckedBanner.push(this.fCurrentBannerOfCollId(index));
                this.ctrl.fMarkCollectionAsRead({
                    bnr_collection_id: this.aBannerCollection[index].id,
                    bnr_id: this.fCurrentBannerOfCollId(index)
                });
            }
        }
        document.body.style.overflowY = "hidden"
        this.ix.checked_story[this.aBannerCollection[index].id] = true;
    }
    /** Получение id баннера */
    fCurrentBannerOfCollId(index: number) {
        return this.ixBannerOfCollection[this.aBannerCollection[index].id][0].bnr_id
    }
    /** Форматирование картинки для подборки */
    sBannerImageLinkJpg(img: string) {
        return gImageS.fBannerCollImageUrlJpg(img);
    }

    /** Форматирование картинки для подборки */
    sBannerImageLinkWebp(img: string) {
      return gImageS.fBannerCollImageUrlWebp(img);
    }
    // Хуки
    mounted() {
        if (!this.isAuth) {
            const aidStoryAsReadInLocalStorage = JSON.parse(localStorage.getItem('aidStoryAsRead'))
            if (!aidStoryAsReadInLocalStorage) {
                localStorage.setItem(
                    'aidStoryAsRead',
                    JSON.stringify(this.ctrl.list.bnr_collection_read_id ? this.ctrl.list.bnr_collection_read_id : [])
                );
                this.ctrl.list.bnr_collection_read_id = []
            } else {
                this.ctrl.list.bnr_collection_read_id = aidStoryAsReadInLocalStorage
            }

            this.ix.checked_story = Object.fromEntries(
                this.ctrl.list.bnr_collection_read_id.map((idBanner) => [idBanner, true])
            );
        }
    }
    // Геттеры
    /** Состояние слайдера */
    get isSliderOpen() {
        return this.ctrl.status.is_open_popup_place;
    }

    /** Баннеры подборки */
    get ixBannerOfCollection() {
        return this.ctrl.ix.banners_of_collection
    }

    /** Список подборок */
    get aBannerCollection() {
        return this.ctrl.list.all_banners_collection;
    }
    /** Список ID просмотренных историй*/
    get aiStoryAsRead() {
        return this.ctrl.list.bnr_collection_read_id;
    }

    /** Проверка авторизован ли пользователь */
    get isAuth() {
        return this.ctrl.userSys.user.isAuth;
    }
    /** Список просмотренных баннеров */
    get aCheckedBanner() {
        return this.ctrl.list.bnr_of_collection_read_id
    }
    /** Список просмотренных историй */
    get ixChekecdStory() {
        return this.ctrl.ix.checked_story;
    }
    /** Проверка является ли текущий баннер просмотренным */
    get isBannerViewed() {
        return this.aCheckedBanner.includes(this.ctrl.one.banner_id)
    }
    /** Хранилище  списков*/
    get ix() {
        return this.ctrl.ix;
    }
    /** Хранилище */
    get ctrl(): HomeCtrl {
        return initHomeCtrl(mIsClient() ? this.$context : this.$ssrContext);
    }
}

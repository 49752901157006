<script lang="ts">
import Component from 'vue-class-component';
import CardAdvantageSource from './card-advantage-source';

@Component
export default class CardAdvantage extends CardAdvantageSource {}
</script>

<template>
    <li class="card-adv">
        <a :href="sLink" class="card-adv__link" :target="$sTargetLink" @click="fSendActionAdvantageClick">
            <div class="card-adv__info">
                <h5 class="card-adv__title">{{ sTitle }}</h5>
                <p class="card-adv__text">{{ sText }}</p>
            </div>
            <img class="card-adv__img" :src="sImage" :alt="sTitle" />
        </a>
    </li>
</template>

<style lang="scss" scoped>
.card-adv {
    position: relative;
    width: 318px;
    height: 140px;
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    grid-row: 1;
    scroll-snap-align: start;

    &:first-child {
        order: -1;
    }

    @media (max-width: 767px) {
        width: 235px;
        height: 150px;
    }
    &__link {
        text-decoration: none;
    }
    &__info {
        height: 100%;
        display: flex;
        flex-direction: column;
        font-family: 'Open Sans', Arial, Helvetica, sans-serif;
        padding: 16px 0 16px 16px;
        position: relative;
        z-index: 2;
    }
    &__title {
        font-size: 20px;
        font-weight: 600;
        line-height: 115%; /* 23px */
        margin: 0 0 8px;
        width: 255px;
        color: #2b2a29;
        white-space: break-spaces;
        transition: color 0.3s ease-out;
        @media (max-width: 767px) {
            font-size: 14px;
            width: 177px;
        }
    }
    &:hover &__title {
        color: #36a6f2;
    }
    &__text {
        font-size: 14px;
        font-weight: 400;
        line-height: 1.2;
        width: 210px;
        margin: 0;
        color: #606266;
        white-space: break-spaces;
        @media (max-width: 767px) {
            font-size: 12px;
            width: 105px;
        }
    }
    &__img {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100px;
        height: 100px;
        z-index: 1;
        transition: transform 0.3s ease-out;
        @media (max-width: 767px) {
            width: 130px;
            height: 130px;
            right: -25px;
        }
    }
    &:hover &__img {
        transform: scale(1.07);
    }
}
</style>

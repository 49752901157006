import { PageCtrl } from './PageCtrl';
import isEqual from 'lodash/isEqual';
import { PageR } from './PageR';
import { ModelRulesC, ModelRulesT } from '@63pokupki/components';
import { Route } from 'vue-router';
import { mIsClient } from '@/common/helpers/ContextH';

export class RouteSys {
  private vRouter = null;

  private vDefRoute = null;

  private vRouterRule = null;

  /** конструктор */
  constructor(ctrl: PageCtrl) {}

  /** Инициализация на странице */
  public fInit(vRouter: Route, vRouterRule: PageR, vDefRoute: any) {
    this.vRouter = vRouter;

    this.vDefRoute = vDefRoute;

    this.vRouterRule = vRouterRule;
  }

  /** Обновляет query (?a=1&b=2) в соответствии с новыми параметрами.
   * @param {any} data - данные (query params)
   * @param {boolean} bChangeUrlMode - режим редактирования URL
   * 1. Берет существующие параметры, обрабатывает их методом Route.parse.
   * 2. Объединяет существующие параметры с новыми значениями.
   * 3. Очищает от значений по умолчанию
   * 4. Перевод значения в строки
   * 5. Обновляет url
   */
  public fSync(data: any, bChangeUrlMode: boolean = true) {
    // Объединяем запросы
    const newQuery = { ...this.vRouter.currentRoute.query, ...this.vRouter.currentRoute.params, ...data };
    
    // console.log('newQuery>>>>', newQuery);

    // Убирает из query значения, которые равны себе по умолчанию.
    const filteredQuery = this.fFilterDefault(newQuery);

    // console.log('filteredQuery>>>>', filteredQuery)

    // Устанавливаем хранилище
    const toStoreQuery = this.vRouterRule.fDownRoute(filteredQuery);

    // console.log('toStoreQuery>>>>', toStoreQuery)

    // Установить в значения в URL
    const toRouteQuery = this.vRouterRule.fUpRoute(filteredQuery);

    // console.log('toRouteQuery>>>>', toRouteQuery);
    
    if (mIsClient() && bChangeUrlMode) {
      // Убираем дефолтные параметры для строки браузера
      const filteredToURL = this.fFilterToURL(toRouteQuery);

      //   console.log('filteredToURL>>>>', filteredToURL);

      const vQueryParam = { query: filteredToURL };
      const sFrom = this.vRouter.currentRoute.fullPath
      const sTo = this.vRouter.resolve(vQueryParam).route.fullPath
      if (sFrom !== sTo) {
          this.vRouter.push(vQueryParam);
      }
    }
  }

    /** Обновляет query (?a=1&b=2) в соответствии с новыми параметрами.
   * @param {any} data - данные (path и query params)
   * @param {boolean} bChangeUrlMode - режим редактирования URL
   * 1. Берет существующие параметры, обрабатывает их методом Route.parse.
   * 2. Объединяет существующие параметры с новыми значениями.
   * 3. Очищает от значений по умолчанию
   * 4. Перевод значения в строки
   * 5. Обновляет url
   */
    public fSyncQueryAndPath(data: {path?: string, query?: any}, bChangeUrlMode: boolean = true) {
      // Объединяем запросы
      const newQuery = { ...this.vRouter.currentRoute.query, ...this.vRouter.currentRoute.params, ...data.query };
      
      // console.log('newQuery>>>>', newQuery);
  
      // Убирает из query значения, которые равны себе по умолчанию.
      const filteredQuery = this.fFilterDefault(newQuery);
  
      // console.log('filteredQuery>>>>', filteredQuery)
  
      // Установить в значения в URL
      const toRouteQuery = this.vRouterRule.fUpRoute(filteredQuery);
  
      // console.log('toRouteQuery>>>>', toRouteQuery);
      
      if (mIsClient() && bChangeUrlMode) {
        // Убираем дефолтные параметры для строки браузера
        const filteredToURL = this.fFilterToURL(toRouteQuery);
  
          // console.log('filteredToURL>>>>', filteredToURL);
  
        const vPathAndQueryParam = { path: data.path, query: filteredToURL };
        const sFrom = this.vRouter.currentRoute.fullPath
        const sTo = this.vRouter.resolve(vPathAndQueryParam).route.fullPath
        if (sFrom !== sTo) {
            this.vRouter.push(vPathAndQueryParam);
        }
      }
    }

  /** Установить значения по умолчанию */
  public fDef() {
    this.fSync(this.vRouterRule.vDefRoute);
  }

  /** Убирает из query значения, которые равны себе по умолчанию.
   *
   * @example
   * { page: 0 } -> {}, т.к page по умолчанию 0
   */
  private fFilterDefault(newQuery: Object): Object {
    const filteredQuery = Object.fromEntries(
      Object.entries(newQuery).filter(([key, value]) => {
        return !isEqual(value, this.vDefRoute[key]) && !isEqual(value, undefined);
      })
    );

    return filteredQuery;
  }

  /** Убирает из query значения, которые равны себе по умолчанию.
   *
   * @example
   * { page: 0 } -> {}, т.к page по умолчанию 0
   */
  private fFilterToURL(newQuery: Object): Object {
    const ixParams = this.vRouter.currentRoute.params ? this.vRouter.currentRoute.params : {};

    const filteredQuery = Object.fromEntries(
      Object.entries(newQuery).filter(([key, value]) => {
        return !isEqual(value, this.vDefRoute[key]) && !isEqual(value, undefined) && !ixParams[key];
      })
    );

    return filteredQuery;
  }

  /** добавить правила для UTM меток */
  public validUtmRule(rules: ModelRulesC) {
    rules.set(rules.rule('utm').type(ModelRulesT.text).errorEx('utm', 'utm'));

    rules.set(rules.rule('utm_source').type(ModelRulesT.text).errorEx('utm_source', 'utm_source'));

    rules.set(rules.rule('utm_medium').type(ModelRulesT.text).errorEx('utm_medium', 'utm_medium'));

    rules.set(rules.rule('utm_term').type(ModelRulesT.text).errorEx('utm_term', 'utm_term'));

    rules.set(rules.rule('utm_term').type(ModelRulesT.text).errorEx('utm_term', 'utm_term'));

    rules.set(rules.rule('utm_content').type(ModelRulesT.text).errorEx('utm_content', 'utm_content'));

    rules.set(rules.rule('utm_campaign').type(ModelRulesT.text).errorEx('utm_campaign', 'utm_campaign'));

    return rules;
  }
}

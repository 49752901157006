/** Отправка ошибок */
export namespace ErrorR {
  export namespace sendToMattermost {
    /** APIURL */
    export const route = '/common/front-error';

    /** Параметры api запроса */
    export interface RequestI {
      errors: Array<{
        title: string;
        value: string;
      }>;
    }

    /** Параметры api ответа */
    export interface ResponseI {}
  }
}


/**
 * Описание полей таблицы тем
 */
export interface TopicI {
    id?: number; // ID темы
    type?: TopicT; // Тип темы
    title?: string; // Заголовок темы
    alias?: string; // Алиас темы
    author_user_id?: number; // ID создателя темы
    created_at?: string; // Дата и время создания темы
    is_closed?: boolean; // Флаг: закрыта ли тема
    is_deleted?: boolean; // Флаг: удалена ли тема
    deleted_at?: string; // Дата и время удаления темы
    parrent_topic_id?: number; // ID родительской темы
}

/**
 * Типы тем
 */
export enum TopicT {
    common = 'common', // Обычная тема
    appeal = 'appeal', // Тема-заявка
    review = 'review', // Тема-отзыв
    document = 'document', // Тема-документация
    qa = 'qa', // Тема 'ВОПРОС-ОТВЕТ по закупке'
    claim = 'claim', // Тема-жалоба
    document_news = 'document_news', // Тема-документация/новость
    buh_receipt = 'buh_receipt ', // Тема для общения бухгалтеров и оргов
    requirement = 'requirement', // ???
    news = 'news', // Новости
    refund_order = 'refund_order', // Тема по заявкам на возврат заказа
    blog = 'blog' // Тема статьи блога
}

/**
 * Алиасы
 */
export enum TopicAliasT {
    private_message_claim = 'private_message_claim',
    review_claim = 'review_claim',
    question_claim = 'question_claim',
    footer_claim = 'footer_claim',
    item_price_claim = 'item_price_claim',
    support = 'support',
    org_claim = 'org_claim'
}

/**
 * Тема
 */

<template>
    <div class="home-registration">
        <p class="home-registration__title">Совместные покупки&nbsp;&mdash; это выгодно!</p>
        <p class="home-registration__text">Участвуйте в&nbsp;наших закупках и&nbsp;экономьте до&nbsp;90%</p>
        <button class="home-registration__button-link" @click="fSendActionClick">Быстрая регистрация</button>
    </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { HomeCtrl, initHomeCtrl } from '@/pages/home/view/ctrl-home';

import P63Vue from '@/system/P63Vue';
import { mIsClient } from '@/common/helpers/ContextH';

@Component
export default class HomeRegistration extends P63Vue {
    /** Отправить данные в статистику о клике по кнопке и перенаправить на страницу регистрации */
    fSendActionClick() {
        this.ctrl.statSys.faPushClickToStat({
            page: location.pathname,
            type: 'button',
            alias: 'button_click_fast_reg',
            entity_id: 0,
        });

        setTimeout(() => (window.location.href = this.sFastRegistrationLink), 0);
    }

    /** Ссылка на страницу регистрации с отправкой события в ЯМетрику */
    get sFastRegistrationLink() {
        return `/auth#/register?redirect=${encodeURIComponent(this.$route.path)}&reachGoal=HomeRegistrationClick`;
    }

    /** Хранилище */
    get ctrl(): HomeCtrl {
        return initHomeCtrl(mIsClient() ? this.$context : this.$ssrContext);
    }
}
</script>

<style lang="scss" scoped>
.home-registration {
    display: grid;
    justify-items: center;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, min-content);

    padding: 16px;

    color: #1d1e1f;
    border-radius: 14px;
    background: #ffffff;
    box-shadow: 0 0 24px rgba(227, 227, 227, 0.25);

    @media (min-width: 768px) {
        justify-items: stretch;
        column-gap: 20px;
        grid-template-columns: 1fr min-content;
        grid-template-rows: repeat(2, min-content);

        padding: 23px 30px;
    }

    @media (min-width: 1024px) {
        padding: 23px 93px;
    }

    &__title {
        grid-column: 1 / 2;
        grid-row: 1 / 2;

        margin: 0;
        padding: 0;

        text-align: center;

        font-size: 22px;
        font-weight: 700;

        @media (min-width: 768px) {
            grid-column: 1 / 2;
            grid-row: 1 / 2;

            text-align: start;
        }
    }

    &__text {
        grid-column: 1 / 2;
        grid-row: 2 / 3;

        margin: 0;
        margin-top: 8px;
        padding: 0;

        text-align: center;

        font-size: 14px;

        @media (min-width: 768px) {
            grid-column: 1 / 2;
            grid-row: 2 / 3;

            text-align: start;
        }
    }

    &__button-link {
        grid-column: 1 / 2;
        grid-row: 3 / 4;

        width: 100%;
        max-width: 280px;
        height: 36px;
        margin-top: 15px;

        cursor: pointer;
        transition: background 0.1s ease-in-out, color 0.1s ease-in-out;
        text-align: center;
        text-decoration: none;

        color: #fff;
        border: 1px solid #36a6f2;
        border-radius: 39px;
        background: #36a6f2;

        font-size: 14px;
        font-weight: 700;
        line-height: 36px;

        &:hover {
            color: #36a6f2;
            background: #fff;
        }

        &:active {
            transform: scale(0.95);
        }

        @media (min-width: 768px) {
            width: 398px;
            max-width: none;
            height: 56px;
            margin-top: 0;

            font-size: 20px;
            line-height: 56px;

            @media (min-width: 768px) {
                grid-column: 2 / 3;
                grid-row: 1 / 3;
            }
        }
    }
}
</style>

<script lang="ts">
import Component from 'vue-class-component';
import P63Vue from '@/system/P63Vue';
import Tooltip from '@/ds/el/tooltip/tooltip';
import UserCityD from '@/common/el/UserCity/NewUserCityD.vue';

@Component({
    components: { UserCityD, Tooltip },
})
export default class HeaderTop extends P63Vue {}
</script>

<template>
    <div class="header-top">
        <nav class="header-top__links">
            <UserCityD />
            <a class="header-top__link" href="/about-us/"> О совместных покупках </a>
            <a class="header-top__link" href="https://school.63pokupki.ru/">Стать организатором</a>
        </nav>

        <div class="header-top__info">
            <!-- <a class="header-top__tel" href="tel:+78007006301">8 (800) 700-63-01</a>
            <Tooltip>
                <Icon sIconName="information" sWidth="20" sHeight="20" />
                <template v-slot:content>
                    <div class="header-top__tooltip">7:00 - 19:00 Время Московское, звонок бесплатный</div>
                </template>
            </Tooltip> -->
            <a class="header-top__faq" href="/page/faq">Помощь</a>
        </div>
    </div>
</template>

<style scoped lang="scss">
.header-top {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 8px 24px;
    width: 100%;
    max-width: 1440px;
    background-color: #fff;

    @media (max-width: 767px) {
        display: none;
    }

    @media (min-width: 1440px) {
        padding: 8px 48px;
    }

    &__links {
        display: flex;
        align-items: end;
        gap: 24px;
        font-size: 13px;
        width: 100%;

        ::v-deep .city-button {
            transition: color 0.2s ease;

            .ds-icon {
                transition: fill 0.2s ease;
            }

            &:hover {
                text-decoration: none;
                color: #36a5f2;

                .ds-icon {
                    fill: #36a5f2;
                }
            }
        }
    }

    &__link {
        display: flex;
        align-items: center;
        color: #606266;
        font-size: 12px;
        font-weight: 400;
        line-height: 150%;
        cursor: pointer;
        text-decoration: none;
        transition: color 0.2s ease;

        &:hover {
            color: #36a5f2;
        }
    }

    &__info {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;

        .has-tooltip {
            height: 20px;

            .ds-icon {
                fill: #606266;
                transition: fill 0.2s ease;
            }
        }
    }

    &__tel {
        margin-right: 4px;
        font-size: 12px;
        font-weight: 600;
        line-height: 150%;
        color: #606266;
        text-decoration: none;
        white-space: nowrap;
        transition: color 0.2s ease;

        &:hover {
            color: #36a5f2;
        }

        &:hover + .has-tooltip {
            .ds-icon {
                fill: #36a5f2;
            }
        }
    }

    &__tooltip {
        width: 200px;
    }

    &__faq {
        border-radius: 24px;
        background: #36a5f2;
        padding: 0 6px;
        margin-left: 16px;
        font-size: 12px;
        line-height: 150%;
        font-weight: 600;
        cursor: pointer;
        display: inline-flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        color: #fff;
        border: 1px solid #36a6f2;
        text-decoration: none;
        transition: background 0.2s ease-in-out, color 0.2s ease-in-out;

        &:hover {
            background: #0080f6;
            border-color: #0080f6;
        }
    }
}
</style>

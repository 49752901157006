import Component from 'vue-class-component';
import P63Vue from '@/system/P63Vue';
import { mIsClient } from '@/common/helpers/ContextH';
import { gImageS } from '@/common/service/ImageS';
import { HomeCtrl, initHomeCtrl } from '@/pages/home/view/ctrl-home';
import { HomeN as N } from '@/pages/home/view/ifc-home';
import { mFormatInvertedDateDotsTime } from '@/common/helpers/DateTimeH';
import { NewsInfoI } from '@/ifc/msg/EntitySQL/NewsE';

@Component
export default class HomeNewsSource extends P63Vue {
    /** Хранилище */
    get ctrl(): HomeCtrl {
        return initHomeCtrl(mIsClient() ? this.$context : this.$ssrContext);
    }

    /** Новости */
    get aNews() {
        return this.ctrl.list.all_news;
    }

    /** Изображения новостей  */
    get aImage() {
        return this.ctrl.list.news_images;
    }

    /** filename изображений, сортированные по post_id  */
    get ixImageFileName() {
        const ixImageFileName = {};

        for (let i = 0; i < this.aImage.length; i++) {
            const vImage = this.aImage[i];

            ixImageFileName[vImage.post_id] = vImage.filename;
        }

        return ixImageFileName;
    }

    /** URL и дата новости, индексированные по post_id */
    get ixNewsData() {
        const ixNewsData: Record<number, N.NewsDataI> = {};

        for (let i = 0; i < this.aNews.length; i++) {
            const vNews = this.aNews[i];

            ixNewsData[vNews.post_id] = {
                imageUrl: this.fGetImageUrlByFileName(this.ixImageFileName[vNews.post_id]),
                date: mFormatInvertedDateDotsTime(vNews.created_at),
                detailLink: '/news/' + vNews.post_id,
            };
        }

        return ixNewsData;
    }

    /** Получить URL изображения по его filename */
    fGetImageUrlByFileName(sFileName: string): string {
        let sUrl = 'https://614119.selcdn.ru/design/home-page/no-news-image.jpg';

        if (sFileName) {
            sUrl = gImageS.fPostImageUrlx512(sFileName);
        }

        return sUrl;
    }

/** Отправить данные в статистику о клике по новости */
    fPushClickToStat(vNews: NewsInfoI) {
      this.ctrl.statSys.faPushClickToStat({
        page: location.pathname,
        type: 'news',
        alias: 'news_click',
        entity_id: vNews.post_id,
      });
    }

/** Отправить данные в статистику о клике по кнопке "Смотреть другие новости" */
    fSendActionOpenMoreNews() {
        this.ctrl.statSys.faPushClickToStat({
            page: location.pathname,
            type: 'news',
            alias: 'button_show_more',
            entity_id: 0,
          });
    }
}

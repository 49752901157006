import { HomeN } from '@/pages/home/view/ifc-home';
import { Component } from 'vue-property-decorator';
import P63Vue from '@/system/P63Vue';
import CardAdvantage from '../card-advantage/card-advantage.vue';
import IconAccess from '@/assets/images/home/icon-access.webp';
import IconDelivery from '@/assets/images/home/icon-delivery.webp';
import IconItem from '@/assets/images/home/icon-item.webp';
import IconOriginal from '@/assets/images/home/icon-original.webp';
import IconPrice from '@/assets/images/home/icon-price.webp';
import ArrowButton from '../arrow-button/arrow-button.vue';
import { HomeCtrl, initHomeCtrl } from '../../view/ctrl-home';
import { mIsClient } from '@/common/helpers/ContextH';
import ListCounts from '../list-counts/list-counts.vue';

@Component({ components: { CardAdvantage, ArrowButton, ListCounts } })
export default class InfoblockSource extends P63Vue {
    /** Ширина карточки для прокрутки на десктопе */
    readonly WIDTH_CARD_WITH_PADDING: number = 330;
    /** Список для отображения карточек с преимуществами */
    aAdvantage: HomeN.AdvantageI[] = [
        {
            title: '63pokupki — ваш доступ к\u00A0оптовым ценам',
            text: 'Товары напрямую от фабрик и поставщиков',
            image: IconAccess,
            link: '/about-us',
            alias: 'card_click_advantage_about_us',
        },
        {
            title: 'Цена — наша\nсуперсила',
            text: 'Выкупаем ваши розничные заказы оптовыми партиями',
            image: IconPrice,
            link: '/page/pochemu-u-nas-deshevle/',
            alias: 'card_click_advantage_price',
        },
        {
            title: 'Доставка\nпо всей России',
            text: 'Где бы вы не\nнаходились, у нас найдется пункт выдачи',
            image: IconDelivery,
            link: '/page/delivery',
            alias: 'card_click_advantage_delivery',
        },
        {
            title: '100%\nоригиналы',
            text: 'Работаем только с надежными поставщиками',
            image: IconOriginal,
            link: '/purchases/',
            alias: 'card_click_advantage_originals',
        },
        {
            title: 'Огромный\nассортимент',
            text: '3 млн товаров для себя, семьи и дома',
            image: IconItem,
            link: '/search?sort_by=1',
            alias: 'card_click_advantage_assortiment',
        },
    ];

    /** Скрыть стрелку назад */
    isHideLeftArrow: boolean = true;
    /** Скрыть стрелку вперед */
    isHideRightArrow: boolean = false;

    $refs: {
        scrollElement: HTMLElement; //элемент с прокруткой
    };

    /** Скролл к другой карточке */
    fScroll(sType: 'left' | 'right') {
        if (sType === 'left') {
            this.$refs.scrollElement.scrollLeft -= this.WIDTH_CARD_WITH_PADDING;
            if (this.$refs.scrollElement.scrollLeft < this.WIDTH_CARD_WITH_PADDING + 1) {
                this.isHideLeftArrow = true;
            }
            this.isHideRightArrow = false;
        } else {
            if (
                this.$refs.scrollElement.scrollWidth -
                    (this.$refs.scrollElement.scrollLeft + this.$refs.scrollElement.offsetWidth) <
                this.WIDTH_CARD_WITH_PADDING
            ) {
                this.isHideRightArrow = true;
            }
            this.$refs.scrollElement.scrollLeft += this.WIDTH_CARD_WITH_PADDING;
            this.isHideLeftArrow = false;
        }
    }

    /** Следим за скроллом блока, чтобы скрывать/показывать кнопки переключения */
    fWatchScroll(e) {
        if (e.target.scrollLeft === 0) {
            this.isHideLeftArrow = true;
            this.isHideRightArrow = false;
        }

        const scrollElement: HTMLElement = this.$refs.scrollElement;

        if (scrollElement) {
            const currentScrollWidth = scrollElement.scrollWidth - scrollElement.offsetWidth;

            if (e.target.scrollLeft === currentScrollWidth) {
                this.isHideLeftArrow = false;
                this.isHideRightArrow = true;
            }
        }
    }

    mounted() {
        const scrollElement: HTMLElement = this.$refs.scrollElement;
        if (scrollElement) {
            scrollElement.addEventListener('scroll', this.fWatchScroll);
        }
    }

    destroyed() {
        const scrollElement: HTMLElement = this.$refs.scrollElement;
        if (scrollElement) {
            scrollElement.removeEventListener('scroll', this.fWatchScroll);
        }
    }

    /** Мобильное ли устройство */
    get isMobile(): boolean {
        return this.status.is_mobile;
    }

    /** Неавторизованный пользователь */
    get isUnauth(): boolean {
        return !this.ctrl.userSys.user?.isAuth;
    }

    /** Пользователь с нулевым уровнем рейтинга */
    get isNewUser(): boolean {
        return this.ctrl.userSys.user?.info?.consumer_rating === 0;
    }

    get status() {
        return this.ctrl.status;
    }

    /** Хранилище */
    get ctrl(): HomeCtrl {
        return initHomeCtrl(mIsClient() ? this.$context : this.$ssrContext);
    }
}

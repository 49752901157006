/**
 * Возвращает количество товара в базе по артикулу, цвету и размеру
 */

import { AddressI } from './EntitySQL/AddressE';
import { UserGenderT, UserInfoI } from './EntitySQL/UserE';
import { NotifySubscribeI, NotifySubscribeMsgI } from './EntitySQL/NotifySubscribeE';
import { NotifyCategoryI, NotifySubscribleOrderI } from './EntitySQL/NotifyCategoryE';
import { PvzI } from './PvzSQL/PvzE';
import { PvzParametersI } from './PvzSQL/PvzParametersE';
import { PvzAddressI } from './PvzSQL/PvzAddressE';
import { PvzContactI } from './PvzSQL/PvzContactE';
import { CityIxI } from './EntitySQL/CityE';
import { RegionIxI } from './EntitySQL/RegionE';
import { NotificationTypeI } from './EntitySQL/PhpbbNotificationTypesE';
import { PhpbbUserNotificationIDsI } from './EntitySQL/PhpUserNotificationE';
import { NotificationSettingI } from './MsgSQL/NotificationSettingE';
import { OnePurchaseDataI } from './CommonI/PurchaseI';
import { PaginationI } from './CommonI/PaginationI';
import { MarketItemOrderFieldT } from './CommonI/SortingI';
import { MarketItemInfoI, MarketItemStatusT } from './EntitySQL/MarketItemE';
import { CatalogI } from './EntitySQL/CatalogE';
import { P63ItemCharacteristicIDsI } from './EntitySQL/P63ItemCharacteristicE';
import { S3ItemImgIDsI } from './EntitySQL/S3ItemImgE';
import { RefundOrderRequestUzI } from './MsgSQL/RefundOrderRequestE';
import { RefundOrderImgImgIDsI } from './ImgSQL/RefundOrderImgImgE';
import { RefundOrderI } from './EntitySQL/RefundOrderE';
import { OrderI } from './EntitySQL/OrderE';
import { PurchaseIDsI } from './EntitySQL/PurchaseE';
import { PostI } from './MsgSQL/PostE';
import { ItemI, ItemIDsI } from './EntitySQL/ItemE';
import { P63OrderCharacteristicIDsI } from './EntitySQL/P63OrderCharacteristicE';

/**
 * Пользовательские данные и статус пользователя
 */
export interface UserDataI {
    userInfo?: UserInfoI; // Основная информация пользователя
    user_id?: number; // Получить ID пользователя
    userRating?: number; // Получить рейтинг пользователя
    isOrg?: boolean; // Проверка является ли пользователь организатором
    isAdmin?: boolean; // Проверка является ли пользователь администратором
    isOrgAdmin?: boolean; // Проверка является ли пользователь администратором организаторов
    userGroupIdList?: number[]; // Список ID групп в которых состоит пользователь
}

export namespace PersonalR {
    export const Alias = 'Personal';

    // =======================================================
    /** Проверка данных регистрации */
    export namespace checkLoginExist {

        /** APIURL */
        export const route = '/personal/check-login-exist';

        /** Alias действия */
        export const action = 'check-login-exist';

        /** Параметры api запроса */
        export interface RequestI {
            login?: string; // Логин
        }

        /** Параметры api ответа */
        export interface ResponseI {
            is_correct: boolean; // Если корректны все поля для регистрации
            is_login_exist: boolean; // Если логин существует
            is_order_exist: boolean; // Заказы существуют
        }
    }

    /** Получение информации для профиля пользователя */
    export namespace getUserProfile2 {
        /** APIURL */
        export const route = '/personal/get-user-profile2';

        /** Параметры api запроса */
        export interface RequestI {
        }

        /** Параметры api ответа */
        export interface ResponseI {
            user_info: UserInfoI;
            is_change_email_process: boolean; // Находится ли юзер в процессе смены email
            user_new_email: string; // Новый эмейл
            is_confirmed_email: boolean; // подтвержден ли основной email
            pvz: PvzI; // Выбранный дефолтный ПВЗ юзера
            pvz_parameters: PvzParametersI; // Параметры дефолтного ПВЗ
            pvz_address: PvzAddressI; // Адрес дефолтного ПВЗ
            pvz_contact_list: PvzContactI[]; // Список контактов дефолтного ПВЗ
            pvz_worktime: string[]; // Режим работы
            city: CityIxI; // Город нахождения дефолтного ПВЗ
            region: RegionIxI; // Регион нахождения дефолтного ПВЗ
            delivery_addresses: AddressI[]; // Список адресов доставки юзера
            is_subscribed_telegram: boolean; // Флаг "Пользователь подписан на бота Телеграм"
            is_changeable_delivery_exist: boolean; // Флаг: у пользователя есть посылки, с возможностью изменить адрес
        }
    }

    /**
     * Получение информации для раздела настроек пользователя
     *
     * @deprecated Будет удалено после внедрения новых методов для ЛК
     */
    export namespace getUserSettings {

        // /** APIURL */
        export const route = '/personal/get-user-settings';

        /** Alias действия */
        export const action = 'get-user-settings';

        /** Параметры api запроса */
        export interface RequestI {
        }

        /** Параметры api ответа */
        export interface ResponseI {
            messages?: {
                [key: string]: NotifySubscribeMsgI[];
            };
            orders?: {
                [key: string]: NotifySubscribleOrderI[];
            };
        }
    }

    /** Получить настройки уведомлений пользователя */
    export namespace getNotificationSettings {

        /** APIURL */
        export const route = '/personal/get-notification-settings';

        /** Параметры api запроса */
        export interface RequestI {
        }

        /** Параметры api ответа */
        export interface ResponseI {
            forum_settings_info: NotificationTypeI[]; // Сами настройки форумных уведомлений (только актуальные, не все что есть в БД)
            forum_user_settings: PhpbbUserNotificationIDsI[]; // Пользовательские настройки форумных уведомлений (все что есть, в т.ч. неактуальные)
            calendar_settings_info: NotifyCategoryI[]; // Сами настройки календарей (только актуальные, не все что есть в БД)
            calendar_user_settings: NotifySubscribeI[]; // Пользовательские настройки календарей (все что есть, в т.ч. неактуальные)
            notification_settings: NotificationSettingI[]; // Настройки уведомлений
        }
    }

    /** Получить все адреса для почтовой отправки */
    export namespace getAddresses {
        export const route = '/personal/get-addresses';

        /** Alias действия */
        export const action = 'get-addresses';

        export interface RequestI {
        }

        export interface ResponseI {
            delivery_addresses?: AddressI[];
        }

    }

    /** Кнопка добавить адрес почтовой доставки на странице профиля */
    export namespace addProfileAddress {
        // /** APIURL */
        export const route = '/personal/add-address';

        /** Alias действия */
        export const action = 'add-address';

        /** Параметры api запроса */
        export interface RequestI {
            user_id?: number;
            address_value: string;
            address_zip_code: string;
            address_room: string;
            address_latitude?: string;
            address_longitude?: string;
            address_delivery_zone?: number;
            is_change_address_everywhere?: boolean; // Обновить адрес доставки во всех заказах
        }

        /** Параметры api ответа */
        export interface ResponseI {
            address_id?: number;
        }
    }

    /** Кнопка добавить адрес почтовой доставки на странице профиля */
    export namespace delProfileAddress {
        // /** APIURL */
        export const route = '/personal/del-address';

        /** Alias действия */
        export const action = 'del-address';

        /** Параметры api запроса */
        export interface RequestI {
            user_id?: number;
            address_id: number;
        }

        /** Параметры api ответа */
        export interface ResponseI {
            address_id: number;
            deleted: boolean;
        }
    }

    /**
     * Сохранить изменения на странице профиля
     */
    export namespace saveProfile2 {
        /** APIURL */
        export const route = '/personal/save-profile2';

        /** Параметры api запроса */
        export interface RequestI {
            username?: string; // Ник
            user_fullname?: string; // Имя пользователя
            user_sig?: string; // Подпись пользователя
            user_gender?: UserGenderT; // Пол
            user_birthday?: string; // Дата рождения
            address_id?: number; // Адрес почтовой доставки
            user_office_trusted?: string; // Доверенные лица
            pvz_id?: number; // ID ПВЗ
            city_id?: number; // ID города
            is_change_pvz_everywhere?: boolean; // Обновить ПВЗ во всех заказах
            is_change_address_everywhere?: boolean; // Обновить адрес доставки во всех заказах
        }

        /** Параметры api ответа */
        export interface ResponseI {
        }
    }

    /**
     * Изменить аватар
     *
     * После того, как фронт перейдёт со старого метода на этот - переименовать без 2
     */
    export namespace saveAvatar2 {
        /** API URL */
        export const route = '/personal/save-avatar2';

        /** Параметры api запроса */
        export interface RequestI {
            dataBase64: string;
            crop_left?: number;
            crop_top?: number;
            crop_width?: number;
            crop_height?: number;
        }

        /** Параметры api ответа */
        export interface ResponseI {
        }
    }

    /** Сохранить изменения на странице профиля */
    export namespace delAvatar {
        // /** APIURL */
        export const route = '/personal/del-avatar';

        /** Alias действия */
        export const action = 'del-avatar';

        /** Параметры api запроса */
        export interface RequestI {
        }

        /** Параметры api ответа */
        export interface ResponseI {
            status: boolean;
        }
    }

    /**
     * Кнопка сохранить изменения на странице настроек пользователя
     *
     * @deprecated Будет удалено после внедрения новых методов для ЛК
     */
    export namespace saveSettings {
        // /** APIURL */
        export const route = '/personal/save-settings';

        /** Alias действия */
        export const action = 'save-settings';

        /** Параметры api запроса */
        export interface RequestI {
            messages?: {
                [key: string]: NotifySubscribeMsgI[];
            };
            orders?: {
                [key: string]: NotifySubscribleOrderI[];
            };
        }

        /** Параметры api ответа */
        export interface ResponseI {
        }
    }

    /** Сохранить настройки уведомлений пользователя */
    export namespace saveNotificationSettings {
        /** APIURL */
        export const route = '/personal/save-notification-settings';

        /** Параметры api запроса */
        export interface RequestI {
            // Настройки для форумных уведомлений. Если настройка отключена - обновляется флаг notify.
            forum_user_settings: PhpbbUserNotificationIDsI[];
            // Настройки для календарей. Если настройка отключена - она удаляется.
            // Потому сюда нужно слать все настройки, которые должны быть включены.
            // Пустой массив или отсутствие свойства приведёт к удалению (=отключению) настроек.
            calendar_user_settings: NotifySubscribeI[];
            notification_settings: NotificationSettingI[];
        }

        /** Параметры api ответа */
        export interface ResponseI {
        }
    }

    /**
     * Изменить пароль пользователя
     *
     * После правок на фронте убрать из названия "2"
     */
    export namespace changePswd2 {
        /** APIURL */
        export const route = '/personal/change-pswd2';

        /** Параметры api запроса */
        export interface RequestI {
            old_pswd?: string; // Старый пароль
            new_pswd: string; // Новый пароль
            new_pswd_repeat: string; // Повторный ввод нового пароля
        }

        /** Параметры api ответа */
        export interface ResponseI {
            status: boolean;
            token: string; // новый токен
        }
    }

    /** Изменить телефон пользователя */
    export namespace changePhone {
        /** APIURL */
        export const route = '/personal/change-phone';

        /** Alias действия */
        export const action = 'change-phone';

        /** Параметры api запроса */
        export interface RequestI {
            token2?: string; // Google токен капчи
            tel: string; // Телефон
            code: number; // Код из смс
            login?: string; // Логин
            email?: string; // Email
        }

        /** Параметры api ответа */
        export interface ResponseI {
            status: boolean;
        }
    }

    /** Повтороно отправить СМС код */
    export namespace sendTelSmsCode {

        /** APIURL */
        export const route = '/personal/send-sms-code-tel';

        /** Alias действия */
        export const action = 'send-sms-code-tel';

        /** Параметры api запроса */
        export interface RequestI {
            tel: string;
        }

        /** Параметры api ответа */
        export interface ResponseI {
        }
    }

    /** Отправка письма с подтверждение о смене email */
    export namespace changeEmail {

        /** APIURL */
        export const route = '/personal/change-email';

        /** Alias действия */
        export const action = 'change-email';

        /** Параметры api запроса */
        export interface RequestI {
            email: string; // новый емейл
        }

        /** Параметры api ответа */
        export interface ResponseI {
        }
    }

    /** Подтверждение смены email */
    export namespace confirmChangeEmail {

        /** APIURL */
        export const route = '/personal/confirm-change-email';

        /** Alias действия */
        export const action = 'confirm-change-email';

        /** Параметры api запроса */
        export interface RequestI {
            token: string; // новый емейл
        }

        /** Параметры api ответа */
        export interface ResponseI {
        }
    }

    /** Подтверждение смены email */
    export namespace rollOverChangePswd {

        /** APIURL */
        export const route = '/personal/roll-over-change-pswd';

        /** Alias действия */
        export const action = 'roll-over-change-pswd';

        /** Параметры api запроса */
        export interface RequestI {
        }

        /** Параметры api ответа */
        export interface ResponseI {
        }
    }

    /** Получить данные о telegram-подписчике */
    export namespace getTelegramSubscribeStatus {
        /** API URL */
        export const route = '/personal/get-telegram-subscribe-status';

        /** Параметры api запроса */
        export interface RequestI {
        }

        /** Параметры api ответа */
        export interface ResponseI {
            telegram_subscribe_status: boolean; // текущий статус telegram-подписчика АКТИВНО/НЕАКТИВНО
        }
    }

    /** Задать статус telegram-подписчика */
    export namespace changeTelegramSubscribeStatus {
        /** API URL */
        export const route = '/personal/change-telegram-subscribe-status';

        /** Параметры api запроса */
        export interface RequestI {
            telegram_subscribe_status: boolean; // новый статус telegram-подписчика АКТИВНО/НЕАКТИВНО
        }

        /** Параметры api ответа */
        export interface ResponseI {
        }
    }

    /** Сгенерировать ссылку на telegram-бота для юзера */
    export namespace generateTelegramLink {
        /** API URL */
        export const route = '/personal/generate-telegram-link';

        /** Параметры api запроса */
        export interface RequestI {
        }

        /** Параметры api ответа */
        export interface ResponseI {
            telegram_link: string; // ссылка на telegram-бота для пользователя
        }
    }

    /** Получить информацию об избегаемых закупках по списку шаблонов */
    export namespace getDodgelistPurchaseInfo {
        /** API URL */
        export const route = '/personal/dodge-list-info';

        /** Параметры api запроса */
        export interface RequestI {
            page: number;
            limit: number;
        }

        /** Параметры api ответа */
        export interface ResponseI {
            list_purchase_info: OnePurchaseDataI[]; // информация об избегаемых закупках
            pagination: PaginationI // пагинация
        }
    }

    /** получить все товары текущего пользователя из пристроя  */
    export namespace getAllUserMarketItem {
        /** API URL */
        export const route = '/personal/get-all-user-market-item';

        /** Параметры api запроса */
        export interface RequestI {
            limit: number,
            order_by?: MarketItemOrderFieldT,
            page: number,
            status?: MarketItemStatusT
        }

        /** Параметры api ответа */
        export interface ResponseI {
            market_item_list: MarketItemInfoI[]; // товары пристроя
            market_catalog_list: CatalogI[]; // каталоги товара пристроя
            market_item_characteristic_list: P63ItemCharacteristicIDsI[]; // хар-ки товара пристроя
            market_image_list: S3ItemImgIDsI[]; // картинки товара пристроя
            pagination: PaginationI;
        }
    }

    /** Редактировать товар личного пристроя */
    export namespace updateOwnItem {

        /** API URL */
        export const route = '/personal/update-own-item';

        export interface RequestI {
            item_id: number; // ID товара
            item_hidden?: boolean; // Флаг - скрытый товар из личного пристроя или нет
            item_name?: string; // Наименование товара
            item_price?: number; // Цена товара
            item_desc?: string; // Описание товара
        }

        export interface ResponseI {
        }
    }

    /** Обновить заявку на возврат */
    export namespace updateRefundOrderRequest {
        /** API URL */
        export const route = '/personal/update-refund-order-request';

        /** Параметры api запроса */
        export interface RequestI {
            refund_order_request_id: number; // ID заявки
            pvz_id?: number;                 // ID ПВЗ
            description?: string;            // Описание
            list_add_order_id?: number[];    // Список заказов, которые добавить к заявке
            list_del_order_id?: number[];    // Список заказов, которые убрать из заявки
        }

        /** Параметры api ответа */
        export interface ResponseI {
            // нет ответа
        }
    }

    /** Получить список заказов инвойса для добавления их в заявку */
    export namespace getListOrderForRefundOrderRequest {
        /** API URL */
        export const route = '/personal/get-list-order-for-refund-order-request';

        /** Параметры api запроса */
        export interface RequestI {
            refund_order_request_id: number; // ID заявки
        }

        /** Параметры api ответа */
        export interface ResponseI {
            list_order: OrderI[]; // Список заказов
            list_item: ItemI[]; // Товары
            list_image: S3ItemImgIDsI[]; // Картинки товара
            list_order_characteristic: P63OrderCharacteristicIDsI[]; // Список характеристик заказанных товаров
        }
    }

    /** Удалить заявку на возврат */
    export namespace deleteRefundOrderRequest {
        /** API URL */
        export const route = '/personal/delete-refund-order-request';

        /** Параметры api запроса */
        export interface RequestI {
            refund_order_request_id: number; // ID заявки
        }

        /** Параметры api ответа */
        export interface ResponseI {
            // нет ответа
        }
    }

    /** Получить список заявок на возврат */
    export namespace listRefundOrderRequest {
        /** API URL */
        export const route = '/personal/list-refund-order-request';

        /** Параметры api запроса */
        export interface RequestI {
            page?: number;  // default 1
            limit?: number; // default 30
        }

        /** Параметры api ответа */
        export interface ResponseI {
            list_refund_order_request: RefundOrderRequestUzI[];                   // Список заявок
            list_refund_order_image: RefundOrderImgImgIDsI[];                    // Вложения заявок
            list_refund_order: RefundOrderI[];                                    // Заказы заявок
            list_count_order_in_invoice: { invoice_id: number, count: number }[]; // Количество заказов в инвойсе
            list_order: OrderI[];                                                 // Информация о заказах
            list_purchase: PurchaseIDsI[];                                        // Информация по закупкам
            list_post: PostI[];                                                   // Список постов
            list_item: ItemIDsI[];                                                // Товары
            list_order_characteristic: P63OrderCharacteristicIDsI[];              // Список характеристик заказанных товаров
            pagination: PaginationI;
        }
    }

    /** Получить QR для передачи возварата в ПВЗ */
    export namespace getQrCodeRefundRequest {
        /** API URL */
        export const route = '/personal/get-qr-code-refund-request';

        /** Параметры api запроса */
        export interface RequestI {
            refund_order_request_id: number; // Номер заявки на возврат
        }

        /** Параметры api ответа */
        export interface ResponseI {
            // buffer
        }
    }

    // ====================================================
    // Подписка на Web Push Notification
    // ====================================================
    /** Проверить подписку текущего устройства на Web Push уведомления */
    export namespace checkUserDeviceSub {
        /** APIURL */
        export const route = '/personal/check-user-device-sub';

        /** Параметры api запроса */
        export interface RequestI {
            endpoint: string; // Ссылка на устройство пользователя
        }

        /** Параметры api ответа */
        export interface ResponseI {
            is_sub: boolean; // Статус подписки текущего устройства
        }
    }
    /** Подписать устройство пользователя на Web Push уведомления */
    export namespace userDeviceSubscribe {
        /** APIURL */
        export const route = '/personal/user-device-subscribe';

        /** Параметры api запроса */
        export interface RequestI {
            endpoint: string; // Ссылка на устройство пользователя
            p256dh: string; // Публичный ключ устройства
            auth: string; // Ключ аутентификации пользователя
        }

        /** Параметры api ответа */
        export interface ResponseI {
            id_user_device_subs: number; // ID подписки
        }
    }
    /** Отписать все устройства пользователя от Web Push уведомлений */
    export namespace userDeviceUnsubscribeCurrent {
        /** APIURL */
        export const route = '/personal/user-device-unsubscribe-current';

        /** Параметры api запроса */
        export interface RequestI {
            endpoint: string; // ССылка на устройство пользователя
        }

        /** Параметры api ответа */
        export interface ResponseI {}
    }
    /** Отписать все устройства пользователя от Web Push уведомлений */
    export namespace userDeviceUnsubscribeAll {
        /** APIURL */
        export const route = '/personal/user-device-unsubscribe-all';

        /** Параметры api запроса */
        export interface RequestI {}

        /** Параметры api ответа */
        export interface ResponseI {}
    }
}

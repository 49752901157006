import Component from 'vue-class-component';
import Tooltip from '@/ds/el/tooltip/tooltip';
import SearchInput from '@/common/el/SearchInput/SearchInput';
import MenuManagementSections from '@/common/el/MenuManagementSections/MenuManagementSections';
import { PageCtrl } from '@/system/PageCtrl';
import { gfInitContextCtrl } from '@/system/ContextSys';
import P63Vue from '@/system/P63Vue';
import { gAppealPopupS } from '@/lib_common/service/appeal_popup/AppealPopupS';
import { mIsClient } from '@/common/helpers/ContextH';
import fRegisterCommonAsyncComponents from './InitHeader';
import WarningMessage from '../WarningMessage/WarningMessage';
import { gNoAuthCartS } from '@/common/service/NoAuthCartS';
import { clickOutside } from '@/ds/directives/clickOutside';
import WebPushPopup from '@/common/el/WebPushPopup/WebPushPopup.vue';
import MultiLevelMenuDesktop from '@/common/el/MultiLevelMenuDesktop/MultiLevelMenuDesktop';
import MultiLevelMenuMobile from '@/common/el/MultiLevelMenuMobile/MultiLevelMenuMobile';
import UserMenu from '@/common/el/UserMenu/UserMenu';
import { mGetDeviceType } from '@/common/helpers/DeviceTypeH';
import ModalQrUser from '@/common/el/ModalQrUser/ModalQrUser';
import gErrorSys from '@/lib_common/system/ErrorSys';
import { BonusExpireInfoI } from '@/ifc/core/BonusActionUserE';
import dayjs from 'dayjs';
import { mFormatDateTime } from '@/common/helpers/DateTimeH';
import UserCityD from '../UserCity/UserCityD.vue';
import UserCityM from '../UserCity/UserCityM.vue';
import NewMenuManagement from '@/common/el/NewMenuManagement/NewMenuManagement';
import { fOnloadScript } from '@/common/service/OnloadScript';

// Зарегистрировать асинхронные common-компоненты
fRegisterCommonAsyncComponents();

@Component({
    directives: {
        'click-outside': clickOutside,
    },

    components: {
        ModalQrUser,
        SearchInput,
        MenuManagementSections,
        WarningMessage,
        Tooltip,
        WebPushPopup,
        MultiLevelMenuDesktop,
        MultiLevelMenuMobile,
        UserMenu,
        UserCityD,
        UserCityM,
        NewMenuManagement,
    },
})
export default class Header extends P63Vue {
    // Определение локальных переменных компонента
    //   Переменные для фиксирования Header-а
    /** Координата по Y */
    iScrollY: number = 0;
    /** Есть ли скролл вниз */
    bScrollDown: boolean = false;

    /** Показывать ли тултип корзины */
    isShowCartTooltip = false;

    /** Статус рендера на клиенте */
    isClient: boolean = false;

    /** Разрешение экрана */
    isDesktop: boolean = false;
    isMobile: boolean = false;

    /** Интервал проверки модалки розыгрыша айфона */
    nIntevalIphone: NodeJS.Timer = null;

    // Методы
    /** Метод для работы с прилипанием header-а */
    fToggleHeaderSticky() {
        const iCurrentYPosition: number = window.scrollY;

        if (iCurrentYPosition <= 60) {
            this.bScrollDown = false;
        } else {
            this.bScrollDown = this.iScrollY < iCurrentYPosition;
        }

        this.iScrollY = iCurrentYPosition;
    }

    /** Переключатель для multi-level-menu (desktop)
     * @param {boolean} bCloseMod - режим работы (режим закрытия true / false)
     */
    fToggleMultiLevelMenuDesktop(bCloseMod: boolean) {
        if (bCloseMod) {
            this.ctrl.headerSys.header.is_open_multi_level_menu_desktop = false;
        } else {
            this.ctrl.headerSys.header.is_open_multi_level_menu_desktop = !this.bOpenMultiLevelMenuDesktop;
            this.ctrl.headerSys.header.is_open_favorite_menu = false;
            this.ctrl.headerSys.header.is_open_user_menu = false;
            if (this.ctrl.headerSys.header.is_open_multi_level_menu_desktop) {
            /** Отправить данные в статистику об открытии меню категорий */
                this.ctrl.statSys.faPushClickToStat({
                    page: location.pathname,
                    type: 'menu',
                    alias: 'open_menu_categories',
                    entity_id: 0,
                  });
            }
        }
    }

    /** Переключатель для multi-level-menu (mobile)
     * @param {boolean=} bCloseMod - режим работы (режим закрытия true / false)
     */
    fChangeVisibleMultiLevelMenuMobile(bCloseMod?: boolean) {
        this.fCloseUserMenu();
        if (typeof bCloseMod === 'boolean') {
            this.ctrl.headerSys.header.is_open_multi_level_menu_mobile = bCloseMod;
        } else {
            this.ctrl.headerSys.header.is_open_multi_level_menu_mobile = !this.bOpenMultiLevelMenuMobile;
        }

        this.bOpenMultiLevelMenuMobile
            ? document.body.classList.add('stop-scrolling')
            : document.body.classList.remove('stop-scrolling');

        if (this.bOpenMultiLevelMenuMobile) {
        /** Отправить данные в статистику об открытии меню категорий */
            this.ctrl.statSys.faPushClickToStat({
                page: location.pathname,
                type: 'menu',
                alias: 'open_menu_categories',
                entity_id: 0,
                });
        }
    }

    /** Закрыть user_menu */
    fCloseUserMenu() {
        this.ctrl.headerSys.header.is_open_user_menu = false;
        document.body.classList.remove('stop-scrolling');
    }

    /** Переключатель для user_menu - desktop */
    fToggleUserMenuDesktop() {
        this.ctrl.headerSys.header.is_open_user_menu = !this.bOpenUserMenu;
        this.ctrl.headerSys.header.is_open_multi_level_menu_desktop = false;
        this.ctrl.headerSys.header.is_open_favorite_menu = false;
    }

    /** Переключатель для user_menu - mobile */
    fToggleUserMenuMobile() {
        if (!this.bOpenUserMenu) {
            this.fChangeVisibleMultiLevelMenuMobile(false);
        }

        this.ctrl.headerSys.header.is_scroll_on = false;
        this.ctrl.headerSys.header.is_open_user_menu = !this.bOpenUserMenu;
        this.bOpenUserMenu
            ? document.body.classList.add('stop-scrolling')
            : document.body.classList.remove('stop-scrolling');
    }

    /** Показывать ли административное меню */
    get isShowManagerMenu() {
        return (
            this.vUserInfo.isAuth &&
            (this.vUserInfo.isAdmin ||
                this.vUserInfo.isModerator ||
                this.vUserInfo.isBuh ||
                this.vUserInfo.isOrg ||
                this.vUserInfo.isMarketing)
        );
    }

    /** Является ли страница главной */
    get isMainPage() {
        return this.$route.path === '/'
    }

    /** Прошло ли больше чем 1 день */
    isMoreThanADay(nDate: number) {
        const oneDay = 24 * 60 * 60 * 1000;
        return Date.now() - nDate > oneDay;
    }

    /** Переключатель для favorite - menu
     * @param {boolean} bCloseMod - режим работы (режим закрытия true / false)
     */
    fToggleFavoriteMenu(bCloseMod: boolean) {
        if (bCloseMod) {
            this.ctrl.headerSys.header.is_open_favorite_menu = false;
        } else {
            this.ctrl.headerSys.header.is_open_favorite_menu = !this.bOpenFavoriteMenu;
        }
    }

    /** Дополнительная функция поиска, сейчас нужна только для страницы поиска на десктопе (для поиска внутри каталога)*/
    fSearch(value: string) {
        this.$emit('fSearch', value);
    }

    /** Закрыт тултип */
    fCloseCartTooltip() {
        this.isShowCartTooltip = false
        gNoAuthCartS.fCloseCartTooltip()
    }

    // Хуки
    async mounted() {
        this.isClient = true;
        window.addEventListener('scroll', this.fToggleHeaderSticky);

        if (this.ctrl.userSys.user.isAuth) {
            this.ctrl.headerSys.fGetPvzInfo();
            this.ctrl.headerSys.fGetUserBalance();
            this.ctrl.headerSys.fGetSavedLinksForOrgs();
            this.ctrl.headerSys.fGetOrgNotificationCounters();
            this.ctrl.headerSys.fGetOverpaymentCounter();
            if (this.ctrl.userSys.user.isOrg) {
                this.ctrl.headerSys.fGetNewOrderRequestCounter();
            }
            if (localStorage.getItem('bonus_expired_not_show')) {
                let aBonusExpireNotShow: BonusExpireInfoI[] = [];
                try {
                    aBonusExpireNotShow = JSON.parse(localStorage.getItem('bonus_expired_not_show'));
                } catch (err) {
                    gErrorSys.error(err);
                }

                aBonusExpireNotShow = aBonusExpireNotShow.filter(
                    (objBonus: BonusExpireInfoI) =>
                        dayjs().isSame(dayjs(objBonus.date_bonus_expired), 'day') ||
                        dayjs().add(1, 'day').isSame(dayjs(objBonus.date_bonus_expired), 'day')
                );

                this.ctrl.headerSys.header.bonus_expired_not_show = aBonusExpireNotShow;
                if (aBonusExpireNotShow.length) {
                    const objLastBonusExpire: BonusExpireInfoI = aBonusExpireNotShow[0];
                    this.ctrl.headerSys.header.is_expire_bonus = true;
                    this.ctrl.headerSys.header.date_bonus_expire =
                        objLastBonusExpire?.date_bonus_expired || mFormatDateTime();
                    this.ctrl.headerSys.header.number_of_bonuses = objLastBonusExpire?.sum_bonus_expired || 0;
                    this.ctrl.headerSys.header.is_show_notification_bonus = true;
                }
            }
        } else {
            this.isShowCartTooltip = gNoAuthCartS.fIsShowCartTooltip();
            gNoAuthCartS.fSubsribeNoAuthCart((vNoauthCart) => {
                this.ctrl.userSys.user.countReadyToPayOrders = vNoauthCart.aPurchase.length;
            });
        }
        window.innerWidth > 1023 ? (this.isDesktop = true) : (this.isMobile = true);
    }

    beforeDestroy() {
        window.removeEventListener('scroll', this.fToggleHeaderSticky);
    }

    // Геттеры
    /** Получить статусы окна отправки заявки */
    get vAppealPopupStatus() {
        return gAppealPopupS.status;
    }

    /** Получить сохраненные ссылки */
    get aOrgLink() {
        return this.ctrl.headerSys.header.saved_link;
    }

    //   Селекторы
    /** Открыто ли многоуровневое меню (desktop) */
    get bOpenMultiLevelMenuDesktop() {
        return this.ctrl.headerSys.header.is_open_multi_level_menu_desktop;
    }

    /** Открыто ли многоуровневое меню (mobile) */
    get bOpenMultiLevelMenuMobile() {
        return this.ctrl.headerSys.header.is_open_multi_level_menu_mobile;
    }

    /** Открыто ли меню пользователя */
    get bOpenUserMenu() {
        return this.ctrl.headerSys.header.is_open_user_menu;
    }

    /** Открыто ли окно QR пользователя */
    get isOpenUserQr() {
        return this.ctrl.headerSys.header.is_open_user_qr;
    }

    /** Открыто ли окно отправки заявки */
    get bOpenAppealPopup(): boolean {
        return this.vAppealPopupStatus.is_open_appeal_popup;
    }

    /** Открыто ли меню избранное */
    get bOpenFavoriteMenu() {
        return this.ctrl.headerSys.header.is_open_favorite_menu;
    }

    //     Переменные для работы с данными пользователя
    /** Информация о пользователе */
    get vUserInfo() {
        return this.ctrl.userSys.user;
    }

    /** Информация о пользователе */
    get nUserNotification() {
        const nMessages = this.vUserInfo.countUnreadPrivmsg > 0 ? this.vUserInfo.countUnreadPrivmsg : null;
        const nSystemNotifications =
            this.vUserInfo.countUnreadSystemNotifications > 0 ? this.vUserInfo.countUnreadSystemNotifications : null;
        return nMessages + nSystemNotifications;
    }

    /**Ссылка на корзину */
    get sCartLink() {
        return '/cart';
    }

    /**Получение id пользователя */
    get idUser() {
        return this.vUserInfo.isAuth ? this.vUserInfo.info.user_id : 0;
    }

    get nCountReadyToPayOrders() {
        return this.ctrl.userSys.user.countReadyToPayOrders;
    }
    /** Кол-во уведомлений орга */
    get nOrgNotificationCounter() {
        return this.ctrl.headerSys.header.org_notification_counter;
    }

    /** Кол-во новых заявок на возврат */
    get nNewRefundRequestCounter() {
        return this.ctrl.headerSys.header.new_refund_counter;
    }

    /** Количество записей с переплатами */
    get nOverpaymentCounter() {
        return this.ctrl.headerSys.header.count_overpayment;
    }

    /** Плказывать ли модалку неоплаченный заказ */
    get bShowUnpaidOrderInCartPopup() {
        const sDateClosed = this.ctrl.headerSys.header.cart_local_storage.unpaid_order_popup_date_closed;
        const bShowUnpaidOrderInCartPopup =
            !this.ctrl.headerSys.header.is_closed_unpaid_order_in_cart_popup &&
            !!this.nCountReadyToPayOrders &&
            (sDateClosed ? this.isMoreThanADay(sDateClosed) : true);
        return bShowUnpaidOrderInCartPopup;
    }

    /** Тип устройства */
    get vDeviceType() {
        return mGetDeviceType(this.ctrl.deviceType);
    }

    // Основные
    get ctrl(): PageCtrl {
        return gfInitContextCtrl(mIsClient() ? this.$context : this.$ssrContext, PageCtrl);
    }
}

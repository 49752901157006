import P63Vue from '@/system/P63Vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { gAppNavigatorS } from '@/common/service/AppNavigatorS';
import SearchInputApp from '@/common/el/SearchInputApp/SearchInputApp';
import NewMenuManagement from '@/common/el/NewMenuManagement/NewMenuManagement';
import { mIsClient } from '@/common/helpers/ContextH';
import { gfInitContextCtrl } from '@/system/ContextSys';
import { PageCtrl } from '@/system/PageCtrl';
import { SavedMenuLinkI } from '@/ifc/core/CommonSQL/SavedMenuLinkE';

@Component({
    components: {
        'SearchInputApp': SearchInputApp,
        'NewMenuManagement': NewMenuManagement,
    }
})
export default class app_header extends P63Vue {
    /** Название раздела */
    @Prop({ type: String, default: '' }) sSectionName: string;
    /** Показывать ли стрелку назад */
    @Prop({ type: Boolean, default: false }) isShowBackWay: boolean;
    /** Показывать ли кнопку поделиться */
    @Prop({ type: Boolean, default: false }) isShowShareButton: boolean;
    /** Функция для отправки данных в статистику при нажатии на кнопку "Поделиться" */
    @Prop({ type: Function }) onSendDataToStat: () => void;
    /** Показывать ли Поиск */
    @Prop({ type: Boolean, default: false }) isShowSearchInput: boolean;
    /** Показывать ли административное меню */
    @Prop({ type: Boolean, default: false }) isShowNewMenuManagement: boolean;
    /** Список избранных ссылок  */
    @Prop({ type: Array, default: () => [] }) aOrgLink: SavedMenuLinkI[];        
    /** Параметр квери строки для поиска*/
    @Prop({ type: String, default: '' }) sQuery: string;

    fBack(e) {
        gAppNavigatorS.fBack(e);
    }

    fShare(e) {
        if (this.onSendDataToStat) {
            this.onSendDataToStat();
        }
        navigator.share({
            url: window.location.href
        });
    }

    /** Кол-во уведомлений орга */
    get nOrgNotificationCounter() {
        return this.ctrl.headerSys.header.org_notification_counter;
    }

    /** Кол-во новых заявок на возврат */
    get nNewRefundRequestCounter() {
        return this.ctrl.headerSys.header.new_refund_counter;
    }

    /** Количество записей с переплатами */
    get nOverpaymentCounter() {
        return this.ctrl.headerSys.header.count_overpayment;
    }

    get ctrl(): PageCtrl {
        return gfInitContextCtrl(mIsClient() ? this.$context : this.$ssrContext, PageCtrl);
    }
}

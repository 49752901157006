<script lang="ts">
import Component from 'vue-class-component';
import InfoblockSource from './infoblock-source';

@Component
export default class Infoblock extends InfoblockSource {}
</script>

<template>
    <div class="infoblock">
        <div
            ref="scrollElement"
            :class="[
                'infoblock__wrapper',
                {
                    infoblock__wrapper_blur_start: !isHideLeftArrow && !isMobile && (isUnauth || isNewUser),
                    infoblock__wrapper_blur_end: !isHideRightArrow && !isMobile && (isUnauth || isNewUser),
                },
            ]"
        >
            <ul class="infoblock__advantage">
                <template v-if="isUnauth || isNewUser">
                    <CardAdvantage
                        v-for="(objAdv, idx) in aAdvantage"
                        :key="idx"
                        :sTitle="objAdv.title"
                        :sText="objAdv.text"
                        :sImage="objAdv.image"
                        :sLink="objAdv.link"
                        :sStatAlias="objAdv.alias"
                /></template>
                <li v-if="isMobile" class="infoblock__counts" :class="{ 'full-width': !isUnauth && !isNewUser }">
                    <ListCounts />
                </li>
            </ul>

            <ArrowButton
                v-if="!isMobile && !isHideLeftArrow && (isUnauth || isNewUser)"
                sArrowType="left"
                :fChangeElement="fScroll"
                class="infoblock__arrow infoblock__arrow_left"
            />
            <ArrowButton
                v-if="!isMobile && !isHideRightArrow && (isUnauth || isNewUser)"
                sArrowType="right"
                :fChangeElement="fScroll"
                class="infoblock__arrow infoblock__arrow_right"
            />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.infoblock {
    position: relative;
    margin: 24px 0 12px;
    width: 100%;
    max-width: 1344px;

    @media (max-width: 767px) {
        margin: 12px 0 0;
    }

    &__wrapper {
        display: flex;
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
        scroll-padding-left: calc((100% - 1440px + 96px) / 2);
        -webkit-overflow-scrolling: touch;
        scroll-behavior: smooth;
        scroll-snap-stop: always;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
        @media (max-width: 1440px) {
            scroll-padding-left: 48px;
        }

        @media (max-width: 1279px) {
            scroll-margin-left: 12px;
            scroll-padding-left: unset;
        }
        @media (max-width: 767px) {
            scroll-padding-left: 12px;
        }
        &_blur_start {
            &::before {
                content: '';
                height: 142px;
                width: 70px;
                position: absolute;
                top: -1px;
                z-index: 5;
                background: linear-gradient(90deg, #f5f6fb 0%, rgba(255, 255, 255, 0) 100%);
                left: 48px;
                @media (min-width: 1440px) {
                    left: calc((100% - 1440px + 96px) / 2);
                }
                @media (max-width: 1279px) {
                    left: -1px;
                }
            }
        }
        &_blur_end {
            &::after {
                content: '';
                height: 142px;
                width: 70px;
                position: absolute;
                top: -1px;
                z-index: 5;
                background: linear-gradient(270deg, #f5f6fb 0%, rgba(255, 255, 255, 0) 100%);
                right: 48px;
                @media (min-width: 1440px) {
                    right: calc((100% - 1440px + 96px) / 2);
                }
                @media (max-width: 1279px) {
                    right: -2px;
                }
            }
        }
    }
    &__advantage {
        margin: 0;
        display: flex;
        gap: 12px;
        padding: 0;
        list-style: none;
        position: relative;
        scroll-snap-align: start;
        @media (max-width: 767px) {
            grid-template-rows: 150px;
            grid-auto-columns: 235px;
        }
        &:has(.infoblock__counts:only-child) {
            grid-auto-columns: unset;
            flex-basis: 100%;
        }
    }
    &__counts {
        min-height: 48px;
        margin: 0;
        padding: 0;
        list-style: none;
        order: -1;

        &.full-width {
            width: -webkit-fill-available;
        }
    }
    &__arrow {
        top: 70px;
        transition: background-color 0.3s ease-out;
        &:hover {
            background-color: #ececec;
        }
        &_left {
            left: -20px;
        }
        &_right {
            right: -20px;
        }
    }
    &__action {
        grid-row: 1;
        position: relative;
        width: 318px;
        height: 140px;
        background-color: #36a6f2;
        border-radius: 8px;
        overflow: hidden;

        scroll-snap-align: start;
        @media (min-width: 768px) {
            &:hover .action__blur {
                filter: none;
            }
            &:hover .action__button {
                background-color: #f0f0f0;
            }
            &:hover .action__img {
                &:first-of-type {
                    transform: scale(1.11) translate(22px, -12px);
                }
                &:nth-of-type(2) {
                    transform: scale(1.05) translate(2px, -2px);
                }
                &:last-of-type {
                    transform: scale(0.9) translate(7px, -20px);
                }
            }
        }
        @media (max-width: 767px) {
            width: 235px;
            height: 150px;
        }
        .action {
            &__link {
                text-decoration: none;
            }
            &__info {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                font-family: 'Open Sans';

                height: 100%;
                position: relative;
                z-index: 5;
            }
            &__title {
                color: #fff;
                font-size: 16px;
                line-height: 120%;
                width: 160px;
                margin: 0;
                font-weight: 600;
                margin: 16px 0 0 16px;
                @media (max-width: 767px) {
                    width: 140px;
                    font-size: 14px;
                }
            }
            &__button {
                font-weight: 600;
                display: flex;
                width: 180px;
                height: 41px;
                justify-content: center;
                align-items: center;
                color: #2b2a29;
                font-size: 14px;
                line-height: 150%;
                border-radius: 22px;
                background-color: #fff;
                transition: background-color 0.5s ease-out;
                margin: 0 0 12px 12px;
                @media (max-width: 767px) {
                    width: auto;
                    margin: 0 12px 12px;
                }
            }
            &__blur {
                list-style: none;
                margin: 0;
                padding: 0;
                position: absolute;
                top: 0;
                right: 0;
                width: 100%;
                height: 150px;
                transition: background-color 0.5s ease-out, filter 0.5s ease-out;
                background-color: #36a5f2;
                filter: blur(2px) opacity(0.6);
                @media (max-width: 767px) {
                    background-color: unset;
                    filter: none;
                }
            }
            &__img {
                position: absolute;
                padding: 0;
                background-size: contain;
                transition: transform 0.5s ease-out;
                background-repeat: no-repeat;
                &:first-of-type {
                    background-image: url('@/assets/images/home/icon-action-first.webp');
                    width: 67px;
                    height: 67px;
                    top: 80px;
                    right: 28px;
                    z-index: 3;
                    @media (max-width: 767px) {
                        width: 59px;
                        height: 59px;
                        top: 35px;
                        right: -12px;
                    }
                }
                &:nth-of-type(2) {
                    background-image: url('@/assets/images/home/icon-action-second.webp');
                    width: 98px;
                    height: 98px;
                    top: 0px;
                    right: 0px;
                    z-index: 1;
                    @media (max-width: 767px) {
                        width: 89px;
                        height: 88px;
                        top: -7px;
                        right: -18px;
                    }
                }
                &:last-of-type {
                    background-image: url('@/assets/images/home/icon-action-third.webp');
                    width: 86px;
                    height: 86px;
                    top: 37px;
                    right: 69px;
                    z-index: 2;
                    @media (max-width: 767px) {
                        width: 62px;
                        height: 62px;
                        top: 34px;
                        right: 44px;
                    }
                }
            }
        }
    }
}
</style>

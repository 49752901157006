var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"infoblock"},[_c('div',{ref:"scrollElement",class:[
            'infoblock__wrapper',
            {
                infoblock__wrapper_blur_start: !_vm.isHideLeftArrow && !_vm.isMobile && (_vm.isUnauth || _vm.isNewUser),
                infoblock__wrapper_blur_end: !_vm.isHideRightArrow && !_vm.isMobile && (_vm.isUnauth || _vm.isNewUser),
            },
        ]},[_c('ul',{staticClass:"infoblock__advantage"},[(_vm.isUnauth || _vm.isNewUser)?_vm._l((_vm.aAdvantage),function(objAdv,idx){return _c('CardAdvantage',{key:idx,attrs:{"sTitle":objAdv.title,"sText":objAdv.text,"sImage":objAdv.image,"sLink":objAdv.link,"sStatAlias":objAdv.alias}})}):_vm._e(),(_vm.isMobile)?_c('li',{staticClass:"infoblock__counts",class:{ 'full-width': !_vm.isUnauth && !_vm.isNewUser }},[_c('ListCounts')],1):_vm._e()],2),(!_vm.isMobile && !_vm.isHideLeftArrow && (_vm.isUnauth || _vm.isNewUser))?_c('ArrowButton',{staticClass:"infoblock__arrow infoblock__arrow_left",attrs:{"sArrowType":"left","fChangeElement":_vm.fScroll}}):_vm._e(),(!_vm.isMobile && !_vm.isHideRightArrow && (_vm.isUnauth || _vm.isNewUser))?_c('ArrowButton',{staticClass:"infoblock__arrow infoblock__arrow_right",attrs:{"sArrowType":"right","fChangeElement":_vm.fScroll}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }

import { P63OnboardingPageIxI, P63OnboardingPageI } from './EntitySQL/P63OnboardingPageE';
import { P63OnboardingStepI } from './EntitySQL/P63OnboardingStepE';

/**
 * Онбоардинг модуль пользователя
 */
export namespace OnboardingNoAuthR {
    export const Alias = 'OnboardingNoAuth';

    // =======================================================
    /** Получить список шагов цепочки */
    export namespace listStep {

        /** APIURL */
        export const route = '/onboarding-no-auth/list-step';

        /** Alias действия */
        export const action = 'list-step';

        /** Параметры api запроса */
        export interface RequestI {
            obkey: string; // onboarding key
            alias: string; // Псевдоним страницы
        }

        /** Параметры api ответа */
        export interface ResponseI {
            is_active: boolean; // Показывать или нет
            obkey: string; // Фронтовый или новый токен если фронтовый не актуальный
            list_m_step: P63OnboardingStepI[]; // Шаги для мобильной версии
            list_d_step: P63OnboardingStepI[]; // Шаги для десктопной версии
        }
    }

    // =======================================================
    /** Инкрементировать действие пользователя */
    export namespace incrAction {

        /** APIURL */
        export const route = '/onboarding-no-auth/incr-action';

        /** Alias действия */
        export const action = 'incr-action';

        /** Параметры api запроса */
        export interface RequestI {
            obkey: string; // onboarding key
            alias: string; // Псевдоним страницы
            next?: boolean; // 0/1 - Продолжить
            skip?: boolean; // 0/1 - Пропустить
            view?: boolean; // 0/1 - Посмотреть
            end?: boolean; // 0/1 - Закрыть
        }

        /** Параметры api ответа */
        export interface ResponseI {
        }
    }

}

import { BaseCtrl } from '@63pokupki/front';
import gVuexSys from '@/lib_common/system/VuexSys';

/** Хранилище системы */
namespace JivoSysStoreI {
    export class One {
        /** Элемент Jivo-виджета */
        widgetElement: HTMLElement = null;
    }
    export class Status {
        /** Статус загрузки виджета */
        is_widget_loaded: boolean = false;
        /** Статус отображения кнопки-заглушки */
        is_stub_button_shown: boolean = true;
    }
}

/** Сервис для работы c Jivo-виджетом */
class JivoSys extends BaseCtrl {
    // Регистрация модулей хранилища системы
    public one: JivoSysStoreI.One = gVuexSys.registerModuleCustom(new JivoSysStoreI.One());
    public status: JivoSysStoreI.Status = gVuexSys.registerModuleCustom(new JivoSysStoreI.Status());

    /** Добавить в разметку скрипт с загрузкой виджета */
    public fAddLoadingScript() {
        const idWidget = 'TTSmPpatz4';

        const vScriptTag = document.createElement('script');
        vScriptTag.type = 'text/javascript';
        vScriptTag.src = '//code.jivosite.com/script/widget/' + idWidget;
        vScriptTag.async = true;

        const vFirstScriptTag = document.getElementsByTagName('script')[0];
        vFirstScriptTag.parentNode.insertBefore(vScriptTag, vFirstScriptTag);
    }

    /** Обновить данные виджета и открыть его, как только виджет загрузится */
    public fUpdateAndOpenWidgetWhenItLoad() {
        globalThis.jivo_onLoadCallback = () => {
            this.fUpdateWidgetData();

            globalThis.jivo_api.open();
        };
    }

    /** Обновить данные виджета */
    private fUpdateWidgetData() {
        this.status.is_stub_button_shown = false;
        this.status.is_widget_loaded = true;
        this.one.widgetElement = document.querySelector('jdiv');
    }

    /** Визуально скрыть значок виджета или заглушку */
    public fHideWidget() {
        if (!this.status.is_widget_loaded) {
            this.status.is_stub_button_shown = false;
        }

        if (this.one.widgetElement) {
            this.one.widgetElement.style.display = 'none';
        }
    }

    /** Визуально показать значок виджета или заглушку */
    public fShowWidget() {
        if (!this.status.is_widget_loaded) {
            this.status.is_stub_button_shown = true;
        }

        if (this.one.widgetElement) {
            this.one.widgetElement.style.display = 'block';
        }
    }
}

export const gJivoSys = new JivoSys(gVuexSys);

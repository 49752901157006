import { Component, Prop } from 'vue-property-decorator';
import P63Vue from '@/system/P63Vue';

@Component
export default class ArrowButtonSource extends P63Vue {
    /** Направление стрелки */
    @Prop({ type: String, default: 'left' }) sArrowType: 'left' | 'right';
    /** Функция для обработки нажатия */
    @Prop({ type: Function, required: true }) fChangeElement: (sArrowType?: string) => void;

    /** Определение направления */
    get bLeftArrow(): boolean {
        return this.sArrowType === 'left';
    }
}

<script lang="ts">
import Component from 'vue-class-component';
import UserCitySource from './UserCitySource';
import SelectCityModalM from '../SelectCityModal/SelectCityModalM.vue';

@Component({
    components: {
        SelectCityModalM,
    },
})
export default class UserCityM extends UserCitySource {}
</script>

<template>
    <div v-if="vCity" class="user-city">
        <div class="city-button" @click="fCityClick()">
            <Icon sIconName="marker" sWidth="20" sHeight="20" />
            Ваш город:
            <span>
                {{ vCity.city_name }}
                <Icon sIconName="pointer-right" sWidth="20" sHeight="20" />
            </span>
        </div>

        <CityTooltip v-if="isShowTooltip" :sCity="vCity.city_name" :idCity="vCity.city_id" @close="fCloseTooltip" />

        <SelectCityModalM v-if="isShowSelectCity" :sCity="vCity.city_name" @closeTooltip="fCloseTooltip" />
    </div>
</template>

<style lang="scss" scoped>
.user-city {
    position: relative;
    z-index: 3;

    .city-button {
        position: relative;
        display: flex;
        align-items: center;
        gap: 4px;
        line-height: 150%; /* 21px */

        span {
            display: flex;
            align-items: center;
            font-weight: 600;
            color: #36a6f2;
        }
    }
}
</style>

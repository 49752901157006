<script lang="ts">
import Component from 'vue-class-component';
import ModalReviewImageSource from '../modal-review-image/modal-review-image-source';

@Component
export default class ModalReviewImage extends ModalReviewImageSource {}
</script>

<template>
    <div>
        <div class="modal is-visible">
            <div class="modal__wrapper">
                <div class="modal__header">
                    <div @click="fCloseModal" class="modal__btn-close">
                        <i class="ds-icon icon-close"></i>
                    </div>
                </div>
                <div class="modal__content">
                    <div class="image-wrapper">
                        <template>
                            <div class="switch__wrapper">
                                <ImageSwitch
                                    class="switch"
                                    v-model="idCurrentImg"
                                    :aImages="aImages"
                                    :fGetImgSrc="fGetImageUrlByFileName"
                                    :onClickImg="fCloseModal"
                                    :bDisplayButtons="!(aImages.length < 2)"
                                    :vImgStyle="{'max-height': '60vh'}"
                                />
                            </div>
                        </template>
                    </div>
                    <template>
                        <div class="slider slider-wrapper">
                            <ImageSwitchPagination
                                class="switch__pagination"
                                v-model="idCurrentImg"
                                :aImages="aImages"
                                :fGetImgSrc="fGetImageUrlByFileName"
                                bShowFade
                            />
                        </div>
                    </template>
                </div>
            </div>
            <div @click="fCloseModal" class="modal__bg" style="position: absolute"></div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import '../../view/mixins.scss';

.modal {
    &__bg {
        opacity: 0.5;
        background-color: rgba(0, 0, 0, 0.5);
        visibility: visible;
        z-index: 300;
    }

    &__wrap {
        border-radius: 16px;
    }

    &__wrapper {
        padding: 10px;
        position: fixed;
        overflow: hidden;
        max-width: 90vw;
        max-height: 90vh;
        width: 550px;
        height: 600px;
        border-radius: 8px;
        background-color: #fff;
        z-index: 10001;

        @include md {
            height: unset;
        }
    }

    &__header {
        font-size: 18px;
        font-weight: 600;
        color: #2b2a29;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
    }
}

.switch {
    height: 460px;
    @media (max-height: 600px) and (min-width: 1024px) {
        height: 410px;
    }
    position: relative;

    @include md {
        height: 100%;
    }

    &__wrapper {
        width: 100%;
        height: fit-content;
        position: relative;
    }

    &__pagination {
        height: 80px;
    }
}

.image-wrapper {
    cursor: pointer;
    @include md {
        height: unset;
    }
}

.slider-wrapper {
    min-width: 280px;
}

.icon-close {
    width: 18px;
    height: 18px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
}

.icon-close:after,
.icon-close:before {
    content: '';
    background-color: #1d1e1f;
    width: 100%;
    height: 10%;
    position: absolute;
    transform: rotate(45deg);
}

.icon-close:after {
    transform: rotate(-45deg);
}

.icon-close:active {
    transform: scale(0.8);
}
</style>

<script lang="ts">
import Component from 'vue-class-component';
import UserCitySource from './UserCitySource';
import SelectCityModalM from '../SelectCityModal/SelectCityModalM.vue';

@Component({
    components: {
        SelectCityModalM,
    },
})
export default class UserCityM extends UserCitySource {}
</script>

<template>
    <div v-if="vCity" class="user-city">
        <CityTooltip v-if="isShowTooltip" :sCity="vCity.city_name" :idCity="vCity.city_id" @close="fCloseTooltip" />

        <SelectCityModalM v-if="isShowSelectCity" :sCity="vCity.city_name" @closeTooltip="fCloseTooltip" />
    </div>
</template>

<style lang="scss" scoped>
.user-city {
    position: absolute;
}
</style>

<template>
    <div class="container popup">
        <div class="left">
            <img
                class='popup__img'
                src='@/assets/images/app_popup_fox.webp'
                loading="lazy"
                decoding="async"
                alt='телефончик'
            />
        </div>
        <div class="right">
            <button @click='fClose' class='popup__close' type='button'>
                <span>Закрыть</span>
                &nbsp;
                <icon
                    sClassName='popup__close-icon'
                    sIconName='close'
                    sWidth='11'
                    sHeight='11'
                />
            </button>
            <div class='popup__content'>
                <h4 class='popup__title'>Отличная новость для пользователей {{sPlatform}}!</h4>
                <p class='popup__text'>Теперь выбирать товары <br> и отслеживать статус своих заказов можно через приложение 63pokupki!</p>
                <ds-button :onClick='fDownloadApp' nWidth="189" nHeight="30">Скачать приложение</ds-button>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import Component from 'vue-class-component';
import P63Vue from '@/system/P63Vue';
import ds_button from '@/ds/el/button/button';
import icon from '@/ds/el/icon/icon'
import { Prop } from 'vue-property-decorator';

@Component({
    components: {
        'ds-button': ds_button,
        icon
    }
})
export default class Variant1 extends P63Vue {
    @Prop({type: Function, default: () => {}}) fClose: Function;
    @Prop({type: Function, default: () => {}}) fDownloadApp: Function;
    @Prop({type: String, default: ''}) sPlatform: string;
}
</script>

<style lang="scss" scoped>
$primary-color: #2B2A29;
$secondary-color: #A2A3A5;

.container {
    max-width: 360px;
    margin: 12px 12px 0 0;
    position: relative;
    display: flex;
    justify-content: space-between;
}

.popup {
    &__title {
        margin: 0 0 8px 0;
        font-weight: 600;
        font-size: 14px;
        color: $primary-color;
        line-height: 150%;

    }

    &__text {
        font-size: 12px;
        color: #606266;
        line-height: 150%;
        margin: 8px 0 16px 0;
        max-width: 193px;
    }

    &__img {
        justify-self: end;
        width: 143px;
        height: 238px;
    }

    &__close {
        color: $secondary-color;
        margin-bottom: 8px;
        font-size: 12px;

        &-icon {
            fill: $secondary-color;
            padding: 3.5px;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;

    }
}

.left {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-right: 12px;
}

.right {
    display: flex;
    flex-direction: column;
    align-items: end;
    margin-bottom: 1rem;
}
</style>

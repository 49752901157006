var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"home-page"},[(!_vm.isApp)?_c('div',{ref:"searchbar",class:{
            'home-page__search-mobile': true,
            'home-page__search-mobile_no-auth': !_vm.isAuth,
            'hide-categories': _vm.ctrl.status.is_mobile || _vm.ctrl.status.is_tablet,
        }},[_c('SearchInput')],1):_vm._e(),_c('div',{staticClass:"home-page__top-banners",class:{'top-banners_app': _vm.isApp}},[_c('HomeBanner',{attrs:{"bannerType":_vm.tBannerType.top,"banner":_vm.aTopBannerList,"alias":"main"}})],1),(_vm.list.all_banners_collection.length)?_c('div',[(_vm.status.is_desktop)?_c('div',[_c('HomeStoryD')],1):_c('div',[_c('HomeStoryM')],1)]):_vm._e(),(!_vm.isAuth)?_c('HomeRegistration',{staticClass:"home-page__registration"}):_vm._e(),_c('div',{staticClass:"home-page__content"},[(
                _vm.ixTopSelectionId[_vm.vRating.first] &&
                _vm.ixTopSelection[_vm.ixTopSelectionId[_vm.vRating.first]] &&
                _vm.ixTopSelection[_vm.ixTopSelectionId[_vm.vRating.first]].list_item &&
                _vm.ixTopSelection[_vm.ixTopSelectionId[_vm.vRating.first]].list_item.length
            )?_c('TopSelection',{attrs:{"id":_vm.aSortedTopSelectionByRating[_vm.vRating.first].id,"sNameVisible":_vm.aSortedTopSelectionByRating[_vm.vRating.first].name_visible,"aItems":_vm.ixTopSelection[_vm.ixTopSelectionId[_vm.vRating.first]].list_item,"nRemainingItems":_vm.ixTopSelection[_vm.ixTopSelectionId[_vm.vRating.first]].cnt_item_of_collection,"bSelectionItemsLoading":_vm.bSelectionItemsLoading,"nSyncCard":_vm.nSyncItemCard,"fGetItems":_vm.fGetListItem(_vm.ixTopSelectionId[_vm.vRating.first]),"bFromHome":true}}):_vm._e(),(_vm.status.is_desktop)?_c('div',{staticClass:"home-page__banners"},[_c('HomeBanner',{attrs:{"bannerType":_vm.tBannerType.mini,"banner":_vm.ixBanners[_vm.tBannerPlaceAlias.homeBPageLeft1],"alias":"homeBPageLeft1"}}),_c('HomeBanner',{attrs:{"bannerType":_vm.tBannerType.mini,"banner":_vm.ixBanners[_vm.tBannerPlaceAlias.homeBPageRight1],"alias":"homeBPageRight1"}})],1):_c('div',{staticClass:"home-page__banners home-page__banners_mob"},[_c('HomeBanner',{attrs:{"bannerType":_vm.tBannerType.mini,"banner":_vm.ixBanners[_vm.tBannerPlaceAlias.homeBPageMobile2],"alias":"homeBPageLeft1"}})],1),(
                _vm.ixTopSelectionId[_vm.vRating.second] &&
                _vm.ixTopSelection[_vm.ixTopSelectionId[_vm.vRating.second]] &&
                _vm.ixTopSelection[_vm.ixTopSelectionId[_vm.vRating.second]].list_item &&
                _vm.ixTopSelection[_vm.ixTopSelectionId[_vm.vRating.second]].list_item.length
            )?_c('TopSelection',{attrs:{"id":_vm.aSortedTopSelectionByRating[_vm.vRating.second].id,"sNameVisible":_vm.aSortedTopSelectionByRating[_vm.vRating.second].name_visible,"aItems":_vm.ixTopSelection[_vm.ixTopSelectionId[_vm.vRating.second]].list_item,"nRemainingItems":_vm.ixTopSelection[_vm.ixTopSelectionId[_vm.vRating.second]].cnt_item_of_collection,"bSelectionItemsLoading":_vm.bSelectionItemsLoading,"fGetItems":_vm.fGetListItem(_vm.ixTopSelectionId[_vm.vRating.second]),"bFromHome":true}}):_vm._e(),_c('HomeNews'),(
                _vm.fGetSplicedBanners(_vm.tBannerSelectionNumber.first) &&
                _vm.fGetSplicedBanners(_vm.tBannerSelectionNumber.first).length
            )?_c('Brands',{attrs:{"aBannerSelection":_vm.fGetSplicedBanners(_vm.tBannerSelectionNumber.first)}}):_vm._e(),(
                _vm.ixTopSelectionId[_vm.vRating.third] &&
                _vm.ixTopSelection[_vm.ixTopSelectionId[_vm.vRating.third]] &&
                _vm.ixTopSelection[_vm.ixTopSelectionId[_vm.vRating.third]].list_item &&
                _vm.ixTopSelection[_vm.ixTopSelectionId[_vm.vRating.third]].list_item.length
            )?_c('TopSelection',{attrs:{"id":_vm.aSortedTopSelectionByRating[_vm.vRating.third].id,"sNameVisible":_vm.aSortedTopSelectionByRating[_vm.vRating.third].name_visible,"aItems":_vm.ixTopSelection[_vm.ixTopSelectionId[_vm.vRating.third]].list_item,"nRemainingItems":_vm.ixTopSelection[_vm.ixTopSelectionId[_vm.vRating.third]].cnt_item_of_collection,"bSelectionItemsLoading":_vm.bSelectionItemsLoading,"fGetItems":_vm.fGetListItem(_vm.ixTopSelectionId[_vm.vRating.third]),"bFromHome":true}}):_vm._e(),(_vm.status.is_desktop)?_c('div',{staticClass:"home-page__banners"},[_c('HomeBanner',{attrs:{"bannerType":_vm.tBannerType.mini,"banner":_vm.ixBanners[_vm.tBannerPlaceAlias.homeBPageLeft2],"alias":"homeBPageLeft2"}}),_c('HomeBanner',{attrs:{"bannerType":_vm.tBannerType.mini,"banner":_vm.ixBanners[_vm.tBannerPlaceAlias.homeBPageRight2],"alias":"homeBPageRight2"}})],1):_c('div',{staticClass:"home-page__banners home-page__banners_mob"},[_c('HomeBanner',{attrs:{"bannerType":_vm.tBannerType.mini,"banner":_vm.ixBanners[_vm.tBannerPlaceAlias.homeBPageMobile3],"alias":"homeBPageRight1"}})],1),(
                _vm.ixTopSelectionId[_vm.vRating.fourth] &&
                _vm.ixTopSelection[_vm.ixTopSelectionId[_vm.vRating.fourth]] &&
                _vm.ixTopSelection[_vm.ixTopSelectionId[_vm.vRating.fourth]].list_item &&
                _vm.ixTopSelection[_vm.ixTopSelectionId[_vm.vRating.fourth]].list_item.length
            )?_c('TopSelection',{attrs:{"id":_vm.aSortedTopSelectionByRating[_vm.vRating.fourth].id,"sNameVisible":_vm.aSortedTopSelectionByRating[_vm.vRating.fourth].name_visible,"aItems":_vm.ixTopSelection[_vm.ixTopSelectionId[_vm.vRating.fourth]].list_item,"nRemainingItems":_vm.ixTopSelection[_vm.ixTopSelectionId[_vm.vRating.fourth]].cnt_item_of_collection,"bSelectionItemsLoading":_vm.bSelectionItemsLoading,"fGetItems":_vm.fGetListItem(_vm.ixTopSelectionId[_vm.vRating.fourth]),"bFromHome":true}}):_vm._e(),(
                _vm.fGetSplicedBanners(_vm.tBannerSelectionNumber.first) &&
                _vm.fGetSplicedBanners(_vm.tBannerSelectionNumber.first).length
            )?_c('Brands',{attrs:{"aBannerSelection":_vm.fGetSplicedBanners(_vm.tBannerSelectionNumber.second)}}):_vm._e(),(
                _vm.ixTopSelectionId[_vm.vRating.fifth] &&
                _vm.ixTopSelection[_vm.ixTopSelectionId[_vm.vRating.fifth]] &&
                _vm.ixTopSelection[_vm.ixTopSelectionId[_vm.vRating.fifth]].list_item &&
                _vm.ixTopSelection[_vm.ixTopSelectionId[_vm.vRating.fifth]].list_item.length
            )?_c('TopSelection',{ref:"penultimate_static_selection",attrs:{"id":_vm.aSortedTopSelectionByRating[_vm.vRating.fifth].id,"sNameVisible":_vm.aSortedTopSelectionByRating[_vm.vRating.fifth].name_visible,"aItems":_vm.ixTopSelection[_vm.ixTopSelectionId[_vm.vRating.fifth]].list_item,"nRemainingItems":_vm.ixTopSelection[_vm.ixTopSelectionId[_vm.vRating.fifth]].cnt_item_of_collection,"bSelectionItemsLoading":_vm.bSelectionItemsLoading,"fGetItems":_vm.fGetListItem(_vm.ixTopSelectionId[_vm.vRating.fifth]),"bFromHome":true}}):_vm._e(),(
                _vm.ixTopSelectionId[_vm.vRating.sixth] &&
                _vm.ixTopSelection[_vm.ixTopSelectionId[_vm.vRating.sixth]] &&
                _vm.ixTopSelection[_vm.ixTopSelectionId[_vm.vRating.sixth]].list_item &&
                _vm.ixTopSelection[_vm.ixTopSelectionId[_vm.vRating.sixth]].list_item.length
            )?_c('TopSelection',{attrs:{"id":_vm.aSortedTopSelectionByRating[_vm.vRating.sixth].id,"sNameVisible":_vm.aSortedTopSelectionByRating[_vm.vRating.sixth].name_visible,"aItems":_vm.ixTopSelection[_vm.ixTopSelectionId[_vm.vRating.sixth]].list_item,"nRemainingItems":_vm.ixTopSelection[_vm.ixTopSelectionId[_vm.vRating.sixth]].cnt_item_of_collection,"bSelectionItemsLoading":_vm.bSelectionItemsLoading,"fGetItems":_vm.fGetListItem(_vm.ixTopSelectionId[_vm.vRating.sixth]),"bFromHome":true}}):_vm._e(),_vm._l((_vm.nDisplayedTopSelectionCount),function(nNumber){return [(
                    nNumber > _vm.nInitiaSelectionCount &&
                    _vm.aSortedTopSelectionByRating[nNumber - 1] &&
                    _vm.ixTopSelection[_vm.ixTopSelectionId[nNumber - 1]].list_item &&
                    _vm.ixTopSelection[_vm.ixTopSelectionId[nNumber - 1]].list_item.length
                )?_c('TopSelection',{key:nNumber,attrs:{"id":_vm.aSortedTopSelectionByRating[nNumber - 1].id,"sNameVisible":_vm.aSortedTopSelectionByRating[nNumber - 1].name_visible,"aItems":_vm.ixTopSelection[_vm.ixTopSelectionId[nNumber - 1]].list_item,"nRemainingItems":_vm.ixTopSelection[_vm.ixTopSelectionId[nNumber - 1]].cnt_item_of_collection,"bSelectionItemsLoading":_vm.bSelectionItemsLoading,"fGetItems":_vm.fGetListItem(_vm.ixTopSelectionId[nNumber - 1]),"bFromHome":true}}):_vm._e()]}),_c('HomeCities'),_c('HomeAboutsUsBottom'),_c('HomeUserActions'),(_vm.status.is_modal_user_not_sign_in)?_c('ModalUserIsNotSignIn',{on:{"onClose":function($event){_vm.status.is_modal_user_not_sign_in = false},"onGoSignIn":_vm.fOnGoSignIn,"onGoSignUp":_vm.fOnGoSignUp}}):_vm._e()],2),_c('div',{ref:"background",staticClass:"background"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
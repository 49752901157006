/** Тип устройства */
export enum DeviceTypeT {
    not_detected = 0,
    mobile = 1,
    tablet = 2,
    desktop = 3,
}

/** Интерфейс таблицы событий взаимодействия по пользователям */
export interface UserActionI {
    page?: string,
    user_id?: number,
    noauthkey?: string,
    device_type?: DeviceTypeT,
    alias?: string,
    entity_type?: string,
    entity_id?: number,
    action_date?: string,
    action_count?: number
}

import { Component, Prop } from 'vue-property-decorator';

import P63Vue from '@/system/P63Vue';
import { PageCtrl } from '@/system/PageCtrl';
import { gfInitContextCtrl } from '@/system/ContextSys';
import { ItemOfCollectionI, ProductOfCollectionI } from '@/ifc/core/CommonI/ItemCollectionI';
import { mIsClient } from '@/common/helpers/ContextH';
import { mIsElementVisible } from '@/common/helpers/ElementVisibleH';
import { mThrottled } from '@/common/helpers/Throttled';
import ItemCard from '@/common/el/ItemCard/ItemCard';
import ProductCard from '../ProductCard/ProductCard';
import { TopSelectionN } from '@/common/interface/TopSelectionN';
import { ItemCardN } from '@/common/interface/ItemCardN';

@Component({
    components: {
        ItemCard,
        ProductCard,
    },
})
export default class TopSelection extends P63Vue {
    /** id топ-подборки */
    @Prop({ type: Number, default: 0 }) id: number;
    /** Отображаемое имя топ-подборки */
    @Prop({ type: String, default: '' }) sNameVisible: string;
    /** Товары топ-подборки */
    @Prop({ type: Array, default: () => [] }) aItems: (ItemOfCollectionI | ProductOfCollectionI)[];
    /** Загружаются ли товары в подборку */
    @Prop({ type: Boolean, default: false }) bSelectionItemsLoading: boolean;
    /** Количество оставшихся карточек в коллекции */
    @Prop({ type: Number, default: 0 }) nRemainingItems: number;
    /** Количество карточек, которые нужно нужно загрузить синхронно */
    @Prop({ type: Number, default: 0 }) nSyncCard: number;
    /** Функция для получения товаров одной подборки */
    @Prop({ type: Function, default: () => {} }) fGetItems: () => void;
    /** Находится ли подборка на главной странице */
    @Prop({ type: Boolean, default: false }) bFromHome: boolean;

    /** Количество карточек в одной секции */
    nItemsSection: number = TopSelectionN.SelectionItemCountT.one_section;
    /** Была ли показана карточка */
    bItWasVisible: boolean = false;
    /** Функция, которая будет ограничивать mIsElementVisible */
    fThrottleVisibleHandlerFn: () => Function = null;

    /** Хранилище (общее) */
    get ctrl(): PageCtrl {
        return gfInitContextCtrl(mIsClient() ? this.$context : this.$ssrContext, PageCtrl);
    }

    /** Статус авторизации пользователя */
    get bIsAuth() {
        return this.ctrl.userSys.user.isAuth;
    }

    /** Получить товары из списка желаний */
    private fGetWishList() {
        const aidItem: number[] = [];
        const aidProduct: number[] = [];

        if (this.aItems?.length) {
            for (let i = 0; i < this.aItems.length; i++) {
                const vItem = this.aItems[i];
                if ('item_id' in vItem) {
                    aidItem.push(vItem.item_id)
                } else {
                    aidProduct.push(vItem.product_id)
                }
            }
        }

        this.ctrl.wishSys.fGetItemsInWishList({
            list_item_id: aidItem,
            list_product_id: aidProduct
        });
    }

    /** Загрузить дополнительные товары при клике на кнопку */
    fButtonClick() {
        this.fGetItems();
        /** Отправить данные в статистику о клике по кнопке  Показать еще*/
        this.ctrl.statSys.faPushClickToStat({
            page: location.pathname,
            type: 'top_selection',
            alias: 'button_show_more',
            entity_id: this.id,
        });
    }

    /** Отправить аналитику при нажатии на товар и перейти на страницу товара */
    fOnItemClick(arg: ItemCardN.CardClickArgI): void {
        const { item, i, callbackUrl } = arg;
        const idItem = 'item_id' in item ? item.item_id : (item as ProductOfCollectionI).product_id;
        const nPrice = 'item_id' in item ? item.price : (item as ProductOfCollectionI).min_price;

        /** Отправить данные в статистику о клике по товару из подборки */
        this.ctrl.statSys.faPushClickToStat({
            page: location.pathname,
            type: 'item_id' in item ? 'item' : 'product',
            alias: 'item_click_home_selection',
            entity_id: idItem,
        });

        this.ctrl.statSys.fSendEcommerceAction({
            action: 'Product Click',
            bNonInteraction: false,
            data: {
                ecommerce: {
                    click: {
                        actionField: { list: 'top-selection-' + this.id },
                        products: [
                            {
                                id: String(idItem),
                                name: item.name,
                                price: String(nPrice),
                                position: i,
                            },
                        ],
                    },
                },
            },
        });

        window.open(callbackUrl, this.$sTargetLink);
    }

    async mounted() {
        if (this.bIsAuth) {
            this.fGetWishList();
        }

        const section = this.$refs.section;
        const throttledFn = mThrottled(() => {
            const isVisible = mIsElementVisible(section);
            if (isVisible && !this.bItWasVisible) {
                const items = this.aItems.map((item, i) => {
                    const idItem = 'item_id' in item ? item.item_id : (item as ProductOfCollectionI).product_id;
                    const nPrice = 'item_id' in item ? item.price : (item as ProductOfCollectionI).min_price;
                    return {
                        id: String(idItem),
                        name: item.name,
                        price: String(nPrice),
                        position: i,
                        list: 'top-selection-' + this.id,
                    }
                });

                this.ctrl.statSys.fSendEcommerceAction({
                    action: 'Product Impressions',
                    bNonInteraction: true,
                    data: {
                        ecommerce: {
                            impressions: items,
                        },
                    },
                });

                this.bItWasVisible = true;
            }
        }, 500);

        this.fThrottleVisibleHandlerFn = throttledFn;
        document.addEventListener('scroll', this.fThrottleVisibleHandlerFn, { passive: true });
    }

    beforeDestroy() {
        document.removeEventListener('scroll', this.fThrottleVisibleHandlerFn);
    }
}

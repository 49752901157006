import { ItemI } from './EntitySQL/ItemE';
import { CollectionTypeT, P63ItemCollectionI } from './EntitySQL/P63ItemCollectionE';
import { P63ItemOfItemCollectionI } from './EntitySQL/P63ItemOfItemCollectionE';
import { S3ItemImgIDsI } from './EntitySQL/S3ItemImgE';
import { P63ItemOrderCounterI } from './EntitySQL/P63ItemOrderCounterE';
import { ItemOfCollectionI, ProductOfCollectionI } from './CommonI/ItemCollectionI';

export namespace ItemCollectionR {
    export const Alias = 'ItemCollection';

    /** Получить общим списком коллекции */
    export namespace listItemCollection {

        /** APIURL */
        export const route = '/item-collection/list-item-collection';

        /** Параметры api запроса */
        export interface RequestI {
            // параметры в запросе не предусмотрены
        }

        /** Параметры api ответа */
        export interface ResponseI {
            list_collection: P63ItemCollectionI[]; // список всех коллекций
            ix_count_item_of_collection: Record<number, number>; // key = item_collection_id, val = cnt - количество товаров и продуктов в коллекции
        }
    }

    /** Получить список всех товаров и продуктов в коллекции */
    export namespace listItemOfCollection {

        /** APIURL */
        export const route = '/item-collection/list-item-of-collection';

        /** Параметры api запроса */
        export interface RequestI {
            collection_id: number; // ID коллекции
        }

        /** Параметры api ответа */
        export interface ResponseI {
            list_item_of_collection: P63ItemOfItemCollectionI[]; // Список всех товаров в коллекции
            list_item: ItemI[]; // Список товаров
            list_item_img: S3ItemImgIDsI[]; // Список картинок
            list_item_order_counter: P63ItemOrderCounterI[]; // Количество заказов у товара
            list_active_item: number[]; // Список активных товаров
            list_product_of_collection: ProductOfCollectionI[]; // Список всех продуктов в коллекции
        }
    }

    /** Добавить коллекцию */
    export namespace insertCollection {

        /** APIURL */
        export const route = '/item-collection/insert-collection';

        /** Параметры api запроса */
        export interface RequestI {
            name_base: string, // исходное имя коллекции
            name_visible: string, // имя коллекции, отображаемое на сайте
            type: CollectionTypeT, // тип коллекции
            is_visible: number, // видимость топ коллекции на главной странице
            rating: number, // рейтинг для сортировки и повышения в выдаче
            alias: string, // ключ в нижнем регистре латиница по которому можно запрашивать выборку
        }

        /** Параметры api ответа */
        export interface ResponseI {
            collection_id: number, // ID коллекции, которая была добавлена
        }
    }

    /** Удалить коллекцию */
    export namespace deleteCollection {

        /** APIURL */
        export const route = '/item-collection/delete-collection';

        /** Параметры api запроса */
        export interface RequestI {
            collection_id: number, // ID коллекции, которую нужно удалить
        }

        /** Параметры api ответа */
        export interface ResponseI {
            is_delete_collection: boolean // удалить коллекцию
        }
    }

    /** Изменить коллекцию */
    export namespace updateCollection {

        /** APIURL */
        export const route = '/item-collection/update-collection';

        /** Параметры api запроса */
        export interface RequestI {
            item_collection_id: number, // ID коллекции, которую нужно изменить
            name_base?: string, // исходное имя коллекции
            name_visible?: string, // имя коллекции, отображаемое на сайте
            type?: CollectionTypeT, // тип коллекции
            is_visible?: number, // видимость коллекции на главной странице
            rating?: number, // рейтинг для сортировки и повышения в выдаче
            alias?: string, // ключ в нижнем регистре латиница по которому можно запрашивать выборку
            cost_place?: number, // стоимость добавления товара в коллекцию
        }

        /** Параметры api ответа */
        export interface ResponseI {
            count_updated_rows: number, // количество обновлённых строк
        }
    }

    /** Организатор добавляет товар в коллекции */
    export namespace insertItemToCollectionByOrg {

        /** APIURL */
        export const route = '/item-collection/insert-item-to-collection-by-org';

        /** Параметры api запроса */
        export interface RequestI {
            item_id: number; // ID товара, который нужно добавить
        }

        /** Параметры api ответа */
        export interface ResponseI {
            item_of_item_collection_id: number, // ID товара (primary key) в таблице p63_item_of_item_collection
        }
    }

    /** Добавить товар в коллекцию с ранжированием по цене в зависимости от категории организатором */
    export namespace insertItemToCollectionWithRangeByOrg {

        /** APIURL */
        export const route = '/item-collection/insert-item-to-collection-with-range-by-org';

        /** Параметры api запроса */
        export interface RequestI {
            item_id: number; // ID товара, который нужно добавить
        }

        /** Параметры api ответа */
        export interface ResponseI {
            item_of_item_collection_id: number, // ID товара (primary key) в таблице p63_item_of_item_collection
        }
    }

    /** Админ добавляет товар в коллекцию */
    export namespace insertItemToCollection {

        /** APIURL */
        export const route = '/item-collection/insert-item-to-collection';

        /** Параметры api запроса */
        export interface RequestI {
            item_collection_id: number; // ID коллекции, в которую нужно добавить товар
            item_id: number; // ID товара, который нужно добавить
            old_price?: number; // старая цена
            filename: string; // картинка товара
        }

        /** Параметры api ответа */
        export interface ResponseI {
            item_of_item_collection_id: number, // ID товара (primary key) в таблице p63_item_of_item_collection
        }
    }

    /** Админ добавляет продукт в коллекцию */
    export namespace insertProductToCollection {

        /** APIURL */
        export const route = '/item-collection/insert-product-to-collection';

        /** Параметры api запроса */
        export interface RequestI {
            item_collection_id: number; // ID коллекции, в которую нужно добавить продукт
            product_id: number; // ID продукта, который нужно добавить
        }

        /** Параметры api ответа */
        export interface ResponseI {
        }
    }

    /** Удалить товар из коллекции */
    export namespace deleteItemFromCollection {

        /** APIURL */
        export const route = '/item-collection/delete-item-from-collection';

        /** Параметры api запроса */
        export interface RequestI {
            item_of_item_collection_id: number, // ID товара, который нужно удалить из коллекции
        }

        /** Параметры api ответа */
        export interface ResponseI {
            count_affected_rows: number, // подсчет затронутых строк
        }
    }

    /** Удалить продукт из коллекции */
    export namespace deleteProductFromCollection {

        /** APIURL */
        export const route = '/item-collection/delete-product-from-collection';

        /** Параметры api запроса */
        export interface RequestI {
            product_id: number, // ID продукта, который нужно удалить из коллекции
        }

        /** Параметры api ответа */
        export interface ResponseI {
        }
    }

    /** Обновить товар в коллекции */
    export namespace updateItemCollection {

        /** APIURL */
        export const route = '/item-collection/update-item-collection';

        /** Параметры api запроса */
        export interface RequestI {
            id_item_of_collection: number; // ID товара в коллекции, в который нужно внести изменения
            old_price?: number; // старая цена
            visible?:boolean; // Видимость товара в коллекции
        }

        /** Параметры api ответа */
        export interface ResponseI {
            // ответ не предусмотрен
        }
    }

    /** Получить коллекцию по alias (для клиентской части) */
    export namespace collectionByAlias {

        /** APIURL */
        export const route = '/item-collection/collection-by-alias';

        /** Параметры api запроса */
        export interface RequestI {
            alias: string; // alias в строковом значении
        }

        /** Параметры api ответа */
        export interface ResponseI {
            alias_collection: P63ItemOfItemCollectionI[]; // список всех товаров в коллекции
        }
    }

    /** Получить смешанную подборку: несколько коллекций (для клиентской части) */
    export namespace multipleCollection {

        /** APIURL */
        export const route = '/item-collection/multiple-collection';

        /** Параметры api запроса */
        export interface RequestI {
            names_collection: string[]; // массив строк с коллекциями
        }

        /** Параметры api ответа */
        export interface ResponseI {
            list_collection: P63ItemOfItemCollectionI[]; // список всех товаров в подборке (несколько коллекций)
        }
    }

    /** Получить коллекции "Распродажи" или "Товар дня" (для клиентской части) */
    export namespace saleOrCollectionDay {

        /** APIURL */
        export const route = '/item-collection/sale-or-collection-day';

        /** Параметры api запроса */
        export interface RequestI {
            type: CollectionTypeT; // alias коллекции Распродажи (sale) или Товар дня (top_day)
        }

        /** Параметры api ответа */
        export interface ResponseI {
            collection: P63ItemOfItemCollectionI[]; // список товаров в коллекции "Распродажи" или "Товар дня"
        }
    }

    /**  Получить 60 популярных товаров по любимым шаблонам и категориям пользователя */
    export namespace getListByStock{

        /** APIURL */
        export const route = '/item-collection/get-list-by-stock';

        /** Параметры api запроса */
        export interface RequestI {
            // входные параметры не предусмотрены
        }

        /** Параметры api ответа */
        export interface ResponseI {
            info: P63ItemCollectionI; // Информация по подборке
            list_item_of_collection: ItemOfCollectionI[]; // Товары в подборке
        }
    }

    /** Получить 60 наиболее популярных товаров по любимым категориям пользователя */
    export namespace getListByCategory {

        /** APIURL */
        export const route = '/item-collection/get-list-by-category';

        /** Параметры api запроса */
        export interface RequestI {
            // входные параметры не предусмотрены
        }

        /** Параметры api ответа */
        export interface ResponseI {
            info: P63ItemCollectionI; // Информация по подборке
            list_item_of_collection: ItemOfCollectionI[]; // Товары в подборке
        }
    }

    /** Получить наиболее популярные товары из любимых категорий пользователя по пристрою*/
    export namespace getListPristroy {

        /** APIURL */
        export const route = '/item-collection/get-list-pristroy';

        /** Параметры api запроса */
        export interface RequestI {
            // входные параметры не предусмотрены
        }

        /** Параметры api ответа */
        export interface ResponseI {
            info: P63ItemCollectionI; // Информация по подборке
            list_item_of_collection: ItemOfCollectionI[]; // Товары в подборке
        }
    }

    /** Получить наиболее популярные товары по рейтингу пользователя */
    export namespace getListByRating {

        /** APIURL */
        export const route = '/item-collection/get-list-by-rating';

        /** Параметры api запроса */
        export interface RequestI {
            // Нет данных
        }

        /** Параметры api ответа */
        export interface ResponseI {
            list_item_of_collection: ItemOfCollectionI[]; // Товары в подборке
        }
    }

    /** Получить наиболее популярные Хиты продаж по рейтингу пользователя */
    export namespace getListTopItem {

        /** APIURL */
        export const route = '/item-collection/get-list-top-item';

        /** Параметры api запроса */
        export interface RequestI {
            // Нет данных
        }

        /** Параметры api ответа */
        export interface ResponseI {
            list_item_of_collection: ItemOfCollectionI[]; // Товары в подборке
        }
    }

    /** Получить подборку товаров, находящихся в одной подкатегорией с товаром (Блок "Товары в наличии") */
    export namespace getListItemByCategoryOfItem {
        /** APIURL */
        export const route = '/item-collection/get-list-item-by-category-of-item';

        /** Параметры api запроса */
        export interface RequestI {
            item_id: number;                    // ID товара, к которому делается подборка
            cache?: number;                     // кэш для повторной выборки
            list_exclude_item_id?: number[];    // список id товаров, которые нужно исключить из выборки
        }

        /** Параметры api ответа */
        export interface ResponseI {
            list_item: ItemOfCollectionI[];     // товары подборки
            list_item_id_from_market_uz: number[]; // id товаров из маркета
            cache: number;                      // кэш, к которому обращались
            cnt_item_of_collection: number;     // количество товаров в коллекции
        }
    }
}

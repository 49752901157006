import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ModalTypeT } from './ifc_modal'
import { gModalS } from './ModalS';
import Icon from '../icon/icon';

/**
 * Компонент Modal принимает
 * @ isDisabledScroll - Запрещать ли скролл основого контента
 * @ isShowCross - Показывать крестик для закрытия окна
 * @ sWidth - Ширина модального окна
 * @ sMinHeight - Минимальная высота модального окна
 * @ sMinWidth - Минимальная ширина модального окна
 * @ vStyle - Объект стилей модального окна
 * @ sMaxHeight - Максимальная высота модального окна
 * @ sMaxWidth - Максимальная ширина модального окна
 * @ sTypeSize - Тип модального окна по размеру
 * @ onClose: () => void - Функция закрытия модального окна
 */
@Component({
    components: {
        Icon,
    }
})
export default class modal extends Vue {
    /** Ширина модального окна */
    @Prop({type: String, default: null }) sWidth: string;
    /** Минимальная высота модального окна */
    @Prop({type: String, default: null }) sMinHeight: string;
    /** Минимальная ширина модального окна */
    @Prop({type: String, default: null }) sMinWidth: string;
    /** Максимальная высота модального окна */
    @Prop({type: String, default: null }) sMaxHeight: string;
    /** Максимальная ширина модального окна */
    @Prop({type: String, default: null }) sMaxWidth: string;
    /** Объект стилей модального окна */
    @Prop({type: Object, default: () => {} }) vStyle: object;
    /** Тип модального окна по размеру*/
    @Prop({ type: String, default: 'small' }) sTypeSize: ModalTypeT;
    /**  Запрещать ли скролл основого контента */
    @Prop({ type: Boolean, default: true }) isDisabledScroll: boolean;
    /**  Показывать крестик для закрытия окна */
    @Prop({ type: Boolean, default: true }) isShowCross: boolean;
    /** Функция закрытия модального окна */
    @Prop({ type: Function }) onClose: () => void;

    idModal = 0 // Айди текущей модалки, (её индекс открытия) нужен для корректного закрытия последней

    /** Закрыть модальное окно */
    fClose() {
        if (this.onClose) {
            this.onClose();
        }

        if (this.isDisabledScroll) {
            document.body.classList.remove('stop-scrolling');
        }
    }

    /** Закрыть модалку по escape */
    fCloseModalEscape(event: KeyboardEvent) {
        if(event.key === 'Escape' && gModalS.fCheckLastOpen(this.idModal)){
            this.onClose();
        }
    }


    mounted() {
        if (this.isDisabledScroll) {
            document.body.classList.add('stop-scrolling');
        }
        this.idModal = gModalS.fIdGen()
        document.addEventListener('keydown', this.fCloseModalEscape);
    }

    beforeDestroy() {
        if (this.isDisabledScroll) {
            document.body.classList.remove('stop-scrolling');
        }
        gModalS.fDicrementOnClose()

        document.removeEventListener('keydown', this.fCloseModalEscape);
    }

    /** Получить объект стилей*/
    get vStyleCustom() {
        let vStyleCustom: {[key: string]: string} = {};

        if(this.sWidth) {
            vStyleCustom['width'] = this.sWidth;
        }

        if(this.sMaxHeight) {
            vStyleCustom['max-height'] = this.sMaxHeight;
        }

        if(this.sMaxWidth) {
            vStyleCustom['max-width'] = this.sMaxWidth;
        }

        if(this.sMinHeight) {
            vStyleCustom['min-height'] = this.sMinHeight;
        }

        if(this.sMinWidth) {
            vStyleCustom['min-width'] = this.sMinWidth;
        }

        vStyleCustom = {...vStyleCustom, ...this.vStyle};
        
        return vStyleCustom;
    }
}

import { Component } from 'vue-property-decorator';
import P63Vue from '@/system/P63Vue';
import { HomeCtrl, initHomeCtrl } from '../../view/ctrl-home';
import { mIsClient } from '@/common/helpers/ContextH';
import { HomeN } from '../../view/ifc-home';
import {
    mChangeNumberPlace,
    mFormatNumber,
    mTruncateDecimal,
} from '@/common/helpers/NumberFormatterH';
import { mPluralize } from '@/common/helpers/TextFormatterH';

@Component
export default class ListCountsSource extends P63Vue {
    /** Отформатировать числа в нужный формат */
    fFormatNumber<T extends Record<string, number>>(objCounts: T): Record<keyof T, string> {
        const objFormatedCounts: Record<keyof T, string> = Object.create(objCounts);
        for (let nCount in objCounts) {
            objFormatedCounts[nCount] =
                objCounts[nCount] >= 1000000
                    ? String(mTruncateDecimal(mChangeNumberPlace(objCounts[nCount], 6), 1)).replace(
                          '.',
                          ','
                      ) + ' млн'
                    : mFormatNumber(objCounts[nCount]);
        }
        return objFormatedCounts;
    }

    /** Отправить данные в статистику о клике по карточке с показателями */
    fSendActionCountsClick(sStatAlias: string) {
        this.ctrl.statSys.faPushClickToStat({
            page: location.pathname,
            type: 'card_counts_new',
            alias: sStatAlias,
            entity_id: 0,
        });
    }

    /** Список для отображения карточек с преимуществами */
    get aInfoCounts(): HomeN.InfoCountsI[] {
        return [
            {
                count: this.objFormatedCounts.item_delivered_cnt,
                text: 'товаров доставлено за год',
                icon: 'icon-count-item',
                link: '/search?sort_by=1 ',
                alias: 'counts_click_items',
            },
            {
                count: this.objFormatedCounts.brand_cnt,
                text: `${mPluralize(this.objCounts.brand_cnt, [
                    'бренд',
                    'бренда',
                    'брендов',
                ])} представлено`,
                icon: 'icon-count-brand',
                link: '/brands/ ',
                alias: 'counts_click_brands',
            },
            {
                count: this.objFormatedCounts.active_purchase_cnt,
                text: `${mPluralize(this.objCounts.active_purchase_cnt, [
                    'выкуп',
                    'выкупа',
                    'выкупов',
                ])} сейчас открыто`,
                icon: 'icon-count-purchase',
                link: '/purchases/',
                alias: 'counts_click_purchases',
            },
            {
                count: this.objFormatedCounts.review_cnt,
                text: `${mPluralize(this.objCounts.review_cnt, [
                    'отзыв',
                    'отзыва',
                    'отзывов',
                ])} пользователей`,
                icon: 'icon-count-review',
                link: '/client-rec',
                alias: 'counts_click_reviews',
            },
        ];
    }

    /** Счетчики */
    get objCounts() {
        return this.ctrl.one.counts;
    }

    /** Форматированные строки с счетчиками */
    get objFormatedCounts() {
        return this.fFormatNumber(this.objCounts);
    }

    /** Путь к спрайту с иконками */
    get sSpritePath() {
        return this.ctrl.status.sSpritePath;
    }

    /** Неавторизованный пользователь */
    get isUnauth(): boolean {
        return !this.ctrl.userSys.user?.isAuth;
    }

    /** Пользователь с нулевым уровнем рейтинга */
    get isNewUser(): boolean {
        return this.ctrl.userSys.user?.info?.consumer_rating === 0;
    }

    /** Хранилище */
    get ctrl(): HomeCtrl {
        return initHomeCtrl(mIsClient() ? this.$context : this.$ssrContext);
    }
}

<template>
    <section class="brands" aria-label="Лучшие подборки">
        <ul class="brands__list">
            <template v-for="selection in aBannerSelection">
                <li
                    class="brands__item"
                    :key="selection.banner_id"
                >
                    <a class="brands__link" :href="selection.banner_url" :target="$sTargetLink">
                        <picture>
                            <source :srcset="fGetImgUrl(selection.filename, selection.is_webp)" />
                            <img
                                class="brands__img"
                                loading="lazy"
                                decoding="async"
                                :src="fGetImgUrl(selection.filename)"
                                :alt="selection.banner_name"
                            />
                        </picture>
                    </a>
                </li>
            </template>
        </ul>
    </section>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import BrandsSource from './BrandsSource';

@Component
export default class Brands extends BrandsSource {}
</script>

<style lang="scss" scoped>
.brands {
    position: relative;

    padding: 0 8px;

    &__list {
        position: relative;

        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 14px;
        padding: 0;
        margin: 0;

        list-style: none;
    }

    &__item {
        aspect-ratio: 145 / 85;
    }

    &__link {
        display: block;

        width: 100%;
        height: 100%;
    }

    &__img {
        display: block;

        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;

        border-radius: 9px;
    }

    @media (min-width: 576px) {
        &__list {
            gap: 25px;
        }

        &__img {
            border-radius: 15px;
        }
    }

    @media (min-width: 768px) {
        padding: 0;

        &__list {
            grid-template-columns: repeat(3, 1fr);
            gap: 34px;
        }

        &__item {
            aspect-ratio: 360 / 206;
        }

        &__img {
            border-radius: 19px;
        }
    }

    @media (min-width: 1184px) {
        &__list {
            gap: 52px;
        }

        &__img {
            border-radius: 29px;
        }
    }
}
</style>

/** Публичный конфиг */
export const configPublic = {
  /** Обработка ошибок */
  errors: {
    /** Отправлять ошибки на в маттермост или нет. Выключить при локальной разработке */
    isSendErrorToMattermost: false,
    /** Url на который отправляются ошибки */
    url: 'https://63pokupki.ru/api/error/common/front-error',
  },
  auth: {
    cookie: {
      options: {
        login: {
          /** 30 дней в секундах */
          maxAge: 2592000,
          path: '/',
          domain: '63pokupki.ru',
          sameSite: 'lax' as const,
          httpOnly: true,
          secure: true,
        },
        logout: {
          maxAge: -1,
          path: '/',
          domain: '63pokupki.ru',
          sameSite: 'lax' as const,
          httpOnly: true,
          secure: true,
        },
      },
    },
  },
  app: {
    host: 'https://63pokupki.ru',
    /** Режим разработки */
    dev: true,
  },
  api: {
    /** URL старого сайта old_core */
    apiOldCoreUrl: 'https://63pokupki.ru',
    /** URL core */
    apiCoreUrl: 'https://63pokupki.ru/api/core',
    /** URL internal core */
    apiCoreIntUrl: 'https://63pokupki.ru/api/core',
    /** URL vendor integrations */
    apiViUrl: 'https://63pokupki.ru/api/vi',
    /** URL statements */
    apiStatementsUrl: 'https://63pokupki.ru/api/core',
    /** URL auth.core */
    apiAuthUrl: 'https://63pokupki.ru/api/auth',
    /** URL msg.core */
    apiMsgUrl: 'https://63pokupki.ru/api/msg',
    /** URL stat.core */
    apiStatUrl: 'https://63pokupki.ru/api/stat', // URL API stat для получений данных аналитики 
    /** URL img.core */
    apiImgUrl: 'https://63pokupki.ru/api/img',
    /** URL proxy-guard */
    apiGuardUrl: 'https://63pokupki.ru/api/guard',
  },
  
    /** Данные по S3 хранилищам */
  storages: {
    /** Все остальные файлы [devops2] */
    S3DO: {
      url: 'https://63pokupkifiles.fra1.cdn.digitaloceanspaces.com',
    },
    S3Selectel: {
      url: 'https://f63.63pokupki.ru',
    },
    /** Item image - картинки размера x256 [devops1] */
    S3DO256: {
      url: 'https://63pokupki256.fra1.cdn.digitaloceanspaces.com',
    },
    S3Selectel256: {
      url: 'https://i256.63pokupki.ru',
    },
    /** Item image - картинки размера x512 [devops] */
    S3DO512: {
      url: 'https://63pokupki.fra1.cdn.digitaloceanspaces.com',
    },
    S3Selectel512: {
      url: 'https://i512.63pokupki.ru',
    },
    /** Item image - картинки размера x1024 [devops4] */
    S3DO1024: {
      url: 'https://63pokupki1024.fra1.cdn.digitaloceanspaces.com',
    },
    S3Selectel1024: {
      url: 'https://i1024.63pokupki.ru',
    },
    /** Файлы личных сообщений и аватары [devops3] */
    S3DOPM512: {
      url: 'https://63pokupkipm.fra1.cdn.digitaloceanspaces.com',
    },
    S3SelectelPM: {
      url: 'https://msg.63pokupki.ru',
    },
  },
};

// CORE API
export const coreApi = {
    baseURL:configPublic.api.apiCoreUrl,
    timeout:30000,
    withCredentials: true,
}

// AUTH.CORE API
export const authApi = {
    baseURL:configPublic.api.apiAuthUrl,
    timeout:10000,
    withCredentials: true,
  }

  // MSG API
export const msgAPI = {
  baseURL: configPublic.api.apiMsgUrl,
  timeout: 30000,
  withCredentials: true,
};

// STAT API
export const statAPI = {
  baseURL: configPublic.api.apiStatUrl,
  timeout: 30000,
  withCredentials: true,
};

// IMG API
export const imgAPI = {
  baseURL: configPublic.api.apiImgUrl,
  timeout: 30000,
  withCredentials: true,
};

 // Proxy-Guarg API
export const guardApi = {
    baseURL:configPublic.api.apiGuardUrl,
    timeout:10000,
    withCredentials: true,
}

// Счетчик Яндекс Метрики
export const yandexAPI = {
  counter: 26867085
}

export default configPublic;

export type ConfigPublic = typeof configPublic;

import Component from 'vue-class-component';
import dayjs from 'dayjs';

import P63Vue from '@/system/P63Vue';
import { PageCtrl } from '@/system/PageCtrl';
import { gfInitContextCtrl } from '@/system/ContextSys';
import { gLinkS } from '@/common/service/LinkS';
import { mIsClient } from '@/common/helpers/ContextH';
import { SocialLinkN } from '@/common/interface/SocialLinkN';

// import SubscribeEmailInput from '@/common/el/SubscribeEmailInput/SubscribeEmailInput';
import { clickOutside } from '@/ds/directives/clickOutside';
import icon from '@/ds/el/icon/icon';
import tooltip from '@/ds/el/tooltip/tooltip';

@Component({
    components: {
        // SubscribeEmailInput,
        Icon: icon,
        Tooltip: tooltip,
    },

    directives: {
        'click-outside': clickOutside,
    },
})
export default class footer_page extends P63Vue {
    /** Открыть модальное окно отправки жалобы */
    fOpenAddClaimModal() {
        this.ctrl.footerSys.footer.is_modal_send_claim_open = true;
    }

    /** Редирект на вход */
    onGoSignIn() {
        window.location.href = gLinkS.fGetLoginUrl(window.location.href);
    }
    /** Редирект на регистрацию */
    onGoSignUp() {
        window.location.href = gLinkS.fGetRegisterUrl(window.location.href);
    }

    /** Закрытие модального окна */
    fCloseAddClaimModal() {
        this.ctrl.footerSys.footer.is_modal_send_claim_open = false;
    }

    /** Отправить данные в статистику о клике по соцсети */
    fSendActionOpenSocialLink(sSocialName: string) {
        this.ctrl.statSys.faPushClickToStat({
            page: location.pathname,
            type: 'social',
            alias: `${sSocialName}_link_click`,
            entity_id: 0,
          });
    }
    // Геттеры
    // Селекторы
    /** Интервал годов для copyright */
    get sYearsCopyright() {
        return `- ${dayjs().year()}`;
    }

    /** Авторизован ли пользователь */
    get bAuth() {
        return this.ctrl.userSys.user.isAuth;
    }

    /** Информация по социальным сетям */
    get aSocial() {
        return [
            {
                ariaLabel: 'Вконтакте',
                link: 'https://vk.com/63pokupki',
                iconName: 'vk',
                tooltipText: 'ТОП-закупки',
                staticLabel: SocialLinkN.SocialLinkT.vkontakte,
            },
            {
                ariaLabel: 'Одноклассники',
                link: 'https://ok.ru/group63pokupki',
                iconName: 'ok',
                tooltipText: 'ТОП-закупки',
                staticLabel: SocialLinkN.SocialLinkT.odnoklassniki,
            },
            {
                ariaLabel: 'Ютуб',
                link: 'https://www.youtube.com/channel/UCiRG7uK7jYLhM0DAzvTlNmg',
                iconName: 'youtube',
                tooltipText: 'ТОП-закупки',
                staticLabel: SocialLinkN.SocialLinkT.youtube,
            },
            {
                ariaLabel: 'Вайбер',
                link: 'https://invite.viber.com/?g2=AQAFp66898%2Fk%2Bkx32C6tpavFlXMi5KjeI6L5re3LuO2qsCSDH8759aNMAsP%2BkZps',
                iconName: 'viber',
                tooltipText: 'Ничего лишнего: только хиты со скидками!',
                staticLabel: SocialLinkN.SocialLinkT.viber,
            },
            {
                ariaLabel: 'Телеграм',
                link: 'https://t.me/sp63pokupki',
                iconName: 'tg',
                tooltipText: 'Видеообзоры, закупки дня и живое общение',
                staticLabel: SocialLinkN.SocialLinkT.telegram,
            },
            {
                ariaLabel: 'Дзен',
                link: 'https://dzen.ru/63pokupki',
                iconName: 'zen',
                tooltipText: 'Закупки дня и самые интересные распродажи',
                staticLabel: SocialLinkN.SocialLinkT.dzen,
            },
            // {
            //     ariaLabel: 'Инстаграм',
            //     link: 'https://www.instagram.com/63pokupki.ru',
            //     iconName: 'instagram',
            //     tooltipText: 'ТОП-закупки',
            // },
        ];
    }

    /** Открыт ли сайт с приложения */
    get isApp() {
        return this.ctrl.isApp;
    }

    // Основные
    get ctrl(): PageCtrl {
        return gfInitContextCtrl(mIsClient() ? this.$context : this.$ssrContext, PageCtrl);
    }
}

<template>
    <section class="home-cities" aria-label="Карта">
        <a @click="fSendActionOpenGeo" href="/geo" class="home-cities__link" :target="$sTargetLink">
            <img
                class="home-cities__image home-cities__image_desktop-tablet"
                width="1119"
                height="401"
                loading="lazy"
                decoding="async"
                src="@/assets/images/home-map.webp"
                alt="Пункты выдачи"
            />
            <img
                class="home-cities__image home-cities__image_mobile"
                width="320"
                height="272"
                loading="lazy"
                decoding="async"
                src="@/assets/images/home-map-mob.webp"
                alt="Пункты выдачи"
            />
        </a>

        <div>
            <a class="home-cities__button" href="/geo" :target="$sTargetLink" @click="fSendActionOpenChoicePvzAddress"> Выбрать пункт выдачи </a>
        </div>
    </section>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import HomeCitiesSource from './home-cities-source';

@Component
export default class HomeCities extends HomeCitiesSource {}
</script>

<style lang="scss" scoped>
// Основные стили страницы
.home-cities {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &__link {
        display: block;

        width: 100%;

        font-size: 0;
    }

    &__image {
        width: 100%;
        max-width: 1120px;
        height: auto;
        margin: 0 auto;

        border-radius: 10px;

        &_desktop-tablet {
            display: none;

            @media (min-width: 480px) {
                display: block;
            }
        }

        &_mobile {
            display: block;

            @media (min-width: 480px) {
                display: none;
            }
        }
    }

    &__button {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;

        width: 288px;
        height: 36px;
        margin-top: 24px;
        padding: 7px 18px;

        cursor: pointer;
        transition: background 0.1s ease-in-out, color 0.1s ease-in-out;
        text-decoration: none;

        color: #fff;
        border-radius: 39px;
        background: #36a6f2;

        font-size: 14px;
        font-weight: 700;
        line-height: 22px;

        &:hover {
            color: #36a6f2;
            border: 1px solid #36a6f2;
            background: #fff;
        }

        &:active {
            transform: scale(0.95);

            color: #fff;
            background: #0080f6;
        }

        @media (min-width: 768px) {
            width: 398px;
            height: 56px;

            font-size: 20px;
        }
    }
}
</style>

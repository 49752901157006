/** Управления id модальных окон */
class ModalS {
    private idLast = 0; // id Последней открытой модалки

    /** Сгенерировать новую id */
    fIdGen() {
        this.idLast++
        return this.idLast;
    }
    
    /** Проверить по id, последняя ли эта модалка */
    fCheckLastOpen(id: number) {
        return this.idLast === id
    }
    
    /** Закрытие модалки */
    fDicrementOnClose() {
        this.idLast--
    }
}

export const gModalS = new ModalS()
<script lang="ts">
import Component from 'vue-class-component';
import PageNewSource from './page-new-source';
import PartnersBanner from '@/pages/home/el/partners-banner/partners-banner';

@Component({
    components: { PartnersBanner },
})
export default class PageNew extends PageNewSource {}
</script>

<template>
    <div class="home-page-new" v-if="status.is_init">
        <section class="promo">
            <div class="promo__content">
                <BannerNew class="promo__banner" />
                <DaySales class="promo__day-sales" :itemOfDayCollection="ItemOfDayCollection" />
            </div>
            <div v-if="isMobile && isUnauth" class="home-page-new__reg-mob">
                <RegBlockM />
            </div>
            <Infoblock />
            <ListCounts v-if="!isMobile" />
        </section>
        <div class="home-page-new__container">
            <div v-if="isUnauth && !isMobile" class="home-page-new__reg-desc">
                <RegBlockD />
            </div>
            <SalesHits :isShowManagerMenu="isShowManagerMenu" />
            <Benefits v-if="isUnauth || isNewUser" />
            <ClientRec />
            <HomeNewsNew />
            <AboutUs />
            <PartnersBanner />
        </div>
    </div>
</template>

<style lang="scss">
.home-page-new {
    width: 100%;
    min-width: 360px;

    .promo {
        display: flex;
        align-items: center;
        flex-direction: column;
        background-color: #f5f6fb;
        padding: 16px 12px;

        @media (min-width: 768px) {
            padding: 16px 24px;
        }

        &__content {
            display: grid;
            grid-template-columns: repeat(12, 1fr);
            grid-template-rows: repeat(2, auto);
            grid-column-gap: 0;
            grid-row-gap: 16px;
            width: 100%;
            max-width: 1344px;

            @media (max-width: 1439px) {
                display: flex;
                flex-direction: column;
            }

            @media (max-width: 767px) {
                grid-row-gap: 12px;
            }
        }

        &__banner {
            grid-area: 1 / 1 / 2 / 13;
            aspect-ratio: 111/35;
            border-radius: 16px;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
            }
        }

        &__day-sales {
            grid-area: 2 / 1 / 3 / 13;
        }

        @media (min-width: 1440px) {
            padding: 24px 48px;

            &__content {
                grid-template-rows: repeat(1, auto);
                grid-column-gap: 24px;
                grid-row-gap: 0;
            }

            &__banner {
                grid-area: 1 / 1 / 2 / 9;
            }

            &__day-sales {
                grid-area: 1 / 9 / 2 / 13;
            }
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
        gap: 48px;
        align-items: center;
        padding: 12px;

        @media (min-width: 768px) {
            padding: 24px 24px 16px 24px;
        }

        @media (min-width: 1440px) {
            padding: 24px 48px;
            gap: 57px;
        }

        section {
            max-width: 1344px;
        }
    }

    &__reg-mob {
        width: 100%;
        padding: 12px 0 0;
    }

    &__reg-desc {
        width: 100%;
        max-width: 1344px;
    }
}
</style>

import currency from 'currency.js';

/**
 * Форматирование цены
 */
export const mFormatPrice = (amount: any): string => {
    return currency(parseFloat(amount), { symbol: '', separator: ' ', decimal: ',' }).format();
};

/**
 * Форматирование цены без копеек
 */
export const mFormatPriceNoCoins = (amount: number): string => {
    return Math.round(amount).toString();
};

/**
 * Форматирование цены без копеек с сепаратором
 */
export const mFormatPriceSeparatorNoCoins = (amount: any): string => {
    return currency(parseFloat(amount), { symbol: '', separator: ' ', precision: 0 }).format();
};

/** Округлить до сотых и привести к строке, на конце всегда будет два знака после запятой 1.00 */
export const fRoundToString = (nNumber: number) => {
    return (Math.round(nNumber * 100) / 100).toFixed(2)
}

/** Округлить до сотых и привести к числу, все нули в конце отбросятся */
export const fRoundToNumber = (nNumber: number) => {
    return Math.round(nNumber * 100) / 100
}

<script lang="ts">
import P63Vue from '@/system/P63Vue';
import Component from 'vue-class-component';
import HomeBtn from '@/pages/home/el/home-btn/home-btn';
import { HomeCtrl, initHomeCtrl } from '../../view/ctrl-home';
import { mIsClient } from '@/common/helpers/ContextH';

@Component({
    components: {
        HomeBtn,
    },
})
export default class RegBlockM extends P63Vue {
    /** Отправить данные в статистику о клике по кнопке "Зарегистрироваться" */
    fSendActionRegClick() {
        this.ctrl.statSys.faPushClickToStat({
            page: location.pathname,
            type: 'button_new',
            alias: 'button_click_reg_new',
            entity_id: 0,
        });
    }

    /** Хранилище */
    get ctrl(): HomeCtrl {
        return initHomeCtrl(mIsClient() ? this.$context : this.$ssrContext);
    }
}
</script>

<template>
    <div class="reg-block-m">
        <h4 class="reg-block-m__title">Совместные покупки — это выгодно!</h4>
        <p class="reg-block-m__info">Участвуйте в наших закупках и экономьте до 90%</p>
        <a class="reg-block-m__button" href="/auth#/register/" :target="$sTargetLink" @click="fSendActionRegClick">
            <HomeBtn>Быстрая регистрация</HomeBtn>
        </a>
    </div>
</template>

<style lang="scss" scoped>
p,
h4 {
    margin: 0;
    padding: 0;
}

.reg-block-m {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    border-radius: 8px;
    padding: 24px 16px;
    text-align: center;

    &__title {
        color: #2b2a29;
        font-size: 16px;
        font-weight: 600;
        line-height: 150%;
        margin-bottom: 4px;
    }

    &__info {
        color: #606266;
        max-width: 250px;
        font-weight: 400;
        line-height: 150%;
        margin-bottom: 10px;
    }

    &__button {
        button {
            width: 304px;
        }
    }
}
</style>

/** Приватные настройки */
export const configPrivate = {
  app: {
    /** Порт приложения */
    port: 3081,
    /** Имя приложения */
    name: 'clientweb',
    /** Host на котором стартует нода */
    host: '0.0.0.0',
    /** Публичны хост балаенсер на проде */
    hostPublic: '63pokupki.ru',
    guard: false, // Блокировщик запросов по IP
  },
  /** Данные для авторизации */
  auth: {
    /** Ключ для шифровки jwt токена */
    secret: 'f484b4d0b0b268295c6bdfdca7b7114bcpy5kf78rkcnui9oh',
    secretNew: 'aac8b2dc58d976b420f7cadad881a8511852ln5o3kxg1wjrp', // Ключ для шифровки jwt токена
    /** Алгоритм шифрования */
    algorithm: 'HS512',
  },
  webPush: {
    /** Ключ для связки с сервером */
    applicationServerKey: 'BM30lSfDo42kVBZzZbItH-9pv48X3s4XI-R_YACyR3QUypqqP1RmR-6Cl1fRhazUad-TevFgIxnpJeAk7QdQfBQ',
    userVisibleOnly: true
  },
} as const;

export default configPrivate;

/** ключи серверной маршрутизации */
export const srv = {
    keyPool: [ // ключи для корректной работы необходимы минимум 5 совпадений
        'feb26b327c1427fe7aa18fe1bb0ea1561852ln5o3kxg25n2u', // 1
        'de71400c7e736fbaa8918b33346fbbda1852ln5o3kxg25n23', // 2
        '6c5a2e7a6a5c62ca2ce6b1db9fcb28121852ln5o3kxg25mj5', // 3
        'c8137539d33bf4c05ef1af881e5567761852ln5o3kxg25mia', // 4
        'ea6b3999b5d09a1193b7e6f22e7fab001852ln5o3kxg2241f', // 5
        'f482f1133e86f385b724cfe43a41805c1852ln5o3kxg222g6', // 6
        'ef5199b658fd285a63957d457c89a06e1852ln5o3kxg1yzzr', // 7
        '91c1cf912693201bc7c774760f92a9271852ln5o3kxg1yzjo', // 8
        '700ed24e976159ae97c66be1864cbb551852ln5o3kxg1yymk', // 9
        '60a8f961988f77d070711af46462ebfb1852ln5o3kxg1wjr1' // 10
    ],
    ipPool:[
        '127.0.0.1', // локальная машина
        // '46.0.204.254', // наш офис
        '185.216.247.182',  // офис Гагарина
        '185.216.247.181',  // склад Мегастрой
        '10.1.100.213', // devsrv стенд
        '10.10.1.180' // webproxy прод
    ],
    jwt:{ // jwt подпись
        jwtKey:'bc9c9bd6874b92817d68b99d6d43865c1852ln5o3kxg1wjal', // Ключ для шифровки jwt токена
        algorithm: 'HS512', // Алгоритм шифрования
        exp: 12 * 30 * 24 * 60 * 60 // 12 мес * 30 дней * 24 часа * 60 минут * 60 секунд
    },
    cry:{ // Ключ для шифровки данных
        key:'fc4fd4bcd5ee652c36d53d9853f4f531852ln5o3kxg1wjqj', // 48 символов
        algorithm: 'aes-256-cbc', // Алгоритм шифрования
    }
};

export type ConfigPrivate = typeof configPrivate;

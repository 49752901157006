import { VuexSys } from '@63pokupki/front';

import { AxiosRequestConfig } from 'axios';
import { OptionCtrlI, PageCtrl } from './PageCtrl';

import cookie from 'cookie';

import { P63Context } from './P63Context';
import { mIsClient } from '../common/helpers/ContextH';

import { mDetectDeviceTypeOnServer } from '@/common/helpers/DeviceTypeOnServerH';
import { mDetectDeviceTypeOnClient } from '@/common/helpers/DeviceTypeH';
import { DeviceTypeN as N } from '@/common/interface/DeviceTypeI';

import https from 'https';

export const gContextSys = { ctrl: {}, id: 0 };

// Объект конекст серверный и фронтовый, аналог синглтона
export function gfInitContextCtrl<T>(ctx: P63Context, TCtrl: new (VuexSys, option?: OptionCtrlI) => T): T {
    let ctrl = null;

    if (ctx.ctrl) {
        ctrl = ctx.ctrl;
    } else {
        // Вызывается на клиенте один раз
        const vVuexSys = new VuexSys(ctx.store);

        let isApp = document.cookie.indexOf('isApp=true') !== -1 ? true : false;

        const sDeviceType = mDetectDeviceTypeOnClient();

        isApp = isApp && sDeviceType === N.DeviceTypeT.mobile;

        ctrl = new TCtrl(vVuexSys, {
            preserveState: true,
            isApp,
            time: Date.now(),
        });
        ctx.ctrl = ctrl;
    }

    return ctrl;
}

export function gfServerContextCtrl<T>(ctx: P63Context, TCtrl: new (VuexSys, option?: OptionCtrlI) => T): T {
    let ctrl = null;
    if (ctx.ctrl) {
        ctrl = ctx.ctrl;
    } else {
        // Вызывается на сервере один раз за запрос

        // Получение куки
        // TODO старый meedleware удалить
        const vCookies = cookie.parse(ctx.req.headers.cookie || '');
        const sApiKey = vCookies?.apikey || '';

        let isApp = Boolean(ctx.cookies.isApp);

        isApp = isApp && ctx.sys.sTypeDevice === N.DeviceTypeT.mobile;

        const vVuexSys = new VuexSys(ctx.store);
        ctrl = new TCtrl(vVuexSys, {
            apikey: sApiKey,
            srvkey: ctx.sys.srvkey,
            deviceType: ctx.sys.sTypeDevice,
            isApp: isApp,
            time: Date.now(),
        });
        ctx.ctrl = ctrl;

        ctx.ctrl.headerSys.header.is_ab_test = <String>ctx.cookies.isShowNewMainPage === 'true';
    }

    return ctrl;
}

/** Получить конфиг клиент/сервер axios */
export function gfInitContextQuery(ctrl: PageCtrl, confAxios: AxiosRequestConfig) {
    const options: AxiosRequestConfig = {};

    if (!mIsClient()) {
        options.headers = {};

        // Игнорирование сертификата при межсерверном запросе
        options.httpsAgent = new https.Agent({
            rejectUnauthorized: false,
        } as unknown);

        if (ctrl._apikey) {
            options.headers.apikey = ctrl._apikey;
        }

        if (ctrl._srvkey) {
            options.headers.srvkey = ctrl._srvkey;
        }
    }

    return { ...confAxios, ...options };
}
